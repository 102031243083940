import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import List from 'ipmp-react-ui/List'

import {SettingsPageBlock} from '../SettingsPageBlock'
import EditInteractiveSessionButton from './Buttons/EditInteractiveSessionButton'

import {__} from 'utils/i18n'

class InteractiveSession extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            doAutoEnableUserApp: PropTypes.bool,
            doAutoEnableConfiguratorApp: PropTypes.bool,
            isDlsServiceEnabled: PropTypes.bool,
            globalServerApptype: PropTypes.string,
            maxInteractiveConnections: PropTypes.number,
            maxInteractiveConnectionsPerPanel: PropTypes.number,
            doRequireUserInstallerApproval: PropTypes.bool,
            requireUserInstallerApprovalTimeout: PropTypes.number,
        }),
    }

    render() {
        const {data} = this.props

        return (
            <div>
                <h1 className="card-header">
                    {__('Interactive Session')}
                    <EditInteractiveSessionButton />
                </h1>

                <List className="card-content" twoCols>
                    {this.renderListItem(
                        __('Enable User App for New Incoming Panels'),
                        data.doAutoEnableUserApp
                    )}
                    {this.renderListItem(
                        __('Enable Installer App for New Incoming Panels'),
                        data.doAutoEnableConfiguratorApp
                    )}
                    {this.renderListItem(
                        __('Enable DLS service'),
                        data.isDlsServiceEnabled
                    )}
                    {this.renderListItem(
                        __('Application Type'),
                        data.globalServerApptype
                    )}
                    {this.renderListItem(
                        __('Limit of Concurrent Interactive Connections per Server'),
                        data.maxInteractiveConnections
                    )}
                    {this.renderListItem(
                        __('Limit of Concurrent Interactive Sessions per Panel'),
                        data.maxInteractiveConnectionsPerPanel
                    )}
                    {this.renderListItem(
                        __('Approve Installer Access by User'),
                        data.doRequireUserInstallerApproval
                    )}
                    {data.doRequireUserInstallerApproval &&
                        this.renderListItem(
                            __('Installer Access Timeout [Minutes]'),
                            data.requireUserInstallerApprovalTimeout
                        )}
                </List>
            </div>
        )
    }
}

export default compose(
    connect(({system: {settings: {interactiveSession}}}) => ({...interactiveSession}))
)(InteractiveSession)
