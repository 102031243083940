import createRowsResponseParser from 'api/base/createRowsResponseParser'
import {get, post} from 'api/http'

import generateFilterString from 'api/base/generateFilterString'

import {
    keysMap,
    mapInteractiveUser,
    mapInteractiveUserPanels,
    mapOnePanelInteractiveUsers,
    assignUserKeyMap,
    mapPanelsInteractiveUsers,
} from './interactiveUsers.map'
import mapValidationErrors from './base/mapValidationErrors'

export function fetch({start = 0, perPage: count = 10, filters, query}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/interactiveusers/list', {start, count, query, filter}).then(
        createRowsResponseParser(mapInteractiveUser)
    )
}

export function suspend(userId) {
    return post('/interactiveusers/suspend', {pru_id: userId}).then(mapInteractiveUser)
}

export function enable(userId) {
    return post('/interactiveusers/enable', {pru_id: userId}).then(mapInteractiveUser)
}

export function fetchInteractiveUserPanels({interactiveUserId}) {
    return get('/interactiveusers/panels', {pru_id: interactiveUserId}).then(
        createRowsResponseParser(mapInteractiveUserPanels)
    )
}

export function fetchInteractiveUserResetPassword({interactiveUserId}) {
    return post('/interactiveusers/reset', {pru_id: interactiveUserId}).then(
        mapInteractiveUser
    )
}

export function disconnectPanel({interactiveUserId, panelId}) {
    return post('/interactiveusers/disconnect', {
        pru_id: interactiveUserId,
        unt_id: panelId,
    })
}

export function assignUserToPanel({panelId, email, panelUserId}) {
    return post('/interactiveusers/connect', {
        email,
        unt_id: panelId,
        pxu_panel_id: panelUserId,
    }).catch((e) => mapValidationErrors(e, assignUserKeyMap))
}

export function panelInteractiveUsers({panelId}) {
    return get('/interactiveusers/unitusers', {unt_id: panelId}).then(
        createRowsResponseParser(mapOnePanelInteractiveUsers)
    )
}

export function register({email}) {
    return post('/interactiveusers/register', {email})
}

// Fetch interactive users, assign to master user on panel
export function interactiveMasterUsersAssignedToPanelsByPanelIds({filters}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/interactiveusers/assignedunitusers', {filter}).then(
        createRowsResponseParser(mapPanelsInteractiveUsers)
    )
}
