import React from 'react'
import {compose} from 'redux'

import Duration from 'ipmp-react-ui/Duration'
import Time from 'ipmp-react-ui/Time'
import {humanDate} from 'ipmp-react-ui/humanTime'
import {TableGrouped} from 'ipmp-react-ui/Table'

import processType from 'constants/processType'
import withBatches from 'containers/withBatches'
import withProps from 'containers/withProps'
import withLifeCycle from 'containers/withLifeCycle'
import withLoader from 'containers/withLoader'

import ProcessCounters from 'components/Processes/ProcessCounters'
import BatchStatusCell from 'pages/Batches/BatchesCells/BatchStatusCell'

import {__, __n} from 'utils/i18n'
import path from 'utils/path'
import Empty from 'ui/Empty'

/**
 * PMN-5919
 * widht values were set, according to minimal table viewport
 * 794px = 1000px - 16px(pzdding) - 190px(asside)
 */
export const columns = [
    {
        maxWidth: 100,
        render: ({started}) => <Time datetime={started} />,
    },
    {
        width: 100,
        render: ({type}) => processType(type),
    },
    {
        width: 100,
        render: ({panels, panelSerial}) => {
            if (panels > 1) {
                return __n('%d panel', '%d panels', panels)
            }

            return panelSerial || <Empty />
        },
    },
    {
        width: 200,
        render: BatchStatusCell,
    },
    {
        width: 150,
        maxWidth: 150,
        render: ({started, finished}) => <Duration from={started} to={finished} />,
    },
    {
        width: 156,
        render: ({stats}) => <ProcessCounters stats={stats} />,
    },
]

export default compose(
    withBatches(),
    withProps(({history}) => ({
        columns,
        emptyMessage: () => __('No batches'),
        separatorCallback(row) {
            let group = humanDate(row.started)
            return group || __('No user processes')
        },
        selectionBar: false,
        onRowClick({id}) {
            history.push(path('batches.runners', {id}))
        },
    })),
    withLifeCycle({
        onMount({startPoll}) {
            startPoll()
        },

        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withLoader(({init}) => init())
)(TableGrouped)
