import {handleActions} from 'redux-actions'

import {sendStartMessage, setCount, setLastEventId} from './actions'

const defaultState = {
    count: 0,
}

export default handleActions(
    {
        [sendStartMessage](state) {
            return {
                ...state,
                count: 0,
            }
        },

        [setCount](state, {payload: {count}}) {
            return {
                ...state,
                count,
            }
        },

        [setLastEventId](state) {
            return {
                ...state,
                count: 0,
            }
        },
    },
    defaultState
)
