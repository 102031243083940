import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Card, {CardHeader, CardContent} from 'ipmp-react-ui/Card'

import __ from 'utils/i18n'
import Search from 'components/Search/Search'
import InstallerAddPanelTable from 'pages/Installers/BlindContent/InstallerAddPanelTable'
import InstallerAddPanelPager from 'pages/Installers/BlindContent/InstallerAddPanelPager'
import {withInstallerAddPanelTable} from 'containers/withInstallerPanelsTable'

class InstallerAddPanel extends Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        installer: PropTypes.shape({
            email: PropTypes.string,
        }).isRequired,
        setQuery: PropTypes.func.isRequired,
        fetch: PropTypes.func.isRequired,
    }

    handleSearchChange = (value) => {
        return this.props.setQuery(value)
    }

    applySearch = () => {
        this.props.fetch()
    }

    onClose = () => this.props.onClose()

    render() {
        const {installer} = this.props
        return (
            <Card className="installerAddPanel">
                <CardHeader onBack={this.onClose}>
                    {__('Add panel to %s', installer.email)}
                </CardHeader>
                <CardContent>
                    <Bar>
                        <Search
                            apply={this.applySearch}
                            onQuery={this.handleSearchChange}
                            filters={[]}
                            selected={[]}
                            hideAutoCompleteAfterQuery={true}
                        />

                        <BarSpace />

                        <InstallerAddPanelPager />
                    </Bar>
                    <InstallerAddPanelTable />
                </CardContent>
            </Card>
        )
    }
}

export default compose(withInstallerAddPanelTable())(InstallerAddPanel)
