import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {setBypass} from 'modules/devices/list/actions'

import {bypass} from 'permissions/panel/devices/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'
import withProps from 'containers/withProps'
import withConfirmation from 'containers/withConfirmation'
import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: bypass}),
    withVisibility(),
    connect(null, (dispatch, {deviceBypassStates, panelId, resetSelection}) =>
        bindActionCreators(
            {
                onClick: () => {
                    resetSelection()
                    return setBypass(panelId, deviceBypassStates)
                },
            },
            dispatch
        )
    ),
    withProps(() => ({
        title: __('Set Bypass for Devices'),
        label: __('Set Bypass'),
        message: __('Do you really want to set bypass to selected panels?'),
    })),
    withConfirmation()
)(Button)
