import withProps from 'containers/withProps'

import BasicConfigurationGridBar from './BasicConfigurationGridBar'
import BasicConfigurationContent from './BasicConfigurationContent'

import Page from 'ipmp-react-ui/Page'

export default withProps({
    Bar: BasicConfigurationGridBar,
    Content: BasicConfigurationContent,
})(Page)
