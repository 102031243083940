import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import get from 'lodash-es/get'

import Autocomplete from 'ipmp-react-ui/Autocomplete'

import ModalCardForm from 'ui/ModalCardForm'

import withForm from 'containers/withForm'
import {assignUserToPanel} from 'modules/forms/handlers'
import {fetch} from 'modules/panels/panelUsers/actions'
import {fetch as interactiveUsersFetch} from 'modules/interactiveUsers/list/actions'
import withSelectLoader from 'containers/withSelectLoader'
import __ from 'utils/i18n'
import isNil from 'lodash-es/isNil'
import withInterruption from 'containers/withInterruption'

import NoPanelUsersError from './NoPanelUsersError'
import {withPermission} from 'containers/withPermission'
import {register} from 'permissions/interactiveUsers/actions'

const PanelUserSelect = withSelectLoader(
    (prefix, maxOptionsToShow, {panelId}) => fetch(panelId),
    ({panels}) => panels.panelUsers.isLoading,
    ({
        modals: {
            args: {panelId},
        },
        panels: {
            panelUsers: {byIds},
        },
    }) => get(byIds, panelId, []).map(({id, role}) => ({value: id, label: role})),
    10,
    true
)(Autocomplete)

const InteractiveUsersSelect = withSelectLoader(
    interactiveUsersFetch,
    ({interactiveUsers}) => interactiveUsers.autocomplete.isLoading,
    ({
        interactiveUsers: {
            store: {byIds},
        },
    }) => Object.values(byIds).map(({email}) => ({value: email, label: email})),
    10,
    true
)(Autocomplete)

class AssignUserToPanel extends Component {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        panel: PropTypes.shape({
            id: PropTypes.number.isRequired,
            serial: PropTypes.string.isRequired,
        }).isRequired,
        interactiveUser: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),

        interactiveUsers: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ),
    }

    rules = {
        panelUserId: {
            presence: true,
        },
        email: {
            presence: true,
            email: true,
        },
    }

    getEmail = (emailFromForm) => {
        const {interactiveUser} = this.props

        if (interactiveUser) {
            return interactiveUser.email
        }

        return emailFromForm
    }

    handle = ({panelUserId, email}) => {
        const {panelId, handle} = this.props

        handle({
            panelId,
            email: this.getEmail(email),
            panelUserId: Number(panelUserId),
        })
    }

    get header() {
        const {panel} = this.props

        return __('Assign user to panel %s', panel.serial)
    }

    renderSelectedInteractiveUser = () => {
        const {interactiveUser} = this.props

        return (
            <Autocomplete
                label={__('Email')}
                name="email"
                disabled
                defaultValue={interactiveUser.email}
                defaultLabel={interactiveUser.email}
            />
        )
    }

    renderInteractiveUsersAutocomplete = () => {
        const {interactiveUser, isRegisterNew} = this.props

        if (!isNil(interactiveUser)) {
            return this.renderSelectedInteractiveUser()
        }

        return (
            <InteractiveUsersSelect
                label={__('Email')}
                name="email"
                isCreatable={isRegisterNew}
            />
        )
    }

    render() {
        const {panel} = this.props

        return (
            <ModalCardForm
                onSubmit={this.handle}
                header={this.header}
                rules={this.rules}
                confirmOnSubmit
                {...this.props}
            >
                {this.renderInteractiveUsersAutocomplete()}
                <PanelUserSelect
                    label={__('Panel User')}
                    name="panelUserId"
                    panelId={panel.id}
                />
            </ModalCardForm>
        )
    }
}

export default compose(
    withForm(assignUserToPanel),
    withPermission({isRegisterNew: register}),
    connect(
        (
            {
                interactiveUsers: {
                    store: {byIds},
                },
                interactiveUserPanels: {list},
                panels,
                panelInteractiveUsers: {store},
            },
            {panelId}
        ) => ({
            interactiveUser: byIds[list.interactiveUserId],
            panel: panels.store.byIds[panelId],
        })
    ),
    withInterruption(
        ({hasPanelUser}) => (isNil(hasPanelUser) ? false : !hasPanelUser),
        NoPanelUsersError
    )
)(AssignUserToPanel)
