export default class InteractiveUserPanelsById {
    panelIds: Array = []
    isLoading: boolean
    error

    constructor({
        isLoading = false,
        error = null,
        panels = [],
    }: {
        isLoading: boolean,
        error: Object,
        panels: Array,
    }) {
        this.isLoading = isLoading
        this.error = error
        this.panels = panels
    }
}
