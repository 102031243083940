import parseDate from 'api/base/parseDate'
import {get, post} from 'api/http'

const mapRemark = (data) => ({
    ...data,
    time: parseDate(data.time),
})

export function fetch(panelId) {
    return get('/unit_remarks/getall', {unt_id: panelId}).then((remarks) =>
        remarks.map(mapRemark)
    )
}

export function add(panelId, value) {
    return post('/unit_remarks/add', {unt_id: panelId, value})
}

export function remove(panelId, remarkId) {
    return post('/unit_remarks/remove', {unt_id: panelId, remarkId})
}
