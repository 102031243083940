import {compose} from 'redux'
import Button from 'ipmp-react-ui/Button'
import Fab from 'ipmp-react-ui/Fab'
import {ReactComponent as IconCross} from 'ipmp-react-ui/icons/cross.svg'

import withConfirmation from 'containers/withConfirmation'
import withProps from 'containers/withProps'
import {__} from 'utils/i18n'

export default compose(
    withProps(({disabled}) => ({
        small: true,
        flat: true,
        primary: !disabled,
        Icon: IconCross,
        title: __('Stop process'),
        message: __('Do you want to stop process?'),
    })),
    withConfirmation()
)(Button)

export const ProcessStopFab = compose(
    withProps(({disabled}) => ({
        mini: true,
        primary: true,
        Icon: IconCross,
        title: __('Stop process'),
        message: __('Do you want to stop process?'),
    })),
    withConfirmation()
)(Fab)
