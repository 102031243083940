import {sprintf} from 'sprintf-js'
import has from 'lodash-es/has'

import * as DeviceTypes from 'constants/deviceType'
import * as DeviceSubtypes from 'constants/deviceSubtype'

export const deviceTypesSectionName = {
    [DeviceTypes.DEVICE_TYPE_ZONE]: 'Zone No. %d',
    [DeviceTypes.DEVICE_TYPE_CAMERA]: 'Zone No. %d',
    [DeviceTypes.DEVICE_TYPE_KEYFOB]: 'Keyfob Params %d',
    [DeviceTypes.DEVICE_TYPE_PENDANT]: 'Panic button Params %d',
    [DeviceTypes.DEVICE_TYPE_WIRELESS_COMMANDER]: 'Keypad Params %d',
    [DeviceTypes.DEVICE_TYPE_WIRED_KEYPAD]: 'Keypad Params %d',
    [DeviceTypes.DEVICE_TYPE_2_WAY_WIRELESS_KEYPAD]: 'Keypad Params %d',
    [DeviceTypes.DEVICE_TYPE_PGM]: 'PGM %d',
    [DeviceTypes.DEVICE_TYPE_WL_SIREN]: 'Siren Params %d',
    [DeviceTypes.DEVICE_TYPE_EXPANDER_33]: 'SET EXP I/O PINS',
    [DeviceTypes.DEVICE_TYPE_POWER_LINK]: 'COMMUNICATION SETTINGS',
}

export const deviceSubtypesSectionName = {
    [DeviceSubtypes.DEVICE_SUBTYPE_NEO_POWERG_WIRELESS_TRANCEIVER_MODULE]:
        '%d Wireless Programming',
    [DeviceSubtypes.DEVICE_SUBTYPE_NEO_AUDIO_MODULE_WITH_RECORDING]:
        '%d Audio Programming',
    [DeviceSubtypes.DEVICE_SUBTYPE_PGM_ON_4_HIGH_CURRENT_OUTPUT_MODULE]:
        'HSM2204 4 O/P Expander %d',
    [DeviceSubtypes.DEVICE_SUBTYPE_NEO_1A_POWER_SUPPLY_MODULE]: 'HSM2300 Power Supply %d',
    [DeviceSubtypes.DEVICE_SUBTYPE_NEO_CORBUS_REPEATER_MODULE]: 'HSM3204CX Repeater %d',
    [DeviceSubtypes.DEVICE_SUBTYPE_NEO_3A_POWER_SUPPLY_MODULE]: 'HSM3350 3A Power Sup %d',
}

export default function deviceSectionName({deviceType, deviceSubtype, zone}) {
    if (has(deviceTypesSectionName, deviceType)) {
        return sprintf(deviceTypesSectionName[deviceType], zone)
    }

    if (has(deviceSubtypesSectionName, deviceSubtype)) {
        return sprintf(deviceSubtypesSectionName[deviceSubtype], zone)
    }

    return null
}
