import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'

import CrossHttp from 'pages/Panels/CrossServerSearch/CrossHttp'
import __ from 'utils/i18n'
import {snackShow} from 'modules/snacks'
import {isNil} from 'lodash-es'
import ModalCardForm from 'ui/ModalCardForm'

const rules = {
    email: {
        presence: true,
        email: true,
    },
    password: {
        presence: true,
        length: {minimum: 6},
    },
}

class LoginForm extends PureComponent {
    static propTypes = {
        http: PropTypes.instanceOf(CrossHttp).isRequired,
        onClose: PropTypes.func.isRequired,
        onSuccess: PropTypes.func.isRequired,
        domain: PropTypes.string.isRequired,
        snackShow: PropTypes.func.isRequired,
    }

    state = {
        isLoading: false,
    }

    handleSubmit = ({email, password}) => {
        const {http, onSuccess, onClose} = this.props

        this.setState({isLoading: true})

        http.login(email, password).then((response) => {
            onSuccess(response.user)
            onClose()
        }, this.handleError)
    }

    handleError = (error) => {
        const {snackShow} = this.props

        if (isNil(error.errors)) {
            snackShow(error.message)
            this.setState({
                isLoading: false,
            })
        } else {
            this.setState({
                isLoading: false,
                error,
            })
        }
    }

    render() {
        const {onClose, domain} = this.props
        const {isLoading, error} = this.state

        return (
            <ModalCardForm
                className="login-form"
                isLoading={isLoading}
                onSubmit={this.handleSubmit}
                errors={error && error.errors}
                rules={rules}
                hide={onClose}
                header={__('Login to %s', [domain])}
                submitLabel={__('Login')}
            >
                <Input name="email" label={__('Email')} />

                <Input name="password" type="password" label={__('Password')} />
            </ModalCardForm>
        )
    }
}

export default connect(null, (dispatch) =>
    bindActionCreators(
        {
            snackShow,
        },
        dispatch
    )
)(LoginForm)
