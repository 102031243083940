import {compose} from 'redux'

import selection from 'permissions/events/selection'

import withSelection from 'containers/withSelection'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import SelectionDropDown from 'components/SelectionDropDown'
import withEvents from 'containers/withEvents'

export default compose(
    withPermission({isVisible: selection}),
    withVisibility(),
    withEvents(),
    withSelection()
)(SelectionDropDown)
