import {get, post} from 'api/http'
import {mapProcess} from 'api/processes'
import dataMapper from 'utils/dataMapper'

const mapVideoOnDemandInfo = ({video, process, history}) => {
    return {
        history: Array.isArray(history) ? history.map(mapVideoOnDemand) : [],
        video: video ? mapVideoOnDemand(video) : {},
        process: process && mapProcess(process),
    }
}

const mapVideoOnDemand = dataMapper(({time, videos, frames, is_closed: isClosed}) => ({
    time: time,
    isClosed: isClosed,
    frames: frames ? frames : [],
    video: Array.isArray(videos) ? {} : videos,
}))

const mapVideo = dataMapper((video) => ({
    time: video.arrived,
    frames: video.frames,
    location: video.location,
    preview: video.preview,
    isReady: video.ready,
    videoPath: video.video,
    zone: video.zone,
}))

export function getVideo(unitId, unitZoneId) {
    return get('/unit_diagnostic/video', {
        unt_id: unitId,
        utz_id: unitZoneId,
    }).then(mapVideo)
}

export function getVideoOnDemandInfo(unitId, unitZoneId) {
    return get('/unit_diagnostic/videoondemandinfo', {
        unt_id: unitId,
        utz_id: unitZoneId,
    })
        .then(mapVideoOnDemandInfo)
        .catch(() => ({
            data: {
                process: {isRunning: false, isFailed: true},
                video: {frames: [], video: [], time: null},
                history: [],
            },
        }))
}

export function requestVideo(unitId, unitZoneId, batchId) {
    return post('/unit_diagnostic/requestvideo', {
        unt_id: unitId,
        utz_id: unitZoneId,
        batch_id: batchId,
    }).then(mapProcess)
}
