import React, {Component} from 'react'

import Input from 'ipmp-react-ui/Input'
import Checkbox from 'ipmp-react-ui/Checkbox'

import {__, weekdays} from 'utils/i18n'
import {FormRow, FormSection} from 'ipmp-react-ui/Form'

export default class extends Component {
    renderDays(start, end) {
        return weekdays()
            .slice(start, end)
            .map(({label, day}) => (
                <Checkbox key={day} name="weeklyDay[]" label={label} value={day} />
            ))
    }

    render() {
        return (
            <FormSection>
                <FormRow>
                    <span>{__('Recur every')}</span>

                    <Input
                        {...{
                            type: 'number',
                            defaultValue: 1,
                            name: 'weeklyEvery',
                            min: 1,
                            max: 52,
                        }}
                    />

                    <span>{__('week')}</span>
                </FormRow>

                <FormRow>{this.renderDays(0, 4)}</FormRow>

                <FormRow name="weeklyDay">{this.renderDays(4)}</FormRow>
            </FormSection>
        )
    }
}
