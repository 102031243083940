import React, {Component} from 'react'
import PropTypes from 'prop-types'

import DeviceActionsWrapper from 'components/Devices/DeviceActionsWrapper'
import BypassCheckbox from './Checkboxes/BypassCheckbox'
import SoakCheckbox from './Checkboxes/SoakCheckbox'
import RarelyUsedCheckbox from './Checkboxes/RarelyUsedCheckbox'
import RenameButton from './Buttons/RenameButton'
import RemoveButton from './Buttons/RemoveButton'

class GeneralInfoTabBlock extends Component {
    static propTypes = {
        device: PropTypes.shape({
            id: PropTypes.number.isRequired,
            traits: PropTypes.shape({
                rarely_used: PropTypes.shape({
                    progress: PropTypes.bool,
                    enabled: PropTypes.bool.isRequired,
                }),
                bypass: PropTypes.shape({
                    progress: PropTypes.bool,
                    enabled: PropTypes.bool.isRequired,
                }),
                soak: PropTypes.shape({
                    progress: PropTypes.bool,
                    enabled: PropTypes.bool.isRequired,
                }),
            }),
        }).isRequired,
        panelId: PropTypes.number.isRequired,
    }

    render() {
        const {device, panelId} = this.props

        if (!device) {
            return null
        }

        return (
            <DeviceActionsWrapper className="block">
                <BypassCheckbox panelId={panelId} device={device} />
                <SoakCheckbox panelId={panelId} device={device} />
                <RarelyUsedCheckbox panelId={panelId} device={device} />
                <DeviceActionsWrapper className="blindModalDevices-actions">
                    <RenameButton panelId={panelId} device={device} />
                    <RemoveButton panelId={panelId} device={device} />
                </DeviceActionsWrapper>
            </DeviceActionsWrapper>
        )
    }
}

export default GeneralInfoTabBlock
