import React from 'react'
import {connect} from 'react-redux'

import {markAsViewed as view} from 'permissions/events/actions'

import withProps from 'containers/withProps'
import withTableColumnsPermission from 'containers/withTableColumnsPermission'
import {markAsViewed} from 'modules/events/one/actions'

import {ReactComponent as MarkViewedIcon} from 'ipmp-react-ui/icons/viewed.svg'
import {ReactComponent as IconCamera} from 'ipmp-react-ui/icons/camera.svg'
import {compose} from 'redux'

import {ActionCell, SeverityCell, EventAppointmentCell} from 'ui/TableCells'
import Time from 'ipmp-react-ui/Time'
import {TableGrouped} from 'ipmp-react-ui/Table'

import {humanDate} from 'ipmp-react-ui/humanTime'

const columns = [
    {
        maxWidth: 100,
        render: ({datetime}) => <Time datetime={datetime} withSeconds />,
    },
    {
        tooltip: false,
        width: 50,
        fixed: true,
        render: ({hasVideo}) => (hasVideo ? <IconCamera /> : null),
    },
    {
        width: 150,
        render: ({severity, description}) => (
            <SeverityCell {...{severity, description}} />
        ),
    },
    {
        maxWidth: 300,
        render: ({zone, appointment, partitions}) => (
            <EventAppointmentCell {...{zone, appointment, partitions}} />
        ),
    },
    {
        permission: view,
        tooltip: false,
        fixed: true,
        width: 50,
        component: connect(
            (state, {row}) => ({
                Icon: MarkViewedIcon,
                isChecked: row.isViewed,
            }),
            (dispatch, {row}) => ({
                onClick: () => dispatch(markAsViewed(row)),
            })
        )(ActionCell),
    },
]

export default compose(
    withProps({
        columns,
        separatorCallback: (row) => humanDate(row.datetime),
    }),
    withTableColumnsPermission()
)(TableGrouped)
