import {compose} from 'redux'

import withProps from 'containers/withProps'
import withUpgradeStatus from 'containers/withUpgradeStatus'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRetry} from 'ipmp-react-ui/icons/refresh.svg'

export default compose(
    withUpgradeStatus(),
    withProps(({retry, id}) => ({
        primary: true,
        flat: true,
        small: true,
        Icon: IconRetry,
        onClick: () => retry(id),
    }))
)(Button)
