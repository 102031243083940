import {createMatchSelector} from 'react-router-redux'
import get from 'lodash-es/get'

export const panelIdMatchSelector = createMatchSelector('/panel/:panelId')

export const getPanelId = (state) => {
    const match = panelIdMatchSelector(state)
    return get(match, 'params.panelId', null)
}

export default panelIdMatchSelector
