import {createAction} from 'redux-actions'
import createListActions from 'modules/higherOrder/createListActions'

export const fetch = createAction(
    'INTERACTIVE_USER_PANELS/LIST/FETCH',
    (interactiveUserId) => ({
        interactiveUserId,
    })
)
export const init = createAction(
    'INTERACTIVE_USER_PANELS/LIST/INIT',
    (interactiveUserId) => ({
        interactiveUserId,
    })
)

export const disconnectPanel = createAction(
    'INTERACTIVE_USER_PANELS/LIST/DISCONNECT_PANEL'
)

export const resetWithOutFetch = createAction(
    'INTERACTIVE_USER_PANELS/LIST/RESET_WITHOUT_FETCH'
)

export const {receive, reset, setStart, revertRemove, purgeList} = createListActions(
    'INTERACTIVE_USER_PANELS/LIST'
)
