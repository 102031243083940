import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import {showPushBasicConfigurationModal} from 'modules/runners/store/actions'

import {PushBasicConfigurationItem} from 'pages/Panels/GridBar/DropDowns/Items/PushBasicConfigurationItem.js'
import withProps from 'containers/withProps'
import {withSelectionFeature} from 'containers/withFeature'
import {CONFIGURATION_UPLOAD} from 'constants/features'
import {selectSelectedRunnersFeature} from 'modules/panels/store/selectors'

export default compose(
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                showPushBasicConfigurationModalProxy: showPushBasicConfigurationModal,
            },
            dispatch
        )
    ),
    withProps(({showPushBasicConfigurationModalProxy}) => ({
        onClick: showPushBasicConfigurationModalProxy,
    })),
    withSelectionFeature(CONFIGURATION_UPLOAD, selectSelectedRunnersFeature)
)(PushBasicConfigurationItem)
