import {createAction} from 'redux-actions'

export const fetch = createAction('BASIC_CONFIGURATION/ONE/FETCH')
export const receive = createAction(
    'BASIC_CONFIGURATION/ONE/RECEIVE',
    null,
    (_, basicConfigId) => ({basicConfigId})
)
export const changeValue = createAction(
    'BASIC_CONFIGURATION/ONE/CHANGE_VALUE',
    (basicConfigId, key, value, valid = true) => ({basicConfigId, key, value, valid})
)
export const setExport = createAction(
    'BASIC_CONFIGURATION/ONE/SET_EXPORT',
    (basicConfigId, key, exported) => ({basicConfigId, key, exported})
)
export const save = createAction('BASIC_CONFIGURATION/ONE/SAVE')
export const setLoading = createAction('BASIC_CONFIGURATION/ONE/SET_LOADING')
export const setQuery = createAction(
    'BASIC_CONFIGURATION/ONE/SET_QUERY',
    (basicConfigId, query) => ({basicConfigId, query})
)
export const showChanged = createAction(
    'BASIC_CONFIGURATION/ONE/SHOW_CHANGED',
    (basicConfigId, isShowChanged) => ({basicConfigId, isShowChanged})
)
export const undo = createAction(
    'BASIC_CONFIGURATION/ONE/UNDO',
    (basicConfigId, key) => ({basicConfigId, key})
)
