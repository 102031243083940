import {combineReducers} from 'redux'

import addPanelList from './addPanelList/reducer'
import list from './list/reducer'
import store from './store/reducer'

export default combineReducers({
    addPanelList,
    list,
    store,
})
