import React from 'react'
import {compose} from 'redux'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import EventRulesPager from './GridBar/EventRulesPager'
import AddEventRuleButton from './GridBar/AddEventRuleButton'

export const EventRulesGridBar = () => (
    <Bar>
        <BarSpace />

        <EventRulesPager />

        <AddEventRuleButton />
    </Bar>
)

export default compose()(EventRulesGridBar)
