import ProcessCounters from 'components/Processes/ProcessCounters'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {__} from 'utils/i18n'
import processType from 'constants/processType'
import {ReactComponent as IconCheck} from 'ipmp-react-ui/icons/check.svg'
import Duration from 'ipmp-react-ui/Duration'
import parseDate from 'api/base/parseDate'
import momentPropType from 'utils/momentPropType'

export default class ProcessBatch extends Component {
    static propTypes = {
        batch: PropTypes.shape({
            type: PropTypes.string.isRequired,
            started: PropTypes.oneOfType([PropTypes.string, momentPropType]).isRequired,
            finished: PropTypes.oneOfType([PropTypes.string, momentPropType]),
            stats: PropTypes.shape({
                succeeded: PropTypes.number.isRequired,
                handled: PropTypes.number.isRequired,
                failed: PropTypes.number.isRequired,
                start: PropTypes.number.isRequired,
            }).isRequired,
            panels: PropTypes.number,
            panelSerial: PropTypes.string,
        }),
        untrack: PropTypes.func.isRequired,
        showInfo: PropTypes.func.isRequired,
        currentBatchId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }

    handleShowInfo = (e) => {
        const {batch, showInfo} = this.props
        showInfo(batch.id)
    }

    handleUntrack = (e) => {
        const {batch, untrack} = this.props
        e.stopPropagation()
        untrack(batch.id)
    }

    renderDuration() {
        let {started, finished} = this.props.batch

        started = parseDate(started)
        finished = parseDate(finished)

        if (finished) {
            return (
                <div className="process-time">
                    {__('Finished in:')} <Duration from={started} to={finished} />
                </div>
            )
        }

        return (
            <div className="process-time">
                <Duration from={started} />
            </div>
        )
    }

    render() {
        const {batch, untrack, currentBatchId} = this.props
        const {type, stats, panels, panelSerial} = batch

        const className = classnames('process', {
            'process--active': currentBatchId === batch.id,
        })

        return (
            <div className={className} onClick={this.handleShowInfo}>
                <div className="process-title">
                    <div className="hint">
                        {panels > 1 ? __('%d panels', panels) : panelSerial}
                    </div>

                    <div className="process-type">{processType(type)}</div>

                    {untrack && (
                        <IconCheck
                            className="process-title-icon"
                            onClick={this.handleUntrack}
                        />
                    )}
                </div>

                {this.renderDuration()}

                <ProcessCounters stats={stats} />
            </div>
        )
    }
}
