import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'

import {remove} from 'modules/stateTypes/list/actions'
import {compose} from 'redux'
import withProps from 'containers/withProps'
import withConfirmation from 'containers/withConfirmation'
import __ from 'utils/i18n'

export default compose(
    connect(null, (dispatcher, {row: {id}}) =>
        bindActionCreators(
            {
                onRemove: () => remove([id]),
            },
            dispatcher
        )
    ),
    withProps(({onRemove, row: {name}}) => ({
        onClick: onRemove,
        small: true,
        flat: true,
        Icon: IconRemove,
        message: __(
            'If you delete this state type, then the event rule in which it is used will also be deleted, if it exists. Do you really want to remove %s state type?',
            name
        ),
        title: __('Remove State Type'),
    })),
    withConfirmation()
)(Button)
