import React, {Component} from 'react'
import {compose} from 'redux'
import {withRouter} from 'react-router-dom'
import has from 'lodash-es/has'

import withBasicConfiguration from 'containers/withBasicConfiguration'
import {withPermission} from 'containers/withPermission'
import withLoader from 'containers/withLoader'
import withConfigurationFilter from 'containers/withConfigurationFilter'

import {edit} from 'permissions/basicConfiguration/actions'
import page from 'permissions/basicConfiguration/page'

import ConfigurationLayout from 'components/Configuration/ConfigurationLayout'
import BasicConfigurationQuickSearch from './Filters/BasicConfigurationQuickSearch'
import {HEIGHT_OFFSET_BEFORE_BASIC_CONFIGURATION_LIST} from 'components/Configuration/measure'

class BasicConfiguration extends Component {
    handleIsChange = (item) => {
        const {exporting, changes, basicValues} = this.props

        if (changes && has(changes, item.key)) {
            return true
        }

        const toExport = exporting && has(exporting, item.key)
        const wasExport = basicValues && has(basicValues, item.key)

        return toExport !== wasExport
    }

    render() {
        const filters = <BasicConfigurationQuickSearch />

        return (
            <ConfigurationLayout
                {...this.props}
                isShowExportCheckbox
                filters={filters}
                isChanged={this.handleIsChange}
                heightOffsetBeforeConfiguration={
                    HEIGHT_OFFSET_BEFORE_BASIC_CONFIGURATION_LIST
                }
            />
        )
    }
}

export default compose(
    withPermission({
        isAllowed: page,
        isEditable: edit,
    }),
    withRouter,
    withBasicConfiguration(),
    withLoader(({fetch, match}) => fetch({basicConfigId: match.params.id})),
    withConfigurationFilter()
)(BasicConfiguration)
