import {compose} from 'redux'

import {edit} from 'permissions/panel/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withPanelInfo from 'containers/withPanelInfo'
import withProps from 'containers/withProps'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: edit}),
    withVisibility(),
    withPanelInfo(),
    withProps(({editCustomerInfo}) => ({
        onClick: editCustomerInfo,
        borderless: true,
        primary: true,
        small: true,
        label: __('Edit'),
    }))
)(Button)
