import React, {PureComponent} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import {setSystemSettings} from 'modules/forms/handlers'

import withForm from 'containers/withForm'

import {__} from 'utils/i18n'
import ModalCardForm from 'ui/ModalCardForm'

import Input from 'ipmp-react-ui/Input'
import Checkbox from 'ipmp-react-ui/Checkbox'

class AuthorizationSettings extends PureComponent {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        data: PropTypes.shape({
            first: PropTypes.shape({
                maxAttemptsCount: PropTypes.number,
                timeout: PropTypes.number,
                expirationPeriod: PropTypes.number,
            }),
            second: PropTypes.shape({
                maxAttemptsCount: PropTypes.number,
                timeout: PropTypes.number,
                expirationPeriod: PropTypes.number,
            }),
            email: PropTypes.shape({
                codeLength: PropTypes.number,
                expirationPeriod: PropTypes.number,
            }),
            userLifetime: PropTypes.number,
            installerAllowedToRemovePanels: PropTypes.bool,
        }),
    }

    get rules() {
        return {
            'first.maxAttemptsCount': {
                presence: {},
                numericality: {
                    onlyInteger: true,
                    lessThanOrEqualTo: 100,
                    greaterThanOrEqualTo: 1,
                },
            },
            'first.timeout': {
                presence: {},
                numericality: {
                    onlyInteger: true,
                    lessThanOrEqualTo: 65535,
                    greaterThanOrEqualTo: 0,
                },
            },
            'first.expirationPeriod': {
                presence: {},
                numericality: {
                    onlyInteger: true,
                    lessThanOrEqualTo: 65535,
                    greaterThanOrEqualTo: 0,
                },
            },
            'second.maxAttemptsCount': {
                presence: {},
                numericality: {
                    onlyInteger: true,
                    lessThanOrEqualTo: 100,
                    greaterThanOrEqualTo: 1,
                },
            },
            'second.timeout': {
                presence: {},
                numericality: {
                    onlyInteger: true,
                    lessThanOrEqualTo: 100000,
                    greaterThanOrEqualTo: 1,
                },
            },
            'second.expirationPeriod': {
                presence: {},
                numericality: {
                    onlyInteger: true,
                    lessThanOrEqualTo: 100000,
                    greaterThanOrEqualTo: 10,
                },
            },
            'email.codeLength': {
                presence: {},
                numericality: {
                    onlyInteger: true,
                    lessThanOrEqualTo: 255,
                    greaterThanOrEqualTo: 5,
                },
            },
            'email.expirationPeriod': {
                presence: {},
                numericality: {
                    onlyInteger: true,
                    lessThanOrEqualTo: 65535,
                    greaterThanOrEqualTo: 0,
                },
            },
            userLifetime: {
                presence: {},
                numericality: {
                    onlyInteger: true,
                    lessThanOrEqualTo: 30,
                    greaterThanOrEqualTo: 1,
                },
            },
        }
    }

    handle = (data) => {
        const {handle} = this.props
        handle(data)
    }

    render() {
        const {data, ...props} = this.props
        return (
            <ModalCardForm
                onSubmit={this.handle}
                rules={this.rules}
                header={__('Authorization Settings')}
                confirmOnDismiss
                {...props}
            >
                <Input
                    label={__('First factor expiration period [Seconds]')}
                    name="first.expirationPeriod"
                    defaultValue={data.first.expirationPeriod}
                />

                <Input
                    label={__('Email code expiration period for Installer App [seconds]')}
                    name="email.expirationPeriod"
                    defaultValue={data.email.expirationPeriod}
                />

                <Input
                    label={__('Email code length for Installer App')}
                    name="email.codeLength"
                    defaultValue={data.email.codeLength}
                />

                <Input
                    label={__('Account login attempts limit')}
                    name="first.maxAttemptsCount"
                    defaultValue={data.first.maxAttemptsCount}
                />

                <Input
                    label={__('Account login lock timeout [seconds]')}
                    name="first.timeout"
                    defaultValue={data.first.timeout}
                />

                <Input
                    label={__('Panel login attempts limit')}
                    name="second.maxAttemptsCount"
                    defaultValue={data.second.maxAttemptsCount}
                />

                <Input
                    label={__('Panel login lock timeout [Seconds]')}
                    name="second.timeout"
                    defaultValue={data.second.timeout}
                />

                <Input
                    label={__('Second factor expiration period [Seconds]')}
                    name="second.expirationPeriod"
                    defaultValue={data.second.expirationPeriod}
                />

                <Input
                    label={__('Permanent user lifetime [Days]')}
                    name="userLifetime"
                    defaultValue={data.userLifetime}
                />

                <Checkbox
                    label={__('Enable Allow Installer Remove Panels')}
                    name="installerAllowedToRemovePanels"
                    defaultChecked={data.installerAllowedToRemovePanels}
                />
            </ModalCardForm>
        )
    }
}

export default compose(withForm(setSystemSettings.AuthorizationSettings))(
    AuthorizationSettings
)
