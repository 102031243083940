import {selectPerPage} from 'modules/settings/selectors'
import {takeEvery, all, put, call, select} from 'redux-saga/effects'
import toIds from 'utils/toIds'

import * as actions from './actions'
import {update, purge} from 'modules/roles/store/actions'
import * as api from 'api/roles'

import {POLL_ROLES} from 'configs/pollers'

import {snackShow} from 'modules/snacks'
import {addRole, removeRole} from 'modules/forms/handlers'
import listSaga from 'modules/higherOrder/createListSaga'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import {deselectAll} from 'modules/selection/actions'

import {__} from 'utils/i18n'

const selector = (state) => ({
    ...state.roles.list,
    perPage: selectPerPage(state, 'roles'),
    listRoute: 'roles',
})

export default function* () {
    yield all([
        listSaga(api, actions, update, selector, purge),
        createListPollerSaga(actions, POLL_ROLES, watchPoll),
        takeEvery(addRole.SUCCESS, watchAddRole),
        takeEvery(removeRole.REQUEST, watchRemoveRole),
    ])
}

function* watchAddRole() {
    yield put(actions.fetch())
    yield put(snackShow(__('Role was created successfully')))
}

function* watchPoll() {
    const state = yield select(selector)

    if (state.start !== 0) {
        return
    }

    try {
        const {rows, count} = yield call(api.fetch, state)

        yield put(update(rows))
        yield put(actions.receive(toIds(rows), count))
    } catch (err) {
        yield put(actions.receive(err))
    }
}

function* watchRemoveRole({payload, meta}) {
    yield put(deselectAll())
}
