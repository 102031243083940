import {connect} from 'react-redux'
import {createSelector} from 'reselect'

const selector = createSelector(
    (state) => state.permissions,
    (state, props) => props.columns,
    (permissions, columns) =>
        columns.filter((column) => !column.permission || column.permission(permissions))
)

export default function withTableColumnsPermission() {
    return connect(
        ({auth: {permissions}}) => ({permissions}),
        null,
        (state, _, props) => ({
            ...props,
            columns: selector(state, props),
        })
    )
}
