import {compose} from 'redux'

import walktest from 'permissions/panel/devices/walktest'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withDevices from 'containers/withDevices'
import withProps from 'containers/withProps'

import {ReactComponent as WalkIcon} from 'ipmp-react-ui/icons/walk.svg'
import {ReactComponent as DiagnosticIcon} from 'ipmp-react-ui/icons/build.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import {withFeatureRejection} from 'containers/withFeature'
import {selectWalktestFeature} from 'modules/features/store/selectors'
import Void from 'components/Void'

export default compose(
    withPermission({isVisible: walktest}),
    withVisibility(),
    withDevices(),
    withProps(({showWalktest, toggleWalktest}) => ({
        onClick: toggleWalktest,
        Icon: showWalktest ? DiagnosticIcon : WalkIcon,
        label: showWalktest ? __('Diagnostics') : __('Walktest'),
    })),
    withFeatureRejection(selectWalktestFeature, Void)
)(Button)
