import React from 'react'
import Account from './Field/Account'

import Group from './Field/Group'
import Serial from './Field/Serial'
import SimNumber from './Field/SimNumber'

export class Fieldset extends React.Component {
    render() {
        const {isNew, data} = this.props

        return (
            <div className="form-field">
                <Serial isNew={isNew} serial={data.serial} />
                <Account isNew={isNew} account={data.account} />
                <Group groupName={data.group} groupId={data.groupId} />
                <SimNumber simNumber={data.simNumber} />
            </div>
        )
    }
}

export const rules = {
    groupId: {
        presence: true,
    },
    simNumber: {
        phone: true,
    },
}
