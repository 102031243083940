import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import {TriggerDiscoveryItem} from 'pages/Panels/GridBar/DropDowns/Items/TriggerDiscoveryItem'

import {showTriggerDiscoveryModal} from 'modules/runners/store/actions'

export default compose(
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                onClick: showTriggerDiscoveryModal,
            },
            dispatch
        )
    )
)(TriggerDiscoveryItem)
