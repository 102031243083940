import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {EventRuleStep, getEventRuleStepsClassNames, steps} from 'constants/eventRules'
import EventRuleStepIcon from 'modals/EventRule/Fieldset/EventRuleStepIcon'

class EventRuleSteps extends Component {
    static propTypes = {
        currentStep: PropTypes.instanceOf(EventRuleStep),
    }

    get className() {
        const {currentStep} = this.props

        return classnames(
            'eventRules-master-steps',
            getEventRuleStepsClassNames(currentStep.currentStep)
        )
    }

    renderSteps() {
        return (
            <div className="eventRules-master-steps-container">
                {steps.map((step) => (
                    <EventRuleStepIcon key={step} step={step} />
                ))}
            </div>
        )
    }

    render() {
        return (
            <header className={this.className}>
                <span className="eventRules-master-steps-line" />
                <span className="eventRules-master-steps-line" />
                <span className="eventRules-master-steps-line" />
                <span className="eventRules-master-steps-line" />
                <span className="eventRules-master-steps-arrow eventRules-master-steps-arrow__first" />
                <span className="eventRules-master-steps-arrow eventRules-master-steps-arrow__second" />
                {this.renderSteps()}
            </header>
        )
    }
}

export default EventRuleSteps
