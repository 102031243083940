import {createSelector} from 'reselect'

export const selectInstallerPanelsList = createSelector(
    (state) => state.installerPanels.list,
    (state) => state.installerPanels.store.byIds,
    ({page, isLoading}, store) => ({
        rows: page.map((id) => store[id] || {}),
        isLoading,
    })
)
