import React, {Component} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'

import {Line, Bar} from 'react-chartjs-2'

import {__} from 'utils/i18n'
import momentPropType from 'utils/momentPropType'

import {humanTime} from 'ipmp-react-ui/humanTime'

import {BRIGHTNESS, GSM_RSSI, TEMPERATURE} from 'constants/meteoTypes'
import {
    getGsmRssiLevelTitle,
    GSM_RSSI_STRONG_LEVEL_INTERVAL,
    GSM_RSSI_UNKNOWN_LEVEL_VALUE,
} from 'constants/gsmRssiLevel'

export default class Chart extends Component {
    _yDefaultThreshold = {
        [BRIGHTNESS]: 1300,
        [TEMPERATURE]: 40,
        [GSM_RSSI]: GSM_RSSI_STRONG_LEVEL_INTERVAL.top,
    }

    static propTypes = {
        type: PropTypes.oneOf([TEMPERATURE, BRIGHTNESS, GSM_RSSI]),
        range: PropTypes.shape({
            from: momentPropType,
            to: momentPropType,
        }).isRequired,

        data: PropTypes.shape({
            min: PropTypes.array,
            max: PropTypes.array,
            avg: PropTypes.array,
            exact: PropTypes.array,
            yThreshold: PropTypes.number,
        }).isRequired,

        label: PropTypes.string,
    }

    constructor(props) {
        super(props)
        this.state = {
            yThreshold: get(props, 'data.yThreshold', this.yDefaultThreshold),
        }
    }

    get yDefaultThreshold() {
        const {type} = this.props
        return this._yDefaultThreshold[type] || this._yDefaultThreshold[TEMPERATURE]
    }

    get legacyDataset() {
        const {
            label,
            data: {min, max, avg, exact},
        } = this.props

        const styles = {
            min: {
                label: __('Minimum'),
                fill: '+2',
                pointRadius: 1,
                pointHitRadius: 2,
                data: min || [],
            },
            avg: {
                label: __('Average'),
                pointRadius: 3,
                pointHitRadius: 3,
                borderColor: '#2d72b9',
                pointBorderWidth: 1,
                pointBorderColor: 'white',
                pointBackgroundColor: '#2d72b9',
                fill: false,
                data: avg || [],
            },
            max: {
                label: __('Maximum'),
                pointRadius: 1,
                pointHitRadius: 2,
                fill: false,
                data: max || [],
            },
            exact: {
                label,
                pointRadius: 3,
                pointHitRadius: 3,
                borderColor: '#2d72b9',
                pointBorderWidth: 1,
                pointBorderColor: 'white',
                pointBackgroundColor: '#2d72b9',
                fill: false,
                data: exact || [],
            },
        }

        if (exact) {
            return {
                datasets: [styles.exact],
            }
        }

        return {
            datasets: [styles.min, styles.avg, styles.max],
        }
    }

    get gsmRssiDataset() {
        const {
            label,
            data: {exact},
        } = this.props

        const data = exact || []

        return {
            labels: data.map(({time}) => humanTime(time)),
            datasets: [
                {
                    label,
                    data: data.map(({value}) => value),
                    backgroundColor: '#2d72b9',
                },
            ],
        }
    }

    get dataset() {
        const {type} = this.props

        switch (type) {
            case GSM_RSSI:
                return this.gsmRssiDataset
            case BRIGHTNESS:
            case TEMPERATURE:
            default:
                return this.legacyDataset
        }
    }

    defs = {
        animation: false,
        legend: false,
    }

    titleCallback = (item) => {
        const {xLabel} = Array.isArray(item) ? item[0] : item

        return xLabel
            .replace('GMT+00:00', 'GMT')
            .replace(/(.*)(GMT[+-]?)0?(\d+)(:[1-9]\d)?.*/, '$1$2$3$4')
    }

    tooltipFormat = 'llll [GMT]Z'
    displayFormats = {
        hour: 'LT',
    }

    get temperatureOptions() {
        const {
            range: {from, to},
            label,
        } = this.props
        const {yThreshold} = this.state

        return {
            ...this.defs,
            tooltips: {
                callbacks: {
                    title: this.titleCallback,
                    label: (item) => {
                        const {yLabel} = Array.isArray(item) ? item[0] : item

                        return `${Number(yLabel).toFixed(1)} ` + label
                    },
                },
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            suggestedMax: yThreshold,
                            suggestedMin: 0,
                        },
                    },
                ],
                xAxes: [
                    {
                        type: 'time',
                        distribution: 'linear',
                        ticks: {
                            min: from,
                            max: to,
                            tooltipFormat: this.tooltipFormat,
                            displayFormats: this.displayFormats,
                        },
                    },
                ],
            },
        }
    }

    get brightnessOptions() {
        const {
            range: {from, to},
            label,
        } = this.props
        const {yThreshold} = this.state

        return {
            ...this.defs,
            tooltips: {
                callbacks: {
                    title: this.titleCallback,
                    label: (item) => {
                        const {yLabel} = Array.isArray(item) ? item[0] : item

                        return `${Number(yLabel).toFixed(1)}  ` + label
                    },
                },
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            suggestedMax: yThreshold,
                            suggestedMin: 0,
                        },
                    },
                ],
                xAxes: [
                    {
                        type: 'time',
                        distribution: 'linear',
                        ticks: {
                            min: from,
                            max: to,
                            tooltipFormat: this.tooltipFormat,
                            displayFormats: this.displayFormats,
                        },
                    },
                ],
            },
        }
    }

    get gsmRssiOptions() {
        const {label} = this.props
        const {yThreshold} = this.state

        return {
            ...this.defs,
            tooltips: {
                callbacks: {
                    title: this.titleCallback,
                    label: (item) => {
                        const {yLabel} = Array.isArray(item) ? item[0] : item
                        const levelTitle = getGsmRssiLevelTitle(yLabel)

                        return yLabel === GSM_RSSI_UNKNOWN_LEVEL_VALUE
                            ? `${levelTitle} ${label}`
                            : `${yLabel} ${levelTitle} ${label}`
                    },
                },
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            suggestedMax: yThreshold,
                            stepSize: 1,
                            suggestedMin: 0,
                            callback: (value, index, values) => {
                                if (value === 0) {
                                    return ''
                                }
                                return `${getGsmRssiLevelTitle(value)}`
                            },
                        },
                    },
                ],
            },
        }
    }

    get options() {
        const {type} = this.props

        switch (type) {
            case BRIGHTNESS:
                return this.brightnessOptions
            case GSM_RSSI:
                return this.gsmRssiOptions
            case TEMPERATURE:
            default:
                return this.temperatureOptions
        }
    }

    render() {
        const {type} = this.props
        const data = this.dataset
        const options = this.options

        if (type === GSM_RSSI) {
            return <Bar data={data} options={options} />
        }

        return <Line data={data} options={options} />
    }
}
