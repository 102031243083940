import {compose} from 'redux'

import {ReviewButton} from 'pages/RemoteInspection/Content/Result/Header/ReviewButton'
import {withPanelRemoteInspectionsResult} from 'containers/withRemoteInspectionsResult'
import withProps from 'containers/withProps'
import __ from 'utils/i18n'

export default compose(
    withPanelRemoteInspectionsResult(),
    withProps(({review, remoteInspectionResult}) => ({
        borderless: true,
        onClick: review,
        disabled: !remoteInspectionResult || remoteInspectionResult.isReviewed,
        label: __('Mark reviewed'),
    }))
)(ReviewButton)
