import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {init, reset, startPoll, stopPoll} from 'modules/stateTypes/list/actions'
import {selectStateTypesListRows} from 'modules/stateTypes/list/selectors'

const mapStateToProps = () => selectStateTypesListRows

const mapDispatchToProps = () => (dispatch) =>
    bindActionCreators(
        {
            init,
            reset,
            startPoll,
            stopPoll,
        },
        dispatch
    )

export default function withStateTypes() {
    return compose(connect(mapStateToProps, mapDispatchToProps))
}
