import React, {PureComponent} from 'react'
import timezones from 'utils/timezones'

import InputDatePicker from 'ipmp-react-ui/InputDatePicker'
import {__} from 'utils/i18n'

// It's not clear that format always be the same,
// but we don't have information about date format
const dateFormat = 'MM/DD/YY'
const empty = '00/00/00'

const parseDateValue = (value) => {
    if (value === empty) {
        return null
    }

    return timezones.server(value, dateFormat)
}

export default class DateValue extends PureComponent {
    handleChange = (value) => {
        this.props.onChange(value ? value.format(dateFormat) : empty)
    }

    render() {
        return (
            <InputDatePicker
                placeholder={__('Not set')}
                value={parseDateValue(this.props.value)}
                onChange={this.handleChange}
            />
        )
    }
}
