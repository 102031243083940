import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {selectDashboard} from 'modules/dashboard/selectors'
import {selectAllProcesses} from 'modules/dashboard/allProcesses/selectors'
import {selectConnectedPanels} from 'modules/dashboard/connectedPanels/selectors'
import {selectEventsRate} from 'modules/dashboard/eventsRate/selectors'
import {selectOnline} from 'modules/dashboard/online/selectors'

import {
    fetch as fetchConnectedPanels,
    startPoll as startPollConnectedPanels,
    stopPoll as stopPollConnectedPanels,
} from 'modules/dashboard/connectedPanels/actions'
import {
    fetch as fetchEventsRate,
    startPoll as startPollEventsRate,
    stopPoll as stopPollEventsRate,
} from 'modules/dashboard/eventsRate/actions'
import {
    fetch as fetchOnline,
    startPoll as startPollOnline,
    stopPoll as stopPollOnline,
} from 'modules/dashboard/online/actions'
import {
    fetch as fetchAllProcesses,
    startPoll as startPollAllProcesses,
    stopPoll as stopPollAllProcesses,
} from 'modules/dashboard/allProcesses/actions'

export default function withDashboard() {
    return compose(
        connect(selectDashboard, (dispatch) =>
            bindActionCreators(
                {
                    fetchConnectedPanels,
                    startPollConnectedPanels,
                    stopPollConnectedPanels,
                    fetchEventsRate,
                    startPollEventsRate,
                    stopPollEventsRate,
                    fetchOnline,
                    startPollOnline,
                    stopPollOnline,
                    fetchAllProcesses,
                    startPollAllProcesses,
                    stopPollAllProcesses,
                },
                dispatch
            )
        )
    )
}

export function withConnectedPanels() {
    return compose(
        connect(selectConnectedPanels, (dispatch) =>
            bindActionCreators(
                {
                    fetchConnectedPanels,
                    startPollConnectedPanels,
                    stopPollConnectedPanels,
                },
                dispatch
            )
        )
    )
}

export function withEventsRate() {
    return compose(
        connect(selectEventsRate, (dispatch) =>
            bindActionCreators(
                {
                    fetchEventsRate,
                    startPollEventsRate,
                    stopPollEventsRate,
                },
                dispatch
            )
        )
    )
}

export function withOnline() {
    return compose(
        connect(selectOnline, (dispatch) =>
            bindActionCreators(
                {
                    fetchOnline,
                    startPollOnline,
                    stopPollOnline,
                },
                dispatch
            )
        )
    )
}

export function withAllProcesses() {
    return compose(
        connect(selectAllProcesses, (dispatch) =>
            bindActionCreators(
                {
                    fetchAllProcesses,
                    startPollAllProcesses,
                    stopPollAllProcesses,
                },
                dispatch
            )
        )
    )
}
