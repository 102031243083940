import React from 'react'
import {compose} from 'redux'

import Table from 'ipmp-react-ui/Table'

import PanelCell from 'components/TableCells/PanelCell'
import GroupCell from 'components/TableCells/GroupCell'

import __ from 'utils/i18n'
import withProps from 'containers/withProps'
import withInteractiveUserPanelsTable from 'containers/withInteractiveUserPanelsTable'
import withLoader from 'containers/withLoader'
import DisconnectPanelCell from './TableCell/DisconnectPanelCell'

const columns = [
    {
        name: () => __('Panel'),
        width: 224,
        render: ({id, serial, account}) => (
            <PanelCell to="interactiveUsers" {...{id, serial, account}} />
        ),
    },
    {
        name: () => __('Group'),
        maxWidth: 132,
        render: ({groupId: id, group: name}) => <GroupCell {...{row: {id, name}}} />,
    },
    {
        name: () => __('Role'),
        maxWidth: 120,
        render: ({role}) => <strong>{role}</strong>,
    },
    {
        maxWidth: 120,
        name: () => __('Label'),
        render: ({label}) => <strong>{label}</strong>,
    },
    {
        tooltip: false,
        fixed: true,
        width: 56,
        component: DisconnectPanelCell,
    },
]

export default compose(
    withInteractiveUserPanelsTable(),
    withLoader(({fetch}) => fetch()),
    withProps(() => ({
        columns,
        className: 'interactiveUserBlind-table',
    }))
)(Table)
