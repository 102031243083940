import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {init, reset, startPoll, stopPoll} from 'modules/notificationTypes/list/actions'
import {selectNotificationTypesListRows} from 'modules/notificationTypes/list/selectors'

const mapStateToProps = () => selectNotificationTypesListRows

const mapDispatchToProps = () => (dispatch) =>
    bindActionCreators(
        {
            init,
            reset,
            startPoll,
            stopPoll,
        },
        dispatch
    )

export default function withZoneTypes() {
    return compose(connect(mapStateToProps, mapDispatchToProps))
}
