import {get} from 'api/http'

import {mapRemoteInspection} from 'api/remoteInspection'

import {mapRemoteInspectionResult} from 'api/remoteInspectionResult'

export function fetch(panelId, from, to, start, count = 10) {
    return get('/unit_remoteinspection_results/getall', {
        unt_id: panelId,
        from,
        to,
        start,
        count,
    }).then(({inspection, rows, process, count}) => ({
        inspection: mapRemoteInspection({...inspection, process}),
        results: rows.map(mapRemoteInspectionResult),
        count,
    }))
}
