import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Button from 'ipmp-react-ui/Button'
import Spinner from 'ipmp-react-ui/Spinner'
import {ReactComponent as IconGoBack} from 'ipmp-react-ui/icons/go-back.svg'
import {ReactComponent as IconGoBackRtl} from 'ipmp-react-ui/icons/go-back-rtl.svg'
import isRtl from 'ipmp-react-ui/_utils/isRtl'
import Definition from 'ipmp-react-ui/Definition'
import {humanDate} from 'ipmp-react-ui/humanTime/humanTime'
import path from 'utils/path'
import RunnersPager from './GridBar/RunnersPager'
import withRunnerGridBar from 'containers/withRunnerGridBar'
import RunnersDropDown from './GridBar/RunnersDropDown'
import ServiceDropDown from './GridBar/DropDowns/ServiceDropDown'
import CreateReportButton from './GridBar/Buttons/CreateReportButton'
import InitiateRemoteInspectionButton from './GridBar/Buttons/InitiateRemoteInspectionButton'
import RunnerStopButton from './GridBar/Buttons/RunnerStopButton'

class RunnersGridBar extends Component {
    static propTypes = {
        history: PropTypes.object,
        batchInfo: PropTypes.shape({
            started: PropTypes.instanceOf(Date),
            batchType: PropTypes.string,
        }),
    }

    handleGoBack = () => {
        const {history} = this.props
        history.push(path('batches', {id: null}))
    }

    renderTitle = () => {
        const {batchInfo} = this.props

        if (!batchInfo) {
            return <Spinner />
        }

        return (
            <Definition
                className="title"
                title={batchInfo.batchType}
                detail={humanDate(batchInfo.started)}
                multiLine
            />
        )
    }

    render() {
        const IconBack = isRtl() ? IconGoBackRtl : IconGoBack

        return (
            <Fragment>
                <Bar>
                    <Button
                        shortcut="backspace"
                        onClick={this.handleGoBack}
                        className="btn--goBack"
                    >
                        <IconBack />
                    </Button>
                    {this.renderTitle()}

                    <BarSpace />

                    <RunnersPager />
                </Bar>
                <Bar>
                    <RunnersDropDown />
                    <ServiceDropDown />
                    <CreateReportButton />
                    <InitiateRemoteInspectionButton />
                    <RunnerStopButton />
                </Bar>
            </Fragment>
        )
    }
}

export default compose(withRunnerGridBar())(RunnersGridBar)
