import {__} from 'utils/i18n'

export const TEMP_UNIT_F = 'F'
export const TEMP_UNIT_C = 'C'
export const DEFAULT_TEMP_UNIT = TEMP_UNIT_C

const list = [
    {key: TEMP_UNIT_C, name: () => __('Celsius'), label: '℃'},
    {key: TEMP_UNIT_F, name: () => __('Fahrenheit'), label: '°F'},
]

export function getTemperatureUnits() {
    return list
}

export function temperatureConverter(celsium, unit) {
    if (unit == TEMP_UNIT_F) {
        return (celsium * 9) / 5 + 32
    }
    return celsium
}

export function temperatureKeyToLabel(key) {
    const unit = list.find((unit) => unit.key == key)
    if (!unit) {
        return ''
    }
    return unit.label
}
