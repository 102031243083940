import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import withLifeCycle from 'containers/withLifeCycle'
import withOptions from 'containers/withOptions'

export default function withSelectLoader(
    fetchActionCreator: (
        prefix: String,
        maxOptionsToShow: Number,
        props: Object
    ) => Object,
    mapStateToIsLoading: (state: Object, props: Object) => Boolean,
    mapStateToOptions: (state: Object, props: Object) => Object,
    maxOptionsToShow?: Number,
    fetchOnMount?: Boolean
) {
    const prefixes = []

    return compose(
        connect(
            (state, props) => ({
                isLoading: mapStateToIsLoading(state, props),
                maxOptionsToShow,
            }),
            (dispatch, props) =>
                bindActionCreators(
                    {
                        fetch: (prefix = '', values) =>
                            fetchActionCreator(
                                prefix,
                                maxOptionsToShow ? maxOptionsToShow + 1 : undefined,
                                {
                                    values,
                                    ...props,
                                }
                            ),
                    },
                    dispatch
                )
        ),
        withLifeCycle(
            {
                onMount({fetch, defaultValues}) {
                    if (typeof fetchOnMount === 'boolean') {
                        fetchOnMount && fetch('', defaultValues)
                    } else {
                        fetch('', defaultValues)
                    }
                },
            },
            ({fetch, ...props}) => {
                if (!maxOptionsToShow) {
                    return props
                }

                return {
                    ...props,
                    onType(e, prefix, values) {
                        if (!prefixes.includes(prefix)) {
                            prefixes.push(prefix)
                            fetch(prefix, values)
                        }
                    },
                }
            }
        ),
        withOptions(mapStateToOptions)
    )
}
