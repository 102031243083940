import {compose} from 'redux'

import {remove} from 'permissions/centralStations/selection'

import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'
import {withSelectionHandler} from 'containers/withSelection'
import withCentralStations from 'containers/withCentralStations'
import withConfirmation from 'containers/withConfirmation'
import withProps from 'containers/withProps'

import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: remove}),
    withVisibility(),
    withCentralStations(),
    withProps(({remove}) => ({
        onClick: remove,
        Icon: IconRemove,
        label: __('Remove'),
        title: __('Removing central stations'),
        message: __('Do you really want to remove selected central stations?'),
    })),
    withSelectionHandler(),
    withConfirmation()
)(Button)
