import {__} from 'utils/i18n'

export default function formatPartitions(partitions) {
    if (partitions.length < 5) {
        if (partitions[0] === -1) {
            return [__('All')]
        }
        return partitions
    }

    return partitions
        .reduce((grouped, partition) => {
            if (grouped.length === 0) {
                grouped.push([partition])
            } else {
                let lastPartition =
                    grouped[grouped.length - 1][grouped[grouped.length - 1].length - 1]
                if (partition - lastPartition === 1) {
                    grouped[grouped.length - 1].push(partition)
                } else {
                    grouped.push([partition])
                }
            }
            return grouped
        }, [])
        .reduce((result, group) => {
            if (group.length < 3) {
                result = result.concat(group)
            } else {
                result.push(group[0] + '-' + group[group.length - 1])
            }
            return result
        }, [])
}
