import {BAD_REQUEST_PARAMS} from 'constants/errorType'
import {takeEvery, call, fork, select, put, all, take} from 'redux-saga/effects'
import {eventChannel} from 'redux-saga'

import * as commands from 'modules/auth/commands'
import * as actions from 'modules/auth/sign/actions'
import * as api from 'api/auth'

import {LOGIN_SCREEN} from 'constants/loginScreen'

import {snackShow} from 'modules/snacks'
import {setLoginScreenForm} from 'modules/auth/sign/actions'
import * as settingsActions from 'modules/settings/actions'
import {restoreLanguage, setLanguage} from 'modules/locales/saga'
import {editProfile, remindPassword} from 'modules/forms/handlers'
import {update as updatePermissions} from 'modules/auth/permissions/actions'

import {__} from 'utils/i18n'

import AuthWorker from 'modules/auth/auth.worker'

export default function* () {
    yield all([
        authWorkerSaga(),
        takeEvery(remindPassword.FAILURE, watchRemindPassword),
        takeEvery(remindPassword.SUCCESS, watchRemindPasswordSuccess),
        takeEvery(actions.loggedOut, clearAuthCookie),
    ])
}

function clearAuthCookie() {
    document.cookie = document.cookie
        .split(';')
        .filter((s) => !s.match(/^ipmp2=/))
        .join(';')
}

function* authWorkerSaga() {
    const worker = new AuthWorker()
    worker.port.start()
    const channel = yield call(createChannel, worker.port)
    yield fork(sendMessages, worker.port)

    while (true) {
        const {command, data} = yield take(channel)

        switch (command) {
            case commands.COMMAND_LOGGED_IN:
                yield setUserData(data)
                break

            case commands.COMMAND_LOGGED_OUT:
                yield put(actions.loggedOut())
                break

            case commands.COMMAND_NO_AUTH:
                yield restoreLanguage()
                yield put(actions.checked())
                break

            case commands.COMMAND_UPDATED:
                if (data.user) {
                    yield put(actions.update(data.user))
                }

                if (data.settings) {
                    yield put(settingsActions.update(data.settings))
                }

                if (data.permissions) {
                    yield put(snackShow(__('Your privileges was changed')))
                    yield put(updatePermissions(data.permissions))
                }
                break
        }
    }
}

function createChannel(port) {
    return eventChannel((emit) => {
        port.onmessageerror = (e) => {
            console.error('auth received error', e)
        }

        port.onmessage = ({data, command}) => {
            emit(data)
        }

        return () => port.close()
    })
}

function* sendMessages(port) {
    if (!window.addEventListener) {
        // running in tests
        return
    }

    window.addEventListener('unload', () => {
        port.postMessage({
            command: commands.COMMAND_CLOSE_PORT,
        })
    })

    const interact = () => {
        port.postMessage({
            command: commands.COMMAND_INTERACT,
        })
    }

    window.addEventListener('mousedown', interact, true)
    window.addEventListener('keydown', interact, true)

    yield all([
        takeEvery(actions.login, watchLogin, port),
        takeEvery(actions.logout, watchLogout, port),
        takeEvery(editProfile.SUCCESS, watchEditProfile, port),
        takeEvery(Object.values(settingsActions), watchChangeSettings, port),
    ])
}

function* watchLogin(port, {payload: {email, password}}) {
    try {
        const data = yield call(api.login, email, password)

        if (port) {
            port.postMessage({
                command: commands.COMMAND_LOGGED_IN,
                data,
            })
        }

        yield setUserData(data)
    } catch (error) {
        if (error.type === BAD_REQUEST_PARAMS) {
            yield put(actions.receive(error))
        } else {
            yield put(snackShow(error.message))
            yield put(actions.setLoading(false))
        }
    }
}

function* watchLogout(port) {
    if (port) {
        port.postMessage({command: commands.COMMAND_LOGGED_OUT})
    }

    yield put(actions.loggedOut())
}

function* watchChangeSettings(port, action) {
    if (!port || action.type === settingsActions.update.toString()) {
        return
    }

    const settings = yield select((state) => state.settings)

    port.postMessage({
        command: commands.COMMAND_UPDATED,
        data: {settings},
    })
}

function* setUserData({settings, permissions, user}) {
    yield put(settingsActions.update(settings))
    yield put(updatePermissions(permissions))

    const {language} = yield select(({settings}) => settings)
    yield setLanguage(language)

    yield put(actions.receive(user))
    yield put(actions.loggedIn())
}

function* watchEditProfile(port, {meta: {phone, countryId}}) {
    const countryList = yield select((state) => state.countries.byIds)
    const countryName = countryId !== null ? countryList[countryId].name : null

    const user = {
        phone,
        countryId,
        countryName,
    }

    yield put(actions.update(user))

    if (port) {
        port.postMessage({
            command: commands.COMMAND_UPDATED,
            data: {user},
        })
    }
}

function* watchRemindPassword({payload: {error}}) {
    if (error) {
        yield put(snackShow(error))
    }
}

function* watchRemindPasswordSuccess() {
    yield put(snackShow(__('Password notification sent')))
    yield put(setLoginScreenForm(LOGIN_SCREEN))
}
