import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {CreateReportButton} from 'pages/Panels/GridBar/Buttons/CreateReportButton'

import {showCreateReportModal} from 'modules/runners/store/actions'
import {withSelectionFeature} from 'containers/withFeature'
import {REPORTS} from 'constants/features'
import {selectSelectedRunnersFeature} from 'modules/panels/store/selectors'

export default compose(
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                onClick: showCreateReportModal,
            },
            dispatch
        )
    ),
    withSelectionFeature(REPORTS, selectSelectedRunnersFeature)
)(CreateReportButton)
