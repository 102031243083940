import {combineReducers} from 'redux'

import store from './store/reducer'
import list from './list/reducer'
import assignableRoles from './assignableRoles/reducer'
import maintainers from './maintainers/reducer'

export default combineReducers({
    store,
    list,
    assignableRoles,
    maintainers,
})
