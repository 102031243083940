import {createAction} from 'redux-actions'

export const startPollRunnerData = createAction(
    'RUNNERS/ONE/START_POLL_DATA',
    (runnerId, callback) => ({runnerId, callback})
)
export const stopPollRunnerData = createAction(
    'RUNNERS/ONE/STOP_POLL_DATA',
    (runnerId) => ({runnerId})
)
