import {handleActions} from 'redux-actions'
import {set} from 'immutable-modify'
import {update, fetch} from './actions'

const defaultState = {
    list: null,
    isLoading: false,
}

export default handleActions(
    {
        [fetch](state) {
            return set(state, 'isLoading', true)
        },

        [update](state, {payload, error}) {
            if (error) {
                return {
                    ...state,
                    isLoading: false,
                    error: payload,
                }
            }

            return {
                ...state,
                isLoading: false,
                error: null,
                list: payload,
            }
        },
    },
    defaultState
)
