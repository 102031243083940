export const KEY_OFF = 0x22
export const KEY_UP = 0x20
export const KEY_ESC = 0x21
export const KEY_LEFT = 0x0f
export const KEY_OK = 0x10
export const KEY_RIGHT = 0x12

export const KEY_1 = 0x01
export const KEY_2 = 0x02
export const KEY_3 = 0x03
export const KEY_4 = 0x04
export const KEY_5 = 0x05
export const KEY_6 = 0x06
export const KEY_7 = 0x07
export const KEY_8 = 0x08
export const KEY_9 = 0x09
export const KEY_STAR = 0x0d
export const KEY_0 = 0x00
export const KEY_HASH = 0x0e
export const KEY_DISARM = 0x0c
export const KEY_ARM_HOME = 0x0b
export const KEY_ARM_AWAY = 0x0a
export const KEY_EMERGENCY = 0x15
export const KEY_PANIC = 0x11
export const KEY_FIRE = 0x14

export const KEY_STAY = 'STAY'
export const KEY_AWAY = 'AWAY'
export const KEY_CHIME = 'CHIME'
export const KEY_RESET = 'RESET'
export const KEY_EXIT = 'EXIT'

export const convertKeyToName = (key) => {
    switch (parseInt(key)) {
        case KEY_OFF:
            return 'RECORD OFF'
        case KEY_UP:
            return 'UP'
        case KEY_ESC:
            return 'ESC'
        case KEY_LEFT:
            return 'BACK'
        case KEY_OK:
            return 'OK'
        case KEY_RIGHT:
            return 'NEXT'

        case KEY_STAR:
            return '*'
        case KEY_HASH:
            return '#'

        case KEY_DISARM:
            return 'DISARM'
        case KEY_ARM_HOME:
            return 'HOME'
        case KEY_ARM_AWAY:
            return 'AWAY'
        case KEY_EMERGENCY:
            return 'EMERGENCY'
        case KEY_PANIC:
            return 'PANIC'
        case KEY_FIRE:
            return 'FIRE ALARM'
        default:
            return key
    }
}
