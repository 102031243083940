import {compose} from 'redux'

import {InitiateButton} from 'pages/RemoteInspection/Content/Result/Header/InitiateButton'
import withProps from 'containers/withProps'
import __ from 'utils/i18n'
import withConfirmation from 'containers/withConfirmation'
import withPanelRemoteInspection from 'containers/withPanelRemoteInspection'

export default compose(
    withPanelRemoteInspection(),
    withProps(({initiateNow, remoteInspection}) => {
        return {
            disabled: remoteInspection && remoteInspection.progress !== null,
            onClick: initiateNow,
            label:
                remoteInspection &&
                remoteInspection.result === 'progress' &&
                remoteInspection.progress === 0
                    ? __('Pending...')
                    : __('Run'),
            title: __('Initiate Inspection'),
            message: __('Do you want to initiate remote inspection now?'),
            positive: __('Begin now'),
            borderless: true,
        }
    }),
    withConfirmation()
)(InitiateButton)
