import {
    VENDOR_FIBRO_TRANSMITTER,
    VENDOR_NEO,
    VENDOR_POWER_MASTER,
    VENDOR_DUAL_PATH,
    VENDOR_QOLSYS,
} from 'constants/panelVendorType'
import {__} from 'utils/i18n'
import pickBy from 'lodash/pickBy'
import * as selectors from 'modules/features/store/selectors'

const tabs = {
    devices: {
        label: () => __('Devices'),
        path: 'panel.devices',
        exact: true,
        selector: selectors.selectDevicesFeature,
    },
    configuration: {
        label: () => __('Configuration'),
        path: 'panel.configuration',
        selector: selectors.selectConfigutionFeature,
    },
    keypad: {
        label: () => __('Keypad'),
        path: 'panel.keypad',
        selector: selectors.selectKeypadFeature,
    },
    state: {
        label: () => __('State'),
        path: 'panel.state',
        selector: selectors.selectStateFeature,
    },
    events: {
        label: () => __('Events'),
        path: 'panel.events',
        selector: selectors.selectEventsFeature,
    },
    interactiveUsers: {
        label: () => __('Users'),
        path: 'panel.interactiveUsers',
    },
    installers: {
        label: () => __('Installers'),
        path: 'panel.installers',
    },
    log: {
        label: () => __('Logs'),
        path: 'panel.log',
        selector: selectors.selectLogsFeature,
    },
    locations: {
        label: () => __('Locations'),
        path: 'panel.locations',
        selector: selectors.selectLocationsFeature,
    },
    remoteInspections: {
        label: () => __('Remote Inspections'),
        path: 'panel.remoteInspections',
        selector: selectors.selectRemoteInspectionsFeature,
    },
    reports: {
        label: () => __('Reports'),
        path: 'panel.reports',
        selector: selectors.selectReportsFeature,
    },
    processes: {
        label: () => __('Processes'),
        path: 'panel.processes',
        selector: selectors.selectProcessesFeature,
    },
    firmware: {
        label: () => __('Firmware'),
        path: 'panel.firmware',
        selector: selectors.selectFirmwareFeature,
    },
    info: {
        label: () => __('Info'),
        path: 'panel.info',
    },
}

const allMenuItems = {
    devices: true,
    info: true,
    state: true,
    configuration: true,
    locations: true,
    processes: true,
    interactiveUsers: true,
    installers: true,
    reports: true,
    log: true,
    remoteInspections: true,
    firmware: true,
    events: true,
    keypad: true,
}

const neoMenuItems = {
    devices: true,
    info: true,
    state: true,
    configuration: true,
    locations: false,
    processes: true,
    interactiveUsers: true,
    installers: true,
    reports: true,
    log: true,
    remoteInspections: true,
    firmware: true,
    events: true,
    keypad: true,
}

const fibroMenuItems = {
    info: true,
    events: true,
}

const dualPathMenuItem = {
    devices: true,
    info: true,
    state: true,
    configuration: true,
    locations: true,
    processes: true,
    interactiveUsers: true,
    installers: false,
    reports: true,
    log: true,
    remoteInspections: true,
    firmware: true,
    events: true,
    keypad: true,
}

const qolsysMenuItem = {
    devices: true,
    info: true,
    state: true,
    configuration: true,
    locations: true,
    processes: true,
    interactiveUsers: true,
    installers: false,
    reports: true,
    log: true,
    remoteInspections: true,
    firmware: true,
    events: true,
    keypad: true,
}

export function getMenuItems(vendor) {
    switch (vendor) {
        case VENDOR_NEO:
            return neoMenuItems
        case VENDOR_FIBRO_TRANSMITTER:
            return fibroMenuItems
        case VENDOR_DUAL_PATH:
            return dualPathMenuItem
        case VENDOR_QOLSYS:
            return qolsysMenuItem
        case VENDOR_POWER_MASTER:
        default:
            return allMenuItems
    }
}

export function getItemsByKeys(keys) {
    return pickBy(tabs, (tab, key) => keys.indexOf(key) >= 0)
}
