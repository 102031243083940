export const POWER = 'POWER'
export const ARM = 'ARM'
export const CHIME = 'CHIME'
export const TROUBLE = 'TROUBLE'

export const COMMUNICATION = 'COMMUNICATION'
export const SERVICE = 'SERVICE'
export const WI_FI = 'WI_FI'

export const READY = 'READY'
export const ARMED = 'ARMED'
export const AC = 'AC'

export const pmaxLeds = [POWER, ARM, CHIME, TROUBLE]

export const pmaxLedsExtended = [
    POWER,
    ARM,
    CHIME,
    TROUBLE,
    COMMUNICATION,
    SERVICE,
    WI_FI,
]

export const neoLeds = [READY, ARMED, TROUBLE, AC]
