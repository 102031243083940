import {get} from 'api/http'

import {mapLicenseInfo, mapServerVersion, mapSeverTime} from 'api/about.map'

export function fetchLicense() {
    return get('/settings/license').then(mapLicenseInfo)
}

export function fetchServerVersion() {
    return get('/settings/version').then(mapServerVersion)
}

export function fetchServerTime() {
    return get('/settings/servertime').then(mapSeverTime)
}
