import {compose} from 'redux'

import {uploadSchedule} from 'permissions/remoteInspections/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withRemoteInspections from 'containers/withRemoteInspections'

import {ReactComponent as IconCalendar} from 'ipmp-react-ui/icons/calendar.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: uploadSchedule}),
    withVisibility(),
    withRemoteInspections(),
    withProps(({showScheduleRriByCsvModal}) => ({
        onClick: showScheduleRriByCsvModal,
        label: __('Upload schedule'),
        Icon: IconCalendar,
    }))
)(Button)
