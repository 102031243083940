import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {showDeviceMeteoChart} from 'modules/modals/actions'

import Button from 'ipmp-react-ui/Button'

export default connect(
    () => ({}),
    (dispatch, {panelId, deviceId, type}) =>
        bindActionCreators(
            {
                onClick: () => showDeviceMeteoChart(panelId, deviceId, type),
            },
            dispatch
        )
)(Button)
