import createListActions from 'modules/higherOrder/createListActions'

export const {
    init,
    receive,
    reset,
    setStart,
    fetch,
    startPoll,
    stopPoll,
    purgeList,
} = createListActions('PANEL/INSTALLERS')
