import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Button from 'ipmp-react-ui/Button'

import {ReactComponent as IconDownload} from 'ipmp-react-ui/icons/download.svg'
import {__, __n} from 'utils/i18n'
import Definition from 'ipmp-react-ui/Definition'
import {buildDownloadReportResultHref} from 'api/reports'
import getContentName from 'constants/reportContentOption'

export default class Report extends Component {
    static propTypes = {
        report: PropTypes.shape({
            title: PropTypes.string.isRequired,
            exportTypes: PropTypes.arrayOf(PropTypes.oneOf(['pdf', 'csv'])),
            emails: PropTypes.arrayOf(PropTypes.string),
            units: PropTypes.number,
            ftps: PropTypes.arrayOf(PropTypes.string),
            contents: PropTypes.arrayOf(
                PropTypes.oneOf(['Events', 'Faults', 'Processes', 'RI Tests'])
            ),
            configurations: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        isDownloadActive: PropTypes.bool,
    }

    static defaultProps = {
        isDownloadActive: true,
    }

    renderSection(title, content, map) {
        if (!content || content.length === 0) {
            return
        }

        return (
            <div>
                <div className="hint">{title}</div>
                {content &&
                    content.map((text, key) => (
                        <div key={key}>{map ? map(text) : text}</div>
                    ))}
            </div>
        )
    }

    render() {
        const {report, isDownloadActive} = this.props
        const {
            id,
            unitId,
            title,
            units,
            contents,
            exportTypes,
            configurations,
            emails,
            isLast,
            ftps,
        } = report

        return (
            <div className="report">
                <Definition
                    multiLine
                    className="title title--large"
                    title={title}
                    detail={
                        units ? __n('%d unit involved', '%d units involved', units) : null
                    }
                />

                {exportTypes && isLast && isDownloadActive && (
                    <div className="report-download">
                        {exportTypes.map((type) => (
                            <Button
                                key={type}
                                small
                                flat
                                href={buildDownloadReportResultHref(unitId, id, type)}
                                Icon={IconDownload}
                                children={type}
                            />
                        ))}
                    </div>
                )}

                {this.renderSection(__('Sent to'), emails)}
                {this.renderSection(__('Sent to ftp'), ftps)}
                {this.renderSection(__('Report Contain'), contents, getContentName)}
                {this.renderSection(__('Selected configurations'), configurations)}
            </div>
        )
    }
}
