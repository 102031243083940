import {
    DEVICE_TYPE_GSM,
    DEVICE_TYPE_LTE_MODULE,
    DEVICE_TYPE_POWER_LINK,
} from 'constants/deviceType'

export const FIRMWARE_UPGRADE_DEFAULT_TIMEOUT = 86400
export const FIRMWARE_UPGRADE_MINIMAL_TIMEOUT = 3600
export const FIRMWARE_UPGRADE_MAXIMUM_TIMEOUT = 604800

export const hasTimeoutAttribute = (deviceType) => {
    switch (deviceType) {
        case DEVICE_TYPE_POWER_LINK:
        case DEVICE_TYPE_GSM:
        case DEVICE_TYPE_LTE_MODULE:
            return true
        default:
            return false
    }
}
