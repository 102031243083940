import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {init, setStart, startPoll, stopPoll, reset} from 'modules/actionLog/list/actions'

export default function withActionLog() {
    return compose(
        withPerPage('actionLog'),
        connect(
            ({
                actionLog: {
                    list: {page, ...list},
                    store: {byIds},
                },
            }) => ({
                ...list,
                rows: page.map((id) => byIds[id]),
            }),
            (dispatch) =>
                bindActionCreators(
                    {
                        init,
                        setStart,
                        startPoll,
                        stopPoll,
                        reset,
                    },
                    dispatch
                )
        )
    )
}
