import {handleActions} from 'redux-actions'

import {clear, update} from './actions'

export default handleActions(
    {
        [clear]() {
            return {}
        },

        [update](state, {payload}) {
            return payload.reduce((carrier, result) => {
                return {
                    ...carrier,
                    [result.id]: {
                        ...carrier[result.id],
                        ...result,
                    },
                }
            }, state)
        },
    },
    {}
)
