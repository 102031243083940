import {showConfirmationModal} from 'modules/modals/actions'
import {connect} from 'react-redux'

export default function withConfirmationModal(
    actionCreator: (props: Object, ...args: Array) => Object,
    messagesCreator: (props: Object, ...args: Array) => Object
) {
    return connect(null, (dispatch, props) => ({
        onClick(...args) {
            const action = actionCreator(props, ...args)
            const messages = messagesCreator(props, ...args)

            if (!action) {
                return
            }

            dispatch(showConfirmationModal(action, messages))
        },
    }))
}
