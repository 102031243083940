import React, {Component, Fragment} from 'react'
import {compose} from 'redux'

import Bar, {CENTER} from 'ipmp-react-ui/Bar'
import CardBlindModal from 'ipmp-react-ui/CardBlindModal'

import RemoteInspectionResults from 'components/RemoteInspection/Results'

import page from 'permissions/panel/remoteInspections/page'
import {withPermission, withRejection} from 'containers/withPermission'
import withPanelRemoteInspection from 'containers/withPanelRemoteInspection'
import withLifeCycle from 'containers/withLifeCycle'
import ReviewButton from './BlindButtons/ReviewButton'
import SendEmailButton from './BlindButtons/SendEmailButton'
import InitiateButton from './BlindButtons/InitiateButton'
import {humanDate} from 'ipmp-react-ui/humanTime'
import NextRICell from 'components/Cell/RemoteInspections/NextRICell'

import RemoteInspectionsCalendar from './RemoteInspectionsCalendar'

class RemoteInspectionsPage extends Component {
    state = {
        activeResult: null,
        isRemoteInspectionResultOpened: false,
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.activeResult === null) {
            const hasFirstRow = nextProps && nextProps.rows.length > 0
            return {
                activeResult: hasFirstRow ? nextProps.rows[0] : null,
            }
        }

        return null
    }

    closeRemoteInspectionResult = () => {
        this.setState({isRemoteInspectionResultOpened: false})
    }

    onItemClick = (result) => {
        this.setState({
            isRemoteInspectionResultOpened: true,
            activeResult: result,
        })
    }

    renderBlindContent = () => {
        const {remoteInspection} = this.props
        const {activeResult} = this.state

        return (
            <Fragment>
                <Bar orientation={CENTER}>
                    <ReviewButton remoteInspectionResult={activeResult} />
                    <SendEmailButton remoteInspectionResult={activeResult} />
                    <InitiateButton />
                </Bar>
                <NextRICell
                    next={remoteInspection.next}
                    repetition={remoteInspection.repetition}
                />
                <RemoteInspectionResults remoteInspectionResult={activeResult} />
            </Fragment>
        )
    }

    render() {
        const {isRemoteInspectionResultOpened, activeResult} = this.state

        return (
            <Fragment>
                <RemoteInspectionsCalendar onItemClick={this.onItemClick} />
                <CardBlindModal
                    opened={isRemoteInspectionResultOpened}
                    title={activeResult && humanDate(activeResult.created)}
                    onClose={this.closeRemoteInspectionResult}
                    className="blindModalPanelRemoteInspection"
                >
                    {activeResult && this.renderBlindContent()}
                </CardBlindModal>
            </Fragment>
        )
    }
}

export default compose(
    withPermission({isAllowed: page}),
    withRejection(),
    withPanelRemoteInspection(),
    withLifeCycle({
        onMount: ({reset}) => {
            reset()
        },
        onUnmount: ({stopPoll}) => stopPoll(),
    })
)(RemoteInspectionsPage)
