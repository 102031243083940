import {takeEvery, all} from 'redux-saga/effects'

import * as actions from './actions'

import {
    changeGroup,
    editPanelInfo,
    editPanelCustomerInfo,
    suspendFaults,
    resumeFaults,
    resolveFaults,
    markForService,
    reassignService,
} from 'modules/forms/handlers'

import watchEncryptionState from 'modules/panels/store/watchers/watchEncryptionState'
import watchReadDiagnostic from 'modules/panels/store/watchers/watchReadDiagnostic'
import watchDiscovery from 'modules/panels/store/watchers/watchDiscovery'
import watchPushBasicConfiguration from 'modules/panels/store/watchers/watchPushBasicConfiguration'
import watchReassignService from 'modules/panels/store/watchers/watchReassignService'
import watchMarkForService from 'modules/panels/store/watchers/watchMarkForService'
import watchResolveFaults from 'modules/panels/store/watchers/watchResolveFaults'
import watchResumeFaults from 'modules/panels/store/watchers/watchResumeFaults'
import watchSuspendFaults from 'modules/panels/store/watchers/watchSuspendFaults'
import watchRefresh from 'modules/panels/store/watchers/watchRefresh'
import watchRefreshState from 'modules/panels/store/watchers/watchRefreshState'
import watchChangeGroup from 'modules/panels/store/watchers/watchChangeGroup'
import watchEditPanelCustomerInfo from 'modules/panels/store/watchers/watchEditPanelCustomerInfo'
import watchEditPanelInfo from 'modules/panels/store/watchers/watchEditPanelInfo'

export default function* () {
    yield all([
        takeEvery(actions.refreshState, watchRefreshState),
        takeEvery(actions.refresh, watchRefresh),
        takeEvery(changeGroup.SUCCESS, watchChangeGroup),
        takeEvery(editPanelInfo.SUCCESS, watchEditPanelInfo),
        takeEvery(editPanelCustomerInfo.SUCCESS, watchEditPanelCustomerInfo),
        takeEvery(suspendFaults.SUCCESS, watchSuspendFaults),
        takeEvery(markForService.SUCCESS, watchMarkForService),
        takeEvery(reassignService.SUCCESS, watchReassignService),
        takeEvery(resumeFaults.SUCCESS, watchResumeFaults),
        takeEvery(resolveFaults.SUCCESS, watchResolveFaults),
        takeEvery(actions.pushBasicConfiguration, watchPushBasicConfiguration),
        takeEvery(actions.discovery, watchDiscovery),
        takeEvery(actions.readDiagnostic, watchReadDiagnostic),
        takeEvery(actions.setEncryptionState, watchEncryptionState),
    ])
}
