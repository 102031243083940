import dataMapper from 'utils/dataMapper'

export const keysMap = {
    id: 'id',
    name: 'name',
    isDelayEnabled: 'is_delay_enabled',
}

export const mapZoneTypes = dataMapper((data) => ({
    id: data.id,
    name: data.name,
    isDelayEnabled: data.is_delay_enabled,
}))

export const mapFormData = (formData) => ({
    name: formData.name,
    is_delay_enabled: formData.isDelayEnabled === 'on' || false,
})
