import {compose} from 'redux'

import list from 'permissions/centralStations/list'

import withCentralStations from 'containers/withCentralStations'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Pager from 'ipmp-react-ui/Pager'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withCentralStations()
)(Pager)
