import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {ScrollView} from 'ipmp-react-ui/Layout'
import Card from 'ipmp-react-ui/Card'

import Info from './Info'

import * as actions from 'modules/system/settings/actions'
import withLoader from 'containers/withLoader'

export class CustomerInfo extends Component {
    render() {
        return (
            <ScrollView>
                <Card className="card">
                    <Info />
                </Card>
            </ScrollView>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {customerInfo},
            },
        }) => ({
            isLoading: customerInfo.isLoading,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    customerInfo: actions.fetchCustomerInfo,
                },
                dispatch
            )
    ),
    withLoader(({customerInfo}) => {
        customerInfo()
    })
)(CustomerInfo)
