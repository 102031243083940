import {compose} from 'redux'

import {create} from 'permissions/groups/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'
import withGroups from 'containers/withGroups'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as PlusIcon} from 'ipmp-react-ui/icons/plus.svg'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: create}),
    withVisibility(),
    withGroups(),
    withProps(({showAddGroup}) => ({
        onClick: showAddGroup,
        Icon: PlusIcon,
        children: __('Add Group'),
    }))
)(Button)
