import {createAction} from 'redux-actions'

// rewrite all data in group
export const receive = createAction(
    'GROUPS/CENTRAL_STATION_LINKS/RECEIVE',
    (groupId, links) => ({groupId, links})
)
export const update = createAction(
    'GROUPS/CENTRAL_STATION_LINKS/UPDATE',
    (groupId, centralStationId, links) => ({groupId, centralStationId, links})
)
export const clear = createAction('GROUPS/CENTRAL_STATION_LINKS/CLEAR')
