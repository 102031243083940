import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {hide} from 'modules/modals/actions'

import Modal from 'ipmp-react-ui/Modal'
import CardModalError from 'ipmp-react-ui/CardModalError'
import {__} from 'utils/i18n'

class MixedVendorError extends Component {
    static propTypes = {
        hide: PropTypes.func.isRequired,
        selectedPanels: PropTypes.array.isRequired,
    }

    get families() {
        const {selectedPanels} = this.props

        // get unique families
        return [...new Set(selectedPanels.map(({family}) => family))]
    }

    get message() {
        return this.families.join(', ')
    }

    render() {
        const {hide} = this.props

        return (
            <Modal onClose={hide}>
                <CardModalError
                    onClose={hide}
                    title={__('Mixed vendors')}
                    message={__(
                        "You can't simultaneously push basic configuration to %s panels",
                        this.message
                    )}
                />
            </Modal>
        )
    }
}

export default connect(null, (dispatch) => bindActionCreators({hide}, dispatch))(
    MixedVendorError
)
