import {combineReducers} from 'redux'

import autocomplete from './autocomplete/reducer'
import list from './list/reducer'
import store from './store/reducer'

export default combineReducers({
    autocomplete,
    list,
    store,
})
