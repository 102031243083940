import React from 'react'

import Page from 'ipmp-react-ui/Page'

import StateTypesTable from './StateTypesTable'
import StateTypesGridBar from './StateTypesGridBar'

export default class StateTypesPage extends Page {
    renderBar() {
        return <StateTypesGridBar />
    }

    renderContent() {
        return <StateTypesTable />
    }
}
