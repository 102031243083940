import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {setStart} from 'modules/processes/list/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProcesses from 'containers/withProcesses'

import Pager from 'ipmp-react-ui/Pager'
import list from 'permissions/processes/list'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProcesses(),
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                setStart,
                onPageChange: setStart,
            },
            dispatch
        )
    )
)(Pager)
