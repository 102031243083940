import {handleActions} from 'redux-actions'

import * as actions from './actions'

export const defaultState = {
    isLoading: false,
    types: [],
    options: [],
    error: null,
}

export default handleActions(
    {
        [actions.fetch]: () => ({
            isLoading: true,
        }),

        [actions.receive]: (state, {error, payload}) => {
            if (error) {
                return {
                    ...state,
                    error: payload,
                    isLoading: false,
                }
            }

            return {
                ...state,
                error: null,
                types: payload.types,
                options: payload.options,
                isLoading: false,
            }
        },
    },
    defaultState
)
