import {selectPerPage} from 'modules/settings/selectors'
import {all, put, select, takeEvery} from 'redux-saga/effects'

import * as storeActions from 'modules/eventRules/store/actions'
import * as listActions from './actions'
import * as api from 'api/eventRules'

import createListWithoutPaginationSaga from 'modules/higherOrder/createListWithoutPaginationSaga'
import * as eventTypesActions from 'modules/eventTypes/list/actions'
import * as zoneTypesActions from 'modules/zoneTypes/list/actions'
import * as notificationTypesActions from 'modules/notificationTypes/list/actions'
import * as stateTypesActions from 'modules/stateTypes/list/actions'
import {addEventRule, editEventRule} from 'modules/forms/handlers'
import {hide} from 'modules/modals/actions'
import {
    selectEventTypeFilter,
    selectEventTypeSuggests,
} from 'modules/eventTypes/store/selectors'
import {
    selectNotificationTypeFilter,
    selectNotificationTypeSuggests,
} from 'modules/notificationTypes/store/selectors'
import {
    selectZoneTypeFilter,
    selectZoneTypeSuggests,
} from 'modules/zoneTypes/store/selectors'
import {
    selectStateTypeFilter,
    selectStateTypeSuggests,
} from 'modules/stateTypes/store/selectors'

const selector = (state) => ({
    ...state.eventRules.list,
    perPage: selectPerPage(state, 'eventRules'),
    listRoute: 'eventRules',
})

function* postFetchFilter(rows) {
    const {filters} = yield select(selector)
    const selectors = {
        eventId: selectEventTypeFilter,
        zoneTypeId: selectZoneTypeFilter,
        notificationId: selectNotificationTypeFilter,
        stateId: selectStateTypeFilter,
    }
    const filteredIds = {
        eventId: new Set(),
        zoneTypeId: new Set(),
        notificationId: new Set(),
        stateId: new Set(),
    }

    for (let filter of filters) {
        const {name, value} = filter
        const selector = selectors[name]
        const ids = yield select((state) => selector(state, value))
        filteredIds[name] = [...filteredIds[name], ...ids]
    }

    for (let filter of filters) {
        const {name} = filter
        let i = 0
        while (i < rows.length) {
            if (!filteredIds[name].includes(rows[i][name])) {
                rows.splice(i, 1)
            } else {
                i++
            }
        }
    }

    yield put(storeActions.update(rows))
}

export default function* () {
    yield all([
        createListWithoutPaginationSaga(
            api,
            listActions,
            postFetchFilter,
            selector,
            storeActions.purge
        ),
        takeEvery(listActions.init, watchInitAll),
        takeEvery(listActions.startPoll, watchStartPollAll),
        takeEvery(listActions.stopPoll, watchStopPollAll),
        takeEvery([addEventRule.SUCCESS, editEventRule.SUCCESS], watchEventRuleSuccess),
        takeEvery(listActions.fetchSuggests, watchFetchSuggests),
    ])
}

function* watchInitAll() {
    yield put(eventTypesActions.init())
    yield put(zoneTypesActions.init())
    yield put(notificationTypesActions.init())
    yield put(stateTypesActions.init())
}

function* watchStartPollAll() {
    yield put(eventTypesActions.startPoll())
    yield put(zoneTypesActions.startPoll())
    yield put(notificationTypesActions.startPoll())
    yield put(stateTypesActions.startPoll())
}

function* watchStopPollAll() {
    yield put(eventTypesActions.stopPoll())
    yield put(zoneTypesActions.stopPoll())
    yield put(notificationTypesActions.stopPoll())
    yield put(stateTypesActions.stopPoll())
}

function* watchEventRuleSuccess() {
    yield put(hide())
    yield put(listActions.fetch())
}

function* watchFetchSuggests({payload: {fields, prefix}}) {
    const selectors = {
        eventId: selectEventTypeSuggests,
        zoneTypeId: selectZoneTypeSuggests,
        notificationId: selectNotificationTypeSuggests,
        stateId: selectStateTypeSuggests,
    }
    const results = {}
    for (let field of fields) {
        results[field] = yield select(selectors[field])
    }
    yield put(listActions.receiveSuggests(results, prefix))
}
