import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'

import {suspend} from 'permissions/interactiveUsers/actions'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'
import __ from 'utils/i18n'
import withInteractiveUsers from 'containers/withInteractiveUsers'
import withConfirmation from 'containers/withConfirmation'

export default compose(
    withPermission({isVisible: suspend}),
    withVisibility(),
    withInteractiveUsers(),
    withProps(({suspend, interactiveUser}) => ({
        label: __('Suspend'),
        onClick: () => suspend(interactiveUser.id),
        message: __('Do you really want to suspend %s?', interactiveUser.email),
        title: __('Suspend user'),
    })),
    withConfirmation()
)(Button)
