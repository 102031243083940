import {takeEvery, takeLatest, all, call, put, select, throttle} from 'redux-saga/effects'
import isNil from 'lodash-es/isNil'
import has from 'lodash-es/has'

import {deselectAll} from 'modules/selection/actions'
import * as selection from 'modules/selection/actions'
import {snackShow} from 'modules/snacks'
import {SCOPE_NOT_FOUND} from 'constants/errorType'
import {ensureProcesses} from 'modules/processes/manager/ensureProcess'
import generateBatch from 'modules/batches/manager/generateBatch'

import toIds from 'utils/toIds'
import {err} from 'utils/log'

import {update} from './globalListActions'
import {HISTORY_PUSH} from 'constants/reactRouterHistoryActions'

export const ERROR_SCOPE_NOT_FOUND = new Error(SCOPE_NOT_FOUND)

const isNeedPurgeList = (actions) => {
    return has(actions, 'purgeList')
}

/**
 * For purge only needed list
 * We check it via selector's `listRoute` prop
 * For list without selection, we don't need `listRoute` in selector
 */
function* isNeedPurgeListOnDeselect(actions, route) {
    const {location} = yield select((state) => state.router)
    const hasAction = isNeedPurgeList(actions)
    const routeOnNeededList = route && location.pathname.includes(route)

    return hasAction && routeOnNeededList
}

export function creteWatchFetch({fetch, actions, updateActionCreator, selector}) {
    return function* watchFetch() {
        const state = yield select(selector)

        if (isNeedPurgeList(actions)) {
            yield put(actions.purgeList())
        }

        try {
            const {rows, count} = yield call(fetch, state)

            const action = yield updateActionCreator(rows)

            if (action) {
                yield put(action)
            }

            yield put(actions.receive(toIds(rows), count))
        } catch (error) {
            yield put(actions.receive(error))
        }
    }
}

export function* createListFetchSaga(fetch, actions, updateActionCreator, selector) {
    if (!fetch) {
        return err(`Api method fetch not found for action: ${actions.fetch}`)
    }

    if (!actions.init) {
        return
    }

    yield all([
        takeEvery(actions.init, function* watchInit({payload: {scope, filters, action}}) {
            const {lastList, lastScope} = yield select((state) => state.globalListReducer)
            const sameList = lastList === actions.init.toString()
            const sameScope = lastScope === scope

            yield put(
                update({
                    lastList: actions.init.toString(),
                    lastScope: scope || null,
                })
            )

            if (!sameList) {
                yield put(deselectAll())
            }

            if (sameList && sameScope && action && action !== HISTORY_PUSH) {
                return
            }

            if (scope) {
                const {scopes} = yield select(selector)

                if (!scopes || !has(scopes, scope)) {
                    yield put(actions.receive(ERROR_SCOPE_NOT_FOUND))
                    return
                }

                filters = scopes[scope].filters || []
            }

            yield put(actions.reset(filters))
        }),

        takeLatest(
            [actions.reset, actions.fetch, actions.setStart],
            creteWatchFetch({fetch, actions, updateActionCreator, selector})
        ),
    ])
}

export function* createListSuggestSaga(suggest, actions, selector) {
    if (!suggest) {
        return err(`Api method suggest not found for action: ${actions.fetchSuggests}`)
    }

    if (!has(actions, 'receiveSuggests')) {
        return err(
            `There is no receiveSuggests action as pair for action: ${actions.fetchSuggests}`
        )
    }

    yield throttle(500, [actions.fetchSuggests], function* watchSuggests({
        type,
        payload: {fields, prefix},
    }) {
        try {
            const state = yield select(selector)

            const data = fields.reduce(
                (acc, key) => ({
                    ...acc,
                    [key]: prefix,
                }),
                {}
            )

            const result = yield call(suggest, data, 0, 10, state)

            yield put(actions.receiveSuggests(result, prefix))
        } catch (error) {
            // ignore?
        }
    })
}

export function* createListRemoveSaga(remove, actions, selector) {
    if (!remove) {
        return err(`Api method remove not found for action: ${actions.remove}`)
    }

    yield takeEvery([actions.remove], function* watchRemove({
        type,
        payload: ids,
        meta: {removeProcessType},
    }) {
        yield put(selection.deselectAll({isNeedsPurge: false}))
        let batchId

        if (!isNil(removeProcessType)) {
            batchId = (yield generateBatch(removeProcessType, ids)).batchId
        }

        try {
            const response = yield call(remove, ids, batchId)
            const {perPage, total, start, page} = yield select(selector)

            if (response && has(response, 'processes')) {
                yield ensureProcesses(response.processes)
            }

            if (!isNil(perPage) && !isNil(total) && !isNil(start)) {
                if (start >= total) {
                    const rest = total % perPage === 0 ? perPage : total % perPage
                    let start = total - rest
                    start = start > 0 ? start : 0

                    yield put(actions.setStart(start))
                }
            }
            yield put(actions.purgeList(page))
        } catch (error) {
            yield put(snackShow(error.message))

            if (has(actions, 'revertRemove')) {
                yield put(actions.revertRemove(error))
            } else {
                yield put(actions.fetch())
            }

            const isEmpty = yield select((state) => state.selection.isEmpty())

            if (isEmpty) {
                yield put(selection.select(ids))
            }
        }
    })
}

/**
 * Checkout for clear store data for current list.
 * Watching for `deselect`, `deselectAll` and `purgeList` actions.
 * Needs reducer handler for `purgeStoreAction`
 * see `createPurgeStoreHandler.js` for more details
 */
export function* createListPurgeSaga(actions, purgeStoreAction, selector) {
    yield all([
        takeEvery([selection.deselectAll, selection.deselect], function* watchDeselect({
            payload,
        }) {
            const {page, listRoute} = yield select(selector)
            const isNeedPurgeListByActionsAndRoute = yield isNeedPurgeListOnDeselect(
                actions,
                listRoute
            )
            // Prevent purge from remove
            const isNeedsPurgeByPayload =
                payload && has(payload, 'isNeedsPurge') ? payload.isNeedsPurge : true

            if (isNeedsPurgeByPayload && isNeedPurgeListByActionsAndRoute) {
                yield put(actions.purgeList(page))
            }
        }),
        takeEvery(actions.purgeList, function* watchPurge({payload: ids = []}) {
            const selection = yield select((state) => state.selection)
            const notPurgeIds = [...ids, ...selection.toJS()]

            yield put(purgeStoreAction(notPurgeIds))
        }),
    ])
}

export default function* (
    api,
    actions,
    updateActionCreator,
    selector,
    purgeStoreAction = null
) {
    const sagas = []

    sagas.push(createListFetchSaga(api.fetch, actions, updateActionCreator, selector))

    if (purgeStoreAction) {
        sagas.push(createListPurgeSaga(actions, purgeStoreAction, selector))
    }

    if (has(actions, 'fetchSuggests')) {
        sagas.push(createListSuggestSaga(api.suggest, actions, selector))
    }

    if (has(actions, 'remove')) {
        sagas.push(createListRemoveSaga(api.remove, actions, selector))
    }

    yield all(sagas)
}
