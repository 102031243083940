import {get, post} from 'api/http'
import dataMapper from 'utils/dataMapper'
import {mapRunners} from 'api/runners.map'

const map = dataMapper((appliance) => ({
    number: parseInt(appliance.number, 10),
    type: appliance.type,
    currentVersion: appliance.current_version,
    packages: appliance.packages,
}))

export function fetchList(panelId) {
    return get('unit_firmware/list', {unt_id: panelId}).then(({appliances, runner}) => ({
        runner: runner ? mapRunners(runner) : null,
        appliances: appliances.map(map),
    }))
}

export function upgrade(panelId, packages, timeout, failOnArmedState, batchId) {
    return post('unit_firmware/upgrade', {
        unt_id: panelId,
        batch_id: batchId,
        packages: packages.map(({type, packageName}) => ({
            device_type: type,
            package: packageName,
        })),
        timeout: +timeout,
        fail_on_armed_state: failOnArmedState,
    }).then(mapRunners)
}
