import {compose} from 'redux'
import {stop} from 'permissions/processes/actions'

import {ifSelection, withSelectionHandler} from 'containers/withSelection'
import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'
import withProcesses from 'containers/withProcesses'
import withProps from 'containers/withProps'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: stop}),
    withVisibility(),
    withProcesses(),
    withProps(({stop}) => ({
        onClick: (ids) => stop(ids),
        label: __('Stop'),
    })),
    withSelectionHandler(),
    ifSelection(
        (id, {processes}) =>
            processes.store.byIds[id] && processes.store.byIds[id].isStoppable
    )
)(Button)
