import React, {Component} from 'react'
import {compose} from 'redux'

import bar from 'permissions/users/bar'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import UserSelectionDropDown from './GridBar/UserSelectionDropDown'
import SuspendUsersButton from './GridBar/SuspendUsersButton'
import RemoveUsersButton from './GridBar/RemoveUsersButton'
import EnableUsersButton from './GridBar/EnableUsersButton'
import CreateUserButton from './GridBar/CreateUserButton'
import UserPager from './GridBar/UserPager'

class UsersBar extends Component {
    render() {
        return (
            <Bar>
                <UserSelectionDropDown />

                <RemoveUsersButton />
                <SuspendUsersButton />
                <EnableUsersButton />

                <BarSpace />

                <UserPager />
                <CreateUserButton />
            </Bar>
        )
    }
}

export default compose(withPermission({isVisible: bar}), withVisibility())(UsersBar)
