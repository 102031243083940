import {createSelector} from 'reselect'

export const selectRunnersRowsByBatchId = createSelector(
    (state, {batchId}) => state.batches.store.byIds[batchId],
    (state) => state.runners.store.byIds,
    (state) => state.batches.batchRunners,
    (state) => state.runners.store.stoppingIds,
    (batch, runners, {ids, perPage, count, isLoading, error}, stoppingIds) => ({
        runners: ids.map((id) => runners[id]),
        stoppingIds,
        batch,
        isLoading,
        error,
        hasMore: count > perPage,
    })
)
