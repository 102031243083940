import {withConfigurationActions} from 'containers/withConfiguration'
import React, {Component} from 'react'
import {compose} from 'redux'
import has from 'lodash-es/has'

import withLoader from 'containers/withLoader'
import withConfigurationFilter from 'containers/withConfigurationFilter'
import withProps from 'containers/withProps'

import ConfigurationLayout from 'components/Configuration/ConfigurationLayout'

import ConfigurationHeader from './Header/ConfigurationHeader'
import ConfigurationFilters from './Sidebar/ConfigurationFilters'
import {HEIGHT_OFFSET_BEFORE_CONFIGURATION_LIST} from 'components/Configuration/measure'

class PanelViewConfiguration extends Component {
    handleIsChanged = (item) => {
        const {makeBasic, exporting, changes} = this.props

        if (makeBasic) {
            return exporting && has(exporting, item.key)
        } else {
            return changes && has(changes, item.key)
        }
    }

    render() {
        const {configuration, backup, makeBasic, isCompareWithBackup} = this.props

        if (!configuration) {
            return null
        }

        const header = <ConfigurationHeader {...this.props} />
        const filters = <ConfigurationFilters {...this.props} />

        return (
            <ConfigurationLayout
                {...{
                    ...this.props,
                    header,
                    filters,
                    isShowExportCheckbox: makeBasic,
                    isChanged: this.handleIsChanged,
                    hasUndo: !(makeBasic || isCompareWithBackup),
                    backup: isCompareWithBackup ? backup : null,
                    heightOffsetBeforeConfiguration: HEIGHT_OFFSET_BEFORE_CONFIGURATION_LIST,
                }}
            />
        )
    }
}

export default compose(
    withConfigurationActions(),
    withLoader(
        ({configuration, fetch}) => {
            if (!configuration) {
                fetch()
            }
        },
        ({makeBasic, dismissChanges, clearFilters}) => {
            if (makeBasic) {
                dismissChanges()
            } else {
                clearFilters()
            }
        }
    ),
    withProps(({isEditable, makeBasic}) => ({
        isEditable: isEditable && !makeBasic,
    })),
    withConfigurationFilter()
)(PanelViewConfiguration)
