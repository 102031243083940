import {createAction} from 'redux-actions'
import toArray from 'utils/toArray'

export const update = createAction(
    'PANEL_INTERACTIVE_USERS/STORE/UPDATE',
    toArray,
    (_, panelId) => ({panelId})
)

export const disconnectInteractiveUser = createAction(
    'PANEL_INTERACTIVE_USERS/STORE/DISCONNECT_INTERACTIVE_USER',
    null,
    (_, panelId) => ({panelId})
)
export const revertDisconnectInteractiveUser = createAction(
    'PANEL_INTERACTIVE_USERS/STORE/REVERT_DISCONNECT_INTERACTIVE_USER',
    null,
    (_, panelId) => ({panelId})
)

export const enable = createAction(
    'PANEL_INTERACTIVE_USERS/STORE/ENABLE',
    null,
    (_, panelId) => ({panelId})
)
export const suspend = createAction(
    'PANEL_INTERACTIVE_USERS/STORE/SUSPEND',
    null,
    (_, panelId) => ({panelId})
)
export const revert = createAction(
    'PANEL_INTERACTIVE_USERS/STORE/REVERT',
    null,
    (_, panelId) => ({panelId})
)
