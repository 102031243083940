import React from 'react'
import PropTypes from 'prop-types'
import Button from 'ipmp-react-ui/Button'
import Spinner from 'ipmp-react-ui/Spinner'
import Tooltip from 'ipmp-react-ui/Tooltip'
import {ReactComponent as IconCheck} from 'ipmp-react-ui/icons/check.svg'
import {ReactComponent as IconNotAvailable} from 'ipmp-react-ui/icons/not-available.svg'

import {
    CrossServerSearchContext,
    SERVER_STATUS_CONNECTING,
    SERVER_STATUS_ERROR,
    SERVER_STATUS_READY,
    SERVER_STATUS_UNAUTHORIZED,
} from 'pages/Panels/CrossServerSearch/CrossServerSearch'
import {__} from 'utils/i18n'

function ServerStatus({status, domain, name, errorText, retry, login}) {
    let icon
    let detail
    let action

    switch (status) {
        case SERVER_STATUS_READY:
            icon = <IconCheck className="icon success" />
            detail = __('Connected')
            break
        case SERVER_STATUS_CONNECTING:
            icon = <Spinner className="icon" />
            break
        case SERVER_STATUS_UNAUTHORIZED:
            icon = <IconNotAvailable className="icon danger" />
            detail = __('Not authorized')
            action = (
                <Button small flat onClick={login}>
                    {__('Login')}
                </Button>
            )
            break
        case SERVER_STATUS_ERROR:
        default:
            icon = <IconNotAvailable className="icon danger" />
            detail = errorText
            action = (
                <Button small flat onClick={retry}>
                    {__('Retry')}
                </Button>
            )
    }

    return (
        <div className="crossServerBlind-server">
            {icon}
            <div>
                <Tooltip tooltip={domain} inline={true}>
                    <span className="crossServerBlind-server-title">{name}</span>
                </Tooltip>
                {detail && (
                    <span className="crossServerBlind-server-detail">— {detail}</span>
                )}
            </div>
            {action}
        </div>
    )
}

ServerStatus.propTypes = {
    status: PropTypes.string,
    domain: PropTypes.string,
    errorText: PropTypes.string,
    retry: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
}

export default function CrossServerStatus() {
    return (
        <div className="crossServerBlind-servers">
            <h1 className="hint hint--gray">{__('Available servers:')}</h1>

            <CrossServerSearchContext.Consumer>
                {({servers}) =>
                    servers.map((server) => <ServerStatus key={server.url} {...server} />)
                }
            </CrossServerSearchContext.Consumer>
        </div>
    )
}
