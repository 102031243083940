import dataMapper from 'utils/dataMapper'

export const mapEventType = dataMapper((data) => ({
    id: data.id,
    cid: data.cid,
    sia: data.sia,
    description: data.description,
}))

export const keysMap = {
    id: 'id',
    cid: 'cid',
    sia: 'sia',
    description: 'description',
}
