import {__} from 'utils/i18n'
import {warn} from 'utils/log'

export const CS_PROFILE_ALERT = 'alert'
export const CS_PROFILE_ALARM = 'alarm'
export const CS_PROFILE_RESTORE = 'restore'
export const CS_PROFILE_SECURITYOPENCLOSE = 'securityopenclose'
export const CS_PROFILE_CAMERABEINGVIEWED = 'camerabeingviewed'
export const CS_PROFILE_CAMERATROUBLE = 'cameratrouble'
export const CS_PROFILE_HDONOFF = 'hdonoff'
export const CS_PROFILE_HDTROUBLE = 'hdtrouble'
export const CS_PROFILE_ONLINE = 'online'
export const CS_PROFILE_OFFLINE = 'offline'
export const CS_PROFILE_NOTICE = 'notice'
export const CS_PROFILE_RRI = 'rri'
export const CS_PROFILE_SMART_SENSORS = 'smartsensors'
export const CS_PROFILE_OPENCLOSE = 'openclose'
export const CS_PROFILE_TEMPERATURE = 'temperature'
export const CS_PROFILE_ILLUMINANCE = 'illuminance'

export default function csProfileType(profileType) {
    profileType = profileType.toLowerCase()

    switch (profileType) {
        case CS_PROFILE_ALERT:
            return __('Alert')
        case CS_PROFILE_ALARM:
            return __('Alarm')
        case CS_PROFILE_RESTORE:
            return __('Restore')
        case CS_PROFILE_SECURITYOPENCLOSE:
            return __('Security open/close')
        case CS_PROFILE_CAMERABEINGVIEWED:
            return __('Camera being viewed')
        case CS_PROFILE_CAMERATROUBLE:
            return __('Camera trouble')
        case CS_PROFILE_HDONOFF:
            return __('HD on/off')
        case CS_PROFILE_HDTROUBLE:
            return __('HD trouble')
        case CS_PROFILE_ONLINE:
            return __('Online')
        case CS_PROFILE_OFFLINE:
            return __('Offline')
        case CS_PROFILE_NOTICE:
            return __('Notice')
        case CS_PROFILE_RRI:
            return __('RRI')
        case CS_PROFILE_SMART_SENSORS:
            return __('Smart Sensors')
        case CS_PROFILE_OPENCLOSE:
            return __('Open/Close')
        case CS_PROFILE_TEMPERATURE:
            return __('Temperature')
        case CS_PROFILE_ILLUMINANCE:
            return __('Illuminance')
    }

    warn(`Unknown profileType ${profileType}`)

    return profileType
}
