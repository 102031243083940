import {eventChannel, END} from 'redux-saga'
import {
    takeEvery,
    all,
    race,
    call,
    put,
    take,
    fork,
    cancel,
    select,
    join,
} from 'redux-saga/effects'

import * as actions from './actions'
import {logout} from 'modules/auth/sign/actions'
import * as api from 'api/panel/keypad'
import {snackShow} from 'modules/snacks'
import logger, {
    MESSAGE_TYPE_AUTORIZED,
    MESSAGE_TYPE_KEY,
    MESSAGE_TYPE_LEDS,
    MESSAGE_TYPE_TEXT,
    ledLogger,
    MESSAGE_TYPE_ERROR,
} from 'utils/keypad/logger'
import isSound from 'constants/virtualKeypad/sounds'
import {OPEN} from 'constants/webSocketReadyState'
import {__} from 'utils/i18n'

let lastMessageTime = {}

export default function* () {
    yield all([takeEvery(actions.enable, watchEnable)])
}

function* watchEnable({payload}) {
    const panelId = payload
    const {serial} = yield select(({panels}) => panels.store.byIds[panelId])
    let receiveTask, sendTask

    try {
        const {token, isenabled, name} = yield call(api.enable, panelId)

        if (typeof token === 'undefined') {
            return yield put(actions.isEnabled({isenabled, name, panelId}))
        }

        const ws = yield call(api.startSession, serial, token)

        receiveTask = yield fork(receiveMessages, ws, panelId)
        sendTask = yield fork(sendMessages, ws, panelId)

        yield race({
            disable: waitDisable(panelId),
            closed: join(receiveTask),
            logout: waitLogout(panelId),
        })

        yield call(api.disable, panelId)
    } catch (e) {
        yield put(snackShow(e.message))
        yield put(actions.disconnect(panelId))
    } finally {
        if (receiveTask && receiveTask.isRunning()) {
            yield cancel(receiveTask)
        }

        if (sendTask && sendTask.isRunning()) {
            yield cancel(sendTask)
        }

        yield put(actions.disconnect(panelId))
    }
}

function* waitDisable(panelId) {
    while (true) {
        const disableAction = yield take(actions.disable)

        if (disableAction.payload === panelId) {
            break
        }
    }
}

function* waitLogout(panelId) {
    while (true) {
        yield take(logout)

        break
    }
}

function* receiveMessages(ws, panelId) {
    const socketChannel = yield call(createSocketChannel, ws, panelId)
    const log = logger()
    const logLed = ledLogger()

    try {
        while (true) {
            const messages = yield take(socketChannel)

            for (let message of messages) {
                let shouldLog = false
                switch (message.type) {
                    case MESSAGE_TYPE_AUTORIZED:
                        yield put(actions.authorize(panelId))
                        break
                    case MESSAGE_TYPE_KEY:
                        shouldLog = true
                        break
                    case MESSAGE_TYPE_LEDS:
                        shouldLog = logLed(message.data)
                        break
                    case MESSAGE_TYPE_TEXT:
                        shouldLog = log(message.data)
                        break
                    case MESSAGE_TYPE_ERROR:
                        throw new Error(message.message)
                }

                if (isSound(message.type)) {
                    yield put(actions.soundMessage(message, panelId))
                }

                yield put(
                    actions.message(
                        {
                            ...message,
                            log: shouldLog,
                        },
                        panelId
                    )
                )
            }
        }
    } catch (e) {
        yield put(snackShow(e.message))
        return yield put(actions.disable(panelId))
    } finally {
        socketChannel.close()
    }
}

function* sendMessages(ws, panelId) {
    while (true) {
        const {payload, meta} = yield take(actions.send)
        const {key, longPress} = payload

        if (ws.readyState !== OPEN) {
            return yield put(actions.disable(panelId))
        }

        if (meta.panelId === panelId) {
            const sendData = [
                {type: 'key', data: new String(key), longpress: longPress || false},
            ]
            lastMessageTime[panelId] = Date.now()

            try {
                ws.send(JSON.stringify(sendData))
            } catch (error) {
                // Oh no!!
            }
        }
    }
}

function createSocketChannel(ws, panelId) {
    return eventChannel((emit) => {
        lastMessageTime[panelId] = Date.now()
        const iId = setInterval(() => {
            if (Date.now() - lastMessageTime[panelId] > 15 * 60 * 1000) {
                emit(new Error(__('Virtual Keypad session timed out due to inactivity')))
            } else if (ws.readyState === OPEN) {
                ws.send('[{"type":"ping"}]')
            }
        }, 1000)

        ws.onopen = () => {}

        ws.onerror = (e) => {
            console.error('ws error', e)
        }

        ws.onclose = (e) => {
            if (e.code !== 1000) {
                // Normal Closure
                emit(new Error(__('Websocket error')))
            }
            emit(END)
        }

        ws.onmessage = ({data}) => {
            // sometimes zero code receives from WS, remove it from string, otherwise JSON.parse throws exception
            emit(JSON.parse(data.toString().replace('\u0000', '')))
        }

        return () => {
            ws.close(1000, 'normal close')
            clearInterval(iId)
        }
    })
}
