import React, {PureComponent} from 'react'
import moment from 'moment/moment'
import {InputTimePicker, InputDatePicker} from 'ipmp-react-ui'
import {FormRow} from 'ipmp-react-ui/Form'
import PropTypes from 'prop-types'
import has from 'lodash-es/has'
import {Moment} from 'moment'

export default class UnixTimestamp extends PureComponent {
    static propTypes = {
        item: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    TYPE_TIME = 'time'
    TYPE_DATE = 'date'

    getValue() {
        return this.props.value ? moment.unix(this.props.value) : null
    }

    getDate = () => this.getValue()
    getTime = () =>
        moment.isMoment(this.getValue()) ? this.getValue().format('LT') : null

    isRequired() {
        const {item} = this.props

        if (!has(item, 'required')) {
            return true
        }

        return item.required
    }

    handleChange = (momentObj: Moment | null, type) => {
        let dateTime

        switch (type) {
            case this.TYPE_DATE:
                dateTime = moment.isMoment(momentObj) ? momentObj : null
                break
            case this.TYPE_TIME:
                if (moment.isMoment(momentObj)) {
                    let update = {
                        hour: momentObj.hour(),
                        minute: momentObj.minute(),
                    }
                    // allows us to set time when date is empty
                    dateTime = this.getValue()
                        ? this.getValue().set(update)
                        : moment().set(update)
                    break
                }
                dateTime = null
                break
        }

        this.props.onChange(dateTime ? dateTime.unix() : null)
    }

    render() {
        return (
            <FormRow>
                <InputDatePicker
                    value={this.getDate()}
                    range={false}
                    onChange={(dateMoment) =>
                        this.handleChange(dateMoment, this.TYPE_DATE)
                    }
                />
                {':'}
                <InputTimePicker
                    value={this.getTime()}
                    onChange={(dateMoment) =>
                        this.handleChange(dateMoment, this.TYPE_TIME)
                    }
                />
            </FormRow>
        )
    }
}
