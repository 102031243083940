import get from 'lodash-es/get'
import {err} from 'utils/log'

const OLD_STORE_NAME = 'interactiveUsers'
export const CURRENT_STORE_NAME = 'historyItems'

export default function nameSpaceResolve(state) {
    try {
        return get(state, [CURRENT_STORE_NAME], get(state, [OLD_STORE_NAME], []))
    } catch (error) {
        err(error.message)
        return []
    }
}
