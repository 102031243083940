import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import has from 'lodash-es/has'

import {setKeypadSoundEnabled} from 'modules/settings/actions'

import {__} from 'utils/i18n'
import {disable} from 'modules/panels/keypad/actions'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Button from 'ipmp-react-ui/Button'
import Togglebox from 'ipmp-react-ui/ToggleBox'

import Sounds from './KeypadParts/Sounds'

class KeypadBar extends Component {
    static propTypes = {
        isMute: PropTypes.bool,
        panelId: PropTypes.number.isRequired,
        keypad: PropTypes.object.isRequired,
        disable: PropTypes.func.isRequired,
    }

    state = {}

    muteToggle = () => {
        const {sounds} = this.state
        const {setKeypadSoundEnabled, isMute} = this.props
        for (const audio in sounds) {
            if (has(sounds, audio)) {
                sounds[audio].muted = this.state.isMute
            }
        }
        setKeypadSoundEnabled(!isMute)
    }

    onDisconnect = () => this.props.disable()

    render() {
        const {
            keypad: {enabled},
            panelId,
            isMute,
        } = this.props

        return (
            <Bar>
                <Togglebox
                    onChange={this.muteToggle}
                    label={__('Sound enabled')}
                    checked={isMute}
                    labelOff={__('Sound disbled')}
                />
                <BarSpace />
                <Sounds mute={!isMute} panelId={panelId} />

                <Button disabled={!enabled} onClick={this.onDisconnect}>
                    {__('Disconnect')}
                </Button>
            </Bar>
        )
    }
}

export default connect(
    ({panels, settings}, {panelId}) => {
        return {
            panelId: panelId,
            keypad: panels.keypad[panelId] || {},
            isMute: settings.alarms.isKeypadSoundEnabled,
        }
    },
    (dispatch, {panelId}) =>
        bindActionCreators(
            {
                disable: () => disable(panelId),
                setKeypadSoundEnabled,
            },
            dispatch
        )
)(KeypadBar)
