import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import isEqual from 'lodash-es/isEqual'

import {content} from 'permissions/panel/page'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Menu, {MenuLink} from 'ipmp-react-ui/Menu'
import NavTabs, {NavTabLink, NavTabPopup} from 'ipmp-react-ui/NavTabs'

import path from 'utils/path'
import {__} from 'utils/i18n'

class PanelPageTabs extends Component {
    static propTypes = {
        menu: PropTypes.object,
    }

    static defaultProps = {
        menu: {},
    }

    state = {
        items: Infinity,
    }

    // Here hack for re-render menu items if menu items are updated
    static getDerivedStateFromProps(props, state) {
        if (!isEqual(props.menu, state.menu)) {
            return {
                menu: props.menu,
                hardReset: true,
            }
        }

        return {
            menu: props.menu,
            hardReset: false,
        }
    }

    calculate = () => {
        const {children} = this.node
        this.widths = Array.from(children, (child) => child.getBoundingClientRect().width)
    }

    resize = () => {
        let {width} = this.node.getBoundingClientRect()

        // This is "other" menu item size.
        // Let's assume it's 140 px :)
        width -= 140

        let items = this.widths.filter((w) => {
            width -= w
            return width >= 0
        }).length

        if (items === this.widths.length - 1) {
            // don't hide one item, cuz it is not so smart
            items += 1
        }

        this.setState({items})
    }

    handleRef = (node) => (this.node = node)

    componentDidMount() {
        this.calculate()
        this.resize()

        window.addEventListener('resize', this.resize)
    }

    // Here hack for re-render menu items if menu items are updated
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.menu, prevProps.menu)) {
            this.calculate()
            this.resize()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    renderTabs = (key) => {
        const {
            match: {params},
            menu,
        } = this.props
        const {label, path: pathName, exact} = menu[key]

        return (
            <NavTabLink key={key} to={path(pathName, params)} exact={exact}>
                {label()}
            </NavTabLink>
        )
    }

    get links() {
        const {menu} = this.props
        const {hardReset} = this.state
        let {items} = this.state

        if (hardReset) {
            items = Infinity
        }

        return [Object.keys(menu).slice(0, items), Object.keys(menu).slice(items)]
    }

    render() {
        const {
            match: {params},
            menu,
        } = this.props
        const [directLinks, popupLinks] = this.links

        return (
            <NavTabs onRef={this.handleRef} className="panelPage-tabs">
                {directLinks.map(this.renderTabs)}

                {popupLinks.length > 0 && (
                    <NavTabPopup label={__('Other')}>
                        <Menu>
                            {popupLinks.map((key) => {
                                const {label, path: pathName} = menu[key]

                                return (
                                    <MenuLink key={key} to={path(pathName, params)}>
                                        {label()}
                                    </MenuLink>
                                )
                            })}
                        </Menu>
                    </NavTabPopup>
                )}
            </NavTabs>
        )
    }
}

export default compose(
    withRouter,
    withPermission({
        isVisible: content,
    }),
    withVisibility()
)(PanelPageTabs)
