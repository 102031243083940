import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {showRenamePartitionModal} from 'modules/modals/actions'

import {setLabel} from 'permissions/panel/labels'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'

export default compose(
    withPermission({isVisible: setLabel}),
    withVisibility(),
    connect(
        () => ({}),
        (dispatch, {partition, panelId}) =>
            bindActionCreators(
                {
                    onClick: () => showRenamePartitionModal(panelId, partition),
                },
                dispatch
            )
    )
)(Button)
