import {combineReducers} from 'redux'

import connectedPanels from './connectedPanels/reducer'
import eventsRate from './eventsRate/reducer'
import online from './online/reducer'
import allProcesses from './allProcesses/reducer'

export default combineReducers({
    connectedPanels,
    eventsRate,
    online,
    allProcesses,
})
