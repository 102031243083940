import StringValue from 'components/Configuration/Row/StringValue'
import {__} from 'utils/i18n'

const regexp = /^(2[0-3]|[01]?[0-9]):[0-5]?[0-9]$/

export default class TimeValue extends StringValue {
    getMaxLength() {
        return '00:00'.length
    }

    validate(value) {
        if (!value) {
            return this.isRequired() ? __('Required') : null
        }

        if (!regexp.test(value)) {
            return __('Incorrect time')
        }
    }
}
