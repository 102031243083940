import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {fetch} from 'modules/groups/one/actions'

export default function withGroup() {
    return connect(
        ({groups}, {match}) => ({
            group: groups.store.byIds[match.params.id],
            isLoading: !groups.store.byIds[match.params.id],
        }),

        (dispatch, {match}) =>
            bindActionCreators(
                {
                    fetch: () => fetch(parseInt(match.params.id)),
                },
                dispatch
            )
    )
}
