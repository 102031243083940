import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'
import Checkbox from 'ipmp-react-ui/Checkbox'

import {__} from 'utils/i18n'

export const validation = {
    name: {
        presence: true,
        length: {maximum: 32},
    },
}

export default class StateTypeFieldset extends PureComponent {
    static propTypes = {
        data: PropTypes.object,
    }

    static defaultProps = {
        data: {
            name: '',
        },
    }

    render() {
        const {data} = this.props
        return (
            <div>
                <Input
                    autoFocus
                    label={__('State Type Name')}
                    name="name"
                    defaultValue={data.name}
                />
                <Checkbox
                    label={__('Is Disarm')}
                    name="isDisarmState"
                    defaultChecked={data.isDisarmState}
                />
                <Checkbox
                    label={__('Has entry delay')}
                    name="hasEntryDelay"
                    defaultChecked={data.hasEntryDelay}
                />
                <Checkbox
                    label={__('Triggers exit delay')}
                    name="triggersExitDelay"
                    defaultChecked={data.triggersExitDelay}
                />
            </div>
        )
    }
}
