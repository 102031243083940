import {createAction, handleActions} from 'redux-actions'
import uniqId from 'uniqid'

export const snackShow = createAction('SNACK/SHOW', (text, action, actionLabel) => ({
    id: uniqId(),
    text,
    action,
    actionLabel,
}))

export const hide = createAction('SNACK/HIDE', (id) => id)

export default handleActions(
    {
        [snackShow](state, {payload}) {
            // maybe we should stack snacks
            return [payload]
        },

        [hide](state, {payload}) {
            return state.filter(({id}) => id !== payload)
        },
    },
    []
)
