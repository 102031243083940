import {handleActions} from 'redux-actions'

import * as actions from './actions'

const defaultState = {
    timezone: null,
    locale: null,
    timestamp: null,
    isLoading: false,
    error: null,
}

export default handleActions(
    {
        [actions.fetch](state) {
            return {
                ...state,
                isLoading: true,
            }
        },
        [actions.receive](state, {payload, error}) {
            if (error) {
                return {
                    ...state,
                    isLoading: false,
                    error: payload,
                }
            }

            return {
                isLoading: false,
                error: null,
                timezone: payload.timezone,
                locale: payload.locale,
                timestamp: payload.timestamp,
            }
        },
    },

    defaultState
)
