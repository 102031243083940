import {compose} from 'redux'
import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'
import {MenuItem} from 'ipmp-react-ui/Menu'

import {remove} from 'permissions/panel/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withPanel from 'containers/withPanel'
import withProps from 'containers/withProps'
import {withRouter} from 'react-router'

import {__} from 'utils/i18n'
import withConfirmationModal from 'containers/withConfirmationModal'

export default compose(
    withPermission({isVisible: remove}),
    withVisibility(),
    withPanel(),
    withRouter,
    withProps(({remove, history}) => ({
        Icon: IconRemove,
        children: __('Remove'),
    })),
    withConfirmationModal(
        ({remove}) => [remove],
        () => ({
            title: __('Removing panel'),
            message: __('Do you really want to remove selected panel?'),
            positive: __('Remove'),
        })
    )
)(MenuItem)
