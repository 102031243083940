import has from 'lodash-es/has'

export default function storeByIdsUpdate(state: {byIds: Object}, rows: Array) {
    if (!has(state, 'byIds')) {
        throw new Error('State don`t have byIds property')
    }

    const byIds = rows.reduce((acc, row) => {
        const old = acc[row.id]

        return {
            ...acc,
            [row.id]: {
                ...old,
                ...row,
            },
        }
    }, state.byIds)

    return {
        ...state,
        byIds,
    }
}
