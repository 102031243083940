import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'

import InteractiveBar from 'ipmp-react-ui/InteractiveBar'

import {messageByError, CUSTOM_ERROR} from 'constants/errorType'
import {__} from 'utils/i18n'
import ActivateButton from './Buttons/ActivateButton'

import {withFeatureRejection} from 'containers/withFeature'
import {selectActivation} from 'modules/features/store/selectors'
import Void from 'components/Void'

class ActivatePanelInteractiveBar extends Component {
    static propTypes = {
        isActivated: PropTypes.bool.isRequired,
        isActivating: PropTypes.bool,
        isFailed: PropTypes.bool,
        isNeo: PropTypes.bool,
        error: PropTypes.node,
    }

    getMessage() {
        const {isActivating, isFailed, error} = this.props

        if (isActivating) {
            return __('Panel activating...')
        }

        if (isFailed) {
            return messageByError(CUSTOM_ERROR, {
                message: __('Panel activation failed: %s', error),
            })
        }

        return __(
            'This panels has not been activated yet and cannot be correctly managed from PowerManager.'
        )
    }

    getButton() {
        const {id} = this.props

        return <ActivateButton panelId={id} />
    }

    render() {
        const {isActivated, isFailed} = this.props

        return (
            <InteractiveBar warning danger={isFailed} active={!isActivated}>
                {this.getMessage()}
                {this.getButton()}
            </InteractiveBar>
        )
    }
}

export default compose(
    connect(({panels}, {id}) => ({
        isActivating: panels.store.byIds[id].isActivating,
        isActivated: panels.store.byIds[id].isActivated,
        isFailed: panels.store.byIds[id].isFailed,
        error: panels.store.byIds[id].activationError,
        panelId: id,
    })),
    withFeatureRejection(selectActivation, Void)
)(ActivatePanelInteractiveBar)
