import React from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'

import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'
import {ReactComponent as IconCheck} from 'ipmp-react-ui/icons/check.svg'
import Button from 'ipmp-react-ui/Button'

import {withInteractiveUserAddPanelTable} from 'containers/withInteractiveUserPanelsTable'

const AddPanelCell = ({row: {id}, showAssignUserToPanelModal, isAssigned}) => {
    if (isAssigned) {
        return <IconCheck />
    }

    return (
        <Button
            flat
            Icon={IconPlus}
            small
            onClick={() => showAssignUserToPanelModal({panelId: id})}
        />
    )
}

AddPanelCell.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    showAssignUserToPanelModal: PropTypes.func.isRequired,
    isAssigned: PropTypes.bool.isRequired,
}

export default compose(
    withInteractiveUserAddPanelTable(),
    connect(({interactiveUserPanels: {list}}, {row: {id}}) => ({
        isAssigned: list.page.includes(id),
    }))
)(AddPanelCell)
