import Pager from 'ipmp-react-ui/Pager'
import {compose} from 'redux'

import list from 'permissions/pushBasicConfiguration/list'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withPushBasicConfigurations from 'containers/withPushBasicConfigurations'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withPushBasicConfigurations()
)(Pager)
