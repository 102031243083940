import {handleActions} from 'redux-actions'

import {reset, receive, more, unshift} from './actions'

const defaultState = {
    panelId: null,
    error: null,
    isLoading: true,
    perPage: 15,
    page: [],
    hasMore: false,
    isLoadingMore: false,
}

export default handleActions(
    {
        [reset](state, {payload: {id}}) {
            return {
                ...defaultState,
                panelId: id,
                isLoading: true,
            }
        },

        [more]: (state) => ({
            ...state,
            error: false,
            isLoadingMore: true,
        }),

        [unshift](state, {payload}) {
            const ids = payload.filter((id) => !state.page.includes(id))

            const page = [...ids, ...state.page].sort((l, r) => r - l)

            return {
                ...state,
                page,
            }
        },

        [receive](state, {error, payload}) {
            if (error) {
                return {
                    ...state,
                    error: payload,
                    isLoadingMore: false,
                    isLoading: false,
                }
            }

            const ids = payload.page.filter((id) => !state.page.includes(id))

            const page = [...state.page, ...ids].sort((l, r) => r - l)

            const hasMore = payload.total > state.perPage

            return {
                ...state,
                ...payload,
                page,
                isLoading: false,
                isLoadingMore: false,
                hasMore,
            }
        },
    },
    defaultState
)
