import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withProps from 'containers/withProps'

import Button from 'ipmp-react-ui/Button'

import {showSystemSettingsAuthorizationSettingsModal} from 'modules/modals/actions'

import {__} from 'utils/i18n'

export default compose(
    connect(
        ({
            system: {
                settings: {authorizationSettings},
            },
        }) => authorizationSettings,
        (dispatch) =>
            bindActionCreators(
                {
                    modal: showSystemSettingsAuthorizationSettingsModal,
                },
                dispatch
            ),
        (props, {modal}) => ({
            onClick: () =>
                modal({
                    ...props,
                }),
        })
    ),
    withProps(() => ({
        borderless: true,
        primary: true,
        small: true,
        label: __('Edit'),
    }))
)(Button)
