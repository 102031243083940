import {combineReducers} from 'redux'

import batchRunners from './batchRunners/reducer'
import list from './list/reducer'
import store from './store/reducer'
import widget from './widget/reducer'

export default combineReducers({
    batchRunners,
    list,
    store,
    widget,
})
