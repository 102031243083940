import withForm from 'containers/withForm'
import UserForm from './UserForm'
import {AddValidation} from './Fieldset/User'
import {addUser} from 'modules/forms/handlers'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {__} from 'utils/i18n'

export default compose(
    connect(({users}, state) => ({
        ...state,
        isNew: true,
        header: __('Add User'),
        rules: AddValidation,
    })),
    withForm(addUser)
)(UserForm)
