import {createSelector} from 'reselect'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'

export const selectConnectedPanelsIsLoading = (state) =>
    state.dashboard.connectedPanels.isLoading
export const selectConnectedPanelsData = (state) => state.dashboard.connectedPanels.data
export const selectConnectedPanelsError = (state) => state.dashboard.connectedPanels.error

export const selectConnectedPanels = createSelector(
    [
        selectConnectedPanelsIsLoading,
        selectConnectedPanelsData,
        selectConnectedPanelsError,
    ],
    (isLoading, data, error) => {
        const from = data.length
            ? maxBy(data, (dataItem) => new Date(dataItem.time).getTime()).time
            : new Date()
        const to = data.length
            ? minBy(data, (dataItem) => new Date(dataItem.time).getTime()).time
            : new Date()

        data = data.map((dataItem) => ({
            x: new Date(dataItem.time),
            y: dataItem.connected,
        }))

        return {isLoading, data, from, to, error}
    }
)
