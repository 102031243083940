const parseSuggestResponse = (data, key) => {
    if (!data[key]) {
        return {
            values: [],
            count: 0,
        }
    }

    const values = data[key].rows.map((item) => item.suggest)

    return {
        values,
        count: data[key].count === 'lot' ? Infinity : parseInt(data[key].count),
    }
}

export default function mapSuggests(fields, keysMap) {
    return (data) => {
        const arr = Array.isArray(fields) ? fields : Object.keys(fields)
        return arr.reduce((acc, key) => {
            return {
                ...acc,
                [key]: parseSuggestResponse(data, keysMap[key] || key),
            }
        }, {})
    }
}
