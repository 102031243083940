import React, {Component} from 'react'
import {compose} from 'redux'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {ifSelection} from 'containers/withSelection'

import Menu, {MenuDelimiter} from 'ipmp-react-ui/Menu'
import DropDownButton from 'ipmp-react-ui/DropDownButton'

import MarkForServiceItem from './Items/MarkForServiceItem'
import ReassignItem from './Items/ReassignItem'
import ResolveFaultsItem from './Items/ResolveFaultsItem'
import SuspendFaultsItem from './Items/SuspendFaultsItem'
import ResumeFaultsItem from './Items/ResumeFaultsItem'

import {faultsMonitoring} from 'permissions/panels/selection'

import {__} from 'utils/i18n'

class FaultsMonitoringDropDown extends Component {
    render() {
        return (
            <DropDownButton label={__('Faults')}>
                <Menu>
                    <MarkForServiceItem />
                    <ReassignItem />
                    <MenuDelimiter />
                    <ResolveFaultsItem />
                    <SuspendFaultsItem />
                    <ResumeFaultsItem />
                </Menu>
            </DropDownButton>
        )
    }
}

export default compose(
    withPermission({isVisible: faultsMonitoring}),
    withVisibility(),
    ifSelection()
)(FaultsMonitoringDropDown)
