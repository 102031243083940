import {handleActions} from 'redux-actions'
import configurationToHash from 'utils/configuration/configurationToHash'
import {set, remove, merge} from 'immutable-modify'
import has from 'lodash-es/has'

import {
    fetch,
    receive,
    changeValue,
    setExport,
    setLoading,
    setQuery,
    showChanged,
    undo,
} from './actions'

export default handleActions(
    {
        [fetch](state, {payload: {basicConfigId}}) {
            return set(state, `${basicConfigId}.isLoading`, true)
        },

        [setLoading](state, {payload: {isLoading, basicConfigId}}) {
            return set(state, `${basicConfigId}.isLoading`, isLoading)
        },

        [receive](state, {error, payload, meta: {basicConfigId}}) {
            if (error) {
                return {
                    ...state,
                    [basicConfigId]: {
                        error: payload,
                    },
                }
            }

            const {configuration, basicValues, ...restData} = payload

            return {
                [basicConfigId]: {
                    ...restData,
                    configuration,
                    values: configurationToHash(configuration),
                    changes: {},
                    errors: {},
                    basicValues,
                    query: '',
                    showChanged: false,
                    exporting: Object.keys(basicValues).reduce(
                        (acc, key) => ({
                            ...acc,
                            [key]: true,
                        }),
                        {}
                    ),
                },
            }
        },

        [changeValue](state, {payload: {basicConfigId, value, key, valid}}) {
            const old = state[basicConfigId] || {}
            const exporting = set(old.exporting, key, true)

            const isUnchanged = value === old.values[key] && has(old.basicValues, key)

            const errors =
                valid || isUnchanged
                    ? remove(old.errors, key)
                    : set(old.errors, key, true)

            const changes = isUnchanged
                ? remove(old.changes, key)
                : set(old.changes, key, value)

            return merge(state, [basicConfigId], {
                changes,
                exporting,
                errors,
            })
        },

        [setExport](state, {payload: {basicConfigId, key, exported}}) {
            const old = state[basicConfigId] || {}

            const exporting = {...old.exporting}
            const changes = {...old.changes}
            const errors = {...old.errors}

            if (exported) {
                exporting[key] = true
            } else {
                delete exporting[key]
                delete changes[key]
                delete errors[key]
            }

            if (exported === has(old.basicValues, key)) {
                delete changes[key]
                delete errors[key]
            } else if (!has(changes, key)) {
                changes[key] = old.values[key]
                delete errors[key]
            }

            return merge(state, basicConfigId, {
                exporting,
                changes,
                errors,
            })
        },

        [setQuery](state, {payload: {basicConfigId, query}}) {
            return set(state, `${basicConfigId}.query`, query)
        },

        [showChanged](state, {payload: {basicConfigId, isShowChanged}}) {
            return set(state, `${basicConfigId}.isShowChanged`, isShowChanged)
        },

        [undo](state, {payload: {basicConfigId, key}}) {
            const old = state[basicConfigId] || {}

            const exporting = {...old.exporting}
            const changes = {...old.changes}

            delete changes[key]

            if (old.basicValues && has(old.basicValues, key)) {
                exporting[key] = true
            } else {
                delete exporting[key]
            }

            return {
                ...state,
                [basicConfigId]: {
                    ...old,
                    exporting,
                    changes,
                },
            }
        },
    },
    {}
)
