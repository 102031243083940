import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/cross.svg'

import path from 'utils/path'

import NavLinkGroup from 'components/Nav/NavLinkGroup'
import NavLink from 'components/Nav/NavLink'
import scopeName from 'constants/navigationScopes'

const activateIfHasScope = (match) => match && !match.params.scope

export default class NavLinkScopesGroup extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        onRemove: PropTypes.func.isRequired,
        Coin: PropTypes.elementType,
        isPermitted: PropTypes.bool,
        scopes: PropTypes.objectOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
            })
        ),
    }

    render() {
        const {scopes, onRemove, ...props} = this.props

        props.path = path(props.link)
        props.to = path(props.link, {})

        return (
            <NavLinkGroup {...props} activate={activateIfHasScope}>
                {scopes &&
                    Object.keys(scopes).map((scope) => (
                        <NavLink
                            className="nav-link-wrapper--action"
                            key={scope}
                            name={scopeName(scopes[scope].name)}
                            to={path(props.link, {scope})}
                        >
                            <div
                                className="nav-link-action"
                                onClick={() => onRemove(props.link, scope)}
                            >
                                <IconRemove className="nav-link-action-icon" />
                            </div>
                        </NavLink>
                    ))}
            </NavLinkGroup>
        )
    }
}
