import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import has from 'lodash-es/has'

import {
    MODULE_TYPE_GPRS,
    MODULE_TYPE_BBA,
    MODULE_TYPE_WIFI,
    MODULE_TYPE_BBA_OVER_GPRS,
    MODULE_STATE_ONLINE,
    MODULE_STATE_OFFLINE,
} from 'constants/moduleTypes'

import Tooltip from 'ipmp-react-ui/Tooltip'
import moduleKATitle from 'constants/moduleKATypes'

export default class Communication extends Component {
    static propTypes = {
        moduleType: PropTypes.oneOf([
            MODULE_TYPE_GPRS,
            MODULE_TYPE_BBA,
            MODULE_TYPE_WIFI,
            MODULE_TYPE_BBA_OVER_GPRS,
        ]).isRequired,
        module: PropTypes.shape({
            connected: PropTypes.bool,
            state: PropTypes.string,
        }),
        showTooltip: PropTypes.bool,
    }

    static defaultProps = {
        showTooltip: true,
    }

    getLetter = () => {
        const {moduleType} = this.props

        switch (moduleType) {
            case MODULE_TYPE_GPRS:
                return 'G'
            case MODULE_TYPE_WIFI:
                return 'W'
            case MODULE_TYPE_BBA:
                return 'B'
            case MODULE_TYPE_BBA_OVER_GPRS:
                return (
                    <span>
                        B<sup className="communication--alt">G</sup>
                    </span>
                )
        }
    }

    getCommunicationStatusClassName() {
        const {module} = this.props

        if (!module) {
            return 'communication--none'
        }

        if (has(module, 'installed') && !module.installed) {
            return 'communication--none'
        }

        switch (true) {
            case module.connected:
                return 'communication--connected'
            case module.state === MODULE_STATE_OFFLINE:
                return 'communication--offline'
            case module.state === MODULE_STATE_ONLINE:
                return 'communication--online'
        }

        return null
    }

    render() {
        const {module, showTooltip} = this.props

        const classes = classnames(
            'communication',
            this.getCommunicationStatusClassName()
        )

        if (showTooltip) {
            return (
                <Tooltip className={classes} tooltip={moduleKATitle(module)}>
                    {this.getLetter()}
                </Tooltip>
            )
        }

        return <div className={classes}>{this.getLetter()}</div>
    }
}
