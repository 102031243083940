import __ from 'utils/i18n'

export const BATCHES_SUCCEEDED_STATUS = 'succeeded'
export const BATCHES_FAILED_STATUS = 'failed'
export const BATCHES_STARTED_STATUS = 'start'
export const BATCHES_HANDLED_STATUS = 'handled'
export const BATCHES_FAILED_TO_START_STATUS = 'failed_to_start'
export const BATCHES_NOT_STARTED_STATUS = 'not_started'
export const BATCHES_CANCELED_STATUS = 'canceled'

// used for frontend purposes
export const BATCHES_FAILED_TO_START_STATUS_MAPPED = 'failedToStart'
export const BATCHES_NOT_STARTED_STATUS_MAPPED = 'notStarted'

export const list = [
    BATCHES_SUCCEEDED_STATUS,
    BATCHES_FAILED_STATUS,
    BATCHES_STARTED_STATUS,
    BATCHES_HANDLED_STATUS,
    BATCHES_FAILED_TO_START_STATUS,
    BATCHES_NOT_STARTED_STATUS,
    BATCHES_CANCELED_STATUS,
]

// needed for correct filters functioning
export function getSnakeStatus(camelStatus) {
    switch (camelStatus) {
        case BATCHES_FAILED_TO_START_STATUS_MAPPED:
            return BATCHES_FAILED_TO_START_STATUS
        case BATCHES_NOT_STARTED_STATUS_MAPPED:
            return BATCHES_NOT_STARTED_STATUS
        default:
            return camelStatus
    }
}

export default function getBatchStatusTitle(status) {
    switch (status) {
        case BATCHES_SUCCEEDED_STATUS:
            return __('Succeeded')
        case BATCHES_FAILED_STATUS:
            return __('Failed')
        case BATCHES_STARTED_STATUS:
            return __('Started')
        case BATCHES_HANDLED_STATUS:
            return __('Handled')
        case BATCHES_FAILED_TO_START_STATUS_MAPPED:
        case BATCHES_FAILED_TO_START_STATUS:
            return __('Failed to start')
        case BATCHES_NOT_STARTED_STATUS_MAPPED:
        case BATCHES_NOT_STARTED_STATUS:
            return __('Not Started')
        case BATCHES_CANCELED_STATUS:
            return __('Canceled')

        default:
            console.warn(`Unknown batch status: ${status}`)
            return status
    }
}
