import React from 'react'
import withLifeCycle from 'containers/withLifeCycle'
import {compose} from 'redux'

import Table from 'ipmp-react-ui/Table'
import {ReactComponent as IconCheck} from 'ipmp-react-ui/icons/check.svg'

import withLoader from 'containers/withLoader'
import withProps from 'containers/withProps'
import withTableColumnsPermission from 'containers/withTableColumnsPermission'
import {__} from 'utils/i18n'
import withZoneTypes from 'containers/withZoneTypes'
import RemoveZoneType from 'pages/EventRules/ZoneTypes/TableCell/RemoveZoneType'
import EditZoneType from 'pages/EventRules/ZoneTypes/TableCell/EditZoneType'

const columns = [
    {
        name: () => __('Zone Type'),
        render: ({name}) => name,
    },
    {
        name: () => __('Entry\\Exit delay'),
        render: ({isDelayEnabled}) => isDelayEnabled && <IconCheck className="success" />,
    },
    {
        tooltip: false,
        width: 50,
        fixed: true,
        component: EditZoneType,
    },
    {
        tooltip: false,
        width: 50,
        fixed: true,
        component: RemoveZoneType,
    },
]

export default compose(
    withZoneTypes(),
    withLifeCycle({
        onMount({init, startPoll}) {
            init()
            startPoll()
        },

        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withLoader(),
    withProps({
        columns,
        fullHeight: true,
        emptyMessage: () => __('No zone types found'),
    }),
    withTableColumnsPermission()
)(Table)
