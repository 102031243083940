import {put, select, take} from 'redux-saga/effects'
import find from 'lodash-es/find'

import {
    fetchInteractiveUserPanels,
    fetchPanelsInteractiveUser,
} from 'modules/panels/recent/actions'
import {getPanelId} from 'utils/panelIdMatchSelector'
import {ROLE_MASTER} from 'constants/interactiveUserRoles'
import {update} from 'modules/panelInteractiveUsers/store/actions'

export default function* updateRecentWidget() {
    yield take(update)

    const panelInteractiveUsers = yield select((state) => {
        const panelId = getPanelId(state)

        return state.panelInteractiveUsers.store[panelId]
    })
    const masterUser = find(panelInteractiveUsers, {role: ROLE_MASTER})

    if (masterUser) {
        yield put(fetchInteractiveUserPanels(Number(masterUser.interactiveUserId)))
        yield put(fetchPanelsInteractiveUser())
    }
}
