import {createSelector} from 'reselect'

export const selectAllProcessesIsLoading = (state) =>
    state.dashboard.allProcesses.isLoading
export const selectAllProcessesData = (state) => state.dashboard.allProcesses.data
export const selectAllProcessesError = (state) => state.dashboard.allProcesses.error

export const selectAllProcesses = createSelector(
    [selectAllProcessesIsLoading, selectAllProcessesData, selectAllProcessesError],
    (isLoading, data, error) => {
        const {dataset, labels} = data.reduce(
            (acc, dataItem) => {
                return {
                    dataset: [...acc.dataset, dataItem.count],
                    labels: [...acc.labels, dataItem.status],
                }
            },
            {dataset: [], labels: []}
        )

        return {isLoading, dataset, labels, error}
    }
)
