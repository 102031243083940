import React, {Component, Fragment} from 'react'

export default class TableWithBlind extends Component {
    state = {
        row: null,
    }

    onOpenBlind = (row) => this.setState({row})

    onCloseBlind = () => this.setState({row: null})

    render() {
        const {row} = this.state
        const {children} = this.props
        const BlindComponent = children[0]
        const TableComponent = children[1]

        return (
            <Fragment>
                <BlindComponent row={row} onClose={this.onCloseBlind} />
                <TableComponent {...this.props} onRowClick={this.onOpenBlind} />
            </Fragment>
        )
    }
}
