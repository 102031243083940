import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withLoader from 'containers/withLoader'
import * as actions from 'modules/system/settings/actions'

import {ScrollView} from 'ipmp-react-ui/Layout'
import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Card, {CardContent, CardHeader} from 'ipmp-react-ui/Card'

import BrokersTable from './BrokersTable'
import AddBrokerButton from './AddBrokerButton'

import {__} from 'utils/i18n'

export class Brokers extends Component {
    render() {
        return (
            <ScrollView>
                <Card>
                    <CardHeader>
                        <Bar>
                            <h1 className="title title--large">
                                {__('Message Brokers')}
                            </h1>
                            <BarSpace />
                            <AddBrokerButton />
                        </Bar>
                    </CardHeader>
                    <CardContent>
                        <BrokersTable />
                    </CardContent>
                </Card>
            </ScrollView>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {messageBrokers},
            },
        }) => ({
            isLoading: messageBrokers.isLoading,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    fetchMessageBrokers: actions.fetchMessageBrokers,
                },
                dispatch
            )
    ),
    withLoader(({fetchMessageBrokers}) => fetchMessageBrokers())
)(Brokers)
