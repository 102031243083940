import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {fetchUpgradeStatusRunnerByBatch} from 'modules/upgradeStatus/list/actions'
import {selectBatchPackages} from 'modules/upgradeStatus/store/selectors'

import Packages from 'components/UpgradeStatus/Packages'

const trigger = ({title, fetch}) => <span onClick={fetch}>{title}</span>

export const ConnectedTrigger = connect(null, (dispatch, {batchId}) =>
    bindActionCreators(
        {
            fetch: () => fetchUpgradeStatusRunnerByBatch(batchId),
        },
        dispatch
    )
)(trigger)

export default connect(selectBatchPackages)(Packages)
