import {createAction} from 'redux-actions'

export const fetch = createAction('GROUPS/SELECT/FECTH', (prefix, count, {values}) => ({
    prefix,
    count,
    values,
}))
export const setLoading = createAction('GROUPS/SELECT/SET_LOADING', (isLoading) => ({
    isLoading,
}))
