import React, {PureComponent} from 'react'
import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'

import CustomerFieldSet, {validation} from './Fieldset/Customer'
import {connect} from 'react-redux'
import {compose} from 'redux'
import withForm from 'containers/withForm'
import {editPanelCustomerInfo} from 'modules/forms/handlers'

export class EditPanelCustomerInfo extends PureComponent {
    handle = (data) => {
        const {panelId, handle} = this.props
        handle(panelId, data)
    }

    render() {
        const {panel, ...props} = this.props

        return (
            <ModalCardForm
                onSubmit={this.handle}
                rules={validation}
                header={__('Edit Panel Customer Info')}
                confirmOnDismiss
                {...props}
            >
                <CustomerFieldSet data={panel ? panel.contact : {}} />
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(({panels}, {panelId}) => ({
        panel: panels.store.byIds[panelId] || {},
    })),
    withForm(editPanelCustomerInfo)
)(EditPanelCustomerInfo)
