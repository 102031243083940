import {get} from 'api/http'
import createRowsResponseParser from 'api/base/createRowsResponseParser'

export function fetch() {
    return get('/units_report/getallmeteometrics').then(
        createRowsResponseParser(({rmm_id, rmm_name}) => ({
            id: parseInt(rmm_id),
            name: rmm_name,
        }))
    )
}
