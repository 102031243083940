import React from 'react'

import Page from 'ipmp-react-ui/Page'

import ZoneTypesTable from './ZoneTypesTable'
import ZoneTypesGridBar from './ZoneTypesGridBar'

export default class ZoneTypesPage extends Page {
    renderBar() {
        return <ZoneTypesGridBar />
    }

    renderContent() {
        return <ZoneTypesTable />
    }
}
