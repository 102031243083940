import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import EventRuleSteps from 'modals/EventRule/Fieldset/EventRuleSteps'
import EventRuleStepContent from 'modals/EventRule/Fieldset/EventRuleStepContent'

export default class EventRuleFieldset extends PureComponent {
    static propTypes = {
        data: PropTypes.object,
    }

    render() {
        const {currentStep} = this.props

        return (
            <section className="eventRules-master">
                <EventRuleSteps currentStep={currentStep} />
                <EventRuleStepContent currentStep={currentStep} />
            </section>
        )
    }
}
