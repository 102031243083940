import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalCardForm from 'ui/ModalCardForm'
import {__, __n} from 'utils/i18n'
import InputDatePicker from 'ipmp-react-ui/InputDatePicker'
import Select from 'ipmp-react-ui/Select'
import {Option} from 'ipmp-react-ui/Select'
import Radiobox from 'ipmp-react-ui/Radiobox'
import {compose} from 'redux'
import withForm from 'containers/withForm'
import {scheduleRri} from 'modules/forms/handlers'
import {parseSingleValue} from 'utils/dateParser'

class ScheduleRRI extends Component {
    constructor(props) {
        super(props)

        if (props.next === null) {
            this.state = {
                disabledChecked: true,
                enabledChecked: false,
                next: null,
                repetition: undefined,
            }
        } else {
            this.state = this.enabled
        }
    }

    rules = {
        next: {
            presence: (value, attributes) => attributes.mode !== 'cancel',
            datetime: {
                presence: (value, attributes) => attributes.mode !== 'cancel',
                dateOnly: true,
            },
        },

        repetition: {
            presence: {allowEmpty: true},
        },
    }

    handle = ({mode, next, repetition}) => {
        const {handle, panelIds} = this.props
        const date = parseSingleValue(next)

        if (mode === 'cancel') {
            handle(panelIds, null, null)
        } else {
            handle(panelIds, date, parseInt(repetition))
        }
    }

    get enabled() {
        const {next, repetition} = this.props

        return {
            disabledChecked: false,
            enabledChecked: true,
            next,
            repetition,
        }
    }

    get disabled() {
        return {
            disabledChecked: true,
            enabledChecked: false,
            next: null,
            repetition: undefined,
        }
    }

    checkEnabled = () => this.setState(this.enabled)

    checkDisabled = () => this.setState(this.disabled)

    render() {
        const {panelIds, serial, ...props} = this.props
        const {next, repetition, disabledChecked, enabledChecked} = this.state

        const single = panelIds.length === 1

        const header = single
            ? __('Schedule %s remote inspection', serial)
            : __('Schedule %d panels remote inspection', panelIds.length)

        return (
            <ModalCardForm
                confirmOnDismiss
                header={header}
                onSubmit={this.handle}
                rules={this.rules}
                {...props}
            >
                {single && (
                    <Radiobox
                        {...{
                            name: 'mode',
                            value: 'cancel',
                            checked: disabledChecked,
                            onChange: this.checkDisabled,
                            label: __('Disabled'),
                        }}
                    />
                )}

                {single && (
                    <Radiobox
                        {...{
                            name: 'mode',
                            value: 'schedule',
                            checked: enabledChecked,
                            onChange: this.checkEnabled,
                            label: __('Enabled'),
                        }}
                    />
                )}

                <InputDatePicker
                    {...{
                        name: 'next',
                        label: __('Next Inspection'),
                        range: false,
                        onFocus: this.checkEnabled,
                        onChange: this.checkEnabled,
                        defaultValue: next,
                    }}
                />

                <Select
                    {...{
                        name: 'repetition',
                        label: __('Choose repetition'),
                        onFocus: this.checkEnabled,
                        defaultValue: repetition,
                    }}
                >
                    <Option value={0} label={__('Once')} />

                    {[1, 3, 6, 9].map((key) => (
                        <Option
                            {...{
                                key,
                                value: key,
                                label: __n('%d month', '%d months', key),
                            }}
                        />
                    ))}
                </Select>
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(({remoteInspections}, {panelIds}) => {
        if (panelIds.length === 1) {
            return remoteInspections.store[panelIds[0]]
        }

        return {}
    }),
    withForm(scheduleRri)
)(ScheduleRRI)
