import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'

import {__} from 'utils/i18n'

export const validation = {
    description: {
        presence: true,
        length: {maximum: 32},
    },
    cid: {
        presence: true,
        length: {
            is: 4,
        },
    },
    sia: {
        presence: true,
        length: {
            is: 3,
        },
    },
}

export default class EventTypeFieldset extends PureComponent {
    static propTypes = {
        data: PropTypes.object,
    }

    static defaultProps = {
        data: {
            description: '',
            cid: '',
            sia: '',
        },
    }

    render() {
        const {data} = this.props
        return (
            <div>
                <Input
                    autoFocus
                    label={__('Event Type Name')}
                    name="description"
                    defaultValue={data.description}
                />
                <Input label={__('cid')} name="cid" defaultValue={data.cid} />
                <Input label={__('sia')} name="sia" defaultValue={data.sia} />
            </div>
        )
    }
}
