import mapKeys from 'lodash-es/mapKeys'

import {post, get} from 'api/http'
import {mapProcess} from 'api/processes'
import mapValidationErrors from 'api/base/mapValidationErrors'

import {map, mapOnline, keysMap, mapUsers, mapIsPanelActivated} from 'api/panel/info.map'

export function retrieveData(panelId) {
    return get('/unit_general/form', {unt_id: panelId}).then(map)
}

export function isOnline(panelId) {
    return get('/unit_general/isonline', {unt_id: panelId}).then(mapOnline)
}

export function updateData(panelId, {vendor, ...form}) {
    return post('/units_all/edit', {
        unt_id: panelId,
        vendor,
        form: mapKeys(form, (value, key) => keysMap[key] || key),
    }).catch((e) => mapValidationErrors(e, keysMap))
}

export function updateInfo(panelId, form) {
    return post('/units_all/editinfo', {
        unt_id: panelId,
        form: mapKeys(form, (value, key) => keysMap[key] || key),
    }).catch((e) => mapValidationErrors(e, keysMap))
}

export function refreshState(panelId, batchId) {
    return post('/pmaxstate/getstate', {
        unt_id: panelId,
        batch_id: batchId,
    }).then(mapProcess)
}

export function createNew({vendor, ...data}) {
    let form = mapKeys(data, (value, key) => keysMap[key] || key)
    return post('/units_all/add', {vendor, form}).catch((e) =>
        mapValidationErrors(e, keysMap)
    )
}

export function activatePanel(panelId, installerCode, batchId) {
    return post('/unit_general/activate', {
        unt_id: panelId,
        installerCode,
        batch_id: batchId,
    })
        .then(mapProcess)
        .catch(mapValidationErrors)
}

export function getUsers(panelId) {
    return get('/unit_general/getusers', {unt_id: panelId}).then(mapUsers)
}

export function isPanelActivating(panelId) {
    return get('/unit_general/isactivating', {unt_id: panelId}).then(mapIsPanelActivated)
}
