import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import List from 'ipmp-react-ui/List'

import {SettingsPageBlock} from '../SettingsPageBlock'
import EditCommonConnectedButton from './Buttons/EditCommonConnectedButton'

import {__} from 'utils/i18n'

class CommonConnected extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            systemIdRegex: PropTypes.string,
            isPanelRemoveForever: PropTypes.bool,
        }),
    }

    render() {
        const {data} = this.props

        return (
            <div>
                <h1 className="card-header">
                    {__('Common Connected')}
                    <EditCommonConnectedButton />
                </h1>

                <List className="card-content" twoCols>
                    {this.renderListItem(__("System ID's Mask"), data.systemIdRegex)}
                    {this.renderListItem(
                        __('Remove Panel by Resetting the Receiver IP'),
                        data.isPanelRemoveForever
                    )}
                </List>
            </div>
        )
    }
}

export default compose(
    connect(({system: {settings: {commonConnected}}}) => ({...commonConnected}))
)(CommonConnected)
