import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import list from 'permissions/groups/list'

import {fetch, setQuery} from 'modules/groups/list/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Search from 'components/Search/Search'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    connect(
        (state) => ({
            filters: [],
            selected: [],
            query: state.groups.list.query,
        }),

        (dispatch) =>
            bindActionCreators(
                {
                    apply: fetch,
                    onQuery: setQuery,
                },
                dispatch
            )
    )
)(Search)
