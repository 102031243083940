import React, {Component, PureComponent} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from 'ipmp-react-ui/Button'
import Fab from 'ipmp-react-ui/Fab'
import {ReactComponent as IconClose} from 'ipmp-react-ui/icons/cross.svg'

import __ from 'utils/i18n'

class NavBlind extends Component {
    static propTypes = {
        refHandler: PropTypes.func,
    }

    refHandler = (...args) => this.props.refHandler && this.props.refHandler(...args)

    get className() {
        return classnames('navBlind', this.props.className)
    }

    render() {
        const {children} = this.props

        return (
            <section className={this.className} ref={this.refHandler}>
                {children}
            </section>
        )
    }
}

export class NavBlindHeader extends Component {
    static propTypes = {
        onClose: PropTypes.func,
    }

    onClose = (event) => this.props.onClose && this.props.onClose(event)

    get className() {
        return classnames('navBlind-title', this.props.className)
    }

    render() {
        return (
            <header className={this.className}>
                {this.props.children}
                <Fab
                    mini
                    borderless
                    className="navBlind-close"
                    Icon={IconClose}
                    onClick={this.onClose}
                />
            </header>
        )
    }
}

export class NavBlindList extends Component {
    get className() {
        return classnames('navBlind-list', this.props.className)
    }

    render() {
        return <div className={this.className}>{this.props.children}</div>
    }
}

export class NavBlindListMore extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        moreLabel: PropTypes.string,
    }

    onClick = (event) => this.props.onClick && this.props.onClick(event)

    get className() {
        return classnames('navBlind-list-moreBtnHolder', this.props.className)
    }

    render() {
        const {moreLabel} = this.props

        return (
            <div className={this.className}>
                <Button primary onClick={this.onClick} label={moreLabel || __('More')} />
            </div>
        )
    }
}

export default NavBlind
