import BoardFilter from 'components/Search/Board/BoardFilter'
import React from 'react'
import PropTypes from 'prop-types'

import Filter from 'components/Search/Filters/Filter'
import InputDatePicker from 'ipmp-react-ui/InputDatePicker'
import {__} from 'utils/i18n'

export default class SuggestBoardFilter extends BoardFilter {
    static propTypes = {
        filter: PropTypes.instanceOf(Filter).isRequired,
        selected: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDeselect: PropTypes.func.isRequired,
    }

    className = 'search-filter--date'

    getFastItems(fastItemsCount) {
        return this.props.filter.getItems()
    }

    getActiveCustomFilter() {
        const {selected, filter} = this.props
        return selected.find(
            (item) => item.name === filter.name && item.value instanceof Object
        )
    }

    handleChange = (from, to) => {
        const {filter, onSelect, onDeselect} = this.props
        const active = this.getActiveCustomFilter()

        if (!from) {
            active && onDeselect(active)
            return
        }

        if (!to) {
            onSelect(filter.createItem(from))
            return
        }

        from = from.toDate()
        to = to.toDate()
        onSelect(filter.createItem({from, to}))
    }

    renderAutocomplete(fastItemsCount) {
        const item = this.getActiveCustomFilter()

        return (
            <InputDatePicker
                range
                value={item ? item.value : null}
                placeholder={__('Choose date')}
                onChange={this.handleChange}
            />
        )
    }

    renderSelectedItems(fastItems) {
        return null
    }
}
