import React from 'react'

import Page from 'ipmp-react-ui/Page'

import EventRulesSearch from './EventRulesSearch'
import EventRulesTable from './EventRulesTable'
import EventRulesGridBar from './EventRulesGridBar'

export default class EventRulesPageContent extends Page {
    renderTop() {
        return <EventRulesSearch />
    }

    renderBar() {
        return <EventRulesGridBar />
    }

    renderContent() {
        return <EventRulesTable />
    }
}
