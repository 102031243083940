import {compose} from 'redux'
import {__} from 'utils/i18n'
import Button from 'ipmp-react-ui/Button'
import get from 'lodash-es/get'

import {withDeviceActions} from 'containers/withDevices'
import withConfirmation from 'containers/withConfirmation'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'

import {remove} from 'permissions/panel/devices/actions'

export default compose(
    withPermission(remove),
    withDeviceActions(),
    withProps(({removeDevice, device, isPermitted}) => ({
        onClick: removeDevice,
        title: __('Remove device'),
        message: __('Do you really want to remove device?'),
        positive: __('Remove'),
        label: __('Remove'),
        className: 'devices-sidebar-button btn--flat',
        isVisible: get(device, ['isRemovable'], false) && isPermitted,
    })),
    withVisibility(),
    withConfirmation()
)(Button)
