import {createAction} from 'redux-actions'
import toArray from 'utils/toArray'

export const update = createAction(
    'PANEL_INSTALLERS/STORE/UPDATE',
    toArray,
    (_, panelId) => ({panelId})
)
export const accept = createAction(
    'INSTALLER_PANELS/STORE/ACCEPT',
    toArray,
    (_, panelId) => ({panelId})
)
export const reject = createAction(
    'INSTALLER_PANELS/STORE/REJECT',
    toArray,
    (_, panelId) => ({panelId})
)
export const revert = createAction(
    'INSTALLER_PANELS/STORE/REVERT',
    toArray,
    (_, panelId) => ({panelId})
)
export const purge = createAction('PANEL_INSTALLERS/STORE/PURGE')
