export const DEFAULT_THEME = 'sanMarino'

export default {
    sanMarino: {
        title: 'SanMarino',
        classList: ['theme--sanMarino'],
    },
    valencia: {
        title: 'Valencia',
        classList: ['theme--dark', 'theme--valencia'],
    },
}
