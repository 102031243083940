import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Modal from 'ipmp-react-ui/Modal'
import Tabs, {VERTICAL_TABS_ALIGN} from 'ipmp-react-ui/Tabs'
import {ReactComponent as IconSettings} from 'ipmp-react-ui/icons/settings.svg'
import {ReactComponent as IconSound} from 'ipmp-react-ui/icons/sound.svg'

import Card from 'ipmp-react-ui/Card'

import {__} from 'utils/i18n'
import CommonSettings from './EditSettings/CommonSettings'
import AlarmsSettings from './EditSettings/AlarmsSettings'

export default class EditSettings extends Component {
    static propTypes = {
        hide: PropTypes.func.isRequired,
    }

    state = {}

    handleClose = () => this.props.hide()

    render() {
        return (
            <Modal onClose={this.handleClose}>
                <Card className="editSettingsCard">
                    <Tabs isTabLabel={false} align={VERTICAL_TABS_ALIGN}>
                        <CommonSettings
                            Icon={IconSettings}
                            name={__('Edit Settings')}
                            onClose={this.handleClose}
                        />
                        <AlarmsSettings
                            Icon={IconSound}
                            name={__('Events Supervision settings')}
                            onClose={this.handleClose}
                        />
                    </Tabs>
                </Card>
            </Modal>
        )
    }
}
