import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import withProps from 'containers/withProps'
import {showEditZoneTypeModal} from 'modules/modals/actions'

export default compose(
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                showEditZoneTypeModal: showEditZoneTypeModal,
            },
            dispatch
        )
    ),
    withProps(({row: {id}, showEditZoneTypeModal}) => ({
        Icon: IconEdit,
        flat: true,
        small: true,
        primary: true,
        onClick: () => showEditZoneTypeModal(id),
    }))
)(Button)
