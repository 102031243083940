import timezones from 'utils/timezones'

const serverFormat = 'YYYY-MM-DD hh:mm:ss'

export function formatDate(date) {
    const m = timezones.server(date)

    if (!m.isValid()) {
        return date
    }

    return date.format(serverFormat)
}

export default function parseDate(date) {
    if (date === '0000-00-00 00:00:00' || date === null) {
        return null
    }
    const m = timezones.server(date, serverFormat)

    if (!m.isValid()) {
        return null
    }

    return m.toDate()
}
