const messages = []

const createLogger = (level) => (msg) => {
    if (messages[level + '.' + msg]) {
        return
    }
    messages[level + '.' + msg] = true
    console[level](msg)
}

export const err = createLogger('error')
export const warn = createLogger('warn')
export const info = createLogger('info')
export const trace = createLogger('trace')

export function devFatal(msg) {
    console.error(msg)
    throw Error(msg)
}
