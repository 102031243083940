import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import has from 'lodash-es/has'

import {save} from 'permissions/panel/locations/actions'
import page from 'permissions/panel/locations/page'

import withLoader from 'containers/withLoader'
import withLifeCycle from 'containers/withLifeCycle'
import withProcessLoader from 'containers/withProcessLoader'
import {withPermission, withRejection} from 'containers/withPermission'

import * as actions from 'modules/panels/locations/actions'

import {ScrollView} from 'ipmp-react-ui/Layout'
import {__} from 'utils/i18n'
import Layout from 'ipmp-react-ui/Layout'
import Button from 'ipmp-react-ui/Button'
import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Checkbox from 'ipmp-react-ui/Checkbox'
import Error from 'ipmp-react-ui/Error'

import LocationRow from './LocationRow'

export class Locations extends PureComponent {
    static propTypes = {
        values: PropTypes.object,
        locations: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                custom: PropTypes.string.isRequired,
                editable: PropTypes.bool,
            })
        ).isRequired,
        change: PropTypes.func.isRequired,
        persist: PropTypes.func.isRequired,
        characters: PropTypes.string,
        maxLength: PropTypes.number,
        process: PropTypes.object,
        isNeo: PropTypes.bool,
    }

    static defaultProps = {
        values: {},
    }

    state = {filter: true}

    handleFilterChange = (e) => {
        this.setState({filter: e.target.checked})
    }

    getLocations() {
        const {locations, isEditable} = this.props

        if (!this.state.filter || !isEditable) {
            return locations
        }

        return locations.filter(({editable}) => !!editable)
    }

    render() {
        const {
            isEditable,
            locations,
            values,
            maxLength,
            persist,
            characters,
            change,
            isNeo,
        } = this.props

        if (isNeo) {
            return <Error title={__('Neo panels has no locations support')} />
        }

        if (!locations || locations.length === 0) {
            return (
                <Error
                    title={__('No editable locations')}
                    message={__('Editable locations were not found for current unit.')}
                />
            )
        }

        return (
            <Layout vertical className="panelPage-content">
                {isEditable && (
                    <Bar>
                        <Checkbox
                            label={__('Show only editable locations')}
                            defaultChecked
                            onChange={this.handleFilterChange}
                        />

                        <BarSpace />

                        <Button
                            onClick={persist}
                            disabled={Object.keys(values).length === 0}
                        >
                            {__('Save locations')}
                        </Button>
                    </Bar>
                )}

                <ScrollView className="card">
                    <div className="locations-list">
                        {this.getLocations().map(({id, name, custom, editable}) => (
                            <LocationRow
                                {...{
                                    key: id,
                                    id,
                                    onChange: change,
                                    name,
                                    isChanged: has(values, id),
                                    value: has(values, id) ? values[id] : custom,
                                    isDisabled: !editable,
                                    isReadOnly: !isEditable,
                                    maxLength,
                                    characters,
                                }}
                            />
                        ))}
                    </div>
                </ScrollView>
            </Layout>
        )
    }
}

export default compose(
    withPermission({
        isAllowed: page,
        isEditable: save,
    }),
    withRejection(),
    connect(
        ({panels}, {match}) => {
            const panelId = parseInt(match.params.id)
            const locations = panels.locations[panelId]
            const {isNeo} = panels.store.byIds[panelId]

            return {
                panelId,
                isNeo,
                isLoading: !isNeo && !locations,
                ...locations,
            }
        },
        (dispatch, {match}) => {
            const panelId = parseInt(match.params.id)

            return bindActionCreators(
                {
                    fetch: () => actions.fetch(panelId),
                    change: (name, value) => actions.change(name, value, panelId),
                    persist: () => actions.persist(panelId),
                    startPolling: () => actions.startPolling(panelId),
                    stopPolling: () => actions.stopPolling(panelId),
                },
                dispatch
            )
        }
    ),
    withLifeCycle({
        onMount: ({isNeo, startPolling}) => {
            if (!isNeo) {
                startPolling()
            }
        },

        onUnmount: ({stopPolling}) => stopPolling(),
    }),
    withLoader(),
    withProcessLoader(
        () => __('Saving…'),
        ({fetch}) => fetch()
    )
)(Locations)
