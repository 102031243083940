import {createAction} from 'redux-actions'
import createListActions from 'modules/higherOrder/createListActions'

export const fetch = createAction('INSTALLER_PANELS/LIST/FETCH', (installerId) => ({
    installerId,
}))
export const init = createAction('INSTALLER_PANELS/LIST/INIT', (installerId) => ({
    installerId,
}))

export const resetWithOutFetch = createAction('INSTALLER_PANELS/LIST/RESET_WITHOUT_FETCH')

export const {receive, reset, setStart, revertRemove, purgeList} = createListActions(
    'INSTALLER_PANELS/LIST'
)

export const assignPanel = createAction('INSTALLER_PANELS/LIST/ASSIGN_PANEL')
export const unassignPanel = createAction('INSTALLER_PANELS/LIST/UNASSIGN_PANEL')
export const unassignInstaller = createAction('INSTALLER_PANELS/LIST/UNASSIGN_INSTALLER')
export const unassignInstallerSuccess = createAction(
    'INSTALLER_PANELS/LIST/UNASSIGN_INSTALLER_SUCCESS',
    ({panelId, installerId}) => ({panelId, installerId})
)
