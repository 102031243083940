import React, {Component} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'

import {set as isSetAllowed} from 'permissions/panel/state/actions'
import page from 'permissions/panel/state/page'

import withLoader from 'containers/withLoader'
import {withPermission, withRejection} from 'containers/withPermission'

import {sendState} from 'modules/panels/state/actions'
import {
    selectActivePartitions,
    selectActivePartitionsState,
} from 'modules/panels/state/selectors'

import PartitionsTable from './PartitionsTable'
import PanelStateSideBar from './PanelStateSideBar'
import Layout, {ScrollView} from 'ipmp-react-ui/Layout'

class PanelState extends Component {
    static propTypes = {
        partitions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
            })
        ).isRequired,
        sendState: PropTypes.func.isRequired,
    }

    state = {
        partitionId: null,
    }

    componentDidMount() {
        const partition = this.props.partitions[0]
        partition && this.handleSelectPartition(partition)
    }

    static getDerivedStateFromProps({partitions}, prevState) {
        const partitionNoLongerAvailable =
            prevState.partitionId &&
            partitions.every((partition) => partition.id !== prevState.partitionId)

        return {
            partitionId: partitionNoLongerAvailable
                ? partitions[0]?.id
                : prevState.partitionId,
        }
    }

    handleSelectPartition = ({id}) => this.setState({partitionId: id})

    handleDeselect = () => this.setState({partitionId: null})

    renderPartitionStates() {
        const {isSetAllowed, partitions, partitionStates, sendState} = this.props
        const {partitionId} = this.state

        if (!partitions) {
            return null
        }

        return (
            <PartitionsTable
                rows={partitions}
                onRowClick={this.handleSelectPartition}
                active={partitions.find((partition) => partition.id === partitionId)}
                sendState={sendState}
                partitionStates={partitionStates}
                isSetAllowed={isSetAllowed}
            />
        )
    }

    renderSidebar() {
        const {partitionId} = this.state
        const {panelId, partitions} = this.props

        if (partitionId) {
            return (
                <PanelStateSideBar
                    panelId={panelId}
                    partition={partitions.find(
                        (partition) => partition.id === partitionId
                    )}
                    onClose={this.handleDeselect}
                />
            )
        }

        return null
    }

    render() {
        return (
            <Layout className="panelState">
                <ScrollView>{this.renderPartitionStates()}</ScrollView>

                {this.renderSidebar()}
            </Layout>
        )
    }
}

export default compose(
    withPermission({isAllowed: page, isSetAllowed}),
    withRejection(),
    connect(
        (state, {match}) => {
            const panelId = parseInt(match.params.id)
            const panel = get(state, ['panels', 'state', 'byIds', panelId])
            const {error} = panel || {}
            const partitions = selectActivePartitions(state, panelId)
            const partitionStates = selectActivePartitionsState(state, panelId)

            return {
                panelId,
                partitions,
                partitionStates,
                error,
                isLoading: !panel,
            }
        },

        (dispatch, {match}) => {
            const panelId = parseInt(match.params.id)

            return bindActionCreators(
                {
                    sendState: (...args) => sendState(panelId, ...args),
                },
                dispatch
            )
        }
    ),
    withLoader()
)(PanelState)
