import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconEmail} from 'ipmp-react-ui/icons/email.svg'
import {ReactComponent as IconEmailSent} from 'ipmp-react-ui/icons/email-checked.svg'

export default class ResendEmail extends Component {
    static propTypes = {
        sendEmail: PropTypes.func.isRequired,
        result: PropTypes.shape({
            id: PropTypes.number,
            isEmailSent: PropTypes.bool,
        }),
    }

    send = (e) => {
        const {
            sendEmail,
            result: {isEmailSent, id},
        } = this.props
        e.stopPropagation()
        !isEmailSent && sendEmail([id])
    }

    render() {
        const {result} = this.props

        if (!result) {
            return null
        }

        return (
            <a onClick={this.send} className="rri-action">
                {result.isEmailSent ? (
                    <IconEmailSent className="rri-action-icon rri-action-icon--sent" />
                ) : (
                    <IconEmail className="rri-action-icon" />
                )}
            </a>
        )
    }
}
