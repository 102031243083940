import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'

import {ReactComponent as IconRun} from 'ipmp-react-ui/icons/run.svg'
import {ReactComponent as IconNotAvailable} from 'ipmp-react-ui/icons/not-available.svg'
import Spinner from 'ipmp-react-ui/Spinner'

import Video from 'components/Video'
import Slider from 'ipmp-react-ui/Slider'
import isEqual from 'lodash-es/isEqual'

class CameraList extends PureComponent {
    static propTypes = {
        cameras: PropTypes.arrayOf(
            PropTypes.shape({
                current: PropTypes.shape({
                    camera: PropTypes.number,
                    frames: PropTypes.arrayOf(PropTypes.string),
                    isClosed: PropTypes.bool,
                    location: PropTypes.string,
                    preview: PropTypes.string,
                    process: PropTypes.shape({
                        token: PropTypes.string,
                        isRunning: PropTypes.bool,
                    }),
                    time: PropTypes.string,
                    video: PropTypes.shape({
                        mp4: PropTypes.string,
                        flv: PropTypes.string,
                        webm: PropTypes.string,
                    }),
                    zone: PropTypes.string,
                }),
                history: PropTypes.arrayOf(
                    PropTypes.shape({
                        camera: PropTypes.number,
                        frames: PropTypes.arrayOf(PropTypes.string),
                        isClosed: PropTypes.bool,
                        location: PropTypes.string,
                        preview: PropTypes.string,
                        process: PropTypes.shape({
                            token: PropTypes.string,
                            isRunning: PropTypes.bool,
                        }),
                        time: PropTypes.string,
                        video: PropTypes.shape({
                            mp4: PropTypes.string,
                            flv: PropTypes.string,
                            webm: PropTypes.string,
                        }),
                        zone: PropTypes.string,
                    })
                ),
                camera: PropTypes.number,
            })
        ),
        onStartRecord: PropTypes.func,
        isRequestAllowed: PropTypes.bool,
        isExportAllowed: PropTypes.bool,
        device: PropTypes.object,
        feature: PropTypes.object,
        time: PropTypes.string,
    }

    constructor(props) {
        super(props)
        this.state = {
            active: 0,
            history: [],
            changedCamera: false,
            video: this.props.cameras[0].current || this.props.cameras[0],
            currents: this.props.cameras.map((camera) => {
                return camera.current
            }),
        }
    }

    getActiveCurrentVideo = (index) => {
        return this.props.cameras[index].current || this.props.cameras[index]
    }

    getCurrentHistory(active) {
        if (this.props.isLiveVideo) {
            const {history} = this.props.cameras[active]
            return history ? history.filter(({video}) => !isEmpty(video)) : []
        }
    }

    showCamera(active) {
        this.setState({
            active,
            video: this.getActiveCurrentVideo(active),
            history: this.getCurrentHistory(active),
            changedCamera: true,
        })
    }

    onVideoSelect = (video) => {
        this.setState(() => {
            return {video}
        })
    }

    isChangedCamera = () => {
        this.setState({changedCamera: false})
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {}
        if (props.isLiveVideo) {
            const {history} = props.cameras[state.active]
            const video = props.cameras[state.active].current
            if (!isEqual(state.history, history)) {
                newState.history = history
                    ? history.filter(({video}) => !isEmpty(video))
                    : []
            }
            const newVideo = state.currents.filter((current) => {
                return isEqual(current, video)
            })
            if (isEmpty(newVideo)) {
                newState.video = video
            }
        } else {
            const activeCamera = props.cameras[state.active]
            if (activeCamera?.frames?.length > state.video.frames.length) {
                newState.video = activeCamera
            }
        }

        return {...state, ...(newState && newState)}
    }

    startRecordVideo = () => {
        const {cameras, onStartRecord, device} = this.props
        const video = cameras && cameras[this.state.active]

        if (video && video.camera) {
            onStartRecord && onStartRecord(video.camera)
        } else {
            onStartRecord && onStartRecord(device && device.zone)
        }
    }

    renderCameraIcon(process, frames) {
        if (process && process.isRunning) {
            return <Spinner />
        }

        if (!frames || frames.length === 0) {
            return (
                <IconNotAvailable className="cameraList-item-image-icon cameraList-item-image-icon--notAvailable" />
            )
        }

        return <IconRun className="cameraList-item-image-icon" />
    }

    renderCamera = (camera, index) => {
        const {preview, location, zone, process, frames} = camera.current || camera
        return (
            <span
                key={'preview$' + zone + index}
                onClick={() => this.showCamera(index)}
                className={classes('cameraList-item', {
                    'cameraList-item--active': index === this.state.active,
                })}
            >
                <div className="cameraList-item-image">
                    <div
                        className="cameraList-item-image-src"
                        style={{backgroundImage: `url(${preview})`}}
                    />
                    {this.renderCameraIcon(process, frames)}
                </div>

                <strong className="cameraList-item-title">{location}</strong>
                <span className="cameraList-item-description">{zone}</span>
            </span>
        )
    }

    render() {
        const {
            device,
            onStartRecord,
            isRequestAllowed,
            isExportAllowed,
            fallbackTrait = {},
            feature,
            isLiveVideo,
            cameras,
        } = this.props
        const {video, history, changedCamera} = this.state
        const trait = get(device, 'traits.vod', false) || fallbackTrait
        return (
            <div className="cameraList">
                {video && (
                    <Video
                        className="card"
                        key={video.zone}
                        {...video}
                        title={video.location}
                        onStartRecord={onStartRecord ? this.startRecordVideo : null}
                        isRequestAllowed={isRequestAllowed}
                        isExportAllowed={isExportAllowed}
                        trait={trait}
                        feature={feature}
                        isLiveVideo={isLiveVideo}
                    />
                )}

                {cameras && cameras.length > 1 && (
                    <div className="cameraList-items">
                        {cameras.map(this.renderCamera)}
                        <div className="cameraList-item cameraList-item--stub" />
                        <div className="cameraList-item cameraList-item--stub" />
                        <div className="cameraList-item cameraList-item--stub" />
                    </div>
                )}
                {isLiveVideo && !isEmpty(history) && <hr className={'top-slider-line'} />}
                {isLiveVideo && !isEmpty(history) && (
                    <Slider
                        items={history}
                        onSelect={this.onVideoSelect}
                        isLiveVideo={isLiveVideo}
                        changedCamera={changedCamera}
                        isChangedCamera={this.isChangedCamera}
                    />
                )}
            </div>
        )
    }
}

export default CameraList
