import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import {discovery as permission} from 'permissions/panels/selection'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'

import {MenuItem} from 'ipmp-react-ui/Menu'
import {ReactComponent as IconDiscovery} from 'ipmp-react-ui/icons/discovery.svg'

import {__} from 'utils/i18n'
import {showTriggerDiscoveryModal} from 'modules/modals/actions'
import {withSelectionFeature} from 'containers/withFeature'
import {DISCOVERY} from 'constants/features'

export const TriggerDiscoveryItem = compose(
    withPermission({isVisible: permission}),
    withVisibility(),
    withSelectionHandler(),
    withProps(() => ({
        Icon: IconDiscovery,
        title: __('Trigger panel discovery'),
        children: __('Trigger Discovery'),
    }))
)(MenuItem)

export default compose(
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                onClick: showTriggerDiscoveryModal,
            },
            dispatch
        )
    ),
    withSelectionFeature(DISCOVERY)
)(TriggerDiscoveryItem)
