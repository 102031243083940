import {createAction} from 'redux-actions'
import toArray from 'utils/toArray'

export const update = createAction('INTERACTIVE_USERS/STORE/UPDATE', toArray)
export const clear = createAction('INTERACTIVE_USERS/STORE/CLEAR')
export const purge = createAction('INTERACTIVE_USERS/STORE/PURGE')

export const enable = createAction('INTERACTIVE_USERS/STORE/ENABLE', toArray)
export const suspend = createAction('INTERACTIVE_USERS/STORE/SUSPEND', toArray)
export const revert = createAction('INTERACTIVE_USERS/STORE/REVERT', toArray)
export const resetPassword = createAction('INTERACTIVE_USERS/STORE/RESET_PASSWORD')
