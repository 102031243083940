import createPermission, {any} from 'permissions/createPermission'

import list from './list'

import {markAsViewed, schedule, initiate, cancelSchedule} from './actions'

export {markAsViewed, schedule, initiate, cancelSchedule}

export default createPermission(
    list,
    any(markAsViewed, schedule, initiate, cancelSchedule)
)
