import {Component} from 'react'
import PropTypes from 'prop-types'

import get from 'lodash-es/get'
import find from 'lodash-es/find'

import {EventRuleFormContext} from 'modals/EventRule/EventRuleFormContext'
import {
    EVENT_TYPE_NAME,
    NOTIFICATION_TYPE_NAME,
    STATE_TYPE_NAME,
    ZONE_TYPE_NAME,
} from 'constants/eventRules'

export default class ContentTemplate extends Component {
    static propTypes = {
        selectName: PropTypes.oneOf([
            ZONE_TYPE_NAME,
            NOTIFICATION_TYPE_NAME,
            STATE_TYPE_NAME,
            EVENT_TYPE_NAME,
        ]),
    }

    static contextType = EventRuleFormContext

    labelDataKey = 'name'

    state = {
        isModalOpened: false,
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isFormSuccess) {
            return {isModalOpened: false}
        }

        return {isModalOpened: state.isModalOpened || false}
    }

    selectName = this.props.selectName

    onOpenModal = () => this.setState({isModalOpened: true})
    onHideModal = () => this.setState({isModalOpened: false})

    get value() {
        const {allData} = this.context

        if (allData[this.selectName]) {
            return allData[this.selectName]
        }

        return null
    }

    get label() {
        const {data} = this.props

        if (this.value) {
            return get(find(data, {id: Number(this.value)}), this.labelDataKey, '')
        }

        return ''
    }

    renderModal = () => null

    render() {
        return null
    }
}
