import {Component} from 'react'

interface CommonRouteInterface {
    path: string;
    exact: boolean;
}

export class CommonRoute implements CommonRouteInterface {
    path: string
    label: string
    exact: boolean

    constructor({
        path,
        label = null,
        exact = false,
    }: {
        path: string,
        label: string,
        exact: boolean,
    }) {
        this.path = path
        this.label = label
        this.exact = exact
    }
}

export class CommonComponentRoute extends CommonRoute {
    component: Component

    constructor({component, ...args}: {component: Component}) {
        super(args)
        this.component = component
    }
}
