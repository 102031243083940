import React from 'react'
import withLifeCycle from 'containers/withLifeCycle'
import {compose} from 'redux'

import Table from 'ipmp-react-ui/Table'
import {ReactComponent as IconCheck} from 'ipmp-react-ui/icons/check.svg'

import withLoader from 'containers/withLoader'
import withProps from 'containers/withProps'
import withTableColumnsPermission from 'containers/withTableColumnsPermission'
import {__} from 'utils/i18n'
import withStateTypes from 'containers/withStateTypes'
import RemoveStateType from './TableCell/RemoveStateType'
import EditStateType from './TableCell/EditStateType'

const columns = [
    {
        name: () => __('State Type'),
        render: ({name}) => name,
    },
    {
        name: () => __('Is Disarm'),
        render: ({isDisarmState}) => isDisarmState && <IconCheck className="success" />,
    },
    {
        name: () => __('Has entry delay'),
        render: ({hasEntryDelay}) => hasEntryDelay && <IconCheck className="success" />,
    },
    {
        name: () => __('Triggers exit delay'),
        render: ({triggersExitDelay}) =>
            triggersExitDelay && <IconCheck className="success" />,
    },
    {
        tooltip: false,
        width: 50,
        fixed: true,
        component: EditStateType,
    },
    {
        tooltip: false,
        width: 50,
        fixed: true,
        component: RemoveStateType,
    },
]

export default compose(
    withStateTypes(),
    withLifeCycle({
        onMount({init, startPoll}) {
            init()
            startPoll()
        },

        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withLoader(),
    withProps({
        columns,
        fullHeight: true,
        emptyMessage: () => __('No state types found'),
    }),
    withTableColumnsPermission()
)(Table)
