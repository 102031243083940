import {handleActions} from 'redux-actions'
import {fetch, receive} from './actions'
import keyBy from 'lodash-es/keyBy'

const defaultState = {
    byIds: {},
    isLoading: false,
    error: null,
}

export default handleActions(
    {
        [fetch](state) {
            return {
                ...state,
                isLoading: true,
            }
        },
        [receive](state, {error, payload}) {
            if (error) {
                return {
                    ...state,
                    error: payload,
                    isLoading: false,
                }
            }

            const byIds = keyBy(payload, 'id')
            return {
                ...state,
                error: null,
                isLoading: false,
                byIds: {
                    ...state.byIds,
                    ...byIds,
                },
            }
        },
    },
    defaultState
)
