import {takeEvery, all, call, put, race, take, delay} from 'redux-saga/effects'
import moment from 'moment'

import {update as updateResults} from 'modules/remoteInspections/results/actions'
import {update as updateInspection} from 'modules/remoteInspections/store/actions'

import * as actions from './actions'
import * as api from 'api/panel/remoteInspections'
import toIds from 'utils/toIds'
import ensureProcess from 'modules/processes/manager/ensureProcess'
import {takeProcessComplete} from 'modules/processes/manager/takeProcess'
import {PROCESS_TYPE_REMOTEINSPECTION} from 'constants/processTypes'
import {POLL_PANEL_REMOTE_INSPECTIONS} from 'configs/pollers'
import {CALEDAR_COUNT_RESULT_RRI} from 'constants/remoteInspection'

export default function* () {
    yield all([
        takeEvery([actions.fetch, actions.reset], watchFetch),
        takeEvery(actions.startPoll, watchStartPoll),
    ])
}

function* watchStartPoll({meta: {panelId}, payload: {date, view}}) {
    yield race({
        poll: call(pollData, {panelId, date, view}),
        take: take([actions.startPoll, actions.stopPoll]),
    })
}

function* pollData({panelId, date, view}) {
    while (true) {
        yield race({
            delay: delay(POLL_PANEL_REMOTE_INSPECTIONS),
            process: takeProcessComplete(PROCESS_TYPE_REMOTEINSPECTION, panelId),
        })

        date = moment(date)

        const from = date.startOf(view).toISOString()
        const to = date.endOf(view).toISOString()

        try {
            const {process, inspection, results, count} = yield call(
                api.fetch,
                panelId,
                from,
                to
            )

            yield ensureProcess(process)

            yield put(updateInspection([inspection]))
            yield put(updateResults(results))
            yield put(actions.update(toIds(results), panelId, count))
        } catch (error) {
            yield put(actions.update(error, panelId))
        }
    }
}

function* watchFetch({meta: {panelId}, payload: {date, view}, type}) {
    date = moment(date)

    if (type === actions.fetch.toString()) {
        yield put(actions.stopPoll())
    }
    yield put(actions.startPoll({date, view}, parseInt(panelId)))

    const from = date.startOf(view).toISOString()
    const to = date.endOf(view).toISOString()

    try {
        const {process, inspection, results, count} = yield call(
            api.fetch,
            panelId,
            from,
            to,
            0,
            CALEDAR_COUNT_RESULT_RRI
        )

        yield ensureProcess(process)

        yield put(updateInspection([inspection]))
        yield put(updateResults(results))
        yield put(actions.update(toIds(results), panelId, count))
    } catch (error) {
        yield put(actions.update(error, panelId))
    }
}
