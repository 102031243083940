import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import List from 'ipmp-react-ui/List'

import {SettingsPageBlock} from '../SettingsPageBlock'
import EditUserNotificationsButton from './Buttons/EditUserNotificationsButton'

import {__} from 'utils/i18n'

class UserNotifications extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            isEmailWithVideoEnabled: PropTypes.bool,
            isEmailWithoutVideoEnabled: PropTypes.bool,
            messageBroker: PropTypes.number,
        }),
        messageBroker: PropTypes.object,
    }

    render() {
        const {data, messageBroker} = this.props

        return (
            <div>
                <h1 className="card-header">
                    {__('User Notifications')}
                    <EditUserNotificationsButton />
                </h1>

                <List className="card-content" twoCols>
                    {this.renderListItem(
                        __('Enable Emails with Attached Video'),
                        data.isEmailWithVideoEnabled
                    )}
                    {this.renderListItem(
                        __('Enable Emails without Attached Video'),
                        data.isEmailWithoutVideoEnabled
                    )}
                    {this.renderListItem(
                        __('Message Broker for SMS by server'),
                        messageBroker && messageBroker.name
                    )}
                </List>
            </div>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {userNotifications, messageBrokers},
            },
        }) => {
            return {
                messageBroker: messageBrokers.data.find(
                    (broker) => broker.id == userNotifications.data.messageBroker
                ),
                ...userNotifications,
            }
        }
    )
)(UserNotifications)
