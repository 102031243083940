import PropTypes from 'prop-types'
import React, {Component} from 'react'

import ModalCardForm from 'ui/ModalCardForm'

import decorate from 'utils/decorate'
import {__} from 'utils/i18n'

export default class extends Component {
    static propTypes = {
        users: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                isRemovable: PropTypes.bool,
            })
        ),
        header: PropTypes.string,
        hide: PropTypes.func.isRequired,
    }

    render() {
        const {users, hide, header} = this.props

        return (
            <ModalCardForm
                showDismiss={true}
                header={header}
                hide={hide}
                onSubmit={() => ({})}
                editable={false}
            >
                {decorate(
                    __(
                        'Users %s has related users or roles and cannot be removed. Remove related users and roles before.'
                    ),
                    users.map(({name}) => name).join(', ')
                )}
            </ModalCardForm>
        )
    }
}
