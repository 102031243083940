import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import list from 'permissions/actionLog/list'

import {
    fetch,
    setQuery,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
} from 'modules/actionLog/list/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Search from 'components/Search/Search'
import DateFilter from 'components/Search/Filters/DateFilter'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'

import __ from 'utils/i18n'
import {MapSuggestFilter} from 'components/Search/Filters/MapSuggestFilter'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProps(() => ({
        filters: [
            new MapSuggestFilter('users', __('User'), {
                value: 'userId',
                title: 'userName',
            }),
            new ValuesFilter('statuses', __('Status'), {
                success: __('Success'),
                error: __('Error'),
            }),

            new MapSuggestFilter('activities', __('Activity'), {
                value: 'key',
                title: 'value',
            }),

            new DateFilter('datetime', __('Time')),
        ].sort((item, next) => item.title.localeCompare(next.title)),
    })),
    connect(
        ({actionLog: {list}}) => ({
            selected: list.filters,
            query: list.query,
            suggests: list.suggests,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    apply: fetch,
                    onSelect: addFilters,
                    onQuery: setQuery,
                    onDeselect: removeFilters,
                    onClear: clearFilters,
                    onSuggest: fetchSuggests,
                },
                dispatch
            )
    )
)(Search)
