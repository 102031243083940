import React, {Component} from 'react'

import Input from 'ipmp-react-ui/Input'
import Radio from 'ipmp-react-ui/Radio'
import Select from 'ipmp-react-ui/Select'
import {Option} from 'ipmp-react-ui/Select'

import {__, ordinals, weekdays} from 'utils/i18n'
import {FormRow, FormSection} from 'ipmp-react-ui/Form'

const MODE_EVERY_WEEK = 'week'
const MODE_EVERY_WEEKDAY = 'weekday'

export default class extends Component {
    state = {
        mode: MODE_EVERY_WEEK,
    }

    setMode(mode) {
        this.setState({mode})
    }

    render() {
        const {mode} = this.state

        return (
            <FormSection>
                <FormRow>
                    <span>{__('Recur every')}</span>

                    <Input
                        {...{
                            type: 'number',
                            min: 1,
                            max: 12,
                            defaultValue: 1,
                            name: 'monthlyEvery',
                        }}
                    />

                    <span>{__('month')}</span>
                </FormRow>

                <FormRow>
                    <Radio
                        {...{
                            checked: mode === MODE_EVERY_WEEK,
                            onChange: () => this.setMode(MODE_EVERY_WEEK),
                            name: '_everyDayOfMonth',
                        }}
                    />

                    <Select
                        {...{
                            onFocus: () => this.setMode(MODE_EVERY_WEEK),
                            defaultValue: 1,
                            name: 'dayOfMonth',
                        }}
                    >
                        {ordinals(5).map((ordinal, index) => (
                            <Option
                                {...{
                                    key: index,
                                    label: __('every %s day', ordinal),
                                    value: index + 1,
                                }}
                            />
                        ))}
                    </Select>

                    <span style={{whiteSpace: 'nowrap'}}>{__('of the month')}</span>
                </FormRow>

                <FormRow>
                    <Radio
                        {...{
                            checked: mode === MODE_EVERY_WEEKDAY,
                            onChange: () => this.setMode(MODE_EVERY_WEEKDAY),
                            name: '_everyDayOfWeek',
                        }}
                    />

                    <Select
                        {...{
                            onFocus: () => this.setMode(MODE_EVERY_WEEKDAY),
                            defaultValue: 1,
                            name: 'numberOfDay',
                        }}
                    >
                        {ordinals(4).map((ordinal, index) => (
                            <Option
                                {...{
                                    key: index,
                                    value: index + 1,
                                    label: __('Every %s', ordinal),
                                }}
                            />
                        ))}
                    </Select>

                    <Select
                        {...{
                            onFocus: () => this.setMode(MODE_EVERY_WEEKDAY),
                            defaultValue: 1,
                            name: 'dayOfWeek',
                        }}
                    >
                        {weekdays('dddd').map(({label, day}) => (
                            <Option {...{key: day, value: day, label}} />
                        ))}
                    </Select>

                    <span> {__('of the month')}</span>
                </FormRow>
            </FormSection>
        )
    }
}
