import mergeStubsAndProcesses from './mergeStubsAndProcesses'

export default function getFailedProcesses(processStubs, processes) {
    const mergedProcesses = mergeStubsAndProcesses(processStubs, processes)

    const failed = mergedProcesses
        .filter((process) => !process.id)
        .map(({panelId}) => panelId)

    return failed
}
