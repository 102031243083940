import {compose} from 'redux'

import {stop} from 'permissions/reports/selection'

import {withSelectionHandler} from 'containers/withSelection'
import withConfirmation from 'containers/withConfirmation'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withReports from 'containers/withReports'
import withProps from 'containers/withProps'

import {ReactComponent as PauseIcon} from 'ipmp-react-ui/icons/pause.svg'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: stop}),
    withVisibility(),
    withReports(),
    withProps(({stop}) => ({
        onClick: stop,
        Icon: PauseIcon,
        label: __('Stop'),
        title: __('Stop Reports'),
        message: __('Do you want to stop selected reports?'),
    })),
    withSelectionHandler(),
    withConfirmation()
)(Button)
