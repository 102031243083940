import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import classes from 'classnames'

import {ReactComponent as IconHistory} from 'ipmp-react-ui/icons/history.svg'
import {ReactComponent as IconPanel} from 'ipmp-react-ui/icons/panel.svg'
import {ReactComponent as IconUser} from 'ipmp-react-ui/icons/user.svg'
import {ReactComponent as DropDownIcon} from 'ipmp-react-ui/icons/drop-down.svg'

import {panel} from 'permissions/navigation'
import assignedUnitUsers from 'permissions/interactiveUsers/recentHistoryAssignedUnitUsersList'
import __ from 'utils/i18n'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import withRoute from 'containers/withRoute'
import {showRecentHistoryNavBlind} from 'modules/navBlind/actions'
import {NAV_BLIND_RECENT_HISTORY} from 'modules/navBlind/constants'
import {selectRecentPanel} from 'modules/panels/recent/selectors'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'

class UserEmailComponent extends Component {
    get userEmail() {
        return isEmpty(this.props.recentUserMail)
            ? __('Unassigned')
            : this.props.recentUserMail
    }

    render() {
        const {path} = this.props
        return (
            <Link to={path} className="recentHistoryWidget-item">
                <div className="nav-link-content">
                    <IconUser className="nav-link-icon" />
                    {this.userEmail}
                </div>
            </Link>
        )
    }
}

const UserEmail = compose(
    withPermission({isVisible: assignedUnitUsers}),
    withVisibility()
)(UserEmailComponent)

class RecentWidget extends Component {
    static propTypes = {
        isRecentEmpty: PropTypes.bool,
        isRecentListOpened: PropTypes.bool.isRequired,
        showRecentHistoryNavBlind: PropTypes.func.isRequired,
        recentUserMail: PropTypes.string,
        recentPanelSerial: PropTypes.string.isRequired,
    }

    onClick = () => {
        const {isRecentListOpened, showRecentHistoryNavBlind} = this.props

        if (!isRecentListOpened) {
            showRecentHistoryNavBlind()
        }
    }

    render() {
        const {
            path,
            recentPanelSerial,
            isRecentListOpened,
            isRecentEmpty,
            recentUserMail,
        } = this.props

        if (isRecentEmpty) {
            return null
        }

        return (
            <div className="nav-link-wrapper recentHistoryWidget">
                <div
                    onClick={this.onClick}
                    className={classes('nav-link recentHistoryWidgetController', {
                        'nav-link--active': isRecentListOpened,
                    })}
                >
                    <div className="nav-link-content">
                        <IconHistory className="nav-link-icon" />
                        {__('Recent history')}
                    </div>
                    <DropDownIcon className="recentHistoryWidget-dropDown" />
                </div>

                <h3 className="recentHistoryWidget-lightTitle">{__('Last Visit')}</h3>
                <Link
                    to={path}
                    className="recentHistoryWidget-item recentHistoryWidget-item--active"
                >
                    <div className="nav-link-content">
                        <IconPanel className="nav-link-icon" />
                        {recentPanelSerial}
                    </div>
                </Link>
                <UserEmail path={path} recentUserMail={recentUserMail} />
            </div>
        )
    }
}

export default compose(
    withRouter,
    withPermission({isVisible: panel}),
    withVisibility(),
    connect(
        ({navBlind: {type}, ...state}) => {
            const recentPanel = selectRecentPanel(state)
            return {
                recentUserMail: get(recentPanel, 'interactiveUser.email', ''),
                recentPanelSerial: get(recentPanel, 'panel.serial', ''),
                path: get(recentPanel, 'path', '/'),
                isRecentListOpened: type === NAV_BLIND_RECENT_HISTORY,
                isRecentEmpty: !recentPanel,
            }
        },
        (dispatcher) => bindActionCreators({showRecentHistoryNavBlind}, dispatcher)
    ),
    withRoute()
)(RecentWidget)
