import withTrackProcess from 'containers/withTrackProcess'
import React, {Component} from 'react'

import getHocName from 'utils/getHocName'
import {__} from 'utils/i18n'
import {connect} from 'react-redux'
import {compose} from 'redux'
import Error from 'ipmp-react-ui/Error'
import Button from 'ipmp-react-ui/Button'
import ProcessLoader from 'ipmp-react-ui/ProcessLoader'
import processErrorMessage from 'constants/processError'

export function withProcess() {
    return compose(
        connect((state, {process}) => {
            const {byKeys} = state.processes.manager

            if (process && byKeys[process]) {
                return {process: byKeys[process]}
            }

            return {process: null}
        }),
        withTrackProcess()
    )
}

export default function withProcessLoader(
    messageBuilder: ?(process: Object) => String,
    onSuppress: ?(props: Object) => any,
    buttonProps: ?Object
) {
    if (!messageBuilder) {
        messageBuilder = () => __('Synchronizing with panel')
    }

    return compose(
        withProcess(),

        (WrappedComponent) => {
            return class extends Component {
                displayName = getHocName('withProcessLoader', WrappedComponent)

                state = {}

                handleSuppress = () => {
                    onSuppress(this.props)
                }

                render() {
                    const {process, ...props} = this.props

                    if (!process || process.isSuccessful) {
                        return <WrappedComponent {...props} />
                    }

                    if (process.isFailed) {
                        return (
                            <Error
                                title={__('Process failed')}
                                message={processErrorMessage(
                                    process.error,
                                    process.errorMessage
                                )}
                            >
                                {onSuppress && (
                                    <Button
                                        onClick={this.handleSuppress}
                                        {...buttonProps}
                                    >
                                        {__('Suppress')}
                                    </Button>
                                )}
                            </Error>
                        )
                    }

                    return (
                        <ProcessLoader
                            message={messageBuilder(process)}
                            percentage={process.percentage}
                            details={process.details}
                        />
                    )
                }
            }
        }
    )
}
