import {handleActions} from 'redux-actions'
import {reset, receiveByBatchId} from './actions'

const defaultState = {
    ids: [],
    isLoading: false,
    error: null,
    perPage: 20,
}

export default handleActions(
    {
        [reset](state, {payload}) {
            return {
                ...state,
                batchId: payload,
                isLoading: true,
                ids: [],
            }
        },

        [receiveByBatchId](state, {payload, error}) {
            if (error) {
                return {
                    ...state,
                    isLoading: false,
                    error: payload,
                    ids: [],
                }
            }

            return {
                ...state,
                isLoading: false,
                error: null,
                ids: payload.ids,
                count: payload.count,
            }
        },
    },
    defaultState
)
