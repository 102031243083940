import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import throttle from 'lodash-es/throttle'

import actions from 'permissions/interactiveUsers/actions'
import {enable, suspend} from 'modules/interactiveUsers/store/actions'

import {withPermission} from 'containers/withPermission'

import Checkbox from 'ipmp-react-ui/Checkbox'

import getInteractiveUserStatusTitle, {
    STATUS_SUSPENDED,
    STATUS_VERIFIED,
    STATUS_REGISTERED,
} from 'constants/interactiveUserStatus'
import stopPropagation from 'utils/stopPropagation'

export class InteractiveUserStatusChanger extends Component {
    static propTypes = {
        row: PropTypes.shape({
            id: PropTypes.number,
            status: PropTypes.string,
        }).isRequired,
        isPermitted: PropTypes.bool,
        enable: PropTypes.func.isRequired,
        suspend: PropTypes.func.isRequired,
    }

    static defaultProps = {
        isPermitted: false,
    }

    constructor(props) {
        super(props)

        this.onChange = throttle(this.onChange, 500)
    }

    getLabel() {
        const {
            row: {status},
        } = this.props

        return getInteractiveUserStatusTitle(status)
    }

    getClassName() {
        const {
            row: {status},
        } = this.props

        switch (status) {
            case STATUS_REGISTERED:
                return 'warn'
            case STATUS_SUSPENDED:
                return 'danger'
        }
    }

    isIndeterminated() {
        const {
            row: {status},
        } = this.props

        return status === STATUS_REGISTERED
    }

    isChecked() {
        const {
            row: {status},
        } = this.props

        return status === STATUS_VERIFIED
    }

    onChange = () => {
        const {
            enable,
            suspend,
            row: {id, status},
        } = this.props

        switch (status) {
            case STATUS_SUSPENDED:
                return enable(id)
            case STATUS_VERIFIED:
                return suspend(id)
        }
    }

    isDisabled() {
        const {isPermitted, row} = this.props
        return !isPermitted || row.status === 'registered'
    }

    render() {
        return (
            <div className={this.getClassName()} onClick={stopPropagation}>
                <Checkbox
                    checked={this.isChecked()}
                    disabled={this.isDisabled()}
                    indeterminate={this.isIndeterminated()}
                    label={this.getLabel()}
                    onChange={this.onChange}
                />
            </div>
        )
    }
}

export default compose(
    withPermission({isPermitted: actions}),
    connect(
        () => ({}),
        (dispatch) => bindActionCreators({enable, suspend}, dispatch)
    )
)(InteractiveUserStatusChanger)
