import createListReducer from 'modules/higherOrder/createListReducer'
import * as actions from './actions'

export default createListReducer(actions, {
    [actions.fetch](state, {payload}) {
        return {
            ...state,
            basicConfigId: payload.basicConfigId || state.basicConfigId,
        }
    },
})
