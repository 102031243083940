import {takeEvery, all, call, put} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/panel/reports'
import timesones from 'utils/timezones'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([takeEvery([actions.fetch, actions.reset], watchFetch)])
}

function* watchFetch({meta: {panelId}, payload: {date, view}}) {
    date = timesones.server(date)

    const from = date.startOf(view).toISOString()
    const to = date.endOf(view).toISOString()

    let result

    try {
        result = yield call(api.receiveExpected, panelId, from, to)
    } catch (error) {
        result = error
        yield put(snackShow(error.message))
    }

    yield put(actions.receive(result, panelId))
}
