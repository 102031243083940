import {compose} from 'redux'
import find from 'lodash-es/find'

import {initiate} from 'permissions/remoteInspections/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withConfirmation from 'containers/withConfirmation'
import withRemoteInspectionsResult from 'containers/withRemoteInspectionsResult'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'
import withRemoteInspections from 'containers/withRemoteInspections'

export const InitiateButton = compose(
    withPermission({isVisible: initiate}),
    withVisibility()
)(Button)

export default compose(
    withRemoteInspections(),
    withRemoteInspectionsResult(),
    withProps(({inspection, initiate, rows, selectedId, ...props}) => {
        const rri = find(rows, {id: selectedId})
        const isSupported = rri ? rri.isSupported : false

        return {
            disabled:
                (inspection &&
                    inspection.result === 'progress' &&
                    inspection.progress === 0) ||
                !isSupported,
            label:
                inspection &&
                inspection.result === 'progress' &&
                inspection.progress === 0
                    ? __('Pending...')
                    : __('Run'),
            title: __('Initiate Inspection'),
            message: __('Do you want to initiate remote inspection now?'),
            positive: __('Begin now'),
            borderless: true,
        }
    }),
    withConfirmation(({initiate}) => initiate())
)(InitiateButton)
