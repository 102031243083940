import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import Input from 'ipmp-react-ui/Input'
import ModalCardForm from 'ui/ModalCardForm'
import {__} from 'utils/i18n'
import withForm from 'containers/withForm'
import {editProfile} from 'modules/forms/handlers'
import Autocomplete from 'ipmp-react-ui/Autocomplete'
import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchCountries} from 'modules/countries/actions'
import getCountryTitle from 'constants/country'

const CountrySelect = withSelectLoader(
    fetchCountries,
    (state) => state.countries.isLoading,
    ({countries}) =>
        Object.values(countries.byIds).map(({id, name}) => ({
            value: id,
            label: getCountryTitle(name),
        }))
)(Autocomplete)

const rules = {
    phone: {
        presence: true,
        phone: true,
    },
    countryId: {
        presence: true,
    },
}

const EditProfileInfo = ({handle, data, ...props}) => (
    <ModalCardForm onSubmit={handle} header={__('Edit Profile')} rules={rules} {...props}>
        <Input label={__('Phone')} name="phone" defaultValue={data.phone} />

        <CountrySelect
            optional
            label={__('Country')}
            name="countryId"
            defaultValue={data.countryId}
            defaultLabel={data.countryName && getCountryTitle(data.countryName)}
        />
    </ModalCardForm>
)

EditProfileInfo.propTypes = {
    handle: PropTypes.func,
    data: PropTypes.shape({
        phone: PropTypes.string,
        countryId: PropTypes.string,
        countryName: PropTypes.string,
    }),
}

export default compose(
    connect(({auth: {sign: {user}}}) => ({
        data: {
            countryId: user.countryId,
            countryName: user.countryName,
            phone: user.phone,
        },
    })),
    withForm(editProfile)
)(EditProfileInfo)
