import {createAction} from 'redux-actions'

export const update = createAction('PANELS/KEYPAD/UPDATE', null, (_, panelId) => ({
    panelId,
}))

export const authorize = createAction('PANELS/KEYPAD/AUTHORIZE')
export const disconnect = createAction('PANELS/KEYPAD/DISCONNECT')
export const isEnabled = createAction('PANELS/KEYPAD/IS_ENABLED')
export const enable = createAction('PANELS/KEYPAD/ENABLE')
export const disable = createAction('PANELS/KEYPAD/DISABLE')

export const message = createAction('PANELS/KEYPAD/MESSAGE', null, (_, panelId) => ({
    panelId,
}))
export const send = createAction('PANELS/KEYPAD/SEND', null, (_, panelId) => ({panelId}))
export const soundMessage = createAction(
    'PANELS/KEYPAD/SOUND_MESSAGE',
    null,
    (_, panelId) => ({panelId})
)

export const cleanLog = createAction('PANELS/KEYPAD/CLEAN_LOG')
export const cleanKey = createAction('PANELS/KEYPAD/CLEAN_KEY')
