import withLifeCycle from 'containers/withLifeCycle'
import React from 'react'
import {compose} from 'redux'

import list from 'permissions/roles/list'
import selection from 'permissions/roles/selection'
import {edit as editable, showPermissions} from 'permissions/roles/actions'

import withSelection from 'containers/withSelection'
import withLoader from 'containers/withLoader'
import {withPermission, withRejection} from 'containers/withPermission'
import withRoles from 'containers/withRoles'
import withProps from 'containers/withProps'
import withTableColumnsPermission from 'containers/withTableColumnsPermission'

import Table from 'ipmp-react-ui/Table'
import Definition from 'ipmp-react-ui/Definition'
import {__} from 'utils/i18n'

import {ReactComponent as IconUser} from 'ipmp-react-ui/icons/user.svg'

import RoleGroupCell from './TableCell/RoleGroupCell'
import RolePermissionsCell from './TableCell/RolePermissionsCell'
import EditRoleCell from './TableCell/EditRoleCell'

const columns = [
    {
        name: () => __('Name'),
        render: ({name}) => name,
    },
    {
        name: () => __('Parent Role'),
        render: ({parentName}) => parentName,
    },
    {
        name: () => __('Created by'),
        render: ({userName}) => <Definition Icon={IconUser} title={userName} />,
    },
    {
        tooltip: false,
        permission: showPermissions,
        width: 50,
        fixed: true,
        component: RolePermissionsCell,
    },
    {
        name: () => __('Groups'),
        tooltip: false,
        width: 200,
        component: RoleGroupCell,
    },
    {
        tooltip: false,
        permission: editable,
        width: 50,
        fixed: true,
        component: EditRoleCell,
    },
]

export default compose(
    withPermission({
        isAllowed: list,
        hasSelection: selection,
    }),
    withRejection(),
    withRoles(),
    withSelection(),
    withLifeCycle({
        onMount({init, startPoll}) {
            init()
            startPoll()
        },

        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withLoader(),
    withProps({
        columns,
        fullHeight: true,
        emptyMessage: () => __('No roles found'),
    }),
    withTableColumnsPermission()
)(Table)
