import {Component} from 'react'
import PropTypes from 'prop-types'

import * as ReportTypes from 'constants/reportTypes'
import {__, __n} from 'utils/i18n'

export default class ScheduleReportCell extends Component {
    static propTypes = {
        row: PropTypes.shape({
            schedule: PropTypes.shape({
                pattern: PropTypes.string,
                every: PropTypes.number,
            }),
        }),
    }

    renderDaily = () => {
        const {
            row: {schedule},
        } = this.props

        if (schedule.settings[ReportTypes.REPORT_DAILY_EVERY_WEEK_DAY]) {
            return __('Recur every weekday')
        }

        return __n('Every %d day', 'Every %d days', schedule.every)
    }

    render() {
        const {
            row: {schedule},
        } = this.props

        switch (schedule.pattern) {
            case ReportTypes.REPORT_ONE_TIME:
                return __('One time report')
            case ReportTypes.REPORT_DAILY:
                return this.renderDaily()
            case ReportTypes.REPORT_WEEKLY:
                return __n('Every %d week', 'Every %d weeks', schedule.every)
            case ReportTypes.REPORT_MONTHLY:
                return __n('Every %d month', 'Every %d months', schedule.every)
            case ReportTypes.REPORT_YEARLY:
                return __n('Every %d year', 'Every %d years', schedule.every)
        }

        return __('Unknown')
    }
}
