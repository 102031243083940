import React, {PureComponent} from 'react'
import timezones from 'utils/timezones'
import {humanTime} from 'ipmp-react-ui/humanTime'
import {rriFaultsDescription} from 'constants/rriFaults'

export default class TimeFaults extends PureComponent {
    renderTime(dt) {
        const {date, time} = humanTime(timezones.server(dt, 'YYYY-MM-DD hh:mm:ss'))

        return (
            <span>
                <span className="rri-result-details-date">{date}</span>{' '}
                <span className="rri-result-details-time">{time}</span>
            </span>
        )
    }

    renderRow = ({device, type, state, user, dt}, index) => {
        return (
            <tr key={index}>
                {type && (
                    <td className="rri-result-details-row">
                        {rriFaultsDescription(type)}
                    </td>
                )}
                {device && <td className="rri-result-details-row">{device}</td>}
                {state && <td className="rri-result-details-row">{state}</td>}
                {user && <td className="rri-result-details-row">{user}</td>}
                {dt && <td className="rri-result-details-row">{this.renderTime(dt)}</td>}
            </tr>
        )
    }

    render() {
        return (
            <table className="rri-result-details">
                <tbody>{this.props.rows.map(this.renderRow)}</tbody>
            </table>
        )
    }
}
