import withForm from 'containers/withForm'
import {editUser} from 'modules/forms/handlers'
import UserForm from './UserForm'
import {EditValidation} from './Fieldset/User'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {__} from 'utils/i18n'

export default compose(
    connect(({users}, {userId}) => ({
        data: users.store.byIds[userId],
        header: __('Edit User'),
        rules: EditValidation,
    })),
    withForm(editUser),
    connect(null, (dispatcher, {userId, handle}) => ({
        handle: (data) => handle(userId, data),
    }))
)(UserForm)
