import {handleActions} from 'redux-actions'

import {fetch, receive} from './actions'

const defaultState = {
    isLoading: false,
    ids: [],
}

export default handleActions(
    {
        [fetch](state) {
            return {
                ...state,
                isLoading: true,
            }
        },

        [receive](state, {error, payload}) {
            if (error) {
                return {
                    ...state,
                    isLoading: false,
                    error: payload,
                }
            }

            return {
                ...state,
                isLoading: false,
                ids: payload,
            }
        },
    },
    defaultState
)
