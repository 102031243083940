import {compose} from 'redux'

import {download} from 'permissions/panel/configuration/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRefresh} from 'ipmp-react-ui/icons/refresh.svg'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: download}),
    withVisibility(),
    withProps(() => ({
        Icon: IconRefresh,
        label: __('Refresh'),
    }))
)(Button)
