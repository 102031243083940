import {handleActions} from 'redux-actions'

import {fetchGroupRoles, receiveGroupRoles, setLoading} from './actions'

const defaultState = {
    isLoading: false,
    groupRoles: {},
    error: null,
}

export default handleActions(
    {
        [setLoading](state, {payload}) {
            return {
                ...state,
                isLoading: payload,
            }
        },

        [fetchGroupRoles](state, {payload}) {
            return {
                ...state,
                isLoading: true,
            }
        },

        [receiveGroupRoles](state, {payload, error}) {
            if (error) {
                return {
                    ...state,
                    isLoading: false,
                    error: payload,
                }
            }

            const {id, roles} = payload

            return {
                ...state,
                isLoading: false,
                groupRoles: {
                    ...state.groupRoles,
                    [id]: roles,
                },
            }
        },
    },
    defaultState
)
