import {createSelector} from 'reselect'
import get from 'lodash-es/get'
import find from 'lodash-es/find'

export const selectBatchPackages = createSelector(
    (state, {batchId}) => find(state.runners.store.byIds, {batchId}),
    (state) => state.upgradeStatus.store.isPackageLoading,
    (runner, isPackageLoading) => ({
        packages: get(runner, 'params.packages', []),
        isLoading: isPackageLoading,
    })
)
