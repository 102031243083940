import {call, put} from 'redux-saga/effects'
import * as api from 'api/installers'
import {snackShow} from 'modules/snacks'

export function* watchResetPassword({payload: installerId}) {
    try {
        yield call(api.fetchInstallerResetPassword, {installerId})
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
