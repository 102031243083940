import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import withProps from 'containers/withProps'

import withLoader from 'containers/withLoader'

import FirmwareUpgradeGridBar from './Upgrade/FirmwareUpgradeGridBar'
import FirmwareUpgradeTable from './Upgrade/FirmwareUpgradeTable'
import FirmwareUpgradeSearch from './Upgrade/FirmwareUpgradeSearch'

import {fetch} from 'modules/firmware/applianceSelection/actions'

import Page from 'ipmp-react-ui/Page'

export default compose(
    withProps({
        Top: FirmwareUpgradeSearch,
        Bar: FirmwareUpgradeGridBar,
        Content: FirmwareUpgradeTable,
    }),
    connect(null, (dispatch) => bindActionCreators({fetch}, dispatch)),
    withLoader(({fetch}) => {
        fetch()
    })
)(Page)
