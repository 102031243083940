import React, {Component} from 'react'
import {Route, Switch} from 'react-router'

import Layout from 'ipmp-react-ui/Layout'

import EventRulesNavTabs from 'pages/EventRules/EventRulesNavTabs'
import {routing} from './EventRulesRouting'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {withRejection} from 'containers/withPermission'

class EventRulesPage extends Component {
    renderRoute = ({component, path, exact}) => {
        return <Route key={path} component={component} exact={exact} path={path} />
    }

    render() {
        return (
            <Layout className="eventRules" vertical>
                <EventRulesNavTabs />
                <div className="eventRules-tabsContainer">
                    <Switch>{routing.map(this.renderRoute)}</Switch>
                </div>
            </Layout>
        )
    }
}

export default compose(
    connect(({settings}) => ({isAllowed: settings.isVirtualPanelEnabled})),
    withRejection()
)(EventRulesPage)
