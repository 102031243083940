import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import withPerPage from 'containers/withPerPage'
import {selectInteractiveUserPanelsList} from 'modules/interactiveUserPanels/list/selectors'
import {init, fetch, resetWithOutFetch} from 'modules/interactiveUserPanels/list/actions'
import {
    init as addPanelListInit,
    fetch as addPanelListFetch,
    setQuery,
    setStart,
} from 'modules/interactiveUserPanels/addPanelList/actions'
import {selectInteractiveUserAddPanelList} from 'modules/interactiveUserPanels/addPanelList/selectors'
import {showAssignUserToPanelModal} from 'modules/modals/actions'

export default function withInteractiveUserPanelsTable() {
    return compose(
        withPerPage('interactiveUserPanels'),
        connect(selectInteractiveUserPanelsList, (dispatch, {interactiveUser: {id}}) =>
            bindActionCreators(
                {
                    init: () => init(id),
                    fetch: () => fetch(id),
                    resetWithOutFetch,
                },
                dispatch
            )
        )
    )
}

export function withInteractiveUserAddPanelTable() {
    return compose(
        withPerPage('interactiveUserAddPanel'),
        connect(selectInteractiveUserAddPanelList, (dispatch) =>
            bindActionCreators(
                {
                    init: addPanelListInit,
                    fetch: addPanelListFetch,
                    setQuery,
                    setStart,
                    showAssignUserToPanelModal,
                },
                dispatch
            )
        )
    )
}
