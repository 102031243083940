import {all} from 'redux-saga/effects'

import assignableRoles from './assignableRoles/saga'
import maintainers from './maintainers/saga'
import list from './list/saga'
import store from './store/saga'

export default function* () {
    yield all([list(), store(), assignableRoles(), maintainers()])
}
