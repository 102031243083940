import {handleActions} from 'redux-actions'

import * as actions from './actions'
import createPurgeStoreHandler from 'modules/higherOrder/createPurgeStoreHandler'
import storeByIdsUpdate from 'utils/storeByIdsUpdate'

const state = {
    byIds: {},
}

export default handleActions(
    {
        [actions.update](state, {payload}) {
            return storeByIdsUpdate(state, payload)
        },
        ...createPurgeStoreHandler(actions.purge),
    },
    state
)
