import {createAction} from 'redux-actions'

export const fetchWalktest = createAction('DEVICES/WALKTEST/FETCH', (panelId) => ({
    panelId,
}))
export const startWalktest = createAction('DEVICES/WALKTEST/START', (panelId) => ({
    panelId,
}))
export const stopWalktest = createAction('DEVICES/WALKTEST/STOP', (panelId) => ({
    panelId,
}))
export const toggleWalktest = createAction('DEVICES/WALKTEST/TOGGLE', (panelId) => ({
    panelId,
}))
export const receiveWalktest = createAction(
    'DEVICES/WALKTEST/RECEIVE',
    null,
    (_, panelId) => ({panelId})
)
