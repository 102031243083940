import {createAction} from 'redux-actions'
import createListActions from 'modules/higherOrder/createListActions'

export default function createListWithoutPaginationActions(
    actionPrefix: string,
    options: Object
) {
    return {
        ...createListActions(actionPrefix, options),
        reset: createAction(actionPrefix + '/RESET'),

        init: createAction(actionPrefix + '/INIT', (scope, action, filters) => ({
            scope,
            action,
            filters,
        })),
    }
}
