import {handleActions} from 'redux-actions'

import {update, purge, stop, resultStop} from './actions'
import storeByIdsUpdate from 'utils/storeByIdsUpdate'
import createPurgeStoreHandler from 'modules/higherOrder/createPurgeStoreHandler'

const defaultState = {
    byIds: {},
    stoppingIds: [],
    stoppedIds: [],
}

export default handleActions(
    {
        [update](state, {payload: runners}) {
            //verify stopped ids - list of already stopped runner ids and returned by next pool
            const verifiedStoppedIds = state.stoppedIds.filter((id) =>
                runners.some((runner) => runner.id == id)
            )
            return {
                ...storeByIdsUpdate(state, runners),
                //remove verified ids - mark runners by real status
                stoppedIds: state.stoppedIds.filter(
                    (id) => !verifiedStoppedIds.includes(id)
                ),
                stoppingIds: state.stoppingIds.filter(
                    (id) => !verifiedStoppedIds.includes(id)
                ),
            }
        },
        [stop](state, {payload: ids}) {
            //save runner ids for mark it Stopping...
            return {
                ...state,
                stoppingIds: [...state.stoppingIds, ...ids.map(parseInt)],
            }
        },
        [resultStop](state, {payload: ids}) {
            //save runners ids for runners when stop request finished
            return {
                ...state,
                stoppedIds: [...state.stoppedIds, ...ids.map(parseInt)],
            }
        },
        ...createPurgeStoreHandler(purge),
    },
    defaultState
)
