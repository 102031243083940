import groupBy from 'lodash-es/groupBy'

import {PROCESS_TYPE_PMAXANONYMOUS} from 'constants/processTypes'
import makeNotStartedProcess from 'modules/processes/manager/helpers/makeNotStartedProcess'

// WORKAROUND
// sometimes process handling went incredibly wrong
// despite process is created, server couldn't set right process type
// but we are smart enough to fix that behaviour
const fixAnonymousProcessType = (process, type) => {
    if (process && process.type === PROCESS_TYPE_PMAXANONYMOUS) {
        process.type = type
    }

    return process
}

export default function mergeStubsAndProcesses(stubs, processes) {
    const stubsByPanelId = groupBy(stubs, 'panelId')

    const receivedProcesses = processes.map((process) => {
        const stub =
            stubsByPanelId[process.panelId] && stubsByPanelId[process.panelId].shift()

        if (!stub) {
            return process
        }

        process.key = stub.key

        return fixAnonymousProcessType(process, stub.type)
    })

    const failedToStart = Object.values(stubsByPanelId)
        .reduce((acc, stubs) => acc.concat(stubs), [])
        .map(makeNotStartedProcess)

    return receivedProcesses.concat(failedToStart)
}
