import {compose} from 'redux'
import {connect} from 'react-redux'

import withForm from 'containers/withForm'
import {editEventType} from 'modules/forms/handlers'
import EventTypeForm from './EventTypeForm'
import {validation} from './EventTypeFieldset'
import {__} from 'utils/i18n'

export default compose(
    connect(({eventTypes}, {eventTypeId}) => ({
        data: eventTypes.store.byIds[eventTypeId],
        header: __('Edit Event Type'),
        rules: validation,
    })),
    withForm(editEventType),
    connect(null, (dispatcher, {eventTypeId, handle}) => ({
        handle: (data) => handle({id: eventTypeId, ...data}),
    }))
)(EventTypeForm)
