import Filter from 'components/Search/Filters/Filter'
import BoardSuggestFilter from 'components/Search/Board/BoardSuggestFilter'

export default class SuggestFilter extends Filter {
    hasSuggests = true

    Component = BoardSuggestFilter

    getItems(values) {
        if (!values) {
            return []
        }

        return values.map(this.createItem.bind(this))
    }
}
