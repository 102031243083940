import withProps from 'containers/withProps'

import Page from 'ipmp-react-ui/Page'

import EventsSearch from './List/EventsSearch'
import EventsGridBar from './List/EventsGridBar'
import EventsTable from './List/EventsTable'

export default withProps({
    Top: EventsSearch,
    Bar: EventsGridBar,
    Content: EventsTable,
})(Page)
