import React from 'react'
import PropTypes from 'prop-types'

import * as Neo from './Panel/Neo'
import * as Qolsys from './Panel/Qolsys'
import * as DualPath from './Panel/DualPath'
import * as PowerMaster from './Panel/PowerMaster'

import * as Generic from './Panel/Generic'

import {
    VENDOR_NEO,
    VENDOR_POWER_MASTER,
    VENDOR_DUAL_PATH,
    VENDOR_QOLSYS,
} from 'constants/panelVendorType'

export const validation = (vendor) => {
    switch (vendor) {
        case VENDOR_POWER_MASTER:
            return PowerMaster.rules
        case VENDOR_NEO:
            return Neo.rules
        case VENDOR_DUAL_PATH:
            return DualPath.rules
        case VENDOR_QOLSYS:
            return Qolsys.rules
        default:
            return Generic.rules
    }
}

const PanelFieldSet = ({isNew = false, data, vendor}) => {
    switch (vendor) {
        case VENDOR_QOLSYS:
            return <Qolsys.Fieldset isNew={isNew} data={data} />
        case VENDOR_POWER_MASTER:
            return <PowerMaster.Fieldset isNew={isNew} data={data} />
        case VENDOR_NEO:
            return <Neo.Fieldset isNew={isNew} data={data} />
        case VENDOR_DUAL_PATH:
            return <DualPath.Fieldset isNew={isNew} data={data} />
        default:
            return <Generic.Fieldset isNew={isNew} data={data} />
    }
}

PanelFieldSet.propTypes = {
    groups: PropTypes.array,
}

PanelFieldSet.defaultProps = {
    groups: [],
    data: {},
}

export default PanelFieldSet
