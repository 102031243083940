import __ from 'utils/i18n'
import isFunction from 'lodash-es/isFunction'
import {err} from 'utils/log'

// from database table `country`
const COUNTY_AFGHANISTAN = 'Afghanistan'
const COUNTY_ALBANIA = 'Albania'
const COUNTY_ALGERIA = 'Algeria'
const COUNTY_ANDORRA = 'Andorra'
const COUNTY_ANGOLA = 'Angola'
const COUNTY_ANTIGUA_AND_BARBUDA = 'Antigua and Barbuda'
const COUNTY_ARGENTINA = 'Argentina'
const COUNTY_ARMENIA = 'Armenia'
const COUNTY_AUSTRALIA = 'Australia'
const COUNTY_AUSTRIA = 'Austria'
const COUNTY_AZERBAIJAN = 'Azerbaijan'
const COUNTY_THE_BAHAMAS = 'The Bahamas'
const COUNTY_BAHRAIN = 'Bahrain'
const COUNTY_BANGLADESH = 'Bangladesh'
const COUNTY_BARBADOS = 'Barbados'
const COUNTY_BELARUS = 'Belarus'
const COUNTY_BELGIUM = 'Belgium'
const COUNTY_BELIZE = 'Belize'
const COUNTY_BENIN = 'Benin'
const COUNTY_BHUTAN = 'Bhutan'
const COUNTY_BOLIVIA = 'Bolivia'
const COUNTY_BOSNIA_AND_HERZEGOVINA = 'Bosnia and Herzegovina'
const COUNTY_BOTSWANA = 'Botswana'
const COUNTY_BRAZIL = 'Brazil'
const COUNTY_BRUNEI = 'Brunei'
const COUNTY_BULGARIA = 'Bulgaria'
const COUNTY_BURKINA_FASO = 'Burkina Faso'
const COUNTY_BURUNDI = 'Burundi'
const COUNTY_CAMBODIA = 'Cambodia'
const COUNTY_CAMEROON = 'Cameroon'
const COUNTY_CANADA = 'Canada'
const COUNTY_CAPE_VERDE = 'Cape Verde'
const COUNTY_CENTRAL_AFRICAN_REPUBLIC = 'Central African Republic'
const COUNTY_CHAD = 'Chad'
const COUNTY_CHILE = 'Chile'
const COUNTY_CHINA = 'China'
const COUNTY_COLOMBIA = 'Colombia'
const COUNTY_COMOROS = 'Comoros'
const COUNTY_CONGO_REPUBLIC_OF_THE = 'Congo, Republic of the'
const COUNTY_CONGO_DEMOCRATIC_REPUBLIC_OF_THE = 'Congo, Democratic Republic of the'
const COUNTY_COSTA_RICA = 'Costa Rica'
const COUNTY_COTE_DIVOIRE = "Cote d'Ivoire"
const COUNTY_CROATIA = 'Croatia'
const COUNTY_CUBA = 'Cuba'
const COUNTY_CYPRUS = 'Cyprus'
const COUNTY_CZECH_REPUBLIC = 'Czech Republic'
const COUNTY_DENMARK = 'Denmark'
const COUNTY_DJIBOUTI = 'Djibouti'
const COUNTY_DOMINICA = 'Dominica'
const COUNTY_DOMINICAN_REPUBLIC = 'Dominican Republic'
const COUNTY_EAST_TIMOR = 'East Timor'
const COUNTY_ECUADOR = 'Ecuador'
const COUNTY_EGYPT = 'Egypt'
const COUNTY_EL_SALVADOR = 'El Salvador'
const COUNTY_EQUATORIAL_GUINEA = 'Equatorial Guinea'
const COUNTY_ERITREA = 'Eritrea'
const COUNTY_ESTONIA = 'Estonia'
const COUNTY_ETHIOPIA = 'Ethiopia'
const COUNTY_FIJI = 'Fiji'
const COUNTY_FINLAND = 'Finland'
const COUNTY_FRANCE = 'France'
const COUNTY_GABON = 'Gabon'
const COUNTY_THE_GAMBIA = 'The Gambia'
const COUNTY_GEORGIA = 'Georgia'
const COUNTY_GERMANY = 'Germany'
const COUNTY_GHANA = 'Ghana'
const COUNTY_GREECE = 'Greece'
const COUNTY_GRENADA = 'Grenada'
const COUNTY_GUATEMALA = 'Guatemala'
const COUNTY_GUINEA = 'Guinea'
const COUNTY_GUINEA_BISSAU = 'Guinea-Bissau'
const COUNTY_GUYANA = 'Guyana'
const COUNTY_HAITI = 'Haiti'
const COUNTY_HONDURAS = 'Honduras'
const COUNTY_HUNGARY = 'Hungary'
const COUNTY_ICELAND = 'Iceland'
const COUNTY_INDIA = 'India'
const COUNTY_INDONESIA = 'Indonesia'
const COUNTY_IRAN = 'Iran'
const COUNTY_IRAQ = 'Iraq'
const COUNTY_IRELAND = 'Ireland'
const COUNTY_ISRAEL = 'Israel'
const COUNTY_ITALY = 'Italy'
const COUNTY_JAMAICA = 'Jamaica'
const COUNTY_JAPAN = 'Japan'
const COUNTY_JORDAN = 'Jordan'
const COUNTY_KAZAKHSTAN = 'Kazakhstan'
const COUNTY_KENYA = 'Kenya'
const COUNTY_KIRIBATI = 'Kiribati'
const COUNTY_KOREA_NORTH = 'Korea, North'
const COUNTY_KOREA_SOUTH = 'Korea, South'
const COUNTY_KUWAIT = 'Kuwait'
const COUNTY_KYRGYZ_REPUBLIC = 'Kyrgyz Republic'
const COUNTY_LAOS = 'Laos'
const COUNTY_LATVIA = 'Latvia'
const COUNTY_LEBANON = 'Lebanon'
const COUNTY_LESOTHO = 'Lesotho'
const COUNTY_LIBERIA = 'Liberia'
const COUNTY_LIBYA = 'Libya'
const COUNTY_LIECHTENSTEIN = 'Liechtenstein'
const COUNTY_LITHUANIA = 'Lithuania'
const COUNTY_LUXEMBOURG = 'Luxembourg'
const COUNTY_MACEDONIA = 'Macedonia'
const COUNTY_MADAGASCAR = 'Madagascar'
const COUNTY_MALAWI = 'Malawi'
const COUNTY_MALAYSIA = 'Malaysia'
const COUNTY_MALDIVES = 'Maldives'
const COUNTY_MALI = 'Mali'
const COUNTY_MALTA = 'Malta'
const COUNTY_MARSHALL_ISLANDS = 'Marshall Islands'
const COUNTY_MAURITANIA = 'Mauritania'
const COUNTY_MAURITIUS = 'Mauritius'
const COUNTY_MEXICO = 'Mexico'
const COUNTY_FEDERATED_STATES_OF_MICRONESIA = 'Federated States of Micronesia'
const COUNTY_MOLDOVA = 'Moldova'
const COUNTY_MONACO = 'Monaco'
const COUNTY_MONGOLIA = 'Mongolia'
const COUNTY_MOROCCO = 'Morocco'
const COUNTY_MOZAMBIQUE = 'Mozambique'
const COUNTY_MYANMAR_BURMA_ = 'Myanmar (Burma)'
const COUNTY_NAMIBIA = 'Namibia'
const COUNTY_NAURU = 'Nauru'
const COUNTY_NEPAL = 'Nepal'
const COUNTY_NETHERLANDS = 'Netherlands'
const COUNTY_NEW_ZEALAND = 'New Zealand'
const COUNTY_NICARAGUA = 'Nicaragua'
const COUNTY_NIGER = 'Niger'
const COUNTY_NIGERIA = 'Nigeria'
const COUNTY_NORWAY = 'Norway'
const COUNTY_OMAN = 'Oman'
const COUNTY_PAKISTAN = 'Pakistan'
const COUNTY_PALAU = 'Palau'
const COUNTY_PANAMA = 'Panama'
const COUNTY_PAPUA_NEW_GUINEA = 'Papua New Guinea'
const COUNTY_PARAGUAY = 'Paraguay'
const COUNTY_PERU = 'Peru'
const COUNTY_PHILIPPINES = 'Philippines'
const COUNTY_POLAND = 'Poland'
const COUNTY_PORTUGAL = 'Portugal'
const COUNTY_QATAR = 'Qatar'
const COUNTY_ROMANIA = 'Romania'
const COUNTY_RUSSIA = 'Russia'
const COUNTY_RWANDA = 'Rwanda'
const COUNTY_SAINT_KITTS_AND_NEVIS = 'Saint Kitts and Nevis'
const COUNTY_SAINT_LUCIA = 'Saint Lucia'
const COUNTY_SAMOA = 'Samoa'
const COUNTY_SAN_MARINO = 'San Marino'
const COUNTY_SAO_TOME_AND_PRINCIPE = 'Sao Tome and Principe'
const COUNTY_SAUDI_ARABIA = 'Saudi Arabia'
const COUNTY_SENEGAL = 'Senegal'
const COUNTY_SERBIA_AND_MONTENEGRO = 'Serbia and Montenegro'
const COUNTY_SEYCHELLES = 'Seychelles'
const COUNTY_SIERRA_LEONE = 'Sierra Leone'
const COUNTY_SINGAPORE = 'Singapore'
const COUNTY_SLOVAKIA = 'Slovakia'
const COUNTY_SLOVENIA = 'Slovenia'
const COUNTY_SOLOMON_ISLANDS = 'Solomon Islands'
const COUNTY_SOMALIA = 'Somalia'
const COUNTY_SOUTH_AFRICA = 'South Africa'
const COUNTY_SPAIN = 'Spain'
const COUNTY_SRI_LANKA = 'Sri Lanka'
const COUNTY_SUDAN = 'Sudan'
const COUNTY_SURINAME = 'Suriname'
const COUNTY_SWAZILAND = 'Swaziland'
const COUNTY_SWEDEN = 'Sweden'
const COUNTY_SWITZERLAND = 'Switzerland'
const COUNTY_SYRIA = 'Syria'
const COUNTY_TAIWAN = 'Taiwan'
const COUNTY_TAJIKISTAN = 'Tajikistan'
const COUNTY_TANZANIA = 'Tanzania'
const COUNTY_THAILAND = 'Thailand'
const COUNTY_TOGO = 'Togo'
const COUNTY_TONGA = 'Tonga'
const COUNTY_TRINIDAD_AND_TOBAGO = 'Trinidad and Tobago'
const COUNTY_TUNISIA = 'Tunisia'
const COUNTY_TURKEY = 'Turkey'
const COUNTY_TURKMENISTAN = 'Turkmenistan'
const COUNTY_TUVALU = 'Tuvalu'
const COUNTY_UGANDA = 'Uganda'
const COUNTY_UKRAINE = 'Ukraine'
const COUNTY_UNITED_ARAB_EMIRATES = 'United Arab Emirates'
const COUNTY_UNITED_KINGDOM = 'United Kingdom'
const COUNTY_UNITED_STATES = 'United States'
const COUNTY_URUGUAY = 'Uruguay'
const COUNTY_UZBEKISTAN = 'Uzbekistan'
const COUNTY_VANUATU = 'Vanuatu'
const COUNTY_VATICAN_CITY_HOLY_SEE = 'Vatican City (Holy See)'
const COUNTY_VENEZUELA = 'Venezuela'
const COUNTY_VIETNAM = 'Vietnam'
const COUNTY_YEMEN = 'Yemen'
const COUNTY_ZAMBIA = 'Zambia'
const COUNTY_ZIMBABWE = 'Zimbabwe'
const COUNTY_MONTENEGRO = 'Montenegro'

const countryTitle = {
    [COUNTY_AFGHANISTAN]: () => __('Afghanistan'),
    [COUNTY_ALBANIA]: () => __('Albania'),
    [COUNTY_ALGERIA]: () => __('Algeria'),
    [COUNTY_ANDORRA]: () => __('Andorra'),
    [COUNTY_ANGOLA]: () => __('Angola'),
    [COUNTY_ANTIGUA_AND_BARBUDA]: () => __('Antigua and Barbuda'),
    [COUNTY_ARGENTINA]: () => __('Argentina'),
    [COUNTY_ARMENIA]: () => __('Armenia'),
    [COUNTY_AUSTRALIA]: () => __('Australia'),
    [COUNTY_AUSTRIA]: () => __('Austria'),
    [COUNTY_AZERBAIJAN]: () => __('Azerbaijan'),
    [COUNTY_THE_BAHAMAS]: () => __('The Bahamas'),
    [COUNTY_BAHRAIN]: () => __('Bahrain'),
    [COUNTY_BANGLADESH]: () => __('Bangladesh'),
    [COUNTY_BARBADOS]: () => __('Barbados'),
    [COUNTY_BELARUS]: () => __('Belarus'),
    [COUNTY_BELGIUM]: () => __('Belgium'),
    [COUNTY_BELIZE]: () => __('Belize'),
    [COUNTY_BENIN]: () => __('Benin'),
    [COUNTY_BHUTAN]: () => __('Bhutan'),
    [COUNTY_BOLIVIA]: () => __('Bolivia'),
    [COUNTY_BOSNIA_AND_HERZEGOVINA]: () => __('Bosnia and Herzegovina'),
    [COUNTY_BOTSWANA]: () => __('Botswana'),
    [COUNTY_BRAZIL]: () => __('Brazil'),
    [COUNTY_BRUNEI]: () => __('Brunei'),
    [COUNTY_BULGARIA]: () => __('Bulgaria'),
    [COUNTY_BURKINA_FASO]: () => __('Burkina Faso'),
    [COUNTY_BURUNDI]: () => __('Burundi'),
    [COUNTY_CAMBODIA]: () => __('Cambodia'),
    [COUNTY_CAMEROON]: () => __('Cameroon'),
    [COUNTY_CANADA]: () => __('Canada'),
    [COUNTY_CAPE_VERDE]: () => __('Cape Verde'),
    [COUNTY_CENTRAL_AFRICAN_REPUBLIC]: () => __('Central African Republic'),
    [COUNTY_CHAD]: () => __('Chad'),
    [COUNTY_CHILE]: () => __('Chile'),
    [COUNTY_CHINA]: () => __('China'),
    [COUNTY_COLOMBIA]: () => __('Colombia'),
    [COUNTY_COMOROS]: () => __('Comoros'),
    [COUNTY_CONGO_REPUBLIC_OF_THE]: () => __('Congo, Republic of the'),
    [COUNTY_CONGO_DEMOCRATIC_REPUBLIC_OF_THE]: () =>
        __('Congo, Democratic Republic of the'),
    [COUNTY_COSTA_RICA]: () => __('Costa Rica'),
    [COUNTY_COTE_DIVOIRE]: () => __("Cote d'Ivoire"),
    [COUNTY_CROATIA]: () => __('Croatia'),
    [COUNTY_CUBA]: () => __('Cuba'),
    [COUNTY_CYPRUS]: () => __('Cyprus'),
    [COUNTY_CZECH_REPUBLIC]: () => __('Czech Republic'),
    [COUNTY_DENMARK]: () => __('Denmark'),
    [COUNTY_DJIBOUTI]: () => __('Djibouti'),
    [COUNTY_DOMINICA]: () => __('Dominica'),
    [COUNTY_DOMINICAN_REPUBLIC]: () => __('Dominican Republic'),
    [COUNTY_EAST_TIMOR]: () => __('East Timor'),
    [COUNTY_ECUADOR]: () => __('Ecuador'),
    [COUNTY_EGYPT]: () => __('Egypt'),
    [COUNTY_EL_SALVADOR]: () => __('El Salvador'),
    [COUNTY_EQUATORIAL_GUINEA]: () => __('Equatorial Guinea'),
    [COUNTY_ERITREA]: () => __('Eritrea'),
    [COUNTY_ESTONIA]: () => __('Estonia'),
    [COUNTY_ETHIOPIA]: () => __('Ethiopia'),
    [COUNTY_FIJI]: () => __('Fiji'),
    [COUNTY_FINLAND]: () => __('Finland'),
    [COUNTY_FRANCE]: () => __('France'),
    [COUNTY_GABON]: () => __('Gabon'),
    [COUNTY_THE_GAMBIA]: () => __('The Gambia'),
    [COUNTY_GEORGIA]: () => __('Georgia'),
    [COUNTY_GERMANY]: () => __('Germany'),
    [COUNTY_GHANA]: () => __('Ghana'),
    [COUNTY_GREECE]: () => __('Greece'),
    [COUNTY_GRENADA]: () => __('Grenada'),
    [COUNTY_GUATEMALA]: () => __('Guatemala'),
    [COUNTY_GUINEA]: () => __('Guinea'),
    [COUNTY_GUINEA_BISSAU]: () => __('Guinea-Bissau'),
    [COUNTY_GUYANA]: () => __('Guyana'),
    [COUNTY_HAITI]: () => __('Haiti'),
    [COUNTY_HONDURAS]: () => __('Honduras'),
    [COUNTY_HUNGARY]: () => __('Hungary'),
    [COUNTY_ICELAND]: () => __('Iceland'),
    [COUNTY_INDIA]: () => __('India'),
    [COUNTY_INDONESIA]: () => __('Indonesia'),
    [COUNTY_IRAN]: () => __('Iran'),
    [COUNTY_IRAQ]: () => __('Iraq'),
    [COUNTY_IRELAND]: () => __('Ireland'),
    [COUNTY_ISRAEL]: () => __('Israel'),
    [COUNTY_ITALY]: () => __('Italy'),
    [COUNTY_JAMAICA]: () => __('Jamaica'),
    [COUNTY_JAPAN]: () => __('Japan'),
    [COUNTY_JORDAN]: () => __('Jordan'),
    [COUNTY_KAZAKHSTAN]: () => __('Kazakhstan'),
    [COUNTY_KENYA]: () => __('Kenya'),
    [COUNTY_KIRIBATI]: () => __('Kiribati'),
    [COUNTY_KOREA_NORTH]: () => __('Korea, North'),
    [COUNTY_KOREA_SOUTH]: () => __('Korea, South'),
    [COUNTY_KUWAIT]: () => __('Kuwait'),
    [COUNTY_KYRGYZ_REPUBLIC]: () => __('Kyrgyz Republic'),
    [COUNTY_LAOS]: () => __('Laos'),
    [COUNTY_LATVIA]: () => __('Latvia'),
    [COUNTY_LEBANON]: () => __('Lebanon'),
    [COUNTY_LESOTHO]: () => __('Lesotho'),
    [COUNTY_LIBERIA]: () => __('Liberia'),
    [COUNTY_LIBYA]: () => __('Libya'),
    [COUNTY_LIECHTENSTEIN]: () => __('Liechtenstein'),
    [COUNTY_LITHUANIA]: () => __('Lithuania'),
    [COUNTY_LUXEMBOURG]: () => __('Luxembourg'),
    [COUNTY_MACEDONIA]: () => __('Macedonia'),
    [COUNTY_MADAGASCAR]: () => __('Madagascar'),
    [COUNTY_MALAWI]: () => __('Malawi'),
    [COUNTY_MALAYSIA]: () => __('Malaysia'),
    [COUNTY_MALDIVES]: () => __('Maldives'),
    [COUNTY_MALI]: () => __('Mali'),
    [COUNTY_MALTA]: () => __('Malta'),
    [COUNTY_MARSHALL_ISLANDS]: () => __('Marshall Islands'),
    [COUNTY_MAURITANIA]: () => __('Mauritania'),
    [COUNTY_MAURITIUS]: () => __('Mauritius'),
    [COUNTY_MEXICO]: () => __('Mexico'),
    [COUNTY_FEDERATED_STATES_OF_MICRONESIA]: () => __('Federated States of Micronesia'),
    [COUNTY_MOLDOVA]: () => __('Moldova'),
    [COUNTY_MONACO]: () => __('Monaco'),
    [COUNTY_MONGOLIA]: () => __('Mongolia'),
    [COUNTY_MOROCCO]: () => __('Morocco'),
    [COUNTY_MOZAMBIQUE]: () => __('Mozambique'),
    [COUNTY_MYANMAR_BURMA_]: () => __('Myanmar (Burma)'),
    [COUNTY_NAMIBIA]: () => __('Namibia'),
    [COUNTY_NAURU]: () => __('Nauru'),
    [COUNTY_NEPAL]: () => __('Nepal'),
    [COUNTY_NETHERLANDS]: () => __('Netherlands'),
    [COUNTY_NEW_ZEALAND]: () => __('New Zealand'),
    [COUNTY_NICARAGUA]: () => __('Nicaragua'),
    [COUNTY_NIGER]: () => __('Niger'),
    [COUNTY_NIGERIA]: () => __('Nigeria'),
    [COUNTY_NORWAY]: () => __('Norway'),
    [COUNTY_OMAN]: () => __('Oman'),
    [COUNTY_PAKISTAN]: () => __('Pakistan'),
    [COUNTY_PALAU]: () => __('Palau'),
    [COUNTY_PANAMA]: () => __('Panama'),
    [COUNTY_PAPUA_NEW_GUINEA]: () => __('Papua New Guinea'),
    [COUNTY_PARAGUAY]: () => __('Paraguay'),
    [COUNTY_PERU]: () => __('Peru'),
    [COUNTY_PHILIPPINES]: () => __('Philippines'),
    [COUNTY_POLAND]: () => __('Poland'),
    [COUNTY_PORTUGAL]: () => __('Portugal'),
    [COUNTY_QATAR]: () => __('Qatar'),
    [COUNTY_ROMANIA]: () => __('Romania'),
    [COUNTY_RUSSIA]: () => __('Russia'),
    [COUNTY_RWANDA]: () => __('Rwanda'),
    [COUNTY_SAINT_KITTS_AND_NEVIS]: () => __('Saint Kitts and Nevis'),
    [COUNTY_SAINT_LUCIA]: () => __('Saint Lucia'),
    [COUNTY_SAMOA]: () => __('Samoa'),
    [COUNTY_SAN_MARINO]: () => __('San Marino'),
    [COUNTY_SAO_TOME_AND_PRINCIPE]: () => __('Sao Tome and Principe'),
    [COUNTY_SAUDI_ARABIA]: () => __('Saudi Arabia'),
    [COUNTY_SENEGAL]: () => __('Senegal'),
    [COUNTY_SERBIA_AND_MONTENEGRO]: () => __('Serbia and Montenegro'),
    [COUNTY_SEYCHELLES]: () => __('Seychelles'),
    [COUNTY_SIERRA_LEONE]: () => __('Sierra Leone'),
    [COUNTY_SINGAPORE]: () => __('Singapore'),
    [COUNTY_SLOVAKIA]: () => __('Slovakia'),
    [COUNTY_SLOVENIA]: () => __('Slovenia'),
    [COUNTY_SOLOMON_ISLANDS]: () => __('Solomon Islands'),
    [COUNTY_SOMALIA]: () => __('Somalia'),
    [COUNTY_SOUTH_AFRICA]: () => __('South Africa'),
    [COUNTY_SPAIN]: () => __('Spain'),
    [COUNTY_SRI_LANKA]: () => __('Sri Lanka'),
    [COUNTY_SUDAN]: () => __('Sudan'),
    [COUNTY_SURINAME]: () => __('Suriname'),
    [COUNTY_SWAZILAND]: () => __('Swaziland'),
    [COUNTY_SWEDEN]: () => __('Sweden'),
    [COUNTY_SWITZERLAND]: () => __('Switzerland'),
    [COUNTY_SYRIA]: () => __('Syria'),
    [COUNTY_TAIWAN]: () => __('Taiwan'),
    [COUNTY_TAJIKISTAN]: () => __('Tajikistan'),
    [COUNTY_TANZANIA]: () => __('Tanzania'),
    [COUNTY_THAILAND]: () => __('Thailand'),
    [COUNTY_TOGO]: () => __('Togo'),
    [COUNTY_TONGA]: () => __('Tonga'),
    [COUNTY_TRINIDAD_AND_TOBAGO]: () => __('Trinidad and Tobago'),
    [COUNTY_TUNISIA]: () => __('Tunisia'),
    [COUNTY_TURKEY]: () => __('Turkey'),
    [COUNTY_TURKMENISTAN]: () => __('Turkmenistan'),
    [COUNTY_TUVALU]: () => __('Tuvalu'),
    [COUNTY_UGANDA]: () => __('Uganda'),
    [COUNTY_UKRAINE]: () => __('Ukraine'),
    [COUNTY_UNITED_ARAB_EMIRATES]: () => __('United Arab Emirates'),
    [COUNTY_UNITED_KINGDOM]: () => __('United Kingdom'),
    [COUNTY_UNITED_STATES]: () => __('United States'),
    [COUNTY_URUGUAY]: () => __('Uruguay'),
    [COUNTY_UZBEKISTAN]: () => __('Uzbekistan'),
    [COUNTY_VANUATU]: () => __('Vanuatu'),
    [COUNTY_VATICAN_CITY_HOLY_SEE]: () => __('Vatican City (Holy See)'),
    [COUNTY_VENEZUELA]: () => __('Venezuela'),
    [COUNTY_VIETNAM]: () => __('Vietnam'),
    [COUNTY_YEMEN]: () => __('Yemen'),
    [COUNTY_ZAMBIA]: () => __('Zambia'),
    [COUNTY_ZIMBABWE]: () => __('Zimbabwe'),
    [COUNTY_MONTENEGRO]: () => __('Montenegro'),
}

export default function getCountryTitle(countryName) {
    const title = countryTitle[countryName]

    if (isFunction(title)) {
        return title()
    }

    err(`No country title for ${countryName}`)
    return countryName
}
