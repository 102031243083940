import {handleActions} from 'redux-actions'
import keyBy from 'lodash-es/keyBy'
import {merge} from 'immutable-modify'
import omit from 'lodash-es/omit'

import {unassignInstallerSuccess} from 'modules/installerPanels/list/actions'
import * as actions from './actions'

const defaultState = {}

export default handleActions(
    {
        [actions.update](state, {payload, meta: {panelId}}) {
            return merge(state, panelId, keyBy(payload, 'id'))
        },
        [actions.purge]() {
            return defaultState
        },
        [unassignInstallerSuccess](state, {payload: {panelId, installerId}}) {
            return {
                ...state,
                [panelId]: {...omit(state[panelId], [installerId])},
            }
        },
    },
    defaultState
)
