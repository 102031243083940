import {createAction} from 'redux-actions'
import {TEMPERATURE} from 'constants/meteoTypes'

export const setError = createAction(
    'DEVICES/METEO/SET_ERROR',
    (panelId, deviceId, type, error) => ({
        panelId,
        deviceId,
        type,
        error,
    })
)

export const setLoading = createAction(
    'DEVICES/METEO/SET_LOADING',
    (panelId, deviceId, type = TEMPERATURE, isLoading = true) => ({
        panelId,
        deviceId,
        type,
        isLoading,
    })
)

export const purge = createAction('DEVICES/METEO/PURGE', (panelId) => ({
    panelId,
}))

export const fetch = createAction(
    'DEVICES/METEO/FETCH',
    (panelId, deviceId, type = TEMPERATURE) => ({
        panelId,
        deviceId,
        type,
    })
)

export const receive = createAction(
    'DEVICES/METEO/RECEIVE',
    (panelId, deviceId, type = TEMPERATURE, data) => ({
        panelId,
        deviceId,
        type,
        data,
    })
)
