import configure from 'ipmp-react-ui/config'
import {__, __n} from 'utils/i18n'

export default function initIpmpReactUI() {
    configure({
        gettext: __,
        ngettext: __n,
        messages: (__, __n) => [
            __('All'),
            __('Fast Search'),
            __('...%d more'),
            __('Today'),
            __('Tomorrow'),
            __('Yesterday'),
            __('Not found'),
            __('... type for more elements'),
            __('Ok'),
            __('Refresh'),
            __('Maximum number of values selected'),
            __('Press Enter to create'),
            __('Empty'),
            __('Select All'),
            __('Dismiss'),
            __('AM'),
            __('PM'),
            __('Choose File'),
            __('File not selected'),
            __('Save'),
            __('of %s'),
            __('Error while loading map'),
            __("Coudn't find address %s"),
            __('MAXIMUM'),
            __('No rows found'),
            __('less than minute'),
            __('Type new value and press Enter'),
            __n('SELECTED %d ROW', 'SELECTED %d ROWS'),
            __n('%d year', '%d years'),
            __n('%d month', '%d months'),
            __n('%d week', '%d weeks'),
            __n('%d day', '%d days'),
            __n('%d hour', '%d hours'),
            __n('%d minute', '%d minutes'),
            __n('%d second', '%d seconds'),
        ],
    })
}
