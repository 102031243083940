import dataMapper from 'utils/dataMapper'

export const keysMap = {
    id: 'id',
    name: 'name',
    isDisarmState: 'is_disarm_state',
    hasEntryDelay: 'has_entry_delay',
    triggersExitDelay: 'triggers_exit_delay',
}

export const mapStateTypes = dataMapper((data) => ({
    id: data.id,
    name: data.name,
    isDisarmState: data.is_disarm_state,
    hasEntryDelay: data.has_entry_delay,
    triggersExitDelay: data.triggers_exit_delay,
}))

export const mapFormData = (formData) => ({
    name: formData.name,
    is_disarm_state: formData.isDisarmState === 'on' || false,
    has_entry_delay: formData.hasEntryDelay === 'on' || false,
    triggers_exit_delay: formData.triggersExitDelay === 'on' || false,
})
