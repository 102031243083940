import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'

import {__} from 'utils/i18n'
import {
    CHANNEL_USB,
    CHANNEL_BBA,
    CHANNEL_GPRS,
    CHANNEL_BBA_OVER_GPRS,
    CHANNEL_WIFI,
    ENCRYPTION_DEFAULT,
    ENCRYPTION_NO,
    ENCRYPTION_ITV2_V1,
    ENCRYPTION_ITV2_V2,
    STATUS_NOT_CONFIGURED,
    STATUS_DISCONNECTED,
    STATUS_KA_DISABLED,
    STATUS_OFFLINE,
    STATUS_ONLINE,
    STATUS_CONNECTED,
    STATUS_CONFIGURED_TO_SLAVE,
    STATUS_CONFIGURED_OUTSIDE_PM,
} from 'constants/connections'

export default class ConnectionStatusTab extends Component {
    static propTypes = {
        connectionStatus: PropTypes.shape({
            status: PropTypes.object.isRequired,
            BBAInstalled: PropTypes.bool,
            GPRSInstalled: PropTypes.bool,
        }).isRequired,
    }

    renderChannelCell(connection) {
        switch (connection.channel_type) {
            case CHANNEL_USB:
                return __('USB') + ' ' + connection.number
            case CHANNEL_BBA:
                return __('BBA') + ' ' + connection.number
            case CHANNEL_GPRS:
            case CHANNEL_BBA_OVER_GPRS:
                return __('GPRS') + ' ' + connection.number
            case CHANNEL_WIFI:
                return __('WI-FI') + ' ' + connection.number
            default:
                return <span className="empty">-</span>
        }
    }

    renderEncryptionCell(connection) {
        switch (connection.encryption) {
            case ENCRYPTION_DEFAULT:
                return __('Encrypted')
            case ENCRYPTION_ITV2_V1:
                return __('ITv2 type 1')
            case ENCRYPTION_ITV2_V2:
                return __('ITv2 type 2')
            case ENCRYPTION_NO:
                return <span className="empty">{__('Unencrypted')}</span>
            default:
                return <span className="empty">-</span>
        }
    }

    renderStatusCell(connection) {
        switch (connection.value) {
            case STATUS_NOT_CONFIGURED:
                return <span className="empty">{__('Not configured')}</span>
            case STATUS_DISCONNECTED:
                return <span>{__('Not Connected')}</span>
            case STATUS_KA_DISABLED:
                return <span className="danger">{__('Keep-Alive disabled')}</span>
            case STATUS_OFFLINE:
                return <span className="danger">{__('Offline')}</span>
            case STATUS_ONLINE:
                return <span className="success">{__('Online')}</span>
            case STATUS_CONNECTED:
                return <span className="primary">{__('Connected')}</span>
            case STATUS_CONFIGURED_TO_SLAVE:
                return <span className="primary">{__('Configured to slave')}</span>
            case STATUS_CONFIGURED_OUTSIDE_PM:
                return <span className="primary">{__('Configured outside PM')}</span>
            default:
                return <span className="empty">-</span>
        }
    }

    renderConnection(connection) {
        return (
            <Fragment key={connection.number}>
                <td>{this.renderChannelCell(connection)}</td>
                <td>{this.renderStatusCell(connection)}</td>
                <td>{this.renderEncryptionCell(connection)}</td>
            </Fragment>
        )
    }

    renderRow(name, connections) {
        return (
            <Fragment key={name}>
                <tr className="connection-block">
                    <td className="connection-block-title" rowSpan={connections.length}>
                        {name}
                    </td>
                    {this.renderConnection(connections[0])}
                </tr>
                {connections.slice(1).map((connection) => (
                    <tr key={name + connection.number}>
                        {this.renderConnection(connection)}
                    </tr>
                ))}
            </Fragment>
        )
    }

    render() {
        const {
            connectionStatus: {status},
        } = this.props
        return (
            <div className="connection-status">
                <table>
                    <thead>
                        <tr>
                            <th>{__('Protocol')}</th>
                            <th>{__('Channel')}</th>
                            <th>{__('Status')}</th>
                            <th>{__('Encryption')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(status).map((name) =>
                            this.renderRow(name, status[name])
                        )}
                    </tbody>
                </table>
            </div>
        )
    }
}
