import createRowsResponseParser from 'api/base/createRowsResponseParser'
import {get, post} from 'api/http'
import generateSuggestString from 'api/base/generateSuggestString'
import generateFilterString from 'api/base/generateFilterString'
import mapSuggests from 'api/base/mapSuggests'
import {keysMap, mapEvent, mapLiveEventVideos, mapPreloadEventVideo} from 'api/events.map'

/**
 * @deprecated
 */
export function retrieve(start = 0, count = 10, filter = {}, sort = 'id', desc = true) {
    filter = generateFilterString(filter, keysMap)
    sort = (desc ? '-' : '') + (keysMap[sort] || sort)

    return get('/event/getallevents', {start, count, filter, sort}).then(
        createRowsResponseParser(mapEvent)
    )
}

export function fetch({start = 0, perPage: count = 10, filters, sort, query}) {
    const filter = generateFilterString(filters, keysMap)

    if (sort) {
        sort = Array.isArray(sort) ? sort : [sort]

        sort = sort
            .map((key) => {
                const desc = key[0] === '-' ? '-' : '+'

                key = key.replace(/^[-+]/, '')
                key = keysMap[key] || key

                return desc + key
            })
            .join(',')
    }

    return get('/event/getallevents', {start, count, filter, sort, query}).then(
        createRowsResponseParser(mapEvent)
    )
}

export function getVideo(id) {
    return get('/event/video', {evt_id: id}).then(({live, preloaded}) => ({
        live: live && live.map(mapLiveEventVideos),
        preloaded: preloaded && preloaded.map(mapPreloadEventVideo),
    }))
}

export function processor(ids) {
    if (!Array.isArray(ids)) {
        ids = [ids]
    }

    return post('/event/eventprocessor', {evt_id: ids})
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('event/suggestevents', {suggest, start, count}).then(
        mapSuggests(fields, keysMap)
    )
}
