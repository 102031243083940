import {put} from 'redux-saga/effects'

import * as api from 'api/panels'

import generateBatch from 'modules/batches/manager/generateBatch'
import {PROCESS_TYPE_NEO_DIAGNOSTIC} from 'constants/processTypes'
import {ensureProcesses} from 'modules/processes/manager/ensureProcess'
import {snackShow} from 'modules/snacks'
import __ from 'utils/i18n'

export default function* watchReadDiagnostic({payload: {panelId}}) {
    const {batchId} = yield generateBatch(PROCESS_TYPE_NEO_DIAGNOSTIC, [panelId])

    try {
        const {processes} = yield api.readDiagnostic(panelId, batchId)
        yield ensureProcesses(processes)
        yield put(snackShow(__('Read Diagnostic')))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
