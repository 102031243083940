import React from 'react'
import {compose} from 'redux'

import list from 'permissions/interactiveUsers/list'

import withProps from 'containers/withProps'
import withLoader from 'containers/withLoader'
import {withPanelInteractiveUsers} from 'containers/withInteractiveUsers'
import {withPermission, withRejection} from 'containers/withPermission'
import DateTime from 'ipmp-react-ui/DateTime'
import Definition from 'ipmp-react-ui/Definition'

import Table from 'ipmp-react-ui/Table'

import {__} from 'utils/i18n'
import DisconnectUserCell from './TableCell/DisconnectUserCell'
import PanelInteractiveUserStatusChanger from './TableCell/PanelInteractiveUserStatusChanger'
import withLifeCycle from 'containers/withLifeCycle'
import withTableColumnsPermission from 'containers/withTableColumnsPermission'
import {disconnect} from 'permissions/interactiveUsers/actions'

const columns = [
    {
        name: () => __('Role'),
        render: ({role}) => role,
    },
    {
        name: () => __('Label'),
        render: ({label}) => label,
    },
    {
        name: () => __('Email'),
        render: ({email, isAssign}) =>
            isAssign ? (
                <Definition title={email} />
            ) : (
                <span className="empty">{__('unassigned')}</span>
            ),
    },
    {
        name: () => __('Registered'),
        render: ({createdAt}) => <DateTime datetime={createdAt} />,
    },
    {
        name: () => __('Status'),
        tooltip: false,
        component: PanelInteractiveUserStatusChanger,
    },
    {
        tooltip: false,
        permission: disconnect,
        fixed: true,
        width: 56,
        component: DisconnectUserCell,
    },
]

export default compose(
    withPermission({
        isAllowed: list,
    }),
    withRejection(),
    withPanelInteractiveUsers(),
    withLifeCycle({
        onMount: ({startPoll, init}) => {
            init()
            startPoll()
        },
        onUnmount: ({stopPoll}) => {
            stopPoll()
        },
    }),
    withLoader(),
    withProps(({isShowUnassignedUsers, rows}) => ({
        fullHeight: true,
        columns,
        rows: isShowUnassignedUsers ? rows : rows.filter(({isAssign}) => isAssign),
        emptyMessage: () =>
            isShowUnassignedUsers
                ? __('No interactive users found')
                : __('No assigned users found'),
    })),
    withTableColumnsPermission()
)(Table)
