import {handleActions} from 'redux-actions'
import {set} from 'immutable-modify'
import keyBy from 'lodash-es/keyBy'

import * as actions from './actions'
import createPurgeStoreHandler from 'modules/higherOrder/createPurgeStoreHandler'

const defaultState = {
    isPackageLoading: false,
    byIds: {},
}

export default handleActions(
    {
        [actions.update]({byIds}, {payload}) {
            return {
                byIds: {
                    ...byIds,
                    ...keyBy(payload, 'id'),
                },
            }
        },
        [actions.setUpagradeStatusPackagesLoading](state, {payload: isLoading}) {
            isLoading = isLoading == undefined ? true : !!isLoading
            return set(state, 'isPackageLoading', isLoading)
        },
        ...createPurgeStoreHandler(actions.purge),
    },
    defaultState
)
