import React from 'react'

import Page from 'ipmp-react-ui/Page'

import EventTypesTable from 'pages/EventRules/EventTypes/EventTypesTable'
import EventTypesGridBar from 'pages/EventRules/EventTypes/EventTypesGridBar'

export default class EventTypesPage extends Page {
    renderBar() {
        return <EventTypesGridBar />
    }

    renderContent() {
        return <EventTypesTable />
    }
}
