import {handleActions} from 'redux-actions'

import * as actions from './actions'
import createPurgeStoreHandler from 'modules/higherOrder/createPurgeStoreHandler'

const state = {
    byIds: [],
}

export default handleActions(
    {
        [actions.update](state, {payload}) {
            const byIds = payload.reduce((acc, row) => {
                const old = acc[row.id]

                return {
                    ...acc,
                    [row.id]: {
                        ...old,
                        ...row,
                    },
                }
            }, state.byIds || {})

            return {
                ...state,
                byIds,
            }
        },

        [actions.clear]() {
            return state
        },

        [actions.accept](state, {payload: installerIds}) {
            const accepted = installerIds.reduce((acc, installerId) => {
                const installer = acc[installerId]

                return {
                    ...acc,
                    [installerId]: {
                        ...installer,
                        _status: installer.status,
                        status: 'accepted',
                    },
                }
            }, state.byIds)

            return {
                ...state,
                byIds: {
                    ...state.byIds,
                    ...accepted,
                },
            }
        },

        [actions.reject](state, {payload: installerIds}) {
            const rejected = installerIds.reduce((acc, installerId) => {
                const installer = acc[installerId]

                return {
                    ...acc,
                    [installerId]: {
                        ...installer,
                        _status: installer.status,
                        status: 'rejected',
                    },
                }
            }, state.byIds)

            return {
                ...state,
                byIds: {
                    ...state.byIds,
                    ...rejected,
                },
            }
        },

        [actions.revert](state, {payload: installerIds}) {
            const reverted = installerIds.reduce((acc, installerId) => {
                const {_status, ...installer} = acc[installerId]

                return {
                    ...acc,
                    [installerId]: {
                        ...installer,
                        status: _status,
                    },
                }
            }, state.byIds)

            return {
                ...state,
                byIds: {
                    ...state.byIds,
                    ...reverted,
                },
            }
        },
        ...createPurgeStoreHandler(actions.purge),
    },
    state
)
