import {
    takeEvery,
    call,
    put,
    select,
    all,
    fork,
    take,
    cancel,
    race,
    delay,
} from 'redux-saga/effects'
import min from 'lodash-es/min'
import max from 'lodash-es/max'

import {fetch} from 'api/events'
import * as actions from './actions'

import {POLL_EVENTS} from 'configs/pollers'

import {update} from 'modules/events/store/actions'
import {updateRows} from 'modules/events/store/saga'
import {snackShow} from 'modules/snacks'

import toIds from 'utils/toIds'

export default function* () {
    yield all([
        takeEvery([actions.fetch], watchFetch),
        takeEvery([actions.fetchNewer], watchFetchNewer),
        takeEvery([actions.fetchOlder], watchFetchOlder),
        takeEvery([actions.fetchRecent], watchFetchRecent),
        eventsBySerialPoll(),
    ])
}

export function* watchFetch({payload: {id}}) {
    const {page, serial, perPage} = yield select((state) => state.events.bySerial)

    try {
        const calls = {}

        if (page.filter((pId) => pId > id - 1).length < perPage) {
            calls.newer = call(fetch, {
                perPage,
                filters: {serial, id: `{>${id - 1}}`},
                sort: 'id',
            })
        }

        if (page.filter((pId) => pId < id).length < perPage) {
            calls.older = call(fetch, {
                perPage,
                filters: {serial, id: `{<${id}}`},
                sort: '-id',
            })
        }

        const {newer, older} = yield all(calls)
        let rows = []

        if (newer) {
            yield put(actions.setHasNewer(newer.count > perPage))
            rows = rows.concat(newer.rows)
        }

        if (older) {
            yield put(actions.setHasOlder(older.count > perPage))
            rows = rows.concat(older.rows)
        }

        yield updateRows(rows)
        yield put(actions.receive(toIds(rows)))
    } catch (error) {
        put(actions.receive(error))
    }
}

function* eventsBySerialPoll() {
    while (yield take(actions.startPoll)) {
        const pollTask = yield fork(pollEventsBySerial)
        yield take(actions.stopPoll)
        yield cancel(pollTask)
    }
}

function* pollEventsBySerial() {
    while (true) {
        yield race({
            delay: delay(POLL_EVENTS),
        })

        const {page} = yield select((state) => state.events.bySerial)

        if (page.length === 0) {
            continue
        }

        try {
            // invalidate data
            const {rows} = yield call(fetch, {
                start: 0,
                perPage: page.length,
                filters: {id: page},
            })
            yield updateRows(rows)
        } catch (error) {
            yield put(snackShow(error.message))
        }

        const {serial, hasNewer, newerCount} = yield select(
            (state) => state.events.bySerial
        )

        if (hasNewer) {
            continue
        }

        try {
            // check if there some new events for that panel
            const {count} = yield call(fetch, {
                perPage: 1,
                filters: {serial, id: `{>${max(page)}}`},
            })

            if (count !== newerCount) {
                yield put(actions.setNewerCount(count))
            }
        } catch (error) {
            yield put(snackShow(error.message))
        }
    }
}

export function* watchFetchNewer() {
    const {serial, page, perPage} = yield select((state) => state.events.bySerial)

    try {
        const {rows, count} = yield call(fetch, {
            start: 0,
            perPage,
            filters: {serial, id: `{>${max(page)}}`},
            sort: 'id',
        })
        yield put(update(rows))
        yield put(actions.receive(toIds(rows)))
        yield put(actions.setHasNewer(count > perPage))
    } catch (error) {
        yield put(actions.receive(error))
    }
}

export function* watchFetchOlder() {
    const {serial, page, perPage} = yield select((state) => state.events.bySerial)

    try {
        const {rows, count} = yield call(fetch, {
            start: 0,
            perPage,
            filters: {serial, id: `{<${min(page)}}`},
            sort: '-id',
        })
        yield put(update(rows))
        yield put(actions.receive(toIds(rows)))
        yield put(actions.setHasOlder(count > perPage))
    } catch (error) {
        yield put(actions.receive(error))
    }
}

export function* watchFetchRecent() {
    const {serial, page, newerCount} = yield select((state) => state.events.bySerial)

    try {
        const {rows, count} = yield call(fetch, {
            start: 0,
            perPage: newerCount,
            filters: {serial, id: `{>${max(page)}}`},
            sort: 'id',
        })
        yield put(update(rows))
        yield put(actions.receive(toIds(rows)))
        yield put(actions.setNewerCount(count - newerCount))
    } catch (error) {
        yield put(actions.receive(error))
    }
}
