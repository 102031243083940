import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Card, {CardHeader, CardContent} from 'ipmp-react-ui/Card'
import Button from 'ipmp-react-ui/Button'
import Buttons from 'ipmp-react-ui/Buttons'
import Definition from 'ipmp-react-ui/Definition'
import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'
import {ReactComponent as UserIcon} from 'ipmp-react-ui/icons/user.svg'
import {ReactComponent as EmailIcon} from 'ipmp-react-ui/icons/email.svg'
import DateTime from 'ipmp-react-ui/DateTime'
import BlindModalDefinition, {BlindModalDefinitionItem} from 'ui/BlindModalDefinition'

import __ from 'utils/i18n'
import InstallerPanelsTable from 'pages/Installers/BlindContent/InstallerPanelsTable'
import ResetPasswordButton from 'pages/Installers/BlindContent/InstallerInfoBar/ResetPasswordButton'
import getInstallerStatusTitle from 'constants/installerStatus'
import {compose} from 'redux'
import withLifeCycle from 'containers/withLifeCycle'
import withInstallerPanelsTable from 'containers/withInstallerPanelsTable'
import withInstallers from 'containers/withInstallers'

const InstallerBlindInfoDetails = ({name, email}) => (
    <section className="installerBlind-info">
        <div className="installerBlind-info-section">
            <UserIcon className="installerBlind-info-icon" />
            <strong className="installerBlind-email-title">{name}</strong>
        </div>
        <div className="installerBlind-info-section">
            <EmailIcon className="installerBlind-info-icon" />
            <strong className="installerBlind-info-emailTitle">{email}</strong>
        </div>
    </section>
)

class InstallerBlindInfo extends Component {
    static propTypes = {
        onAddPanel: PropTypes.func.isRequired,
        installer: PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
            status: PropTypes.string,
            createdAt: PropTypes.object,
        }),
    }
    onAddPanel = () => this.props.onAddPanel()

    render() {
        const {installer} = this.props
        const status = getInstallerStatusTitle(installer.status)

        return (
            <Card className="installerBlind">
                <CardHeader>
                    <Bar>
                        <InstallerBlindInfoDetails
                            email={installer.email}
                            name={installer.name}
                        />
                        <BarSpace />

                        <Buttons flat className={'reset-password-button'}>
                            <ResetPasswordButton installer={installer} />
                        </Buttons>
                    </Bar>
                </CardHeader>
                <CardContent>
                    <BlindModalDefinition>
                        <BlindModalDefinitionItem name={__('Registered')}>
                            <DateTime datetime={installer.createdAt} />
                        </BlindModalDefinitionItem>
                        <BlindModalDefinitionItem name={__('State')}>
                            <Definition title={status} />
                        </BlindModalDefinitionItem>
                    </BlindModalDefinition>
                    <InstallerPanelsTable installer={installer} />

                    <Button
                        className="installerBlind-addPanel"
                        Icon={IconPlus}
                        flat
                        label={__('Add Panel')}
                        onClick={this.onAddPanel}
                    />
                </CardContent>
            </Card>
        )
    }
}

export default compose(
    withInstallers(),
    withInstallerPanelsTable(),
    withLifeCycle({
        onUnmount({resetWithOutFetch}) {
            resetWithOutFetch()
        },
    })
)(InstallerBlindInfo)
