import {compose} from 'redux'
import {connect} from 'react-redux'

import withForm from 'containers/withForm'
import {editEventRule} from 'modules/forms/handlers'
import EventRuleForm from './EventRuleForm'
import {__} from 'utils/i18n'

export default compose(
    connect(({eventRules}, {eventRuleId}) => ({
        data: eventRules.store.byIds[eventRuleId],
        header: __('Edit Event Rules'),
    })),
    withForm(editEventRule),
    connect(null, (dispatcher, {eventRuleId, handle}) => ({
        handle: (data) => handle({id: eventRuleId, ...data}),
    }))
)(EventRuleForm)
