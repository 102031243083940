import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {changeValue, fetch, setExport, undo} from 'modules/basicConfiguration/one/actions'

export default function withBasicConfiguration() {
    return connect(
        ({basicConfiguration}, {match}) => {
            const state = basicConfiguration.one[match.params.id]

            return state || {isLoading: true}
        },
        (dispatch, {match}) =>
            bindActionCreators(
                {
                    fetch,
                    onChange: (...arg) => changeValue(match.params.id, ...arg),
                    onSetExport: (...arg) => setExport(match.params.id, ...arg),
                    onUndo: (...arg) => undo(match.params.id, ...arg),
                },
                dispatch
            )
    )
}
