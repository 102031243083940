import React from 'react'

const decorate = (string, ...args) =>
    string.split('%s').reduce((acc, item, index) => {
        return acc.concat([
            item,
            <b key={index} className="primary">
                {args.shift()}
            </b>,
        ])
    }, [])

export default decorate
