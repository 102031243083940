import React, {Component} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

import withProps from 'containers/withProps'
import {selectNotificationNameById} from 'modules/notificationTypes/store/selectors'

class NotificationTypeCell extends Component {
    static propTypes = {
        name: PropTypes.string,
    }

    render() {
        const {name} = this.props
        return (
            <OverflowTextTooltip className={'evenRules-elements-name'}>
                {name}
            </OverflowTextTooltip>
        )
    }
}

export default compose(
    withProps(({row: {notificationId}}) => {
        return {
            id: notificationId,
        }
    }),
    connect(selectNotificationNameById)
)(NotificationTypeCell)
