import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import List from 'ipmp-react-ui/List'

import {SettingsPageBlock} from '../SettingsPageBlock'
import EditBroadbandConnectedButton from './Buttons/EditBroadbandConnectedButton'

import {__} from 'utils/i18n'

class BroadbandConnected extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            broadbandConnected: PropTypes.shape({
                isAutoEnrollmentEnabled: PropTypes.bool,
            }),
        }),
    }

    render() {
        const {data} = this.props

        return (
            <div>
                <h1 className="card-header">
                    {__('Broadband Connected')}
                    <EditBroadbandConnectedButton />
                </h1>

                <List className="card-content" twoCols>
                    {this.renderListItem(
                        __('Enable Auto-Enroll'),
                        data.isAutoEnrollmentEnabled
                    )}
                </List>
            </div>
        )
    }
}

export default compose(
    connect(({system: {settings: {broadbandConnected}}}) => ({...broadbandConnected}))
)(BroadbandConnected)
