import CardBlindModal from 'ipmp-react-ui/CardBlindModal'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import remarks, {add, remove} from 'permissions/panel/info/remarks'

import * as actions from 'modules/panels/remarks/actions'

import withPoller from 'containers/withPoller'
import withLoader from 'containers/withLoader'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import {ReactComponent as IconSend} from 'ipmp-react-ui/icons/send.svg'
import Hotkey from 'ipmp-react-ui/Hotkey'

import Textarea from 'ipmp-react-ui/Textarea'
import Fab from 'ipmp-react-ui/Fab'
import {__} from 'utils/i18n'
import Comment from 'ipmp-react-ui/Comment'
import {MenuItem} from 'ipmp-react-ui/Menu'

import {humanDate} from 'ipmp-react-ui/humanTime'

const REMARKS_PER_PAGE = 25

export class PanelRemarks extends Component {
    state = {value: '', size: REMARKS_PER_PAGE}

    handleRemove = (key) => {
        const {remove} = this.props
        remove && remove(key)
    }

    createRemark = () => {
        const {create} = this.props
        const value = this.state.value.trim()

        value && create && create(value)
        this.setState({value: ''})
    }

    handleChange = (e) => {
        this.setState({value: e.target.value}, this.handleScroll)
    }

    componentDidUpdate(prevProps, prevState) {
        const {remarks} = prevProps

        if (remarks && this.props.remarks.length > remarks.length) {
            this.scrollToBottom()
        }
    }

    scrollToBottom() {
        if (this.scrollElement) {
            this.scrollElement.scrollTop = this.scrollElement.scrollHeight
        }
    }

    handleRef = (el) => {
        this.scrollElement = el
        this.scrollToBottom()
    }

    renderRemark = (remark) => (
        <Comment {...remark} isMine={remark.user.id === this.props.user.id}>
            {this.props.isRemoveAllowed && (
                <MenuItem onClick={() => this.handleRemove(remark.key)}>
                    {__('Remove')}
                </MenuItem>
            )}
        </Comment>
    )

    renderRemarks() {
        const {remarks} = this.props
        const {size} = this.state

        if (!remarks) {
            return null
        }

        const begin = Math.max(0, remarks.length - size)

        const days = remarks.slice(begin).reduce((acc, remark) => {
            const date = humanDate(remark.time)

            if (!acc[date]) {
                acc[date] = []
            }
            acc[date].push(remark)
            return acc
        }, {})

        const list = Object.keys(days).reduce((acc, day) => {
            return [
                ...acc,
                <div className="hint hint--gray panelInfo-remarks-title" key={day}>
                    {day}
                </div>,
                days[day].map(this.renderRemark),
            ]
        }, [])

        return (
            <div className="scroll" ref={this.handleRef}>
                {list}
            </div>
        )
    }

    render() {
        const {onClose, opened, isCreateAllowed} = this.props

        return (
            <CardBlindModal
                opened={opened}
                onClose={onClose}
                title={__('Panel Remarks')}
                className="panelRemarks"
            >
                {this.renderRemarks()}

                {isCreateAllowed && (
                    <div className="panelInfo-addRemark">
                        <Textarea
                            autoFocus
                            onChange={this.handleChange}
                            value={this.state.value}
                            placeholder={__('Add note')}
                            maxLength={1000}
                        />
                        <Hotkey
                            shortcut="⌘+enter"
                            action={this.createRemark}
                            scope="input"
                        />
                        <Hotkey
                            shortcut="ctrl+enter"
                            action={this.createRemark}
                            scope="input"
                        />
                        <Fab mini primary Icon={IconSend} onClick={this.createRemark} />
                    </div>
                )}
            </CardBlindModal>
        )
    }
}

export default compose(
    withPermission({
        isVisible: remarks,
        isCreateAllowed: add,
        isRemoveAllowed: remove,
    }),
    withVisibility(),
    connect(
        (
            {
                panels,
                auth: {
                    sign: {user},
                },
            },
            {panelId}
        ) => {
            const remarks = panels.remarks[panelId]
            return {
                user: user,
                isLoading: !remarks,
                remarks: remarks && remarks.page.map((id) => remarks.rows[id]),
            }
        },
        (dispatch, {panelId}) => {
            return bindActionCreators(
                {
                    fetch: () => actions.fetch(panelId),
                    create: (text) => actions.create(text, panelId),
                    remove: (key) => actions.remove(key, panelId),
                },
                dispatch
            )
        }
    ),
    withLoader(({fetch}) => fetch()),
    withPoller(5000, ({fetch}) => fetch())
)(PanelRemarks)
