import {bindActionCreators, compose} from 'redux'

import {showAddStateTypeModal} from 'modules/modals/actions'

import withProps from 'containers/withProps'

import {ReactComponent as AddIcon} from 'ipmp-react-ui/icons/plus.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import {connect} from 'react-redux'

export default compose(
    withProps(() => ({
        Icon: AddIcon,
        label: __('Add State Type'),
    })),
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                onClick: showAddStateTypeModal,
            },
            dispatch
        )
    )
)(Button)
