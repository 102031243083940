// libraries
import React from 'react'
import {compose} from 'redux'

import {withPermission, withRejection} from 'containers/withPermission'

import withSelection from 'containers/withSelection'
import withUpgradablePanels from 'containers/withUpgradablePanels'
import withProps from 'containers/withProps'
import withLifeCycle from 'containers/withLifeCycle'

// application
import Table from 'ipmp-react-ui/Table'
import PanelCell from 'components/TableCells/PanelCell'
import GroupCell from 'components/TableCells/GroupCell'

// utils
import {__} from 'utils/i18n'

import list from 'permissions/firmware/list'
import selection from 'permissions/firmware/selection'
import withLoader from 'containers/withLoader'

const columns = [
    {
        name: () => __('Panel'),
        render: ({id, serial, account}) => <PanelCell {...{id, serial, account}} />,
    },
    {
        name: () => __('Group'),
        render: ({groupId: id, group: name}) => <GroupCell {...{row: {id, name}}} />,
    },
    {
        name: () => __('Model'),
        render: ({model}) => model,
    },
]

export default compose(
    withPermission({
        isAllowed: list,
        hasSelection: selection,
    }),
    withRejection(),
    withSelection(),
    withUpgradablePanels(),
    withLifeCycle({
        onMount({deselectAll, init}) {
            init()
            deselectAll()
        },
    }),
    withLoader(({fetch}) => fetch()),
    withProps({
        fullHeight: true,
        columns,
        emptyMessage: () => __('No panels found'),
    })
)(Table)
