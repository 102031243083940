import React from 'react'
import {createSelector} from 'reselect'
import has from 'lodash-es/has'

import filterConfiguration, {
    createSearchFilter,
} from 'utils/configuration/filterConfiguration'

const selector = createSelector(
    (data) => data.configuration,
    (data) => data.values,
    (data) => (data.isShowChanged ? data.changes : null),
    (data) => data.backup,
    (data) => data.isEditable,
    (data) => data.isShowChanged,
    (data) => data.isShowExportable,
    (data) => data.isCompareWithBackup,
    (data) => data.query,
    (
        configuration,
        values,
        changes,
        backup,
        isEditable,
        isShowChanged,
        isShowExportable,
        isCompareWithBackup,
        query
    ) => {
        if (!configuration || !configuration.val) {
            return []
        }

        let config = configuration

        if (isShowChanged) {
            config = filterConfiguration(config, ({key}) => changes && has(changes, key))
        }

        if (isShowExportable) {
            config = filterConfiguration(config, (item) => !!item.export)
        }

        if (isCompareWithBackup) {
            config = filterConfiguration(config, ({key, val}) => val !== backup[key])
        }

        if (query && query.trim().length) {
            const searchFilter = createSearchFilter(query, !isEditable)
            config = filterConfiguration(config, searchFilter, true)
        }

        const head = config.val.filter(({type}) => type !== 'menu')
        const sections = config.val.filter(({type}) => type === 'menu')

        if (head.length > 0) {
            sections.unshift({val: head, key: ''})
        }

        return sections
    }
)

export default function withConfigurationFilter() {
    return (WrappedComponent) =>
        function WithConfigurationFilter(props) {
            const sections = selector(props)
            return <WrappedComponent {...props} sections={sections} />
        }
}
