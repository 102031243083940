import {all, put, call, takeEvery} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/about'

import {snackShow} from 'modules/snacks'
export default function* () {
    yield all([yield takeEvery(actions.fetch, watchFetch)])
}

function* watchFetch() {
    try {
        const data = yield call(api.fetchServerTime)
        yield put(actions.receive(data))
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.receive(error))
    }
}
