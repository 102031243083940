import {selectPerPage} from 'modules/settings/selectors'
import {all, call, put, select, takeEvery} from 'redux-saga/effects'

import {POLL_UPGRADE_STATUS} from 'configs/pollers'

import createListSaga from 'modules/higherOrder/createListSaga'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'

import toIds from 'utils/toIds'
import {
    update,
    purge,
    setUpagradeStatusPackagesLoading,
} from 'modules/upgradeStatus/store/actions'
import {update as runnersUpdate} from 'modules/runners/store/actions'
import {snackShow} from 'modules/snacks'

import * as actions from './actions'
import * as api from 'api/upgradeStatus'

const selector = (state) => ({
    ...state.upgradeStatus.list,
    perPage: selectPerPage(state, 'upgrades'),
    listRoute: 'upgradeStatus',
})

export default function* () {
    yield all([
        createListSaga(api, actions, update, selector, purge),
        createListPollerSaga(actions, POLL_UPGRADE_STATUS, invalidateUpgradeStatus),
        takeEvery(actions.fetchUpgradeStatusRunnerByBatch, watchFetchRunnerByBatch),
    ])
}

function* invalidateUpgradeStatus() {
    const state = yield select(selector)

    try {
        const {rows, count} = yield call(api.fetch, state)
        yield put(update(rows))
        yield put(actions.receive(toIds(rows), count))
    } catch (error) {
        yield put(actions.receive(error))
    }
}

function* watchFetchRunnerByBatch({payload: batchId}) {
    yield put(setUpagradeStatusPackagesLoading())

    try {
        const {rows} = yield call(api.fetchRunners, {batchId, perPage: 1})
        yield put(runnersUpdate(rows))
    } catch (error) {
        yield put(snackShow(error.message))
    } finally {
        yield put(setUpagradeStatusPackagesLoading(false))
    }
}
