import {combineReducers} from 'redux'

import one from './one/reducer'
import list from './list/reducer'
import select from './select/reducer'
import store from './store/reducer'
import push from './push/reducer'

export default combineReducers({
    list,
    one,
    select,
    store,
    push,
})
