import TranslateSuggestFilter from 'components/Search/Filters/FullSuggestsFilter'
import deviceType, {
    DEVICE_TYPE_AUDIO_VERIFICATION,
    DEVICE_TYPE_COMMUNICATOR,
    DEVICE_TYPE_CONTROL_PANEL,
    DEVICE_TYPE_OUTPUT_EXPANDER,
    DEVICE_TYPE_PGH,
    DEVICE_TYPE_POWER_LINK,
    DEVICE_TYPE_POWER_SUPPLY,
    DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS,
    DEVICE_TYPE_WIRED_KEYPAD,
    DEVICE_TYPE_ZONE_EXPANDER,
} from 'constants/deviceType'
import {troubleTitle} from 'constants/troubleType'
import withProps from 'containers/withProps'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import list from 'permissions/panels/list'

import {
    fetchSuggests,
    fetch,
    addFilters,
    removeFilters,
    clearFilters,
    setQuery,
} from 'modules/panels/list/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Search from 'components/Search/Search'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'

import __ from 'utils/i18n'
import {showSaveSearchModal} from 'modules/modals/actions'
import {
    MODULE_TYPE_BBA,
    MODULE_TYPE_GPRS,
    MODULE_STATES_HASH,
} from 'constants/moduleTypes'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProps(() => ({
        filters: [
            new SuggestFilter('serial', __('Panel Id')),
            new SuggestFilter('utz_enroll_id', __('Device Id')),
            new ValuesFilter('faults', __('Faulty'), {
                active: __('Active'),
                suspended: __('Suspended'),
                any: __('Any'),
            }),
            new SuggestFilter('account', __('Account')),
            new SuggestFilter('group', __('Group')),
            new SuggestFilter('model', __('Model')),
            new SuggestFilter('user', __('Operator')),
            new SuggestFilter(
                DEVICE_TYPE_CONTROL_PANEL,
                deviceType(DEVICE_TYPE_CONTROL_PANEL)
            ),
            new SuggestFilter(DEVICE_TYPE_POWER_LINK, deviceType(DEVICE_TYPE_POWER_LINK)),
            new SuggestFilter(
                DEVICE_TYPE_WIRED_KEYPAD,
                deviceType(DEVICE_TYPE_WIRED_KEYPAD)
            ),
            new SuggestFilter(
                DEVICE_TYPE_ZONE_EXPANDER,
                deviceType(DEVICE_TYPE_ZONE_EXPANDER)
            ),
            new SuggestFilter(
                DEVICE_TYPE_OUTPUT_EXPANDER,
                deviceType(DEVICE_TYPE_OUTPUT_EXPANDER)
            ),
            new SuggestFilter(
                DEVICE_TYPE_POWER_SUPPLY,
                deviceType(DEVICE_TYPE_POWER_SUPPLY)
            ),
            new SuggestFilter(
                DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS,
                deviceType(DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS)
            ),
            new SuggestFilter(DEVICE_TYPE_PGH, deviceType(DEVICE_TYPE_PGH)),
            new SuggestFilter(
                DEVICE_TYPE_COMMUNICATOR,
                deviceType(DEVICE_TYPE_COMMUNICATOR)
            ),
            new SuggestFilter(
                DEVICE_TYPE_AUDIO_VERIFICATION,
                deviceType(DEVICE_TYPE_AUDIO_VERIFICATION)
            ),
            new TranslateSuggestFilter('fault', __('Fault'), troubleTitle),
            new ValuesFilter('isActivated', __('Activated'), {
                '1': __('Yes'),
                '{!=1}': __('No'),
            }),
            new ValuesFilter('userApp', __('User App'), {
                allow: __('Allow'),
                disallow: __('Disallow'),
            }),
            new ValuesFilter('configuratorApp', __('Configurator App'), {
                allow: __('Allow'),
                disallow: __('Disallow'),
            }),
            new ValuesFilter(MODULE_TYPE_GPRS, __('GPRS'), MODULE_STATES_HASH),
            new ValuesFilter(MODULE_TYPE_BBA, __('BBA'), MODULE_STATES_HASH),
            new SuggestFilter('configurationName', __('Configuration')),
        ].sort((item, next) => item.title.localeCompare(next.title)),
    })),
    connect(
        ({panels: {list}}, {match}) => ({
            key: match.params.scope,
            selected: list.filters,
            query: list.query,
            suggests: list.suggests,
        }),

        (dispatch) =>
            bindActionCreators(
                {
                    apply: fetch,
                    onSuggest: fetchSuggests,
                    onQuery: setQuery,
                    onSelect: addFilters,
                    onClear: clearFilters,
                    onDeselect: removeFilters,
                    onSaveSearch: (filters) => showSaveSearchModal('panels', filters),
                },
                dispatch
            )
    )
)(Search)
