import {handleActions, combineActions} from 'redux-actions'

import {update, fetch, reset} from './actions'
import moment from 'moment'

const defaultState = {}
const defaultStateByPanelId = {
    page: [],
    isLoading: false,
    date: moment(),
    error: null,
}

export default handleActions(
    {
        [combineActions(fetch, reset)](state, {meta: {panelId}, payload: {date}}) {
            return {
                ...state,
                [panelId]: {
                    page: [],
                    isLoading: true,
                    date,
                },
            }
        },

        [update](state, {payload, meta: {panelId, count}, error}) {
            if (error) {
                return {
                    ...state,
                    [panelId]: {
                        ...state[panelId],
                        isLoading: false,
                        error: payload,
                    },
                }
            }

            const old = state[panelId] || {...defaultStateByPanelId}

            const page = payload
                .map(Number)
                .filter((id) => old.page.indexOf(id) === -1)
                .concat(old.page)
                .sort((a, b) => b - a)

            return {
                ...state,
                [panelId]: {
                    ...old,
                    isLoading: false,
                    error: null,
                    page,
                },
            }
        },
    },
    defaultState
)
