import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {
    init,
    reset,
    setStart,
    remove,
    startPoll,
    stopPoll,
} from 'modules/users/list/actions'
import {setEnabled} from 'modules/users/store/actions'
import {
    showAddUserModal,
    showEditUserModal,
    showRemoveUserModal,
} from 'modules/modals/actions'
import {selectUsersListRows} from 'modules/users/list/selectors'

const mapDispatchToProps = () => (dispatch) =>
    bindActionCreators(
        {
            init,
            startPoll,
            stopPoll,
            reset,
            onPageChange: setStart,
            showAddUser: showAddUserModal,
            showEditUser: showEditUserModal,
            showRemoveUser: showRemoveUserModal,
            remove,
            enable: (ids) => setEnabled(ids, true),
            suspend: (ids) => setEnabled(ids, false),
        },
        dispatch
    )

export default function withUsers() {
    return compose(withPerPage('users'), connect(selectUsersListRows, mapDispatchToProps))
}
