import {sortFaults} from 'api/panels.map'

export default function updatePanelFaults(panels, faultIds, patch) {
    const updater =
        typeof patch === 'function'
            ? patch
            : (faults) =>
                  faults.map((fault) => {
                      if (!faultIds.includes(fault.id)) {
                          return fault
                      }

                      return {
                          ...fault,
                          ...patch,
                      }
                  })

    return Object.values(panels).reduce((acc, panel) => {
        if (
            !panel.faults ||
            panel.faults.every((fault) => !faultIds.includes(fault.id))
        ) {
            return acc
        }

        acc.push({
            id: panel.id,
            faults: sortFaults(updater(panel.faults)),
        })

        return acc
    }, [])
}
