import withForm from 'containers/withForm'
import {editZoneType} from 'modules/forms/handlers'
import ZoneTypeForm from './ZoneTypeForm'
import {validation} from './ZoneTypeFieldset'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {__} from 'utils/i18n'

export default compose(
    connect(({zoneTypes}, {zoneTypeId}) => ({
        data: zoneTypes.store.byIds[zoneTypeId],
        header: __('Edit Zone Type'),
        rules: validation,
    })),
    withForm(editZoneType),
    connect(null, (dispatcher, {zoneTypeId, handle}) => ({
        handle: (data) => handle({id: zoneTypeId, ...data}),
    }))
)(ZoneTypeForm)
