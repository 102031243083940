import React from 'react'
import map from 'lodash/map'

import Communication from 'components/Panel/Communication'

const CommunicationCell = ({modules, serial}) => {
    return (
        <span>
            {map(modules, (module, type) => {
                return (
                    <Communication
                        key={`module-${type}-${serial}`}
                        moduleType={type}
                        module={module}
                    />
                )
            })}
        </span>
    )
}

export default CommunicationCell
