import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {withRouter} from 'react-router'
import {Route, Switch} from 'react-router-dom'

import {general, stations} from 'permissions/group/navigation'

import {withPermission} from 'containers/withPermission'

import path from 'utils/path'

import General from './General/General'
import Stations from './Stations/Stations'

class GroupContent extends Component {
    static propTypes = {
        isGeneral: PropTypes.bool,
        isStations: PropTypes.bool,
        group: PropTypes.object,
    }

    renderPage() {
        const {isGeneral, isStations, group} = this.props

        switch (true) {
            case isGeneral:
                return <General {...{group}} />
            case isStations:
                return <Stations {...{group}} />
            default:
                return null
        }
    }

    renderRouteSwitch() {
        const {group} = this.props

        return (
            <Switch>
                <Route
                    path={path('system.group.general')}
                    exact
                    component={() => <General {...{group}} />}
                />
                <Route
                    path={path('system.group.stations')}
                    exact
                    component={() => <Stations {...{group}} />}
                />
            </Switch>
        )
    }

    render() {
        const {isGeneral, isStations} = this.props

        if (isGeneral && isStations) {
            return this.renderRouteSwitch()
        }

        return this.renderPage()
    }
}

export default compose(
    withRouter,
    withPermission({
        isGeneral: general,
        isStations: stations,
    })
)(GroupContent)
