import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import has from 'lodash-es/has'

import {__} from 'utils/i18n'
import Panel from 'components/Devices/Panel'
import DevicesGroup from 'components/Devices/DevicesGroup'

export default class DevicesList extends Component {
    static propTypes = {
        devices: PropTypes.object.isRequired,
        groups: PropTypes.objectOf(PropTypes.string).isRequired,
        panel: PropTypes.object,
        isChanged: PropTypes.func,
        selectedDevice: PropTypes.number,
        status: PropTypes.objectOf(PropTypes.string),
        onSelectPanel: PropTypes.func,
        isPanelSelected: PropTypes.bool,
    }

    state = {}

    renderDeviceGroup = (category) => {
        const {isChanged, groups, status, devices, selectedDevice: selected} = this.props

        if (!has(devices, category)) {
            return null
        }

        return (
            <DevicesGroup
                {...{
                    key: category,
                    devices: devices[category],
                    groupTitle: groups[category],
                    status,
                    selected,
                    isChanged,
                }}
            />
        )
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize, {passive: true})
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, {passive: true})
    }

    componentDidUpdate(prevProps, prevState) {
        const {isPanelSelected, selectedDevice} = prevProps
        const resizeAfterUpdate =
            this.props.isPanelSelected !== isPanelSelected ||
            this.props.selectedDevice !== selectedDevice

        if (resizeAfterUpdate) {
            this.handleResize()
        }
    }

    handleResize = () => {
        this.setState(() => ({width: this.element && this.element.offsetWidth}))
    }

    handleRef = (element) => {
        this.element = element
        this.handleResize()
    }

    render() {
        const {devices, groups, panel, onSelectPanel, isPanelSelected} = this.props

        if (!devices) {
            return null
        }

        return (
            <div
                className={classnames('devicesList', {
                    'devicesList--twoCols': this.state.width > 700,
                })}
                ref={this.handleRef}
            >
                {panel && (
                    <div className="devicesList-section">
                        <h3 className="devicesList-section-title">
                            {__('Control Panel')}
                        </h3>
                        <Panel
                            panel={panel}
                            active={isPanelSelected}
                            onSelect={onSelectPanel}
                        />
                    </div>
                )}

                {Object.keys(groups).map(this.renderDeviceGroup)}
            </div>
        )
    }
}
