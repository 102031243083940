import * as actions from './actions'
import createListReducer from 'modules/higherOrder/createListReducer'

export default createListReducer(
    actions,
    {
        [actions.fetch]: (state, {payload: {applianceId, upgradePackageId}}) => ({
            ...state,
            applianceId: applianceId || state.applianceId,
            upgradePackageId: upgradePackageId || state.upgradePackageId,
        }),
    },
    {
        applianceId: null,
        upgradePackageId: null,
    }
)
