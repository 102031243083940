import {selectPerPage} from 'modules/settings/selectors'
import {all, call, put, select} from 'redux-saga/effects'

import {POLL_UPGRADE_RUNNERS_STATUS} from 'configs/pollers'

import createListSaga from 'modules/higherOrder/createListSaga'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'

import toIds from 'utils/toIds'
import {update} from 'modules/runners/store/actions'

import * as actions from './actions'
import {fetchRunners, runnersSuggest} from 'api/upgradeStatus'
const api = {fetch: fetchRunners, suggest: runnersSuggest}

const selector = (state) => {
    const location = state.router.location
    const batchId = /.+\/(.+)\/(.+)$/.exec(location.pathname)[2]

    return {
        ...state.upgradeStatus.upgradeStatusRunnersList,
        perPage: selectPerPage(state, 'upgradeStatusRunners'),
        batchId,
    }
}

export default function* () {
    yield all([
        createListSaga(api, actions, update, selector),
        createListPollerSaga(
            actions,
            POLL_UPGRADE_RUNNERS_STATUS,
            invalidateUpgradeStatusRunners
        ),
    ])
}

function* invalidateUpgradeStatusRunners() {
    const state = yield select(selector)
    const batchId = state.batchId

    try {
        const {rows, count} = yield call(api.fetch, {batchId, ...state})
        yield put(update(rows))
        yield put(actions.receive(toIds(rows), count))
    } catch (error) {
        yield put(actions.receive(error))
    }
}
