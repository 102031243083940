import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {Option} from 'ipmp-react-ui/Select'
import {ReactComponent as RunIcon} from 'ipmp-react-ui/icons/run.svg'

// this value must be sync with `transition` time see soundPlaySelect.sass
const TIMEOUT = 2500

class SoundPlayOption extends Component {
    static propTypes = {
        label: PropTypes.string,
        value: PropTypes.string,
        onMouseEnter: PropTypes.func,
        playSound: PropTypes.func,
        isPlaySound: PropTypes.bool.isRequired,
    }

    handleOnMouseEnter = (e) => {
        this.props.onMouseEnter(e)

        if (!this.props.isPlaySound) {
            return
        }
        this.timeoutID = setTimeout(() => {
            this.props.playSound(e)
        }, TIMEOUT)
    }

    handleOnMouseLeave = (e) => {
        this.props.onMouseLeave && this.props.onMouseLeave(e)
        if (!this.props.isPlaySound) {
            return
        }
        clearTimeout(this.timeoutID)
    }

    handleOnIconClick = (e) => {
        e.stopPropagation()
        this.props.playSound(e)
    }

    get className() {
        return classnames('soundPlaySelect-option', {
            'soundPlaySelect-option__noSoundPlay': !this.props.isPlaySound,
        })
    }

    render() {
        const {label, value, isPlaySound, ...props} = this.props

        return (
            <Option
                {...props}
                label={label}
                className={this.className}
                onMouseEnter={this.handleOnMouseEnter}
                onMouseLeave={this.handleOnMouseLeave}
                value={value}
            >
                {isPlaySound && (
                    <RunIcon
                        className="menu-item-icon"
                        onClick={this.handleOnIconClick}
                    />
                )}
                {label}
            </Option>
        )
    }
}

export default SoundPlayOption
