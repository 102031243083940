import has from 'lodash-es/has'
import {createSelector} from 'reselect'

export const selectApplianceTypeAndPackageName = createSelector(
    (
        state,
        {
            match: {
                params: {applianceId},
            },
        }
    ) => state.firmware.store.appliances.byIds[applianceId],
    (
        state,
        {
            match: {
                params: {upgradePackageId},
            },
        }
    ) => state.firmware.store.upgradePackages.byIds[upgradePackageId],
    (appliance, upgradePackage) => {
        const isAvailableApplianceType = appliance && has(appliance, 'type')
        const applianceType = isAvailableApplianceType ? appliance['type'] : null
        const isAvailablePackageName = upgradePackage && has(upgradePackage, 'name')
        const packageName = isAvailablePackageName ? upgradePackage.name : null

        return {
            applianceType,
            packageName,
        }
    }
)
