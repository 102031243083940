import {all, call, put, takeEvery} from 'redux-saga/effects'

import * as api from 'api/zoneTypes'
import toIds from 'utils/toIds'
import * as actions from './actions'

import {POLL_ZONE_TYPES} from 'configs/pollers'

import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import {update, purge} from 'modules/zoneTypes/store/actions'
import {snackShow} from 'modules/snacks'

import {__} from 'utils/i18n'
import createListWithoutPaginationSaga from 'modules/higherOrder/createListWithoutPaginationSaga'
import {addZoneType} from 'modules/forms/handlers'

const selector = (state) => ({
    ...state.zoneTypes.list,
    listRoute: 'zoneTypes',
})

export default function* () {
    yield all([
        createListWithoutPaginationSaga(api, actions, update, selector, purge),
        createListPollerSaga(actions, POLL_ZONE_TYPES, watchPoll),
        takeEvery(addZoneType.SUCCESS, watchAddZoneType),
    ])
}

function* watchAddZoneType() {
    yield put(actions.fetch())
    yield put(snackShow(__('Zone type was created successfully')))
}

function* watchPoll() {
    try {
        const {rows, count} = yield call(api.fetch)

        yield put(update(rows))
        yield put(actions.receive(toIds(rows), count))
    } catch (err) {
        yield put(actions.receive(err))
        yield put(snackShow(__(err.message)))
    }
}
