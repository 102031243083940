function recursionConfiguration(val, lists, charsets) {
    val.forEach((item) => {
        if (item.type === 'menu') {
            return recursionConfiguration(item.val, lists, charsets)
        }

        if (charsets && (item.type === 'string' || item.type === 'phone')) {
            item.charset = charsets[item.charset]
            return item
        }

        if (item.type === 'code' || item.type === 'code_hex') {
            item.val = '*'.repeat(item.val.length)
            return item
        }

        if (lists && item.type === 'list') {
            item.items = lists[item.items]
            return item
        }
    })

    return val
}

// change stars to non-printable dots and ... don't trust anyone, even your backend!
export default function configurationMutate(item) {
    recursionConfiguration(item.val, item.lists, item.charsets)
    return item
}
