import React from 'react'
import {compose} from 'redux'

import Layout from 'ipmp-react-ui/Layout'
import Button from 'ipmp-react-ui/Button'

import withLoader from 'containers/withLoader'
import withProcessLoader from 'containers/withProcessLoader'
import withPanelLogs from 'containers/withPanelLogs'

import LogsTable from './LogsTable'
import Spinner from 'ipmp-react-ui/Spinner'
import {__} from 'utils/i18n'

import LogBar from './LogBar'

export const Log = ({panel, mode, rows, hasMore, more}) => (
    <Layout vertical>
        <LogBar panel={panel} mode={mode} />

        <div className="configuration-content">
            <LogsTable rows={rows} />

            {hasMore && (
                <div className="btnHolder">
                    {hasMore.isLoading ? (
                        <Spinner />
                    ) : (
                        <Button onClick={more}>{__('More')}</Button>
                    )}
                </div>
            )}
        </div>
    </Layout>
)

export default compose(
    withPanelLogs(),
    withLoader(({fetch}) => fetch()),
    withProcessLoader(() => __('Downloading log'))
)(Log)
