import has from 'lodash-es/has'

import {__} from 'utils/i18n'
import {warn} from 'utils/log'

import * as ZoneTypes from './zoneType'

export const ALARM_PANIC = 'PANIC'
export const ALARM_EMERGENCY = 'EMERGENCY'
export const ALARM_FIRE = 'FIRE'
export const ALARM_SMOKE_MEMORY = 'SMOKE_MEMORY'
export const ALARM_HEAT_MEMORY = 'HEAT_MEMORY'
export const ALARM_TAMPER_MEMORY = 'TAMPER_MEMORY'
export const ALARM_ALARM_IN_MEMORY = 'ALARM_IN_MEMORY'
export const ALARM_DURESS = 'DURESS'
export const ALARM_ALARM_DURESS = 'ALARM_DURESS'
export const ALARM_BURGLARY = 'BURGLARY'
export const ALARM_HR24_SUPERVISORY = 'HR24_SUPERVISORY'
export const ALARM_CO_ALARM = 'CO_ALARM'
export const ALARM_GAS_ALARM = 'GAS_ALARM'
export const ALARM_HIGH_TEMPERATURE = 'HIGH_TEMPERATURE'
export const ALARM_LOW_TEMPERATURE = 'LOW_TEMPERATURE'
export const ALARM_WATERFLOW = 'WATERFLOW'
export const ALARM_WATER_LEAKAGE = 'WATER_LEAKAGE'
export const ALARM_HOLD_UP = 'HOLD_UP'
export const ALARM_SPRINKLER = 'SPRINKLER'
export const ALARM_BURGLARY_NO_VERIFIED = 'BURGLARY_NO_VERIFIED'
export const ALARM_HOLD_UP_NO_VERIFIED = 'HOLD_UP_NO_VERIFIED'
export const ALARM_HIGH_TEMPERATURE_WARNING = 'HIGH_TEMPERATURE_WARNING'
export const ALARM_LOW_TEMPERATURE_WARNING = 'LOW_TEMPERATURE_WARNING'
export const ALARM_RF_JAM_ALARM = 'RF_JAM_ALARM'
export const ALARM_HARDWARE_FAULT = 'HARDWARE_FAULT'
export const ALARM_QUICK_BYPASS = 'QUICK_BYPASS'
export const ALARM_UNKNOWN = 'UNKNOWN'
export const ALARM_GENERAL = 'GENERAL'
export const ALARM_GAS = 'GAS'
export const ALARM_CO = 'CO'
export const ALARM_PENDANT = 'PENDANT'
export const ALARM_MEDICAL = 'MEDICAL'
export const ALARM_FIRE_SUPERVISORY = 'FIRE_SUPERVISORY'

import {ReactComponent as IconPanic} from 'ipmp-react-ui/icons/worries/panic.svg'
import {ReactComponent as IconEmergency} from 'ipmp-react-ui/icons/worries/emergency.svg'
import {ReactComponent as IconFire} from 'ipmp-react-ui/icons/worries/fire.svg'
import {ReactComponent as IconFlood} from 'ipmp-react-ui/icons/worries/flood.svg'
import {ReactComponent as IconHeat} from 'ipmp-react-ui/icons/worries/heat.svg'
import {ReactComponent as IconTamper} from 'ipmp-react-ui/icons/worries/tamper.svg'
import {ReactComponent as IconCo} from 'ipmp-react-ui/icons/worries/co.svg'
import {ReactComponent as IconBurglary} from 'ipmp-react-ui/icons/worries/burglary.svg'
import {ReactComponent as IconH24} from 'ipmp-react-ui/icons/worries/h24.svg'
import {ReactComponent as IconGas} from 'ipmp-react-ui/icons/worries/gas.svg'
import {ReactComponent as IconCold} from 'ipmp-react-ui/icons/worries/cold.svg'
import {ReactComponent as IconJammed} from 'ipmp-react-ui/icons/worries/jammed.svg'
import {ReactComponent as IconBypass} from 'ipmp-react-ui/icons/worries/bypass.svg'
import {ReactComponent as IconGuard} from 'ipmp-react-ui/icons/worries/guard.svg'
import {ReactComponent as IconIterior} from 'ipmp-react-ui/icons/worries/interior.svg'
import {ReactComponent as IconPerimeter} from 'ipmp-react-ui/icons/worries/perimeter.svg'
import {ReactComponent as unknown} from 'ipmp-react-ui/icons/worries/trouble.svg'

const icons = {
    [ALARM_PANIC]: IconPanic,
    [ALARM_EMERGENCY]: IconEmergency,
    [ALARM_FIRE]: IconFire,
    [ALARM_SMOKE_MEMORY]: IconFlood,
    [ALARM_HEAT_MEMORY]: IconHeat,
    [ALARM_TAMPER_MEMORY]: IconTamper,
    [ALARM_CO_ALARM]: IconCo,
    [ALARM_BURGLARY]: IconBurglary,
    [ALARM_HR24_SUPERVISORY]: IconH24,
    [ALARM_CO_ALARM]: IconCo,
    [ALARM_GAS_ALARM]: IconGas,
    [ALARM_HIGH_TEMPERATURE]: IconHeat,
    [ALARM_LOW_TEMPERATURE]: IconCold,
    [ALARM_WATERFLOW]: IconFlood,
    [ALARM_WATER_LEAKAGE]: IconFlood,
    [ALARM_SPRINKLER]: IconFlood,
    [ALARM_BURGLARY_NO_VERIFIED]: IconBurglary,
    [ALARM_HIGH_TEMPERATURE_WARNING]: IconHeat,
    [ALARM_LOW_TEMPERATURE_WARNING]: IconCold,
    [ALARM_RF_JAM_ALARM]: IconJammed,
    [ALARM_HARDWARE_FAULT]: IconTamper,
    [ALARM_QUICK_BYPASS]: IconBypass,
    [ALARM_GAS]: IconGas,
    [ALARM_CO]: IconCo,
    [ALARM_MEDICAL]: IconEmergency,
    [ALARM_FIRE_SUPERVISORY]: IconFire,
}

const iconsByZone = {
    [ZoneTypes.ZONE_TYPE_PUSH_BUTTON]: IconPanic,
    [ZoneTypes.ZONE_TYPE_KEYFOB]: IconPanic,
    [ZoneTypes.ZONE_TYPE_SMOKE_OR_HEAT]: IconFire,
    [ZoneTypes.ZONE_TYPE_FIRE]: IconFire,
    [ZoneTypes.ZONE_TYPE_SMOKE]: IconFire,
    [ZoneTypes.ZONE_TYPE_GAS]: IconGas,
    [ZoneTypes.ZONE_TYPE_FLOOD]: IconFlood,
    [ZoneTypes.ZONE_TYPE_EMERGENCY]: IconEmergency,
    [ZoneTypes.ZONE_TYPE_DELAY_1]: IconBurglary,
    [ZoneTypes.ZONE_TYPE_DELAY_2]: IconBurglary,
    [ZoneTypes.ZONE_TYPE_HOME_DELAY]: IconBurglary,
    [ZoneTypes.ZONE_TYPE_GUARD_KEYBOX]: IconGuard,
    [ZoneTypes.ZONE_TYPE_INTERIOR]: IconIterior,
    [ZoneTypes.ZONE_TYPE_INTERIOR_FOLLOW]: IconIterior,
    [ZoneTypes.ZONE_TYPE_PERIMETER]: IconPerimeter,
    [ZoneTypes.ZONE_TYPE_PERIMETER_FOLLOW]: IconPerimeter,
    [ZoneTypes.ZONE_TYPE_H24_SILENT]: IconH24,
    [ZoneTypes.ZONE_TYPE_H24_AUDIBLE]: IconH24,
    [ZoneTypes.ZONE_TYPE_CO]: IconCo,
}

function alarmTitleByZone(zoneType) {
    switch (zoneType) {
        case ZoneTypes.ZONE_TYPE_PUSH_BUTTON:
            return __('Push_button')
        case ZoneTypes.ZONE_TYPE_KEYFOB:
            return __('Keyfob')
        case ZoneTypes.ZONE_TYPE_SMOKE:
            return __('Smoke')
        case ZoneTypes.ZONE_TYPE_GAS:
            return __('Gas')
        case ZoneTypes.ZONE_TYPE_FLOOD:
            return __('Flood')
        case ZoneTypes.ZONE_TYPE_SMOKE_OR_HEAT:
            return __('Smoke or heat')
        case ZoneTypes.ZONE_TYPE_FIRE:
            return __('Fire')
        case ZoneTypes.ZONE_TYPE_EMERGENCY:
            return __('Emergency')
        case ZoneTypes.ZONE_TYPE_DELAY_1:
            return __('Delay 1')
        case ZoneTypes.ZONE_TYPE_DELAY_2:
            return __('Delay 2')
        case ZoneTypes.ZONE_TYPE_HOME_DELAY:
            return __('Home_delay')
        case ZoneTypes.ZONE_TYPE_GUARD_KEYBOX:
            return __('Guard keybox')
        case ZoneTypes.ZONE_TYPE_INTERIOR:
            return __('Interior')
        case ZoneTypes.ZONE_TYPE_INTERIOR_FOLLOW:
            return __('Interior follow')
        case ZoneTypes.ZONE_TYPE_PERIMETER:
            return __('Perimeter')
        case ZoneTypes.ZONE_TYPE_PERIMETER_FOLLOW:
            return __('Perimeter follow')
        case ZoneTypes.ZONE_TYPE_H24_SILENT:
            return __('24H silent')
        case ZoneTypes.ZONE_TYPE_H24_AUDIBLE:
            return __('24H audible')
        case ZoneTypes.ZONE_TYPE_OUTDOOR:
            return __('Outdoor')
        case ZoneTypes.ZONE_TYPE_CO:
            return __('Co')
        case ZoneTypes.ZONE_TYPE_NO_ZONE_TYPE:
            return __('No zone type')
    }

    warn(`Unknown alarm in memory zone type ${zoneType}`)

    return zoneType || __('Alarm in memory')
}

export function alarmTitle(alarm, zoneType) {
    if (alarm === ALARM_ALARM_IN_MEMORY) {
        return alarmTitleByZone(zoneType)
    }

    switch (alarm) {
        case ALARM_PANIC:
            return __('Panic alarm')
        case ALARM_EMERGENCY:
            return __('Emergency alarm')
        case ALARM_FIRE:
            return __('Fire alarm')
        case ALARM_SMOKE_MEMORY:
            return __('Smoke alarm')
        case ALARM_HEAT_MEMORY:
            return __('Heat alarm')
        case ALARM_TAMPER_MEMORY:
            return __('Tamper alarm')
        case ALARM_ALARM_DURESS:
            return __('Duress')
        case ALARM_BURGLARY:
            return __('Burglary')
        case ALARM_HR24_SUPERVISORY:
            return __('HR24 supervisory')
        case ALARM_CO_ALARM:
            return __('CO alarm')
        case ALARM_GAS_ALARM:
            return __('Gas alarm')
        case ALARM_HIGH_TEMPERATURE:
            return __('High temperature')
        case ALARM_LOW_TEMPERATURE:
            return __('Low temperature')
        case ALARM_WATERFLOW:
            return __('Waterflow')
        case ALARM_WATER_LEAKAGE:
            return __('Water leakage')
        case ALARM_HOLD_UP:
            return __('Hold up')
        case ALARM_SPRINKLER:
            return __('Sprinkler')
        case ALARM_BURGLARY_NO_VERIFIED:
            return __('Burglary no verified')
        case ALARM_HOLD_UP_NO_VERIFIED:
            return __('Hold up no verified')
        case ALARM_HIGH_TEMPERATURE_WARNING:
            return __('High temperature warning')
        case ALARM_LOW_TEMPERATURE_WARNING:
            return __('Low temperature warning')
        case ALARM_RF_JAM_ALARM:
            return __('RF jam alarm')
        case ALARM_HARDWARE_FAULT:
            return __('Hardware fault')
        case ALARM_QUICK_BYPASS:
            return __('Quick bypass')
        case ALARM_DURESS:
            return __('Duress')
        case ALARM_GENERAL:
            return __('General')
        case ALARM_GAS:
            return __('Gas')
        case ALARM_CO:
            return __('CO')
        case ALARM_UNKNOWN:
            return __('Unknown')
        case ALARM_PENDANT:
            return __('Pendant')
        case ALARM_MEDICAL:
            return __('Medical')
        case ALARM_FIRE_SUPERVISORY:
            return __('Fire supervisory')
    }

    warn(`Unknown alarm ${alarm}`)

    return alarm
}

function alarmIconByZone(zoneType) {
    if (has(iconsByZone, zoneType)) {
        return iconsByZone[zoneType]
    }

    warn(`Unknown icon for alarm in memory zone type ${zoneType}`)

    return unknown
}

export function alarmIcon(alarm, zoneType) {
    if (alarm === ALARM_ALARM_IN_MEMORY) {
        return alarmIconByZone(zoneType)
    }

    if (has(icons, alarm)) {
        return icons[alarm]
    }

    warn(`Unknown icon for alarm ${alarm}`)

    return unknown
}
