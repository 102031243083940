import {compose} from 'redux'

import selection from 'permissions/basicConfiguration/selection'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withSelection from 'containers/withSelection'
import withBasicConfigurations from 'containers/withBasicConfigurations'

import SelectionDropDown from 'components/SelectionDropDown'

export default compose(
    withPermission({isVisible: selection}),
    withVisibility(),
    withSelection(),
    withBasicConfigurations()
)(SelectionDropDown)
