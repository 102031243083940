import React from 'react'
import {compose} from 'redux'

import list from 'permissions/basicConfiguration/list'
import selection from 'permissions/basicConfiguration/selection'
import {pushBasic} from 'permissions/panels/selection'

import withProps from 'containers/withProps'
import withBasicConfigurations from 'containers/withBasicConfigurations'
import withLoader from 'containers/withLoader'
import withSelection from 'containers/withSelection'
import {withPermission, withRejection} from 'containers/withPermission'

import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'
import DateTime from 'ipmp-react-ui/DateTime'
import LinkButton from 'ipmp-react-ui/LinkButton'
import Table from 'ipmp-react-ui/Table'

import {__} from 'utils/i18n'

import path from 'utils/path'

const columns = [
    {
        width: 100,
        name: () => __('Name'),
        render: ({name}) => name,
    },
    {
        width: 200,
        name: () => __('Configuration type'),
        render: ({vendor}) => vendor,
    },
    {
        name: () => __('Created'),
        maxWidth: 250,
        render: ({created}) => <DateTime datetime={created} />,
    },
    {
        name: () => __('Updated'),
        maxWidth: 250,
        render: ({updated}) => <DateTime datetime={updated} />,
    },
    {
        fixed: true,
        width: 50,
        align: 'right',
        render: ({id}) => (
            <LinkButton
                to={path('system.basicConfiguration.edit', {id})}
                small
                primary
                Icon={IconEdit}
            />
        ),
    },
]

export default compose(
    withPermission({
        isAllowed: list,
        hasSelection: selection,
        pushBasic: pushBasic,
    }),
    withRejection(),
    withBasicConfigurations(),
    withSelection(),
    withLoader(({init}) => init()),
    withProps(({history, pushBasic}) => ({
        fullHeight: true,
        columns,
        emptyMessage: () => __('No basic configurations found'),
        onRowClick: pushBasic
            ? ({id}) => history.push(path('system.basicConfiguration.push', {id}))
            : null,
    }))
)(Table)
