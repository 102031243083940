import {createAction} from 'redux-actions'

import createListActions from 'modules/higherOrder/createListActions'

export const fetch = createAction(
    'FIRMWARE/LIST/FETCH',
    (applianceId, upgradePackageId) => ({
        applianceId,
        upgradePackageId,
    })
)

export const {
    init,
    reset,
    receive,
    setStart,
    setQuery,
    setFilters,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
    receiveSuggests,
    startPoll,
    stopPoll,
    purgeList,
} = createListActions('FIRMWARE/LIST')

export const upgrade = createAction('FIRMWARE/LIST/UPGRADE')
export const upgradeByFilters = createAction('FIRMWARE/LIST/UPGRADE_BY_FILTERS')
