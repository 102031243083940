import {handleActions} from 'redux-actions'
import {set} from 'immutable-modify'

import {update, purge} from './actions'
import createPurgeStoreHandler from 'modules/higherOrder/createPurgeStoreHandler'

const defaultState = {
    byIds: {},
}

export default handleActions(
    {
        [update](state, {payload}) {
            const byIds = payload.reduce((byIds, row) => {
                return set(byIds, [row.id], {
                    ...byIds[row.id],
                    ...row,
                })
            }, state.byIds)

            return {
                ...state,
                byIds,
            }
        },
        ...createPurgeStoreHandler(purge),
    },
    defaultState
)
