import {createAction} from 'redux-actions'

export const check = createAction('AUTH/SIGN/CHECK')
export const checked = createAction('AUTH/SIGN/CHECKED')
export const setLoading = createAction(
    'AUTH/SIGN/SET_LOADING',
    (isLoading = true) => isLoading
)
export const login = createAction('AUTH/SIGN/LOGIN', (email, password) => ({
    email,
    password,
}))
export const loggedIn = createAction('AUTH/SIGN/LOGGED_IN')
export const receive = createAction('AUTH/SIGN/RECEIVE', (user) => user)
export const update = createAction('AUTH/SIGN/UPDATE', (user) => user)
export const logout = createAction('AUTH/SIGN/LOGOUT')
export const loggedOut = createAction('AUTH/SIGN/LOGGED_OUT')
export const setLoginScreenForm = createAction('AUTH/SIGN/SET_LOGIN_SCREEN_FORM')
