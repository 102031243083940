import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'
import withConfirmation from 'containers/withConfirmation'
import withProps from 'containers/withProps'
import withInteractiveUsers from 'containers/withInteractiveUsers'
import __ from 'utils/i18n'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {reset} from 'permissions/interactiveUsers/actions'

export default compose(
    withPermission({
        isVisible: reset,
    }),
    withVisibility(),
    withProps(),
    withInteractiveUsers(),
    withProps(({resetPassword, interactiveUser}) => ({
        label: __('Reset Password'),
        onClick: () => resetPassword(interactiveUser.id),

        message: __(
            'Do you really want to reset password for %s?',
            interactiveUser.email
        ),
        title: __('Reset password'),
    })),
    withConfirmation()
)(Button)
