import {post} from 'api/http'
import {mapProcess} from 'api/processes'
import {get} from 'api/http'

export function fetch(panelId) {
    return get('unit_locations/getall', {unt_id: panelId}).then(({process, ...data}) => ({
        ...data,
        process: process ? mapProcess(process) : null,
    }))
}

export function save(panelId, locations, batchId) {
    return post('unit_locations/save', {
        unt_id: panelId,
        locations,
        batch_id: batchId,
    }).then(mapProcess)
}
