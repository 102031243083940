import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import withProps from 'containers/withProps'

import {__} from 'utils/i18n'
import {showAddInstallerModal} from 'modules/modals/actions'
import {withPermission} from 'containers/withPermission'
import {accept} from 'permissions/installers/actions'
import {withVisibility} from 'containers/withVisibility'

export default compose(
    withPermission({
        isVisible: accept,
    }),
    withVisibility(),
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                showAddInstallerModal,
            },
            dispatch
        )
    ),
    withProps(({showAddInstallerModal}) => ({
        label: __('Add Installer'),
        Icon: IconPlus,
        onClick: showAddInstallerModal,
    }))
)(Button)
