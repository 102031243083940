import {createSelector} from 'reselect'

export const selectEventDescriptionById = createSelector(
    (state) => state.eventTypes.store.byIds,
    (_, {id}) => id,
    (storeByIds, id) => ({description: storeByIds[id] && storeByIds[id].description})
)

export const selectAllEventTypes = createSelector(
    (state) => state.eventTypes.store.byIds,
    (eventTypesByIds) => ({
        data: Object.values(eventTypesByIds),
    })
)

export const selectEventTypeSuggests = createSelector(
    (state) => state.eventTypes.store.byIds,
    (storeByIds) => {
        const data = new Set(Object.values(storeByIds).map((item) => item.description))
        return {
            count: data.size,
            values: [...data],
        }
    }
)

export const selectEventTypeFilter = createSelector(
    (state) => state.eventTypes.store.byIds,
    (_, query) => query,
    (storeByIds, query) =>
        Object.values(storeByIds)
            .filter((item) => item.description === query)
            .map((item) => item.id)
)
