import React, {Component} from 'react'
import {compose} from 'redux'
import get from 'lodash-es/get'

import withPanelInfo from 'containers/withPanelInfo'

import List, {ListHintItem} from 'ipmp-react-ui/List'
import {__} from 'utils/i18n'
import panelInfo from 'constants/panelInfo'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {info} from 'permissions/panel/info/page'

import EditPanelInfoButton from './Buttons/EditPanelInfoButton'
import Empty from 'ui/Empty'
import {PANEL_TYPE_QOLSYS} from 'constants/panelType'

class PanelInfo extends Component {
    getFirmavareValue(key) {
        const {panel} = this.props

        return get(panel, ['firmware', key])
    }

    get empty() {
        return <Empty>{__('not set')}</Empty>
    }

    get isQolsys() {
        return this.props.panel.model === PANEL_TYPE_QOLSYS
    }

    renderFirmware = () => {
        const {panel} = this.props
        const firmware = panel.firmware || {}

        return Object.keys(firmware).map((key) => (
            <ListHintItem
                key={key}
                name={panelInfo(key)}
                text={this.getFirmavareValue(key) || this.empty}
            />
        ))
    }

    renderAotaVersions = () => {
        const {fotaAotaVersions} = this.props

        if (this.isQolsys) {
            return null
        }

        return Object.keys(fotaAotaVersions).map((key) => (
            <ListHintItem
                key={key}
                name={panelInfo(key)}
                text={fotaAotaVersions[key] || this.empty}
            />
        ))
    }

    render() {
        const {panel} = this.props

        return (
            <div>
                <div className="card-header">
                    {__('Panel Info')}
                    <EditPanelInfoButton />
                </div>

                <List className="card-content" twoCols>
                    {panel.simNumber && (
                        <ListHintItem name={__('Sim number')} text={panel.simNumber} />
                    )}

                    {this.renderFirmware()}
                    {this.renderAotaVersions()}
                </List>
            </div>
        )
    }
}

export default compose(
    withPermission({isVisible: info}),
    withVisibility(),
    withPanelInfo()
)(PanelInfo)
