import React from 'react'
import {compose} from 'redux'

import Table from 'ipmp-react-ui/Table'
import Definition from 'ipmp-react-ui/Definition'

import __ from 'utils/i18n'
import withProps from 'containers/withProps'
import withLoader from 'containers/withLoader'
import {withInstallerAddPanelTable} from 'containers/withInstallerPanelsTable'
import AddPanelCell from './TableCell/AddPanelCell'

const columns = [
    {
        name: () => __('Panel'),
        maxWidth: 293,
        render: ({serial, account}) => <Definition title={serial} details={account} />,
    },
    {
        name: () => __('Group'),
        render: ({group}) => group,
    },
    {
        tooltip: false,
        fixed: true,
        width: 56,
        component: AddPanelCell,
    },
]

export default compose(
    withInstallerAddPanelTable(),
    withLoader(({fetch}) => fetch()),
    withProps(() => ({
        columns,
        className: 'installerBlind-table',
    }))
)(Table)
