import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {withRouter} from 'react-router-dom'

import withPerPage from 'containers/withPerPage'

import {selectBasicConfigurationsListRows} from 'modules/basicConfiguration/list/selectors'

import {
    init,
    reset,
    setStart,
    fetch,
    remove,
} from 'modules/basicConfiguration/list/actions'

import {showPushBasicConfigurationModal} from 'modules/modals/actions'

const mapStateToProps = () => (state) => {
    const {isLoading, error, total, start} = state.basicConfiguration.list

    return {
        rows: selectBasicConfigurationsListRows(state),
        isLoading,
        error,
        total,
        start,
    }
}

const mapDispatchToProps = () => (dispatch) =>
    bindActionCreators(
        {
            init,
            fetch,
            reset,
            remove,
            onPageChange: setStart,
            showPushBasicConfigurationModal,
        },
        dispatch
    )

export default function withBasicConfigurations() {
    return compose(
        withRouter,
        withPerPage('basicConfiguration'),
        connect(mapStateToProps, mapDispatchToProps)
    )
}
