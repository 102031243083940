export default function createPurgeReducer(
    reducer,
    purgeAction,
    stateSave = (state: Object) => undefined
) {
    return (state, action) => {
        if (action.type === purgeAction.toString()) {
            return reducer(stateSave(state), action)
        }

        return reducer(state, action)
    }
}
