import React, {Component} from 'react'
import PropTypes from 'prop-types'
import isUndefined from 'lodash-es/isUndefined'
import has from 'lodash-es/has'

import Input from 'ipmp-react-ui/Input'
import Select from 'ipmp-react-ui/Select'
import {Option} from 'ipmp-react-ui/Select'

import {__} from 'utils/i18n'
import Checkbox from 'ipmp-react-ui/Checkbox'

function connectionFieldsPresenceResolve(value, attributes) {
    return attributes.connectionType === 'tcp' || !has(attributes, 'connectionType')
}

function serialIdPresenceResolve(value, attributes) {
    return attributes.connectionType === 'serial'
}

export const rules = {
    name: {
        presence: true,
        format: {
            pattern: /^([-.\w\s]){1,50}$/,
            flags: 'i',
            message: () => __('CS name is not in valid format'),
        },
        length: {
            minimum: 1,
            maximum: 50,
        },
    },
    protocolId: {
        numericality: true,
    },
    heartBeat: {
        numericality: {
            greaterThanOrEqualTo: 10,
            lessThanOrEqualTo: 255,
        },
    },
    retryTime: {
        presence: true,
        numericality: {
            greaterThanOrEqualTo: 5,
            lessThanOrEqualTo: 60,
        },
    },
    retryCount: {
        presence: true,
        numericality: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 5,
        },
    },
    serialPortId: {
        presence: serialIdPresenceResolve,
    },
    host: {
        presence: connectionFieldsPresenceResolve,
        host: true,
    },
    port: {
        presence: connectionFieldsPresenceResolve,
        numericality: {
            greaterThanOrEqualTo: 1024,
            lessThanOrEqualTo: 65535,
        },
    },
    ssl: {
        inclusion: ['none', 'tls1'],
    },
    receiver: {
        numericality: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 99,
        },
    },
    line: {
        numericality: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 9,
        },
    },
}

export default class CentralStationFormFields extends Component {
    static propTypes = {
        data: PropTypes.object,
        serialPorts: PropTypes.array,
        isNew: PropTypes.bool,
        protocols: PropTypes.array,
    }

    constructor(props, context) {
        super(props, context)
        this.state = {
            connectionType: props.data.connectionType,
            isAdvanced: false,
            isHeartBeat: props.data.isHeartBeat,
            selectedProtocolId: props.data.protocolId,
        }
    }

    handleProtocolChange = (e, value) => {
        this.setState({selectedProtocolId: parseInt(value)})
    }

    handleConnectionTypeChange = (e, value) => {
        this.setState({connectionType: value})
    }

    handleAdvancedCheckboxChange = (e) => {
        this.setState({isAdvanced: e.target.checked})
    }

    toggleHeartBeat = (e) => {
        this.setState({isHeartBeat: e.target.checked})
    }

    get isAdvancedToggler() {
        const {protocols} = this.props
        const {selectedProtocolId} = this.state

        if (isUndefined(selectedProtocolId)) {
            return false
        }

        const protocolName = protocols.find(({id}) => id === Number(selectedProtocolId))
            .name

        // Only MLR protocols has Advanced options
        return /MLR/.test(protocolName)
    }

    render() {
        const {protocols, serialPorts, data, isNew} = this.props
        const {isAdvanced, connectionType, isHeartBeat} = this.state

        return (
            <div>
                <Input
                    autoFocus
                    readOnly={!isNew}
                    label={__('Name')}
                    name="name"
                    defaultValue={data.name}
                />

                <Select
                    name="protocolId"
                    defaultValue={data.protocolId}
                    label={__('Protocol')}
                    onChange={this.handleProtocolChange}
                >
                    {protocols.map(({id, name}) => (
                        <Option key={id} label={name} value={id} />
                    ))}
                </Select>

                <Checkbox
                    name="isHeartBeat"
                    onChange={this.toggleHeartBeat}
                    label={__('Heart beat')}
                    checked={isHeartBeat}
                />

                {isHeartBeat && (
                    <Input
                        type="number"
                        label={__('Heart beat')}
                        name="heartBeat"
                        defaultValue={data.heartBeat}
                    />
                )}

                <Input
                    type="number"
                    label={__('Retry time')}
                    name="retryTime"
                    defaultValue={data.retryTime}
                />

                <Input
                    type="number"
                    label={__('Retry count')}
                    name="retryCount"
                    defaultValue={data.retryCount}
                />

                <Select
                    name="connectionType"
                    defaultValue={data.connectionType}
                    onChange={this.handleConnectionTypeChange}
                    label={__('Connection Type')}
                >
                    <Option label={__('TCP/IP')} value="tcp" />
                    <Option label={__('Serial')} value="serial" />
                </Select>

                {connectionType === 'tcp' && (
                    <Input label={__('Host')} name="host" defaultValue={data.host} />
                )}

                {connectionType === 'tcp' && (
                    <Input
                        label={__('Port')}
                        type="number"
                        name="port"
                        defaultValue={data.port}
                    />
                )}

                {connectionType === 'tcp' && (
                    <Select label={__('Security')} name="ssl" defaultValue={data.ssl}>
                        <Option label={__('None')} value="none" />
                        <Option label={'TLS 1'} value="tls1" />
                    </Select>
                )}

                {connectionType === 'serial' && (
                    <Select
                        label={__('Serial Port')}
                        name="serialPortId"
                        defaultValue={parseInt(data.serialPortId)}
                    >
                        {serialPorts.length > 0 &&
                            serialPorts.map(({id, name}) => (
                                <Option key={id} label={name} value={id} />
                            ))}
                        {serialPorts.length === 0 && (
                            <Option
                                disabled
                                label={__('No ports configured on server')}
                            />
                        )}
                    </Select>
                )}

                {this.isAdvancedToggler && (
                    <Checkbox
                        onChange={this.handleAdvancedCheckboxChange}
                        label={__('Advanced options')}
                    />
                )}

                {isAdvanced && (
                    <Input
                        type="number"
                        key="receiver"
                        label={__('Receiver')}
                        name="receiver"
                        defaultValue={data.receiver}
                    />
                )}

                {isAdvanced && (
                    <Input
                        type="number"
                        key="line"
                        label={__('Line')}
                        name="line"
                        defaultValue={data.line}
                    />
                )}
            </div>
        )
    }
}
