import isEqual from 'lodash-es/isEqual'

/// returns same object if fn() returns new and last results are equal
export default function memoizeResult(fn, equalityCheck = isEqual) {
    let prevResult

    return (...args) => {
        const result = fn(...args)

        if (equalityCheck(result, prevResult)) {
            return prevResult
        }

        prevResult = result
        return result
    }
}
