import {handleActions} from 'redux-actions'

import {update, clear, stop, review, purge} from './actions'
import createPurgeStoreHandler from 'modules/higherOrder/createPurgeStoreHandler'

const state = {
    byIds: [],
}

export default handleActions(
    {
        [update](state, {payload}) {
            const byIds = payload.reduce((acc, row) => {
                const old = acc[row.id]

                return {
                    ...acc,
                    [row.id]: {
                        ...old,
                        ...row,
                    },
                }
            }, state.byIds || {})

            return {
                ...state,
                byIds,
            }
        },

        [stop](state, {payload}) {
            return {
                ...state,
                byIds: payload.ids.reduce(
                    (acc, id) => ({
                        ...acc,
                        [id]: {
                            ...acc[id],
                            isActive: false,
                        },
                    }),
                    state.byIds
                ),
            }
        },

        [review](state, {payload: {id}}) {
            return {
                ...state,
                byIds: {
                    ...state.byIds,
                    [id]: {
                        ...state.byIds[id],
                        isReviewed: true,
                    },
                },
            }
        },

        [clear]() {
            return state
        },
        ...createPurgeStoreHandler(purge),
    },
    state
)
