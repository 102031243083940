import {createSelector} from 'reselect'

export const selectEventsListRows = createSelector(
    (state) => state.events.list.page,
    (state) => state.events.store.byIds,
    (page, store) => page.map((id) => store[id] || {})
)

export const selectEventsOverBoundRows = createSelector(
    (state) => state.events.list.overbound,
    (state) => state.events.store.byIds,
    (overbound, store) => {
        const result = {}

        if (overbound.next && overbound.next.length) {
            result.next = store[overbound.next.slice(0, 1).pop()]
        }

        if (overbound.prev && overbound.prev.length) {
            result.prev = store[overbound.prev.slice(-1).pop()]
        }

        return result
    }
)
