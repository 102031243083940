import {compose} from 'redux'
import {connect} from 'react-redux'

import withForm from 'containers/withForm'
import {editStateType} from 'modules/forms/handlers'
import StateTypeForm from './StateTypeForm'
import {validation} from './StateTypeFieldset'
import {__} from 'utils/i18n'

export default compose(
    connect(({stateTypes}, {stateTypeId}) => ({
        data: stateTypes.store.byIds[stateTypeId],
        header: __('Edit State Type'),
        rules: validation,
    })),
    withForm(editStateType),
    connect(null, (dispatcher, {stateTypeId, handle}) => ({
        handle: (data) => handle({id: stateTypeId, ...data}),
    }))
)(StateTypeForm)
