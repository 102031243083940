import {handleActions} from 'redux-actions'

import * as actions from './actions'

const defaultState = {}

export default handleActions(
    {
        [actions.reset](state, {meta: {panelId}}) {
            return {
                ...state,
                [panelId]: null,
            }
        },

        [actions.fetch](state, {meta: {panelId}}) {
            return {
                ...state,
                [panelId]: {
                    data: [],
                    isLoading: true,
                },
            }
        },
        [actions.receive](state, {payload, error, meta: {panelId}}) {
            if (error) {
                return {
                    ...state,
                    [panelId]: {error: payload},
                }
            }

            return {
                ...state,
                [panelId]: {
                    ...state[panelId],
                    isLoading: false,
                    data: payload,
                },
            }
        },
    },
    defaultState
)
