import {handleActions} from 'redux-actions'

import {update, clear, setLoading} from './actions'

const defaultState = {
    data: {
        reportedFaultsOption: '10',
        systemStateOption: '40',
        frequentlyUsedZonesOption: '1',
        treatAdjustedDateOption: '15',
    },
    isLoading: false,
}

export default handleActions(
    {
        [clear]() {
            return defaultState
        },

        [update](state, {payload}) {
            return {...state, data: {...payload}}
        },

        [setLoading](state, {payload}) {
            const {isLoading} = payload
            return {...state, isLoading}
        },
    },
    defaultState
)
