import {takeEvery, call, put} from 'redux-saga/effects'
import {fetch, receive} from './actions'
import {fetchGroupMessagingLanguages} from 'api/groups'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield takeEvery(fetch, watchFetch)
}

function* watchFetch() {
    try {
        const languages = yield call(fetchGroupMessagingLanguages)
        yield put(receive(languages.rows))
    } catch (error) {
        yield put(receive(error))
        yield put(snackShow(error.message))
    }
}
