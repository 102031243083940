import React, {Component} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import page from 'permissions/panel/events/page'

import Layout, {ScrollView, SideBar} from 'ipmp-react-ui/Layout'
import Spinner from 'ipmp-react-ui/Spinner'
import Button from 'ipmp-react-ui/Button'
import Error from 'ipmp-react-ui/Error'
import Table from './EventsTable'
import EventsBySerialSidebar from 'components/Sidebar/EventsSidebar'
import {__} from 'utils/i18n'

import withPoller from 'containers/withPoller'
import withLoader from 'containers/withLoader'
import {withPanelEvents} from 'containers/withPanel'
import {withPermission, withRejection} from 'containers/withPermission'

class Events extends Component {
    static propTypes = {
        panelId: PropTypes.number.isRequired,
        rows: PropTypes.array.isRequired,
        hasMoreRows: PropTypes.bool,
        isTableLoading: PropTypes.bool,
        isMoreLoading: PropTypes.bool,
        fetchOlder: PropTypes.func.isRequired,
        fetch: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        error: PropTypes.instanceOf(Error),
    }

    state = {}

    select = ({id}) => {
        this.setState({selected: id})
    }

    render() {
        const {
            panelId,
            hasOlder,
            fetchOlder,
            fetchNewer,
            isOlderLoading,
            isNewerLoading,
            newerCount,
        } = this.props
        const {selected} = this.state

        return (
            <Layout className="eventsBySerial eventsBySerial--panel">
                <ScrollView>
                    {newerCount > 0 && (
                        <div className="btnHolder">
                            {isNewerLoading ? (
                                <Spinner />
                            ) : (
                                <Button onClick={fetchNewer}>
                                    {__('Load %d new events', newerCount)}
                                </Button>
                            )}
                        </div>
                    )}

                    <Table selected={selected} onRowClick={this.select} />

                    {hasOlder && (
                        <div className="btnHolder">
                            {isOlderLoading ? (
                                <Spinner />
                            ) : (
                                <Button onClick={fetchOlder}>{__('Load older')}</Button>
                            )}
                        </div>
                    )}
                </ScrollView>

                {selected ? (
                    <EventsBySerialSidebar
                        key={selected}
                        eventId={selected}
                        panelId={panelId}
                    />
                ) : (
                    <SideBar />
                )}
            </Layout>
        )
    }
}

export default compose(
    withPermission({isAllowed: page}),
    withRejection(),
    withPanelEvents(),
    withLoader(
        ({fetch}) => fetch(),
        ({reset}) => reset()
    ),
    withPoller(5000, ({checkNewer}) => checkNewer())
)(Events)
