import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Definition from 'ipmp-react-ui/Definition'
import DateTime from 'ipmp-react-ui/DateTime'
import ProgressBar from 'ipmp-react-ui/ProgressBar'

import {ReactComponent as IconAlert} from 'ipmp-react-ui/icons/alert.svg'
import {ReactComponent as IconCompleted} from 'ipmp-react-ui/icons/check.svg'
import {ReactComponent as IconEmpty} from 'ipmp-react-ui/icons/hourglass-empty.svg'

import {__} from 'utils/i18n'

export default class StatusReportCell extends Component {
    static propTypes = {
        row: PropTypes.shape({
            status: PropTypes.string,
            percentage: PropTypes.number,
            finishedAt: PropTypes.object,
        }),
    }

    render() {
        const {
            row: {status, percentage, finishedAt},
        } = this.props

        switch (status) {
            case 'failed':
                return (
                    <DateTime
                        className="danger"
                        Icon={IconAlert}
                        datetime={finishedAt || {}}
                    />
                )
            case 'start':
            case 'handled':
                return <ProgressBar max={100} value={percentage || 0} />
            case 'succeeded':
                return <DateTime Icon={IconCompleted} datetime={finishedAt || {}} />
            case 'new':
            case null:
                return (
                    <Definition
                        className="empty"
                        Icon={IconEmpty}
                        title={__('No results yet')}
                    />
                )
            default:
                return null
        }
    }
}
