import {handleActions} from 'redux-actions'
import {merge, remove, set} from 'immutable-modify'
import omit from 'lodash-es/omit'

import {
    fetch,
    receive,
    receiveCurrent,
    fetchOne,
    receiveOne,
    changeValue,
    restoreBackup,
    dismissChanges,
    clearFilters,
    setExport,
    setMakeBasic,
    setQuery,
    setShowChanges,
    setShowExportable,
    update,
    outdated,
    revertBackup,
    markAsBackup,
    refreshRunner,
} from './actions'
import configurationToHash from 'utils/configuration/configurationToHash'

const defaultState = {
    errors: {},
    changes: {},
    exporting: {},
    makeBasic: false,
    isShowChanged: false,
    isShowExportable: false,
    query: '',
    outdated: false,
}

const setBackupId = (rows, id) =>
    rows.map((row) => {
        if (row.id === id) {
            return {
                ...row,
                backup: true,
            }
        }

        if (row.backup) {
            return {
                ...row,
                backup: false,
            }
        }

        return row
    })

export default handleActions(
    {
        [fetch](state, {payload: panelId}) {
            const old = state[panelId] || defaultState

            return set(state, panelId, {
                ...old,
                isLoading: true,
            })
        },

        [receive](state, {error, payload, meta: {panelId}}) {
            if (error) {
                return merge(state, panelId, {
                    isLoading: false,
                    error: payload,
                })
            }

            return merge(state, panelId, {
                error: null,
                isLoading: false,
                outdated: false,
                ...payload,
            })
        },

        [receiveCurrent](state, {payload, meta: {panelId}}) {
            return merge(state, panelId, {
                values: configurationToHash(payload),
                configuration: payload,
            })
        },

        [update](state, {payload, meta: {panelId}}) {
            return merge(state, panelId, payload)
        },

        [fetchOne](state, {payload: {panelId, configId}}) {
            return set(state, [panelId, 'backups', configId], {
                isLoading: true,
            })
        },

        [receiveOne](state, {error, payload, meta: {panelId, configId}}) {
            if (error) {
                return set(state, [panelId, 'backups', configId], {
                    isLoading: false,
                    error: payload,
                })
            }

            return set(state, [panelId, 'backups', configId], {
                isLoading: false,
                error: null,
                configuration: payload,
            })
        },

        [changeValue](state, {payload: {panelId, value, key, valid}}) {
            const old = {
                ...defaultState,
                ...state[panelId],
            }

            const changes =
                value === old.values[key]
                    ? remove(old.changes, key)
                    : set(old.changes, key, value)

            const errors = valid ? remove(old.errors, key) : set(old.errors, key, true)

            return merge(state, panelId, {
                changes,
                errors,
            })
        },

        [restoreBackup](state, {payload: {panelId, backup}}) {
            const old = {
                ...defaultState,
                ...state[panelId],
            }

            const keys = Object.keys(backup)

            const notChangedKeys = keys.filter((key) => backup[key] === old.values[key])

            const changes = omit(backup, notChangedKeys)

            return merge(state, panelId, {
                changes,
                errors: {},
            })
        },

        [dismissChanges](state, {payload: {panelId}}) {
            return merge(state, panelId, defaultState)
        },

        [clearFilters](state, {payload: {panelId}}) {
            return merge(state, panelId, {
                isShowChanged: false,
                isShowExportable: false,
                query: '',
            })
        },

        [outdated](state, {payload: {panelId}}) {
            return merge(state, panelId, {
                outdated: true,
            })
        },

        [setMakeBasic](state, {payload: {panelId, enabled}}) {
            return merge(state, panelId, {
                makeBasic: enabled,
                isShowExportable: enabled,
                isShowChanged: false,
                errors: {},
                changes: {},
                query: '',
            })
        },

        [setExport](state, {payload: {panelId, key, value, exported}}) {
            if (exported) {
                return set(state, [panelId, 'exporting', key], value)
            } else {
                return remove(state, [panelId, 'exporting', key])
            }
        },

        [setShowExportable](state, {payload: {panelId, enabled}}) {
            return merge(state, panelId, {
                isShowExportable: enabled,
            })
        },

        [setShowChanges](state, {payload: {panelId, enabled}}) {
            return merge(state, panelId, {
                isShowChanged: enabled,
            })
        },

        [setQuery](state, {payload: {panelId, query}}) {
            return merge(state, panelId, {
                query,
            })
        },

        [dismissChanges](state, {payload: {panelId}}) {
            return merge(state, panelId, {
                errors: {},
                changes: {},
                exporting: {},
                makeBasic: false,
                isShowChanged: false,
                isShowExportable: false,
            })
        },

        [revertBackup](state, {payload: {panelId}}) {
            const old = state[panelId] || {}
            const id = old.prevBackupId

            if (!id) {
                return state
            }

            return merge(state, panelId, {
                ...old,
                prevBackupId: null,
                rows: setBackupId(old.rows, id),
            })
        },

        [markAsBackup](state, {payload: {id, panelId}}) {
            const old = state[panelId] || {}
            const prevBackup = old.rows.find((row) => row.backup)

            return merge(state, panelId, {
                ...old,
                prevBackupId: prevBackup ? prevBackup.id : null,
                rows: setBackupId(old.rows, id),
            })
        },
        [refreshRunner](state, {payload: {panelId, runner}}) {
            return set(state, [panelId, 'runner'], runner)
        },
    },
    {}
)
