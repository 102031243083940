import {compose} from 'redux'

import values, {edit} from 'permissions/remoteInspections/values'

import withProps from 'containers/withProps'
import withRemoteInspections from 'containers/withRemoteInspections'
import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'

import {ReactComponent as IconSettings} from 'ipmp-react-ui/icons/settings.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({
        isVisible: values,
        editable: edit,
    }),
    withVisibility(),
    withRemoteInspections(),
    withProps(({showRemoteInspectionValuesModal, editable}) => ({
        onClick: () => showRemoteInspectionValuesModal({editable}),
        label: __('Settings'),
        Icon: IconSettings,
    }))
)(Button)
