import React, {Component} from 'react'
import {compose} from 'redux'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Menu, {MenuDelimiter} from 'ipmp-react-ui/Menu'
import DropDownButton from 'ipmp-react-ui/DropDownButton'

import MarkForServiceItem from './FaultsItems/MarkForServiceItem'
import ReassignItem from './FaultsItems/ReassignItem'
import ResolveFaultsItem from './FaultsItems/ResolveFaultsItem'
import SuspendFaultsItem from './FaultsItems/SuspendFaultsItem'
import ResumeFaultsItem from './FaultsItems/ResumeFaultsItem'

import {services} from 'permissions/panels/bar'

import {__} from 'utils/i18n'
import {withFeatureRejection} from 'containers/withFeature'
import {selectFaults} from 'modules/features/store/selectors'
import Void from 'components/Void'
import {withRouterPanelId} from 'containers/withPanel'

class FaultsDropDown extends Component {
    render() {
        return (
            <DropDownButton label={__('Faults')}>
                <Menu>
                    <MarkForServiceItem />
                    <ReassignItem />
                    <MenuDelimiter />
                    <ResolveFaultsItem />
                    <SuspendFaultsItem />
                    <ResumeFaultsItem />
                </Menu>
            </DropDownButton>
        )
    }
}

export default compose(
    withPermission({isVisible: services}),
    withVisibility(),
    withRouterPanelId(),
    withFeatureRejection(selectFaults, Void)
)(FaultsDropDown)
