import {createSelector} from 'reselect'

export const selectPGMTypesIsLoading = (state) => state.pgm.types.isLoading
export const selectPGMTypesError = (state) => state.pgm.types.error
export const selectPGMTypes = (state) => state.pgm.types.types
export const selectPGMOptions = (state) => state.pgm.types.options

export const selectPGMTypesState = createSelector(
    [selectPGMTypesIsLoading, selectPGMTypesError, selectPGMTypes, selectPGMOptions],
    (isLoading, error, types, options) => ({isLoading, error, types, options})
)
