import {compose} from 'redux'

import send from 'permissions/panel/devices/plink/log/send'

import {ReactComponent as IconSend} from 'ipmp-react-ui/icons/send.svg'

import withProps from 'containers/withProps'
import withConfirmation from 'containers/withConfirmation'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {withPlinkDebugCommands} from 'containers/withDevices'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'

export default compose(
    withPermission({
        isVisible: send,
    }),
    withVisibility(),
    withPlinkDebugCommands(),
    withProps(({sendLog}) => ({
        Icon: IconSend,
        onClick: sendLog,
        title: __('Send log'),
        message: __('Do you want to send log?'),
        positive: __('Send'),
    })),
    withConfirmation()
)(Button)
