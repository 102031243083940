import React from 'react'

import {__} from 'utils/i18n'

import Input from 'ipmp-react-ui/Input'

export const rules = {
    host: {
        presence: true,
        ipv4: true,
    },
    port: {
        presence: true,
        numericality: true,
    },
}

const PlinkLogging = () => {
    return (
        <div>
            <Input label={__('Host')} name="host" />

            <Input label={__('Port')} name="port" />
        </div>
    )
}

export default PlinkLogging
