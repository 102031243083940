import {createAction} from 'redux-actions'

export const fetch = createAction('PANELS/LOCATIONS/FETCH')
export const receive = createAction('PANELS/LOCATIONS/RECEIVE', null, (_, panelId) => ({
    panelId,
}))
export const update = createAction('PANELS/LOCATIONS/UPDATE', null, (_, panelId) => ({
    panelId,
}))
export const persist = createAction('PANELS/LOCATIONS/PERSIST')
export const change = createAction(
    'PANELS/LOCATIONS/CHANGE',
    (id, value) => ({id, value}),
    (_, $, panelId) => ({panelId})
)

export const startPolling = createAction('PANELS/LOCATIONS/POLL/START')
export const stopPolling = createAction('PANELS/LOCATIONS/POLL/STOP')
