import React, {Component} from 'react'
import NavTabs, {NavTabLink} from 'ipmp-react-ui/NavTabs'

import {routing} from './EventRulesRouting'

export default class EventRulesNavTabs extends Component {
    renderNavTabLink = ({path, exact, label}) => (
        <NavTabLink key={path} exact={exact} to={path}>
            {label}
        </NavTabLink>
    )

    render() {
        return (
            <NavTabs className="eventRules-tabs">
                {routing.map(this.renderNavTabLink)}
            </NavTabs>
        )
    }
}
