import {put, select} from 'redux-saga/effects'
import updatePanelFaults from 'modules/panels/store/watchers/updatePanelFaults'
import * as actions from '../actions'

export default function* watchResumeFaults({meta: {faultIds}}) {
    const panels = yield select((state) => state.panels.store.byIds)
    const panelsPatch = updatePanelFaults(panels, faultIds, {isSuspended: false})

    if (panelsPatch.length > 0) {
        yield put(actions.update(panelsPatch))
    }
}
