import {handleActions} from 'redux-actions'
import {receive, fetch, refresh} from './actions'

export default handleActions(
    {
        [fetch](state, {payload: {panelId}}) {
            return {
                ...state,
                [panelId]: {
                    error: null,
                    appliances: null,
                    runner: null,
                },
            }
        },
        [receive](state, {error, payload, meta: {panelId}}) {
            if (error) {
                return {
                    ...state,
                    [panelId]: {
                        error: payload,
                        appliances: null,
                        runner: null,
                    },
                }
            }

            return {
                ...state,
                [panelId]: {
                    ...state[panelId],
                    ...payload,
                    error: null,
                },
            }
        },
        [refresh](state, {payload: {panelId, error, runner}}) {
            return {
                ...state,
                [panelId]: {
                    ...state[panelId],
                    runner: runner,
                },
            }
        },
    },
    {}
)
