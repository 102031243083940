import React, {Component} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

import withProps from 'containers/withProps'
import {selectEventDescriptionById} from 'modules/eventTypes/store/selectors'
import __ from 'utils/i18n'

class EventTypeCell extends Component {
    static propTypes = {
        name: PropTypes.string,
        row: PropTypes.shape({
            isRestore: PropTypes.bool,
        }),
    }

    get tooltip() {
        const {
            description,
            row: {isRestore},
        } = this.props

        return isRestore ? __('restore') + ' ' + description : description
    }

    render() {
        return (
            <OverflowTextTooltip className="evenRules-elements-name">
                {this.tooltip}
            </OverflowTextTooltip>
        )
    }
}

export default compose(
    withProps(({row: {eventId}}) => ({
        id: eventId,
    })),
    connect(selectEventDescriptionById)
)(EventTypeCell)
