import {handleActions} from 'redux-actions'
import keyBy from 'lodash-es/keyBy'
import {set} from 'immutable-modify'

import * as actions from './actions'

export default handleActions(
    {
        [actions.receive](state, {payload: {type, reference}}) {
            return set(state, [type], keyBy(reference, 'code'))
        },
    },
    {}
)
