import Input from 'ipmp-react-ui/Input'
import PropTypes from 'prop-types'
import React from 'react'
import {__} from 'utils/i18n'

export default class Serial extends React.Component {
    static propTypes = {
        simNumber: PropTypes.string,
    }

    render() {
        const {simNumber} = this.props

        return (
            <Input label={__('Sim Number')} name="simNumber" defaultValue={simNumber} />
        )
    }
}
