import React from 'react'
import getHocName from 'utils/getHocName'
import isFunction from 'lodash-es/isFunction'

const defaultMergeProps = (props, override) => ({
    ...props,
    ...override,
})

export default function withProps(alias, mergeProps = defaultMergeProps) {
    return (WrappedComponent) => {
        const HOC = (props) => {
            const override = isFunction(alias) ? alias(props) : alias

            return <WrappedComponent {...mergeProps(props, override)} />
        }

        HOC.displayName = getHocName('withProps', WrappedComponent)

        return HOC
    }
}
