import {all} from 'redux-saga/effects'

import list from './list/saga'
import walktest from './walktest/saga'
import reference from './reference/saga'
import vod from './vod/saga'
import meteo from './meteo/saga'

export default function* () {
    yield all([list(), walktest(), reference(), vod(), meteo()])
}
