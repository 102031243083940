import dataMapper from 'utils/dataMapper'
import _forEach from 'lodash-es/forEach'

export const mapCellularConnected = dataMapper((data) => ({
    isAutoEnrollmentEnabled: !!data.isAutoEnrollmentEnabled,
    messageBroker: parseInt(data.messageBroker),
    retriesMax: parseInt(data.retriesMax),
    retryInterval: parseInt(data.retryInterval),
    wakeupOnUserDiscovery: !!data.wakeupOnUserDiscovery,
}))

export const mapBroadbandConnected = dataMapper((data) => ({
    isAutoEnrollmentEnabled: !!data.isAutoEnrollmentEnabled,
}))

export const mapCommonConnected = dataMapper((data) => ({
    systemIdRegex: data.systemIdRegex,
    isPanelRemoveForever: !!data.isPanelRemoveForever,
}))

export const mapSupervision = dataMapper((data) => ({
    doSendEmailOnOnlineOffline: !!data.doSendEmailOnOnlineOffline,
    doSendSmsOnOnlineOffline: !!data.doSendSmsOnOnlineOffline,
    doSendOneChannelPanelOnlineOffline: !!data.doSendOneChannelPanelOnlineOffline,
    doSendTwoChannelPanelOnlineOffline: !!data.doSendTwoChannelPanelOnlineOffline,
}))

export const mapRemoteInspection = dataMapper((data) => ({
    doSendEmailOnSuccess: !!data.doSendEmailOnSuccess,
    doGenerateResultEvent: !!data.doGenerateResultEvent,
}))

export const mapInteractiveSession = dataMapper((data) => ({
    doAutoEnableUserApp: !!data.doAutoEnableUserApp,
    doAutoEnableConfiguratorApp: !!data.doAutoEnableConfiguratorApp,
    isDlsServiceEnabled: !!data.isDlsServiceEnabled,
    globalServerApptype: data.globalServerApptype,
    maxInteractiveConnections: parseInt(data.maxInteractiveConnections),
    maxInteractiveConnectionsPerPanel: parseInt(data.maxInteractiveConnectionsPerPanel),
    doRequireUserInstallerApproval: !!data.doRequireUserInstallerApproval,
}))

export const mapUserNotification = dataMapper((data) => ({
    isEmailWithVideoEnabled: !!data.isEmailWithVideoEnabled,
    isEmailWithoutVideoEnabled: !!data.isEmailWithoutVideoEnabled,
    messageBroker: parseInt(data.messageBroker),
}))

export const mapAdvertisement = dataMapper((data) => ({
    url: data.url,
}))

export const mapMessageBroker = dataMapper((data) => ({
    id: parseInt(data.id),
    name: data.name,
    description: data.description,
    sender: data.sender,
    login: data.login,
    password: data.password,
    host: data.host,
    port: parseInt(data.port),
    useTLS: !!data.useTLS,
    messageTemplate: data.messageTemplate,
    serial: parseInt(data.serial) !== -1 ? parseInt(data.serial) : null,
}))

export const mapSerialPort = dataMapper((data) => ({
    id: parseInt(data.id),
    name: data.name,
    speed: parseInt(data.speed),
    databits: parseInt(data.databits),
    parity: parseInt(data.parity),
    stopbits: parseInt(data.stopbits),
    application: data.application,
}))

export const mapValidatorInteractiveAuthorizationSettingsError = (errors) => {
    if (errors.details) {
        let details = {}
        _forEach(errors.details, (val, key) => {
            if (key != 'userLifetime') {
                _forEach(errors.details[key], (deepval, deepkey) => {
                    details[key + '.' + deepkey] = deepval
                })
            } else {
                details[key] = val
            }
        })
        errors.details = details
    }
    return errors
}
