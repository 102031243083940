import {get, post, put, del} from 'api/http'
import createRowsResponseParser from 'api/base/createRowsResponseParser'
import mapValidationErrors from 'api/base/mapValidationErrors'
import {mapNotificationTypes, keysMap} from 'api/notificationTypes.map'

export function fetch() {
    return get('/system/virtual/panel/notification_type').then(
        createRowsResponseParser(mapNotificationTypes)
    )
}

export function add(data) {
    return post('/system/virtual/panel/notification_type', {
        form: {
            id_on_panel: data.idOnPanel,
            name: data.name,
        },
    }).catch((e) => mapValidationErrors(e, keysMap))
}

export function edit(data) {
    return put('/system/virtual/panel/notification_type/' + data.id, {
        form: {
            id_on_panel: data.idOnPanel,
            name: data.name,
            id: data.id,
        },
    }).catch((e) => mapValidationErrors(e, keysMap))
}

export function remove(id) {
    return del('/system/virtual/panel/notification_type/' + id[0]).catch((e) =>
        mapValidationErrors(e, keysMap)
    )
}
