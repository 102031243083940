import React from 'react'
import {compose} from 'redux'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'

import AddZoneTypeButton from './GridBar/AddZoneTypeButton'

export const ZoneTypesGridBar = () => (
    <Bar orientation={RIGHT}>
        <AddZoneTypeButton />
    </Bar>
)

export default compose()(ZoneTypesGridBar)
