import {all, call, select, put, takeEvery} from 'redux-saga/effects'

import {fetchInteractiveUserPanels, disconnectPanel} from 'api/interactiveUsers'
import * as actions from './actions'

import {update, purge} from '../store/actions'
import {selectPerPage} from 'modules/settings/selectors'
import {update as updateInteractiveUsers} from 'modules/interactiveUsers/store/actions'

import listSaga from 'modules/higherOrder/createListSaga'
import {snackShow} from 'modules/snacks'

const selector = (state) => ({
    ...state.interactiveUserPanels.list,
    perPage: selectPerPage(state, 'interactiveUserPanels'),
    listRoute: 'interactiveUsers',
})

export default function* () {
    yield all([
        listSaga({fetch: fetchInteractiveUserPanels}, actions, update, selector, purge),
        takeEvery(actions.disconnectPanel, watchDisconnectPanel),
    ])
}

function* watchDisconnectPanel({payload: panelId}) {
    try {
        const interactiveUserId = yield select(
            (state) => state.interactiveUserPanels.list.interactiveUserId
        )
        const interactiveUser = yield select(
            (state) => state.interactiveUsers.store.byIds[interactiveUserId]
        )

        yield call(disconnectPanel, {panelId, interactiveUserId})
        yield put(
            updateInteractiveUsers({
                ...interactiveUser,
                panels: interactiveUser.panels - 1,
            })
        )
    } catch (error) {
        yield put(snackShow(error.message))

        yield put(actions.revertRemove(error))
    }
}
