import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {Switch, Route, Redirect} from 'react-router-dom'
import {ConnectedRouter as Router} from 'connected-react-router'
import {connect} from 'react-redux'
import get from 'lodash-es/get'

import SnackBarComponent from 'ipmp-react-ui/SnackBar'

import {selectWidgetBatches} from 'modules/batches/widget/selectors'
import {hide} from 'modules/snacks'
import {untrack, untrackFinished} from 'modules/batches/widget/actions'
import {
    showEditProfileInfoModal,
    showChangePasswordModal,
    showEditSettingsModal,
    showAboutModal,
} from 'modules/modals/actions'
import {showBatchInfoNavBlind, hide as navBlindHide} from 'modules/navBlind/actions'
import {logout} from 'modules/auth/sign/actions'
import path from 'utils/path'
import withLoader from 'containers/withLoader'
import pages from 'pages/routes'

import Login from 'pages/App/Login'
import UserInfoComponent from 'components/UserInfo'
import ProcessBatches from 'components/Processes/ProcessBatches'
import NavMain from 'components/Nav/NavMain'
import ModalsContainer from 'modals/ModalsContainer'
import Logo from 'ui/Logo'
import NavBlindContainer from 'components/Nav/NavBlindContainer'

const SnackBar = connect(
    ({snacks}) => ({snacks}),
    (dispatch) =>
        bindActionCreators(
            {
                hide,
                dispatch,
            },
            dispatch
        )
)(SnackBarComponent)

const UserInfo = connect(
    ({
        auth: {
            sign: {user},
        },
    }) => ({user}),
    (dispatch) =>
        bindActionCreators(
            {
                showEditProfileInfoModal,
                showChangePasswordModal,
                showEditSettingsModal,
                logout: () => logout(),
            },
            dispatch
        )
)(UserInfoComponent)

const Processes = compose(
    connect(
        (state, navBlind) => ({
            batches: selectWidgetBatches(state),
            batchId: get(navBlind, 'args.batchId', null),
        }),
        (dispatcher) =>
            bindActionCreators(
                {
                    untrack,
                    untrackFinished,
                    showBatchInfoNavBlind,
                    navBlindHide,
                },
                dispatcher
            )
    )
)(ProcessBatches)

class Layout extends PureComponent {
    static propTypes = {
        showAboutModal: PropTypes.func.isRequired,
        locale: PropTypes.string.isRequired,
        isLogged: PropTypes.bool.isRequired,
        history: PropTypes.object,
        navBlindHide: PropTypes.func.isRequired,
    }

    state = {
        isOpenedProcessBatchInfo: false,
    }

    appElement: HTMLElement = null
    navElement: HTMLElement = null
    processElement: HTMLElement = null

    closeNavBlind = () => this.props.navBlindHide()

    handleAppRef = (appElement: HTMLElement) => {
        this.appElement = appElement

        if (this.appElement) {
            this.appElement.addEventListener('click', this.handleAppClick, true)
        }
    }

    navRefHandler = (navElement) => (this.navElement = navElement)

    processRefHandler = (processElement) => (this.processElement = processElement)

    navBlindRefHandler = (navBlindElement) => (this.navBlindElement = navBlindElement)

    componentWillUnmount() {
        if (this.appElement) {
            this.appElement.removeEventListener('click', this.handleAppClick, true)
        }
    }

    handleAppClick = (event: Event) => {
        if (!this.navElement) {
            return
        }

        const {isNavBlindOpened} = this.props

        const isClickOnBatchInfo =
            this.navBlindElement &&
            (this.navBlindElement.contains(event.target) ||
                this.navBlindElement === event.target)
        const isRecentHistoryWidget = Boolean(
            event.target.closest('.recentHistoryWidgetController')
        )
        const isClickOnNavigation = this.navElement.contains(event.target)
        const isClickOnMyProcesses =
            this.processElement && this.processElement.contains(event.target)

        switch (true) {
            case isClickOnBatchInfo:
                break
            case isRecentHistoryWidget && isNavBlindOpened:
                event.stopPropagation()
                this.closeNavBlind()
                break
            case isClickOnNavigation:
                this.closeNavBlind()
                break
            case isClickOnMyProcesses:
                break
            default:
                this.closeNavBlind()
                break
        }
    }

    render() {
        const {showAboutModal, locale, isLogged, history} = this.props

        if (!isLogged) {
            return (
                <Login>
                    <SnackBar />
                </Login>
            )
        }

        return (
            <Router history={history} key={locale}>
                <div className="app" ref={this.handleAppRef}>
                    <UserInfo />

                    <aside className="app-aside">
                        <div className="app-aside-logo" onClick={showAboutModal}>
                            <Logo multiline />
                        </div>

                        <div className="app-aside-content">
                            <NavMain navRefHandler={this.navRefHandler} />
                            <Processes processRefHandler={this.processRefHandler} />
                        </div>
                    </aside>

                    <NavBlindContainer navBlindRefHandler={this.navBlindRefHandler} />

                    <Switch>
                        <Route
                            exact
                            path={path('/')}
                            render={() => <Redirect to={path('panels', {})} />}
                        />
                        {pages.map((page, i) => (
                            <Route key={i} {...page} />
                        ))}
                    </Switch>

                    <SnackBar />

                    <ModalsContainer />
                </div>
            </Router>
        )
    }
}

export default compose(
    connect(
        ({auth: {sign}, locales, navBlind: {type}}) => ({
            locale: locales.locale,
            isLogged: !!sign.user,
            isLoading: !sign.checked,
            isNavBlindOpened: !!type,
        }),

        (dispatch) => bindActionCreators({showAboutModal, navBlindHide}, dispatch)
    ),
    withLoader()
)(Layout)
