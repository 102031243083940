import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {
    init,
    fetch,
    reset,
    setStart,
    startPoll,
    stopPoll,
} from 'modules/upgradeStatus/list/actions'
import {retry} from 'modules/upgradeStatus/store/actions'
import {selectUpgradeStatusListRows} from 'modules/upgradeStatus/list/selectors'

export default function withUpgradeStatus() {
    return compose(
        withPerPage('upgrades'),
        connect(
            (state) => ({
                isLoading: state.upgradeStatus.list.isLoading,
                page: state.upgradeStatus.list.page,
                error: state.upgradeStatus.list.error,
                total: state.upgradeStatus.list.total,
                start: state.upgradeStatus.list.start,
                rows: selectUpgradeStatusListRows(state),
            }),

            (dispatch) =>
                bindActionCreators(
                    {
                        init,
                        fetch,
                        reset,
                        retry,
                        startPoll,
                        stopPoll,
                        onPageChange: setStart,
                    },
                    dispatch
                )
        )
    )
}
