import {compose} from 'redux'

import selection from 'permissions/reports/selection'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import SelectionDropDown from 'components/SelectionDropDown'
import withSelection from 'containers/withSelection'
import withReports from 'containers/withReports'

export default compose(
    withPermission({isVisible: selection}),
    withVisibility(),
    withReports(),
    withSelection()
)(SelectionDropDown)
