import {combineReducers} from 'redux'

import store from './store/reducer'
import list from './list/reducer'
import alarms from './alarms/reducer'
import bySerial from './bySerialList/reducer'

export default combineReducers({
    store,
    list,
    bySerial,
    alarms,
})
