import React, {Component} from 'react'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import {__} from 'utils/i18n'

import {info} from 'permissions/general/license'

import {withPermission, withSilenceRejection} from 'containers/withPermission'
import withLifeCycle from 'containers/withLifeCycle'

import {fetchLicense} from 'modules/system/settings/actions'
import {selectLicense} from 'modules/system/settings/selectors'

import {showAboutLicenseFeaturesModal} from 'modules/modals/actions'

import Void from 'components/Void'

class LicenseInfo extends Component {
    static propTypes = {
        license: PropTypes.object,
    }

    render() {
        if (!this.props.license) {
            return <Void />
        }
        const {
            showAboutLicenseFeaturesModal,
            license: {name, valid},
        } = this.props
        return (
            <div>
                <div className="about-info">
                    <p className="hint hint--gray">{__('Active License')}</p>
                    {name}
                </div>
                <div className="about-info">
                    <p className="hint hint--gray">{__('License expiration date')}</p>
                    <p>{moment.unix(valid.not_after).format('LL – LT')}</p>
                    <a onClick={showAboutLicenseFeaturesModal} className="link">
                        {__('Show license info')}
                    </a>
                </div>
            </div>
        )
    }
}

export default compose(
    withPermission({isAllowed: info}),
    withSilenceRejection(),
    connect(selectLicense, (dispatch) =>
        bindActionCreators(
            {
                fetchLicense,
                showAboutLicenseFeaturesModal,
            },
            dispatch
        )
    ),
    withLifeCycle({
        onMount({fetchLicense, license}) {
            if (!license) {
                fetchLicense()
            }
        },
    })
)(LicenseInfo)
