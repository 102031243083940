import {takeEvery, all, call, put} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/panel/reports'
import {snackShow} from 'modules/snacks'
import moment from 'moment'

export default function* () {
    yield all([
        takeEvery([actions.fetch, actions.reset], watchFetch),
        takeEvery(actions.review, watchReview),
    ])
}

function* watchFetch({meta: {panelId}, payload: {date, view}}) {
    date = moment(date)

    const from = date.startOf(view).toISOString()
    const to = date.endOf(view).toISOString()

    if (moment().endOf(view).isBefore(from)) {
        // we are smart enough to understand, that date is in the future, so we don't need to call api
        yield put(actions.receive([], panelId))
        return
    }

    let result

    try {
        result = yield call(api.receive, panelId, from, to)
    } catch (error) {
        result = error
    }

    yield put(actions.receive(result, panelId))
}

function* watchReview({meta: {panelId}, payload: {id}}) {
    try {
        yield call(api.review, id)
    } catch (error) {
        yield put(actions.unreview(id, panelId))
        yield put(snackShow(error.message))
    }
}
