import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {AppToggle} from 'components/DropDown/ApplicationInteractiveDropdown'

import __ from 'utils/i18n'
import {user} from 'permissions/panels/apps'
import withPanel from 'containers/withPanel'

import {setUserAppState} from 'modules/panels/one/actions'
import {withFeatureRejection} from 'containers/withFeature'
import {selectAppUser} from 'modules/features/store/selectors'
import Void from 'components/Void'
import withProps from 'containers/withProps'

class ToggleUserAppItem extends Component {
    static propTypes = {
        panel: PropTypes.shape({
            configuratorApp: PropTypes.bool,
        }),
    }

    handleUserAppChange = (e) => {
        const {setUserAppState, panel} = this.props
        setUserAppState(panel, e.target.checked)
    }

    render() {
        const {panel} = this.props

        return (
            <div className="menu-item menu-item--toggle">
                <AppToggle
                    permission={user}
                    label={__('User App')}
                    onChange={this.handleUserAppChange}
                    checked={panel.userApp}
                />
            </div>
        )
    }
}

export default compose(
    withPanel(),
    connect(null, (dispatcher) =>
        bindActionCreators(
            {
                setUserAppState,
            },
            dispatcher
        )
    ),
    withProps(({panel}) => ({panelId: panel.id})),
    withFeatureRejection(selectAppUser, Void)
)(ToggleUserAppItem)
