import {compose} from 'redux'

import {MAIN_GROUP_ID} from 'constants/groups'

import selection from 'permissions/groups/selection'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withSelection from 'containers/withSelection'
import withGroups from 'containers/withGroups'
import withProps from 'containers/withProps'

import SelectionDropDown from 'components/SelectionDropDown'

export default compose(
    withPermission({isVisible: selection}),
    withVisibility(),
    withGroups(),
    withSelection(),
    withProps(() => ({
        disabledRowsIds: [MAIN_GROUP_ID],
    }))
)(SelectionDropDown)
