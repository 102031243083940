import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import {showEditEventRuleModal} from 'modules/modals/actions'

export default connect(
    (state) => ({
        flat: true,
        small: true,
        borderless: true,
        Icon: IconEdit,
    }),
    (dispatch, {row: {id}}) =>
        bindActionCreators(
            {
                onClick: () => showEditEventRuleModal(id),
            },
            dispatch
        )
)(Button)
