import {selectPerPage} from 'modules/settings/selectors'
import {takeEvery, all, put, call, race, take, select} from 'redux-saga/effects'

import {PROCESS_TYPE_REMOTEINSPECTION} from 'constants/processTypes'

import * as api from 'api/remoteInspection'
import * as actions from './actions'

import {takeEveryProcessComplete} from 'modules/processes/manager/takeProcess'
import listSaga from 'modules/higherOrder/createListSaga'
import {update as updateResults} from 'modules/remoteInspections/results/actions'
import {update, purge} from 'modules/remoteInspections/store/actions'
import {markAsViewed as markAsViewedResult} from 'modules/remoteInspections/results/actions'
import {ensureProcesses} from 'modules/processes/manager/ensureProcess'

const selector = (state) => ({
    ...state.remoteInspections.list,
    scopes: state.settings.scopes.remoteInspections,
    perPage: selectPerPage(state, 'remoteInspections'),
    listRoute: 'remoteInspections',
})

export default function* () {
    yield all([
        listSaga(api, actions, updateData, selector, purge),
        takeEvery(actions.markAsViewed, watchMarkAsViewed),
        takeEveryProcessComplete(PROCESS_TYPE_REMOTEINSPECTION, watchProcessComplete),
    ])
}

function* updateData(rows) {
    yield put(update(rows))
    yield put(updateResults(getResultsArray(rows)))
    yield ensureProcesses(rows.map((row) => row.process).filter((process) => !!process))
}

function* watchProcessComplete({panelId}) {
    const {page} = yield select((state) => state.remoteInspections.list)

    if (!page.includes(panelId)) {
        return
    }

    try {
        const {request, cancel} = yield race({
            request: call(api.fetch, {
                filters: {id: panelId},
            }),
            cancel: take([actions.fetch, actions.reset, actions.setStart]),
        })

        if (cancel) {
            // we leave that page, so we no longer need to update any data
            return
        }

        yield put(
            update(
                request.rows.map((row) => {
                    // List is grouped by date, and we don't want row to jump in list
                    // so we drop new last ri time
                    delete row.last
                    return row
                })
            )
        )
        yield put(updateResults(getResultsArray(request.rows)))
    } catch (error) {
        yield put(actions.receive(error))
    }
}

function* watchMarkAsViewed({payload: {ids}}) {
    const {store} = yield select((state) => state.remoteInspections)

    const resultIds = ids
        .map((id) => store[id] && store[id].latestId)
        .filter((id) => !!id)

    yield put(markAsViewedResult(resultIds))
}

function getResultsArray(rows) {
    return rows.reduce((carrier, {latest}) => {
        latest && carrier.push(latest)
        return carrier
    }, [])
}
