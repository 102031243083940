import mapper from 'utils/dataMapper'
import parseDate from 'api/base/parseDate'
import {mapStatus} from './batches.map'

export const mapupgradeStatusBatch = mapper((data) => ({
    id: data.batch_id,
    started: parseDate(data.batch_created_timestamp),
    finished: parseDate(data.batch_finished_timestamp),
    type: data.batch_type[0],
    isObservable: data.is_observable === '1',
    processCount: parseInt(data.prs_count),
    stats: mapStatus(data.stats),
    userId: parseInt(data.usr_id),
    panelSerial: data.unt_serial,
    panels: parseInt(data.unt_count),
    userName: data.usr_name,
}))

export const keysMap = {
    type: 'batch_type',
    started: 'batch_created_timestamp',
    serial: '_unt_serial',
    finished: 'batch_finished_timestamp',
    group: '_utg_name',
    status: 'batch_status',
    userName: 'usr_name',
}
