import {connect} from 'react-redux'
import {compose} from 'redux'
import {reduce, get, isNull, find} from 'lodash-es'

import {forgetPanel} from 'modules/panels/recent/actions'
import {selectPanelInteractiveUsers} from 'modules/panelInteractiveUsers/store/selectors'
import {selectDiscovery} from 'modules/features/store/selectors'
import {ROLE_MASTER} from 'constants/interactiveUserRoles'

import {getItemsByKeys, getMenuItems} from 'pages/Panel/PanelPageRouting'
import {filterPanelTabsWithFeature} from 'containers/withFeature'
import withLifeCycle from 'containers/withLifeCycle'

export default function withPanelPage() {
    return compose(
        connect(
            (
                state,
                {
                    allowedItems,
                    panel,
                    match,
                    isLoading,
                    isFeatureLoading,
                    panelId,
                    features,
                    isInteractiveUsersPagePermitted,
                    error,
                }
            ) => {
                let panelInteractiveUsers, isPanelInteractiveUsers, isLoadingBeforeFetch
                const isPanelFeatures = Boolean(features)

                if (isInteractiveUsersPagePermitted) {
                    panelInteractiveUsers = selectPanelInteractiveUsers(state, {panelId})
                    isPanelInteractiveUsers = isNull(panelInteractiveUsers)
                    isLoadingBeforeFetch =
                        !panel || !isPanelFeatures || isPanelInteractiveUsers
                } else {
                    isLoadingBeforeFetch = !panel || !isPanelFeatures
                }

                if (isLoadingBeforeFetch) {
                    return {
                        isLoading: !error ? isLoadingBeforeFetch : false,
                        isPanelFeatures,
                    }
                }
                const isDiscoveryApplicable = selectDiscovery(state, {
                    panelId,
                }).isAvailable
                const isContentHidden = !panel.isActivated && isDiscoveryApplicable
                const masterUserToRemember = find(panelInteractiveUsers, {
                    role: ROLE_MASTER,
                })

                if (isContentHidden) {
                    allowedItems = {
                        info: allowedItems.info,
                    }
                }

                const vendorItems = getMenuItems(panel.vendor)
                let mergedItems = reduce(
                    allowedItems,
                    (res, isAllowed, item) => {
                        if (isAllowed && get(vendorItems, item, false)) {
                            res.push(item)
                        }
                        return res
                    },
                    []
                )

                const items = filterPanelTabsWithFeature(
                    state,
                    getItemsByKeys(mergedItems),
                    match.params.id
                )

                return {
                    items,
                    key: match.params.id,
                    isLoading: isLoading || isFeatureLoading,
                    isDiscoveryApplicable,
                    isPanelFeatures,
                    masterUserToRemember,
                }
            }
        ),
        withLifeCycle({
            onUpdate({error, panelId, dispatch}, {error: prevError}) {
                if (error && error !== prevError) {
                    dispatch(forgetPanel({panelId}))
                }
            },
        })
    )
}
