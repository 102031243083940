import React, {Component} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import Button from 'ipmp-react-ui/Button'
import Card, {CardActions, CardClose, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import Layout, {ScrollView} from 'ipmp-react-ui/Layout'

import {__} from 'utils/i18n'
import Modal from 'ipmp-react-ui/Modal'

import {selectServerVersion} from 'modules/system/settings/selectors'

class AboutReleaseNotes extends Component {
    static propTypes = {
        hide: PropTypes.func,
        serverVersion: PropTypes.object,
    }

    render() {
        const {hide, serverVersion} = this.props
        return (
            <Modal onClose={hide}>
                <Card className="card--wide">
                    <CardHeader>{__('Release notes')}</CardHeader>
                    <CardClose onClick={hide} />
                    <CardContent className="card-about-servernotes">
                        <Layout>
                            <ScrollView className="block">
                                <div className="about-info">
                                    <p className="hint hint--gray">{__('Version')}</p>
                                    {serverVersion.version}
                                </div>

                                <div className="about-info">
                                    <p className="hint hint--gray">{__('Revision')}</p>
                                    {serverVersion.revision}
                                </div>

                                <div className="about-info">
                                    <p className="hint hint--gray">{__('Hostname')}</p>
                                    {serverVersion.hostname}
                                </div>

                                <div className="servernotes">
                                    {serverVersion.releaseNotes}
                                </div>
                            </ScrollView>
                        </Layout>
                    </CardContent>
                    <CardActions>
                        <Button primary onClick={hide}>
                            {__('Ok')}
                        </Button>
                    </CardActions>
                </Card>
            </Modal>
        )
    }
}

export default compose(connect(selectServerVersion))(AboutReleaseNotes)
