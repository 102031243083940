import createRowsResponseParser from 'api/base/createRowsResponseParser'
import mapValidationErrors from 'api/base/mapValidationErrors'

import {del, get, post, put} from 'api/http'
import {mapEventType, keysMap} from './eventTypes.map'

export function fetch() {
    return get('/system/virtual/panel/event_type').then(
        createRowsResponseParser(mapEventType)
    )
}

export function add(data) {
    return post('/system/virtual/panel/event_type', {
        form: data,
    }).catch((e) => mapValidationErrors(e, keysMap))
}

export function edit(data) {
    return put('/system/virtual/panel/event_type/' + data.id, {
        form: data,
    }).catch((e) => mapValidationErrors(e, keysMap))
}

export function remove(id) {
    return del('/system/virtual/panel/event_type/' + id[0]).catch((e) =>
        mapValidationErrors(e, keysMap)
    )
}
