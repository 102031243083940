import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {fetch} from 'modules/devices/meteo/actions'
import {
    selectDeviceBrightness,
    selectDeviceGsmRssi,
    selectDeviceTemperature,
} from 'modules/devices/meteo/selectors'
import {BRIGHTNESS, GSM_RSSI, TEMPERATURE} from 'constants/meteoTypes'

export default function withDeviceMeteo() {
    return connect(
        (state, {type, panelId, deviceId, range: {from, to}}) => {
            const props = {
                panelId,
                deviceId,
                from,
                to,
            }

            switch (type) {
                case TEMPERATURE:
                    return selectDeviceTemperature(state, props)
                case BRIGHTNESS:
                    return selectDeviceBrightness(state, props)
                case GSM_RSSI:
                    return selectDeviceGsmRssi(state, props)
            }
        },
        (dispatch, {type, panelId, deviceId}) =>
            bindActionCreators(
                {
                    fetch: () => fetch(panelId, deviceId, type),
                },
                dispatch
            )
    )
}
