import React, {Component} from 'react'
import withProps from 'containers/withProps'

import InstallersBar from './InstallersBar'
import InstallersTable from './InstallersTable'
import InstallersSearch from './InstallersSearch'
import InstallerBlind from './InstallerBlind'

import Page from 'ipmp-react-ui/Page'
import TableWithBlind from 'components/Table/TableWithBlind'

class InstallersContent extends Component {
    render() {
        return <TableWithBlind children={[InstallerBlind, InstallersTable]} />
    }
}

export default withProps({
    Bar: InstallersBar,
    Top: InstallersSearch,
    Content: InstallersContent,
})(Page)
