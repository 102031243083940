import React, {PureComponent} from 'react'

import {ReactComponent as CheckIcon} from 'ipmp-react-ui/icons/check.svg'
import {ReactComponent as OnlineIcon} from 'ipmp-react-ui/icons/online.svg'
import {ReactComponent as AlertIcon} from 'ipmp-react-ui/icons/alert.svg'
import {ReactComponent as AlarmIcon} from 'ipmp-react-ui/icons/alarm.svg'
import {ReactComponent as OfflineIcon} from 'ipmp-react-ui/icons/offline.svg'
import {ReactComponent as InfoIcon} from 'ipmp-react-ui/icons/info.svg'

import Button from 'ipmp-react-ui/Button'

export class ActionCell extends PureComponent {
    onClick = (e) => {
        e.stopPropagation()
        this.props.onClick && this.props.onClick()
    }

    render() {
        const {isChecked, disabled, Icon} = this.props

        if (isChecked) {
            return (
                <center>
                    <CheckIcon />
                </center>
            )
        }

        return (
            <Button
                Icon={Icon}
                primary
                small
                disabled={disabled}
                onClick={this.onClick}
            />
        )
    }
}

const getSeverityIcon = (severity) => {
    switch (severity) {
        case 'online':
            return OnlineIcon
        case 'offline':
            return OfflineIcon
        case 'alert':
            return AlertIcon
        case 'alarm':
            return AlarmIcon
        default:
            return InfoIcon
    }
}

const getSeverityClass = (severity) => {
    switch (severity) {
        case 'alarm':
        case 'offline':
            return 'danger'
        case 'alert':
            return 'warning'
        default:
            return 'info'
    }
}

export const SeverityCell = ({severity, description}) => {
    const Icon = getSeverityIcon(severity)

    return (
        <span className={'severity severity--' + getSeverityClass(severity)}>
            <Icon className="severity-icon" />
            {description}
        </span>
    )
}

export const EventAppointmentCell = ({zone, appointment, partitions}) => {
    if (!zone) {
        return <span>{appointment}</span>
    }

    return (
        <div>
            #{zone} {appointment}
            {partitions && (
                <span className="partitions">
                    {partitions.map((partition) => (
                        <span key={partition} className="partition">
                            {partition}
                        </span>
                    ))}
                </span>
            )}
        </div>
    )
}
