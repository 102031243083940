import panelVendorType from 'constants/panelVendorType'
import {VENDOR_FIBRO_TRANSMITTER} from 'constants/panelVendorType'
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Buttons from 'ipmp-react-ui/Buttons'

import PanelStatus from 'components/PanelStatus'
import PanelCell from 'components/TableCells/PanelCell'
import PanelStatusLine from './PanelPageTop/PanelStatusLine'

import RefreshPanelButton from './PanelPageTop/Buttons/RefreshPanelButton'
import FaultsDropDown from './PanelPageTop/DropDowns/FaultsDropDown'
import ActionsDropDown from './PanelPageTop/DropDowns/ActionsDropDown'
import classes from 'classnames'
import Button from 'ipmp-react-ui/Button'
import Tooltip from 'ipmp-react-ui/Tooltip'
import {ReactComponent as IconChat} from 'ipmp-react-ui/icons/baseline-chat.svg'
import __ from 'utils/i18n'

const PanelStatusLineStubber = ({panel: {vendor}}) => {
    if (vendor !== VENDOR_FIBRO_TRANSMITTER) {
        return null
    }

    return (
        <div className={classes('panelStatusLine')}>
            {panelVendorType(VENDOR_FIBRO_TRANSMITTER)}
        </div>
    )
}

export default class PanelPageTop extends Component {
    static propTypes = {
        panel: PropTypes.shape({
            serial: PropTypes.string,
            account: PropTypes.string,
        }).isRequired,
        openRemarks: PropTypes.func.isRequired,
    }

    render() {
        const {panel, openRemarks} = this.props
        const {serial, account} = panel

        return (
            <Bar>
                <PanelStatus panel={panel} />
                <PanelCell
                    multiLine
                    className="title title--large"
                    {...{serial, account}}
                />
                <PanelStatusLine panel={panel} />
                <PanelStatusLineStubber panel={panel} />

                <BarSpace />

                <FaultsDropDown />
                <ActionsDropDown panel={panel} />
                <Buttons>
                    <RefreshPanelButton panel={panel} />
                </Buttons>
                <Buttons>
                    <Tooltip tooltip={__('Open Panel Remarks')}>
                        <Button onClick={openRemarks} Icon={IconChat} />
                    </Tooltip>
                </Buttons>
            </Bar>
        )
    }
}
