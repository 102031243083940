import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import List from 'ipmp-react-ui/List'

import {SettingsPageBlock} from '../SettingsPageBlock'
import EditAdvertisementButton from './Buttons/EditAdvertisementButton'

import {__} from 'utils/i18n'

class Advertisement extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            url: PropTypes.string,
        }),
    }

    render() {
        const {data} = this.props
        return (
            <div>
                <h1 className="card-header">
                    {__('Advertisement')}
                    <EditAdvertisementButton />
                </h1>

                <List className="card-content" twoCols>
                    {this.renderListItem(__('URL'), data.url)}
                </List>
            </div>
        )
    }
}

export default compose(
    connect(({system: {settings: {advertisement}}}) => ({...advertisement}))
)(Advertisement)
