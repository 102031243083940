import React from 'react'
import {compose} from 'redux'

import Page from 'ipmp-react-ui/Page'
import {ScrollView} from 'ipmp-react-ui/Layout'

import {list} from 'permissions/firmware/upgradeStatus'

import {withPermission, withRejection} from 'containers/withPermission'

import UpgradeStatusSearch from './UpgradeStatusSearch'
import UpgradeStatusTable from './UpgradeStatusTable'
import UpgradeStatusBar from './UpgradeStatusBar'
import RunnersPage from './Runners/RunnersPage'

class UpgradeStatusPage extends Page {
    renderTop() {
        return <UpgradeStatusSearch />
    }

    renderBar() {
        return <UpgradeStatusBar />
    }

    renderContent() {
        return (
            <ScrollView>
                <UpgradeStatusTable history={this.props.history} />
            </ScrollView>
        )
    }
}

const UpgradeStatus = (props) => {
    if (props.match.params.batchId) {
        return <RunnersPage {...props} />
    }

    return <UpgradeStatusPage {...props} />
}

export default compose(withPermission({isAllowed: list}), withRejection())(UpgradeStatus)
