import React, {Component} from 'react'

import {Tab} from 'ipmp-react-ui/Tabs'
import {__} from 'utils/i18n'

import Firmware from 'pages/Panel/Devices/Sidebar/Tabs/Firmware/Firmware'

class FirmwareTab extends Component {
    render() {
        return (
            <Tab name={__('Firmware')}>
                <Firmware {...this.props} />
            </Tab>
        )
    }
}

export default FirmwareTab
