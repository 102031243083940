import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {__} from 'utils/i18n'
import Input from 'ipmp-react-ui/Input'
import Select from 'ipmp-react-ui/Select'
import Autocomplete from 'ipmp-react-ui/Autocomplete'
import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchCountries} from 'modules/countries/actions'
import {fetch as fetchRoles} from 'modules/users/assignableRoles/actions'

export const AddValidation = {
    name: {
        presence: true,
        userName: true,
    },
    phone: {
        presence: true,
        phone: true,
    },
    email: {
        presence: true,
        email: true,
    },
    countryId: {
        presence: true,
    },
    roleId: {
        presence: true,
    },
    password: {
        presence: true,
        password: true,
    },
    confirmPassword: {
        noEmptyEquality: {
            attribute: 'password',
            message: () => __('Passwords do not match'),
            comparator: (v1, v2) => v1 === v2,
        },
    },
}

export const EditValidation = {
    ...AddValidation,
    password: {
        password: true,
    },
}

const CountrySelect = withSelectLoader(
    fetchCountries,
    (state) => state.countries.isLoading,
    ({countries}) =>
        Object.values(countries.byIds).map(({id, name}) => ({value: id, label: name}))
)(Autocomplete)

const RolesSelect = withSelectLoader(
    fetchRoles,
    (state) => state.users.assignableRoles.isLoading,
    ({users}) =>
        Object.values(users.assignableRoles.byIds).map(({id, name}) => ({
            value: id,
            label: name,
        }))
)(Select)

export default class UserFieldSet extends PureComponent {
    static propTypes = {
        roles: PropTypes.array,
        data: PropTypes.object,
    }

    static defaultProps = {
        data: {
            name: '',
            email: '',
            phone: '',
            countryId: '',
            roleId: '',
        },
        roles: [],
    }

    render() {
        const {data} = this.props
        return (
            <div>
                <Input
                    autoFocus
                    label={__('Full Name')}
                    name="name"
                    defaultValue={data.name}
                />

                <Input
                    label={__('Email Address')}
                    name="email"
                    defaultValue={data.email}
                />

                <Input label={__('Phone')} name="phone" defaultValue={data.phone} />

                <CountrySelect
                    label={__('Country')}
                    name="countryId"
                    defaultValue={data.countryId}
                    defaultLabel={data.countryName}
                    optional
                />

                <RolesSelect
                    label={__('Belongs to Role')}
                    name="roleId"
                    defaultValue={data.roleId}
                    defaultLabel={data.roleName}
                />

                <Input type="password" label={__('Password')} name="password" />
                <Input
                    type="password"
                    label={__('Confirm Password')}
                    name="confirmPassword"
                />
            </div>
        )
    }
}
