import React, {PureComponent} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import uniqid from 'uniqid'

import {saveSearchScope} from 'modules/settings/actions'

import ModalCardForm from 'ui/ModalCardForm'
import Input from 'ipmp-react-ui/Input'

import path from 'utils/path'
import {__} from 'utils/i18n'

class SaveSearch extends PureComponent {
    static propTypes = {
        page: PropTypes.any,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        filter: PropTypes.any,
        scopes: PropTypes.object,
        onSave: PropTypes.func.isRequired,
        hide: PropTypes.func.isRequired,
    }

    rules = {
        name: {
            presence: true,
        },
    }

    handle = ({name}) => {
        const {onSave, hide, page, history, filter} = this.props
        const scope = uniqid()

        onSave(page, scope, name, filter)
        hide()
        history.push(path(page, {scope}))
    }

    render() {
        const {hide} = this.props

        return (
            <ModalCardForm
                onSubmit={this.handle}
                hide={hide}
                header={__('Save search')}
                rules={this.rules}
            >
                <Input autoFocus name="name" maxLength={30} label={__('Name')} />
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(
        (state, {page}) => ({
            scopes: state.settings.scopes[page],
        }),
        (dispatch) => bindActionCreators({onSave: saveSearchScope}, dispatch)
    ),
    withRouter
)(SaveSearch)
