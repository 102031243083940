import {takeEvery, put, call, select} from 'redux-saga/effects'
import uniq from 'lodash-es/uniq'

import * as api from 'api/panels'
import * as actions from './actions'

export default function* () {
    yield takeEvery(actions.fetch, watchFetch)
}

function* watchFetch({payload}) {
    const panels = yield select((state) =>
        payload.map((id) => state.panels.store.byIds[id])
    )

    const groupIds = uniq(panels.filter((panel) => !!panel).map((panel) => panel.groupId))

    try {
        const rows = yield call(api.panelMaintainers, groupIds)
        yield put(actions.receive(rows))
    } catch (error) {
        yield put(actions.receive(error))
    }
}
