import {handleActions} from 'redux-actions'
import {set} from 'immutable-modify'

import {
    receive,
    update,
    login,
    loggedOut,
    setLoading,
    check,
    checked,
    setLoginScreenForm,
} from 'modules/auth/sign/actions'
import {LOGIN_SCREEN} from 'constants/loginScreen'

const defaultState = {
    loginScreenName: LOGIN_SCREEN,
}

export default handleActions(
    {
        [receive](state, {payload, error}) {
            if (error) {
                return {
                    ...state,
                    isLoading: false,
                    error: payload,
                }
            }

            return {
                ...state,
                checked: true,
                user: payload,
                error: null,
            }
        },

        [update](state, {payload}) {
            return {
                ...state,
                checked: true,
                user: {
                    ...state.user,
                    ...payload,
                },
                error: null,
            }
        },

        [check](state) {
            return {
                ...state,
                checked: false,
            }
        },

        [checked](state) {
            return {
                ...state,
                checked: true,
            }
        },

        [setLoading](state, {payload}) {
            return {
                ...state,
                isLoading: !!payload,
            }
        },

        [login](state) {
            return {
                ...state,
                isLoading: true,
            }
        },

        [loggedOut]() {
            return {
                ...defaultState,
                checked: true,
            }
        },

        [setLoginScreenForm](state, {payload}) {
            return set(state, 'loginScreenName', payload)
        },
    },
    defaultState
)
