import has from 'lodash-es/has'
import __ from 'utils/i18n'

import {warn} from 'utils/log'

export const PANEL_TYPE_UNKNOWN = 'Unknown'
export const PANEL_TYPE_PRO = 'Pro'
export const PANEL_TYPE_COMPLETE = 'Complete'
export const PANEL_TYPE_EXPRESS = 'Express'
export const PANEL_TYPE_POWER_MASTER_10 = 'PowerMaster 10'
export const PANEL_TYPE_POWER_MASTER_10_TRIPLE = 'PowerMaster 10 Triple'
export const PANEL_TYPE_POWER_MASTER_30 = 'PowerMaster 30'
export const PANEL_TYPE_POWER_G_PRO = 'PwrG-Pro'
export const PANEL_TYPE_POWER_MASTER_33 = 'PowerMaster 33'
export const PANEL_TYPE_MSP_2 = 'MSP-2'
export const PANEL_TYPE_POWER_MASTER_360 = 'PowerMaster 360'
export const PANEL_TYPE_POWER_MASTER_365 = 'PowerMaster 365'
export const PANEL_TYPE_POWER_MASTER_360R = 'PowerMaster 360R'
export const PANEL_TYPE_POWER_MASTER_33E = 'PowerMaster-33E'
export const PANEL_TYPE_NEO_1 = 'Neo 1'
export const PANEL_TYPE_HS3128 = 'HS3128'
export const PANEL_TYPE_HS2128 = 'HS2128'
export const PANEL_TYPE_HS2032 = 'HS2032'
export const PANEL_TYPE_HS2016 = 'HS2016'
export const PANEL_TYPE_HS2064 = 'HS2064'
export const PANEL_TYPE_HS3032 = 'HS3032'
export const PANEL_TYPE_HS2064E = 'HS2064E'
export const PANEL_TYPE_HS2128E = 'HS2128E'
export const PANEL_TYPE_HS3248 = 'HS3248'
export const PANEL_TYPE_FIBRO_TRANSMITTER = 'Fibro transmitter'
export const PANEL_TYPE_BGS220KIT = 'BGS220KIT' // DualPath
export const PANEL_TYPE_BGS220BOX = 'BGS220BOX' // DualPath
export const PANEL_TYPE_BLE320 = 'BLE320' // DualPath
export const PANEL_TYPE_BGS210 = 'BGS210' // DualPath
export const PANEL_TYPE_PG_BOX = 'PGBox'
export const PANEL_TYPE_IQ2 = 'IQ2'
export const PANEL_TYPE_IQ4 = 'IQ4'
export const PANEL_TYPE_QOLSYS = 'Qolsys'

const images = {
    [PANEL_TYPE_PRO]: require('assets/panels/powermax-pro.svg'),
    [PANEL_TYPE_POWER_G_PRO]: require('assets/panels/powermax-pro.svg'),
    [PANEL_TYPE_COMPLETE]: require('assets/panels/powermaster-30.svg'),
    [PANEL_TYPE_POWER_MASTER_10]: require('assets/panels/powermaster-10.svg'),
    [PANEL_TYPE_POWER_MASTER_10_TRIPLE]: require('assets/panels/powermaster-10.svg'),
    [PANEL_TYPE_EXPRESS]: require('assets/panels/powermaster-10.svg'),
    [PANEL_TYPE_POWER_MASTER_30]: require('assets/panels/powermaster-30.svg'),
    [PANEL_TYPE_POWER_MASTER_33]: require('assets/panels/powermaster-33E.svg'),
    [PANEL_TYPE_POWER_MASTER_33E]: require('assets/panels/powermaster-33E.svg'),
    [PANEL_TYPE_POWER_MASTER_360]: require('assets/panels/powermaster-360.svg'),
    [PANEL_TYPE_POWER_MASTER_360R]: require('assets/panels/powermaster-360R.svg'),
    [PANEL_TYPE_NEO_1]: require('assets/panels/neo-1.svg'),
    [PANEL_TYPE_HS3128]: require('assets/panels/neo-1.svg'),
    [PANEL_TYPE_HS2128]: require('assets/panels/neo-1.svg'),
    [PANEL_TYPE_HS2032]: require('assets/panels/neo-1.svg'),
    [PANEL_TYPE_HS2016]: require('assets/panels/neo-1.svg'),
    [PANEL_TYPE_HS2064]: require('assets/panels/neo-1.svg'),
    [PANEL_TYPE_HS3032]: require('assets/panels/neo-1.svg'),
    [PANEL_TYPE_HS2064E]: require('assets/panels/neo-1.svg'),
    [PANEL_TYPE_HS2128E]: require('assets/panels/neo-1.svg'),
    [PANEL_TYPE_HS3248]: require('assets/panels/neo-1.svg'),
    [PANEL_TYPE_UNKNOWN]: require('assets/panels/unknown.svg'),
    [PANEL_TYPE_MSP_2]: require('assets/panels/unknown.svg'),
    [PANEL_TYPE_BGS220KIT]: require('assets/panels/dualpath.svg'),
    [PANEL_TYPE_BGS220BOX]: require('assets/panels/dualpath.svg'),
    [PANEL_TYPE_BLE320]: require('assets/panels/dualpath.svg'),
    [PANEL_TYPE_BGS210]: require('assets/panels/dualpath.svg'),
    [PANEL_TYPE_PG_BOX]: require('assets/panels/virtual-panel.svg'),
    [PANEL_TYPE_QOLSYS]: require('assets/panels/IQ2.svg'),
    [PANEL_TYPE_IQ2]: require('assets/panels/IQ2.svg'),
    [PANEL_TYPE_IQ4]: require('assets/panels/IQ4.svg'),
}

export function panelName(model) {
    switch (model) {
        case PANEL_TYPE_PG_BOX:
            return __('Virtual Panel')
        default:
            return model
    }
}

export function panelImage(model) {
    if (has(images, model)) {
        return images[model]
    }

    warn(`Unknown panel model ${model}`)

    return require('assets/panels/unknown.svg')
}
