import React, {Component} from 'react'
import PropTypes from 'prop-types'

import list from 'permissions/panel/devices/list'

import {withPermission} from 'containers/withPermission'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Buttons from 'ipmp-react-ui/Buttons'
import Checkbox from 'ipmp-react-ui/Checkbox'

import RefreshRssiButton from './Buttons/RefreshRssiButton'
import RefreshGsmButton from './Buttons/RefreshGSMButton'
import AddDeviceButton from './Buttons/AddDeviceButton'
import ToggleWalktestButton from './Buttons/ToggleWalktestButton'

import {__} from 'utils/i18n'

class DefaultDevicesBar extends Component {
    static propTypes = {
        onToggleFaults: PropTypes.func.isRequired,
        filterTroubles: PropTypes.bool,
        onToggleNullZones: PropTypes.func.isRequired,
        hideNullZones: PropTypes.bool,
        hasList: PropTypes.bool,
    }

    render() {
        const {
            onToggleFaults,
            onToggleNullZones,
            hasList,
            filterTroubles,
            hideNullZones,
        } = this.props
        return (
            <Bar className="devices-bar">
                {hasList && (
                    <Checkbox
                        onChange={onToggleFaults}
                        checked={filterTroubles}
                        label={__('Only trouble devices')}
                    />
                )}
                {hasList && (
                    <Checkbox
                        onChange={onToggleNullZones}
                        checked={hideNullZones}
                        label={__('Hide null devices')}
                    />
                )}

                <BarSpace />

                <Buttons>
                    <RefreshGsmButton />
                    <RefreshRssiButton />
                    <AddDeviceButton />
                    <ToggleWalktestButton />
                </Buttons>
            </Bar>
        )
    }
}

export default withPermission({
    hasList: list,
})(DefaultDevicesBar)
