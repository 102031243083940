import {connect} from 'react-redux'
import {ActionCell} from 'ui/TableCells'

import {markAsViewed} from 'modules/events/one/actions'
import {ReactComponent as MarkViewedIcon} from 'ipmp-react-ui/icons/viewed.svg'

export default connect(
    (state, {row}) => ({
        Icon: MarkViewedIcon,
        isChecked: row && row.isViewed,
    }),
    (dispatch, {row}) => ({
        onClick: () => dispatch(markAsViewed(row)),
    })
)(ActionCell)
