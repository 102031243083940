import {__} from 'utils/i18n'
import {warn} from 'utils/log'

const TEMPERATURE = 'temperature'
const BRIGHTNESS = 'light'
const GSM_RSSI = 'gsm_rssi'

export default function getMeteoName(type) {
    switch (type) {
        case TEMPERATURE:
            return __('Temperature')
        case BRIGHTNESS:
            return __('Brightness')
        case GSM_RSSI:
            return __('GSM RSSI')
    }

    warn(`Unknown meteo metric ${type}`)

    return type
}
