import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'
import get from 'lodash/get'

import {
    MODULE_TYPE_GPRS,
    MODULE_TYPE_BBA,
    MODULE_TYPE_WIFI,
    MODULE_TYPE_BBA_OVER_GPRS,
} from 'constants/moduleTypes'

import {panelImage, panelName} from 'constants/panelType'
import TroublesList from 'components/Devices/TroublesList'
import DeviceModule, {DeviceModules} from './DeviceModule'

export default class Panel extends Component {
    static propTypes = {
        panel: PropTypes.shape({
            serial: PropTypes.string,
            modules: PropTypes.objectOf(
                PropTypes.shape({
                    connected: PropTypes.bool,
                    state: PropTypes.string,
                })
            ).isRequired,
            info: PropTypes.shape({
                model: PropTypes.string,
            }).isRequired,
        }).isRequired,
        onSelect: PropTypes.func,
        active: PropTypes.bool,
        className: PropTypes.string,
    }

    getRssiInfo = () => {
        const {panel} = this.props

        return get(panel, 'rssi')
    }

    getModuleInfo(type) {
        const {
            panel: {modules},
        } = this.props

        if (!modules) {
            return null
        }

        return get(modules, type, null)
    }

    renderModules() {
        const {
            panel: {modules},
        } = this.props

        if (!modules) {
            return null
        }

        return (
            <DeviceModules>
                <DeviceModule
                    moduleType={MODULE_TYPE_BBA}
                    module={this.getModuleInfo(MODULE_TYPE_BBA)}
                />

                <DeviceModule
                    moduleType={MODULE_TYPE_BBA_OVER_GPRS}
                    module={this.getModuleInfo(MODULE_TYPE_BBA_OVER_GPRS)}
                    rssiInfo={this.getRssiInfo()}
                />

                <DeviceModule
                    moduleType={MODULE_TYPE_GPRS}
                    module={this.getModuleInfo(MODULE_TYPE_GPRS)}
                    rssiInfo={this.getRssiInfo()}
                />

                <DeviceModule
                    moduleType={MODULE_TYPE_WIFI}
                    module={this.getModuleInfo(MODULE_TYPE_WIFI)}
                />
            </DeviceModules>
        )
    }

    render() {
        const {panel, onSelect, active, className} = this.props
        const {info, serial, warnings} = panel
        const image = panelImage(info && info.model)

        return (
            <div
                className={classes('device device--panel', className, {
                    'device--active': active,
                })}
                onClick={onSelect}
            >
                <div className="device-image">
                    <div
                        className="device-image-src"
                        style={{backgroundImage: `url(${image})`}}
                    />
                </div>

                <div className="device-content">
                    <div className="hint device-hint">
                        <div className="device-hint-content">{serial}</div>
                    </div>

                    <div className="device-title">
                        {info && info.model ? panelName(info.model) : null}
                    </div>

                    <TroublesList warnings={warnings} />
                </div>

                {this.renderModules()}
            </div>
        )
    }
}
