import {Component} from 'react'
import PropTypes from 'prop-types'

export default class Link extends Component {
    static propTypes = {
        server: PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string.isRequired,
        }).isRequired,
        unlink: PropTypes.func.isRequired,
        link: PropTypes.func.isRequired,
    }

    componentDidMount() {
        this.props.link(this.props.server)
    }

    componentWillUnmount() {
        this.props.unlink(this.props.server)
    }

    shouldComponentUpdate() {
        return false
    }

    render() {
        return null
    }
}
