import React from 'react'

import * as keys from 'utils/keypad/keys'

import Tooltip from 'ipmp-react-ui/Tooltip'
import {ReactComponent as IconKeypadLock} from 'ipmp-react-ui/icons/keypad/keypad-lock.svg'
import {ReactComponent as IconArrowUp} from 'ipmp-react-ui/icons/arrow-up.svg'
import {ReactComponent as IconArrowPrev} from 'ipmp-react-ui/icons/arrow-prev.svg'
import {ReactComponent as IconArrowNext} from 'ipmp-react-ui/icons/arrow-next.svg'
import {ReactComponent as IconDisarm} from 'ipmp-react-ui/icons/keypad/keypad-unlock.svg'
import {ReactComponent as IconHome} from 'ipmp-react-ui/icons/keypad/keypad-home.svg'
import {ReactComponent as IconEmergency} from 'ipmp-react-ui/icons/keypad/keypad-emergency.svg'
import {ReactComponent as IconPanic} from 'ipmp-react-ui/icons/keypad/keypad-alarm.svg'
import {ReactComponent as IconFire} from 'ipmp-react-ui/icons/worries/fire.svg'
import {ReactComponent as IconStay} from 'ipmp-react-ui/icons/keypad/keypad-stay.svg'
import {ReactComponent as IconAway} from 'ipmp-react-ui/icons/keypad/keypad-away.svg'
import {ReactComponent as IconChime} from 'ipmp-react-ui/icons/alert-off.svg'
import {ReactComponent as IconReset} from 'ipmp-react-ui/icons/refresh.svg'
import {ReactComponent as IconExit} from 'ipmp-react-ui/icons/logout.svg'
import {__} from 'utils/i18n'

const commonOrderedDigits = [
    {keyCode: keys.KEY_1, digit: () => 1},
    {keyCode: keys.KEY_2, digit: () => 2},
    {keyCode: keys.KEY_3, digit: () => 3},
    {keyCode: keys.KEY_4, digit: () => 4},
    {keyCode: keys.KEY_5, digit: () => 5},
    {keyCode: keys.KEY_6, digit: () => 6},
    {keyCode: keys.KEY_7, digit: () => 7},
    {keyCode: keys.KEY_8, digit: () => 8},
    {keyCode: keys.KEY_9, digit: () => 9},
    {keyCode: keys.KEY_STAR, digit: () => '*'},
    {keyCode: keys.KEY_0, digit: () => '0'},
    {keyCode: keys.KEY_HASH, digit: () => '#'},
]

export const pmaxDigits = [
    {
        keyCode: keys.KEY_OFF,
        digit: () => <Tooltip tooltip={__('Clear')}>{'OFF'}</Tooltip>,
    },
    {
        keyCode: keys.KEY_UP,
        digit: () => (
            <Tooltip tooltip={__('Level Up')}>
                <IconArrowUp />
            </Tooltip>
        ),
    },
    {
        keyCode: keys.KEY_ESC,
        digit: () => <Tooltip tooltip={__('Exit Menu')}>{'ESC'}</Tooltip>,
    },
    {keyCode: keys.KEY_LEFT, digit: () => <IconArrowPrev />},
    {keyCode: keys.KEY_OK, digit: () => __('OK')},
    {keyCode: keys.KEY_RIGHT, digit: () => <IconArrowNext />},
    ...commonOrderedDigits,
    {keyCode: keys.KEY_DISARM, digit: () => <IconDisarm />},
    {keyCode: keys.KEY_ARM_HOME, digit: () => <IconHome />},
    {keyCode: keys.KEY_ARM_AWAY, digit: () => <IconKeypadLock />},
    {
        keyCode: keys.KEY_EMERGENCY,
        digit: () => (
            <Tooltip tooltip={__('Emergency')}>
                <IconEmergency />
            </Tooltip>
        ),
    },
    {
        keyCode: keys.KEY_PANIC,
        digit: () => (
            <Tooltip tooltip={__('Panic')}>
                <IconPanic />
            </Tooltip>
        ),
    },
    {
        keyCode: keys.KEY_FIRE,
        digit: () => (
            <Tooltip tooltip={() => __('Fire Alarm')}>
                <IconFire />
            </Tooltip>
        ),
    },
]

export const neoDigits = [
    {keyCode: keys.KEY_LEFT, digit: () => <IconArrowPrev />},
    {keyCode: keys.KEY_RIGHT, digit: () => <IconArrowNext />},
    ...commonOrderedDigits,
    {
        keyCode: keys.KEY_EMERGENCY,
        digit: () => (
            <Tooltip tooltip={__('Emergency')}>
                <IconEmergency />
            </Tooltip>
        ),
    },
    {
        keyCode: keys.KEY_PANIC,
        digit: () => (
            <Tooltip tooltip={__('Panic')}>
                <IconPanic />
            </Tooltip>
        ),
    },
    {
        keyCode: keys.KEY_FIRE,
        digit: () => (
            <Tooltip tooltip={__('Fire Alarm')}>
                <IconFire />
            </Tooltip>
        ),
    },

    {
        keyCode: keys.KEY_STAY,
        digit: () => (
            <Tooltip tooltip={__('Stay')}>
                <IconStay />
            </Tooltip>
        ),
    },
    {
        keyCode: keys.KEY_AWAY,
        digit: () => (
            <Tooltip tooltip={__('Away')}>
                <IconAway />
            </Tooltip>
        ),
    },
    {
        keyCode: keys.KEY_CHIME,
        digit: () => (
            <Tooltip tooltip={__('Chime')}>
                <IconChime />
            </Tooltip>
        ),
    },
    {
        keyCode: keys.KEY_RESET,
        digit: () => (
            <Tooltip tooltip={__('Reset')}>
                <IconReset />
            </Tooltip>
        ),
    },
    {
        keyCode: keys.KEY_EXIT,
        digit: () => (
            <Tooltip tooltip={__('Exit')}>
                <IconExit />
            </Tooltip>
        ),
    },
]
