import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import CardModalError from 'ipmp-react-ui/CardModalError'
import Modal from 'ipmp-react-ui/Modal'
import Bar, {CENTER} from 'ipmp-react-ui/Bar'
import Button from 'ipmp-react-ui/Button'
import Card, {CardActions, CardHeader, CardContent, CardClose} from 'ipmp-react-ui/Card'

import __, {__n} from 'utils/i18n'

import PanelsTable from './UnavailableFeature/PanelsTable'

export default class UnavailableFeature extends Component {
    static propTypes = {
        message: PropTypes.func,
        hide: PropTypes.func.isRequired,
        unavailablePanels: PropTypes.array.isRequired,
        availableIds: PropTypes.array.isRequired,
        action: PropTypes.func.isRequired,
    }

    static defaultProps = {
        message: () => __('Operation is not supported'),
    }

    state = {
        isTableVisible: false,
    }

    proceed = () => {
        const {action, hide, availableIds} = this.props

        // This order important, becouse if `action` open modal(Create Report),
        // we need close current modal, and not close modal,
        // that call's `action`
        hide()
        action(availableIds)
    }

    showTable = () => this.setState({isTableVisible: true})

    render() {
        const {message, hide, unavailablePanels, availableIds} = this.props
        const {isTableVisible} = this.state
        const couldProceed = availableIds.length > 0

        const className = classnames({
            'card--wide': couldProceed,
        })

        if (!couldProceed) {
            return (
                <Modal onClose={hide}>
                    <CardModalError title={message()} onClose={hide} />
                </Modal>
            )
        }

        return (
            <Modal onClose={hide}>
                <Card className={className}>
                    <CardHeader>
                        {message()}
                        <CardClose onClick={hide} />
                    </CardHeader>

                    <CardContent>
                        <p>
                            {__n(
                                'Do you want to continue operation without %d panel?',
                                'Do you want to continue operation without %d panels?',
                                unavailablePanels.length
                            )}
                        </p>
                    </CardContent>

                    {!isTableVisible && (
                        <Bar orientation={CENTER}>
                            <Button small flat onClick={this.showTable}>
                                {__('Show unavailable panels')}
                            </Button>
                        </Bar>
                    )}

                    {isTableVisible && (
                        <PanelsTable
                            fullHeight
                            className="modal-table"
                            rows={unavailablePanels}
                        />
                    )}

                    <CardActions>
                        <Button
                            borderless
                            shortcut={'esc'}
                            onClick={hide}
                            label={__('Dismiss')}
                        />

                        <Button primary onClick={this.proceed}>
                            {__('Continue')}
                        </Button>
                    </CardActions>
                </Card>
            </Modal>
        )
    }
}
