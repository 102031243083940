import {compose} from 'redux'

import withLoader from 'containers/withLoader'
import withEventRules from 'containers/withEventRules'
import withProps from 'containers/withProps'
import withLifeCycle from 'containers/withLifeCycle'

import Table from 'ipmp-react-ui/Table'
import {__} from 'utils/i18n'

import RemoveEventRulesButton from './TableCell/RemoveEventRulesButton'
import EditEventRuleCell from './TableCell/EditEventRuleCell'
import ZoneTypeCell from './TableCell/ZoneTypeCell'
import NotificationTypeCell from './TableCell/NotificationTypeCell'
import StateTypeCell from './TableCell/StateTypeCell'
import EventTypeCell from './TableCell/EventTypeCell'

const columns = [
    {
        name: () => __('Zone Type'),
        width: 218,
        fixed: true,
        render: () => __('When IoT Device with zone type'),
    },
    {
        width: 160,
        fixed: true,
        component: ZoneTypeCell,
    },
    {
        name: () => __('Notification'),
        width: 126,
        fixed: true,
        render: () => __('sends notification'),
    },
    {
        width: 160,
        fixed: true,
        component: NotificationTypeCell,
    },
    {
        name: () => __('State'),
        fixed: true,
        width: 108,
        render: () => __('and VP in state'),
    },
    {
        width: 160,
        fixed: true,
        component: StateTypeCell,
    },
    {
        name: () => __('Event'),
        width: 114,
        fixed: true,
        render: () => __('then send event'),
    },
    {
        width: 160,
        fixed: true,
        component: EventTypeCell,
    },
    {
        tooltip: false,
        width: 44,
        fixed: true,
        component: EditEventRuleCell,
    },
    {
        tooltip: false,
        width: 48,
        fixed: true,
        component: RemoveEventRulesButton,
    },
]

export default compose(
    withEventRules(),
    withLifeCycle({
        onMount({init, startPoll}) {
            init()
            startPoll()
        },

        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withLoader(({fetch}) => fetch()),
    withProps({
        columns,
        fullHeight: true,
        emptyMessage: () => __('No event rules found'),
        className: 'eventRule-table',
    })
)(Table)
