import {__} from 'utils/i18n'

import * as Generic from './Generic'

export class Fieldset extends Generic.Fieldset {}

export const rules = {
    ...Generic.rules,
    serial: {
        presence: true,
        format: {
            pattern: '[a-f0-9]{8}',
            flags: 'i',
            message: () => __('Must be a 8 digit hexadecimal'),
        },
    },
    account: {
        presence: true,
        format: {
            pattern: '[a-f0-9]{4}',
            flags: 'i',
            message: () => __('Must be a 4 digit hexadecimal'),
        },
    },
}
