import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {InitiateRemoteInspectionButton} from 'pages/RemoteInspection/Bar/InitiateRemoteInspectionButton'
import withRemoteInspections from 'containers/withRemoteInspections'
import withProps from 'containers/withProps'
import {initiateNow} from 'modules/runners/store/actions'
import {__} from 'utils/i18n'
import {withSelectionFeature} from 'containers/withFeature'
import {REMOTE_INSPECTION} from 'constants/features'
import {selectSelectedRunnersFeature} from 'modules/panels/store/selectors'

export default compose(
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                initiateNowProxy: initiateNow,
            },
            dispatch
        )
    ),
    withRemoteInspections(),
    withProps(({initiateNowProxy}) => ({
        onClick: initiateNowProxy,
        label: __('Run inspection'),
    })),
    withSelectionFeature(REMOTE_INSPECTION, selectSelectedRunnersFeature)
)(InitiateRemoteInspectionButton)
