import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import withPerPage from 'containers/withPerPage'
import {init, fetch, resetWithOutFetch} from 'modules/installerPanels/list/actions'
import {
    init as addPanelListInit,
    fetch as addPanelListFetch,
    setQuery,
    setStart,
} from 'modules/installerPanels/addPanelList/actions'
import {showAssignInstallerToPanelModal} from 'modules/modals/actions'
import {selectInstallerPanelsList} from 'modules/installerPanels/list/selectors'
import {selectInstallerAddPanelList} from 'modules/installerPanels/addPanelList/selectors'

export default function withInstallerPanelsTable() {
    return compose(
        withPerPage('installerPanels'),
        connect(selectInstallerPanelsList, (dispatch, {installer: {id}}) =>
            bindActionCreators(
                {
                    init: () => init(id),
                    fetch: () => fetch(id),
                    resetWithOutFetch,
                },
                dispatch
            )
        )
    )
}

export function withInstallerAddPanelTable() {
    return compose(
        withPerPage('installerAddPanel'),
        connect(selectInstallerAddPanelList, (dispatch) =>
            bindActionCreators(
                {
                    init: addPanelListInit,
                    fetch: addPanelListFetch,
                    setQuery,
                    setStart,
                    showAssignInstallerToPanelModal,
                },
                dispatch
            )
        )
    )
}
