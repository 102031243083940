import dataMapper from 'utils/dataMapper'

export const keysMap = {
    id: 'id',
    name: 'name',
    idOnPanel: 'id_on_panel',
}

export const mapNotificationTypes = dataMapper((data) => ({
    id: data.id,
    name: data.name,
    idOnPanel: data.id_on_panel,
}))
