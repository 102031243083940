import createListActions from 'modules/higherOrder/createListActions'
import {PROCESS_TYPE_PMAXRESETRECEIVERIP} from 'constants/processTypes'

export const {
    init,
    receive,
    reset,
    setStart,
    setQuery,
    setFilters,
    clearFilters,
    addFilters,
    removeFilters,
    fetch,
    fetchSuggests,
    receiveSuggests,
    startPoll,
    stopPoll,
    purgeList,
    remove,
    revertRemove,
} = createListActions('PANELS/LIST', {
    removeProcessType: PROCESS_TYPE_PMAXRESETRECEIVERIP,
})
