import withProps from 'containers/withProps'
import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import Modal from 'ipmp-react-ui/Modal'
import Portal from 'ipmp-react-ui/Portal'

import getHocName from 'utils/getHocName'
import CardMessage from 'ipmp-react-ui/CardMessage'
import {__} from 'utils/i18n'
import stopPropagation from 'utils/stopPropagation'

export default function withConfirmation(action: ?(Object) => any) {
    return (WrappedComponent) =>
        class extends PureComponent {
            static displayName = getHocName('WithConfirmation', WrappedComponent)

            static propTypes = {
                isConfirmationEnabled: PropTypes.bool,
                title: PropTypes.string,
                message: PropTypes.string,
                positive: PropTypes.string,
                negative: PropTypes.string,
                onClick: PropTypes.func,
            }

            static defaultProps = {
                isConfirmationEnabled: true,
            }

            state = {}

            handleClick = (e) => {
                e.stopPropagation()

                if (this.props.isConfirmationEnabled) {
                    this.setState({active: true})
                } else {
                    this.agree(e)
                }
            }

            dismiss = (e) => {
                e.stopPropagation()
                this.setState({active: false})
            }

            agree = (e) => {
                e.stopPropagation()

                if (action) {
                    action(this.props)
                } else {
                    this.props.onClick()
                }

                this.setState({active: false})
            }

            render() {
                const {title, message, positive, negative, ...props} = this.props

                delete props.isConfirmationEnabled
                delete props.confirmOnDismiss

                const component = (
                    <WrappedComponent {...props} onClick={this.handleClick} key="" />
                )

                if (!this.state.active) {
                    return component
                }

                return [
                    component,
                    <Portal onClick={stopPropagation} key="portal">
                        <Modal onClose={this.dismiss}>
                            <CardMessage
                                className="card--narrow"
                                title={title}
                                message={message}
                                positive={positive}
                                negative={negative}
                                onPositive={this.agree}
                                onNegative={this.dismiss}
                                onClose={this.dismiss}
                            />
                        </Modal>
                    </Portal>,
                ]
            }
        }
}

export function withCloseConfirmation() {
    return compose(
        withProps(({confirmOnDismiss, title, message, positive, negative}) => ({
            title: title || __('Closing form'),
            message: message || __("All data will be lost. Do you wan't to proceed?"),
            positive: positive || __('Confirm'),
            negative: negative || __('Cancel'),
            isConfirmationEnabled: confirmOnDismiss,
        })),
        withConfirmation()
    )
}
