import {compose} from 'redux'

import withEventRules from 'containers/withEventRules'

import Pager from 'ipmp-react-ui/Pager'
import React, {Component} from 'react'
import PropTypes from 'prop-types'

class EventRulesPager extends Component {
    static propTypes = {
        start: PropTypes.number,
        total: PropTypes.number,
        perPage: PropTypes.number,
        setFrontEndStart: PropTypes.func,
        onPerPageChange: PropTypes.func,
    }

    render() {
        const {start, total, perPage, setFrontEndStart, onPerPageChange} = this.props

        return (
            <Pager
                start={start}
                total={total}
                perPage={perPage}
                onPageChange={setFrontEndStart}
                onPerPageChange={onPerPageChange}
            />
        )
    }
}
export default compose(withEventRules())(EventRulesPager)
