import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconSimCard} from 'ipmp-react-ui/icons/sim-card.svg'
import {ReactComponent as IconEthernet} from 'ipmp-react-ui/icons/ethernet.svg'

import Definition from 'ipmp-react-ui/Definition'

export default class CommunicationCell extends Component {
    static propTypes = {
        row: PropTypes.shape({
            host: PropTypes.string,
            port: PropTypes.string,
            device: PropTypes.string,
        }),
    }

    renderSerial() {
        const {
            row: {device},
        } = this.props

        return <Definition Icon={IconSimCard} title={device} />
    }

    renderTcp() {
        const {
            row: {host, port},
        } = this.props

        return <Definition Icon={IconEthernet} title={host} detail={port} />
    }

    render() {
        const {
            row: {device},
        } = this.props

        if (device) {
            return this.renderSerial()
        }

        return this.renderTcp()
    }
}
