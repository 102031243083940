import {isMoment} from 'moment'

export default function momentPropType(props, propName, componentName) {
    if (isMoment(props[propName]) && !props[propName].isValid()) {
        return new Error(
            'Failed prop type: The prop `' +
                propName +
                '` supplied to' +
                ' `' +
                componentName +
                '` should be valid moment object, but its value is' +
                ' `' +
                props[propName] +
                '`'
        )
    }
}

momentPropType.isRequired = (props, propName, componentName) => {
    if (!props[propName]) {
        return new Error(
            `Failed prop type: The prop \`${propName}\` is marked as required in \`${componentName}\`,
            but its value is \`${props[propName]}\``
        )
    }

    return momentPropType(props, propName, componentName)
}
