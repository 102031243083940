import {ListHintItem} from 'ipmp-react-ui/List'
import React, {Component} from 'react'
import {__} from 'utils/i18n'

export class SettingsPageBlock extends Component {
    // Abstract class
    convertValue(value) {
        switch (typeof value) {
            case 'undefined':
                return __('not set')
            case 'boolean':
                return value ? __('Enabled') : __('Disabled')
            default:
                return value
        }
    }

    renderListItem(title, value) {
        return <ListHintItem name={__(title)} text={this.convertValue(value)} />
    }
}
