import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import has from 'lodash-es/has'

import Page from 'ipmp-react-ui/Page'

import {withLocationChange} from 'containers/withLocationChange'
import ActivatePanelInteractiveBar from 'pages/Panel/InteractiveBar/ActivatePanelInteractiveBar'
import ConfigurationChangesInteractiveBar from 'pages/Panel/InteractiveBar/ConfigurationChangesInteractiveBar'
import MakeBasicConfigurationInteractiveBar from 'pages/Panel/InteractiveBar/MakeBasicConfigurationInteractiveBar'
import {content} from 'permissions/panel/page'
import * as tabs from 'permissions/panel/navigation'
import page from 'permissions/interactiveUsers/page'

import withPanel, {withRouterPanelId} from 'containers/withPanel'
import withLoader from 'containers/withLoader'
import {withPermission, withRejection} from 'containers/withPermission'
import withLifeCycle from 'containers/withLifeCycle'
import withFeatures from 'containers/withFeature'
import withPanelPage from 'containers/withPanelPage'

import PanelPageTop from './PanelPageTop'
import PanelPageBar from './PanelPageBar'
import PanelPageContent from './PanelPageContent'
import PanelRemarks from './PanelRemarks'

class PanelPage extends Page {
    static propTypes = {
        isDiscoveryApplicable: PropTypes.bool,
        items: PropTypes.object,
    }

    state = {
        isOpenedRemarks: false,
    }

    openRemarks = () => this.setState({isOpenedRemarks: true})

    closeRemarks = () => this.setState({isOpenedRemarks: false})

    renderTop() {
        return <PanelPageTop openRemarks={this.openRemarks} {...this.props} />
    }

    renderBar() {
        return <PanelPageBar menu={this.props.items} />
    }

    renderContent() {
        const {panel, items} = this.props

        if (Object.keys(items).length === 0) {
            return null
        }

        return (
            <Fragment>
                <PanelPageContent routes={items} />

                <PanelRemarks
                    panelId={panel.id}
                    opened={this.state.isOpenedRemarks}
                    onClose={this.closeRemarks}
                />
            </Fragment>
        )
    }

    get hasActivation() {
        return has(this.props.panel, 'isActivated')
    }

    renderInteractiveBar() {
        const {id, isActivated} = this.props.panel

        if (this.hasActivation && !isActivated) {
            return [<ActivatePanelInteractiveBar id={id} key="activate-panel" />]
        }

        return [
            <ConfigurationChangesInteractiveBar key="configuration" />,
            <MakeBasicConfigurationInteractiveBar key="make-basic-configuration" />,
        ]
    }
}

export default compose(
    withPermission({
        isAllowed: content,
        allowedItems: {
            ...tabs,
        },
        isInteractiveUsersPagePermitted: page,
    }),
    withRejection(),
    withPanel(),
    withRouterPanelId(),
    withFeatures(),
    withPanelPage(),
    withLoader(
        ({
            fetch,
            fetchFeatures,
            fetchFirmware,
            fetchPanelInteractiveUsers,
            panelId,
            isPanelFeatures,
            isInteractiveUsers,
            isInteractiveUsersPagePermitted,
        }) => {
            !isPanelFeatures && fetchFeatures(panelId)
            if (isInteractiveUsersPagePermitted) {
                !isInteractiveUsers && fetchPanelInteractiveUsers()
            }
            fetchFirmware()
            fetch()
        }
    ),
    withLocationChange(({location, match}, {remember, masterUserToRemember}) => {
        remember({path: location.pathname, masterUserToRemember})
    }),
    withLifeCycle({
        onMount({startPollPanelData}) {
            startPollPanelData()
        },

        onUnmount({stopPollPanelData}) {
            stopPollPanelData()
        },
    })
)(PanelPage)
