import {__} from 'utils/i18n'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Checkbox from 'ipmp-react-ui/Checkbox'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import isEmpty from 'lodash-es/isEmpty'
import get from 'lodash-es/get'

import {withPermission} from 'containers/withPermission'
import {bypass} from 'permissions/panel/devices/actions'
import {setBypass} from 'modules/devices/list/actions'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'

export class BypassCheckbox extends Component {
    static propTypes = {
        handleChange: PropTypes.func.isRequired,
        isPermitted: PropTypes.bool,
        device: PropTypes.shape({
            id: PropTypes.number.isRequired,
            traits: PropTypes.shape({
                bypass: PropTypes.shape({
                    progress: PropTypes.bool,
                    enabled: PropTypes.bool,
                }),
            }),
        }).isRequired,
        panelId: PropTypes.number.isRequired,
    }

    static defaultProps = {
        isPermitted: true,
    }

    handleChange = (e) => this.props.handleChange(e.target.checked)

    render() {
        const data = get(this.props, ['device', 'traits', 'bypass'], {})

        if (!Object.entries(data).length) {
            return null
        }

        return (
            <Checkbox
                disabled={!this.props.isPermitted}
                onChange={this.handleChange}
                label={__('Bypass')}
                progress={data.progress}
                checked={data.enabled}
            />
        )
    }
}

export default compose(
    withPermission(bypass),
    connect(null, (dispatch, {panelId, device}) =>
        bindActionCreators(
            {
                handleChange: (enabled) => setBypass(panelId, {[device.id]: enabled}),
            },
            dispatch
        )
    ),
    withProps(({device}) => ({
        isVisible: !isEmpty(get(device, ['traits', 'bypass'], {})),
    })),
    withVisibility()
)(BypassCheckbox)
