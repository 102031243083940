import React, {Component} from 'react'
import PropTypes from 'prop-types'

import DoubleBlindModal, {DoubleBlindModalEntry} from 'ipmp-react-ui/DoubleBlindModal'

import InstallerInfo from './BlindContent/InstallerInfo'
import InstallerAddPanel from './BlindContent/InstallerAddPanel'

export default class InstallerBlind extends Component {
    static propTypes = {
        row: PropTypes.object,
        onClose: PropTypes.func.isRequired,
    }

    state = {
        isAddPanelOpened: false,
    }

    get isInstallerPanelsOpened() {
        return Boolean(this.props.row)
    }

    onClose = () => this.props.onClose()

    onAddPanel = () => this.setState({isAddPanelOpened: true})

    onCloseAddPanel = () => this.setState({isAddPanelOpened: false})

    render() {
        const {row: installer} = this.props
        const {isAddPanelOpened} = this.state

        return (
            <DoubleBlindModal>
                <DoubleBlindModalEntry
                    opened={this.isInstallerPanelsOpened}
                    onClose={this.onClose}
                >
                    <InstallerInfo installer={installer} onAddPanel={this.onAddPanel} />
                </DoubleBlindModalEntry>
                <DoubleBlindModalEntry
                    opened={isAddPanelOpened}
                    onClose={this.onCloseAddPanel}
                >
                    <InstallerAddPanel
                        installer={installer}
                        onClose={this.onCloseAddPanel}
                    />
                </DoubleBlindModalEntry>
            </DoubleBlindModal>
        )
    }
}
