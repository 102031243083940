import React from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'
import {__} from 'utils/i18n'

export default class Account extends React.Component {
    static propTypes = {
        isNew: PropTypes.bool,
        account: PropTypes.string,
    }

    render() {
        const {isNew, account} = this.props

        return (
            <Input
                readOnly={!isNew}
                label={__('Account')}
                name="account"
                defaultValue={account}
            />
        )
    }
}
