import {__} from 'utils/i18n'

export const PANEL_STATUS_CONNECTED = 'connected'
export const PANEL_STATUS_ONLINE = 'online'
export const PANEL_STATUS_OFFLINE = 'offline'
export const PANEL_STATUS_INSTALLED = 'installed'
export const PANEL_STATUS_UNKNOWN = 'unknown'

export function panelStatusName(status) {
    switch (status) {
        case PANEL_STATUS_CONNECTED:
            return __('Connected')
        case PANEL_STATUS_ONLINE:
            return __('Online')
        case PANEL_STATUS_OFFLINE:
            return __('Offline')
        case PANEL_STATUS_INSTALLED:
            return __('Installed')
        case PANEL_STATUS_UNKNOWN:
        default:
            return __('Unknown')
    }
}

const statusWeight = (status) => {
    switch (status) {
        case PANEL_STATUS_CONNECTED:
            return 10000
        case PANEL_STATUS_ONLINE:
            return 1000
        case PANEL_STATUS_OFFLINE:
            return 100
        case PANEL_STATUS_INSTALLED:
            return 10
        case PANEL_STATUS_UNKNOWN:
        default:
            return 1
    }
}

export function selectMaxStatus(statuses) {
    return statuses.reduce((max, byModule) => {
        if (statusWeight(byModule) > statusWeight(max)) {
            return byModule
        }

        return max
    }, PANEL_STATUS_UNKNOWN)
}
