import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import ResultsRow from './Results/Row'

export default class RemoteInspectionResults extends PureComponent {
    static propTypes = {
        remoteInspectionResult: PropTypes.object.isRequired,
    }

    renderResultRowNodes() {
        const {remoteInspectionResult} = this.props

        return remoteInspectionResult.result.map((remoteInspectionTest) => {
            return (
                <ResultsRow
                    key={
                        remoteInspectionResult.id + '$' + remoteInspectionTest.testTypeId
                    }
                    {...remoteInspectionTest}
                />
            )
        })
    }

    render() {
        return <div className="rri-results-content">{this.renderResultRowNodes()}</div>
    }
}
