import React from 'react'

import {ReactComponent as IconExclamation} from 'ipmp-react-ui/icons/exclamation.svg'
import Circle from 'ipmp-react-ui/Circle'

import {__} from 'utils/i18n'

const PersonalDataNotice = () => {
    return (
        <div className="panelInfo-notification">
            <Circle Icon={IconExclamation} />

            <p>
                {__(
                    "Customer's Personal Data is used solely for security purpose, directly related to the panel that is connected to this server"
                )}
            </p>
        </div>
    )
}

export default PersonalDataNotice
