import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withProps from 'containers/withProps'
import withForm from 'containers/withForm'

import Button from 'ipmp-react-ui/Button'

import {setSystemSettings} from 'modules/forms/handlers'
import {showSystemSettingsRemoteInspectionModal} from 'modules/modals/actions'

import {__} from 'utils/i18n'

export default compose(
    withForm(setSystemSettings.RemoteInspection),
    connect(
        ({
            system: {
                settings: {remoteInspection},
            },
        }) => remoteInspection,
        (dispatch) =>
            bindActionCreators(
                {
                    modal: showSystemSettingsRemoteInspectionModal,
                },
                dispatch
            ),
        (props, {modal}) => ({
            onClick: () => modal({...props}),
        })
    ),
    withProps(() => ({
        borderless: true,
        primary: true,
        small: true,
        label: __('Edit'),
    }))
)(Button)
