import createListActions from 'modules/higherOrder/createListActions'

export const {
    init,
    receive,
    reset,
    setStart,
    setQuery,
    setFilters,
    clearFilters,
    addFilters,
    removeFilters,
    fetch,
    purgeList,
    startPoll,
    stopPoll,
} = createListActions('INSTALLERS/LIST')
