import {select, all, takeEvery, put} from 'redux-saga/effects'
import {MAX_SELECTABLE_ROWS} from 'constants/selection'

import {select as selectAction} from './actions'

import {snackShow} from 'modules/snacks'
import {__} from 'utils/i18n'

export default function* () {
    yield all([takeEvery(selectAction, watchSelect)])
}

function* watchSelect() {
    const isFull = yield select(({selection}) => selection.isFull())

    if (isFull) {
        yield put(snackShow(__('Max selection of %d rows reached', MAX_SELECTABLE_ROWS)))
    }
}
