import React from 'react'
import {compose} from 'redux'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'

import AddEventTypeButton from './GridBar/AddEventTypeButton'

export const EventTypesGridBar = () => (
    <Bar orientation={RIGHT}>
        <AddEventTypeButton />
    </Bar>
)

export default compose()(EventTypesGridBar)
