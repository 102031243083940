import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {ScrollView} from 'ipmp-react-ui/Layout'
import Card from 'ipmp-react-ui/Card'

import RemoteInspection from './RemoteInspection'
import Rotation from './Rotation'

import * as actions from 'modules/system/settings/actions'
import withLoader from 'containers/withLoader'

export class Resolve extends Component {
    render() {
        return (
            <ScrollView>
                <Card className="card">
                    <RemoteInspection />
                </Card>
                <Card className="card">
                    <Rotation />
                </Card>
            </ScrollView>
        )
    }
}

export default compose(
    connect(
        ({
            system: {
                settings: {remoteInspection, rotation},
            },
        }) => ({
            isLoading: remoteInspection.isLoading || rotation.isLoading,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    remoteInspection: actions.fetchRemoteInspection,
                    rotation: actions.fetchRotation,
                },
                dispatch
            )
    ),
    withLoader(({remoteInspection, rotation}) => {
        remoteInspection()
        rotation()
    })
)(Resolve)
