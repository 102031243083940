import withProps from 'containers/withProps'

import GroupsTable from './GroupsTable'
import GroupsGridBar from './GroupsGridBar'
import GroupsSearch from './GroupsSearch'

import Page from 'ipmp-react-ui/Page'

export default withProps({
    Top: GroupsSearch,
    Bar: GroupsGridBar,
    Content: GroupsTable,
})(Page)
