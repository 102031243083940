import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import List from 'ipmp-react-ui/List'

import {SettingsPageBlock} from '../SettingsPageBlock'
import EditInfoButton from './Buttons/EditInfoButton'

import {__} from 'utils/i18n'

class Info extends SettingsPageBlock {
    static propTypes = {
        data: PropTypes.shape({
            name: PropTypes.string,
            address: PropTypes.string,
            person_email: PropTypes.string,
            person_name: PropTypes.string,
            person_phone: PropTypes.string,
            web_admin_email: PropTypes.string,
        }),
    }

    render() {
        const {data} = this.props
        return (
            <div>
                <h1 className="card-header">
                    {__('Customer info')}
                    <EditInfoButton />
                </h1>
                <List className="card-content" twoCols>
                    {this.renderListItem(__('Name'), data.name)}
                    {this.renderListItem(__('Person Email'), data.person_email)}
                    {this.renderListItem(__('Address'), data.address)}
                    {this.renderListItem(__('Person Name'), data.person_name)}
                    {this.renderListItem(__('Web Admin Email'), data.web_admin_email)}
                    {this.renderListItem(__('Person Phone'), data.person_phone)}
                </List>
            </div>
        )
    }
}

export default compose(
    connect(({system: {settings: {customerInfo}}}) => ({...customerInfo}))
)(Info)
