import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {
    EVENT_RULE_MASTER_STEP_EVENT_TYPE,
    EVENT_RULE_MASTER_STEP_NOTIFICATION_TYPE,
    EVENT_RULE_MASTER_STEP_SAVE,
    EVENT_RULE_MASTER_STEP_STATE_TYPE,
    EVENT_RULE_MASTER_STEP_ZONE_TYPE,
    EventRuleStep,
    ZONE_TYPE_NAME,
    NOTIFICATION_TYPE_NAME,
    STATE_TYPE_NAME,
    EVENT_TYPE_NAME,
} from 'constants/eventRules'
import ZoneType from './Content/ZoneType'
import NotificationType from './Content/NotificationType'
import StateType from './Content/StateType'
import EventType from './Content/EventType'
import Save from './Content/Save'

class EventRuleStepContent extends Component {
    static propTypes = {
        currentStep: PropTypes.instanceOf(EventRuleStep),
    }

    render() {
        switch (this.props.currentStep.currentStep) {
            case EVENT_RULE_MASTER_STEP_ZONE_TYPE:
                return <ZoneType selectName={ZONE_TYPE_NAME} />
            case EVENT_RULE_MASTER_STEP_NOTIFICATION_TYPE:
                return <NotificationType selectName={NOTIFICATION_TYPE_NAME} />
            case EVENT_RULE_MASTER_STEP_STATE_TYPE:
                return <StateType selectName={STATE_TYPE_NAME} />
            case EVENT_RULE_MASTER_STEP_EVENT_TYPE:
                return <EventType selectName={EVENT_TYPE_NAME} />
            case EVENT_RULE_MASTER_STEP_SAVE:
                return <Save />
        }
    }
}

export default EventRuleStepContent
