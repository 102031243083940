import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {
    getEventRuleStepIcon,
    getEventRuleStepsClassNameEnd,
    getEventRuleStepTitle,
    steps,
} from 'constants/eventRules'

const EventRuleStepIcon = ({step}) => {
    return (
        <div
            className={classnames(
                'eventRules-step-icon',
                `eventRules-step-icon__${getEventRuleStepsClassNameEnd(step)}`
            )}
        >
            <span className="eventRules-step-icon-holder">
                {getEventRuleStepIcon(step)}
            </span>
            <span className="eventRules-step-icon-title">
                {getEventRuleStepTitle(step)}
            </span>
        </div>
    )
}

EventRuleStepIcon.propTypes = {
    step: PropTypes.oneOf(steps),
}

export default EventRuleStepIcon
