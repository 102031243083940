import {handleActions} from 'redux-actions'
import {set} from 'immutable-modify'
import keyBy from 'lodash-es/keyBy'
import get from 'lodash-es/get'
import omit from 'lodash-es/omit'

import {
    fetch,
    update,
    removeDevice,
    receive,
    setSoak,
    setBypass,
    setRarelyUsed,
    confirmProgress,
    revertProgress,
    renameDevice,
} from './actions'

export default handleActions(
    {
        [fetch](state, {payload: {panelId}}) {
            return set(state, [panelId, 'isLoading'], true)
        },

        [update](state, {payload, meta: {panelId}}) {
            return set(state, [panelId, 'byIds'], (byIds) =>
                payload.reduce(
                    (acc, {id, ...data}) => {
                        acc[id] = {
                            id,
                            ...acc[id],
                            ...data,
                        }

                        return acc
                    },
                    {...byIds}
                )
            )
        },

        [receive](state, {payload, error, meta: {panelId}}) {
            if (error) {
                return set(state, panelId, (store) => ({
                    ...store,
                    isLoading: false,
                    error: payload,
                }))
            }

            return set(state, panelId, (store) => ({
                ...store,
                isLoading: false,
                error: null,
                byIds: keyBy(payload, 'id'),
            }))
        },

        [setSoak](state, {payload: {deviceId, panelId, enabled}}) {
            return set(state, [panelId, 'progress', 'soak', deviceId], enabled)
        },

        [setBypass](state, {payload: {panelId, states}}) {
            return set(state, [panelId, 'progress', 'bypass'], (ids) => ({
                ...ids,
                ...states,
            }))
        },

        [setRarelyUsed](state, {payload: {panelId, deviceId, enabled}}) {
            return set(
                state,
                [panelId, 'byIds', deviceId, 'traits', 'rarely_used'],
                (data) => ({
                    ...data,
                    enabled,
                })
            )
        },

        [confirmProgress](state, {payload: {deviceIds, panelId, type}}) {
            const status = get(state, [panelId, 'progress', type])

            state = set(state, [panelId, 'progress', type], (ids) => omit(ids, deviceIds))

            return set(state, [panelId, 'byIds'], (devices) =>
                deviceIds.reduce(
                    (acc, id) => {
                        if (acc[id]) {
                            acc[id] = set(acc[id], ['traits', type], {
                                enabled: !!status[id],
                                progress: true,
                            })
                        }

                        return acc
                    },
                    {...devices}
                )
            )
        },

        [revertProgress](state, {payload: {deviceIds, panelId, type}}) {
            return set(state, [panelId, 'progress', type], (ids) => omit(ids, deviceIds))
        },

        [removeDevice](state, {payload: {deviceId, panelId}}) {
            return set(state, [panelId, 'byIds', deviceId], (state) => ({
                ...state,
                removing: true,
            }))
        },

        [renameDevice](state, {payload: {panelId, deviceType, zone, name}}) {
            return set(state, [panelId, 'byIds'], (byIds) => {
                const id = Object.keys(byIds).find(
                    (id) => byIds[id].zone === zone && byIds[id] === deviceType
                )

                if (!id) {
                    return byIds
                }

                return set(byIds, id, (device) => ({
                    ...device,
                    name,
                }))
            })
        },
    },
    {}
)
