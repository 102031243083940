import createListWithoutPaginationActions from 'modules/higherOrder/createListWithoutPaginationActions'

export const {
    fetch,
    init,
    receive,
    reset,
    remove,
    revertRemove,
    purgeList,
    startPoll,
    stopPoll,
} = createListWithoutPaginationActions('STATE_TYPES/LIST')
