import {takeEvery, all, put} from 'redux-saga/effects'
import {editRole} from 'modules/forms/handlers'
import * as actions from './actions'

export default function* () {
    yield all([takeEvery([editRole.SUCCESS], watchEditRole)])
}

function* watchEditRole({payload: {result}}) {
    yield put(actions.update(result))
}
