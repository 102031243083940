import React, {Component, Fragment} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import CardBlindModal from 'ipmp-react-ui/CardBlindModal'
import Buttons from 'ipmp-react-ui/Buttons'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconNext} from 'ipmp-react-ui/icons/arrow-next.svg'
import {ReactComponent as IconPrev} from 'ipmp-react-ui/icons/arrow-prev.svg'

import ConnectedDevice from './Device'
import DeviceSidebar from './Sidebar/DeviceSidebar'
import PanelSidebar from './Sidebar/PanelSidebar'

import withDevices from 'containers/withDevices'
import withDevicesNavigation from 'containers/withDevicesNavigation'

import ConnectedPanel from 'components/Devices/Panel'

class DevicesBlindModal extends Component {
    static propTypes = {
        selectedDeviceId: PropTypes.number,
        startWithVideoOnDemandTab: PropTypes.bool.isRequired,
        startWithMeteoTab: PropTypes.bool.isRequired,
        panel: PropTypes.shape({
            id: PropTypes.number,
            isNeo: PropTypes.bool,
        }).isRequired,
        onClose: PropTypes.func.isRequired,
        onSelectDevice: PropTypes.func.isRequired,
        prevDeviceId: PropTypes.number,
        nextDeviceId: PropTypes.number,
        isPanelSelected: PropTypes.bool,
        onSelectPanel: PropTypes.func.isRequired,
    }

    selectPrevDevice = () => {
        const {onSelectDevice, onSelectPanel, prevDeviceId} = this.props
        if (prevDeviceId === null) {
            onSelectPanel()
        } else {
            onSelectDevice(prevDeviceId)
        }
    }

    selectNextDevice = () => {
        const {onSelectDevice, nextDeviceId} = this.props

        onSelectDevice(nextDeviceId)
    }

    renderDeviceContent() {
        const {
            selectedDeviceId,
            startWithVideoOnDemandTab,
            startWithMeteoTab,
            panel,
        } = this.props

        return (
            <Fragment>
                <ConnectedDevice deviceId={selectedDeviceId} panelId={panel.id} />

                <DeviceSidebar
                    key={selectedDeviceId}
                    deviceId={selectedDeviceId}
                    panelId={panel.id}
                    isNeo={panel.isNeo}
                    onSelect={this.handleSelect}
                    onClose={this.handleCloseSidebar}
                    startWithVideoOnDemandTab={startWithVideoOnDemandTab}
                    startWithMeteoTab={startWithMeteoTab}
                />
            </Fragment>
        )
    }

    renderPanelContent() {
        const {panel} = this.props

        return (
            <Fragment>
                <ConnectedPanel className="blindModalDevices-device" panel={panel} />
                <PanelSidebar panel={panel} />
            </Fragment>
        )
    }

    renderContent() {
        const {selectedDeviceId, nextDeviceId, isPanelSelected} = this.props

        if (!selectedDeviceId && !isPanelSelected) {
            return null
        }

        return (
            <Fragment>
                <Buttons className="blindModalDevices-paginator" flat>
                    <Button
                        disabled={isPanelSelected}
                        className="blindModalDevices-paginator-prev"
                        onClick={this.selectPrevDevice}
                        Icon={IconPrev}
                    />
                    <Button
                        disabled={nextDeviceId === null}
                        className="blindModalDevices-paginator-next"
                        onClick={this.selectNextDevice}
                        Icon={IconNext}
                    />
                </Buttons>
                {isPanelSelected ? this.renderPanelContent() : this.renderDeviceContent()}
            </Fragment>
        )
    }

    render() {
        const {onClose, isDeviceInfoOpened} = this.props
        return (
            <CardBlindModal
                opened={isDeviceInfoOpened}
                onClose={onClose}
                className="blindModalDevices"
            >
                {this.renderContent()}
            </CardBlindModal>
        )
    }
}

export default compose(withDevices(), withDevicesNavigation())(DevicesBlindModal)
