import {takeEvery, takeLatest, all, put} from 'redux-saga/effects'
import has from 'lodash-es/has'

import {err} from 'utils/log'

import {
    createListPurgeSaga,
    createListRemoveSaga,
    creteWatchFetch,
} from './createListSaga'

export function* createListFetchSaga(fetch, actions, updateActionCreator, selector) {
    if (!fetch) {
        return err(`Api method fetch not found for action: ${actions.fetch}`)
    }

    if (!actions.init) {
        return
    }

    yield all([
        takeEvery(actions.init, function* watchInit() {
            yield put(actions.reset())
        }),

        takeLatest(
            [actions.reset, actions.fetch],
            creteWatchFetch({fetch, actions, updateActionCreator, selector})
        ),
    ])
}

export default function* createListWithoutPaginationSaga(
    api,
    actions,
    updateActionCreator,
    selector,
    purgeStoreAction = null
) {
    const sagas = []

    sagas.push(createListFetchSaga(api.fetch, actions, updateActionCreator, selector))

    if (purgeStoreAction) {
        sagas.push(createListPurgeSaga(actions, purgeStoreAction, selector))
    }

    if (has(actions, 'remove')) {
        sagas.push(createListRemoveSaga(api.remove, actions, selector))
    }

    yield all(sagas)
}
