import {ElementType} from 'react'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import has from 'lodash-es/has'
import {OutputSelector} from 'reselect'

import withProps from './withProps'
import {showUnavailableFeatureModal} from 'modules/modals/actions'
import FeatureNotSupported from 'components/Panel/FeatureNotSupported'
import withInterruption from './withInterruption'
import {selectSelectedFeature} from 'modules/panels/store/selectors'
import {selectPanelFeatures} from 'modules/features/store/selectors'
import {fetch, fetchWithoutLoader} from 'modules/features/store/actions'

export function withSelectionFeature(feature, selector, message: () => String) {
    return compose(
        connect(
            (state) => ({state}),
            (dispatch) =>
                bindActionCreators(
                    {
                        showUnavailableFeatureModal,
                    },
                    dispatch
                ),
            ({...state}, dispatch, {onClick, ...props}) => ({
                ...state,
                ...props,
                onClick,
                showUnavailableFeatureModal: ({unavailablePanels, availableIds}) => {
                    dispatch.showUnavailableFeatureModal({
                        message: message,
                        unavailablePanels,
                        action: onClick,
                        availableIds,
                    })
                },
            })
        ),
        withProps(({onClick, showUnavailableFeatureModal, state}) => ({
            onClick: (...args) => {
                const {isFeatureAvailable, unavailablePanels, availableIds} = selector
                    ? selector(state, {feature})
                    : selectSelectedFeature(state, {feature})

                isFeatureAvailable
                    ? onClick(...args)
                    : showUnavailableFeatureModal({
                          unavailablePanels,
                          availableIds,
                      })
            },
        }))
    )
}

export function withFeatureRejection(
    selector: OutputSelector,
    Component: ElementType = FeatureNotSupported
) {
    return compose(
        connect(selector),
        withInterruption(({isAvailable}) => !isAvailable, Component)
    )
}

export const filterPanelTabsWithFeature = (state, menu, panelId) => {
    return Object.keys(menu).reduce((acc, key) => {
        const menuObj = menu[key]

        if (!has(menuObj, 'selector')) {
            acc[key] = menuObj
        } else {
            const {isAvailable} = menuObj.selector(state, {panelId})

            if (isAvailable) {
                acc[key] = menuObj
            }
        }

        return acc
    }, {})
}

export default function withFeatures() {
    return connect(selectPanelFeatures, (dispatch) =>
        bindActionCreators(
            {
                fetchFeaturesWithoutLoader: fetchWithoutLoader,
                fetchFeatures: fetch,
            },
            dispatch
        )
    )
}
