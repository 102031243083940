import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {showEditRole} from 'modules/modals/actions'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'
import Button from 'ipmp-react-ui/Button'

export default connect(
    (state, {row: {isEditable}}) => ({
        flat: true,
        small: true,
        primary: true,
        Icon: IconEdit,
        disabled: !isEditable,
    }),
    (dispatch, {row: {id}}) =>
        bindActionCreators(
            {
                onClick: () => showEditRole(id),
            },
            dispatch
        )
)(Button)
