import {createAction} from 'redux-actions'
import toArray from 'utils/toArray'

export const observe = createAction(
    'PROCESSES/MANAGER/OBSERVE',
    toArray,
    (_, batchId) => ({batchId})
)

export const receive = createAction('PROCESSES/MANAGER/RECEIVE', toArray)

export const restore = createAction('PROCESSES/MANAGER/RESTORE')

export const track = createAction('PROCESSES/MANAGER/TRACK', toArray)

export const untrack = createAction('PROCESSES/MANAGER/UNTRACK', toArray)
