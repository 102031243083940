import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import Select, {Option} from 'ipmp-react-ui/Select'
import Togglebox from 'ipmp-react-ui/ToggleBox'

import _sortBy from 'lodash-es/sortBy'

export default class ListValue extends PureComponent {
    static propTypes = {
        item: PropTypes.object.isRequired,
        value: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    handleToggleChange = (e) => {
        const {items: originalItems} = this.props.item
        const items = this.convertItems(originalItems)

        const value = e.target.checked ? items[1][0] : items[0][0]

        this.handleChange(e, value)
    }

    handleChange = (e, value) => {
        this.props.onChange(value)
    }

    convertItems(items) {
        if (items.length === 2) {
            return _sortBy(items, [0])
        }
        return items
    }

    render() {
        const {
            item: {items: originalItems},
            value,
        } = this.props
        const items = this.convertItems(originalItems)
        if (items.length < 2) {
            return items[value] ? items[value][1] : value
        }

        if (items.length === 2) {
            return (
                <Togglebox
                    onChange={this.handleToggleChange}
                    label={items[1][1]}
                    checked={value === items[1][0]}
                    labelOff={items[0][1]}
                />
            )
        }

        return (
            <Select value={value} onChange={this.handleChange}>
                {items.map(([key, value]) => (
                    <Option key={key} label={value.trim()} value={key} />
                ))}
            </Select>
        )
    }
}
