import {compose} from 'redux'

import {enroll} from 'permissions/panel/devices/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withDevices from 'containers/withDevices'
import withProps from 'containers/withProps'

import {ReactComponent as PlusIcon} from 'ipmp-react-ui/icons/plus.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import Void from 'components/Void'
import {selectAddDeviceFeature} from 'modules/features/store/selectors'
import {withFeatureRejection} from 'containers/withFeature'

export default compose(
    withPermission({isVisible: enroll}),
    withVisibility(),
    withDevices(),
    withProps(({showAddDevice}) => ({
        Icon: PlusIcon,
        onClick: showAddDevice,
        label: __('Add device'),
    })),
    withFeatureRejection(selectAddDeviceFeature, Void)
)(Button)
