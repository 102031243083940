import {compose} from 'redux'

import ConnectedPanels from 'components/Dashboard/ConnectedPanels'
import {withConnectedPanels, withOnline} from 'containers/withDashboard'
import {withPermission, withRejection} from 'containers/withPermission'
import {connectedPanels} from 'permissions/dashboard/charts'

export default compose(
    withPermission({isAllowed: connectedPanels}),
    withRejection(),
    withOnline(),
    withConnectedPanels()
)(ConnectedPanels)
