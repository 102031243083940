import React, {Component} from 'react'
import {compose} from 'redux'

import page from 'permissions/panel/info/page'

import {withPermission, withRejection} from 'containers/withPermission'
import withPanelInfo from 'containers/withPanelInfo'

import Layout, {ScrollView} from 'ipmp-react-ui/Layout'

import PanelInfo from './PanelInfo'
import CustomerInfo from './CustomerInfo'
import PersonalDataNotice from './PersonalDataNotice'

export class Info extends Component {
    render() {
        return (
            <Layout className="panelInfo">
                <div className="card">
                    <ScrollView>
                        <CustomerInfo />
                        <PanelInfo />
                        <PersonalDataNotice />
                    </ScrollView>
                </div>
            </Layout>
        )
    }
}

export default compose(
    withPermission({isAllowed: page}),
    withRejection(),
    withPanelInfo()
)(Info)
