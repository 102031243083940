import {fetch, receive} from './actions'
import {call, takeEvery, put} from 'redux-saga/effects'
import {listAppliance} from 'api/firmware'
import {update} from '../store/actions'
import toIds from 'utils/toIds'

export default function* () {
    yield takeEvery(fetch, watchFetch)
}

function* watchFetch() {
    try {
        const appliances = yield call(listAppliance)

        yield put(update(appliances))
        yield put(receive(toIds(appliances)))
    } catch (error) {
        yield put(receive(error))
    }
}
