//TODO: remove cross server search module, implement horizontal scaling

import {
    CrossServerLink,
    CrossServerSearch,
} from 'pages/Panels/CrossServerSearch/CrossServerSearch'
import CrossServerSearchBar from 'pages/Panels/CrossServerSearch/CrossServerSearchBar'
import CrossServerSearchResultsTable from 'pages/Panels/CrossServerSearch/CrossServerSearchResultsTable'
import CrossServerStatus from 'pages/Panels/CrossServerSearch/CrossServerStatus'
import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import CardBlindModal from 'ipmp-react-ui/CardBlindModal'
import 'styles/components/crossServerBlind.sass'

export class CrossBlind extends PureComponent {
    static propTypes = {
        opened: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        servers: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                url: PropTypes.string.isRequired,
            })
        ),
    }

    render() {
        const {opened, onClose, servers} = this.props

        return (
            <CardBlindModal
                className="crossServerBlind"
                opened={opened}
                onClose={onClose}
            >
                <CrossServerSearch>
                    {servers.map((server) => (
                        <CrossServerLink key={server.name} server={server} />
                    ))}

                    <CrossServerStatus />
                    <CrossServerSearchBar />
                    <CrossServerSearchResultsTable />
                </CrossServerSearch>
            </CardBlindModal>
        )
    }
}

export default CrossBlind
