import moment from 'moment'
import get from 'lodash/get'

import {humanTime} from 'ipmp-react-ui/humanTime'

export function timeTitleCallback(tooltipItem) {
    const date = humanTime(
        moment(get(tooltipItem, ['0', 'xLabel'], new Date().toString()), [
            'MMM D, YYYY, HH:mm:ss A',
        ])
    )
    return `${date.date}, ${date.time}`
}

export function timeXAxes(from, to) {
    const time = {
        displayFormats: {
            hour: 'MMM D',
        },
    }

    if (from) {
        time.min = moment(from).time
    }
    if (to) {
        time.max = moment(to).time
    }

    return [
        {
            type: 'time',
            time,
        },
    ]
}
