import createListActions from 'modules/higherOrder/createListActions'

export const {
    init,
    reset,
    fetch,
    receive,
    setStart,
    setQuery,
    setFilters,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
    receiveSuggests,
    startPoll,
    stopPoll,
    purgeList,
} = createListActions('RUNNERS/LIST')
