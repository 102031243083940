import __ from 'utils/i18n'
import {warn} from 'utils/log'

export function rriFaultsDescription(description) {
    switch (description) {
        // pmax
        case 'Freezer Alert':
            return __('Freezer Alert')
        case 'Freezer trouble':
            return __('Freezer trouble')
        case 'Freezing Alert':
            return __('Freezing Alert')
        case 'Cold Alert':
            return __('Cold Alert')
        case 'Hot Alert':
            return __('Hot Alert')
        case 'Fire':
            return __('Fire')
        case 'Tamper Memory':
            return __('Tamper Memory')
        case 'Panic':
            return __('Panic')
        case 'Emergency':
            return __('Emergency')
        case 'Alarm in Memory':
            return __('Alarm in Memory')
        case 'Heat Memory':
            return __('Heat Memory')
        case 'Smoke Memory':
            return __('Smoke Memory')
        case 'AC failure':
            return __('AC failure')
        case 'Comm failure':
            return __('Comm failure')
        case 'Jammed':
            return __('Jammed')
        case 'Line failure':
            return __('Line failure')
        case 'Low battery':
            return __('Low battery')
        case 'No active':
            return __('No active')
        case 'Tamper':
            return __('Tamper')
        case 'SIM not verified':
            return __('SIM not verified')
        case 'Inactive':
            return __('Inactive')
        case 'Trouble':
            return __('Trouble')
        case 'Clean me':
            return __('Clean me')
        case 'Heat Open':
            return __('Heat Open')
        case 'Bypass':
            return __('Bypass')
        case 'Soak failed':
            return __('Soak failed')
        case 'Sensor':
            return __('Sensor')
        case 'Selft-Test':
            return __('Selft-Test')
        case 'Fuse':
            return __('Fuse')
        case 'Working 1-way':
            return __('Working 1-way')
        case 'Smoke Open':
            return __('Smoke Open')
        case 'Reported 1-way':
            return __('Reported 1-way')
        case 'RSSI Low':
            return __('RSSI Low')
        case 'Masking Trouble':
            return __('Masking Trouble')
        case 'Mark for Service':
            return __('Mark for Service')
        case 'Went offline':
            return __('Went offline')
        case 'GPRS Went offline':
            return __('GPRS Went offline')
        case 'BBA Went offline':
            return __('BBA Went offline')
        case 'Not Activated':
            return __('Not Activated')
        case 'Opened':
            return __('Opened')
        case 'RSSI poor ot less (now)':
            return __('RSSI poor ot less (now)')
        case 'RSSI poor or less (24H)':
            return __('RSSI poor or less (24H)')
        case 'Pre-enroll status (only ID entered, but code)':
            return __('Pre-enroll status (only ID entered, but code)')
        case 'Module Battery Disconnected':
            return __('Module Battery Disconnected')
        case 'Unfinished discovery':
            return __('Unfinished discovery')

        // neo
        case 'Unknown':
            return __('Unknown')
        case 'Burglary':
            return __('Burglary')
        case 'HR24 Supervisory':
            return __('HR24 Supervisory')
        case 'Fire Supervisory':
            return __('Fire Supervisory')
        case 'CO':
            return __('CO')
        case 'Gas':
            return __('Gas')
        case 'High Temperature':
            return __('High Temperature')
        case 'Low Temperature':
            return __('Low Temperature')
        case 'Medical':
            return __('Medical')
        case 'Waterflow':
            return __('Waterflow')
        case 'Water Leakage':
            return __('Water Leakage')
        case 'Pendant':
            return __('Pendant')
        case 'RF Jam Alarm':
            return __('RF Jam Alarm')
        case 'Hardware Fault':
            return __('Hardware Fault')
        case 'Duress':
            return __('Duress')
        case 'Hold Up':
            return __('Hold Up')
        case 'Sprinkler':
            return __('Sprinkler')
        case 'Quick Bypass':
            return __('Quick Bypass')
        case 'Burglary no verified':
            return __('Burglary no verified')
        case 'Hold Up no verified':
            return __('Hold Up no verified')
        case 'High Temperature Warning':
            return __('High Temperature Warning')
        case 'Low Temperature Warning':
            return __('Low Temperature Warning')
        case 'General':
            return __('General')
        case 'Device Fault':
            return __('Device Fault')
        case 'Device Delinquency':
            return __('Device Delinquency')
        case 'Failure Communicate':
            return __('Failure Communicate')
        case 'GSIP Receiver':
            return __('GSIP Receiver')
        case 'All Receiver Not Available':
            return __('All Receiver Not Available')
        case 'All Receiver Superivision':
            return __('All Receiver Superivision')
        case 'Device Low Sensivity':
            return __('Device Low Sensivity')
        case 'Device internal Fault':
            return __('Device internal Fault')
        case 'RF Device Not Networked':
            return __('RF Device Not Networked')
        case 'Device Mask Trouble':
            return __('Device Mask Trouble')
        case 'Power Unit Failure':
            return __('Power Unit Failure')
        case 'Overcurrent':
            return __('Overcurrent')
        case 'System Overview':
            return __('System Overview')
        case 'Service Request':
            return __('Service Request')
        case 'Network Trouble':
            return __('Network Trouble')
        case 'Bell':
            return __('Bell')
        case 'Ground Fault':
            return __('Ground Fault')
        case 'Output Fault':
            return __('Output Fault')
        case 'TLM':
            return __('TLM')
        case 'Printer':
            return __('Printer')
        case 'Time Date':
            return __('Time Date')
        case 'Configuration':
            return __('Configuration')
        case 'Warm Start':
            return __('Warm Start')
        case 'USB WIFI':
            return __('USB WIFI')
        case 'SIM Lock':
            return __('SIM Lock')
        case 'GSM':
            return __('GSM')
        case 'Ethernet':
            return __('Ethernet')
        case 'Module Supervisory':
            return __('Module Supervisory')
        case 'Module Tamper':
            return __('Module Tamper')
        case 'Module AC':
            return __('Module AC')
        case 'Module Low Battery':
            return __('Module Low Battery')
        case 'Module Battery Missing':
            return __('Module Battery Missing')
        case 'Module Battery Charger':
            return __('Module Battery Charger')
        case 'Module Battery Bus Low Voltage':
            return __('Module Battery Bus Low Voltage')
        case 'Module Bus Fault':
            return __('Module Bus Fault')
        case 'Module AUX':
            return __('Module AUX')
        case 'Firmaware Upgrade':
            return __('Firmaware Upgrade')
        case 'Default Code':
            return __('Default Code')
        case 'Duplicate Code':
            return __('Duplicate Code')
        case 'Heat Trouble':
            return __('Heat Trouble')
        case 'Freezer':
            return __('Freezer')
        case 'Probe':
            return __('Probe')
        case 'Smoke Trouble':
            return __('Smoke Trouble')
        case 'Radio SIM':
            return __('Radio SIM')
        case 'Radio Low Signal':
            return __('Radio Low Signal')
        case 'GSM Network':
            return __('GSM Network')
        case 'IP Remote Sever':
            return __('IP Remote Sever')
        case 'Low Input Voltage':
            return __('Low Input Voltage')
        case 'Low Output':
            return __('Low Output')
        case 'Module Battery 2 Low Voltage':
            return __('Module Battery 2 Low Voltage')
        case 'Module Battery 2 Missing':
            return __('Module Battery 2 Missing')
        case 'Module AUX 2':
            return __('Module AUX 2')
        case 'Fibro encryption disabled (BBA)':
            return __('Fibro encryption disabled (BBA)')
        case 'Fibro encryption disabled (GPRS)':
            return __('Fibro encryption disabled (GPRS)')
        case 'Fibro encryption fail (BBA)':
            return __('Fibro encryption fail (BBA)')
        case 'Fibro encryption fail (GPRS)':
            return __('Fibro encryption fail (GPRS)')
        case 'Fibro encryption not supported (BBA)':
            return __('Fibro encryption not supported (BBA)')
        case 'Fibro encryption not supported (GPRS)':
            return __('Fibro encryption not supported (GPRS)')
        case 'Fibro compromise attempt':
            return __('Fibro compromise attempt')
        case 'Fibro invalid repor':
            return __('Fibro invalid repor')

        //dual path
        case 'Low batter':
            return __('Low batter')
    }

    warn(`Undefined fault ${description}`)
    return description
}
