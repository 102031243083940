import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconMinus} from 'ipmp-react-ui/icons/minus.svg'

import {disconnectPanel} from 'modules/interactiveUserPanels/list/actions'
import {compose} from 'redux'
import withProps from 'containers/withProps'
import withConfirmation from 'containers/withConfirmation'
import __ from 'utils/i18n'

export default compose(
    connect(null, (dispatcher, {row: {id}}) =>
        bindActionCreators(
            {
                onDisconnect: () => disconnectPanel(id),
            },
            dispatcher
        )
    ),
    withProps(({onDisconnect, row: {serial}}) => ({
        onClick: onDisconnect,
        small: true,
        flat: true,
        Icon: IconMinus,
        message: __('Do you really want to disconnect %s panel?', serial),
        title: __('Disconnect panel'),
    })),
    withConfirmation()
)(Button)
