import {createStore, applyMiddleware, compose} from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise'
import {routerMiddleware} from 'connected-react-router'

import instance from 'api/http'

import saga from 'modules/saga'
import createRootReducer, {persistReducers} from 'modules/reducer'
import {logout} from 'modules/auth/sign/actions'

import {save, load} from 'redux-localstorage-simple'

const LOCAL_STORAGE_PERSIST_NAME = 'PowerManageStorePersist'

export default function createAppStore(history, defaultState = {}, useLogging = false) {
    const composeEnhancers =
        typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
              })
            : compose

    const sagaMiddleware = createSagaMiddleware()

    const middlewares = [
        thunk,
        promiseMiddleware,
        sagaMiddleware,
        routerMiddleware(history),
        save({states: persistReducers, namespace: LOCAL_STORAGE_PERSIST_NAME}),
    ]

    if (useLogging) {
        middlewares.push(({dispatch, getState}) => (next) => (action) => {
            console.log(action)
            return next(action)
        })
    }

    if (process.env.NODE_ENV === 'development') {
        const freeze = require('redux-freeze')
        middlewares.push(freeze)
    }

    // const enhancer = composeEnhancers(applyMiddleware(...middlewares))
    const createStoreWithMiddlware = composeEnhancers(applyMiddleware(...middlewares))(
        createStore
    )

    const store = createStoreWithMiddlware(createRootReducer(history), {
        ...defaultState,
        ...load({states: persistReducers, namespace: LOCAL_STORAGE_PERSIST_NAME}),
    })

    let sagaTask = sagaMiddleware.run(saga)

    if (module.hot) {
        module.hot.accept('../modules/saga', () => {
            const newSaga = require('../modules/saga').default
            sagaTask.cancel()
            sagaTask.done.then(() => {
                sagaTask = sagaMiddleware.run(newSaga)
                console.warn('Sagas replaced')
            })
        })

        module.hot.accept('../modules/reducer', () => {
            const nextRootReducer = require('../modules/reducer').default
            store.replaceReducer(nextRootReducer)
            console.warn('Reducers replaced')
        })
    }

    instance.interceptors.response.use(null, (error) => {
        const {status} = error

        switch (true) {
            case status === 401:
            case error.isCommunicationError:
                store.dispatch(logout())
        }

        return Promise.reject(error)
    })

    return store
}
