import React from 'react'

import Page from 'ipmp-react-ui/Page'

import RolesSearch from './RolesSearch'
import RolesTable from './RolesTable'
import RolesGridBar from './RolesGridBar'

export default class RolesPage extends Page {
    renderTop() {
        return <RolesSearch />
    }

    renderBar() {
        return <RolesGridBar />
    }

    renderContent() {
        return <RolesTable />
    }
}
