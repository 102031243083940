import {all, takeEvery} from 'redux-saga/effects'

import {fetchInstallerPanels} from 'api/installers'
import * as actions from './actions'
import * as watchers from './watchers'

import {update, purge} from '../store/actions'
import {selectPerPage} from 'modules/settings/selectors'

import listSaga from 'modules/higherOrder/createListSaga'

const selector = (state) => ({
    ...state.installerPanels.list,
    perPage: selectPerPage(state, 'installerPanels'),
    listRoute: 'installerPanels',
})

export default function* () {
    yield all([
        listSaga({fetch: fetchInstallerPanels}, actions, update, selector, purge),
        takeEvery(actions.assignPanel, watchers.watchAssignPanel),
        takeEvery(actions.unassignPanel, watchers.watchUnassignPanel),
        takeEvery(actions.unassignInstaller, watchers.watchUnassignInstaller),
    ])
}
