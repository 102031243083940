import diagnosticItemType, {diagnosticItemMeasurement} from 'constants/diagnosticItemType'
import List, {ListItem} from 'ipmp-react-ui/List'
import groupBy from 'lodash-es/groupBy'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Empty from 'ui/Empty'

export default class DiagnosticInfo extends Component {
    static propTypes = {
        params: PropTypes.arrayOf(
            PropTypes.shape({
                index: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            })
        ),
    }

    renderItem(name, values) {
        return (
            <ListItem key={name}>
                <h6 className="hint">{diagnosticItemType(name)}</h6>

                {values.map(({index, value}) => (
                    <div key={index}>
                        {values.length > 1 && (
                            <span className="diagnostic-index">{index}</span>
                        )}
                        <strong>
                            {value ? (
                                value + ' ' + diagnosticItemMeasurement(name)
                            ) : (
                                <Empty />
                            )}
                        </strong>
                    </div>
                ))}
            </ListItem>
        )
    }

    render() {
        const perName = groupBy(this.props.params, 'name')

        return (
            <List className="diagnostic">
                {Object.keys(perName).map((name) => this.renderItem(name, perName[name]))}
            </List>
        )
    }
}
