import React, {useEffect} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {track, untrack} from 'modules/processes/manager/actions'
import {createTrackedSelector} from 'modules/processes/manager/selectors'
import memoize from 'fast-memoize'

function getTrackKey(process) {
    if (!process) {
        return null
    }

    return process.key || process.id || process
}

function defaultGetter({processId, process}) {
    return process || processId
}

export default function withTrackProcess(processGetter = defaultGetter) {
    const memo = memoize(createTrackedSelector)

    return compose(
        connect(
            (state, props) => {
                const key = getTrackKey(processGetter(props))
                const selector = memo(key)
                const process = selector(state)
                return {process}
            },
            (dispatch) => bindActionCreators({track, untrack}, dispatch)
        ),
        (Component) => (props) => {
            const {track, untrack, process, ...rest} = props

            const id = parseInt(process && process.isRunning && process.id) || 0

            useEffect(() => {
                id && track(id)
                return () => id && untrack(id)
            }, [id])

            return <Component process={process} {...rest} />
        }
    )
}
