import withLifeCycle from './withLifeCycle'

export default function withRunnerPoll(mapToRefreshRunner = (args) => ({...args})) {
    return withLifeCycle({
        onMount({runner, startPollRunnerData, refreshRunner, ...rest}) {
            if (runner) {
                startPollRunnerData(runner.id, (payload) =>
                    refreshRunner(mapToRefreshRunner({...payload, ...rest}))
                )
            }
        },
        onUnmount({stopPollRunnerData, runner}) {
            if (runner) {
                stopPollRunnerData(runner.id)
            }
        },
        onUpdate(
            {runner, startPollRunnerData, stopPollRunnerData, refreshRunner, ...rest},
            prevProps
        ) {
            if (runner && !prevProps.runner) {
                startPollRunnerData(runner.id, (payload) =>
                    refreshRunner(mapToRefreshRunner({...payload, ...rest}))
                )
            }
            if (!runner && prevProps.runner) {
                stopPollRunnerData(prevProps.runner.id)
            }
        },
    })
}
