import createPermission, {any} from 'permissions/createPermission'
import {
    allProcesses,
    connectedPanels,
    eventsRate,
    faulty,
    online,
} from 'permissions/dashboard/charts'

export default createPermission(
    any(connectedPanels, eventsRate, faulty, online, allProcesses)
)
