import {createAction} from 'redux-actions'

export const setLoading = createAction('DEVICES/VOD/SET_LOADING')
export const receive = createAction(
    'DEVICES/VOD/RECEIVE',
    null,
    (_, panelId, unitZoneId) => ({panelId, unitZoneId})
)
export const erase = createAction('DEVICES/VOD/ERASE', (panelId, unitZoneId) => ({
    panelId,
    unitZoneId,
}))
export const start = createAction('DEVICES/VOD/START', (panelId, unitZoneId) => ({
    panelId,
    unitZoneId,
}))
export const fetch = createAction('DEVICES/VOD/FETCH', (panelId, unitZoneId) => ({
    panelId,
    unitZoneId,
}))
