import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import {withPermission} from 'containers/withPermission'
import {connect as connectPermission} from 'permissions/interactiveUsers/actions'
import withProps from 'containers/withProps'
import __ from 'utils/i18n'
import {withRouterPanelId} from 'containers/withPanel'
import {showAssignUserToPanelModal} from 'modules/modals/actions'
import {withVisibility} from 'containers/withVisibility'

export default compose(
    withPermission({
        isVisible: connectPermission,
    }),
    withVisibility(),
    withRouterPanelId(),
    connect(
        ({panelInteractiveUsers: {store}}) => ({
            panelsByIds: store,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    showAssignUserToPanelModal,
                },
                dispatch
            ),
        ({panelsByIds}, {showAssignUserToPanelModal}, {panelId}) => ({
            showAssignUserToPanelModal: () =>
                showAssignUserToPanelModal({
                    panelId,
                    hasPanelUser: Object.values(panelsByIds[panelId]).some(
                        ({isAssign}) => !isAssign
                    ),
                }),
            panelId,
        })
    ),
    withProps(({showAssignUserToPanelModal}) => ({
        Icon: IconPlus,
        label: __('Assign User'),
        onClick: showAssignUserToPanelModal,
    }))
)(Button)
