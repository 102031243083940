import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {fetch as fetchEventProfiles} from 'modules/centralStations/profiles/actions'

import ModalCardForm from 'ui/ModalCardForm'
import Checkbox from 'ipmp-react-ui/Checkbox'

import withModalLoader from 'containers/withModalLoader'
import withForm from 'containers/withForm'
import {editGroupCsLinks} from 'modules/forms/handlers'
import csProfileType, {CS_PROFILE_SMART_SENSORS} from 'constants/csProfileType'

import {__} from 'utils/i18n'

class EditLinks extends Component {
    static propTypes = {
        groupId: PropTypes.number.isRequired,
        centralStation: PropTypes.object.isRequired,
        profiles: PropTypes.object.isRequired,
        links: PropTypes.array,
        hide: PropTypes.func.isRequired,
        handle: PropTypes.func.isRequired,
    }

    state = {}

    constructor(props, context) {
        super(props, context)
        this.state.active = props.links || []
    }

    handle = ({profile}) => {
        const {handle, centralStation, groupId} = this.props
        handle(groupId, centralStation.id, profile.map(Number))
    }

    handleChange = (e) => {
        const key = parseInt(e.target.value)
        let active = this.state.active
        let includes = active.includes(key)

        if (e.target.checked && !includes) {
            active = [...active, key]
        }

        if (!e.target.checked && includes) {
            active = active.filter((id) => id !== key)
        }

        this.setState({active})
    }

    handleCheckAll = (e) => {
        const {profiles} = this.props

        this.setState({
            active: e.target.checked ? Object.keys(profiles).map(Number) : [],
        })
    }

    isProfileAvaible = (key) => {
        const {centralStation, profiles} = this.props

        if (profiles[key].name === CS_PROFILE_SMART_SENSORS) {
            // Smart sensors avaible only with 'VIS NAP V3' protocol
            return /VIS NAP V3/.test(centralStation.protocol)
        }

        return true
    }

    renderList(keys) {
        const {profiles} = this.props

        return keys
            .filter(this.isProfileAvaible)
            .map((key) => (
                <Checkbox
                    key={key}
                    value={key}
                    onChange={this.handleChange}
                    checked={this.state.active.includes(key)}
                    name={`profile[${key}]`}
                    label={csProfileType(profiles[key].name)}
                />
            ))
    }

    render() {
        const {centralStation, profiles, ...props} = this.props
        const header = `${centralStation.name} (${centralStation.protocol})`
        const keys = Object.keys(profiles).map(Number)
        const {active} = this.state
        const middle = Math.ceil(keys.length / 2)

        return (
            <ModalCardForm header={header} onSubmit={this.handle} {...props}>
                <Checkbox
                    label={__('All')}
                    onChange={this.handleCheckAll}
                    checked={active.length >= keys.length}
                    indeterminate={active.length > 0}
                />

                <hr />

                <div className="cols cols--stretch">
                    <div className="col">{this.renderList(keys.slice(0, middle))}</div>
                    <div className="col">{this.renderList(keys.slice(middle))}</div>
                </div>
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(
        ({centralStations, groups}, {groupId, centralStationId}) => ({
            isLoading: centralStations.profiles.isLoading, // used by withModalLoader
            error: centralStations.profiles.error, // used by withModalLoader
            profiles: centralStations.profiles.byId,
            links: groups.links[groupId][centralStationId],
            centralStation: centralStations.store.byId[centralStationId],
        }),

        (dispatch) =>
            bindActionCreators(
                {
                    fetchEventProfiles,
                },
                dispatch
            )
    ),

    withModalLoader(({fetchEventProfiles}) => fetchEventProfiles()),

    withForm(editGroupCsLinks)
)(EditLinks)
