import {compose} from 'redux'
import {connect} from 'react-redux'

import withForm from 'containers/withForm'
import StateTypeForm from './StateTypeForm'
import {validation} from './StateTypeFieldset'
import {addStateType} from 'modules/forms/handlers'
import {__} from 'utils/i18n'

export default compose(
    connect(() => ({
        header: __('Add State Type'),
        rules: validation,
    })),
    withForm(addStateType)
)(StateTypeForm)
