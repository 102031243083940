import {get, post, put, del} from 'api/http'
import {keysMap, mapZoneTypes, mapFormData} from 'api/zoneTypes.map'
import createRowsResponseParser from 'api/base/createRowsResponseParser'
import mapValidationErrors from 'api/base/mapValidationErrors'

export function fetch() {
    return get('/system/virtual/panel/zone_type').then(
        createRowsResponseParser(mapZoneTypes)
    )
}

export function add(data) {
    return post('/system/virtual/panel/zone_type', {
        form: mapFormData(data),
    }).catch((e) => mapValidationErrors(e, keysMap))
}

export function edit(data) {
    return put('/system/virtual/panel/zone_type/' + data.id, {
        form: mapFormData(data),
    }).catch((e) => mapValidationErrors(e, keysMap))
}

export function remove(id) {
    return del('/system/virtual/panel/zone_type/' + id[0]).catch((e) =>
        mapValidationErrors(e, keysMap)
    )
}
