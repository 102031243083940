import React from 'react'
import withSelectLoader from 'containers/withSelectLoader'
import Definition from 'ipmp-react-ui/Definition'
import Select from 'ipmp-react-ui/Select'
import {Option, Separator} from 'ipmp-react-ui/Select'
import {fetch as fetchUsers} from 'modules/users/maintainers/actions'

import {__} from 'utils/i18n'

const MaintainerSelect = ({children, ...props}) => (
    <Select {...props}>
        <Option value={0} label={__('Task Pool')} />
        {children && children.length && <Separator label={__('Selected panels users')} />}
        {children}
    </Select>
)

export default withSelectLoader(
    (prefix, maxOptionsToShow, {panelIds}) => fetchUsers(panelIds),
    (state) => state.users.maintainers.isLoading,
    (state) =>
        state.users.maintainers.rows.map(({id, name, isEnabled}) => ({
            label: name,
            value: id,
            children: (
                <Definition title={name} detail={isEnabled ? null : __('Suspended')} />
            ),
        }))
)(MaintainerSelect)
