import React from 'react'
import {connect} from 'react-redux'
import {hide} from 'modules/navBlind/actions'
import {bindActionCreators} from 'redux'
import * as blinds from 'modules/navBlind/constants'

import BatchInfo from './NavBlinds/BatchInfo'
import RecentHistory from './NavBlinds/RecentHistory'

const components = {
    [blinds.NAV_BLIND_BATCH_INFO]: BatchInfo,
    [blinds.NAV_BLIND_RECENT_HISTORY]: RecentHistory,
}

export const NavBlindContainer = ({type, hide, navBlindRefHandler, args}) => {
    if (!type) {
        return null
    }

    if (!components[type]) {
        throw new Error`Unknown nav blind type ${type}`()
    }

    const Component = components[type]

    return <Component {...args} navBlindRefHandler={navBlindRefHandler} hide={hide} />
}

export default connect(
    ({navBlind}) => ({...navBlind}),
    (dispatch) => bindActionCreators({hide}, dispatch)
)(NavBlindContainer)
