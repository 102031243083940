import moment from 'moment'
import {__} from 'utils/i18n'

export default {
    name: {
        presence: {
            message: () => __("Report's name is required"),
        },
        format: {
            pattern: /^[^ |:?\\/^]+/,
            message: () => __("Report's name contains incorrect characters"),
        },
        length: {
            minimum: 1,
            maximum: 250,
        },
    },

    'schedule.schedule': (value, {_startRightNow, _rangeEndDateEnabled, range}) => {
        if (_startRightNow) {
            return null
        }

        const latest =
            _rangeEndDateEnabled && range && range.end_date
                ? {latest: moment.utc(range.end_date, 'L')}
                : {}

        return {
            presence: {
                message: __('Start date should be selected'),
            },
            datetime: {
                dateOnly: true,
                earliest: moment().startOf('day').format('L'),
                ...latest,
                tooEarly: __('Start date should be in future'),
                tooLate: __('Start date should prepend end reporting date'),
            },
        }
    },

    'schedule.schedule_time': (value, {_startRightNow}) => {
        if (_startRightNow) {
            return null
        }

        return {
            presence: {
                message: __('Start time should be selected'),
            },
            custom: (value, {schedule: {schedule}}) => {
                const now = moment()
                const actual = moment(schedule + ' ' + value, 'L LT')

                if (actual.isBefore(now, 'minutes')) {
                    return __('Time should not be less than %s', now.format('LT'))
                }
            },
        }
    },

    weeklyDay: {
        presence: (value, {schedule}) => schedule && schedule.pattern === 'weekly',
    },

    weeklyEvery: {
        presence: (value, {schedule}) => schedule && schedule.pattern === 'weekly',

        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 1,
            lessThanOrEqualTo: 52,
        },
    },

    destinationEmails: (value, {sendTo}) => {
        if (!Array.isArray(sendTo) || sendTo.indexOf('email') === -1) {
            return null
        }

        return {
            presence: {
                message: () => __('Email cannot be empty'),
            },
            each: {
                email: {
                    message: () => __('Destination email should be valid email'),
                },
            },
        }
    },

    destinationFtp: (destinationFtp, {sendTo}) => {
        if (sendTo !== 'ftp') {
            return null
        }

        if (Array.isArray(sendTo) && !sendTo.includes('ftp')) {
            return null
        }

        return {
            presence: {
                message: () => __('FTP address cannot be empty'),
            },
            url: {
                schemes: ['ftp'],
                allowLocal: true,
            },
        }
    },

    'range.times': (value, {_rangeEndAfter}) => {
        if (!_rangeEndAfter) {
            return null
        }

        return {
            presence: true,
            numericality: {
                onlyInteger: true,
                greaterThan: 0,
                notInteger: () => __('Number of times should be integer'),
                notGreaterThan: () => __('Number of times should be positive number'),
            },
        }
    },

    'range.end_date': (value, {_rangeEndDateEnabled}) => {
        if (!_rangeEndDateEnabled) {
            return null
        }

        return {
            presence: true,
            datetime: {
                dateOnly: true,
                earliest: moment().format('L'),
                tooEarly: () => __('End reporting date should be in future'),
            },
        }
    },

    'settings.every': (value, {_recurEvery}) => {
        if (!_recurEvery) {
            return null
        }

        return {
            presence: true,
            numericality: {
                onlyInteger: true,
                greaterThanOrEqualTo: 1,
                lessThanOrEqualTo: 30,
            },
        }
    },
}
