import {createAction} from 'redux-actions'
import toArray from 'utils/toArray'

export const update = createAction('INSTALLERS/STORE/UPDATE', toArray)
export const clear = createAction('INSTALLERS/STORE/CLEAR')
export const purge = createAction('INSTALLERS/STORE/PURGE')

export const accept = createAction('INSTALLERS/STORE/ACCEPT', toArray)
export const reject = createAction('INSTALLERS/STORE/REJECT', toArray)
export const revert = createAction('INSTALLERS/STORE/REVERT', toArray)
export const resetPassword = createAction('INSTALLERS/STORE/RESET_PASSWORD')
