import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import actions from 'permissions/interactiveUsers/actions'
import {enable, suspend} from 'modules/panelInteractiveUsers/store/actions'

import {InteractiveUserStatusChanger} from 'pages/InteractiveUsers/TableCell/InteractiveUserStatusChanger'
import {withPermission} from 'containers/withPermission'
import {withRouterPanelId} from 'containers/withPanel'

class PanelInteractiveUserStatusChanger extends InteractiveUserStatusChanger {
    render() {
        return this.props.row.isAssign ? super.render() : null
    }
}
export default compose(
    withPermission({isPermitted: actions}),
    withRouterPanelId(),
    connect(
        () => ({}),
        (dispatch, {panelId}) =>
            bindActionCreators(
                {
                    enable: (id) => enable(id, panelId),
                    suspend: (id) => suspend(id, panelId),
                },
                dispatch
            )
    )
)(PanelInteractiveUserStatusChanger)
