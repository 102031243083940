import {handleActions} from 'redux-actions'

import keyBy from 'lodash-es/keyBy'

import * as actions from './actions'

export default handleActions(
    {
        [actions.fetch]: (state) => ({...state, isLoading: true}),
        [actions.receive]: (state, {payload, error}) => {
            if (error) {
                return {
                    ...state,
                    error: payload,
                    isLoading: false,
                }
            }

            if (payload && payload.rows) {
                return {...state, options: keyBy(payload.rows, 'id'), isLoading: false}
            }

            return state
        },
    },
    {
        isLoading: false,
        options: {},
        error: {},
    }
)
