import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'

import {__} from 'utils/i18n'

import Checkbox from 'ipmp-react-ui/Checkbox'
import Input from 'ipmp-react-ui/Input'
import {ReactComponent as IconCross} from 'ipmp-react-ui/icons/cross.svg'

export default class ConfigurationFilters extends Component {
    static propTypes = {
        query: PropTypes.string.isRequired,
        onSearch: PropTypes.func.isRequired,
        setShowChanges: PropTypes.func.isRequired,
        setShowExportable: PropTypes.func.isRequired,
        isShowExportable: PropTypes.bool,
        isShowChanged: PropTypes.bool,
        makeBasic: PropTypes.bool,
        isEditable: PropTypes.bool,
        isCompareWithBackup: PropTypes.bool,
        backup: PropTypes.object,
    }

    handleOnChange = (e) => {
        const {onSearch} = this.props
        onSearch(e.target.value)
    }

    handleOnClear = () => {
        const {onSearch} = this.props
        onSearch('')
    }

    handleFilterChanged = (e) => {
        const {setShowChanges} = this.props
        setShowChanges(e.target.checked)
    }

    handleShowExportable = (e) => {
        const {setShowExportable} = this.props
        setShowExportable(e.target.checked)
    }

    render() {
        const {
            makeBasic,
            isCompareWithBackup,
            isShowExportable,
            isShowChanged,
            isEditable,
            query,
        } = this.props

        return (
            <Fragment>
                <Input
                    autoFocus
                    placeholder={__('Quick search')}
                    value={query || ''}
                    onChange={this.handleOnChange}
                    Icon={query ? IconCross : null}
                    onIconClick={this.handleOnClear}
                />

                {!makeBasic && !isCompareWithBackup && isEditable && (
                    <Checkbox
                        onChange={this.handleFilterChanged}
                        checked={isShowChanged}
                        label={__('Show only changed')}
                    />
                )}

                {makeBasic && (
                    <Checkbox
                        onChange={this.handleShowExportable}
                        checked={isShowExportable}
                        label={__('Show exportable elements')}
                    />
                )}
            </Fragment>
        )
    }
}
