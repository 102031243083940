import React from 'react'

import {
    POWER,
    ARM,
    CHIME,
    TROUBLE,
    COMMUNICATION,
    SERVICE,
    WI_FI,
    READY,
    ARMED,
    AC,
} from 'constants/virtualKeypad/leds'

import {ReactComponent as IconPower} from 'ipmp-react-ui/icons/power.svg'
import {ReactComponent as IconKeypadLock} from 'ipmp-react-ui/icons/keypad/keypad-lock.svg'
import {ReactComponent as IconAlarm} from 'ipmp-react-ui/icons/alarm.svg'
import {ReactComponent as IconAlert} from 'ipmp-react-ui/icons/keypad/keypad-alert.svg'
import {ReactComponent as IconNetwork} from 'ipmp-react-ui/icons/network.svg'
import {ReactComponent as IconAmdocs} from 'ipmp-react-ui/icons/amdocs.svg'
import {ReactComponent as IconWiFi} from 'ipmp-react-ui/icons/wi-fi.svg'
import {ReactComponent as IconAc} from 'ipmp-react-ui/icons/keypad/keypad-ac.svg'
import {ReactComponent as IconReady} from 'ipmp-react-ui/icons/check.svg'

import Tooltip from 'ipmp-react-ui/Tooltip'

import {__} from 'utils/i18n'

const renderArmIcon = (ledState) => (
    <Tooltip key="keypad-lock" tooltip={__('Arming State')}>
        <IconKeypadLock className={`led led--away ${ledState}`} />
    </Tooltip>
)

const ledsIcons = [
    {
        name: POWER,
        led: (ledState) => (
            <Tooltip key="power" tooltip={__('Power')}>
                <IconPower className={`led led--power ${ledState}`} />
            </Tooltip>
        ),
    },
    {
        name: READY,
        led: (ledState) => (
            <Tooltip key="ready" tooltip={__('Ready')}>
                <IconReady className={`led led--ac ${ledState}`} />
            </Tooltip>
        ),
    },
    {
        name: ARM,
        led: renderArmIcon,
    },
    {
        name: ARMED,
        led: renderArmIcon,
    },
    {
        name: CHIME,
        led: (ledState) => (
            <Tooltip key="alarm" tooltip={__('Chime')}>
                <IconAlarm className={`led led--alarm ${ledState}`} />
            </Tooltip>
        ),
    },
    {
        name: TROUBLE,
        led: (ledState) => (
            <Tooltip key="alert" tooltip={__('Trouble')}>
                <IconAlert className={`led led--alert ${ledState}`} />
            </Tooltip>
        ),
    },
    {
        name: COMMUNICATION,
        led: (ledState) => (
            <Tooltip key="network" tooltip={__('Communication')}>
                <IconNetwork className={`led led--network ${ledState}`} />
            </Tooltip>
        ),
    },
    {
        name: SERVICE,
        led: (ledState) => (
            <Tooltip key="amdocs" tooltip={__('Service')}>
                <IconAmdocs className={`led led--amdocs ${ledState}`} />
            </Tooltip>
        ),
    },
    {
        name: WI_FI,
        led: (ledState) => (
            <Tooltip key="wifi" tooltip={__('Wi-Fi')}>
                <IconWiFi className={`led led--wi-fi ${ledState}`} />
            </Tooltip>
        ),
    },
    {
        name: AC,
        led: (ledState) => (
            <Tooltip key="ac" tooltip={__('Ac')}>
                <IconAc className={`led led--ac ${ledState}`} />
            </Tooltip>
        ),
    },
]

export default ledsIcons
