import createRowsResponseParser from 'api/base/createRowsResponseParser'
import mapValidationErrors from 'api/base/mapValidationErrors'

import {del, put, get, post} from 'api/http'
import {mapEventRule, keysMap, mapFormData} from './eventRules.map'

export function fetch() {
    return get('/system/virtual/panel/event_rule').then(
        createRowsResponseParser(mapEventRule)
    )
}

export function add(data) {
    return post('/system/virtual/panel/event_rule', {
        form: mapFormData(data),
    }).catch((e) => mapValidationErrors(e, keysMap))
}

export function edit(data) {
    return put('/system/virtual/panel/event_rule/' + data.id, {
        form: mapFormData(data),
    }).catch((e) => mapValidationErrors(e, keysMap))
}

export function remove(id) {
    return del('/system/virtual/panel/event_rule/' + id[0]).catch((e) =>
        mapValidationErrors(e, keysMap)
    )
}
