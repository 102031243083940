import {put, call} from 'redux-saga/effects'

import generateBatch from 'modules/batches/manager/generateBatch'
import {PROCESS_TYPE_PMAXSTATEGET} from 'constants/processTypes'
import * as api from 'api/panels'
import {snackShow} from 'modules/snacks'

export default function* watchRefreshState({payload: panelIds}) {
    try {
        const {batchId} = yield generateBatch(PROCESS_TYPE_PMAXSTATEGET, panelIds)
        yield call(api.refreshState, panelIds, batchId)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
