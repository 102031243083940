import React from 'react'

import Definition from 'ipmp-react-ui/Definition'

import {__} from 'utils/i18n'

import {ReactComponent as IconSuccess} from 'ipmp-react-ui/icons/check.svg'
import {ReactComponent as IconQuestion} from 'ipmp-react-ui/icons/question.svg'
import {ReactComponent as IconAlert} from 'ipmp-react-ui/icons/alert.svg'

const getStatusIcon = (status) => {
    switch (status) {
        case 'success':
            return IconSuccess
        case 'fatal':
        case 'error':
            return IconAlert
        default:
            return IconQuestion
    }
}

const getStatusClass = (status) => {
    switch (status) {
        case 'success':
            return 'success'
        case 'fatal':
        case 'error':
            return 'danger'
        default:
            return null
    }
}

const getStatusTitle = (status) => {
    switch (status) {
        case 'success':
            return __('Success')
        case 'fatal':
            return __('Fatal')
        case 'error':
            return __('Error')
        case null:
        case '':
            return __('Unknown')
        default:
            return status
    }
}

export default function ActionLogStatusCell({row: {status}}) {
    return (
        <Definition
            className={getStatusClass(status)}
            Icon={getStatusIcon(status)}
            title={getStatusTitle(status)}
        />
    )
}
