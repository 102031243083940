import React, {Component} from 'react'
import getHocName from 'utils/getHocName'

export default function withPoller(
    interval,
    action: (Object) => any,
    condition?: (Object) => boolean
) {
    condition = condition || (() => true)

    return (WrappedComponent) =>
        class extends Component {
            static displayName = getHocName('withPoller', WrappedComponent)

            iId: ?number

            constructor(props) {
                super(props)

                if (condition(props)) {
                    this.startPoller()
                }
            }

            componentWillUnmount() {
                this.stopPoller()
            }

            tick = () => {
                action(this.props)
            }

            startPoller() {
                if (!this.iId) {
                    this.iId = setInterval(this.tick, interval)
                }
            }

            stopPoller() {
                if (this.iId) {
                    clearInterval(this.iId)
                    this.iId = null
                }
            }

            componentDidUpdate(prevProps, prevState) {
                if (condition(prevProps)) {
                    this.startPoller()
                } else {
                    this.stopPoller()
                }
            }

            render() {
                return <WrappedComponent {...this.props} />
            }
        }
}
