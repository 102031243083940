import path from 'utils/path'
import __ from 'utils/i18n'

import {CommonComponentRoute} from 'utils/routing'

import EventRulesPageContent from 'pages/EventRules/EventRulesPageContent'
import ZoneTypesPage from './ZoneTypes/ZoneTypesPage'
import NotificationTypesPage from './NotificationTypes/NotificationTypesPage'
import EventTypesPage from './EventTypes/EventTypesPage'
import StateTypesPage from './StateTypes/StateTypesPage'

export const routing = [
    new CommonComponentRoute({
        path: path('system.eventRules'),
        component: EventRulesPageContent,
        label: __('Event Rule'),
        exact: true,
    }),
    new CommonComponentRoute({
        path: path('system.eventRules.zoneTypes'),
        component: ZoneTypesPage,
        label: __('Zone Types'),
    }),
    new CommonComponentRoute({
        path: path('system.eventRules.notificationTypes'),
        component: NotificationTypesPage,
        label: __('Notification'),
    }),
    new CommonComponentRoute({
        path: path('system.eventRules.stateTypes'),
        component: StateTypesPage,
        label: __('State'),
    }),
    new CommonComponentRoute({
        path: path('system.eventRules.eventTypes'),
        component: EventTypesPage,
        label: __('Events'),
    }),
]
