import {handleActions} from 'redux-actions'

const defaultState = {
    lastList: null,
    lastScope: null,
}

import {update} from './globalListActions.js'

export default handleActions(
    {
        [update](state, {payload: {lastList, lastScope}}) {
            return {
                lastList,
                lastScope,
            }
        },
    },
    defaultState
)
