import withForm from 'containers/withForm'
import EventTypeForm from './EventTypeForm'
import {validation} from './EventTypeFieldset'
import {addEventType} from 'modules/forms/handlers'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {__} from 'utils/i18n'

export default compose(
    connect(() => ({
        header: __('Add Event Type'),
        rules: validation,
    })),
    withForm(addEventType)
)(EventTypeForm)
