import {takeEvery, all, call, put} from 'redux-saga/effects'
import * as actions from './actions'
import * as api from 'api/panel/meteoMetrics'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([takeEvery(actions.fetch, fetchMeteoMetrics)])
}

function* fetchMeteoMetrics() {
    try {
        const data = yield call(api.fetch)

        yield put(actions.receive(data))
    } catch (error) {
        yield put(actions.receive(error))
        snackShow(error.message)
    }
}
