import RunnersTable from './RunnersTable'
import RunnersGridBar from './RunnersGridBar'
import RunnersSearch from './RunnersSearch'

import Page from 'ipmp-react-ui/Page'
import withProps from 'containers/withProps'

export default withProps({
    Top: RunnersSearch,
    Bar: RunnersGridBar,
    Content: RunnersTable,
})(Page)
