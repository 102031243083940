import createListActions from 'modules/higherOrder/createListActions'
import {createAction} from 'redux-actions'

export const fetch = createAction('BASIC_CONFIGURATIONS/PUSH/FETCH', (basicConfigId) => ({
    basicConfigId,
}))

export const {
    init,
    receive,
    reset,
    setStart,
    setQuery,
    setFilters,
    clearFilters,
    addFilters,
    removeFilters,
    fetchSuggests,
    receiveSuggests,
} = createListActions('BASIC_CONFIGURATIONS/PUSH')
