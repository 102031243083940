import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {download} from 'permissions/panel/configuration/actions'

import {refresh} from 'modules/panels/configuration/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'

import {MenuItem} from 'ipmp-react-ui/Menu'
import {ReactComponent as IconDownload} from 'ipmp-react-ui/icons/download.svg'

import {__} from 'utils/i18n'
import {withSelectionFeature} from 'containers/withFeature'
import {CONFIGURATION_DOWNLOAD} from 'constants/features'

export const DownloadConfigurationItem = compose(
    withPermission({isVisible: download}),
    withVisibility(),
    withProps(() => ({
        Icon: IconDownload,
        children: __('Refresh configuration'),
    })),
    withSelectionHandler()
)(MenuItem)

export default compose(
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                onClick: refresh,
            },
            dispatch
        )
    ),
    withSelectionFeature(CONFIGURATION_DOWNLOAD)
)(DownloadConfigurationItem)
