import React from 'react'

import ProcessesGridBar from './ProcessesGridBar'
import ProcessesTable from './ProcessesTable'
import ProcessesSearch from './ProcessesSearch'

import Page from 'ipmp-react-ui/Page'

export default class ProcessesPage extends Page {
    renderTop() {
        return <ProcessesSearch />
    }

    renderBar() {
        return <ProcessesGridBar />
    }

    renderContent() {
        return <ProcessesTable />
    }
}
