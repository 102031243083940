import React, {Component} from 'react'
import {compose} from 'redux'
import classnames from 'classnames'
import {withPermission} from 'containers/withPermission'
import PropTypes from 'prop-types'

import {ReactComponent as IconPanel} from 'ipmp-react-ui/icons/panel.svg'
import {ReactComponent as IconUser} from 'ipmp-react-ui/icons/user.svg'
import {ReactComponent as DropDownIcon} from 'ipmp-react-ui/icons/drop-down.svg'
import assignedUnitUsers from 'permissions/interactiveUsers/recentHistoryAssignedUnitUsersList'
import {withVisibility} from 'containers/withVisibility'

class InteractiveUserEmailComponent extends Component {
    render() {
        const {interactiveUserEmail} = this.props
        return (
            <div className="recentHistory-item-info recentHistory-item-info--userEmail">
                <div className="recentHistory-item-info-iconWrapper">
                    <IconUser />
                </div>
                {interactiveUserEmail}
            </div>
        )
    }
}

const InteractiveUserEmail = compose(
    withPermission({isVisible: assignedUnitUsers}),
    withVisibility()
)(InteractiveUserEmailComponent)

export default class RecentHistoryItem extends Component {
    static propTypes = {
        interactiveUserEmail: PropTypes.string,
        panelSerial: PropTypes.string,
        children: PropTypes.object,
    }

    state = {
        isPanelListOpened: false,
    }

    openPanelList = () => this.setState({isPanelListOpened: true})

    closePanelList = () => this.setState({isPanelListOpened: false})

    togglePanelList = () => {
        if (this.state.isPanelListOpened) {
            this.closePanelList()
        } else {
            this.openPanelList()
        }
    }

    renderPanelList = () => {
        const {children} = this.props
        return children
    }

    render() {
        const {panelSerial, interactiveUserEmail} = this.props
        const {isPanelListOpened} = this.state
        return (
            <div
                className={classnames('recentHistory-item', {
                    'recentHistory-item--active': isPanelListOpened,
                })}
            >
                <header
                    className="recentHistory-item-header"
                    onClick={this.togglePanelList}
                >
                    <DropDownIcon className="recentHistory-item-header-dropDownIcon" />
                    <div className="recentHistory-item-infoWrapper">
                        <div className="recentHistory-item-info recentHistory-item-info--panelSerial">
                            <div className="recentHistory-item-info-iconWrapper">
                                <IconPanel />
                            </div>
                            {panelSerial}
                        </div>
                        <InteractiveUserEmail
                            interactiveUserEmail={interactiveUserEmail}
                        />
                    </div>
                </header>
                <div className="recentHistory-item-panelList">
                    {isPanelListOpened && this.renderPanelList()}
                </div>
            </div>
        )
    }
}
