import createRowsResponseParser from 'api/base/createRowsResponseParser'
import mapValidationErrors from 'api/base/mapValidationErrors'
import {mapFormData, keysMap} from 'api/group'
import {get, post} from 'api/http'
import _get from 'lodash-es/get'
import _forEach from 'lodash-es/forEach'
import _camelCase from 'lodash-es/camelCase'
import has from 'lodash-es/has'

import dataMapper from 'utils/dataMapper'

const mapValidatorCommunicatorError = (errors) => {
    if (errors.details && errors.details.communications) {
        _forEach(errors.details.communications, (val, key) => {
            errors.details[_camelCase(key)] = val
        })
        delete errors.details.communications
    }
    return errors
}

export const mapGroupCSLink = dataMapper((link) => ({
    profileId: parseInt(link.etp_id),
    centralStationId: parseInt(link.cls_id),
}))

export const mapLanguage = dataMapper((data) => ({
    id: parseInt(data.ugl_id),
    name: data.ugl_name,
    short: data.ugl_short,
    locale: data.ugl_locale,
    isDefault: data.ugl_default === 'y',
}))

export const mapGroup = dataMapper((group) => ({
    ...group,
    communications: _get(group, 'communications', {}),
    links:
        has(group, 'links') && group.links
            ? group.links.map((link) => mapGroupCSLink(link))
            : [],
}))

export function fetch({start = 0, perPage: count = 10, query, exclude = []}) {
    return get('/group/getallgroups', {start, count, query, 'exclude[]': exclude}).then(
        createRowsResponseParser(mapGroup)
    )
}

export function remove(groupIds) {
    return post('/group/remove', {groupIds})
}

export function add(data) {
    return post('/group/add', {form: mapFormData(data)}).catch((e) =>
        mapValidationErrors(mapValidatorCommunicatorError(e), keysMap)
    )
}

export function edit(id, data) {
    return post('/group/edit', {
        form: mapFormData({id, ...data}),
    }).catch((e) => mapValidationErrors(mapValidatorCommunicatorError(e), keysMap))
}

export function fetchGroupMessagingLanguages() {
    return get('/group/languages').then(({count, rows}) => ({
        count,
        rows: rows.map(mapLanguage),
    }))
}
