import {createAction} from 'redux-actions'

export const remember = createAction(
    'PANELS/LAST/REMEMBER',
    ({panel, path, masterUserToRemember}) => ({
        panel,
        path,
        masterUserToRemember,
    })
)
export const forget = createAction('PANELS/LAST/FORGET')
export const forgetPanel = createAction('PANEL/ONE/FORGET', ({panelId}) => ({panelId}))
export const fetchInteractiveUserPanels = createAction(
    'PANELS/LAST/FETCH_INTERACTIVE_USER_PANELS'
)
export const receiveInteractiveUserPanels = createAction(
    'PANELS/LAST/RECEIVE_INTERACTIVE_USER_PANELS'
)

// Fetch interactive users, assign to master user on panel
export const fetchPanelsInteractiveUser = createAction(
    "PANELS/LAST/FETCH_PANEL'S_INTERACTIVE_USERS"
)
export const receivePanelsInteractiveUser = createAction(
    "PANELS/LAST/RECEIVE_PANEL'S_INTERACTIVE_USERS"
)
