import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import list from 'permissions/actionLog/list'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withActionLog from 'containers/withActionLog'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'
import Pager from 'ipmp-react-ui/Pager'

class ActionLogBar extends Component {
    static propTypes = {
        start: PropTypes.number,
        total: PropTypes.number,
        perPage: PropTypes.number,
        setStart: PropTypes.func,
        onPerPageChange: PropTypes.func,
    }

    render() {
        const {start, total, perPage, setStart, onPerPageChange} = this.props

        return (
            <Bar orientation={RIGHT}>
                <Pager
                    start={start}
                    total={total}
                    perPage={perPage}
                    onPageChange={setStart}
                    onPerPageChange={onPerPageChange}
                />
            </Bar>
        )
    }
}

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withActionLog()
)(ActionLogBar)
