import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import withPanel, {withRouterPanelId} from 'containers/withPanel'
import withProps from 'containers/withProps'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import permission from 'permissions/panel/devices/diagnostic'

import Void from 'components/Void'
import {withFeatureRejection} from 'containers/withFeature'
import {selectDiagnosticFeature} from 'modules/features/store/selectors'

import {MenuItem} from 'ipmp-react-ui/Menu'
import {ReactComponent as IconDiscovery} from 'ipmp-react-ui/icons/discovery.svg'

import {readDiagnostic} from 'modules/panels/store/actions'

import {__} from 'utils/i18n'

export default compose(
    withPanel(),
    withRouterPanelId(),
    withFeatureRejection(selectDiagnosticFeature, Void),
    withPermission({isVisible: permission}),
    withVisibility(),
    withProps(() => ({
        Icon: IconDiscovery,
        title: __('Read panel diagnostic'),
        children: __('Read Diagnostic'),
    })),
    connect(null, (dispatch, {panel}) =>
        bindActionCreators(
            {
                onClick: () => readDiagnostic(panel.id),
            },
            dispatch
        )
    )
)(MenuItem)
