import has from 'lodash-es/has'

import dataMapper from 'utils/dataMapper'
import {TROUBLE_TYPE_PANEL_MARK_FOR_SERVICE} from 'constants/troubleType'
import {SEVERITY_ALARM, SEVERITY_ALERT, SEVERITY_TROUBLE} from 'constants/severityType'
import {isNeoSerial, getVendor} from 'utils/panelType'
import parseDate from './base/parseDate'

import {
    DEVICE_TYPE_CONTROL_PANEL,
    DEVICE_TYPE_WIRED_KEYPAD,
    DEVICE_TYPE_ZONE_EXPANDER,
    DEVICE_TYPE_OUTPUT_EXPANDER,
    DEVICE_TYPE_POWER_SUPPLY,
    DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS,
    DEVICE_TYPE_PGH,
    DEVICE_TYPE_COMMUNICATOR,
    DEVICE_TYPE_AUDIO_VERIFICATION,
    DEVICE_TYPE_POWER_LINK,
} from 'constants/deviceType'
import * as features from 'constants/features'

export const mapFault = dataMapper((fault) => {
    return {
        id: parseInt(fault.utf_id),
        type: fault.iow_rest_name,
        severity: fault.iow_type && fault.iow_type.toUpperCase(),
        deviceType: fault.device_type,
        zone: fault.utz_zone_id ? parseInt(fault.utz_zone_id) : null,
        comment: fault.utf_comment,
        inMemory: fault.in_memory,
        isSuspended: fault.suspended,
        isMarkForService: fault.iow_rest_name === TROUBLE_TYPE_PANEL_MARK_FOR_SERVICE,
    }
})

const warningsOrder = [SEVERITY_ALARM, SEVERITY_ALERT, SEVERITY_TROUBLE]

export const sortFaults = (faults) =>
    faults.sort((f1, f2) => {
        if (f1.isSuspended !== f2.isSuspended) {
            return f1.isSuspended - f2.isSuspended
        }

        const p1 = warningsOrder.indexOf(f1.severity)
        const p2 = warningsOrder.indexOf(f1.severity)

        if (p1 !== p2) {
            return p1 - p2
        }

        if (f1.name !== f2.name) {
            return f1.name > f2.name ? 1 : -1
        }

        return 0
    })

export const mapFaults = (faults) => sortFaults(faults.map(mapFault))

const mapConfigurationFeatures = (configuration, untId) =>
    configuration
        ? {
              [features.UPLOAD]: configuration[features.UPLOAD],
              [features.DOWNLOAD]: configuration[features.DOWNLOAD],
          }
        : {
              [features.UPLOAD]: false,
              [features.DOWNLOAD]: false,
          }

const mapApplicationsFeatures = (applications) =>
    applications
        ? {
              [features.USER]: applications[features.USER],
              [features.INSTALLER]: applications[features.INSTALLER],
          }
        : {
              [features.USER]: false,
              [features.INSTALLER]: false,
          }

const mapFeatures = dataMapper((data) => ({
    [features.REFRESH_STATE]: data[features.REFRESH_STATE],
    [features.CONFIGURATION]: mapConfigurationFeatures(data[features.CONFIGURATION]),
    [features.REPORTS]: data[features.REPORTS],
    [features.APPLICATIONS]: mapApplicationsFeatures(data[features.APPLICATIONS]),
    [features.REMOTE_INSPECTION]: data[features.REMOTE_INSPECTION],
    [features.EVENTS]: data[features.EVENTS],
    [features.DISCOVERY]: data[features.DISCOVERY],
    [features.ASSIGNABLE]: data[features.ASSIGNABLE],
}))

export const mapPanel = dataMapper((data) => ({
    id: parseInt(data.unt_id),
    account: data.unt_account,
    model: data.unt_model,
    family: data.family,
    serial: data.unt_serial,
    isNeo: isNeoSerial(data.unt_serial),
    vendor: getVendor({family: data.family}),
    configuratorApp: data.unt_installer_remote_access === 'allow',
    userApp: data.unt_remote_access === 'allow',
    alerts: parseInt(data._unt_alerts),
    alarms: parseInt(data._unt_alarms),
    modules: data.modules,
    group: data._utg_name,
    groupId: parseInt(data.utg_id),
    hasTroubles: !!data.faults && data.faults.length > 0,
    removing: false, // todo mark from database!!!
    rri: data.uri_last_result,
    rriDate: parseDate(data.urir_created_timestamp),
    user: data.usr_name,
    userId: data.usr_id ? parseInt(data.usr_id) : null,
    faults: data.faults ? mapFaults(data.faults) : [],
    faultsSince: parseDate(data.ufs_created_timestamp),
    isActivated: data.unt_activated === '1',
    features: has(data, 'features') ? mapFeatures(data.features) : null,
    configurationName: data.cgm_name,
    customerName: data.unt_contact_name || '',
    customerEmail: data.unt_contact_email || '',
    customerPhone: data.unt_contact_phone || '',
    customerAddress: data.unt_contact_address || '',
    customerRemark: data.unt_remark || '',
    customerRemark2: data.unt_remark_2 || '',
    customerRemark3: data.unt_remark_3 || '',
}))

export const keysMap = {
    id: 'unt_id',
    account: 'unt_account',
    model: 'unt_model',
    serial: 'unt_serial',
    configuratorApp: 'unt_installer_remote_access',
    userApp: 'unt_remote_access',
    alerts: '_unt_alerts',
    alarms: '_unt_alarms',
    keepAlive: '_unt_gprs_ka_enabled',
    bba: '_unt_module_bb',
    gprs: '_unt_module_gprs',
    group: '_utg_name',
    fault: 'iow_rest_name',
    user: 'usr_name',
    userId: 'usr_id',
    hasTroubles: 'has_troubles',
    isActivated: 'unt_activated',
    configurationName: 'cgm_name',
    [DEVICE_TYPE_CONTROL_PANEL]: `utz_firmware_version_${DEVICE_TYPE_CONTROL_PANEL}`,
    [DEVICE_TYPE_POWER_LINK]: `utz_firmware_version_${DEVICE_TYPE_POWER_LINK}`,
    [DEVICE_TYPE_WIRED_KEYPAD]: `utz_firmware_version_${DEVICE_TYPE_WIRED_KEYPAD}`,
    [DEVICE_TYPE_ZONE_EXPANDER]: `utz_firmware_version_${DEVICE_TYPE_ZONE_EXPANDER}`,
    [DEVICE_TYPE_OUTPUT_EXPANDER]: `utz_firmware_version_${DEVICE_TYPE_OUTPUT_EXPANDER}`,
    [DEVICE_TYPE_POWER_SUPPLY]: `utz_firmware_version_${DEVICE_TYPE_POWER_SUPPLY}`,
    [DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS]: `utz_firmware_version_${DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS}`,
    [DEVICE_TYPE_PGH]: `utz_firmware_version_${DEVICE_TYPE_PGH}`,
    [DEVICE_TYPE_COMMUNICATOR]: `utz_firmware_version_${DEVICE_TYPE_COMMUNICATOR}`,
    [DEVICE_TYPE_AUDIO_VERIFICATION]: `utz_firmware_version_${DEVICE_TYPE_AUDIO_VERIFICATION}`,
}
