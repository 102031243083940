import withLifeCycle from 'containers/withLifeCycle'
import {compose} from 'redux'

import Table from 'ipmp-react-ui/Table'

import withLoader from 'containers/withLoader'
import withProps from 'containers/withProps'
import withTableColumnsPermission from 'containers/withTableColumnsPermission'
import {__} from 'utils/i18n'
import withEventTypes from 'containers/withEventTypes'
import RemoveEventType from './TableCell/RemoveEventType'
import EditEventType from './TableCell/EditEventType'

const columns = [
    {
        name: () => __('Event Type'),
        render: ({description}) => description,
    },
    {
        tooltip: false,
        width: 50,
        fixed: true,
        component: EditEventType,
    },
    {
        tooltip: false,
        width: 50,
        fixed: true,
        component: RemoveEventType,
    },
]

export default compose(
    withEventTypes(),
    withLifeCycle({
        onMount({init, startPoll}) {
            init()
            startPoll()
        },

        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withLoader(),
    withProps({
        columns,
        fullHeight: true,
        emptyMessage: () => __('No event types found'),
    }),
    withTableColumnsPermission()
)(Table)
