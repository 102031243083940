import {compose} from 'redux'
import get from 'lodash-es/get'

import {cancelSchedule} from 'permissions/remoteInspections/selection'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withConfirmation from 'containers/withConfirmation'
import {ifSelection, withSelectionHandler} from 'containers/withSelection'
import withRemoteInspections from 'containers/withRemoteInspections'

import {ReactComponent as IconStop} from 'ipmp-react-ui/icons/stop.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: cancelSchedule}),
    withVisibility(),
    withRemoteInspections(),
    withProps(({cancel}) => ({
        onClick: cancel,
        message: __('Do you really want to cancel schedule?'),
        positive: __('Cancel'),
        title: __('Schedule cancellation'),
        label: __('Cancel'),
        Icon: IconStop,
    })),
    withSelectionHandler(),
    withConfirmation(),
    ifSelection(
        (id, {remoteInspections}) => !!get(remoteInspections, ['store', id, 'next'])
    )
)(Button)
