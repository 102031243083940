import {combineReducers} from 'redux'

import list from './list/reducer'
import store from './store/reducer'
import permissions from './permissions/reducer'
import assignableRoles from './assignableRoles/reducer'

export default combineReducers({
    list,
    store,
    permissions,
    assignableRoles,
})
