import {createSelector} from 'reselect'

export const selectAllZoneTypes = createSelector(
    (state) => state.zoneTypes.store.byIds,
    (zoneTypesByIds) => ({
        data: Object.values(zoneTypesByIds),
    })
)

export const selectZoneTypeNameById = createSelector(
    (state) => state.zoneTypes.store.byIds,
    (_, {id}) => id,
    (storeByIds, id) => ({name: storeByIds[id] && storeByIds[id].name})
)

export const selectZoneTypeSuggests = createSelector(
    (state) => state.zoneTypes.store.byIds,
    (storeByIds) => {
        const data = new Set(Object.values(storeByIds).map((item) => item.name))
        return {
            count: data.size,
            values: [...data],
        }
    }
)

export const selectZoneTypeFilter = createSelector(
    (state) => state.zoneTypes.store.byIds,
    (_, query) => query,
    (storeByIds, query) =>
        Object.values(storeByIds)
            .filter((item) => item.name === query)
            .map((item) => item.id)
)
