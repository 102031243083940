import {createSelector} from 'reselect'
import {selectPanelInteractiveUsers} from 'modules/panelInteractiveUsers/store/selectors'

export const selectPanelInteractiveUsersList = createSelector(
    (state) => state.panelInteractiveUsers.list,
    selectPanelInteractiveUsers,
    ({page, isLoading}, store) => ({
        rows: page.map((id) => store[id] || {}),
        isLoading,
    })
)
