import React, {Component} from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash-es/noop'

import Checkbox from 'ipmp-react-ui/Checkbox'

import stopPropagation from 'utils/stopPropagation'
import getInstallerStatusTitle from 'constants/installerStatus'

export default class InstallerStatus extends Component {
    static propTypes = {
        row: PropTypes.shape({
            id: PropTypes.number,
            status: PropTypes.string,
        }).isRequired,
        isPermitted: PropTypes.bool,
    }

    getLabel() {
        const {
            row: {status},
        } = this.props

        return getInstallerStatusTitle(status)
    }

    getClassName() {
        const {
            row: {status},
        } = this.props

        switch (status) {
            case 'pending':
                return 'warn'
            case 'rejected':
                return 'danger'
        }
    }

    isIndeterminated() {
        const {
            row: {status},
        } = this.props

        return status === 'pending'
    }

    isChecked() {
        const {
            row: {status},
        } = this.props

        return status === 'accepted'
    }

    onChange = noop

    isDisabled() {
        const {isPermitted} = this.props
        return !isPermitted
    }

    render() {
        return (
            <div className={this.getClassName()} onClick={stopPropagation}>
                <Checkbox
                    checked={this.isChecked()}
                    disabled={this.isDisabled()}
                    indeterminate={this.isIndeterminated()}
                    label={this.getLabel()}
                    onChange={this.onChange}
                />
            </div>
        )
    }
}
