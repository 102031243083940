import {takeEvery, call, put, select} from 'redux-saga/effects'
import last from 'lodash-es/last'

import * as actions from './actions'
import {purgeStore} from 'modules/panels/one/actions'
import {
    fetchInteractiveUserPanels,
    interactiveMasterUsersAssignedToPanelsByPanelIds,
} from 'api/interactiveUsers'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield takeEvery(actions.remember, watchRemember)
    yield takeEvery(actions.fetchInteractiveUserPanels, watchFetchInteractiveUserPanels)
    yield takeEvery(actions.fetchPanelsInteractiveUser, watchFetchPanelsInteractiveUser)
}

function* watchFetchInteractiveUserPanels({payload: interactiveUserId}) {
    try {
        const {rows} = yield call(fetchInteractiveUserPanels, {interactiveUserId})

        yield put(
            actions.receiveInteractiveUserPanels({
                interactiveUserId,
                panels: rows,
            })
        )
    } catch (error) {
        yield put(
            actions.receiveInteractiveUserPanels({interactiveUserId, errorObj: error})
        )
        yield put(snackShow(error.message))
    }
}

function* watchRemember({payload}) {
    const newPanelId = payload.panel.id
    const currentPanelId = yield select(
        (state) => last(state.panels.recent.historyItems).panel.id
    )

    if (newPanelId !== currentPanelId) {
        yield put(purgeStore())
    }
}

function* watchFetchPanelsInteractiveUser() {
    const historyItems = yield select((state) => state.panels.recent.historyItems)
    const panelIds = historyItems.map(({panel}) => panel.id)

    try {
        const {rows} = yield call(interactiveMasterUsersAssignedToPanelsByPanelIds, {
            filters: {panelIds},
        })

        yield put(actions.receivePanelsInteractiveUser(rows))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
