import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'

import Checkbox from 'ipmp-react-ui/Checkbox'

import {__} from 'utils/i18n'
import {FormSection} from 'ipmp-react-ui/Form'
import getContentName from 'constants/reportContentOption'
import getMeteoMetricName from 'constants/reportMeteoMetric'
import Select from 'ipmp-react-ui/Select'
import {Option} from 'ipmp-react-ui/Select'
import {METEO_REPORT_DAY} from 'constants/reportMeteoPeriods'
import omit from 'lodash-es/omit'

export default class extends Component {
    static propTypes = {
        contentOptions: PropTypes.object,
        meteoMetrics: PropTypes.object,
    }

    state = {
        csvEnabled: true,
        pdfEnabled: false,
        checkedMeteoMetrics: {},
    }

    meteoPeriods = {
        day: __('Day'),
        week: __('Week'),
        month: __('Month'),
        all: __('All'),
    }

    changeFormatPdf = () => {
        return this.setState(({csvEnabled, pdfEnabled}) => ({
            csvEnabled: !csvEnabled && pdfEnabled ? true : csvEnabled,
            pdfEnabled: !pdfEnabled,
        }))
    }

    changeFormatCsv = () => {
        // PDF is disabled, so csv can't be unchecked
        if (!this.props.panelIds) {
            return
        }

        return this.setState(({csvEnabled, pdfEnabled}) => ({
            pdfEnabled: !pdfEnabled && csvEnabled ? true : pdfEnabled,
            csvEnabled: !csvEnabled,
        }))
    }

    changeMeteo = ({target: {checked}}, name) => {
        const {checkedMeteoMetrics} = this.state

        if (checked) {
            this.setState({
                checkedMeteoMetrics: {...checkedMeteoMetrics, [name]: checked},
            })
        } else {
            const items = {...checkedMeteoMetrics}
            this.setState({
                checkedMeteoMetrics: omit(items, [name]),
            })
        }
    }

    // TBD: hardcoded export formats ids
    render() {
        const {pdfEnabled, csvEnabled, checkedMeteoMetrics} = this.state
        const {contentOptions, meteoMetrics, panelIds} = this.props

        return (
            <div>
                <Checkbox
                    {...{
                        checked: csvEnabled,
                        onChange: this.changeFormatCsv,
                        value: 2,
                        name: 'export',
                    }}
                >
                    {__('CSV')}
                </Checkbox>

                <Checkbox
                    {...{
                        disabled: !panelIds,
                        checked: pdfEnabled,
                        onChange: this.changeFormatPdf,
                        value: 1,
                        name: 'export',
                    }}
                >
                    {!panelIds ? (
                        <Fragment>
                            {__('PDF')}{' '}
                            <small>
                                {__('It is not possible to use for all panels')}
                            </small>
                        </Fragment>
                    ) : (
                        __('PDF')
                    )}
                </Checkbox>

                {pdfEnabled && (
                    <FormSection title={__('Content Options')}>
                        {Object.keys(contentOptions).map((key) => (
                            <Checkbox {...{key, value: key, name: 'contentOptions'}}>
                                {getContentName(contentOptions[key].name)}
                            </Checkbox>
                        ))}
                    </FormSection>
                )}
                {pdfEnabled && (
                    <FormSection title={__('Meteo Options')}>
                        {Object.keys(meteoMetrics).map((key) => (
                            <Checkbox
                                {...{
                                    key,
                                    value: key,
                                    name: 'meteoMetrics',
                                    onChange: (event) =>
                                        this.changeMeteo(event, meteoMetrics[key].name),
                                }}
                            >
                                {getMeteoMetricName(meteoMetrics[key].name)}
                            </Checkbox>
                        ))}
                        {!!Object.keys(checkedMeteoMetrics).length && (
                            <Select
                                {...{
                                    label: __('Period'),
                                    name: 'meteoMetricsPeriod',
                                    defaultValue: METEO_REPORT_DAY,
                                }}
                            >
                                {Object.keys(this.meteoPeriods).map((key) => (
                                    <Option
                                        {...{
                                            key,
                                            value: key,
                                            label: this.meteoPeriods[key],
                                        }}
                                    />
                                ))}
                            </Select>
                        )}
                    </FormSection>
                )}
            </div>
        )
    }
}
