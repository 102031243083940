import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import Pager from 'ipmp-react-ui/Pager'
import {withInstallerAddPanelTable} from 'containers/withInstallerPanelsTable'

export default compose(
    withInstallerAddPanelTable(),
    connect(null, (dispatch, {setStart}) =>
        bindActionCreators(
            {
                setStart,
                onPageChange: setStart,
            },
            dispatch
        )
    )
)(Pager)
