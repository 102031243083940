import {compose} from 'redux'

import list from 'permissions/events/list'

import withEvents from 'containers/withEvents'

import Pager from 'ipmp-react-ui/Pager'
import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withEvents(),
    withProps(({isDataLoading}) => ({
        disabled: isDataLoading,
        sort: [],
    }))
)(Pager)
