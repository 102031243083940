import React, {Fragment, PureComponent} from 'react'
import PropTypes from 'prop-types'

import processErrorMessage from 'constants/processError'

import {ReactComponent as IconAlert} from 'ipmp-react-ui/icons/alert.svg'
import {ReactComponent as IconCompleted} from 'ipmp-react-ui/icons/check.svg'

import Definition from 'ipmp-react-ui/Definition'
import ProgressBar from 'ipmp-react-ui/ProgressBar'
import Spinner from 'ipmp-react-ui/Spinner'
import {__} from 'utils/i18n'

export default class ProcessStatus extends PureComponent {
    static propTypes = {
        row: PropTypes.shape({
            isFailedToStart: PropTypes.bool,
            errorMessage: PropTypes.string,
            error: PropTypes.string,
            details: PropTypes.string,
            percentage: PropTypes.number,
            id: PropTypes.number,
            status: PropTypes.string,
            isRunning: PropTypes.bool,
            isFailed: PropTypes.bool,
            isSuccessful: PropTypes.bool,
        }),
        isStopping: PropTypes.bool,
    }

    renderNotStarted() {
        const {isFailedToStart, errorMessage} = this.props.row
        if (isFailedToStart) {
            return (
                <Definition
                    className="danger"
                    Icon={IconAlert}
                    title={errorMessage || __('Failed to start')}
                />
            )
        }

        return <Definition Icon={Spinner} title={__('Starting…')} />
    }

    renderFailed() {
        const {error, errorMessage} = this.props.row

        return (
            <Definition
                className="danger"
                Icon={IconAlert}
                title={processErrorMessage(error, errorMessage)}
            />
        )
    }

    renderRunning() {
        const {details, percentage} = this.props.row

        if (!details) {
            return (
                <ProgressBar
                    max={100}
                    value={percentage || 0}
                    indeterminate={percentage === 0}
                />
            )
        }

        return (
            <Fragment>
                <ProgressBar
                    max={100}
                    value={percentage || 0}
                    indeterminate={percentage === 0}
                    inline
                />
                {details}
            </Fragment>
        )
    }

    renderSuccessful() {
        return (
            <Definition
                className="success"
                Icon={IconCompleted}
                title={__('Succeeded')}
            />
        )
    }

    renderStopping() {
        return (
            <Definition className="danger" Icon={IconAlert} title={__('Stopping...')} />
        )
    }

    renderStatus = () => this.props.row.status

    render() {
        const {
            isStopping,
            row: {isNotRunned, isRunning, isFailed, isSuccessful},
        } = this.props

        switch (true) {
            case isStopping:
                return this.renderStopping()

            case isNotRunned:
                return this.renderNotStarted()

            case isFailed:
                return this.renderFailed()

            case isRunning:
                return this.renderRunning()

            case isSuccessful:
                return this.renderSuccessful()

            default:
                return this.renderStatus()
        }
    }
}
