import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'
import withConfirmation from 'containers/withConfirmation'
import withProps from 'containers/withProps'
import withInstallers from 'containers/withInstallers'
import __ from 'utils/i18n'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {reject} from 'permissions/installers/actions'

export default compose(
    withPermission({
        isVisible: reject,
    }),
    withVisibility(),
    withInstallers(),
    withProps(({resetPassword, installer}) => ({
        label: __('Reset Password'),
        onClick: () => resetPassword(installer.id),

        message: __('Do you really want to reset password for %s?', installer.email),
        title: __('Reset password'),
    })),
    withConfirmation()
)(Button)
