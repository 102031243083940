import {compose} from 'redux'
import selection from 'permissions/centralStations/selection'

import withSelection from 'containers/withSelection'
import withCentralStations from 'containers/withCentralStations'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import SelectionDropDown from 'components/SelectionDropDown'

export default compose(
    withPermission({isVisible: selection}),
    withVisibility(),
    withSelection(),
    withCentralStations()
)(SelectionDropDown)
