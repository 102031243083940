// These constants from `event_profile` table. Could be changed.
import last from 'lodash-es/last'

import __ from 'utils/i18n'
import {mapEvent} from 'api/events.map'
import {warn} from 'utils/log'

export const EVENT_SEVERITY_ALERT = 'alert'
export const EVENT_SEVERITY_ALARM = 'alarm'
export const EVENT_SEVERITY_RESTORE = 'restore'
export const EVENT_SEVERITY_SECURITY_OPEN_CLOSE = 'securityopenclose'
export const EVENT_SEVERITY_CAMERA_BEING_VIEWED = 'camerabeingviewed'
export const EVENT_SEVERITY_CAMERA_TROUBLE = 'cameratrouble'
export const EVENT_SEVERITY_HD_ON_OFF = 'hdonoff'
export const EVENT_SEVERITY_HD_TROUBLE = 'hdtrouble'
export const EVENT_SEVERITY_ONLINE = 'online'
export const EVENT_SEVERITY_OFFLINE = 'offline'
export const EVENT_SEVERITY_NOTICE = 'notice'
export const EVENT_SEVERITY_RRI = 'RRI'
export const EVENT_SEVERITY_OPEN_CLOSE = 'openclose'
export const EVENT_SEVERITY_TEMPERATURE = 'temperature'
export const EVENT_SEVERITY_ILLUMINANCE = 'illuminance'

// custom severity for play sound
export const EVENT_SEVERITY_OTHER = 'other'

export const eventSeverities = [
    EVENT_SEVERITY_ALERT,
    EVENT_SEVERITY_ALARM,
    EVENT_SEVERITY_RESTORE,
    EVENT_SEVERITY_SECURITY_OPEN_CLOSE,
    EVENT_SEVERITY_CAMERA_BEING_VIEWED,
    EVENT_SEVERITY_CAMERA_TROUBLE,
    EVENT_SEVERITY_HD_ON_OFF,
    EVENT_SEVERITY_HD_TROUBLE,
    EVENT_SEVERITY_ONLINE,
    EVENT_SEVERITY_OFFLINE,
    EVENT_SEVERITY_NOTICE,
    EVENT_SEVERITY_RRI,
    EVENT_SEVERITY_OPEN_CLOSE,
    EVENT_SEVERITY_TEMPERATURE,
    EVENT_SEVERITY_ILLUMINANCE,
]

const EVENT_SEVERITY_PRIORITY = [EVENT_SEVERITY_ALERT]

export const severitiesWithSounds = [
    EVENT_SEVERITY_ALARM,
    EVENT_SEVERITY_ALERT,
    EVENT_SEVERITY_SECURITY_OPEN_CLOSE,
    EVENT_SEVERITY_RESTORE,
]

export const severityForChooseSound = [...severitiesWithSounds, EVENT_SEVERITY_OTHER]

export const eventSeverityTitle = {
    [EVENT_SEVERITY_ALARM]: () => __('Alarm'),
    [EVENT_SEVERITY_ALERT]: () => __('Alert'),
    [EVENT_SEVERITY_SECURITY_OPEN_CLOSE]: () => __('Security open close'),
    [EVENT_SEVERITY_NOTICE]: () => __('Notice'),
    [EVENT_SEVERITY_RESTORE]: () => __('Restore'),
    [EVENT_SEVERITY_CAMERA_TROUBLE]: () => __('Camera trouble'),
    [EVENT_SEVERITY_HD_TROUBLE]: () => __('HD trouble'),
    [EVENT_SEVERITY_ONLINE]: () => __('Online'),
    [EVENT_SEVERITY_OFFLINE]: () => __('Offline'),
    [EVENT_SEVERITY_OTHER]: () => __('Other'),
}

const resolveSeverity = (severity) => {
    if (severitiesWithSounds.includes(severity)) {
        return severity
    }
    if (eventSeverities.includes(severity)) {
        return EVENT_SEVERITY_OTHER
    }
    warn(`Unknown event severity; ${severity}`)

    return severity
}

const resolveEventsPriorityBySeverity = (rows) => {
    const mappedRows = rows.map(mapEvent)
    for (const row in mappedRows) {
        for (const eventSeverityPriorityIndex in EVENT_SEVERITY_PRIORITY) {
            if (EVENT_SEVERITY_PRIORITY[eventSeverityPriorityIndex] === row.severity) {
                return row.severity
            }
        }
    }

    return last(mappedRows).severity
}

export function getEventSeverityFromRows(rows: Array) {
    return resolveSeverity(resolveEventsPriorityBySeverity(rows))
}

export const superVisionEventsSeverityFilter = eventSeverities
    .map((severity) => `:_etp_name=${severity};`)
    .join(' || ')
