import sounds, {SOUND_KEY_1} from './sounds'
import {err} from 'utils/log'

export default {
    play(key = SOUND_KEY_1) {
        let sound = sounds.get(key)

        if (!sound) {
            sound = sounds.get(SOUND_KEY_1)
        }

        try {
            sound.play()
        } catch (error) {
            err(`Error when trying play sound with key ${key}: ${error}`)
        }
    },
}
