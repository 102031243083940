import React, {Component} from 'react'
import PropTypes from 'prop-types'

import processType, {getProcessLinkByType} from 'constants/processType'
import path from 'utils/path'
import BatchRunnerItem from 'components/Processes/BatchRunnerItem'
import BatchRunnerStatus from 'components/Processes/BatchRunnerStatus'
import __ from 'utils/i18n'
import Error from 'ipmp-react-ui/Error'
import Spinner from 'ipmp-react-ui/Spinner'
import NavBlind, {
    NavBlindHeader,
    NavBlindList,
    NavBlindListMore,
} from 'components/Nav/NavBlind'

import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import {stop as stopPermission} from 'permissions/processes/actions'

import {selectRunnersRowsByBatchId} from 'modules/batches/batchRunners/selectors'
import {selectBatchIsTemporary} from 'modules/batches/widget/selectors'
import {stop} from 'modules/runners/store/actions'
import {withPermission} from 'containers/withPermission'
import withLifeCycle from 'containers/withLifeCycle'
import withProps from 'containers/withProps'

import {reset, startPoll, stopPoll} from 'modules/batches/batchRunners/actions'

class BatchInfo extends Component {
    static propTypes = {
        batch: PropTypes.shape({
            type: PropTypes.string,
        }),
        stoppingIds: PropTypes.array,
        runners: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                panelId: PropTypes.number.isRequired,
            })
        ),
        stop: PropTypes.func.isRequired,
        hide: PropTypes.func.isRequired,
        navBlindRefHandler: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        error: PropTypes.object,
        hasMore: PropTypes.bool,
    }

    onClose = () => {
        const {hide} = this.props

        hide()
    }

    onStop = this.props.stop

    handleClick = (panelId) => {
        const {history, batch, hide} = this.props
        const link = getProcessLinkByType(batch.type)

        if (!link) {
            return
        }

        history.push(path(link, {id: panelId}))
        hide()
    }

    onMoreClick = () => {
        const {history, batch, hide} = this.props

        history.push(path('batches.runners', {id: batch.id}))
        hide()
    }

    renderBatchItem = (runner, isStopping) => (
        <BatchRunnerItem {...runner} key={runner.id} onRowClick={this.handleClick}>
            <BatchRunnerStatus
                row={{
                    ...runner,
                }}
                isStopping={isStopping}
                isStopPermitted={this.props.isStopPermitted}
                onStop={this.onStop}
            />
        </BatchRunnerItem>
    )

    renderList = () => {
        const {runners, isLoading, error, stoppingIds} = this.props
        if (isLoading) {
            return <Spinner />
        }

        if (error) {
            return <Error error={error} />
        }

        if (!runners.length) {
            return <Error message={__('No processes')} />
        }
        return runners.map((runner) => {
            return runner && this.renderBatchItem(runner, stoppingIds.includes(runner.id))
        })
    }

    render() {
        const {hasMore, batch, navBlindRefHandler} = this.props
        const {type} = batch

        return (
            <NavBlind refHandler={navBlindRefHandler}>
                <NavBlindHeader onClose={this.onClose}>
                    {processType(type)}
                </NavBlindHeader>

                <NavBlindList>
                    {this.renderList()}

                    {hasMore && <NavBlindListMore onClick={this.onMoreClick} />}
                </NavBlindList>
            </NavBlind>
        )
    }
}

export default compose(
    withRouter,
    withPermission({
        isStopPermitted: stopPermission,
    }),
    connect(selectRunnersRowsByBatchId, (dispatch, {batchId}) =>
        bindActionCreators(
            {
                stop,
                reset: () => reset(batchId),
                startPoll,
                stopPoll,
            },
            dispatch
        )
    ),
    withLifeCycle({
        onMount({startPoll}) {
            startPoll()
        },
        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withProps(({batchId}) => ({key: batchId})),
    withLifeCycle({
        onMount({reset}) {
            reset()
        },
    }),
    connect((state, {isLoading, batchId}) => ({
        isLoading: isLoading || selectBatchIsTemporary(state, batchId),
    }))
)(BatchInfo)
