import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import {__} from 'utils/i18n'
import withSelectLoader from 'containers/withSelectLoader'

import ModalCardForm from 'ui/ModalCardForm'
import Autocomplete from 'ipmp-react-ui/Autocomplete'

import MixedVendorsError from './PushBasicConfigurationError/MixedVendorsError'

import {fetch as fetchBasicConfig} from 'modules/basicConfiguration/select/actions'
import {pushBasicConfiguration} from 'modules/panels/store/actions'
import withModalLoader from 'containers/withModalLoader'
import withInterruption from 'containers/withInterruption'

const BasicConfigSelect = withSelectLoader(
    fetchBasicConfig,
    ({basicConfiguration}) => basicConfiguration.select.isLoading,
    ({
        basicConfiguration,
        modals: {
            args: {panelIds},
        },
        panels: {
            store: {byIds},
        },
    }) =>
        Object.values(basicConfiguration.store.byIds)
            .filter(({vendor}) => vendor === byIds[panelIds[0]].configurationName)
            .map(({id, name}) => ({label: name, value: id})),
    10,
    false
)(Autocomplete)

export class PushBasicConfiguration extends Component {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        panelIds: PropTypes.arrayOf(PropTypes.number),
    }

    rules = {
        basicConfigId: {
            presence: true,
        },
    }

    handle = ({basicConfigId}) => {
        const {panelIds, handle, hide} = this.props
        handle(basicConfigId, panelIds)
        hide()
    }

    render() {
        return (
            <ModalCardForm
                onSubmit={this.handle}
                header={__('Push Basic Config')}
                rules={this.rules}
                submitLabel={__('Push')}
                {...this.props}
            >
                <BasicConfigSelect
                    label={__('Basic Configurations')}
                    name="basicConfigId"
                />
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(
        ({panels, basicConfiguration}, {panelIds}) => {
            const selectedPanels = panelIds.map((id) => panels.store.byIds[id])
            const selectedConfigModel = selectedPanels[0].family
            const configurations = Object.values(basicConfiguration.store.byIds).filter(
                ({vendor}) => vendor === selectedConfigModel
            )

            return {
                selectedPanels,
                configurations,
                selectedConfigModel,
            }
        },
        (dispatch) =>
            bindActionCreators(
                {
                    fetchBasicConfig,
                    handle: pushBasicConfiguration,
                },
                dispatch
            )
    ),
    withModalLoader(({fetchBasicConfig}) => fetchBasicConfig()),
    withInterruption(
        ({selectedPanels, selectedConfigModel}) =>
            !selectedPanels.every((panel) => panel.family === selectedConfigModel),
        MixedVendorsError
    )
)(PushBasicConfiguration)
