import __ from 'utils/i18n'
import {warn} from 'utils/log'

class WrongIntervalValue extends Error {}

class GsmRssiLevelInterval {
    top: number
    bottom: number
    _list: Array = []

    _checkValues(bottom, top) {
        switch (true) {
            case isNaN(top):
                throw new WrongIntervalValue(`top interval value must be Number: ${top}`)
            case isNaN(bottom):
                throw new WrongIntervalValue(
                    `bottom interval value must be Number: ${bottom}`
                )
            case top <= bottom:
                throw new WrongIntervalValue(
                    `Wrong values for interval\n top: ${top}\n or bottom: ${bottom}`
                )
        }
    }

    constructor(bottom: number, top: number) {
        this._checkValues(bottom, top)
        this.bottom = parseInt(bottom)
        this.top = parseInt(top)
        this._fillInterval()
    }

    _fillInterval() {
        for (let i = this.bottom; i <= this.top; i++) {
            this[i] = i
            this._list.push(i)
        }
    }

    toString() {
        return this._list.join()
    }
}

export const GSM_RSSI_BAD_LEVEL_INTERVAL = new GsmRssiLevelInterval(0, 1)
export const GSM_RSSI_POOR_LEVEL_INTERVAL = new GsmRssiLevelInterval(1, 2)
export const GSM_RSSI_OK_LEVEL_INTERVAL = new GsmRssiLevelInterval(2, 3)
export const GSM_RSSI_GOOD_LEVEL_INTERVAL = new GsmRssiLevelInterval(3, 4)
export const GSM_RSSI_STRONG_LEVEL_INTERVAL = new GsmRssiLevelInterval(4, 5)
export const GSM_RSSI_UNKNOWN_LEVEL_INTERVAL = new GsmRssiLevelInterval(6, 255)
export const GSM_RSSI_UNKNOWN_LEVEL_VALUE = -1

export function getGsmRssiLevelTitle(level: number) {
    switch (true) {
        case level in GSM_RSSI_BAD_LEVEL_INTERVAL:
            return __('Bad')
        case level in GSM_RSSI_POOR_LEVEL_INTERVAL:
            return __('Poor')
        case level in GSM_RSSI_OK_LEVEL_INTERVAL:
            return __('Ok')
        case level in GSM_RSSI_GOOD_LEVEL_INTERVAL:
            return __('Good')
        case level in GSM_RSSI_STRONG_LEVEL_INTERVAL:
            return __('Strong')
        case level === GSM_RSSI_UNKNOWN_LEVEL_VALUE:
        case level in GSM_RSSI_UNKNOWN_LEVEL_INTERVAL:
            return __('Unknown')
        default:
            warn(`Unknown GSM RSSI level: ${level}`)
            return __('Unknown')
    }
}
