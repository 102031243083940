import withLifeCycle from 'containers/withLifeCycle'
import withLoader from 'containers/withLoader'
import React from 'react'
import {compose} from 'redux'

import list from 'permissions/actionLog/list'

import {withPermission, withRejection} from 'containers/withPermission'
import withProps from 'containers/withProps'
import withActionLog from 'containers/withActionLog'

import DateTime from 'ipmp-react-ui/DateTime'
import Table from 'ipmp-react-ui/Table'

import {__} from 'utils/i18n'

import ActionLogDescriptionCell from './Cell/ActionLogDescriptionCell'
import ActionLogStatusCell from './Cell/ActionLogStatusCell'

const columns = [
    {
        name: () => __('Time'),
        maxWidth: 140,
        render: ({time}) => <DateTime datetime={time} timeFirst />,
    },
    {
        name: () => __('User'),
        width: 30,
        maxWidth: 200,
        render: ({userName}) => userName || <span className="empty">—</span>,
    },
    {
        name: () => __('Description'),
        width: 350,
        component: ActionLogDescriptionCell,
    },
    {
        name: () => __('Status'),
        maxWidth: 150,
        component: ActionLogStatusCell,
    },
]

export default compose(
    withPermission({isAllowed: list}),
    withRejection(),
    withActionLog(),
    withLifeCycle({
        onMount({init, startPoll}) {
            init()
            startPoll()
        },

        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withLoader(),
    withProps({
        fullHeight: true,
        columns,
        emptyMessage: () => __('No action logs found'),
    })
)(Table)
