import {takeEvery, all, put, select, call, fork} from 'redux-saga/effects'
import {POLL_PANEL_INTERACTIVE_USERS} from 'configs/pollers'
import isNil from 'lodash-es/isNil'

import * as api from 'api/interactiveUsers'
import * as actions from './actions'

import {update} from '../store/actions'
import toIds from 'utils/toIds'
import {getPanelId} from 'utils/panelIdMatchSelector'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import {assignUserToPanel} from 'modules/forms/handlers'
import {snackShow} from 'modules/snacks'
import updateRecentWidget from 'modules/panelInteractiveUsers/list/watchers/updateRecentWidget'

const selector = (state) => {
    const panelId = getPanelId(state)

    return {
        ...state.panelInteractiveUsers.list,
        panelId,
    }
}

export default function* () {
    yield all([
        takeEvery(actions.init, watchInit),
        takeEvery([actions.reset, actions.fetch], watchFetch),
        createListPollerSaga(actions, POLL_PANEL_INTERACTIVE_USERS, watchInvalidate),
        takeEvery(assignUserToPanel.SUCCESS, watchAssignUserToPanel),
        takeEvery(assignUserToPanel.FAILURE, watchAssignUserToPanelFailed),
    ])
}

function* watchInit({payload}) {
    yield put(actions.reset())
}

function* watchFetch() {
    const state = yield select(selector)

    try {
        const {rows, count} = yield call(api.panelInteractiveUsers, state)

        yield put(update(rows, state.panelId))

        yield put(actions.receive(toIds(rows), count))
    } catch (error) {
        yield put(actions.receive(error))
    }
}

function* watchInvalidate() {
    const state = yield select(selector)

    try {
        const {rows, count} = yield call(api.panelInteractiveUsers, state)

        yield put(update(rows, state.panelId))

        yield put(actions.receive(toIds(rows), count))
    } catch (error) {
        yield put(actions.receive(error))
    }
}

function* watchAssignUserToPanel() {
    const interactiveUserId = yield select(
        (state) => state.interactiveUserPanels.list.interactiveUserId
    )

    // On panel page
    if (!isNil(interactiveUserId)) {
        return
    }
    const state = yield select(selector)

    yield put(actions.fetch(state))

    yield fork(updateRecentWidget)
}

function* watchAssignUserToPanelFailed({payload}) {
    const interactiveUserId = yield select(
        (state) => state.interactiveUserPanels.list.interactiveUserId
    )

    // On panel page
    if (!isNil(interactiveUserId)) {
        return
    }

    yield put(snackShow(payload.error))
}
