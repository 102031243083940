import {compose} from 'redux'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'
import withRemoteInspections from 'containers/withRemoteInspections'

import {ReactComponent as ViewIcon} from 'ipmp-react-ui/icons/viewed.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import {markAsViewed} from 'permissions/remoteInspections/selection'

export default compose(
    withPermission({isVisible: markAsViewed}),
    withVisibility(),
    withRemoteInspections(),
    withProps(({markAsViewed}) => ({
        onClick: markAsViewed,
        Icon: ViewIcon,
        label: __('Mark as viewed'),
    })),
    withSelectionHandler()
)(Button)
