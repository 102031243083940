import {createSelector} from 'reselect'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'

export const selectEventsRateIsLoading = (state) => state.dashboard.eventsRate.isLoading
export const selectEventsRateData = (state) => state.dashboard.eventsRate.data
export const selectEventsRateError = (state) => state.dashboard.eventsRate.error

export const selectEventsRate = createSelector(
    [selectEventsRateIsLoading, selectEventsRateData, selectEventsRateError],
    (isLoading, data, error) => {
        const {alarms, alerts, other} = data.reduce(
            (acc, dataItem) => ({
                alarms: [
                    ...acc.alarms,
                    {
                        x: new Date(dataItem.time),
                        y: dataItem.alarms,
                    },
                ],
                alerts: [
                    ...acc.alerts,
                    {
                        x: new Date(dataItem.time),
                        y: dataItem.alerts,
                    },
                ],
                other: [
                    ...acc.other,
                    {
                        x: new Date(dataItem.time),
                        y: dataItem.other,
                    },
                ],
            }),
            {alarms: [], alerts: [], other: []}
        )

        const from = data.length
            ? maxBy(data, (dataItem) => new Date(dataItem.time).getTime()).time
            : new Date()
        const to = data.length
            ? minBy(data, (dataItem) => new Date(dataItem.time).getTime()).time
            : new Date()

        return {isLoading, alarms, alerts, other, from, to, error}
    }
)
