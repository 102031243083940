import React, {Fragment} from 'react'
import PropTypes from 'prop-types'

import ProgressBar from 'ipmp-react-ui/ProgressBar'

import ProcessStatus from './ProcessStatus'
import {ProcessStopFab} from './ProcessStopButton'

export default class BatchRunnerStatus extends ProcessStatus {
    static propTypes = {
        row: PropTypes.shape({
            percentage: PropTypes.number,
        }),
        onStop: PropTypes.func,
        isStopPermitted: PropTypes.bool,
    }

    stopProcess = () => this.props.onStop(this.props.row.id)

    renderRunning = () => {
        const {percentage, isStoppable} = this.props.row
        const {isStopPermitted} = this.props

        return (
            <Fragment>
                <ProcessStopFab
                    className="batchInfo-list-item-stopProcess"
                    onClick={this.stopProcess}
                    disabled={!isStoppable || !isStopPermitted}
                />
                <ProgressBar
                    max={100}
                    value={percentage || 0}
                    indeterminate={percentage === 0}
                />
            </Fragment>
        )
    }
}
