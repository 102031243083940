import {takeEvery, call, put, select, all} from 'redux-saga/effects'
import {retrieve} from 'api/events'
import {update} from 'modules/events/store/actions'
import {
    fetch,
    receive,
    fetchOlder,
    fetchNewer,
    checkNewer,
    setNewerCount,
    setHasOlder,
} from './actions'
import toIds from 'utils/toIds'

const limit = 10
const sortField = 'evt_id'
const sortDesc = true

export default function* () {
    yield all([
        takeEvery([fetch], watchFetch),
        takeEvery([fetchOlder], watchFetchOlder),
        takeEvery([fetchNewer], watchFetchNewer),
        takeEvery([checkNewer], watchCheckNewer),
    ])
}

export function* watchFetch() {
    const {panelId} = yield select((state) => state.panels.events)
    try {
        const {rows, count} = yield call(
            retrieve,
            0,
            limit,
            {panelId},
            sortField,
            sortDesc
        )

        yield put(update(rows))
        yield put(receive(toIds(rows)))
        yield put(setHasOlder(count > rows.length))
    } catch (error) {
        yield put(receive(error))
    }
}

export function* watchFetchOlder() {
    const {panelId, rows} = yield select((state) => state.panels.events)
    const filters = {
        panelId,
        id: `{<${rows.slice(-1).pop()}}`,
    }

    try {
        const {rows, count} = yield call(retrieve, 0, limit, filters, sortField, sortDesc)

        yield put(update(rows))
        yield put(receive(toIds(rows)))
        yield put(setHasOlder(count > rows.length))
    } catch (error) {
        yield put(receive(error))
    }
}

export function* watchFetchNewer() {
    const {panelId, rows, newerCount} = yield select((state) => state.panels.events)
    const filters = {
        panelId,
        id: `{>${getMaxEventId(rows)}}`,
    }

    try {
        const {rows} = yield call(retrieve, 0, newerCount, filters, sortField, sortDesc)

        yield put(update(rows))
        yield put(receive(toIds(rows)))
        yield put(setNewerCount(0))
    } catch (error) {
        yield put(receive(error))
    }
}

export function* watchCheckNewer() {
    const {panelId, rows} = yield select((state) => state.panels.events)
    const filters = {
        panelId,
        id: `{>${getMaxEventId(rows)}}`,
    }

    try {
        const {rows, count} = yield call(retrieve, 0, 1, filters, sortField, sortDesc)

        yield put(update(rows))
        yield put(setNewerCount(count))
    } catch (error) {
        yield put(receive(error))
    }
}

function getMaxEventId(rows) {
    return rows.length > 0 ? rows[0] : 0
}
