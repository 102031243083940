import {compose} from 'redux'

import {markAsViewed} from 'permissions/remoteInspections/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withRemoteInspections from 'containers/withRemoteInspections'

import ViewedRRI from 'components/Cell/RemoteInspections/Actions/ViewedRRI'

export default compose(
    withPermission({isVisible: markAsViewed}),
    withVisibility(),
    withRemoteInspections()
)(ViewedRRI)
