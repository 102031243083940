import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {pushBasicConfiguration} from 'modules/panels/store/actions'

import {withSelectionHandler} from 'containers/withSelection'
import withProps from 'containers/withProps'
import withConfirmation from 'containers/withConfirmation'

import {ReactComponent as IconUpload} from 'ipmp-react-ui/icons/upload.svg'
import Button from 'ipmp-react-ui/Button'
import __ from 'utils/i18n'

export default compose(
    connect(null, (dispatch, {basicConfigId}) =>
        bindActionCreators(
            {
                onClick: (...args) => pushBasicConfiguration(basicConfigId, ...args),
            },
            dispatch
        )
    ),
    withProps(() => ({
        message: __(
            'Do you really want to push basic configurations for selected panels?'
        ),
        title: __('Push basic configurations'),
        children: __('Push Basic Configuration'),
        Icon: IconUpload,
    })),
    withSelectionHandler(),
    withConfirmation()
)(Button)
