import toIds from 'utils/toIds'
import {MAX_SELECTABLE_ROWS} from 'constants/selection'

export default class IdSet {
    set = new Set()

    constructor(set = new Set()) {
        this.set = set
    }

    add(obj) {
        const newSet = new Set(this.set)
        toIds(obj).forEach((id) => {
            return !(newSet.size === MAX_SELECTABLE_ROWS) && newSet.add(id)
        })
        return new IdSet(newSet)
    }

    remove(obj) {
        const newSet = new Set(this.set)
        toIds(obj).forEach((id) => newSet.delete(id))
        return new IdSet(newSet)
    }

    has(obj) {
        return toIds(obj).every((id) => this.set.has(id))
    }

    hasAny(obj) {
        return toIds(obj).some((id) => this.set.has(id))
    }

    some(iteratee) {
        for (let item of this.set) {
            if (iteratee(item)) {
                return true
            }
        }

        return false
    }

    isEmpty() {
        return this.count() === 0
    }

    isFull() {
        return this.count() === MAX_SELECTABLE_ROWS
    }

    count() {
        return this.set.size
    }

    toJS() {
        return Array.from(this.set)
    }

    toString() {
        return this.set.toString()
    }

    clear() {
        return new IdSet()
    }

    get first() {
        if (this.isEmpty()) {
            return undefined
        }

        const iterator = this.set.values()

        return iterator.next().value
    }

    get last() {
        if (this.isEmpty()) {
            return undefined
        }

        let value
        /* eslint-disable curly */
        for (value of this.set);
        /* eslint-enable curly */
        return value
    }
}

export class IdSetSubstractive extends IdSet {
    constructor(total, set) {
        super(set)
        this.total = total
    }

    count() {
        return this.total
    }

    add(obj) {
        const ids = toIds(obj)
        const newSet = new Set(this.set)

        ids.forEach((id) => newSet.delete(id))

        return new IdSetSubstractive(this.total + ids.length, newSet)
    }

    remove(obj) {
        const ids = toIds(obj).filter((id) => !this.set.has(id))
        const newSet = new Set(this.set)

        ids.forEach((id) => !this.isFull() && newSet.add(id))

        return new IdSetSubstractive(this.total - ids.length, newSet)
    }

    has(obj) {
        return toIds(obj).every((id) => !this.set.has(id))
    }

    hasAny(obj) {
        return toIds(obj).some((id) => !this.set.has(id))
    }

    some(iteratee) {
        for (let item of this.set) {
            if (!iteratee(item)) {
                return true
            }
        }

        return false
    }

    isEmpty() {
        return this.count() == 0
    }

    isFull() {
        return this.set.size === MAX_SELECTABLE_ROWS
    }

    clear() {
        return new IdSetSubstractive(null, this.count())
    }
}
