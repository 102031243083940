import {snackShow} from 'modules/snacks'
import {takeEvery, all, put, call, select} from 'redux-saga/effects'

import {getLastKnownLanguage, setLastKnownLanguage} from 'storage/lastKnownLanguage'

import * as api from 'api/locale'
import {__} from 'utils/i18n'
import * as timezones from 'utils/timezones'
import * as actions from './actions'
import * as i18n from 'utils/i18n'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetchLocalesList),
        takeEvery(actions.changeLocale, watchChangeLocalesList),
    ])
}

export function* restoreLanguage() {
    yield loadLanguage(getLastKnownLanguage())
}

function* watchChangeLocalesList({payload}) {
    const {locale} = yield select((state) => state.locales)

    if (locale !== payload) {
        yield setLanguage(payload)
    }
}

export function* setLanguage(language) {
    setLastKnownLanguage(language)
    yield loadLanguage(language)
}

function* loadLanguage(language) {
    if (i18n.isLoaded(language)) {
        i18n.setLocale(language)
        yield put(actions.setLocale(language))
        return
    }

    try {
        const {locale, timezone, ...translations} = yield call(api.fetch, language)
        i18n.addLocale(locale, translations)
        i18n.setLocale(locale)
        timezones.init(timezone)
        yield put(actions.setLocale(locale))
    } catch (error) {
        yield put(snackShow(__("Can't get language translations")))
        i18n.setLocale('en')
    }
}

function* watchFetchLocalesList() {
    try {
        const locales = yield call(api.fetchList)
        yield put(actions.receive(locales))
    } catch (error) {
        yield put(actions.receive(error))
    }
}
