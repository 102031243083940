import createListActions from 'modules/higherOrder/createListActions'

export const {
    init,
    receive,
    reset,
    setStart,
    setQuery,
    fetch,
    purgeList,
} = createListActions('INSTALLER_PANELS/ADD_PANEL_LIST')
