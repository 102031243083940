import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {setStart} from 'modules/firmware/list/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withUpgradablePanels from 'containers/withUpgradablePanels'

import Pager from 'ipmp-react-ui/Pager'
import list from 'permissions/firmware/list'
import {withRouter} from 'react-router'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withRouter,
    withUpgradablePanels(),
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                setStart,
                onPageChange: setStart,
            },
            dispatch
        )
    )
)(Pager)
