import withForm from 'containers/withForm'
import NotificationTypeForm from './NotificationTypeForm'
import {validation} from './NotificationTypeFieldset'
import {addNotificationType} from 'modules/forms/handlers'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {__} from 'utils/i18n'

export default compose(
    connect(() => ({
        header: __('Add Notification Type'),
        rules: validation,
    })),
    withForm(addNotificationType)
)(NotificationTypeForm)
