import {isNil} from 'lodash-es'
import {all, takeEvery, select, put} from 'redux-saga/effects'

import * as api from 'api/panels'
import * as actions from './actions'

import {update, purge} from 'modules/panels/store/actions'
import {selectPerPage} from 'modules/settings/selectors'
import {fetch} from 'modules/installerPanels/list/actions'

import listSaga from 'modules/higherOrder/createListSaga'
import {assignInstallerToPanel} from 'modules/forms/handlers'

const selector = (state) => ({
    ...state.installerPanels.addPanelList,
    perPage: selectPerPage(state, 'installerAddPanel'),
    listRoute: 'installers',
})

export default function* () {
    yield all([
        listSaga(api, actions, update, selector, purge),
        takeEvery(assignInstallerToPanel.SUCCESS, watchAssignInstallerToPanel),
    ])
}

function* watchAssignInstallerToPanel() {
    const installerId = yield select((state) => state.installerPanels.list.installerId)

    // On system/installers page
    if (isNil(installerId)) {
        return
    }

    yield put(fetch(installerId))
}
