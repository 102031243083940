import SearchBar from 'components/Search/SearchBar'
import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import FilterDefinition from 'components/Search/Filters/Filter'
import SearchBoard from 'components/Search/SearchBoard'
import SearchAutoComplete from 'components/Search/SearchAutoComplete'

import SlideDown from 'ipmp-react-ui/SlideDown'
import Button from 'ipmp-react-ui/Button'
import OuterClick from 'ipmp-react-ui/OuterClick'

import {ReactComponent as IconUp} from 'ipmp-react-ui/icons/arrow-up.svg'
import {ReactComponent as IconDown} from 'ipmp-react-ui/icons/arrow-down.svg'

export default class Search extends Component {
    static propTypes = {
        filters: PropTypes.arrayOf(PropTypes.instanceOf(FilterDefinition)),
        selected: PropTypes.array.isRequired,
        suggests: PropTypes.object,
        onSuggest: PropTypes.func,
        apply: PropTypes.func,
        onSelect: PropTypes.func,
        onDeselect: PropTypes.func,
        onClear: PropTypes.func,
        onQuery: PropTypes.func,
        onSearch: PropTypes.func,
        onSaveSearch: PropTypes.func,
        hideAutoCompleteAfterQuery: PropTypes.bool,
    }

    static defaultProps = {
        hideAutoCompleteAfterQuery: false,
    }

    state = {
        expanded: false,
        applyAfterCollapse: false,
        currentSearch: '',
    }

    handleToggleClick = (e) => {
        e.stopPropagation()
        e.preventDefault()
        this.setState(({expanded, applyAfterCollapse}) => {
            if (applyAfterCollapse && expanded) {
                this.props.apply && this.props.apply()
            }

            return {expanded: !expanded, applyAfterCollapse: false}
        })
    }

    hide = () =>
        this.setState(({applyAfterCollapse}) => {
            if (applyAfterCollapse) {
                this.props.apply && this.props.apply()
            }

            return {expanded: false, applyAfterCollapse: false}
        })

    handleOuterClick = () => {
        // prevent close while modal is open
        if (document.querySelector('.modal')) {
            return false
        }

        this.hide()
    }

    onSelect = (item) => {
        delete item.label
        this.props.onSelect && this.props.onSelect(item)
        this.apply()
    }

    onDeselect = (item) => {
        this.props.onDeselect && this.props.onDeselect(item)
        this.apply()
    }

    onClear = () => {
        this.props.onClear && this.props.onClear()
        this.apply()
    }

    onQuery = (query) => {
        this.props.onQuery && this.props.onQuery(query)
        this.apply()
    }

    handleSaveSearch = () => {
        this.props.onSaveSearch(this.props.selected)
    }

    apply() {
        if (this.state.expanded) {
            this.setState({applyAfterCollapse: true})
        } else {
            this.props.apply && this.props.apply()
        }
    }

    handleSearchButton = () => {
        const {currentSearch} = this.state
        this.onQuery(currentSearch)
        this.handleCurrentSearch('')
        this.hide()
    }

    handleCurrentSearch = (value) => {
        this.setState({currentSearch: value})
    }

    render() {
        const {expanded, currentSearch} = this.state
        const {
            filters,
            selected,
            onQuery,
            onSaveSearch,
            query,
            suggests,
            isLoading,
            onSearch,
            className,
            onSuggest,
            hideAutoCompleteAfterQuery,
        } = this.props

        const props = {
            onSuggest,
            filters,
            selected,
            query,
            isLoading,
            suggests,
            onSelect: this.onSelect,
            onDeselect: this.onDeselect,
            onClear: this.onClear,
            onQuery: onQuery ? this.onQuery : null,
            onSearch,
            hideAutoCompleteAfterQuery,
        }

        const input = (
            <Fragment>
                <SearchAutoComplete
                    disabled={expanded}
                    {...props}
                    value={currentSearch}
                    handleCurrentSearch={this.handleCurrentSearch}
                />

                {filters && filters.length > 0 && (
                    <Button primary flat onClick={this.handleToggleClick}>
                        {expanded ? <IconUp /> : <IconDown />}
                    </Button>
                )}
            </Fragment>
        )

        return (
            <OuterClick
                className={classes('search', {'search--expanded': expanded}, className)}
                onOuterClick={expanded ? this.handleOuterClick : null}
            >
                <SearchBar input={input} className="search-box--withIcon">
                    <SlideDown>
                        {expanded && (
                            <SearchBoard
                                onSubmit={this.handleSearchButton}
                                onSaveSearch={onSaveSearch && this.handleSaveSearch}
                                {...props}
                            />
                        )}
                    </SlideDown>
                </SearchBar>
            </OuterClick>
        )
    }
}
