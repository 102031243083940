import React, {Component, createContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import flatten from 'lodash-es/flatten'

import Link from 'components/CrossServerSearch/Link'

import LoginForm from 'pages/Panels/CrossServerSearch/LoginForm'
import CrossHttp from 'pages/Panels/CrossServerSearch/CrossHttp'

export const CrossServerSearchContext = createContext()

const domainOf = (url) => url.replace(/^\w+:\/\/|\/.*$/g, '')

export const SERVER_STATUS_READY = 'READY'
export const SERVER_STATUS_CONNECTING = 'CONNECTING'
export const SERVER_STATUS_UNAUTHORIZED = 'UNAUTHORIZED'
export const SERVER_STATUS_ERROR = 'FAIL'

export function CrossServerLink({server}) {
    return (
        <CrossServerSearchContext.Consumer>
            {({connections}) => <Link {...connections} server={server} />}
        </CrossServerSearchContext.Consumer>
    )
}

export class CrossServerSearch extends Component {
    static propTypes = {
        throttle: PropTypes.number,
    }

    static defaultProps = {
        throttle: 200,
    }

    servers = {}

    tId = null

    handleSearch = (query) => {
        clearTimeout(this.tId)

        if (query.trim().length === 0) {
            this.setState({
                results: null,
                isLoading: false,
                query: null,
            })
            return
        }

        this.setState({
            query,
            isLoading: true,
        })

        this.tId = setTimeout(() => this.performSearch(query), this.props.throttle)
    }

    performSearch = (query) => {
        const readyToSearch = this.state.servers.filter(
            (server) => server.status === SERVER_STATUS_READY
        )

        const promises = readyToSearch.map(({url, domain}) =>
            this.servers[url]
                .getPanels({
                    account: '{:' + query + '}',
                })
                .then(
                    (results) =>
                        results.rows.map((item) => ({
                            url,
                            domain,
                            ...item,
                        })),
                    (error) => {
                        this.handleResponseError(url, error)
                        console.error(error)
                        return []
                    }
                )
        )

        Promise.all(promises)
            .then(flatten)
            .then((results) =>
                this.setState({
                    isLoading: false,
                    results,
                })
            )
    }

    handleLinkServer = ({url, name}) => {
        this.servers[url] = new CrossHttp(url)

        this.setState(({servers}) => {
            servers = [
                ...servers,
                {
                    url,
                    name,
                    domain: domainOf(url),
                    status: SERVER_STATUS_CONNECTING,
                    retry: () => this.handleRetry(url),
                    login: () => this.handleLogin(url),
                },
            ]

            return {servers}
        })

        this.handleRetry(url)
    }

    handleCloseLogin = () => {
        this.setState({
            login: null,
        })
    }

    handleLogin = (url) => {
        this.setState({
            login: {
                http: this.servers[url],
                domain: domainOf(url),
                onClose: this.handleCloseLogin,
                onSuccess: () => this.handleRetry(url),
            },
        })
    }

    handleRetry = (url) => {
        if (!this.servers[url]) {
            return
        }

        this.servers[url].whoami().then(
            ({user}) => {
                this.setServerState(url, {
                    user,
                    status: SERVER_STATUS_READY,
                    errorText: null,
                })

                if (this.state.query) {
                    this.handleSearch(this.state.query)
                }
            },
            (error) => this.handleResponseError(url, error)
        )
    }

    handleResponseError = (url, error) => {
        if (error && error.status === 401) {
            this.setServerState(url, {
                status: SERVER_STATUS_UNAUTHORIZED,
                errorText: null,
            })
        } else {
            this.setServerState(url, {
                status: SERVER_STATUS_ERROR,
                errorText: error.message ? error.message : error,
            })
        }
    }

    handleUnlinkServer = ({url}) => {
        delete this.servers[url]

        this.setState(({servers}) => {
            return {
                servers: servers.filter((server) => server.url !== url),
            }
        })
    }

    state = {
        servers: [],
        search: this.handleSearch,
        results: null,
        isLoading: false,
        connections: {
            link: this.handleLinkServer,
            unlink: this.handleUnlinkServer,
        },
    }

    setServerState(url, state) {
        this.setState(({servers}) => {
            return {
                servers: servers.map((server) => {
                    if (server.url === url) {
                        return {
                            ...server,
                            ...state,
                        }
                    }

                    return server
                }),
            }
        })
    }

    componentWillUnmount() {
        clearTimeout(this.tId)
    }

    render() {
        const {children} = this.props
        const {login} = this.state

        return (
            <Fragment>
                <CrossServerSearchContext.Provider
                    value={this.state}
                    children={children}
                />

                {login && <LoginForm {...login} />}
            </Fragment>
        )
    }
}
