import {compose} from 'redux'
import {connect} from 'react-redux'

import withForm from 'containers/withForm'
import {editNotificationType} from 'modules/forms/handlers'
import NotificationTypeForm from './NotificationTypeForm'
import {validation} from './NotificationTypeFieldset'
import {__} from 'utils/i18n'

export default compose(
    connect(({notificationTypes}, {notificationTypeId}) => ({
        data: notificationTypes.store.byIds[notificationTypeId],
        header: __('Edit Notification Type'),
        rules: validation,
    })),
    withForm(editNotificationType),
    connect(null, (dispatcher, {notificationTypeId, handle}) => ({
        handle: (data) => handle({id: notificationTypeId, ...data}),
    }))
)(NotificationTypeForm)
