import {combineReducers} from 'redux'

import list from './list/reducer'
import store from './store/reducer'
import upgradeStatusRunnersList from './upgradeStatusRunnersList/reducer'

export default combineReducers({
    list,
    store,
    upgradeStatusRunnersList,
})
