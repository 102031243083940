import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as Icon} from 'ipmp-react-ui/icons/drop-down.svg'
import classes from 'classnames'
import Button from 'ipmp-react-ui/Button'

const isActive = (key, highlightKey) =>
    highlightKey && highlightKey.substr(0, key.length) === key

class ConfigurationLink extends Component {
    static propTypes = {
        node: PropTypes.shape({
            key: PropTypes.string.isRequired,
        }).isRequired,
        onSelect: PropTypes.func.isRequired,
        highlightKey: PropTypes.string,
    }

    toggle = (e) => {
        e.stopPropagation()
        this.setState(({active}) => ({active: !active}))
    }

    constructor(props, context) {
        super(props, context)
        const {highlightKey, node} = props
        const active = isActive(node.key, highlightKey)

        this.state = {
            active,
            autoOpen: active,
        }
        // this.autoOpen = active
    }

    // isActive(key, highlightKey) {
    //     return highlightKey && highlightKey.substr(0, key.length) === key
    // }

    static getDerivedStateFromProps({highlightKey, node}, state) {
        const active = isActive(node.key, highlightKey)

        // prevent collapse if manually opened node
        if (active || state.autoOpen) {
            return {
                active,
                autoOpen: active,
            }
        }

        return {
            autoOpen: active,
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {highlightKey, node} = this.props

        if (this.state !== nextState) {
            return true
        }

        if (nextProps.node !== node) {
            return true
        }

        if (nextProps.highlightKey !== highlightKey) {
            const shouldBeActive = isActive(nextProps.node.key, nextProps.highlightKey)

            if (this.state.active !== shouldBeActive) {
                return true
            }

            if (
                shouldBeActive &&
                highlightKey !== nextProps.highlightKey &&
                node.type === 'menu'
            ) {
                // some changes in deeper elements
                return true
            }
        }

        return false
    }

    handleClick = () => {
        const {onSelect, node} = this.props
        onSelect(node.key)
    }

    handleSelect = (key) => {
        const {onSelect, node} = this.props
        onSelect(node.key + '$$' + key)
    }

    render() {
        const {node, highlightKey} = this.props
        const nested = node.val.filter(({type}) => type === 'menu')
        const {active} = this.state

        const nestedHighlightKey =
            highlightKey && highlightKey.substr(0, node.key.length) === node.key
                ? highlightKey.substr(node.key.length + 2)
                : null

        return (
            <div className="configuration-link">
                <div
                    onClick={this.handleClick}
                    className={classes('configuration-link-row', {
                        'configuration-link-row--highlight': highlightKey === node.key,
                    })}
                >
                    {nested.length > 0 && (
                        <Button
                            borderless
                            onClick={this.toggle}
                            className={classes('configuration-link-button', {
                                'configuration-link-button--active': active,
                            })}
                        >
                            <Icon />
                        </Button>
                    )}

                    {node.name}
                </div>

                {nested.length > 0 && active && (
                    <ConfigurationTableOfContents
                        nodes={nested}
                        onSelect={this.handleSelect}
                        highlightKey={nestedHighlightKey}
                    />
                )}
            </div>
        )
    }
}

export default class ConfigurationTableOfContents extends Component {
    static propTypes = {
        className: PropTypes.any,
        onSelect: PropTypes.func.isRequired,
        nodes: PropTypes.array,
        highlightKey: PropTypes.string,
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {highlightKey, nodes} = this.props

        return nextProps.nodes !== nodes || nextProps.highlightKey !== highlightKey
    }

    render() {
        const {onSelect, className, highlightKey, nodes} = this.props

        return (
            <div className={classes('configuration-links', className)}>
                {nodes
                    .filter((node) => node.name)
                    .map((node) => (
                        <ConfigurationLink
                            key={node.key}
                            node={node}
                            highlightKey={highlightKey}
                            onSelect={onSelect}
                        />
                    ))}
            </div>
        )
    }
}
