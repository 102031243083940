import {combineReducers, compose} from 'redux'
import {connectRouter} from 'connected-react-router'

import createLogoutReducer from 'modules/auth/createLogoutReducer'

import locales from './locales/reducer'
import auth from './auth/reducer'
import modals from './modals/reducer'
import events from './events/reducer'
import forms from './forms/reducer'
import devices from './devices/reducer'
import pgm from './pgm/reducer'
import settings from './settings/reducer'
import selection from './selection/reducer'
import snacks from './snacks'
import panels from './panels/reducer'
import groups from './groups/reducer'
import reports from './reports/reducer'
import remoteInspections from './remoteInspections/reducer'
import centralStations from './centralStations/reducer'
import countries from './countries/reducer'
import users from './users/reducer'
import processes from './processes/reducer'
import roles from './roles/reducer'
import languages from './languages/reducer'
import actionLog from './actionLog/reducer'
import firmware from './firmware/reducer'
import basicConfiguration from './basicConfiguration/reducer'
import installers from './installers/reducer'
import interactiveUsers from './interactiveUsers/reducer'
import upgradeStatus from './upgradeStatus/reducer'
import dashboard from './dashboard/reducer'
import batches from './batches/reducer'
import runners from './runners/reducer'
import features from './features/reducer'
import system from './system/reducer'
import servers from './servers/reducer'
import interactiveUserPanels from './interactiveUserPanels/reducer'
import panelInteractiveUsers from './panelInteractiveUsers/reducer'
import eventRules from './eventRules/reducer'
import globalListReducer from './higherOrder/globalListReducer'
import navBlind from './navBlind/reducer'
import installerPanels from './installerPanels/reducer'
import panelInstallers from './panelInstallers/reducer'
import zoneTypes from './zoneTypes/reducer'
import notificationTypes from './notificationTypes/reducer'
import stateTypes from './stateTypes/reducer'
import eventTypes from './eventTypes/reducer'
import {IS_FIRST_PAGE_STREAMABLE_NAME} from 'modules/events/store/reducer'

const reducers = {
    locales,
    forms,
    auth,
    modals,
    processes,
    events,
    devices,
    pgm,
    selection,
    settings,
    snacks,
    panels,
    groups,
    reports,
    remoteInspections,
    centralStations,
    users,
    countries,
    languages,
    firmware,
    roles,
    actionLog,
    basicConfiguration,
    eventRules,
    installers,
    interactiveUsers,
    upgradeStatus,
    dashboard,
    batches,
    runners,
    features,
    system,
    servers,
    interactiveUserPanels,
    panelInteractiveUsers,
    globalListReducer,
    navBlind,
    installerPanels,
    panelInstallers,
    zoneTypes,
    notificationTypes,
    stateTypes,
    eventTypes,
}

export const persistReducers = [
    'panels.recent',
    `events.store.${IS_FIRST_PAGE_STREAMABLE_NAME}`,
]

export default function createRootReducer(history) {
    const reducer = combineReducers({
        router: connectRouter(history),
        ...reducers,
    })

    return compose(createLogoutReducer)(reducer)
}
