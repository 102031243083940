import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {showResumeFaultsModal} from 'modules/modals/actions'
import {withRouterPanelId} from 'containers/withPanel'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'

import {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'
import {resumeFaults} from 'permissions/panels/selection'

export default compose(
    withProps(() => ({
        children: __('Resume Faults'),
    })),
    withPermission({isVisible: resumeFaults}),
    withVisibility(),
    withRouterPanelId(),
    connect(null, (dispatch, {panelId}) =>
        bindActionCreators(
            {
                onClick: () => showResumeFaultsModal([panelId]),
            },
            dispatch
        )
    )
)(MenuItem)
