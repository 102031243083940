import React, {Component} from 'react'
import classnames from 'classnames'

import {ReactComponent as IconInfo} from 'ipmp-react-ui/icons/info-inversed.svg'

export default class Disclaimer extends Component {
    get className() {
        return classnames('disclaimer', this.props.className)
    }

    render() {
        const {children} = this.props
        return (
            <div className={this.className}>
                <IconInfo />
                {children}
            </div>
        )
    }
}
