import {handleActions} from 'redux-actions'

import * as actions from './actions'

export default handleActions(
    {
        [actions.setLoading](state, {payload: {isLoading}}) {
            return {
                ...state,
                isLoading,
            }
        },

        [actions.receiveCanAdd](state, {payload}) {
            return {
                ...state,
                ...payload,
                isLoading: false,
            }
        },
    },
    {
        isLoading: false,
        add: false,
        max: 0,
    }
)
