import {createSelector} from 'reselect'
import get from 'lodash-es/get'

export const selectDeviceVOD = createSelector(
    (state, {panelId, device: {zone: zoneId}}) =>
        get(state.devices.vod, `${panelId}.${zoneId}`, {
            isLoading: true,
        }),
    (vod) => vod
)
