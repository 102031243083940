import get from 'lodash-es/get'
import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'
import has from 'lodash-es/has'

import {addDevice} from 'modules/forms/handlers'

import ModalCardForm from 'ui/ModalCardForm'
import Input from 'ipmp-react-ui/Input'

import {__, __n} from 'utils/i18n'
import withForm from 'containers/withForm'
import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchPartitions} from 'modules/panels/state/actions'
import {withDeviceReference} from 'containers/withDevices'
import withLifeCycle from 'containers/withLifeCycle'

import {VENDOR_NEO, VENDOR_POWER_MASTER} from 'constants/panelVendorType'
import {
    deviceSubtype as deviceSubtypeName,
    COMMON_KEYFOB_DEVICE_SUBTYPE,
} from 'constants/deviceSubtype'

import {fetchUsers} from 'modules/panels/one/actions'
import MultiSelect from 'ipmp-react-ui/MultiSelect'
import Select from 'ipmp-react-ui/Select'
import Checkbox from 'ipmp-react-ui/Checkbox'

const PartitionSelect = compose(
    withSelectLoader(
        (prefix, maxOptionsToShow, {panelId}) => fetchPartitions(panelId),
        ({panels: {state}}, {panelId}) =>
            !get(state, ['byIds', panelId, 'partitions'], true),
        ({panels: {state}}, {isLoading, panelId}) =>
            !isLoading
                ? Object.values(state.byIds[panelId].partitions)
                      .filter(({id}) => id > 0)
                      .map(({id, name}) => ({value: id, label: name}))
                : []
    )
)(MultiSelect)

const UserSelect = compose(
    withSelectLoader(
        (prefix, maxOptionsToShow, {panelId}) => fetchUsers(panelId),
        ({panels: {store}}, {panelId}) =>
            !(
                store &&
                store.byIds &&
                store.byIds[panelId] &&
                store.byIds[panelId].userNames
            ),
        (
            {panels: {store}, devices: {list: panels}},
            {isLoading, panelId, deviceSubtype}
        ) => {
            if (isLoading) {
                return []
            }

            const usersWithKeyfobs = Object.values(panels[panelId].byIds).reduce(
                (acc, device) => {
                    if (
                        device.subtype === deviceSubtype &&
                        device.traits &&
                        device.traits.owner
                    ) {
                        acc.push(device.traits.owner.id)
                    }

                    return acc
                },
                []
            )

            const options = Object.entries(store.byIds[panelId].userNames)
                .filter(([id]) => !usersWithKeyfobs.includes(parseInt(id)))
                .map(([id, name]) => ({value: id, label: name || __('User %s', id)}))

            return options.length
                ? options
                : [
                      {
                          label: __(
                              'All users already have %s',
                              deviceSubtypeName(deviceSubtype)
                          ),
                          disabled: true,
                      },
                  ]
        }
    )
)(Select)

class AddDevice extends Component {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        hide: PropTypes.func.isRequired,
        panelId: PropTypes.number.isRequired,
        isNeo: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool, // populated from withForm HOC
        reference: PropTypes.objectOf(
            PropTypes.shape({
                code: PropTypes.string,
                users: PropTypes.number,
                partitions: PropTypes.number,
            })
        ),
    }

    static defaultProps = {
        isLoading: false,
    }

    state = {
        deviceId: '',
        showUser: false,
        showPartitions: false,
        isUserNumber: false,
    }

    delim = ' — '

    onClose = () => {
        const {hide} = this.props

        return hide()
    }

    onSubmit = ({zoneId, deviceId, partitions, userId, userNumber}) => {
        const {handle, panelId} = this.props
        return handle(
            panelId,
            zoneId,
            deviceId.replace(/\D/g, ''),
            partitions,
            userId || userNumber
        )
    }

    getRules = () => {
        const {showUser, showPartitions, isUserNumber} = this.state

        const rules = {
            deviceId: {
                presence: {
                    message: __('You should define enroll code of device'),
                },

                deviceId: {
                    invalid: __('This is not valid enrollment ID'),
                    notExists: __('This enrollment ID is not exists'),
                    existence: (code) =>
                        code && this.deviceSubtypeName(code.substr(0, 3)) !== false,
                },
            },

            zoneId: {
                presence: {
                    message: __('You should define zone to enroll device'),
                },
                numericality: {
                    strict: true,
                    greaterThan: 0,
                    message: __('Zone number must be a integer.'),
                },
            },
        }

        const neoRules = !showPartitions
            ? {}
            : {
                  partitions: {
                      presence: {
                          message: __('You should define partitions to enroll device'),
                      },
                  },
              }

        const user = !isUserNumber
            ? {
                  userId: {
                      presence: {
                          message: __('You should define user to enroll device'),
                      },
                  },
              }
            : {
                  userNumber: {
                      presence: {
                          message: __('You should define user number to enroll device'),
                      },
                      numericality: {
                          strict: true,
                          greaterThan: 0,
                          message: __('User number must be a integer.'),
                      },
                  },
              }
        const userRules = !showUser ? {} : user

        return {
            ...rules,
            ...neoRules,
            ...userRules,
        }
    }

    handleDeviceIdChange = (e) => {
        const digits = e.target.value.replace(/\D/g, '')
        const hasDelim = e.target.value.indexOf(this.delim) === 3

        let deviceId = digits.substr(0, 3)

        if (deviceId.length === 3) {
            this.setState({
                showUser: this.deviceSubtypeHasUser(deviceId),
                deviceSubtype: this.deviceSubtypeName(deviceId),
                showPartitions: this.deviceSubtypeHasPartitions(deviceId),
            })
        } else {
            this.setState({showUser: false, showPartitions: false, deviceSubtype: ''})
        }

        if (hasDelim || (digits.length === 3 && this.digitAdded) || digits.length > 3) {
            deviceId += this.delim
        }

        deviceId += digits.substr(3)

        this.setState({deviceId})
    }

    deviceSubtypeHasUser(deviceId) {
        const {reference} = this.props

        if (has(reference, deviceId)) {
            return get(reference, [deviceId, 'users'], false)
        }

        return false
    }

    deviceSubtypeHasPartitions(deviceId) {
        const {reference, hasPartitions} = this.props

        if (!hasPartitions) {
            return false
        }

        if (has(reference, deviceId)) {
            return get(reference, [deviceId, 'partitions'], false)
        }

        return false
    }

    deviceSubtypeName(deviceId) {
        const {reference} = this.props

        if (has(reference, deviceId)) {
            return get(reference, [deviceId, 'subtype'], false)
        }

        return false
    }

    handleDeviceIdKeyDown = (e) => {
        this.digitAdded = e.keyCode >= 48 && e.keyCode <= 57
    }

    isKeyfob = (deviceSubtype) => {
        return deviceSubtype.indexOf(COMMON_KEYFOB_DEVICE_SUBTYPE) > 0
    }

    setUserWithoutPin = (e) => {
        this.setState({isUserNumber: e.target.checked})
    }

    render() {
        const {isLoading, errors, error, panelId} = this.props
        const {showUser, deviceSubtype, showPartitions, isUserNumber} = this.state
        return (
            <ModalCardForm
                isLoading={isLoading}
                header={__('Enroll device')}
                submitLabel={__('Add')}
                errors={errors}
                error={error}
                hide={this.onClose}
                onSubmit={this.onSubmit}
                rules={this.getRules()}
            >
                <Input
                    label={__('Enrollment ID')}
                    name="deviceId"
                    type="text"
                    maxLength="10"
                    value={this.state.deviceId}
                    onChange={this.handleDeviceIdChange}
                    onKeyDown={this.handleDeviceIdKeyDown}
                />

                <Input label={__('Zone Number')} name="zoneId" type="number" min="1" />

                {!!showPartitions && (
                    <PartitionSelect
                        label={__('Partitions')}
                        name="partitions"
                        panelId={panelId}
                        hasSelectAll
                        maxSelectOptions={showPartitions}
                        maxSelectOptionsLabel={__n(
                            'Only %d partition allowed to select for this device',
                            'Only %d partitions allowed for this device',
                            showPartitions
                        )}
                    />
                )}

                {!!showUser && (
                    <Fragment>
                        {isUserNumber ? (
                            <Input label={__('User Number')} name="userNumber" />
                        ) : (
                            <UserSelect
                                label={__('User')}
                                name="userId"
                                deviceSubtype={deviceSubtype}
                                panelId={panelId}
                            />
                        )}
                        {this.isKeyfob(deviceSubtype) && (
                            <Checkbox
                                onChange={(e) => {
                                    this.setUserWithoutPin(e)
                                }}
                                label={__('User without pin-code')}
                            />
                        )}
                    </Fragment>
                )}
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(({panels}, {panelId}) => {
        const {isNeo} = panels.store.byIds[panelId] || {}
        const {hasPartitions} = panels.state.byIds[panelId] || {}

        return {
            hasPartitions,
            isNeo,
            vendor: isNeo ? VENDOR_NEO : VENDOR_POWER_MASTER,
        }
    }),
    withDeviceReference(),
    withLifeCycle({
        onMount({fetchReference}) {
            fetchReference()
        },
    }),
    withForm(addDevice)
)(AddDevice)
