import React, {PureComponent} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import withForm from 'containers/withForm'
import {setSystemSettings} from 'modules/forms/handlers'

import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'

import Input from 'ipmp-react-ui/Input'
import Checkbox from 'ipmp-react-ui/Checkbox'

class CommonConnectedSettings extends PureComponent {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        data: PropTypes.shape({
            systemIdRegex: PropTypes.string,
            isPanelRemoveForever: PropTypes.bool,
        }),
    }

    handle = (data) => {
        const {handle} = this.props
        handle(data)
    }

    get rules() {
        return {
            systemIdRegex: {
                presence: {
                    message: __("You should define System ID's Mask"),
                },
            },
        }
    }

    render() {
        const {data, ...props} = this.props
        return (
            <ModalCardForm
                onSubmit={this.handle}
                rules={this.rules}
                header={__('Edit Common Connected Settings')}
                confirmOnDismiss
                {...props}
            >
                <Input
                    label={__("System ID's Mask")}
                    name="systemIdRegex"
                    defaultValue={data.systemIdRegex}
                />

                <Checkbox
                    label={__('Remove Panel by Resetting the Receiver IP')}
                    name="isPanelRemoveForever"
                    defaultChecked={data.isPanelRemoveForever}
                />
            </ModalCardForm>
        )
    }
}

export default compose(withForm(setSystemSettings.CommonConnected))(
    CommonConnectedSettings
)
