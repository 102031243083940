import {createAction} from 'redux-actions'
import toArray from 'utils/toArray'

export const update = createAction('EVENTS/STORE/UPDATE', toArray)
export const clear = createAction('EVENTS/STORE/CLEAR')
export const purge = createAction('EVENTS/STORE/PURGE')

export const setFirstPageStreamable = createAction(
    'EVENTS/STORE/SET_FIRST_PAGE_STREAMABLE',
    (isFirstPageStreamable) => ({isFirstPageStreamable})
)
