import createRowsResponseParser from 'api/base/createRowsResponseParser'
import {get, post} from 'api/http'
import has from 'lodash-es/has'

import generateSuggestString from 'api/base/generateSuggestString'
import generateFilterString from 'api/base/generateFilterString'

import {
    assignInstallerKeyMap,
    keysMap,
    mapInstaller,
    mapInstallerPanels,
} from 'api/installers.map'
import mapValidationErrors from 'api/base/mapValidationErrors'

export function fetch({start = 0, perPage: count = 10, filters, query: search}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/installer/list', {start, count, search, filter}).then(
        createRowsResponseParser(mapInstaller)
    )
}

export function accept(installerId) {
    return post('/installer/accept', {installer_id: installerId}).then(mapInstaller)
}

export function reject(installerId) {
    return post('/installer/reject', {installer_id: installerId}).then(mapInstaller)
}

// TODO: checkout usage this method
export function suggest(fields, prefix = '', start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('/installer/suggest', {suggest, start, count}).then((data) =>
        fields.reduce((acc, key) => {
            const responseKey = keysMap[key] || key

            if (has(data, responseKey)) {
                acc[key] = data[responseKey].rows.map(({suggest}) => suggest)
            } else {
                acc[key] = []
            }

            return acc
        }, {})
    )
}

export function fetchInstallerPanels({installerId}) {
    return get('/installer/panels', {installer_id: installerId}).then(
        createRowsResponseParser(mapInstallerPanels)
    )
}

export function assignInstallerToPanel({installerId, panelId}) {
    return post('/installer/assign', {
        installer_id: installerId,
        unt_id: panelId,
    }).catch((e) => mapValidationErrors(e, assignInstallerKeyMap))
}

export function unassignInstallerFromPanel({installerId, panelId}) {
    return post('/installer/unassign', {installer_id: installerId, unt_id: panelId})
}

export function registerInstaller({email, name, phone}) {
    return post('/installer/add', {email, name, telephone: phone}).catch((e) =>
        mapValidationErrors(e, assignInstallerKeyMap)
    )
}

export function panelInstallers({panelId}) {
    return get('/installer/panel', {unt_id: panelId}).then(
        createRowsResponseParser(mapInstaller)
    )
}

export function resetInstallerPassword({installerId}) {
    return post('/installer/reset', {installer_id: installerId})
}

export function fetchInstallerResetPassword({installerId}) {
    return post('/installer/reset', {installer_id: installerId}).then(mapInstaller)
}
