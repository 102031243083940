import React from 'react'
import {compose} from 'redux'

import Table from 'ipmp-react-ui/Table'

import PanelCell from 'components/TableCells/PanelCell'
import GroupCell from 'components/TableCells/GroupCell'

import __ from 'utils/i18n'
import withProps from 'containers/withProps'
import withLoader from 'containers/withLoader'
import withInstallerPanelsTable from 'containers/withInstallerPanelsTable'
import UnassignPanelCell from './TableCell/UnassignPanelCell'

const columns = [
    {
        name: () => __('Panel'),
        width: 224,
        render: ({id, serial, account}) => (
            <PanelCell to="installers" {...{id, serial, account}} />
        ),
    },
    {
        name: () => __('Group'),
        maxWidth: 132,
        render: ({groupId: id, group: name}) => {
            return !!id && !!name && <GroupCell {...{row: {id, name}}} />
        },
    },
    {
        tooltip: false,
        fixed: true,
        width: 56,
        component: UnassignPanelCell,
    },
]

export default compose(
    withInstallerPanelsTable(),
    withLoader(({fetch}) => fetch()),
    withProps(() => ({
        columns,
        className: 'installerBlind-table',
    }))
)(Table)
