import toArray from 'utils/toArray'
import {createAction} from 'redux-actions'

export const update = createAction('RUNNERS/STORE/UPDATE')
export const stop = createAction('RUNNERS/STORE/STOP', toArray)
export const resultStop = createAction('RUNNERS/STORE/STOP_RESULT', toArray)

export const refreshState = createAction('RUNNER/STORE/PROXY_REFRESH_STATE')
export const showTriggerDiscoveryModal = createAction(
    'RUNNER/STORE/PROXY_SHOW_TRIGGER_DISCOVERY_MODAL'
)
export const refreshConfiguration = createAction(
    'RUNNER/STORE/PROXY_CONFIGURATION_REFRESH'
)
export const showPushBasicConfigurationModal = createAction(
    'RUNNER/STORE/PROXY_SHOW_PUSH_BASIC_CONFIGURATION_MODAL'
)
export const showChangePanelGroupModal = createAction(
    'RUNNER/STORE/PROXY_SHOW_CHANGE_PANEL_GROUP_MODAL'
)
export const showMarkForServiceModal = createAction(
    'RUNNER/STORE/PROXY_SHOW_MARK_FOR_SERVICE_MODAL'
)
export const showCreateReportModal = createAction(
    'RUNNER/STORE/PROXY_SHOW_CREATE_REPORT_MODAL'
)
export const initiateNow = createAction(
    'RUNNER/STORE/PROXY_INITIAL_NOW_REMOTE_INSPECTION'
)
export const purge = createAction('RUNNERS/STORE/PURGE')
