import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import {createSelectorCreator, defaultMemoize} from 'reselect'
import isEqual from 'lodash-es/isEqual'
import has from 'lodash-es/has'

import {DEVICE_TYPE_PGM} from 'constants/deviceType'
import {selectDevicesByIds} from 'modules/devices/list/selectors'
import {selectPGMIsDisabling, selectPGMIsSaving} from 'modules/pgm/item/selectors'
import {selectActivePartitions} from 'modules/panels/state/selectors'
import {selectPGM} from 'modules/pgm/list/selectors'
import {
    selectPGMTypes,
    selectPGMOptions,
    selectPGMTypesIsLoading,
} from 'modules/pgm/types/selectors'

import {startSavePGM} from 'modules/pgm/item/actions'
import {fetch as fetchPGMList} from 'modules/pgm/list/actions'
import {fetch as fetchPGMTypes} from 'modules/pgm/types/actions'

export const createSelector = createSelectorCreator(defaultMemoize, isEqual)

const selectPGMParents = createSelector(
    (state, {panelId}) => selectDevicesByIds(state, panelId),
    (list) => {
        const devices = Object.values(list)

        return devices.reduce((acc, {traits: {parent}, deviceType}) => {
            if (
                deviceType === DEVICE_TYPE_PGM &&
                !acc.some(({deviceId}) => deviceId === parent.id)
            ) {
                const parentDevice = devices.find(({id}) => id === parent.id)

                if (parentDevice) {
                    acc.push({
                        deviceId: parentDevice.id,
                        deviceType: parentDevice.deviceType,
                    })
                }
            }
            return acc
        }, [])
    }
)

const selectPGMPortsByParents = createSelector(
    (state, {panelId}) => selectDevicesByIds(state, panelId),
    (list) => {
        const devices = Object.values(list)

        return devices
            ? devices.reduce((acc, {id, traits, deviceType}) => {
                  if (has(traits, 'parent')) {
                      const {port, id: deviceId} = traits.parent

                      if (deviceType === DEVICE_TYPE_PGM) {
                          if (!has(acc, deviceId)) {
                              acc[deviceId] = []
                          }
                          if (!acc[deviceId].includes(port)) {
                              acc[deviceId].push({id, port})
                          }
                      }
                  }

                  return acc
              }, {})
            : {}
    }
)

const selectIsLoading = createSelector(
    [selectPGMTypesIsLoading, selectPGMIsSaving, selectPGMIsDisabling],
    (typesIsLoading, PGMIsSaving, PGMIsDisabling) =>
        typesIsLoading || PGMIsSaving || PGMIsDisabling
)

const selectPartitionsForOptions = createSelector(
    (state, {panelId}) => selectActivePartitions(state, panelId),
    (partitions) => partitions.map(({id, name}) => ({value: id, label: name}))
)

export function withPGMForm() {
    return compose(
        connect(
            createSelector(
                [
                    selectPGM,
                    selectPGMParents,
                    selectPGMPortsByParents,
                    selectPGMTypes,
                    selectPGMOptions,

                    selectIsLoading,

                    selectPartitionsForOptions,
                ],
                (pgm, parents, ports, types, options, isLoading, partitionsList) => ({
                    pgm,
                    parents,
                    ports,
                    types,
                    options,
                    isLoading,
                    partitionsList,
                })
            ),
            (dispatch, {panelId}) =>
                bindActionCreators(
                    {
                        fetchPGMList: () => fetchPGMList(panelId),
                        fetchPGMTypes: () => fetchPGMTypes(panelId),
                        onSubmit: (id, type, options) =>
                            startSavePGM({panelId, id, type, options}),
                    },
                    dispatch
                )
        )
    )
}
