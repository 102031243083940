import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {ReactComponent as IconAdd} from 'ipmp-react-ui/icons/plus.svg'
import Button from 'ipmp-react-ui/Button'

import withProps from 'containers/withProps'
import {__} from 'utils/i18n'
import {showAddEventRuleModal} from 'modules/modals/actions'

export default compose(
    connect(null, (dispatcher) =>
        bindActionCreators(
            {
                showAddEventRuleModal,
            },
            dispatcher
        )
    ),
    withProps(({showAddEventRuleModal}) => ({
        Icon: IconAdd,
        onClick: showAddEventRuleModal,
        label: __('Add Event Rules'),
    }))
)(Button)
