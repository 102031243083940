import React, {Component} from 'react'

import ServiceDropDown from './GridBar/DropDowns/ServiceDropDown'
import FaultsMonitoringDropDown from './GridBar/DropDowns/FaultsMonitoringDropDown'

import AddPanelButton from './GridBar/Buttons/AddPanelButton'
import CreateReportButton from './GridBar/Buttons/CreateReportButton'

import CrossServerSearchButton from './CrossServerSearch/CrossServerSearchButton'

import PanelsPager from './GridBar/PanelsPager'
import PanelsDropDown from './GridBar/PanelsDropDown'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import InitiateRemoteInspectionButton from './GridBar/Buttons/InitiateRemoteInspectionButton'

export default class PanelsGridBar extends Component {
    render() {
        return (
            <Bar>
                <PanelsDropDown />

                <ServiceDropDown />
                <FaultsMonitoringDropDown />

                <CreateReportButton />
                <InitiateRemoteInspectionButton />

                <BarSpace />

                <PanelsPager />
                <AddPanelButton />

                <CrossServerSearchButton />
            </Bar>
        )
    }
}
