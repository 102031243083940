import Void from 'components/Void'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import {discovery as permission} from 'permissions/panels/selection'

import withPanel, {withRouterPanelId} from 'containers/withPanel'
import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import {withFeatureRejection} from 'containers/withFeature'
import {selectForceDiscovery} from 'modules/features/store/selectors'

import {MenuItem} from 'ipmp-react-ui/Menu'
import {ReactComponent as IconDiscovery} from 'ipmp-react-ui/icons/discovery.svg'

import {__} from 'utils/i18n'
import {showTriggerDiscoveryModal} from 'modules/modals/actions'

export default compose(
    withPanel(),
    withPermission({isVisible: permission}),
    withVisibility(),
    withProps(() => ({
        Icon: IconDiscovery,
        title: __('Trigger panel discovery'),
        children: __('Trigger Discovery'),
    })),
    connect(null, (dispatch, {panel}) =>
        bindActionCreators(
            {
                onClick: () => showTriggerDiscoveryModal([panel.id]),
            },
            dispatch
        )
    ),
    withRouterPanelId(),
    withFeatureRejection(selectForceDiscovery, Void)
)(MenuItem)
