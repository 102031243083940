import React, {Component} from 'react'
import Device from 'components/Devices/Device'

export default class ChildrenTab extends Component {
    render() {
        const {devices, onSelect} = this.props

        if (!devices || !devices.length) {
            return null
        }

        return (
            <div className="devicesList">
                {devices.map((device) => (
                    <Device {...{device}} onClick={onSelect} />
                ))}
            </div>
        )
    }
}
