import React, {Component} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

import withProps from 'containers/withProps'
import {selectZoneTypeNameById} from 'modules/zoneTypes/store/selectors'

class ZoneTypeCell extends Component {
    static propTypes = {
        name: PropTypes.string,
    }

    render() {
        const {name} = this.props
        return (
            <OverflowTextTooltip className={'evenRules-elements-name'}>
                {name}
            </OverflowTextTooltip>
        )
    }
}

export default compose(
    withProps(({row: {zoneTypeId}}) => ({
        id: zoneTypeId,
    })),
    connect(selectZoneTypeNameById)
)(ZoneTypeCell)
