import React, {Component} from 'react'
import {compose} from 'redux'
import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'

import bar from 'permissions/groups/bar'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import RemoveGroupsButton from './GridBar/RemoveGroupsButton'
import CreateGroupButton from './GridBar/CreateGroupButton'
import GroupsPager from './GridBar/GroupsPager'
import GroupsSelectionDropDown from './GridBar/GroupsSelectionDropDown'

export class GroupsBar extends Component {
    render() {
        return (
            <Bar>
                <GroupsSelectionDropDown />
                <RemoveGroupsButton />

                <BarSpace />

                <GroupsPager />
                <CreateGroupButton />
            </Bar>
        )
    }
}

export default compose(withPermission({isVisible: bar}), withVisibility())(GroupsBar)
