import StringValue from 'components/Configuration/Row/StringValue'
import {__} from 'utils/i18n'

const regexp = /^\d{0,8}$/

export default class PinValue extends StringValue {
    getMaxLength() {
        return 8
    }

    validate(value) {
        if (!regexp.test(value)) {
            return __('Should contains only digits')
        }
    }
}
