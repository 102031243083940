import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export default class CircularProgress extends Component {
    static propTypes = {
        value: PropTypes.number,
    }

    makePath(progress, from = 0) {
        const angleFrom = from * Math.PI * 2
        const angle = progress * Math.PI * 2

        const fx = 10 + Math.sin(angleFrom) * 6
        const fy = 10 - Math.cos(angleFrom) * 6

        const x = 10 + Math.sin(angleFrom + angle) * 6
        const y = 10 - Math.cos(angleFrom + angle) * 6

        const arc = angle > Math.PI ? 1 : 0

        return `M10 10 L${fx} ${fy} A6 6 0 ${arc} 1 ${x} ${y} Z`
    }

    render() {
        const {value, className} = this.props
        const progress = Math.max(0.05, (value || 0) / 100)

        return (
            <svg
                viewBox="0 0 20 20"
                width="20"
                height="20"
                className={classnames(className, 'circularProgress')}
            >
                <path d={this.makePath(progress)} fill="currentColor" />
            </svg>
        )
    }
}
