import {all} from 'redux-saga/effects'

import list from './list/saga'
import store from './store/saga'
import permissions from './permissions/saga'
import assignableRoles from './assignableRoles/saga'

export default function* () {
    yield all([list(), store(), permissions(), assignableRoles()])
}
