import {withPermission} from 'containers/withPermission'
import React, {Component} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {exports, request} from 'permissions/panel/devices/video/actions'

import withLoader from 'containers/withLoader'
import {withDeviceVideoOnDemand} from 'containers/withDevices'

import Video from 'components/Video'
import Slider from 'ipmp-react-ui/Slider'
import {Tab} from 'ipmp-react-ui/Tabs'

import {__} from 'utils/i18n'

import {selectVod} from 'modules/features/store/selectors'
import get from 'lodash-es/get'

class VideoOnDemandTab extends Component {
    static propTypes = {
        isRequestAllowed: PropTypes.bool,
        isExportAllowed: PropTypes.bool,
        panelId: PropTypes.number,
        device: PropTypes.shape({
            id: PropTypes.number,
            zone: PropTypes.number,
        }),
        onStartRecord: PropTypes.func,
        video: PropTypes.shape({
            frames: PropTypes.arrayOf(PropTypes.string),
            video: PropTypes.objectOf(PropTypes.string),
            time: PropTypes.string,
            isClosed: PropTypes.bool,
        }),
        history: PropTypes.arrayOf(
            PropTypes.shape({
                frames: PropTypes.arrayOf(PropTypes.string),
                video: PropTypes.objectOf(PropTypes.string),
                time: PropTypes.string,
            })
        ),
        process: PropTypes.shape({
            status: PropTypes.string,
            isFailed: PropTypes.bool,
            isSuccessful: PropTypes.bool,
            isRunning: PropTypes.bool,
        }),
    }

    state = {
        selectedVideo: null,
    }
    constructor(props) {
        super(props)
        this.state = {
            selectedVideo: null,
            // for check new video from VOD
            lastVideoTime: get(props, ['video', 'time'], null),
        }
    }

    static getDerivedStateFromProps({video}, state) {
        const lastVideoTime = get(video, 'time', null)
        const isNewVideoFromVOD = state.lastVideoTime !== lastVideoTime

        if (isNewVideoFromVOD) {
            return {...state, lastVideoTime, selectedVideo: null}
        }

        return state
    }

    onSliderSelect = (video) => {
        this.setState(() => {
            return {selectedVideo: video}
        })
    }

    getLocation() {
        const {device} = this.props

        return device.traits.location && device.traits.location.name
    }

    get resolveVideo() {
        if (!this.state.selectedVideo) {
            return this.props.video
        }

        return this.state.selectedVideo
    }

    render() {
        const {
            onStartRecord,
            process,
            isRequestAllowed,
            isExportAllowed,
            device: {
                traits: {vod},
            },
            feature,
            history,
            video: mainVideo,
        } = this.props
        const video = this.resolveVideo
        const {frames, time} = video || {}

        return (
            <Tab name={__('Video On Demand')}>
                <Video
                    title={this.getLocation()}
                    frames={(video && frames) || []}
                    video={video && video.video}
                    isClosed={mainVideo && mainVideo.isClosed}
                    time={video && time}
                    process={process}
                    onStartRecord={onStartRecord}
                    isRequestAllowed={isRequestAllowed}
                    isExportAllowed={isExportAllowed}
                    trait={vod}
                    feature={feature}
                />
                {<hr className={'top-slider-line'} />}
                {history && <Slider items={history} onSelect={this.onSliderSelect} />}
            </Tab>
        )
    }
}

export default compose(
    withPermission({
        isRequestAllowed: request,
        isExportAllowed: exports,
    }),
    connect(selectVod),
    withDeviceVideoOnDemand(),
    withLoader(
        ({fetch}) => fetch(),
        ({erase}) => erase()
    )
)(VideoOnDemandTab)
