import React, {Component} from 'react'
import {compose} from 'redux'

import Page from 'ipmp-react-ui/Page'

import InteractiveUsersBar from './InteractiveUsersBar'
import InteractiveUsersSearch from './InteractiveUsersSearch'
import InteractiveUsersTable from './InteractiveUsersTable'
import InteractiveUserBlind from './InteractiveUserBlind'

import {withPermission, withRejection} from 'containers/withPermission'
import withProps from 'containers/withProps'
import page from 'permissions/interactiveUsers/page'
import TableWithBlind from 'components/Table/TableWithBlind'

class InteractiveUsersContent extends Component {
    render() {
        return <TableWithBlind children={[InteractiveUserBlind, InteractiveUsersTable]} />
    }
}

export default compose(
    withPermission({isAllowed: page}),
    withRejection(),
    withProps({
        Bar: InteractiveUsersBar,
        Top: InteractiveUsersSearch,
        Content: InteractiveUsersContent,
    })
)(Page)
