import {createAction} from 'redux-actions'

export const fetch = createAction('PANELS/LOG/FETCH', (panelId, type) => ({
    panelId,
    type,
}))
export const refresh = createAction('PANELS/LOG/REFRESH', (panelId, type) => ({
    panelId,
    type,
}))
export const download = createAction('PANELS/LOG/DOWNLOAD', (panelId, type) => ({
    panelId,
    type,
}))
export const more = createAction('PANELS/LOG/MORE', (panelId, type) => ({panelId, type}))
export const update = createAction('PANELS/LOG/UPDATE', null, (_, panelId, type) => ({
    panelId,
    type,
}))
export const receive = createAction('PANELS/LOG/RECEIVE', null, (_, panelId, type) => ({
    panelId,
    type,
}))
