import React, {Component, Fragment} from 'react'

export default class extends Component {
    render() {
        const {string} = this.props

        return (
            <Fragment>
                {string.split('').map((letter, idx) => (
                    <span style={{display: 'inline-block'}} key={`${idx}$char`}>
                        {letter === ' '
                            ? '\u00A0' // nbps!
                            : letter}
                    </span>
                ))}
            </Fragment>
        )
    }
}
