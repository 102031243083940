import {compose} from 'redux'

import {withRouter} from 'react-router-dom'

import BasicConfigurationsGridBar from './BasicConfigurationsGridBar'
import BasicConfigurationsTable from './BasicConfigurationsTable'
import BasicConfigurationsSearch from './BasicConfigurationsSearch'

import Page from 'ipmp-react-ui/Page'
import withProps from 'containers/withProps'

export default compose(
    withRouter,
    withProps({
        Top: BasicConfigurationsSearch,
        Bar: BasicConfigurationsGridBar,
        Content: BasicConfigurationsTable,
    })
)(Page)
