import createRowsResponseParser from 'api/base/createRowsResponseParser'
import generateSuggestString from 'api/base/generateSuggestString'
import generateFilterString from 'api/base/generateFilterString'
import mapSuggests from 'api/base/mapSuggests'
import mapValidationErrors from 'api/base/mapValidationErrors'

import mapKeys from 'lodash-es/mapKeys'

import dataMapper from 'utils/dataMapper'
import {get, post} from 'api/http'

export const mapRole = dataMapper((data) => ({
    id: parseInt(data.roe_id),
    name: data.roe_name,
    parentId: parseInt(data.roe_roe_id),
    parentName: data.role_name,
    userId: parseInt(data.usr_id),
    userName: data.usr_name,
    isEditable: !!data.editable,
    groups:
        data.groups &&
        data.groups.map((group) => ({
            id: parseInt(group.utg_id),
            name: group.utg_name,
        })),
    relatedRoles: data.relatedRoles,
    relatedUsers: data.relatedUsers,
    removable: data.removable,
    isRemovable:
        data.removable &&
        parseInt(data.relatedUsers) === 0 &&
        parseInt(data.relatedRoles) === 0,
}))

const keysMap = {
    id: 'roe_id',
    name: 'roe_name',
    parentId: 'roe_roe_id',
    parentName: 'role_name',
    userId: 'usr_id',
    userName: 'usr_name',
    isEditable: 'editable',
    groups: 'utg_id',
}

const mapFormData = (data) => mapKeys(data, (value, key) => keysMap[key] || key)

export function fetch({start, perPage: count, filters, query}) {
    return get('/role/getallroles', {
        start,
        count,
        query,
        filter: generateFilterString(filters, keysMap),
    }).then(createRowsResponseParser(mapRole))
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('/role/suggestroles', {suggest, start, count}).then(
        mapSuggests(fields, keysMap)
    )
}

export function assignableRoles() {
    return get('/role/roles').then(createRowsResponseParser(mapRole))
}

export function add(data) {
    return post('/role/add', mapFormData(data)).catch((e) =>
        mapValidationErrors(e, keysMap)
    )
}

export function edit(id, data) {
    return post(
        '/role/edit',
        mapFormData({
            id,
            ...data,
        })
    )
        .then(mapRole)
        .catch((e) => mapValidationErrors(e, keysMap))
}

export function remove(ids) {
    return post('/role/remove', {roe_id: ids})
}

export function fetchPermissions(roleId) {
    return get('/role/permissions', {roe_id: roleId})
}

export function editPermissions(roleId, permissions) {
    return post('/role/setpermissions', {roe_id: roleId, permissions}).catch(
        mapValidationErrors
    )
}
