import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import {upload} from 'permissions/panel/configuration/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withPanel from 'containers/withPanel'
import withConfirmation from 'containers/withConfirmation'

import Button from 'ipmp-react-ui/Button'
import InteractiveBar from 'ipmp-react-ui/InteractiveBar'

import {__, __n} from 'utils/i18n'

const ConfirmationButton = withConfirmation()(Button)

class ConfigurationChangesInteractiveBar extends Component {
    static propTypes = {
        pendingChanges: PropTypes.number,
        commitChanges: PropTypes.func,
        resetChanges: PropTypes.func,
    }

    render() {
        const {pendingChanges, commitChanges, resetChanges} = this.props

        return (
            <InteractiveBar active={pendingChanges > 0}>
                {__n(
                    '1 pending configuration change',
                    '%d pending configuration changes',
                    pendingChanges
                )}

                <ConfirmationButton
                    small
                    onClick={commitChanges}
                    label={__('Upload')}
                    title={__('Upload configuration changes')}
                    message={__(
                        'Uploading configuration process can take more than 5 minutes.\nDo you really want to apply changes now?'
                    )}
                    positive={__('Upload')}
                />

                <Button small borderless onClick={resetChanges} label={__('Cancel')} />
            </InteractiveBar>
        )
    }
}

export default compose(
    withPermission({isVisible: upload}),
    withVisibility(),
    withPanel()
)(ConfigurationChangesInteractiveBar)
