import React, {Component} from 'react'

import {compose} from 'redux'

import bar from 'permissions/remoteInspections/bar'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Buttons from 'ipmp-react-ui/Buttons'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import ConfigureRemoteInspectionOptionsButton from './Bar/ConfigureRemoteInspectionOptionsButton'
import ScheduleRemoteInspectionsByCsvButton from './Bar/ScheduleRemoteInspectionsByCsvButton'
import MarkAsViewedRemoteInspectionButton from './Bar/MarkAsViewedRemoteInspectionButton'
import InitiateRemoteInspectionButton from './Bar/InitiateRemoteInspectionButton'
import ScheduleRemoteInspectionButton from './Bar/ScheduleRemoteInspectionButton'
import CancelRemoteInspectionButton from './Bar/CancelRemoteInspectionButton'
import RemoteInspectionsDropDown from './Bar/RemoteInspectionsDropDown'
import RemoteInspectionsPager from './Bar/RemoteInspectionsPager'

class RemoteInspectionBar extends Component {
    render() {
        return (
            <Bar>
                <RemoteInspectionsDropDown />
                <MarkAsViewedRemoteInspectionButton />

                <Buttons>
                    <ScheduleRemoteInspectionButton />
                    <InitiateRemoteInspectionButton />
                    <CancelRemoteInspectionButton />
                </Buttons>

                <BarSpace />

                <RemoteInspectionsPager />
                <ConfigureRemoteInspectionOptionsButton />
                <ScheduleRemoteInspectionsByCsvButton />
            </Bar>
        )
    }
}

export default compose(
    withPermission({isVisible: bar}),
    withVisibility()
)(RemoteInspectionBar)
