import {connect} from 'react-redux'
import {ReactComponent as AlertOffIcon} from 'ipmp-react-ui/icons/alert-off.svg'
import React, {Component} from 'react'
import classNames from 'classnames'
import DropDown, {DropDownContent, ALIGN_LEFT, ALIGN_TOP} from 'ipmp-react-ui/DropDown'
import Togglebox from 'ipmp-react-ui/ToggleBox'
import {__} from 'utils/i18n'
import {bindActionCreators} from 'redux'
import {
    setAlarmsEnabled,
    setAlarmsSoundEnabled,
    setAlarmsNotificationsEnabled,
} from 'modules/settings/actions'
import PropTypes from 'prop-types'

const preventDefault = (e) => e.preventDefault()

export class NewEventsCounter extends Component {
    static propTypes = {
        isEnabled: PropTypes.bool,
        isSoundEnabled: PropTypes.bool,
        isNotificationsEnabled: PropTypes.bool,

        setAlarmsEnabled: PropTypes.func,
        setAlarmsSoundEnabled: PropTypes.func,
        setAlarmsNotificationsEnabled: PropTypes.func,
        alarmCount: PropTypes.number,
    }

    handleChangeEnabled = (e) => {
        const {setAlarmsEnabled} = this.props
        setAlarmsEnabled(e.target.checked)
    }

    handleChangeSoundEnabled = (e) => {
        const {setAlarmsSoundEnabled} = this.props
        setAlarmsSoundEnabled(e.target.checked)
    }

    handleChangeNotificationsEnabled = (e) => {
        const {setAlarmsNotificationsEnabled} = this.props
        setAlarmsNotificationsEnabled(e.target.checked)
    }

    renderTrigger() {
        const {isEnabled, alarmCount} = this.props

        if (!isEnabled) {
            return <AlertOffIcon className="nav-link-coin-icon" />
        }

        return <span>{alarmCount || '0'}</span>
    }

    render() {
        const {isEnabled, isSoundEnabled, isNotificationsEnabled, alarmCount} = this.props

        return (
            <div onClick={preventDefault}>
                <DropDown
                    className={classNames('nav-link-coin', {
                        'nav-link-coin--alarm': isEnabled && !!alarmCount,
                    })}
                    trigger={this.renderTrigger()}
                    align={ALIGN_LEFT | ALIGN_TOP}
                >
                    <DropDownContent title={__('Events supervision')}>
                        <Togglebox
                            label={__('Enable')}
                            onChange={this.handleChangeEnabled}
                            checked={isEnabled || false}
                        />

                        <Togglebox
                            label={__('Notifications')}
                            onChange={this.handleChangeNotificationsEnabled}
                            disabled={!isEnabled}
                            checked={isNotificationsEnabled || false}
                        />

                        <Togglebox
                            label={__('Sound')}
                            onChange={this.handleChangeSoundEnabled}
                            disabled={!isEnabled}
                            checked={isSoundEnabled || false}
                        />
                    </DropDownContent>
                </DropDown>
            </div>
        )
    }
}

export default connect(
    ({
        events: {
            alarms: {count},
        },
        settings: {
            alarms: {isEnabled, isSoundEnabled, isNotificationsEnabled},
        },
    }) => ({
        alarmCount: count,
        isEnabled,
        isSoundEnabled,
        isNotificationsEnabled,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setAlarmsEnabled,
                setAlarmsSoundEnabled,
                setAlarmsNotificationsEnabled,
            },
            dispatch
        )
)(NewEventsCounter)
