import {createSelector} from 'reselect'
import get from 'lodash-es/get'

export const selectInteractiveUserAddPanelList = createSelector(
    (state) => state.interactiveUserPanels.addPanelList,
    (state) => state.panels.store.byIds,
    ({page, start, total, error, isLoading}, store) => ({
        rows: page
            .map((id) => store[id] || {})
            // Filter panels without interactive user feature
            .filter((row) => get(row, ['features', 'applications', 'user'], false)),
        isLoading,
        start,
        total,
        error,
    })
)
