import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {showCreateReportModal} from 'modules/modals/actions'

import {withPermission} from 'containers/withPermission'
import {withSelectionHandler} from 'containers/withSelection'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconFile} from 'ipmp-react-ui/icons/file.svg'

import {__} from 'utils/i18n'
import {createReport} from 'permissions/panels/selection'
import {withSelectionFeature} from 'containers/withFeature'
import {REPORTS} from 'constants/features'

export const CreateReportButton = compose(
    withProps(() => ({
        Icon: IconFile,
        children: __('Create Report'),
    })),
    withPermission({isVisible: createReport}),
    withVisibility(),
    withSelectionHandler()
)(Button)

export default compose(
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                onClick: showCreateReportModal,
            },
            dispatch
        )
    ),
    withSelectionFeature(REPORTS)
)(CreateReportButton)
