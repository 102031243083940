import React, {Component} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import withForm from 'containers/withForm'
import withSnacks from 'containers/withSnacks'

import Parser from 'utils/RRI/Parser'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconFile} from 'ipmp-react-ui/icons/file.svg'

import {__} from 'utils/i18n'
import {CardActions, CardClose, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import Form from 'ipmp-react-ui/Form'
import Fab from 'ipmp-react-ui/Fab'
import Modal from 'ipmp-react-ui/Modal'
import {scheduleInspectionByCsvFile} from 'modules/forms/handlers'

class ScheduleByCsv extends Component {
    state = {
        data: null,
    }

    static propTypes = {
        hide: PropTypes.func.isRequired,
        snackShow: PropTypes.func.isRequired,
        acceptedMaxLinesInFile: PropTypes.number.isRequired,
        acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
    }

    static defaultProps = {
        acceptedMaxLinesInFile: 1000,
        acceptedFileTypes: ['text/csv', 'application/vnd.ms-excel'],
    }

    readFile = (event) => {
        const {snackShow, acceptedMaxLinesInFile} = this.props

        const lines = event.target.result.trim().split('\n')

        if (event.target.result === 0) {
            this.resetInputValue()
            return snackShow(__('File is empty.'))
        }

        if (lines.length > acceptedMaxLinesInFile) {
            this.resetInputValue()
            return snackShow(__('File contains more than 1000 rows'))
        }

        const parser = new Parser(lines)

        if (parser.errors.length > 0) {
            this.resetInputValue()
            return snackShow(parser.errors[0])
        }

        this.setState({
            data: parser.hash(),
        })
    }

    process = (e) => {
        if (e.target.files.length === 0) {
            return
        }

        const {snackShow, acceptedFileTypes} = this.props

        if (acceptedFileTypes.indexOf(e.target.files[0].type) === -1) {
            return snackShow(__('File type is not accepted'))
        } else {
            const reader = new FileReader()
            reader.onload = this.readFile

            this.setState({filename: e.target.files[0].name, input: e.target})
            reader.readAsText(e.target.files[0])
        }
    }

    onSubmit = () => {
        const {snackShow, handle} = this.props
        const {data} = this.state

        if (data) {
            handle(data)
        } else {
            return snackShow(__('File is not selected'))
        }
    }

    reset = (e) => {
        e.preventDefault()

        this.setState({
            data: null,
        })
    }

    renderFileInput() {
        const accept = this.props.acceptedFileTypes

        return (
            <input
                className="btn-input"
                type="file"
                accept={accept}
                onChange={this.process}
            />
        )
    }

    renderSubmission() {
        const {data, filename} = this.state

        return (
            <div className="cols">
                <Fab flat className="col">
                    <IconFile />
                    {this.renderFileInput()}
                </Fab>

                <div className="col">
                    <h1 className="title title--large" style={{marginBottom: 8}}>
                        {filename}
                    </h1>
                    <p>{__('%d panels to schedule', data.length)}</p>
                </div>
            </div>
        )
    }

    renderHelp() {
        return (
            <div>
                <div className="block">
                    <h3 className="hint">{__('STRUCTURE')}</h3>

                    <ul className="ulist">
                        <li>{__('Panel Id')}</li>
                        <li>{__('Start date')}</li>
                        <li>{__('Repetition [0,1,3,6,9]')}</li>
                    </ul>
                </div>

                <div className="block">
                    <h3 className="hint">{__('LIMITATIONS')}</h3>

                    <ul className="ulist">
                        <li>{__('Zero/empty repetition means one-time report')}</li>
                        <li>{__('Date format: YYYY-MM-DD')}</li>
                    </ul>
                </div>

                <div className="block">
                    <h3 className="hint">{__('EXAMPLE')}</h3>

                    <ul className="ulist">
                        <li>A00000,2017-03-25,3</li>
                        <li>A00001,2017-03-26,</li>
                        <li>A00002,2017-03-27,9</li>
                    </ul>
                </div>
            </div>
        )
    }

    render() {
        const {hide, isLoading} = this.props
        const {data} = this.state

        return (
            <Modal onClose={hide}>
                <Form className="card" isLoading={isLoading}>
                    {hide && <CardClose onClick={hide} />}

                    <CardHeader onBack={data ? this.reset : null}>
                        {__('Scheduling RRI by CSV')}
                    </CardHeader>

                    <CardContent>
                        {data ? this.renderSubmission() : this.renderHelp()}
                    </CardContent>

                    <CardActions>
                        <Button borderless onClick={hide}>
                            {__('Dismiss')}
                        </Button>

                        {!data && (
                            <Button primary element="div">
                                {__('Choose file')}
                                {this.renderFileInput()}
                            </Button>
                        )}

                        {data && (
                            <Button primary onClick={this.onSubmit} type="submit">
                                {__('Schedule')}
                            </Button>
                        )}
                    </CardActions>
                </Form>
            </Modal>
        )
    }

    resetInputValue() {
        const {input} = this.state
        input.value = ''
    }
}

export default compose(withSnacks(), withForm(scheduleInspectionByCsvFile))(ScheduleByCsv)
