import {PROCESS_ERROR_CANCELED_BY_REQUEST} from 'constants/processError'
import {ensureProcesses} from 'modules/processes/manager/ensureProcess'
import {all, takeEvery, select, put, call} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/processes'

import {snackShow} from 'modules/snacks'
import {takeEveryProcessProgress} from 'modules/processes/manager/takeProcess'

export default function* () {
    yield all([
        takeEvery(actions.update, watchUpdate),
        takeEvery(actions.stop, watchStop),
        takeEveryProcessProgress(null, watchProcessProgress),
    ])
}

function* watchStop({payload: ids}) {
    const toStop = yield select((state) =>
        ids
            .map((id) => state.processes.store.byIds[id])
            .filter((process) => process.isStoppable)
    )

    if (!toStop.length) {
        return
    }

    yield put(
        actions.update(
            toStop.map((process) => ({
                id: process.id,
                error: PROCESS_ERROR_CANCELED_BY_REQUEST,
                errorMessage: 'Cancelled',
                isStoppable: false,
                isRunning: false,
                isFailed: true,
                finishedAt: new Date(),
            }))
        )
    )

    try {
        yield call(
            api.stop,
            toStop.map((process) => process.id)
        )
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.update(toStop))
    }
}

function* watchProcessProgress(process) {
    yield put(actions.update(process))
}

function* watchUpdate({payload}) {
    yield ensureProcesses(payload.filter((process) => process.isRunning && !process.key))
}
