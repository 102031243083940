import dataMapper from 'utils/dataMapper'

export const mapServerVersion = dataMapper((info) => ({
    version: info.version,
    revision: info.revision,
    hostname: info.hostname,
    releaseNotes: info.release_notes,
}))

export const mapLicenseInfo = dataMapper((info) => ({
    limits: {
        centralStationNum: parseInt(info.limits.central_station_num),
        panels: parseInt(info.limits.panels),
        panelsDualPath: parseInt(info.limits.panels_dual_path),
        panelsNeo: parseInt(info.limits.panels_neo),
    },
    name: info.name,
    valid: info.valid,
    features: info.features,
}))

export const mapSeverTime = dataMapper((serverTime) => ({
    timezone: serverTime.timezone,
    locale: serverTime.locale,
    timestamp: Number(`${serverTime.timestamp}000`),
}))
