import React, {PureComponent} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import withForm from 'containers/withForm'
import {setSystemSettings} from 'modules/forms/handlers'

import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'

import Input from 'ipmp-react-ui/Input'

class CustomerInfoSettings extends PureComponent {
    static propTypes = {
        data: PropTypes.shape({
            url: PropTypes.string,
        }),
    }

    static defaultProps = {
        data: {},
    }

    handle = (data) => {
        const {handle} = this.props
        handle(data)
    }

    get rules() {
        return {}
    }

    render() {
        const {data, ...props} = this.props
        return (
            <ModalCardForm
                onSubmit={this.handle}
                rules={this.rules}
                header={__('Edit Customer Info')}
                confirmOnDismiss
                {...props}
            >
                <Input label={__('Name')} name="name" defaultValue={data.name} />
                <Input label={__('Address')} name="address" defaultValue={data.address} />
                <Input
                    label={__('Person Name')}
                    name="person_name"
                    defaultValue={data.person_name}
                />
                <Input
                    label={__('Person Email')}
                    name="person_email"
                    defaultValue={data.person_email}
                />
                <Input
                    label={__('Person Phone')}
                    name="person_phone"
                    defaultValue={data.person_phone}
                />
                <Input
                    label={__('Web Admin Email')}
                    name="web_admin_email"
                    defaultValue={data.web_admin_email}
                />
            </ModalCardForm>
        )
    }
}
export default compose(withForm(setSystemSettings.CustomerInfo))(CustomerInfoSettings)
