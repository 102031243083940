import {call, put, select} from 'redux-saga/effects'
import * as api from 'api/installers'
import {snackShow} from 'modules/snacks'

import {update as updateInstaller} from 'modules/installers/store/actions'
import {fetch as fetchPanels} from '../actions'
import {revertRemove} from '../actions'

export function* watchAssignPanel({payload: panelId}) {
    try {
        const installerId = yield select(
            (state) => state.installerPanels.list.installerId
        )
        const installer = yield select(
            (state) => state.installers.store.byIds[installerId]
        )

        yield call(api.assignInstallerToPanel, {panelId, installerId})
        yield put(fetchPanels())
        yield put(
            updateInstaller({
                ...installer,
            })
        )
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(revertRemove(error))
    }
}
