import {createAction} from 'redux-actions'

export const fetch = createAction(
    'BASIC_CONFIGURATION/SELECT/FECTH',
    (prefix = '', count) => ({prefix, count})
)
export const setLoading = createAction(
    'BASIC_CONFIGURATION/SELECT/SET_LOADING',
    (isLoading) => ({isLoading})
)
