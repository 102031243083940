import {all, call, put, takeEvery} from 'redux-saga/effects'
import {snackShow} from 'modules/snacks'
import has from 'lodash-es/has'

import * as api from 'api/interactiveUsers'
import * as actions from './actions'

import watchResetPassword from './watchers/watchResetPassword'

export default function* () {
    yield all([
        takeEvery(actions.enable, watchEnable),
        takeEvery(actions.suspend, watchSuspend),
        takeEvery(actions.resetPassword, watchResetPassword),
    ])
}

function* watchEnable({payload: userIds}) {
    for (let idx in userIds) {
        if (has(userIds, idx)) {
            try {
                const user = yield call(api.enable, userIds[idx])
                yield put(actions.update(user))
            } catch (error) {
                yield put(snackShow(error.message))
                yield put(actions.revert(userIds[idx]))
            }
        }
    }
}

function* watchSuspend({payload: userIds}) {
    for (let idx in userIds) {
        if (has(userIds, idx)) {
            try {
                const installer = yield call(api.suspend, userIds[idx])
                yield put(actions.update(installer))
            } catch (error) {
                yield put(snackShow(error.message))
                yield put(actions.revert(userIds[idx]))
            }
        }
    }
}
