import {__} from 'utils/i18n'
import {warn} from 'utils/log'

const SECTION_INTERACTIVE = 'Interactive'
const SECTION_RECEIVER = 'Receiver'
const SECTION_RESOLVE = 'Resolve'

const FEATURE_END_USER_REPORTING = 'End User Reporting'
const FEATURE_INSTALLER_APP = 'Installer App'
const FEATURE_USER_APP = 'User App'
const FEATURE_ALARMS_HANDLING = 'Alarms Handling'
const FEATURE_CENTRAL_STATIONS = 'Central Stations'
const FEATURE_RECEIVE_EVENTS = 'Receive Events'
const FEATURE_DLS_API = 'Dls Api'
const FEATURE_PANEL_CONFIGURATION = 'Panel Configuration'
const FEATURE_PANEL_DIAGNOSTIC = 'Panel Diagnostic'
const FEATURE_PANEL_EVENT_LOG = 'Panel Event Log'
const FEATURE_PANEL_LABELS = 'Panel Labels'
const FEATURE_PANEL_SET_STATE = 'Panel Set State'
const FEATURE_REMOTE_INSPECTION = 'Remote Inspection'
const FEATURE_REPORTS = 'Reports'
const FEATURE_SOFTWARE_UPGRADE = 'Software Upgrade'
const FEATURE_VIRTUAL_KEYPAD = 'Virtual Keypad'

export function featureName(feature) {
    switch (feature) {
        case FEATURE_END_USER_REPORTING:
            return __('End User Reporting')
        case FEATURE_INSTALLER_APP:
            return __('Installer App')
        case FEATURE_USER_APP:
            return __('User App')
        case FEATURE_ALARMS_HANDLING:
            return __('Alarms Handling')
        case FEATURE_CENTRAL_STATIONS:
            return __('Central Stations')
        case FEATURE_RECEIVE_EVENTS:
            return __('Receive Events')
        case FEATURE_DLS_API:
            return __('Dls Api')
        case FEATURE_PANEL_CONFIGURATION:
            return __('Panel Configuration')
        case FEATURE_PANEL_DIAGNOSTIC:
            return __('Panel Diagnostic')
        case FEATURE_PANEL_EVENT_LOG:
            return __('Panel Event Log')
        case FEATURE_PANEL_LABELS:
            return __('Panel Labels')
        case FEATURE_PANEL_SET_STATE:
            return __('Panel Set State')
        case FEATURE_REMOTE_INSPECTION:
            return __('Remote Inspection')
        case FEATURE_REPORTS:
            return __('Reports')
        case FEATURE_SOFTWARE_UPGRADE:
            return __('Software Upgrade')
        case FEATURE_VIRTUAL_KEYPAD:
            return __('Virtual Keypad')

        default:
            warn(`Unknown license feature: ${feature}`)
            return feature
    }
}

export function sectionName(section) {
    switch (section) {
        case SECTION_INTERACTIVE:
            return __('Interactive')
        case SECTION_RECEIVER:
            return __('Receiver')
        case SECTION_RESOLVE:
            return __('Resolve')

        default:
            warn(`Unknown license section: ${section}`)
            return section
    }
}
