import withRunnerLoader from 'containers/withRunnerLoader'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import find from 'lodash-es/find'
import some from 'lodash-es/some'

import page from 'permissions/panel/firmware/page'

import {VENDOR_NEO} from 'constants/panelVendorType'
import withLoader from 'containers/withLoader'
import {withPermission, withRejection} from 'containers/withPermission'
import {fetch, refresh as refreshRunner} from 'modules/panels/firmware/actions'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'
import Buttons from 'ipmp-react-ui/Buttons'
import Error from 'ipmp-react-ui/Error'
import Layout, {ScrollView} from 'ipmp-react-ui/Layout'
import {__} from 'utils/i18n'

import UpgradeButton from './Buttons/UpgradeButton'
import FirmwareRow from './FirmwareRow'

import {startPollRunnerData, stopPollRunnerData} from 'modules/runners/actions'
import withRunnerPoll from 'containers/withRunnerPoll'

export class Firmware extends Component {
    static propTypes = {
        panelId: PropTypes.number.isRequired,
        hasTransactionSupport: PropTypes.bool,
        appliances: PropTypes.arrayOf(
            PropTypes.shape({
                number: PropTypes.number,
                type: PropTypes.string,
                currentVersion: PropTypes.string,
                packages: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string,
                        description: PropTypes.string,
                        version: PropTypes.string,
                    })
                ),
            })
        ),
    }

    state = {
        selectedPackages: [],
    }

    onPackageChange = (newPackageName, type, number) => {
        const {hasTransactionSupport} = this.props

        if (!hasTransactionSupport) {
            if (newPackageName === '') {
                this.deselectPackage({type, number})
            } else {
                this.setState({
                    selectedPackages: [
                        {
                            type,
                            number,
                            packageName: newPackageName,
                        },
                    ],
                })
            }
        } else {
            const deselectLinkedPackages = this.deselectLinkedPackages({
                type,
                number,
            })
            const selectedPackages = this.selectLinkedPackages(
                newPackageName,
                deselectLinkedPackages
            )

            this.setState({selectedPackages})
        }
    }

    deselectPackage = ({type, number}) => {
        const {selectedPackages} = this.state
        const samePackage = find(selectedPackages, {type, number})
        if (samePackage) {
            this.setState({selectedPackages: []})
        }
    }

    deselectLinkedPackages = ({type, number}) => {
        const selectedPackageName = this.getSelectedPackageName({type, number})
        return !selectedPackageName
            ? [...this.state.selectedPackages]
            : this.state.selectedPackages.filter(
                  ({packageName}) => packageName !== selectedPackageName
              )
    }

    selectLinkedPackages = (packageName, packages) => {
        this.props.appliances.forEach(({number, type, packages: availablePackages}) => {
            if (some(availablePackages, {name: packageName})) {
                packages = packages.filter(
                    (pkg) => !(pkg.number === number && pkg.type === type)
                )

                packages.push({type, number, packageName})
            }
        })

        return packages
    }

    /** todo: move that state to reducer */
    getSelectedPackageName({type, number}) {
        const pkg = find(this.state.selectedPackages, {type, number})

        return pkg ? pkg.packageName : null
    }

    renderContent() {
        const {appliances} = this.props

        if (!appliances) {
            return null
        }

        return appliances.map((appliance) => (
            <FirmwareRow
                onChange={this.onPackageChange}
                key={`${appliance.type}${appliance.number}`}
                selectedPackageName={this.getSelectedPackageName(appliance)}
                {...appliance}
            />
        ))
    }

    renderHeader() {
        const {runner} = this.props
        const packages = Object.values(this.state.selectedPackages)

        return (
            <Bar orientation={RIGHT} className="bar">
                <Buttons>{!runner && <UpgradeButton packages={packages} />}</Buttons>
            </Bar>
        )
    }

    render() {
        const {appliances} = this.props

        if (!appliances || !appliances.length) {
            return <Error title={__('No upgradable packages')} />
        }

        return (
            <Layout vertical className="firmware">
                {this.renderHeader()}

                <div className="table-header">
                    <div className="table-header-cell">{__('Appliance')}</div>
                    <div className="table-header-cell">{__('Current version')}</div>
                    <div className="table-header-cell">{__('Apply package')}</div>
                </div>

                <ScrollView className="card">{this.renderContent()}</ScrollView>
            </Layout>
        )
    }
}

export default compose(
    withPermission({
        isAllowed: page,
    }),
    withRejection(),
    connect(
        ({panels: {firmware, store}, processes}, {match}) => {
            const panelId = parseInt(match.params.id)
            const {appliances, runner} = firmware[panelId] || {}

            return {
                panelId,
                isLoading: !appliances,
                appliances,
                runner,
                hasTransactionSupport: store.byIds[panelId].vendor === VENDOR_NEO,
            }
        },
        (dispatch) =>
            bindActionCreators(
                {
                    fetch,
                    startPollRunnerData,
                    stopPollRunnerData,
                    refreshRunner,
                },
                dispatch
            )
    ),
    withLoader(({fetch, panelId}) => {
        fetch(panelId)
    }),
    withRunnerPoll(),
    withRunnerLoader(
        () => __('Upgrading panel software…'),
        ({fetch, panelId}) => fetch(panelId)
    )
)(Firmware)
