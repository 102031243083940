import {compose} from 'redux'

import {edit} from 'permissions/panel/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'
import withPanel from 'containers/withPanel'

import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'
import {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: edit}),
    withVisibility(),
    withPanel(),
    withProps(({showEditPanelInfo}) => ({
        onClick: showEditPanelInfo,
        Icon: IconEdit,
        children: __('Edit'),
    }))
)(MenuItem)
