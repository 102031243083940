import {compose} from 'redux'
import {connect} from 'react-redux'

import withForm from 'containers/withForm'
import EventRuleForm from './EventRuleForm'
import {addEventRule} from 'modules/forms/handlers'
import {__} from 'utils/i18n'

export default compose(
    connect(() => ({
        header: __('Add Event Rule'),
    })),
    withForm(addEventRule)
)(EventRuleForm)
