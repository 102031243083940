import React, {Component} from 'react'

import Layout, {ScrollView} from 'ipmp-react-ui/Layout'
import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Checkbox from 'ipmp-react-ui/Checkbox'

import PanelInteractiveUsersTable from './PanelInteractiveUsersTable'

import __ from 'utils/i18n'
import AssignUserButton from 'pages/Panel/PanelInteractiveUsers/GridBar/AssignUserButton'
import {withPermission} from 'containers/withPermission'
import page from 'permissions/interactiveUsers/page'
import {compose} from 'redux'

class PanelInteractiveUsers extends Component {
    state = {
        isShowUnassignedUsers: false,
    }

    handleFilterChange = () =>
        this.setState({
            isShowUnassignedUsers: !this.state.isShowUnassignedUsers,
        })

    render() {
        const {isShowUnassignedUsers} = this.state

        return (
            <Layout vertical className="panelPage-content">
                <Bar>
                    <Checkbox
                        label={__('Show unassigned users')}
                        checked={isShowUnassignedUsers}
                        onChange={this.handleFilterChange}
                    />

                    <BarSpace />
                    <AssignUserButton />
                </Bar>
                <ScrollView>
                    <PanelInteractiveUsersTable
                        isShowUnassignedUsers={isShowUnassignedUsers}
                    />
                </ScrollView>
            </Layout>
        )
    }
}

export default compose(withPermission({isAllowed: page}))(PanelInteractiveUsers)
