import {put, select} from 'redux-saga/effects'
import {forget} from 'modules/panels/recent/actions'

export default function* forgetPanel(ids) {
    const recent = yield select((state) => state.panels.recent)

    if (recent && ids.includes(recent.id)) {
        yield put(forget())
    }
}
