import {handleActions} from 'redux-actions'

import {fetch, setLoading} from './actions'

const state = {
    isLoading: false,
}

export default handleActions(
    {
        [fetch]: (state) => ({
            ...state,
            isLoading: true,
        }),

        [setLoading]: (state, {payload: {isLoading}}) => ({
            ...state,
            isLoading,
        }),
    },
    state
)
