import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import withProps from 'containers/withProps'

import {__} from 'utils/i18n'
import {showRegisterInteractiveUserModal} from 'modules/modals/actions'
import {withPermission} from 'containers/withPermission'
import {register} from 'permissions/interactiveUsers/actions'
import {withVisibility} from 'containers/withVisibility'

export default compose(
    withPermission({
        isVisible: register,
    }),
    withVisibility(),
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                showRegisterInteractiveUserModal,
            },
            dispatch
        )
    ),
    withProps(({showRegisterInteractiveUserModal}) => ({
        label: __('Add User'),
        Icon: IconPlus,
        onClick: showRegisterInteractiveUserModal,
    }))
)(Button)
