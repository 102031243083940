import {__} from 'utils/i18n'

export const HOUSE_LOCATION_ATTIC = 0
export const HOUSE_LOCATION_BACK_DOOR = 1
export const HOUSE_LOCATION_BASEMENT = 2
export const HOUSE_LOCATION_BATHROOM = 3
export const HOUSE_LOCATION_BEDROOM = 4
export const HOUSE_LOCATION_CHILD_ROOM = 5
export const HOUSE_LOCATION_CLOSET = 6
export const HOUSE_LOCATION_DEN = 7
export const HOUSE_LOCATION_DINING_ROOM = 8
export const HOUSE_LOCATION_DOWNSTAIRS = 9
export const HOUSE_LOCATION_EMERGENCY = 10
export const HOUSE_LOCATION_FIRE = 11
export const HOUSE_LOCATION_FRONT_DOOR = 12
export const HOUSE_LOCATION_GARAGE = 13
export const HOUSE_LOCATION_GARAGE_DOOR = 14
export const HOUSE_LOCATION_GUEST_ROOM = 15
export const HOUSE_LOCATION_HALL = 16
export const HOUSE_LOCATION_KITCHEN = 17
export const HOUSE_LOCATION_LAUNDRY_ROOM = 18
export const HOUSE_LOCATION_LIVING_ROOM = 19
export const HOUSE_LOCATION_MASTER_BATHROOM = 20
export const HOUSE_LOCATION_MASTER_BEDROOM = 21
export const HOUSE_LOCATION_OFFICE = 22
export const HOUSE_LOCATION_UPSTAIRS = 23
export const HOUSE_LOCATION_UTILITY_ROOM = 24
export const HOUSE_LOCATION_YARD = 25
export const HOUSE_LOCATION_CUSTOM1 = 26
export const HOUSE_LOCATION_CUSTOM2 = 27
export const HOUSE_LOCATION_CUSTOM3 = 28
export const HOUSE_LOCATION_CUSTOM4 = 29
export const HOUSE_LOCATION_CUSTOM5 = 30

export default function location(id, name) {
    switch (id) {
        case HOUSE_LOCATION_ATTIC:
            return __('Attic')
        case HOUSE_LOCATION_BACK_DOOR:
            return __('Back Door')
        case HOUSE_LOCATION_BASEMENT:
            return __('Basement')
        case HOUSE_LOCATION_BATHROOM:
            return __('Bathroom')
        case HOUSE_LOCATION_BEDROOM:
            return __('Bedroom')
        case HOUSE_LOCATION_CHILD_ROOM:
            return __('Child Room')
        case HOUSE_LOCATION_CLOSET:
            return __('Closet')
        case HOUSE_LOCATION_DEN:
            return __('Den')
        case HOUSE_LOCATION_DINING_ROOM:
            return __('Dining Room')
        case HOUSE_LOCATION_DOWNSTAIRS:
            return __('Downstairs')
        case HOUSE_LOCATION_EMERGENCY:
            return __('Emergency')
        case HOUSE_LOCATION_FIRE:
            return __('Fire')
        case HOUSE_LOCATION_FRONT_DOOR:
            return __('Front Door')
        case HOUSE_LOCATION_GARAGE:
            return __('Garage')
        case HOUSE_LOCATION_GARAGE_DOOR:
            return __('Garage Door')
        case HOUSE_LOCATION_GUEST_ROOM:
            return __('Guest Room')
        case HOUSE_LOCATION_HALL:
            return __('Hall')
        case HOUSE_LOCATION_KITCHEN:
            return __('Kitchen')
        case HOUSE_LOCATION_LAUNDRY_ROOM:
            return __('Laundry Room')
        case HOUSE_LOCATION_LIVING_ROOM:
            return __('Living Room')
        case HOUSE_LOCATION_MASTER_BATHROOM:
            return __('Master Bathroom')
        case HOUSE_LOCATION_MASTER_BEDROOM:
            return __('Master Bedroom')
        case HOUSE_LOCATION_OFFICE:
            return __('Office')
        case HOUSE_LOCATION_UPSTAIRS:
            return __('Upstairs')
        case HOUSE_LOCATION_UTILITY_ROOM:
            return __('Utility Room')
        case HOUSE_LOCATION_YARD:
            return __('Yard')
        case HOUSE_LOCATION_CUSTOM1:
            return __('Custom1')
        case HOUSE_LOCATION_CUSTOM2:
            return __('Custom2')
        case HOUSE_LOCATION_CUSTOM3:
            return __('Custom3')
        case HOUSE_LOCATION_CUSTOM4:
            return __('Custom4')
        case HOUSE_LOCATION_CUSTOM5:
            return __('Custom5')
    }

    return name
}
