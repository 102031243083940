import {loggedOut} from 'modules/auth/sign/actions'

export default function createLogoutReducer(reducer) {
    return (state, action) => {
        if (action.type === loggedOut.toString()) {
            return reducer(undefined, action)
        }

        return reducer(state, action)
    }
}
