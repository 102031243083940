import {handleActions} from 'redux-actions'
import keyBy from 'lodash-es/keyBy'
import {merge} from 'immutable-modify'

import CacheFields from 'utils/cacheFieldsForOptimisticUa'

import * as actions from './actions'
import {STATUS_SUSPENDED, STATUS_VERIFIED} from 'constants/interactiveUserStatus'

const state = {}

const emptyPanelInteractiveUser = {
    role: null,
    email: null,
    isAssign: false,
    status: null,
    oldStatus: null,
    createdAt: null,
}

const cacheFields = new CacheFields([
    'role',
    'email',
    'isAssign',
    'status',
    'oldStatus',
    'createdAt',
])

export default handleActions(
    {
        [actions.update](state, {payload, meta: {panelId}}) {
            return merge(state, panelId, keyBy(payload, 'id'))
        },
        [actions.disconnectInteractiveUser](state, {payload: id, meta: {panelId}}) {
            return merge(state, [panelId, id], {
                ...emptyPanelInteractiveUser,
                ...cacheFields.createCachedFields(state[panelId][id]),
            })
        },
        [actions.revertDisconnectInteractiveUser](state, {payload: id, meta: {panelId}}) {
            return merge(state, [panelId, id], {
                ...cacheFields.revertFromCache(state[panelId][id]),
            })
        },

        [actions.enable](state, {payload: interactiveUserId, meta: {panelId}}) {
            return merge(state, [panelId, interactiveUserId], {
                _status: state[panelId][interactiveUserId].status,
                status: STATUS_VERIFIED,
            })
        },

        [actions.suspend](state, {payload: interactiveUserId, meta: {panelId}}) {
            return merge(state, [panelId, interactiveUserId], {
                _status: state[panelId][interactiveUserId].status,
                status: STATUS_SUSPENDED,
            })
        },

        [actions.revert](state, {payload: interactiveUserId, meta: {panelId}}) {
            return merge(state, [panelId, interactiveUserId], {
                status: state[panelId][interactiveUserId]._status,
            })
        },
    },
    state
)
