import React, {Component} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

import withProps from 'containers/withProps'
import {selectStateTypeNameById} from 'modules/stateTypes/store/selectors'

class StateTypeCell extends Component {
    static propTypes = {
        name: PropTypes.string,
        row: PropTypes.shape({
            isStateActive: PropTypes.bool,
        }),
    }

    get tooltip() {
        return this.props.name
    }

    render() {
        return (
            <OverflowTextTooltip className="evenRules-elements-name">
                {this.tooltip}
            </OverflowTextTooltip>
        )
    }
}

export default compose(
    withProps(({row: {stateId}}) => ({
        id: stateId,
    })),
    connect(selectStateTypeNameById)
)(StateTypeCell)
