import React from 'react'
import Account from './Field/Account'

import Group from './Field/Group'
import Serial from './Field/Serial'

import {__} from 'utils/i18n'

export class Fieldset extends React.Component {
    render() {
        const {isNew, data} = this.props

        return (
            <div className="form-field">
                <Serial isNew={isNew} serial={data.serial} label={__('IMEI')} />
                <Account isNew={isNew} account={data.account} />
                <Group groupName={data.group} groupId={data.groupId} />
            </div>
        )
    }
}

export const rules = {
    groupId: {
        presence: true,
    },
    serial: {
        presence: true,
        format: {
            pattern: '[0-9]{15}',
            flags: 'i',
            message: () => __('Panel ID must be 15 decimals digits'),
        },
    },
    account: {
        presence: true,
        format: {
            pattern: '[a-f0-9]{6}',
            flags: 'i',
            message: () => __('Must be a 6 digit hexadecimal'),
        },
    },
}
