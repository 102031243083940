import React, {Component} from 'react'
import Device from 'components/Devices/Device'

export default class ParentTab extends Component {
    render() {
        const {device, onSelect} = this.props

        if (!device) {
            return null
        }

        return (
            <div className="devicesList">
                <Device device={device} onClick={onSelect} />
            </div>
        )
    }
}
