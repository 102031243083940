import {handleActions} from 'redux-actions'
import moment from 'moment'
import {set, remove} from 'immutable-modify'
import uniqid from 'uniqid'

import {
    update,
    disable,
    enable,
    send,
    message,
    cleanLog,
    cleanKey,
    disconnect,
    isEnabled,
    authorize,
    soundMessage,
} from './actions'
import {convertKeyToName} from 'utils/keypad/keys'
import {MESSAGE_TYPE_KEY, MESSAGE_TYPE_POS, MESSAGE_TYPE_LEDS} from 'utils/keypad/logger'

/**
 * state chime
 * {
 *      [panelId]: {
 *          enabled: bool
 *          isConnectiong: bool
 *          isLoading: bool
 *          enabledBy: string
 *          log: [{
 *              time: Object
 *              text: string
 *              type: string
 *          }]
 *          sound: {
 *              type: string
 *              value: string
 *              id: string
 *          }
 *      }
 * }
 */
export default handleActions(
    {
        [authorize](state, {payload: panelId}) {
            return {
                ...state,
                [panelId]: {
                    ...state[panelId],
                    enabled: true,
                    isConnecting: false,
                },
            }
        },

        [update](state, {payload, meta}) {
            const {panelId} = meta

            return {
                ...state,
                [panelId]: {
                    ...state[panelId],
                    ...payload,
                },
            }
        },

        [isEnabled](state, {payload: {panelId, isenabled, name}}) {
            return {
                ...state,
                [panelId]: {
                    ...state[panelId],
                    isLoading: false,
                    enabledBy: isenabled ? name : null,
                },
            }
        },

        [disconnect](state, {payload: panelId}) {
            return {
                ...state,
                [panelId]: {
                    ...state[panelId],
                    isConnecting: false,
                },
            }
        },

        [disable](state, {payload: panelId}) {
            return {
                ...state,
                [panelId]: {
                    ...state[panelId],
                    enabled: false,
                    isConnecting: false,
                },
            }
        },

        [enable](state, {payload: panelId}) {
            return {
                ...state,
                [panelId]: {
                    ...state[panelId],
                    isConnecting: true,
                    enabledBy: null,
                },
            }
        },

        [send](state, {payload: {key, longPress}, meta}) {
            const {panelId} = meta

            const log = [
                {
                    time: moment(),
                    text: `${convertKeyToName(key)}${longPress ? ', longpress' : ''}`,
                    type: MESSAGE_TYPE_KEY,
                },
                ...(state[panelId].log || []),
            ]

            return {
                ...state,
                [panelId]: {
                    ...state[panelId],
                    log,
                },
            }
        },

        [message](state, {payload: {type, data, log, ...rest}, meta: {panelId}}) {
            const newState = {
                ...state[panelId],
            }

            switch (type) {
                // 'key' reserved name in React Component
                case MESSAGE_TYPE_KEY:
                    newState.keyCode = data
                    break
                case MESSAGE_TYPE_POS:
                    newState[type] = rest
                    break
                case MESSAGE_TYPE_LEDS:
                    newState[type] = {
                        ...newState[type],
                        ...data,
                    }
                    break
                default:
                    newState[type] = data
                    break
            }

            if (log) {
                newState.log = [
                    {
                        time: moment(),
                        text: type === MESSAGE_TYPE_KEY ? convertKeyToName(data) : data,
                        type,
                    },
                    ...(state[panelId].log || []),
                ]
            }

            return {
                ...state,
                [panelId]: newState,
            }
        },

        [soundMessage](state, {payload: {type, data, duration}, meta: {panelId}}) {
            return set(state, [panelId, 'sound'], {
                type,
                value: data,
                duration: duration * 1000, // covert to milliseconds
                id: uniqid(),
            })
        },

        [cleanLog](state, {payload: panelId}) {
            return set(state, [panelId, 'log'], [])
        },

        [cleanKey](state, {payload: panelId}) {
            return remove(state, [panelId, 'keyCode'])
        },
    },
    {}
)
