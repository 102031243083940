import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import {ModalCardFormWithCustomActions} from 'ui/ModalCardForm'
import EventRuleFieldset from './EventRuleFieldset'
import EventRuleStepPagination from './Fieldset/EventRuleStepPagination'

import {
    EVENT_RULE_MASTER_STEP_SAVE,
    EVENT_RULE_MASTER_STEP_ZONE_TYPE,
    EVENT_TYPE_NAME,
    EVENT_TYPE_NAME_RESTORE,
    EventRuleStep,
    getEvenRuleFormValidationRules,
    NOTIFICATION_TYPE_NAME,
    STATE_TYPE_NAME,
    ZONE_TYPE_NAME,
} from 'constants/eventRules'
import withProps from 'containers/withProps'
import {EventRuleFormContext} from 'modals/EventRule/EventRuleFormContext'

export default class EventRuleForm extends PureComponent {
    static propTypes = {
        handle: PropTypes.func,
        header: PropTypes.string,
        data: PropTypes.shape({
            id: PropTypes.number,
            notificationId: PropTypes.number,
            zoneTypeId: PropTypes.number,
            isRestore: PropTypes.bool,
            stateId: PropTypes.number,
            eventId: PropTypes.number,
        }),
    }

    isEditEventRule = () => !!this.props.data

    allDataResolve = () =>
        this.isEditEventRule()
            ? {
                  ...this.props.data,
                  [EVENT_TYPE_NAME_RESTORE]: this.props.data[EVENT_TYPE_NAME_RESTORE]
                      ? 'on'
                      : null,
              }
            : {
                  [ZONE_TYPE_NAME]: null,
                  [NOTIFICATION_TYPE_NAME]: null,
                  [STATE_TYPE_NAME]: null,
                  [EVENT_TYPE_NAME]: null,
                  [EVENT_TYPE_NAME_RESTORE]: null,
              }

    state = {
        currentStep: this.isEditEventRule()
            ? new EventRuleStep(EVENT_RULE_MASTER_STEP_SAVE)
            : new EventRuleStep(EVENT_RULE_MASTER_STEP_ZONE_TYPE),
        allData: this.allDataResolve(),
    }

    onNext = () => {
        this.setState({currentStep: new EventRuleStep(this.state.currentStep.nextStep)})
    }

    onPrev = () => {
        this.setState({currentStep: new EventRuleStep(this.state.currentStep.prevStep)})
    }

    onSubmit = (data) => {
        const {handle} = this.props

        if (this.state.currentStep.isLastStep) {
            this.setState({
                allData: data,
            })
            handle(data)
        } else {
            this.setState({
                allData: {
                    ...this.state.allData,
                    ...data,
                },
                currentStep: new EventRuleStep(this.state.currentStep.nextStep),
            })
        }
    }

    get rules() {
        return getEvenRuleFormValidationRules(this.state.currentStep.currentStep)
    }

    render() {
        const {header, ...props} = this.props
        const {currentStep} = this.state

        return (
            <ModalCardFormWithCustomActions
                wide
                confirmOnDismiss
                onSubmit={this.onSubmit}
                header={header}
                CustomActions={withProps({
                    currentStep,
                    onPrev: this.onPrev,
                    onNext: this.onNext,
                })(EventRuleStepPagination)}
                {...props}
                rules={this.rules}
            >
                <EventRuleFormContext.Provider value={{allData: this.state.allData}}>
                    <EventRuleFieldset currentStep={currentStep} {...props} />
                </EventRuleFormContext.Provider>
            </ModalCardFormWithCustomActions>
        )
    }
}
