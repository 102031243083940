import {createAction} from 'redux-actions'

export const reset = createAction(
    'PANELS/REPORTS/EXPECTED/RESET',
    null,
    (_, panelId) => ({panelId})
)
export const fetch = createAction(
    'PANELS/REPORTS/EXPECTED/FETCH',
    null,
    (_, panelId) => ({panelId})
)
export const receive = createAction(
    'PANELS/REPORTS/EXPECTED/RECEIVE',
    null,
    (_, panelId) => ({panelId})
)
