import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import Ava from 'ipmp-react-ui/Ava'
import {__} from 'utils/i18n'
import Menu, {MenuItem} from 'ipmp-react-ui/Menu'
import {ReactComponent as IconPassword} from 'ipmp-react-ui/icons/password.svg'
import {ReactComponent as IconProfile} from 'ipmp-react-ui/icons/user.svg'
import {ReactComponent as IconLogout} from 'ipmp-react-ui/icons/logout.svg'
import {ReactComponent as IconHelp} from 'ipmp-react-ui/icons/help.svg'
import {ReactComponent as IconSettings} from 'ipmp-react-ui/icons/settings.svg'
import DropDown, {ALIGN_RIGHT, ALIGN_TOP, ALIGN_LEFT} from 'ipmp-react-ui/DropDown'
import isRtl from 'ipmp-react-ui/_utils/isRtl'

export default class UserInfo extends PureComponent {
    static propTypes = {
        showEditProfileInfoModal: PropTypes.func,
        showChangePasswordModal: PropTypes.func,
        showEditSettingsModal: PropTypes.func,
        logout: PropTypes.func,
        user: PropTypes.object,
    }

    handleChangePasswordClick = () => {
        this.props.showChangePasswordModal && this.props.showChangePasswordModal()
    }

    handleHelpClick = () => {
        window.open('http://help.tycomonitor.com/4.10/', '_blank')
    }

    handleProfileEdit = () => {
        this.props.showEditProfileInfoModal && this.props.showEditProfileInfoModal()
    }

    handleSettingsEdit = () => {
        this.props.showEditSettingsModal && this.props.showEditSettingsModal()
    }

    render() {
        const {user, logout} = this.props
        const trigger = <Ava name={user.name} id={user.id} />

        return (
            <DropDown
                trigger={trigger}
                align={(isRtl() ? ALIGN_LEFT : ALIGN_RIGHT) | ALIGN_TOP}
                className="userInfo"
            >
                <div className="card">
                    <div className="userInfo-user">
                        <Ava
                            className="userInfo-ava"
                            large
                            name={user.name}
                            id={user.id}
                        />

                        <div>
                            <h1 className="userInfo-name">{user.name}</h1>
                            <p className="userInfo-details">{user.email}</p>
                            <p className="userInfo-details">{user.countryName}</p>
                            <p className="userInfo-details">{user.phone}</p>
                        </div>
                    </div>

                    <Menu className="userInfo-menu">
                        <MenuItem Icon={IconSettings} onClick={this.handleSettingsEdit}>
                            {__('Settings')}
                        </MenuItem>
                        <MenuItem Icon={IconProfile} onClick={this.handleProfileEdit}>
                            {__('Edit Profile')}
                        </MenuItem>
                        <MenuItem
                            Icon={IconPassword}
                            onClick={this.handleChangePasswordClick}
                        >
                            {__('Change Password')}
                        </MenuItem>
                        <MenuItem Icon={IconHelp} onClick={this.handleHelpClick}>
                            {__('Help')}
                        </MenuItem>
                        <MenuItem Icon={IconLogout} onClick={logout}>
                            {__('Logout')}
                        </MenuItem>
                    </Menu>
                </div>
            </DropDown>
        )
    }
}
