import {createAction} from 'redux-actions'
import toArray from 'utils/toArray'

export const update = createAction('PANELS/STORE/UPDATE', toArray)
export const clear = createAction('PANELS/STORE/CLEAR')
export const setActivationStatus = createAction('PANELS/STORE/SET_ACTIVATION_STATUS')
export const setActivationFailed = createAction('PANELS/STORE/SET_ACTIVATION_FAILED')
export const setActivationSuccess = createAction('PANELS/STORE/SET_ACTIVATION_SUCCESS')
export const refreshState = createAction('PANELS/LIST/REFRESH_STATE', toArray)
export const refresh = createAction('PANELS/STORE/REFRESH', toArray)
export const setRefreshing = createAction(
    'PANELS/STORE/SET_REFRESH',
    (isRefreshing = true) => ({isRefreshing})
)
export const pushBasicConfiguration = createAction(
    'PANELS/STORE/PUSH',
    (basicConfigId, panelIds) => ({basicConfigId, panelIds})
)
export const discovery = createAction('PANELS/STORE/DISCOVERY', (panelIds, timeout) => ({
    timeout,
    panelIds: toArray(panelIds),
}))

export const setEncryptionState = createAction(
    'PANELS/STORE/SET_ENCRYPTION_STATE',
    (PanelId, channel, encrypted) => ({id: PanelId, channel, encrypted})
)

export const readDiagnostic = createAction('PANELS/STORE/READ_DIAGNOSTIC', (panelId) => ({
    panelId,
}))
export const purge = createAction('PANELS/STORE/PURGE')
