import React from 'react'
import {Device} from './Device'
import Checkbox from 'ipmp-react-ui/Checkbox'
import {__} from 'utils/i18n'

export default class EventDevice extends Device {
    onBypassChange = (e) => {
        this.props.setBypass(this.props.device.id, e.target.checked)
    }

    renderBottom(device) {
        return (
            <div>
                {this.renderPorts()}
                {this.renderBypass()}
            </div>
        )
    }

    renderBypass() {
        const {traits} = this.props.device || {}
        const bypass = traits && traits.bypass

        if (!bypass) {
            return null
        }

        return (
            <Checkbox
                onChange={this.onBypassChange}
                label={__('Bypass')}
                progress={bypass.progress}
                checked={bypass.enabled}
            />
        )
    }

    renderCameraIcon() {}
}
