import {call, put, select} from 'redux-saga/effects'
import {snackShow} from 'modules/snacks'

import {unassignInstallerFromPanel} from 'api/installers'

import * as panelInstallersActions from 'modules/panelInstallers/list/actions'
import {getPanelId} from 'utils/panelIdMatchSelector'
import {unassignInstallerSuccess} from 'modules/installerPanels/list/actions'

export function* watchUnassignInstaller({payload: installerId}) {
    try {
        const state = yield select((state) => state)
        const panelId = getPanelId(state)
        yield call(unassignInstallerFromPanel, {panelId, installerId})
        yield put(unassignInstallerSuccess({panelId, installerId}))
        yield put(panelInstallersActions.init())
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
