import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {selectPanelDevicesByType} from 'modules/devices/list/selectors'
import {showFirmwareUpgradeModal} from 'modules/modals/actions'
import {fetch, refresh as refreshRunner} from 'modules/panels/firmware/actions'
import {startPollRunnerData, stopPollRunnerData} from 'modules/runners/actions'
import __ from 'utils/i18n'
import withRunnerLoader from 'containers/withRunnerLoader'

import Firmware from 'components/Devices/Firmware'
import withLifeCycle from 'containers/withLifeCycle'

export default compose(
    connect(selectPanelDevicesByType, (dispatch, {panelId}) =>
        bindActionCreators(
            {
                handler: (pkg) => showFirmwareUpgradeModal({panelId, packages: [pkg]}),
                fetch,
                startPollRunnerData,
                stopPollRunnerData,
                refreshRunner,
            },
            dispatch
        )
    ),
    withLifeCycle({
        onMount({runner, startPollRunnerData, refreshRunner, panelId}) {
            if (runner) {
                startPollRunnerData(runner.id, (payload) =>
                    refreshRunner({...payload, panelId})
                )
            }
        },
        onUnmount({stopPollRunnerData, runner}) {
            if (runner) {
                stopPollRunnerData(runner.id)
            }
        },
        onUpdate(
            {runner, startPollRunnerData, stopPollRunnerData, refreshRunner, panelId},
            prevProps
        ) {
            if (runner && !prevProps.runner) {
                startPollRunnerData(runner.id, (payload) =>
                    refreshRunner({...payload, panelId})
                )
            }
            if (!runner && prevProps.runner) {
                stopPollRunnerData(prevProps.runner.id)
            }
        },
    }),
    withRunnerLoader(
        () => __('Upgrading device software…'),
        ({fetch, panelId}) => fetch(panelId)
    )
)(Firmware)
