import React from 'react'
import {connect} from 'react-redux'

import Select, {Option} from 'ipmp-react-ui/Select'
import Button from 'ipmp-react-ui/Button'
import Bar, {RIGHT} from 'ipmp-react-ui/Bar'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'
import Portal from 'ipmp-react-ui/Portal'

import Disclaimer from 'ui/Disclaimer'
import __ from 'utils/i18n'

import AddNotificationType from 'modals/NotificationType/AddNotificationType'
import ContentTemplate from 'modals/EventRule/Fieldset/Content/ContentTemplate'
import {selectAllNotificationTypes} from 'modules/notificationTypes/store/selectors'
import get from 'lodash-es/get'

class NotificationType extends ContentTemplate {
    renderModal = () => (
        <Portal>
            <AddNotificationType hide={this.onHideModal} />
        </Portal>
    )

    render() {
        const {data} = this.props
        const {isModalOpened} = this.state

        return (
            <div className="eventRules-master-content">
                <Disclaimer className="eventRules-master-content-disclaimer">
                    {__(
                        'Notification - Lorem ipsum, or lipsum as it is sometimes known, is dummy text usedin laying out print, graphic or web designs.'
                    )}
                </Disclaimer>
                <Select
                    name={this.selectName}
                    defaultValue={this.value}
                    defaultLabel={this.label}
                    label={__('Notification')}
                >
                    {data &&
                        data.length &&
                        data.map(({id, name}) => (
                            <Option key={id} label={name} value={id} />
                        ))}
                </Select>
                <Bar orientation={RIGHT} className="eventRules-master-content-bar">
                    <Button
                        className="eventRules-master-content-addButton"
                        Icon={IconPlus}
                        borderless
                        onClick={this.onOpenModal}
                    >
                        {__('Add Notification')}
                    </Button>
                </Bar>
                {isModalOpened && this.renderModal()}
            </div>
        )
    }
}

export default connect((store) => ({
    ...selectAllNotificationTypes(store),
    isFormSuccess: get(store, ['forms', 'addNotificationType', 'success'], false),
}))(NotificationType)
