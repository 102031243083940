import React, {Fragment} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {withPermission, withRejection} from 'containers/withPermission'
import permission from 'permissions/settings/page'

import SettingsPageTabs from './SettingsPageTabs'
import SettingsPageContent from './SettingsPageContent'
import {getItemsByKeys, getMenuItems} from './SettingsPageRouting'

import Page from 'ipmp-react-ui/Page'

export class SettingsPage extends Page {
    static propTypes = {
        items: PropTypes.object,
    }

    state = {}

    renderTop() {
        return null
    }

    renderBar() {
        const {items} = this.props

        return <SettingsPageTabs menu={items} />
    }

    renderContent() {
        const {items} = this.props

        return (
            <Fragment>
                <div className="systemSettings">
                    <SettingsPageContent routes={items} />
                </div>
            </Fragment>
        )
    }
}

export default compose(
    withPermission({isAllowed: permission}),
    withRejection(),
    connect(() => {
        const itemKeys = getMenuItems()
        const items = getItemsByKeys(Object.keys(itemKeys))

        return {
            items,
        }
    })
)(SettingsPage)
