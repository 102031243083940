import React, {Component} from 'react'

import Layout, {ScrollView} from 'ipmp-react-ui/Layout'
import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import PanelInstallersTable from './PanelInstallersTable'

import {withPermission} from 'containers/withPermission'
import page from 'permissions/installers/page'
import {compose} from 'redux'
import AssignInstallerButton from './GridBar/AssignInstallerButton'

class PanelInstallers extends Component {
    render() {
        return (
            <Layout vertical className="panelPage-content">
                <Bar>
                    <BarSpace />
                    <AssignInstallerButton />
                </Bar>
                <ScrollView>
                    <PanelInstallersTable />
                </ScrollView>
            </Layout>
        )
    }
}

export default compose(withPermission({isAllowed: page}))(PanelInstallers)
