import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import Menu, {MenuLink} from 'ipmp-react-ui/Menu'
import NavTabs, {NavTabLink, NavTabPopup} from 'ipmp-react-ui/NavTabs'
import Bar from 'ipmp-react-ui/Bar'

import path from 'utils/path'
import {__} from 'utils/i18n'

class SettingsPageTabs extends Component {
    static propTypes = {
        menu: PropTypes.object,
    }

    static defaultProps = {
        menu: {},
    }

    state = {
        items: Infinity,
    }

    renderTabs = (key) => {
        const {
            match: {params},
            menu,
        } = this.props
        const {label, path: pathName, exact} = menu[key]

        return (
            <NavTabLink key={key} to={path(pathName, params)} exact={exact}>
                {label()}
            </NavTabLink>
        )
    }

    render() {
        const {
            match: {params},
            menu,
        } = this.props
        const {items} = this.state

        const directLinks = Object.keys(menu).slice(0, items)
        const popupLinks = Object.keys(menu).slice(items)

        return (
            <Bar className="systemSettings-bar">
                <NavTabs onRef={this.handleRef}>
                    {directLinks.map(this.renderTabs)}

                    {popupLinks.length > 0 && (
                        <NavTabPopup label={__('Other')}>
                            <Menu>
                                {popupLinks.map((key) => {
                                    const {label, path: pathName} = menu[key]

                                    return (
                                        <MenuLink key={key} to={path(pathName, params)}>
                                            {label()}
                                        </MenuLink>
                                    )
                                })}
                            </Menu>
                        </NavTabPopup>
                    )}
                </NavTabs>
            </Bar>
        )
    }
}

export default compose(withRouter)(SettingsPageTabs)
