import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {setStart} from 'modules/runners/list/actions'

import withRunners from 'containers/withRunners'

import Pager from 'ipmp-react-ui/Pager'

export default compose(
    withRunners(),
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                setStart,
                onPageChange: setStart,
            },
            dispatch
        )
    )
)(Pager)
