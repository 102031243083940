import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import __ from 'utils/i18n'

import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'
import Button from 'ipmp-react-ui/Button'
import withProps from 'containers/withProps'
import withConfirmation from 'containers/withConfirmation'

import {remove} from 'modules/eventTypes/list/actions'

export default compose(
    connect(null, (dispatcher, {row: {id}}) =>
        bindActionCreators(
            {
                onRemove: () => remove([id]),
            },
            dispatcher
        )
    ),
    withProps(({onRemove, row: {name}}) => ({
        onClick: onRemove,
        small: true,
        flat: true,
        Icon: IconRemove,
        message: __(
            'If you delete this event type, then the event rule in which it is used will also be deleted, if it exists. Do you really want to remove %s event type?',
            name
        ),
        title: __('Remove Event Type'),
    })),
    withConfirmation()
)(Button)
