import withForm from 'containers/withForm'
import ZoneTypeForm from './ZoneTypeForm'
import {validation} from './ZoneTypeFieldset'
import {addZoneType} from 'modules/forms/handlers'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {__} from 'utils/i18n'

export default compose(
    connect(() => ({
        header: __('Add Zone Type'),
        rules: validation,
    })),
    withForm(addZoneType)
)(ZoneTypeForm)
