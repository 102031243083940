// You should set on NEO-emulator version SWU = 1.30.01.20
export const DEFAULT_VIDEO_FORMAT = 'webm'

export const REQUEST_ALLOWED_TYPE_ANY = 'ANY' // Vod can be requested anytime (for some old Pmax panels for which we cannot request VOD settings)
export const REQUEST_ALLOWED_TYPE_BY_EVENT = 'BY_EVENT' // can be requested if there is an event with video for that cam (true for Fibro transmitters)
export const REQUEST_ALLOWED_TYPE_WITH_TIME_WINDOW = 'WITH_TIME_WINDOW' // Pmax & Neo/PSP panels - if we can read VOD settings for them.
export const REQUEST_ALLOWED_TYPE_CONDITIONAL = 'CONDITIONAL' // PSP 1.3 - if all the requirements are satisfied then VOD can be requested at any time.

//we should check it only when features set contain CONDITIONAL ALLOWED TYPE
export const TRAIT_VOD_CONDITION_ALLOWED = 'ALLOWED' //allowed for itv2
export const TRAIT_VOD_CONDITION_VOD_DISABLED = 'VOD_DISABLED' //disabled for itv2, we should check time
export const TRAIT_VOD_CONDITION_NOT_ALARM_CONDITION = 'NOT_ALARM_CONDITION'
export const TRAIT_VOD_CONDITION_NOT_AWAY_CONDITION = 'NOT_AWAY_CONDITION'
