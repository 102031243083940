import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {sendEmail} from 'modules/remoteInspections/results/actions'
import {
    cancel,
    initiateNow,
    initiateNowBatch,
} from 'modules/remoteInspections/store/actions'
import {selectRemoteInspectionsListRows} from 'modules/remoteInspections/list/selectors'
import {
    init,
    reset,
    fetch,
    setStart,
    markAsViewed,
} from 'modules/remoteInspections/list/actions'
import {
    showRemoteInspectionValuesModal,
    showScheduleRri,
    showScheduleRriByCsvModal,
} from 'modules/modals/actions'

export default function withRemoteInspections() {
    return compose(
        withPerPage('remoteInspections'),
        connect(
            (state) => {
                const {isLoading, error, start, total} = state.remoteInspections.list
                const rows = selectRemoteInspectionsListRows(state)
                const disabledRowsIds = rows
                    .filter(({isSupported}) => !isSupported)
                    .map(({id}) => id)

                return {
                    start,
                    total,
                    isLoading,
                    error,
                    rows,
                    disabledRowsIds,
                }
            },
            (dispatch) =>
                bindActionCreators(
                    {
                        init,
                        reset,
                        fetch,
                        onPageChange: setStart,
                        markAsViewed,
                        showScheduleRri,
                        showScheduleRriByCsvModal,
                        showRemoteInspectionValuesModal,
                        initiateNow,
                        cancel,
                        sendEmail,
                        // Workaround for use runners
                        initiateNowBatch,
                    },
                    dispatch
                )
        )
    )
}
