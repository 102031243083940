import {compose} from 'redux'

import {create} from 'permissions/reports/bar'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withReports from 'containers/withReports'
import withProps from 'containers/withProps'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as PlusIcon} from 'ipmp-react-ui/icons/plus.svg'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: create}),
    withVisibility(),
    withReports(),
    withProps(({showCreateReportModal}) => ({
        onClick: () => showCreateReportModal(null),
        Icon: PlusIcon,
        label: __('Add report'),
    }))
)(Button)
