import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {hide} from 'modules/modals/actions'

import Modal from 'ipmp-react-ui/Modal'
import CardModalError from 'ipmp-react-ui/CardModalError'
import {__} from 'utils/i18n'

const NoPanelUsersError = ({hide}) => (
    <Modal onClose={hide}>
        <CardModalError
            onClose={hide}
            title={__('No Panel Users')}
            message={__('All users were assigned')}
        />
    </Modal>
)

NoPanelUsersError.propTypes = {
    hide: PropTypes.func.isRequired,
}

export default connect(null, (dispatch) => bindActionCreators({hide}, dispatch))(
    NoPanelUsersError
)
