import {compose} from 'redux'

import {run} from 'permissions/panel/devices/walktest'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withDevices from 'containers/withDevices'
import withProps from 'containers/withProps'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: run}),
    withVisibility(),
    withDevices(),
    withProps(({startWalktest}) => ({
        onClick: startWalktest,
        label: __('Start Walktest'),
    }))
)(Button)
