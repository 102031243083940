import {all} from 'redux-saga/effects'

import list from './list/saga'
import one from './one/saga'
import store from './store/saga'
import panelState from './state/saga'
import keypad from './keypad/saga'
import locations from './locations/saga'
import logs from './logs/saga'
import plink from './plink/saga'
import remarks from './remarks/saga'
import remoteInspections from './remoteInspections/saga'
import processes from './processes/saga'
import firmware from './firmware/saga'
import reports from './reports/saga'
import events from './events/saga'
import configuration from './configuration/saga'
import recent from './recent/saga'
import panelUsers from './panelUsers/saga'

export default function* () {
    yield all([
        store(),
        one(),
        list(),
        remoteInspections(),
        panelState(),
        keypad(),
        remarks(),
        locations(),
        logs(),
        plink(),
        processes(),
        firmware(),
        reports(),
        events(),
        configuration(),
        recent(),
        panelUsers(),
    ])
}
