import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconMinus} from 'ipmp-react-ui/icons/minus.svg'

import {disconnectInteractiveUser} from 'modules/panelInteractiveUsers/store/actions'
import {withRouterPanelId} from 'containers/withPanel'
import withConfirmation from 'containers/withConfirmation'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'
import __ from 'utils/i18n'

export default compose(
    withRouterPanelId(),
    connect(null, (dispatcher, {row: {id}, panelId}) =>
        bindActionCreators(
            {
                onDisconnect: () => disconnectInteractiveUser(id, panelId),
            },
            dispatcher
        )
    ),
    withProps(({row: {isAssign, label}, onDisconnect}) => ({
        isVisible: isAssign,
        small: true,
        flat: true,
        Icon: IconMinus,
        onClick: onDisconnect,
        message: __('Do you really want to disconnect %s?', label),
        title: __('Disconnect panel'),
    })),
    withVisibility(),
    withConfirmation()
)(Button)
