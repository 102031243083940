import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {NavBlindList} from 'components/Nav/NavBlind'
import {selectRecentHistory} from 'modules/panels/recent/selectors'
import {fetchPanelsInteractiveUser} from 'modules/panels/recent/actions'
import withLoader from 'containers/withLoader'
import {withPermission} from 'containers/withPermission'
import recentHistoryAssignedUnitUsersList from 'permissions/interactiveUsers/recentHistoryAssignedUnitUsersList'

export default compose(
    withPermission({recentHistoryAssignedUnitUsersList}),
    connect(selectRecentHistory, (dispatcher) =>
        bindActionCreators(
            {
                fetchPanelsInteractiveUser,
            },
            dispatcher
        )
    ),
    withLoader(({fetchPanelsInteractiveUser, recentHistoryAssignedUnitUsersList}) => {
        recentHistoryAssignedUnitUsersList && fetchPanelsInteractiveUser()
    })
)(NavBlindList)
