import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Definition from 'ipmp-react-ui/Definition'
import DateTime from 'ipmp-react-ui/DateTime'

import {ReactComponent as IconCalendar} from 'ipmp-react-ui/icons/calendar.svg'
import {ReactComponent as IconStop} from 'ipmp-react-ui/icons/stop.svg'
import {ReactComponent as IconPause} from 'ipmp-react-ui/icons/pause.svg'

import {__} from 'utils/i18n'

export default class StateReportCell extends Component {
    static propTypes = {
        row: PropTypes.shape({
            isFinished: PropTypes.bool,
            isActive: PropTypes.bool,
            next: PropTypes.instanceOf(Date),
        }),
    }

    render() {
        const {
            row: {isFinished, isActive, next},
        } = this.props

        switch (true) {
            case isFinished:
                return (
                    <Definition
                        className="empty"
                        Icon={IconStop}
                        title={__('Finished')}
                    />
                )
            case !isActive:
                return (
                    <Definition
                        className="empty"
                        Icon={IconPause}
                        title={__('Stopped')}
                    />
                )
            default:
                return <DateTime Icon={IconCalendar} datetime={next} />
        }
    }
}
