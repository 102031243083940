import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'

import {hide} from 'modules/modals/actions'

import Modal from 'ipmp-react-ui/Modal'
import CardModalError from 'ipmp-react-ui/CardModalError'

import {__n, __} from 'utils/i18n'

class AddCentralStationLimitExceeded extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func,
        maxCentralStations: PropTypes.number,
        hide: PropTypes.func.isRequired,
    }

    render() {
        const {maxCentralStations, hide} = this.props

        return (
            <Modal onClose={hide}>
                <CardModalError
                    onClose={hide}
                    title={__('Limit exceeded')}
                    message={__n(
                        'You cannot add more than %d central station',
                        'You cannot add more than %d central stations',
                        maxCentralStations
                    )}
                />
            </Modal>
        )
    }
}

export default connect(null, (dispatch) => bindActionCreators({hide}, dispatch))(
    AddCentralStationLimitExceeded
)
