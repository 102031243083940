import isEmpty from 'lodash-es/isEmpty'
import mapper from '../utils/dataMapper'
import * as DeviceTypes from '../constants/deviceType'

export const mapPreloadEventVideo = ({time, location, description, ...currentData}) => {
    return {
        time: time && time,
        location: !isEmpty(location) ? location : description,
        isClosed: currentData.is_closed,
        ...currentData,
    }
}

export const mapLiveEventVideos = (camera) => {
    const {
        current: {
            process: currProcess,
            is_closed: currIsClosed,
            time: currTime,
            video: currVideo,
            ...currData
        },
        history,
        ...data
    } = camera
    return {
        current: {
            process: currProcess && {token: currProcess, isRunning: !currIsClosed},
            time: currTime && currTime,
            isClosed: currIsClosed,
            video: Array.isArray(currVideo) ? {} : currVideo,
            ...currData,
        },
        history: history.map(
            ({process, is_closed: currIsClosed, time, video, ...data}) => {
                return {
                    process: process && {token: process, isRunning: !currIsClosed},
                    time: time && time,
                    isClosed: currIsClosed,
                    video: Array.isArray(video) ? {} : video,
                    ...data,
                }
            }
        ),
        ...data,
    }
}

export const mapEvent = mapper((data) => {
    const zone =
        data.evt_zone_id && parseInt(data.evt_zone_id) !== 0
            ? parseInt(data.evt_zone_id)
            : null
    const deviceType = data.utd_name
    const hasPanel = deviceType === DeviceTypes.DEVICE_TYPE_CONTROL_PANEL
    const hasDevice =
        zone &&
        deviceType !== DeviceTypes.DEVICE_TYPE_CONTROL_PANEL &&
        Object.values(DeviceTypes).includes(deviceType)

    return {
        id: parseInt(data.evt_id),
        panelId: parseInt(data.unt_id),
        severity: data._etp_name,
        serial: data.unt_serial,
        account: data.unt_account,
        description: data.evt_description,
        datetime: data.evt_created_timestamp,
        changed: data.evt_changed_timestamp,
        binMessage: data.evt_bin_message,
        appointment: data.evt_appointment,
        path: data.evt_path,
        hasVideo: data.evt_camera_count && parseInt(data.evt_camera_count) !== 0,
        isViewed:
            data.evt_reconsideration_condition &&
            data.evt_reconsideration_condition === 'yes',
        deviceTypeId: parseInt(data.utd_id),
        deviceType,
        zone,
        hasPanel,
        hasDevice,
        partitions: data.evt_partition_bitmask
            ? data.evt_partition_bitmask.split(',')
            : [],
    }
})

export const keysMap = {
    id: 'evt_id',
    panelId: 'unt_id',
    serial: 'unt_serial',
    account: 'unt_account',
    severity: '_etp_name',
    description: 'evt_description',
    isViewed: 'evt_reconsideration_condition',
    zone: 'evt_zone_id',
    binMessage: 'evt_bin_message',
    datetime: 'evt_created_timestamp',
    changed: 'evt_changed_timestamp',
    appointment: 'evt_appointment',
    path: 'evt_path',
    bitmask: 'evt_partition_bitmask',
    camera: 'evt_camera_count',
}
