import {createSelector} from 'reselect'
import {selectProtocols} from 'modules/centralStations/protocols/selectors'
import {selectSerialPorts} from 'modules/centralStations/serialPorts/selectors'
import {selectLicensing} from 'modules/centralStations/licensing/selectors'

export const selectAddCentralStation = createSelector(
    selectProtocols,
    selectSerialPorts,
    selectLicensing,
    (protocols, serialPorts, licensing) => ({
        isLoading: protocols.isLoading || serialPorts.isLoading || licensing.isLoading,
        serialPorts: serialPorts.data,
        protocols: protocols.data,
        creatingAllowed: licensing.add,
        maxCentralStations: licensing.max,
    })
)

export const selectEditCentralStation = createSelector(
    selectProtocols,
    selectSerialPorts,
    (state, {centralStationId}) => state.centralStations.store.byId[centralStationId],
    (protocols, serialPorts, data) => ({
        serialPorts: serialPorts.data,
        protocols: protocols.data,
        isLoading: protocols.isLoading || serialPorts.isLoading,
        data,
    })
)
