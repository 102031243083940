import {all, call, put, takeEvery, select} from 'redux-saga/effects'
import {snackShow} from 'modules/snacks'

import generateBatch from 'modules/batches/manager/generateBatch'
import * as actions from './actions'
import * as list from 'modules/upgradeStatus/list/actions'
import * as api from 'api/runners'
import {PROCESS_TYPE_NEO_UPGRADE} from 'constants/processTypes'

export default function* () {
    yield all([takeEvery(actions.retry, watchRetry)])
}

function* watchRetry({payload: runnerIds}) {
    try {
        const runnersByIds = yield select((state) => state.runners.store.byIds)
        const {panelIds} = runnerIds.reduce(
            (acc, id) => {
                acc.processIds.push(runnersByIds[id].processId)
                acc.panelIds.push(runnersByIds[id].panelId)
                return acc
            },
            {processIds: [], panelIds: []}
        )
        const {batchId} = yield generateBatch(PROCESS_TYPE_NEO_UPGRADE, panelIds)

        yield call(api.retry, {runnerIds, batchId})

        yield put(list.invalidate())
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
