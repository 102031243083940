import {handleActions} from 'redux-actions'
import has from 'lodash-es/has'

import * as actions from './actions'

export default handleActions(
    {
        [actions.setLoading](state, {payload: {panelId, unitZoneId}}) {
            return {
                ...state,
                [panelId]: {
                    ...state[panelId],
                    [unitZoneId]: {
                        isLoading: true,
                    },
                },
            }
        },

        [actions.receive](state, {payload, error, meta: {panelId, unitZoneId}}) {
            if (error) {
                return {
                    ...state,
                    [panelId]: {
                        ...state[panelId],
                        [unitZoneId]: {
                            isLoading: false,
                            error: payload,
                        },
                    },
                }
            }

            return {
                ...state,
                [panelId]: {
                    ...state[panelId],
                    [unitZoneId]: {
                        isLoading: false,
                        error: null,
                        ...payload,
                    },
                },
            }
        },

        [actions.erase](state, {payload: {panelId, unitZoneId}}) {
            if (has(state, panelId)) {
                const panel = Object.assign({}, state[panelId])

                if (has(panel, unitZoneId)) {
                    delete panel[unitZoneId]

                    return {
                        ...state,
                        [panelId]: {
                            ...panel,
                        },
                    }
                }
            }

            return state
        },
    },
    {}
)
