import {put, select} from 'redux-saga/effects'
import * as actions from '../actions'
import {SEVERITY_TROUBLE} from 'constants/severityType'
import {TROUBLE_TYPE_PANEL_MARK_FOR_SERVICE} from 'constants/troubleType'
import {DEVICE_TYPE_CONTROL_PANEL} from 'constants/deviceType'
import {sortFaults} from 'api/panels.map'

export default function* watchMarkForService({
    meta: {ids, comment, userId, user},
    payload: {result},
}) {
    const byIds = yield select((state) => state.panels.store.byIds)

    const fault = {
        severity: SEVERITY_TROUBLE,
        type: TROUBLE_TYPE_PANEL_MARK_FOR_SERVICE,
        deviceType: DEVICE_TYPE_CONTROL_PANEL,
        isMarkForService: true,
        isSuspended: false,
        zone: null,
        comment,
    }

    const panels = ids
        .map((id) => byIds[id])
        .filter((panel) => panel)
        .map(({id, faults}) => ({
            id,
            user,
            userId,
            faults: sortFaults(
                (faults || []).concat({
                    id: result[id],
                    ...fault,
                })
            ),
        }))

    if (panels.length > 0) {
        yield put(actions.update(panels))
    }
}
