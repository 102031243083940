import {compose} from 'redux'

import withLifeCycle from 'containers/withLifeCycle'
import {withPGMForm} from 'containers/withPGMForm'
import {withRouterPanelId} from 'containers/withPanel'

import PGMForm from 'components/PGM/PGMForm'

export default compose(
    withRouterPanelId(),
    withPGMForm(),
    withLifeCycle({
        onMount({fetchPGMList, fetchPGMTypes}) {
            fetchPGMList()
            fetchPGMTypes()
        },
    })
)(PGMForm)
