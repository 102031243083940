import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'
import Select, {Option} from 'ipmp-react-ui/Select'

import discoveryTimeoutTitle, {
    DISCOVERY_TIMEOUTS,
    DEFAULT_DISCOVERY_TIMEOUT,
} from 'constants/discovery'
import {discovery} from 'modules/panels/store/actions'

export class TriggerDiscovery extends Component {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        panelIds: PropTypes.arrayOf(PropTypes.number),
    }

    rules = {
        timeout: {
            presence: true,
        },
    }

    handle = ({timeout}) => {
        const {handle, hide} = this.props
        handle(parseInt(timeout))
        hide()
    }

    renderOption = (timeout) => {
        return (
            <Option
                key={timeout}
                value={timeout}
                label={discoveryTimeoutTitle(timeout)}
            />
        )
    }

    render() {
        return (
            <ModalCardForm
                onSubmit={this.handle}
                header={__('Trigger discovery')}
                rules={this.rules}
                submitLabel={__('Trigger')}
                {...this.props}
            >
                <Select
                    label={__('Timeout')}
                    name="timeout"
                    defaultValue={DEFAULT_DISCOVERY_TIMEOUT}
                >
                    {DISCOVERY_TIMEOUTS.map(this.renderOption)}
                </Select>
            </ModalCardForm>
        )
    }
}

export default compose(
    connect(null, (dispatch, {panelIds}) =>
        bindActionCreators(
            {
                handle: (timeout) => discovery(panelIds, timeout),
            },
            dispatch
        )
    )
)(TriggerDiscovery)
