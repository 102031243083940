import React, {Component} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import Card, {CardHeader, CardContent} from 'ipmp-react-ui/Card'
import Button from 'ipmp-react-ui/Button'
import Buttons from 'ipmp-react-ui/Buttons'
import Definition from 'ipmp-react-ui/Definition'
import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'
import DateTime from 'ipmp-react-ui/DateTime'

import __ from 'utils/i18n'

import InteractiveUserPanelsTable from './InteractiveUserPanelsTable'
import SuspendUserButton from './InteractiveUserInfoBar/SuspendUserButton'
import ResetPasswordButton from './InteractiveUserInfoBar/ResetPasswordButton'

import getInteractiveUserStatusTitle, {
    STATUS_VERIFIED,
    STATUS_SUSPENDED,
} from 'constants/interactiveUserStatus'
import withInteractiveUserPanelsTable from 'containers/withInteractiveUserPanelsTable'
import withLifeCycle from 'containers/withLifeCycle'
import withInteractiveUsers from 'containers/withInteractiveUsers'
import EnableUserButton from './InteractiveUserInfoBar/EnableUserButton'
import BlindModalDefinition, {BlindModalDefinitionItem} from 'ui/BlindModalDefinition'

class InteractiveUserBlindInfo extends Component {
    static propTypes = {
        interactiveUser: PropTypes.shape({
            id: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
                .isRequired,
        }),
        onAddPanel: PropTypes.func.isRequired,
        suspend: PropTypes.func.isRequired,
        resetPassword: PropTypes.func.isRequired,
    }

    onAddPanel = () => this.props.onAddPanel()

    renderStatusChange = () => {
        const {interactiveUser} = this.props

        switch (interactiveUser.status) {
            case STATUS_VERIFIED:
                return <SuspendUserButton interactiveUser={interactiveUser} />
            case STATUS_SUSPENDED:
                return <EnableUserButton interactiveUser={interactiveUser} />
            default:
                return null
        }
    }

    render() {
        const {interactiveUser} = this.props
        const status = getInteractiveUserStatusTitle(interactiveUser.status)

        return (
            <Card className="interactiveUserBlind">
                <CardHeader>
                    <Bar>
                        <strong>{interactiveUser.email}</strong>

                        <BarSpace />

                        <Buttons flat>
                            {this.renderStatusChange()}
                            <ResetPasswordButton interactiveUser={interactiveUser} />
                        </Buttons>
                    </Bar>
                </CardHeader>
                <CardContent>
                    <BlindModalDefinition>
                        <BlindModalDefinitionItem name={__('Registered')}>
                            <DateTime datetime={interactiveUser.createdAt} />
                        </BlindModalDefinitionItem>
                        <BlindModalDefinitionItem name={__('State')}>
                            <Definition title={status} />
                        </BlindModalDefinitionItem>
                    </BlindModalDefinition>
                    <InteractiveUserPanelsTable interactiveUser={interactiveUser} />

                    <Button
                        className="interactiveUserBlind-addPanel"
                        Icon={IconPlus}
                        flat
                        label={__('Add Panel')}
                        onClick={this.onAddPanel}
                    />
                </CardContent>
            </Card>
        )
    }
}

export default compose(
    withInteractiveUsers(),
    withInteractiveUserPanelsTable(),
    withLifeCycle({
        onUnmount({resetWithOutFetch}) {
            resetWithOutFetch()
        },
    })
)(InteractiveUserBlindInfo)
