import React from 'react'
import PropTypes from 'prop-types'

import {__} from 'utils/i18n'

import Input from 'ipmp-react-ui/Input'
import Textarea from 'ipmp-react-ui/Textarea'

export const validation = {
    email: {
        email: true,
        length: {maximum: 72},
    },
    name: {
        format: {
            pattern: /^[a-zA-Z 0-9_\-'.]+$/,
            message: () =>
                __(
                    'Allowed only digits, letters, spaces, dots, dashes, and single-quotes.'
                ),
        },
    },
    phone: {
        phone: true,
    },
    address: {
        length: {maximum: 200},
    },
    remark: {
        length: {
            maximum: 1000,
        },
    },
}

const CustomerFieldSet = ({data}) => {
    return (
        <div>
            <Input
                label={__('Name')}
                name="name"
                maxLength="32"
                defaultValue={data.name}
            />

            <Input
                label={__('Email')}
                name="email"
                maxLength="72"
                defaultValue={data.email}
            />

            <Input label={__('Phone')} name="phone" defaultValue={data.phone} />

            <Textarea
                label={__('Address')}
                name="address"
                maxLength="200"
                defaultValue={data.address}
            />

            <Textarea
                label={__('Remark')}
                name="remark"
                maxLength="1000"
                defaultValue={data.remark}
            />

            <Textarea
                label={__('Remark 2')}
                name="remark2"
                maxLength="1000"
                defaultValue={data.remark2}
            />

            <Textarea
                label={__('Remark 3')}
                name="remark3"
                maxLength="1000"
                defaultValue={data.remark3}
            />
        </div>
    )
}

CustomerFieldSet.propTypes = {
    data: PropTypes.object,
}

CustomerFieldSet.defaultProps = {
    data: {},
}

export default CustomerFieldSet
