export const LED_ON = 'LED_ON'
export const LED_OFF = 'LED_OFF'
export const LED_BLINK = 'LED_BLINK'
export const FLASH_SLOW = 'FLASH_SLOW'
export const FLASH_MEDIUM = 'FLASH_MEDIUM'
export const FLASH_FAST = 'FLASH_FAST'
export const ONE_WINK = 'ONE_WINK'
export const DOUBLE_WINK = 'DOUBLE_WINK'
export const TRIPLE_WINK = 'TRIPLE_WINK'
export const LONG_WINK = 'LONG_WINK'
export const LONG_DOUBLE_WINK = 'LONG_DOUBLE_WINK'
export const LONG_TRIPLE_WINK = 'LONG_TRIPLE_WINK'
export const FLASH_STATUS_ONE = 'FLASH_STATUS_ONE'
export const FLASH_STATUS_TWO = 'FLASH_STATUS_TWO'
export const FLASH_STATUS_THREE = 'FLASH_STATUS_THREE'
export const ARMED_SLEEP_MODE = 'ARMED_SLEEP_MODE'
export const ARMED_STOP_MODE = 'ARMED_STOP_MODE'
export const SYSTEM_TEST = 'SYSTEM_TEST'

export default function getLedState(ledStatus) {
    switch (ledStatus) {
        case LED_ON:
            return 'led--active'
        case LED_BLINK:
            return 'led--blink'
        case ONE_WINK:
            return 'led--one-wink'
        case DOUBLE_WINK:
            return 'led--double-wink'
        case TRIPLE_WINK:
            return 'led--triple-wink'
        case LONG_WINK:
            return 'led--long-wink'
        case LONG_DOUBLE_WINK:
            return 'led--long-double-wink'
        case LONG_TRIPLE_WINK:
            return 'led--long-triple-wink'
        case FLASH_SLOW:
            return 'led--flash-slow'
        case FLASH_MEDIUM:
            return 'led--flash-medium'
        case FLASH_FAST:
            return 'led--flash-fast'
        case FLASH_STATUS_ONE:
            return 'led--flash-status-one'
        case FLASH_STATUS_TWO:
            return 'led--flash-status-two'
        case FLASH_STATUS_THREE:
            return 'led--flash-status-three'
        case ARMED_SLEEP_MODE:
            return 'led--armed-sleep-mode'
        case ARMED_STOP_MODE:
            return 'led--armed-stop-mode'
        case SYSTEM_TEST:
            return 'led--system-test'
        case LED_OFF:
        default:
            return ''
    }
}
