import React from 'react'

import Page from 'ipmp-react-ui/Page'

import ZoneTypesTable from 'pages/EventRules/NotificationTypes/NotificationTypesTable'
import ZoneTypesGridBar from 'pages/EventRules/NotificationTypes/NotificationTypesGridBar'

export default class NotificationTypesPage extends Page {
    renderBar() {
        return <ZoneTypesGridBar />
    }

    renderContent() {
        return <ZoneTypesTable />
    }
}
