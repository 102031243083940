import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {
    init,
    receive,
    reset,
    setFrontEndStart,
    setQuery,
    setFilters,
    clearFilters,
    addFilters,
    removeFilters,
    fetch,
    remove,
    startPoll,
    stopPoll,
    purgeList,
} from 'modules/eventRules/list/actions'
import {showAddEventRuleModal} from 'modules/modals/actions'

const mapStateToProps = () => ({eventRules}, {perPage}) => {
    const {start, total, error, page} = eventRules.list
    const pages = page.filter((_, index) => index >= start && index < start + perPage)
    return {
        error,
        start,
        total,
        rows: pages.map((id) => ({
            className: 'eventRule-row',
            ...eventRules.store.byIds[id],
        })),
    }
}

const mapDispatchToProps = () => (dispatch) =>
    bindActionCreators(
        {
            init,
            receive,
            reset,
            setFrontEndStart,
            setQuery,
            setFilters,
            clearFilters,
            addFilters,
            removeFilters,
            fetch,
            remove,
            startPoll,
            stopPoll,
            purgeList,
            showAddEventRuleModal,
        },
        dispatch
    )

export default function withEventRules() {
    return compose(
        withPerPage('eventRules'),
        connect(mapStateToProps, mapDispatchToProps)
    )
}
