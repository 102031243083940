import has from 'lodash-es/has'
import {createSelector} from 'reselect'

const selectPanelFirmware = (state, {panelId}) => state.panels.firmware[panelId] || {}

function getPanelFirmwareByDeviceType(firmware, deviceType) {
    return has(firmware, 'appliances') && firmware.appliances !== null
        ? firmware.appliances.find((appliance) => appliance.type === deviceType) || {}
        : {}
}

export const selectPanelFirmwareByDeviceType = createSelector(
    selectPanelFirmware,
    (_, {device}) => device.deviceType,
    (firmware, type) => ({
        firmware: getPanelFirmwareByDeviceType(firmware, type),
        runner: firmware.runner,
    })
)
