import {takeEvery, call, put, select, all} from 'redux-saga/effects'

import {takeEveryProcessProgress} from 'modules/processes/manager/takeProcess'

import * as api from 'api/processes'
import toIds from 'utils/toIds'
import * as actions from './actions'
import {update} from 'modules/processes/store/actions'

export default function* () {
    yield all([
        takeEvery([actions.reset, actions.more], watchFetch),
        takeEveryProcessProgress(null, watchProcessCreated),
    ])
}

function* watchFetch() {
    const {page, perPage, panelId} = yield select((state) => state.panels.processes)

    try {
        const firstId = Math.min(...page)

        const filters = {panelId}

        if (page.length) {
            filters.id = `{<${firstId}}`
        }

        const {rows, count} = yield call(api.fetch, {
            filters,
            perPage,
            sort: 'id',
            desc: true,
        })

        yield put(update(rows))
        yield put(actions.receive(toIds(rows), count))
    } catch (error) {
        yield put(actions.receive(error))
    }
}

function* watchProcessCreated(process) {
    const {page, isLoading, panelId} = yield select((state) => state.panels.processes)

    if (isLoading || panelId !== process.panelId) {
        return
    }

    if (process.id > Math.max(...page)) {
        yield put(actions.unshift(process.id))
    }
}
