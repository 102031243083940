import {post} from 'api/http'
import mapValidationErrors from 'api/base/mapValidationErrors'

export function rebootPlink(unitId, {seconds}) {
    return post('system_plinkdebug/reboot', {
        unt_id: unitId,
        timeout: parseInt(seconds),
    }).catch((e) => mapValidationErrors(e, {seconds: 'timeout'}))
}

const mapPlinkLogging = ({unitId, host, port}) => ({
    unt_id: unitId,
    connection: {
        host,
        port: parseInt(port),
    },
})

export function enablePlinkLogging(unitId, {host, port}) {
    return post(
        'system_plinkdebug/logenable',
        mapPlinkLogging({unitId, host, port})
    ).catch(mapValidationErrors)
}

export function disablePlinkLogging(unitId, {host, port}) {
    return post(
        'system_plinkdebug/logdisable',
        mapPlinkLogging({unitId, host, port})
    ).catch(mapValidationErrors)
}

export function sendPlinkLog(unitId) {
    return post('system_plinkdebug/logsend', {unt_id: unitId})
}

export function enablePlinkSSH(unitId) {
    return post('system_plinkdebug/sshenable', {unt_id: unitId})
}

export function disablePlinkSSH(unitId) {
    return post('system_plinkdebug/sshdisable', {unt_id: unitId})
}
