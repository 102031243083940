import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {
    init,
    reset,
    setStart,
    fetch,
    startPoll,
    stopPoll,
} from 'modules/runners/list/actions'
import {selectRunnersList} from 'modules/runners/list/selectors'
import {stop} from 'modules/runners/store/actions'

export default function withRunners() {
    return compose(
        withPerPage('runners'),
        connect(selectRunnersList, (dispatch) =>
            bindActionCreators(
                {
                    init,
                    reset,
                    setStart,
                    fetch,
                    onPageChange: setStart,
                    startPoll,
                    stopPoll,
                    stop,
                },
                dispatch
            )
        )
    )
}
