import {createSelector} from 'reselect'

export const selectZoneTypesListRows = createSelector(
    (state) => state.zoneTypes.list,
    (state) => state.zoneTypes.store.byIds,
    (list, storeByIds) => ({
        ...list,
        rows: list.page.map((id) => storeByIds[id] || {}),
    })
)
