import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {AppToggle} from 'components/DropDown/ApplicationInteractiveDropdown'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withPanel from 'containers/withPanel'

import {encryption} from 'permissions/panel/actions'
import {setEncryptionState} from 'modules/panels/store/actions'
import {MODULE_TYPE_BBA, MODULE_TYPE_GPRS, moduleTypeTitle} from 'constants/moduleTypes'
import {showConfirmationModal} from 'modules/modals/actions'

import {withFeatureRejection} from 'containers/withFeature'
import {selectEncryption} from 'modules/features/store/selectors'
import Void from 'components/Void'
import __ from 'utils/i18n'

class ToggleEncryptionItem extends Component {
    static contextTypes = {
        hidePopup: PropTypes.func,
    }

    static propTypes = {
        type: PropTypes.oneOf([MODULE_TYPE_BBA, MODULE_TYPE_GPRS]),

        panelId: PropTypes.number.isRequired,
        encrypted: PropTypes.bool,
        connected: PropTypes.bool,

        label: PropTypes.string,
        handle: PropTypes.func.isRequired,
        setPreventHideOnOuterClick: PropTypes.func.isRequired,
    }

    state = {
        isConfirmationActive: false,
    }

    handleEncryptionChange = (e) => {
        const {
            handle,
            panelId,
            type,
            showConfirmationModal,
            setPreventHideOnOuterClick,
        } = this.props

        this.setState({
            changeValue: e.target.checked,
        })

        setPreventHideOnOuterClick(true)

        showConfirmationModal(
            () => {
                setPreventHideOnOuterClick(false)
                handle(panelId, type, this.state.changeValue)
            },
            {
                message: __('Do you really want to change encryption settings?'),
                title: __('Toggle encryption'),
            }
        )
    }

    get isChecked() {
        return this.props.encrypted || false
    }

    render() {
        const {label, connected} = this.props

        return (
            <div className="menu-item menu-item--toggle">
                <AppToggle
                    permission={encryption}
                    label={label}
                    onChange={this.handleEncryptionChange}
                    checked={this.isChecked}
                    disabled={connected}
                />
            </div>
        )
    }
}

export default compose(
    withPermission({isVisible: encryption}),
    withVisibility(),
    withPanel(),
    connect(
        (state, props) => {
            const {panel, type} = props
            const {id, modules} = panel || {}
            const {bba, gprs} = modules || {}

            return {
                panelId: id,
                encrypted:
                    type === MODULE_TYPE_BBA
                        ? bba && bba.encrypted
                        : gprs && gprs.encrypted,
                label: moduleTypeTitle(type),
            }
        },
        (dispatcher) =>
            bindActionCreators(
                {
                    handle: setEncryptionState,
                    showConfirmationModal,
                },
                dispatcher
            )
    ),
    withFeatureRejection(selectEncryption, Void)
)(ToggleEncryptionItem)
