import {createAction} from 'redux-actions'
import toArray from 'utils/toArray'

export const reset = createAction('PANELS/PROCESS/RESET', (id) => ({id}))
export const more = createAction('PANELS/PROCESS/GET_MORE')
export const receive = createAction('PANELS/PROCESS/RECEIVE', (page, total) => ({
    page,
    total,
}))
export const unshift = createAction('PANELS/PROCESS/UNSHIFT', toArray)
