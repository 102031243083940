import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import withProps from 'containers/withProps'

import Search from 'components/Search/Search'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'

import {
    fetch,
    addFilters,
    setQuery,
    removeFilters,
    clearFilters,
    fetchSuggests,
} from 'modules/eventRules/list/actions'

import __ from 'utils/i18n'

export default compose(
    withProps(() => ({
        filters: [
            new SuggestFilter('zoneTypeId', __('Zone Type')),
            new SuggestFilter('notificationId', __('Notification Type')),
            new SuggestFilter('stateId', __('State Type')),
            new SuggestFilter('eventId', __('Event Type')),
        ].sort((item, next) => item.title.localeCompare(next.title)),
    })),
    connect(
        ({eventRules: {list}}) => ({
            selected: list.filters,
            query: list.query,
            suggests: list.suggests,
        }),

        (dispatch) =>
            bindActionCreators(
                {
                    apply: fetch,
                    onSelect: addFilters,
                    onQuery: setQuery,
                    onDeselect: removeFilters,
                    onClear: clearFilters,
                    onSuggest: fetchSuggests,
                },
                dispatch
            )
    )
)(Search)
