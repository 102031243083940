import {takeEvery, all, call, put} from 'redux-saga/effects'
import {__} from 'utils/i18n'

import {
    rebootPlink,
    enablePlinkLogging,
    disablePlinkLogging,
} from 'modules/forms/handlers'
import {sendPlinkLog, enablePlinkSSH, disablePlinkSSH, setPlinkError} from './actions'
import * as API from 'api/panel/plink'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([
        takeEvery(sendPlinkLog, watchSendPlinkLog),
        takeEvery(enablePlinkSSH, watchEnablePlinkSSH),
        takeEvery(disablePlinkSSH, watchDisablePlinkSHH),
        takeEvery(
            [
                rebootPlink.FAILURE,
                enablePlinkLogging.FAILURE,
                disablePlinkLogging.FAILURE,
            ],
            watchPlinkFailure
        ),
        takeEvery(
            [
                rebootPlink.SUCCESS,
                enablePlinkLogging.SUCCESS,
                disablePlinkLogging.SUCCESS,
            ],
            watchPlinkSuccess
        ),
    ])
}

function* watchPlinkFailure({payload}) {
    yield put(setPlinkError(payload))
}

function* watchPlinkSuccess({payload: {name}}) {
    let message
    switch (name) {
        case 'rebootPlink':
            message = __('PLink reboot initiated')
            break
        case 'enablePlinkLogging':
            message = __('PLink logging enabled')
            break
        case 'disablePlinkLogging':
            message = __('PLink logging disabled')
            break
    }

    yield put(snackShow(message))
}

function* watchSendPlinkLog({payload}) {
    try {
        yield call(API.sendPlinkLog, payload)
        yield put(snackShow(__('PLink log sent')))
    } catch (error) {
        yield put(setPlinkError(error))
    }
}

function* watchEnablePlinkSSH({payload}) {
    try {
        yield call(API.enablePlinkSSH, payload)
        yield put(snackShow(__('PLink ssh enabled')))
    } catch (error) {
        yield put(setPlinkError(error))
    }
}

function* watchDisablePlinkSHH({payload}) {
    try {
        yield call(API.disablePlinkSSH, payload)
        yield put(snackShow(__('PLink ssh disabled')))
    } catch (error) {
        yield put(setPlinkError(error))
    }
}
