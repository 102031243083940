import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconHelp} from 'ipmp-react-ui/icons/help.svg'
import __ from 'utils/i18n'

export default class PGMFormFieldDescription extends Component {
    static propTypes = {
        children: PropTypes.string,
    }

    state = {
        isOpen: false,
    }

    onClick = () => this.setState({isOpen: !this.state.isOpen})

    render() {
        const {isOpen} = this.state

        const className = classnames('pgm-form-field-description', {
            'pgm-form-field-description--open': isOpen,
        })

        return (
            <div className={className}>
                <Button
                    onClick={this.onClick}
                    className="pgm-form-field-description-trigger"
                    Icon={IconHelp}
                    borderless
                    small
                    active={isOpen}
                >
                    {__('Details')}
                </Button>
                <p className="pgm-form-field-description-info">{this.props.children}</p>
            </div>
        )
    }
}
