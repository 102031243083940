import {compose} from 'redux'

import {reject} from 'permissions/installers/actions'

import withProps from 'containers/withProps'
import withInstallers from 'containers/withInstallers'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withSelection, {withSelectionHandler} from 'containers/withSelection'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: reject}),
    withVisibility(),
    withSelection(),
    withInstallers(),
    withProps(({reject}) => ({
        label: __('Reject'),
        onClick: (selection) => reject(selection),
    })),
    withSelectionHandler()
)(Button)
