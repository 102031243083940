import has from 'lodash-es/has'
import {createSelector} from 'reselect'

export const selectBatchesListRows = createSelector(
    (state) => state.batches.list.page,
    (state) => state.batches.store.byIds,
    (page, store) =>
        page.reduce((acc, id) => {
            if (has(store, id)) {
                acc.push(store[id])
            }
            return acc
        }, [])
)
