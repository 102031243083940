import has from 'lodash-es/has'

import __ from 'utils/i18n'
import {warn} from 'utils/log'

export const MODULE_KA_STATE_ONLINE = 'online'
export const MODULE_KA_STATE_OFFLINE = 'offline'
export const MODULE_KA_STATE_DISABLED = 'disabled'

export default function moduleKATitle(module) {
    if (!module) {
        return __('Not Installed')
    }

    if (module.connected) {
        return __('Connected')
    }

    if (has(module, 'installed') && !module.installed) {
        return __('Not Installed')
    }

    switch (module.state) {
        case MODULE_KA_STATE_ONLINE:
            return __('Online')
        case MODULE_KA_STATE_OFFLINE:
            return __('Offline')
        case MODULE_KA_STATE_DISABLED:
            return __('Disabled')
        case null:
            return __('Installed')
    }

    warn(`Unknown module KA state ${module.state}`)
    return module.state || __('Unknown')
}
