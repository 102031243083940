import {takeEvery, put, call} from 'redux-saga/effects'
import * as actions from './actions'
import * as api from 'api/roles'

export default function* () {
    yield takeEvery(actions.fetch, watchFetch)
}

function* watchFetch({payload: roleId}) {
    try {
        const permissions = yield call(api.fetchPermissions, roleId)
        yield put(actions.receive(permissions, roleId))
    } catch (error) {
        yield put(actions.receive(error, roleId))
    }
}
