import {get} from 'api/http'
import dataMapper from 'utils/dataMapper'

const mapServers = dataMapper((data) => ({
    name: data.name,
    url: data.url,
}))

export function fetch() {
    return get('/settings/servers').then((data) => data.map(mapServers))
}
