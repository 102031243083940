import {put, select} from 'redux-saga/effects'
import * as actions from '../actions'
import updatePanelFaults from 'modules/panels/store/watchers/updatePanelFaults'

export default function* watchResolveFaults({meta: {faultIds}}) {
    const panels = yield select((state) => state.panels.store.byIds)
    const panelsPatch = updatePanelFaults(panels, faultIds, (faults) =>
        faults.filter((fault) => !faultIds.includes(fault.id))
    )

    if (panelsPatch.length > 0) {
        yield put(actions.update(panelsPatch))
    }
}
