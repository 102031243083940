import React from 'react'

import Definition from 'ipmp-react-ui/Definition'
import {__} from 'utils/i18n'
import {humanTime} from 'ipmp-react-ui/humanTime'

import TimeFaults from './TimeFaults'

export default class FrequentlyUsed extends TimeFaults {
    renderRow = ({name, description, used}, index) => {
        return (
            <tr key={index}>
                <td className="rri-result-details-row">
                    <Definition title={name} detail={description} />
                </td>
                <td className="rri-result-details-row">
                    {used ? __('Last used %s', humanTime(used).date) : __('Never used')}
                </td>
            </tr>
        )
    }

    render() {
        return (
            <table className="rri-result-details">
                <tbody>{this.props.rows.map(this.renderRow)}</tbody>
            </table>
        )
    }
}
