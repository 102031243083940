import {__} from 'utils/i18n'

import * as Generic from './Generic'

export class Fieldset extends Generic.Fieldset {}

export const rules = {
    ...Generic.rules,
    serial: {
        presence: true,
        format: {
            pattern: '[a-f0-9]{12}',
            flags: 'i',
            message: () => __('Must be a 12 digit hexadecimal'),
        },
    },
    account: {
        presence: true,
        format: {
            pattern: '[a-f0-9]{4}([a-f0-9]{2})?',
            flags: 'i',
            message: () => __('Must be a 4 or 6 digit hexadecimal'),
        },
    },
}
