import createListReducer from 'modules/higherOrder/createListReducer'
import * as actions from './actions'

const handlers = {
    [actions.receive](state) {
        const selectedIndex = 0

        return {
            ...state,
            selectedIndex,
            selectedId: state.page.length > 0 && state.page[selectedIndex],
            hasNext: state.page.length > 0,
        }
    },

    [actions.select](state, {payload: selectedIndex}) {
        return {
            ...state,
            selectedIndex,
            selectedId: state.page[selectedIndex],
            hasNext: state.page.length > selectedIndex + 1,
            hasPrev: state.page.length && selectedIndex > 0,
        }
    },

    [actions.selectNext](state) {
        if (!state.hasNext) {
            return state
        }

        const selectedIndex = state.selectedIndex + 1

        return {
            ...state,
            selectedIndex,
            selectedId: state.page[selectedIndex],
            hasNext: state.page.length > selectedIndex + 1,
            hasPrev: state.page.length && selectedIndex > 0,
        }
    },

    [actions.selectPrevious](state) {
        if (!state.hasPrev) {
            return state
        }

        const selectedIndex = state.selectedIndex - 1

        return {
            ...state,
            selectedIndex,
            selectedId: state.page[selectedIndex],
            hasNext: state.page.length > selectedIndex + 1,
            hasPrev: state.page.length && selectedIndex > 0,
        }
    },
}

export default createListReducer(actions, handlers, {
    selectedIndex: 0,
    selectedId: null,
    hasNext: false,
    hasPrev: false,
})
