import {put} from 'redux-saga/effects'
import * as actions from '../actions'

export default function* watchEditPanelCustomerInfo({meta}) {
    const {panelId, data} = meta

    yield put(
        actions.update({
            id: panelId,
            contact: data,
        })
    )
}
