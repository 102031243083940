import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import ModalCardForm from 'ui/ModalCardForm'

import head from 'lodash-es/head'
import {__, __n} from 'utils/i18n'

import withUsers from 'containers/withUsers'

import RemoveUsersReject from './RemoveUser/RemoveUsersReject'
import RemoveUserReject from './RemoveUser/RemoveUserReject'

class RemoveUser extends Component {
    static propTypes = {
        userIds: PropTypes.arrayOf(PropTypes.number),
        users: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                isRemovable: PropTypes.bool,
            })
        ),
        hide: PropTypes.func.isRequired,
        handle: PropTypes.func,
    }

    static defaultProps = {
        users: [],
    }

    unRemovableUsers() {
        return this.props.users.filter(({isRemovable}) => !isRemovable)
    }

    submit = () => {
        this.props.handle()
        this.props.hide()
    }

    render() {
        const unRemovableUsers = this.unRemovableUsers()
        const {hide, users} = this.props
        const header = users.length > 1 ? __('Remove users') : __('Remove user')

        switch (unRemovableUsers.length) {
            case 0:
                return (
                    <ModalCardForm
                        hide={hide}
                        header={header}
                        submitLabel={__('Remove')}
                        onSubmit={this.submit}
                    >
                        {__n(
                            'Do you really want to remove %d user?',
                            'Do you really want to remove %d users?',
                            users.length
                        )}
                    </ModalCardForm>
                )

            case 1:
                return (
                    <RemoveUserReject
                        hide={hide}
                        header={header}
                        user={head(unRemovableUsers)}
                    />
                )

            default:
                return (
                    <RemoveUsersReject
                        hide={hide}
                        header={header}
                        users={unRemovableUsers}
                    />
                )
        }
    }
}

export default compose(
    withUsers(),
    connect(({users: {store: {byIds}}}, {userIds, remove}) => ({
        users: Object.values(byIds).filter(({id}) => userIds.indexOf(id) !== -1),
        handle: () => remove(userIds),
    }))
)(RemoveUser)
