import * as api from 'api/centralStations'
import {createAction} from 'redux-actions'

export const setLoading = createAction('CENTRAL_STATIONS/SERIAL_PORTS/SET_LOADING')
export const receive = createAction(
    'CENTRAL_STATIONS/SERIAL_PORTS/RECEIVE',
    api.getSerialPorts
)

export function fetch() {
    return (dispatch) => {
        dispatch(setLoading())
        dispatch(receive())
    }
}
