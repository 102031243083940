import {all, call, put, takeEvery} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/dashboard'

import {POLL_DASHBOARD} from 'configs/pollers'
import createPollerSaga from 'modules/higherOrder/createPollerSaga'

export default function* () {
    yield all([
        takeEvery(actions.fetch, receive),
        createPollerSaga(
            actions.startPoll,
            actions.stopPoll,
            POLL_DASHBOARD,
            receive,
            true
        ),
    ])
}

export function* receive() {
    try {
        const data = yield call(api.getEventsRate)

        yield put(actions.receive(data))
    } catch (error) {
        yield put(actions.receive(error))
    }
}
