import React, {Component} from 'react'

import ProcessesPager from './GridBar/ProcessesPager'
import ProcessesDropDown from './GridBar/ProcessesDropDown'
import ProcessesStopButton from './GridBar/ProcessesStopButton'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

export default class ProcessesGridBar extends Component {
    render() {
        return (
            <Bar>
                <ProcessesDropDown />
                <ProcessesStopButton />

                <BarSpace />

                <ProcessesPager />
            </Bar>
        )
    }
}
