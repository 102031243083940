import {compose} from 'redux'

import enable from 'permissions/panel/devices/plink/ssh/enable'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withConfirmation from 'containers/withConfirmation'
import {withPlinkDebugCommands} from 'containers/withDevices'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'

export default compose(
    withPermission({
        isVisible: enable,
    }),
    withVisibility(),
    withPlinkDebugCommands(),
    withProps(({enableSSH}) => ({
        onClick: enableSSH,
        title: __('SSH enable'),
        message: __('Do you want to enable SSH?'),
        positive: __('Enable'),
    })),
    withConfirmation()
)(Button)
