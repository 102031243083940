import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ModalCardForm from 'ui/ModalCardForm'

import decorate from 'utils/decorate'
import {__} from 'utils/i18n'

export default class extends Component {
    static propTypes = {
        user: PropTypes.shape({
            name: PropTypes.string,
            relatedUsers: PropTypes.number,
            relatedRoles: PropTypes.number,
        }),
        hide: PropTypes.func.isRequired,
        header: PropTypes.string,
    }

    render() {
        const {user, hide, header} = this.props

        return (
            <ModalCardForm
                header={header}
                showDismiss={true}
                hide={hide}
                editable={false}
                onSubmit={() => ({})}
            >
                {decorate(
                    __(
                        'User %s cannot be deleted, because it has %s child users and %s child roles. Remove them before delete this user'
                    ),
                    user.name,
                    user.relatedUsers,
                    user.relatedRoles
                )}
            </ModalCardForm>
        )
    }
}
