import {compose} from 'redux'
import {__} from 'utils/i18n'
import Button from 'ipmp-react-ui/Button'
import get from 'lodash-es/get'

import {withPermission} from 'containers/withPermission'
import {withDeviceActions} from 'containers/withDevices'
import withProps from 'containers/withProps'
import {withVisibility} from 'containers/withVisibility'

import {setLabel as rename} from 'permissions/panel/labels'

export default compose(
    withPermission(rename),
    withDeviceActions(),
    withProps(({renameDevice, device, isPermitted}) => ({
        onClick: renameDevice,
        label: __('Rename'),
        className: 'devices-sidebar-button btn--flat',
        isVisible: get(device, ['isRenameable'], false) && isPermitted,
    })),
    withVisibility()
)(Button)
