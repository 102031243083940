import {takeEvery, put, take, fork, cancel} from 'redux-saga/effects'

import {show, hide} from 'modules/modals/actions'
import {receive} from 'modules/forms/actions'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield takeEvery(show, watchModal)
}

function* watchResult(args) {
    while (true) {
        const {
            payload: {success, errors, error},
        } = yield take(receive)

        const isCombinedModals =
            args && args.combinedModals !== undefined && args.combinedModals === true

        if (success && !isCombinedModals) {
            yield put(hide())
            return
        } else if (!success && (!errors || Object.keys(errors).length === 0)) {
            yield put(snackShow(error))
        }
    }
}

function* watchModal({payload: {args}}) {
    const watchResultTask = yield fork(watchResult, args)
    yield take(hide)
    yield cancel(watchResultTask)
}
