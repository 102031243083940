import {compose} from 'redux'

import RoleForm from './RoleForm'
import withForm from 'containers/withForm'
import {addRole} from 'modules/forms/handlers'
import {__} from 'utils/i18n'
import withProps from 'containers/withProps'

const defaultValues = {}

export default compose(
    withProps(() => ({
        data: defaultValues,
        isNew: true,
        header: __('Add role'),
    })),
    withForm(addRole)
)(RoleForm)
