import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import deviceType from 'constants/deviceType'

import Definition from 'ipmp-react-ui/Definition'
import Select from 'ipmp-react-ui/Select'
import {Option} from 'ipmp-react-ui/Select'
import __ from 'utils/i18n'

export default class FirmwareRow extends PureComponent {
    static propTypes = {
        number: PropTypes.number,
        type: PropTypes.string,
        currentVersion: PropTypes.string,
        packages: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                description: PropTypes.string,
                version: PropTypes.string,
            })
        ),
        selectedPackageName: PropTypes.string,
        onChange: PropTypes.func,
    }

    onChange = (e, value) => {
        const {onChange, number, type} = this.props
        onChange(value, type, number)
    }

    renderPackageSelector() {
        const {packages, selectedPackageName} = this.props

        if (!packages.length) {
            return <div className="empty">{__('No packages available')}</div>
        }

        return (
            <Select
                optional={__('Do not upgrade')}
                value={selectedPackageName}
                onChange={this.onChange}
            >
                {packages.map(({name}) => (
                    <Option key={name} label={name} />
                ))}
            </Select>
        )
    }

    render() {
        const {number, type, currentVersion, selectedPackageName} = this.props

        return (
            <div
                className={classnames('form-row', {
                    'form-row--changed': selectedPackageName,
                })}
            >
                <Definition
                    className="form-row-label"
                    title={deviceType(type)}
                    detail={number}
                />

                <div className="form-row-label">{currentVersion}</div>

                <div className="form-row-content">{this.renderPackageSelector()}</div>
            </div>
        )
    }
}
