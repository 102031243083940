import {takeEvery, put, call, select, all} from 'redux-saga/effects'
import {fetch, setLoading} from './actions'
import {fetch as fetchApi} from 'api/basicConfiguration'
import {update} from '../store/actions'

export default function* () {
    yield all([takeEvery(fetch, watchFetch)])
}

function* watchFetch({payload: {prefix, count}}) {
    const {panelIds, byIds} = yield select(({modals, panels}) => ({
        ...modals.args,
        ...panels.store,
    }))

    // Use first panel for detect vendor, becouse choosen panels must have same vendors
    const firstSelectedPanelId = panelIds[0]
    const selectedPanelConfigModel = byIds[firstSelectedPanelId].configurationName

    try {
        const {rows} = yield call(fetchApi, {
            count,
            filters: {
                vendor: selectedPanelConfigModel,
                ...(prefix ? {name: `{:${prefix}}`} : {}),
            },
            sort: 'name',
        })

        yield put(update(rows))
        yield put(setLoading(false))
    } catch (error) {
        yield put(setLoading(false))
    }
}
