import has from 'lodash-es/has'

import BoardDateFilter from 'components/Search/Board/BoardDateFilter'
import Filter from 'components/Search/Filters/Filter'

import parse, {stringify} from 'utils/dateParser'

import __ from 'utils/i18n'

export default class DateFilter extends Filter {
    Component = BoardDateFilter

    range = true

    uniqId = null

    constructor(name, title, period = 'past', range = true) {
        super(name, title)

        this.range = range
        this.period = period

        if (period === 'past') {
            this.items = {
                '{0day}': __('Today'),
                '{-1day:-1day}': __('Yesterday'),
                '{-7day}': __('Last Week'),
                '{-1month}': __('Last Month'),
            }
        } else {
            this.items = {
                '{0day}': __('Today'),
                '{+1day:+1day}': __('Tomorrow'),
                '{+7day}': __('Next Week'),
                '{+1month}': __('Next Month'),
            }
        }
    }

    // For prevent duplicate filter values
    // we assume that there can be a maximum of two date filters,
    // past and future
    digest(value) {
        if (value instanceof Object) {
            return this.period + this.name + '$custom'
        }

        return this.period + value
    }

    getItems() {
        return Object.keys(this.items).map(this.createItem.bind(this))
    }

    getItemLabel(value) {
        if (has(this.items, value)) {
            return this.items[value]
        }

        return stringify(value)
    }

    createItemByPrefix(prefix) {
        const parsed = parse(prefix)

        if (parsed && parsed.from) {
            return this.createItem(parsed)
        }

        return null
    }
}
