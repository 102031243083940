import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {
    init,
    fetch,
    reset,
    setStart,
    startPoll,
    stopPoll,
} from 'modules/installers/list/actions'
import {accept, reject, resetPassword} from 'modules/installers/store/actions'
import {withRouterPanelId} from './withPanel'
import * as panelInstallersActions from 'modules/panelInstallers/list/actions'
import {selectPanelInstallersList} from 'modules/panelInstallers/list/selectors'

export default function withInstallers() {
    return compose(
        withPerPage('installers'),
        connect(
            ({installers}) => {
                const {isLoading, page, error, total, start} = installers.list

                return {
                    rows: page.map((id) => installers.store.byIds[id]),
                    isLoading,
                    error,
                    total,
                    start,
                }
            },
            (dispatch) =>
                bindActionCreators(
                    {
                        init,
                        fetch,
                        reset,
                        accept,
                        reject,
                        resetPassword,
                        onPageChange: setStart,
                        startPoll,
                        stopPoll,
                    },
                    dispatch
                )
        )
    )
}

export function withPanelInstallers() {
    return compose(
        withRouterPanelId(),
        connect(selectPanelInstallersList, (dispatch) =>
            bindActionCreators(
                {
                    init: panelInstallersActions.init,
                    fetch: panelInstallersActions.fetch,
                    reset: panelInstallersActions.reset,
                    startPoll: panelInstallersActions.startPoll,
                    stopPoll: panelInstallersActions.stopPoll,
                    purgeList: panelInstallersActions.purgeList,
                },
                dispatch
            )
        )
    )
}
