import {put, call} from 'redux-saga/effects'

import * as api from 'api/panels'
import {snackShow} from 'modules/snacks'

export default function* watchEncryptionState({payload}) {
    const {id, channel, encrypted} = payload

    try {
        yield call(api.setEncryption, id, channel, encrypted)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
