import React from 'react'
import ReactDOM from 'react-dom'
import {createBrowserHistory as createHistory} from 'history'

import createAppStore from 'modules/createAppStore'

import App from 'pages/App'

import 'css-reset-and-normalize-sass'
import 'styles/index.sass'
import 'configs/init'

const history = createHistory()
const store = createAppStore(history)

ReactDOM.render(<App store={store} history={history} />, document.getElementById('root'))
