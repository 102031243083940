import {createAction} from 'redux-actions'

export const createWorker = createAction('EVENTS/ALARMS/INIT_WORKER')
export const sendStartMessage = createAction(
    'EVENTS/ALARMS/SEND_START_MESSAGE',
    (lastId) => ({lastId})
)
export const setLastEventId = createAction(
    'EVENTS/ALARMS/SET_LAST_EVENT_ID',
    (lastId) => ({lastId})
)
export const sendStopMessage = createAction('EVENTS/ALARMS/SEND_STOP_MESSAGE')
export const setCount = createAction('EVENTS/ALARMS/SET_COUNT', (count) => ({count}))
