import {cancel, fork, take, call, delay} from 'redux-saga/effects'

export default function* createPollerSaga(
    startAction,
    stopAction,
    interval,
    iteratee,
    startBeforeDelay = false
) {
    while (true) {
        const action = yield take(startAction)
        const pollTask = yield fork(poller, interval, iteratee, startBeforeDelay, action)
        yield take(stopAction)
        yield cancel(pollTask)
    }
}

export function* poller(interval, iteratee, startBeforeDelay, ...args) {
    if (startBeforeDelay) {
        yield call(iteratee, ...args)
    }
    while (true) {
        if (Number.isInteger(interval)) {
            yield delay(interval)
        } else {
            yield call(interval, ...args)
        }

        yield call(iteratee, ...args)
    }
}
