import React from 'react'
import {compose} from 'redux'

import {edit} from 'permissions/centralStations/actions'
import list from 'permissions/centralStations/list'
import selection from 'permissions/centralStations/selection'

import withProps from 'containers/withProps'
import withLoader from 'containers/withLoader'
import withSelection from 'containers/withSelection'
import withCentralStations from 'containers/withCentralStations'
import withTableColumnsPermission from 'containers/withTableColumnsPermission'
import {withPermission, withRejection} from 'containers/withPermission'

import {
    CS_STATUS_ONLINE,
    CS_STATUS_OFFLINE,
    CS_STATUS_HEARTBEAT,
} from 'constants/centralStation'

import Table from 'ipmp-react-ui/Table'
import EditCell from './TableCell/EditCell'
import Definition from 'ipmp-react-ui/Definition'
import CommunicationCell from './TableCell/CommunicationCell'

import {ReactComponent as IconHeart} from 'ipmp-react-ui/icons/heart.svg'
import {ReactComponent as IconOnline} from 'ipmp-react-ui/icons/online.svg'
import {ReactComponent as IconOffline} from 'ipmp-react-ui/icons/offline.svg'

import {__} from 'utils/i18n'

const columns = [
    {
        name: () => __('Name'),
        render: ({name}) => {
            return name
        },
    },
    {
        name: () => __('Status'),
        render: ({status}) => {
            switch (status) {
                case CS_STATUS_ONLINE:
                    return (
                        <Definition
                            title={__('Online')}
                            iconClassName=""
                            Icon={IconOnline}
                        />
                    )
                case CS_STATUS_OFFLINE:
                    return (
                        <Definition
                            title={__('Offline')}
                            iconClassName=""
                            Icon={IconOffline}
                        />
                    )
                case CS_STATUS_HEARTBEAT:
                    return (
                        <Definition
                            title={__('Heartbeat')}
                            iconClassName="primary icon-heartbeat"
                            Icon={IconHeart}
                        />
                    )
                default:
                    console.warn(`Unknown cs status: ${status}`)
                    return status
            }
        },
    },
    {
        name: () => __('Protocol'),
        width: 100,
        maxWidth: 220,
        render: ({protocol}) => protocol,
    },
    {
        name: () => __('Communication'),
        width: 220,
        component: CommunicationCell,
    },
    {
        tooltip: false,
        permission: edit,
        width: 50,
        fixed: true,
        component: EditCell,
    },
]

export default compose(
    withPermission({
        isAllowed: list,
        hasSelection: selection,
    }),
    withRejection(),
    withCentralStations(),
    withSelection(),
    withLoader(({init}) => init()),
    withProps({
        fullHeight: true,
        columns,
        emptyMessage: () => __('No stations found'),
    }),
    withTableColumnsPermission()
)(Table)
