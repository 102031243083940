import React, {Component} from 'react'
import PropTypes from 'prop-types'

import AutohideList from 'ipmp-react-ui/AutohideList'
import DropDown, {DropDownContent} from 'ipmp-react-ui/DropDown'

export default class RoleGroupCell extends Component {
    static propTypes = {
        row: PropTypes.shape({
            groups: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                })
            ).isRequired,
        }).isRequired,
    }

    render() {
        const {groups} = this.props.row

        const trigger = (
            <AutohideList className="colons">
                {groups.map(({id, name}) => (
                    <span className="colon" key={id}>
                        {name}
                    </span>
                ))}
            </AutohideList>
        )

        return (
            <DropDown trigger={trigger}>
                <DropDownContent>
                    {groups.map(({id, name}) => (
                        <div key={id}>{name}</div>
                    ))}
                </DropDownContent>
            </DropDown>
        )
    }
}
