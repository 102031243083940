import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import get from 'lodash-es/get'

import NavBlind, {NavBlindHeader} from 'components/Nav/NavBlind'
import RecentHistoryItem from 'components/RecentHistory/RecentHistoryItem'
import RecentHistoryPanelList from './RecentHistory/RecentHistoryPanelList'

import NavBlindList from './RecentHistory/NavBlindList'

import {selectRecentHistory} from 'modules/panels/recent/selectors'
import __ from 'utils/i18n'

class RecentHistory extends Component {
    static propTypes = {
        recentHistory: PropTypes.array.isRequired,
    }

    onClose = (e) => {
        const {hide} = this.props

        hide()
    }

    renderList = () => {
        const {recentHistory} = this.props
        return recentHistory.map(({interactiveUser, panel}, index) => (
            <RecentHistoryItem
                key={'' + index + panel.id}
                interactiveUserEmail={
                    get(interactiveUser, 'email', __('Unassigned')) || __('Unassigned')
                }
                panelSerial={panel.serial}
            >
                <RecentHistoryPanelList
                    onClose={this.onClose}
                    interactiveUserId={Number(
                        get(interactiveUser, 'interactiveUserId', null)
                    )}
                    panel={panel}
                />
            </RecentHistoryItem>
        ))
    }

    render() {
        const {navBlindRefHandler} = this.props

        return (
            <NavBlind refHandler={navBlindRefHandler}>
                <NavBlindHeader onClose={this.onClose}>
                    {__('Recent history')}
                </NavBlindHeader>

                <NavBlindList>{this.renderList()}</NavBlindList>
            </NavBlind>
        )
    }
}

export default compose(connect(selectRecentHistory))(RecentHistory)
