import has from 'lodash-es/has'

import {__} from 'utils/i18n'
import {warn} from 'utils/log'
import deviceType, * as deviceTypes from './deviceType'

// that's out type
export const DEVICE_SUBTYPE_UNDEFINED = 'UNDEFINED'

// Please carry alphabet order of this list!
// Sorting is very helpful when maintaining this list
export const DEVICE_SUBTYPE_AC_OUTDOOR = 'AC_OUTDOOR'
export const DEVICE_SUBTYPE_BASIC_KEYFOB = 'BASIC_KEYFOB'
export const DEVICE_SUBTYPE_BASIC_REPEATER = 'BASIC_REPEATER'
export const DEVICE_SUBTYPE_BIG = 'BIG'
export const DEVICE_SUBTYPE_CO = 'CO'
export const DEVICE_SUBTYPE_CONTACT = 'CONTACT'
export const DEVICE_SUBTYPE_CONTACT_4_IN = 'CONTACT_4_IN'
export const DEVICE_SUBTYPE_CONTACT_AUX = 'CONTACT_AUX'
export const DEVICE_SUBTYPE_CONTACT_IOS = 'CONTACT_IOS'
export const DEVICE_SUBTYPE_CONTACT_V = 'CONTACT_V'
export const DEVICE_SUBTYPE_COMBO_NBIOT = 'COMBO_NBIOT'
export const DEVICE_SUBTYPE_CURTAIN = 'CURTAIN'
export const DEVICE_SUBTYPE_DSC_KEYFOB_1 = 'DSC_KEYFOB_1'
export const DEVICE_SUBTYPE_DSC_KEYFOB_2 = 'DSC_KEYFOB_2'
export const DEVICE_SUBTYPE_FLAT_PIR_SMART = 'FLAT_PIR_SMART'
export const DEVICE_SUBTYPE_FLOOD = 'FLOOD'
export const DEVICE_SUBTYPE_FLOOD_PROBE = 'FLOOD_PROBE'
export const DEVICE_SUBTYPE_GAS = 'GAS'
export const DEVICE_SUBTYPE_GENERIC_COMMUNICATOR = 'GENERIC_COMMUNICATOR'
export const DEVICE_SUBTYPE_GENERIC_EXPANDER33 = 'GENERIC_EXPANDER33'
export const DEVICE_SUBTYPE_GENERIC_FIREBELL = 'GENERIC_FIREBELL'
export const DEVICE_SUBTYPE_GENERIC_HSMX56K = 'GENERIC_HSMX56K'
export const DEVICE_SUBTYPE_GENERIC_HW_IOV = 'GENERIC_HW_IOV'
export const DEVICE_SUBTYPE_GENERIC_HW_ZONE = 'GENERIC_HW_ZONE'
export const DEVICE_SUBTYPE_GENERIC_PGM = 'GENERIC_PGM'
export const DEVICE_SUBTYPE_GENERIC_PRINTER = 'GENERIC_PRINTER'
export const DEVICE_SUBTYPE_GENERIC_PROXY_TAG = 'GENERIC_PROXY_TAG'
export const DEVICE_SUBTYPE_GLASS_BREAK = 'GLASS_BREAK'
export const DEVICE_SUBTYPE_GLASS_BREAK_SMART = 'GLASS_BREAK_SMART'
export const DEVICE_SUBTYPE_HW_ZONE_CONNECTED_DIRECTLY_TO_THE_PANEL =
    'HW_ZONE_CONNECTED_DIRECTLY_TO_THE_PANEL'
export const DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_EXPANDER33 =
    'HW_ZONE_CONNECTED_TO_EXPANDER33'
export const DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_EXPANDER_HW_IOV =
    'HW_ZONE_CONNECTED_TO_EXPANDER_HW_IOV'
export const DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_WL_EXPANDER_IOV =
    'HW_ZONE_CONNECTED_TO_WL_EXPANDER_IOV'
export const DEVICE_SUBTYPE_HW_ZONE_ON_8_IO_EXPANDER = 'HW_ZONE_ON_8_IO_EXPANDER'
export const DEVICE_SUBTYPE_HW_ZONE_ON_8_ZONE_EXPANDER = 'HW_ZONE_ON_8_ZONE_EXPANDER'
export const DEVICE_SUBTYPE_HW_ZONE_ON_KEYPAD = 'HW_ZONE_ON_KEYPAD'
export const DEVICE_SUBTYPE_INDOOR = 'INDOOR'
export const DEVICE_SUBTYPE_KEYFOB_ARM_LED = 'KEYFOB_ARM_LED'
export const DEVICE_SUBTYPE_KEYPAD = 'KEYPAD'
export const DEVICE_SUBTYPE_LCD_KEYPAD = 'LCD_KEYPAD'
export const DEVICE_SUBTYPE_LCD_PRG_KEYPAD = 'LCD_PRG_KEYPAD'
export const DEVICE_SUBTYPE_LOCAL_PGM_ON_KEYPAD = 'LOCAL_PGM_ON_KEYPAD'
export const DEVICE_SUBTYPE_MC303_VANISH = 'MC303_VANISH'
export const DEVICE_SUBTYPE_MOTION = 'MOTION'
export const DEVICE_SUBTYPE_MOTION_CAMERA = 'MOTION_CAMERA'
export const DEVICE_SUBTYPE_MOTION_DUAL = 'MOTION_DUAL'
export const DEVICE_SUBTYPE_MOTION_ENERGY = 'MOTION_ENERGY'
export const DEVICE_SUBTYPE_MOTION_OUTDOOR = 'MOTION_OUTDOOR'
export const DEVICE_SUBTYPE_MOTION_OUTDOOR_CAMERA = 'MOTION_OUTDOOR_CAMERA'
export const DEVICE_SUBTYPE_MOTION_V_ANTIMASK = 'MOTION_V_ANTIMASK'
export const DEVICE_SUBTYPE_NEO_16_LED_KEYPAD = 'NEO_16_LED_KEYPAD'
export const DEVICE_SUBTYPE_NEO_1A_POWER_SUPPLY_MODULE = 'NEO_1A_POWER_SUPPLY_MODULE'
export const DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD = 'NEO_2X16_LCD_KEYPAD'
export const DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD_WITH_MIFARE =
    'NEO_2X16_LCD_KEYPAD_WITH_MIFARE'
export const DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD_WITH_PROX =
    'NEO_2X16_LCD_KEYPAD_WITH_PROX'
export const DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD = 'NEO_2X16_LCD_RFK_KEYPAD'
export const DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD_WITH_MIFARE =
    'NEO_2X16_LCD_RFK_KEYPAD_WITH_MIFARE'
export const DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD_WITH_PROX =
    'NEO_2X16_LCD_RFK_KEYPAD_WITH_PROX'
export const DEVICE_SUBTYPE_NEO_3A_POWER_SUPPLY_MODULE = 'NEO_3A_POWER_SUPPLY_MODULE'
export const DEVICE_SUBTYPE_NEO_4_HIGH_CURRENT_OUTPUT_MODULE =
    'NEO_4_HIGH_CURRENT_OUTPUT_MODULE'
export const DEVICE_SUBTYPE_NEO_8_IO_EXPANDER_MODULE = 'NEO_8_I/O_EXPANDER_MODULE'
export const DEVICE_SUBTYPE_NEO_8_OUTPUT_EXPANDER_MODULE = 'NEO_8_OUTPUT_EXPANDER_MODULE'
export const DEVICE_SUBTYPE_NEO_8_ZONE_EXPANDER_MODULE = 'NEO_8_ZONE_EXPANDER_MODULE'
export const DEVICE_SUBTYPE_NEO_AUDIO_ALARM_VERIFICATION_MODULE =
    'NEO_AUDIO_ALARM_VERIFICATION_MODULE'
export const DEVICE_SUBTYPE_NEO_AUDIO_MODULE_WITH_RECORDING =
    'NEO_AUDIO_MODULE_WITH_RECORDING'
export const DEVICE_SUBTYPE_NEO_CORBUS_REPEATER_MODULE = 'NEO_CORBUS_REPEATER_MODULE'
export const DEVICE_SUBTYPE_NEO_DOOR_CONTROL_MODULE = 'NEO_DOOR_CONTROL_MODULE'
export const DEVICE_SUBTYPE_NEO_ICON_KEYPAD = 'NEO_ICON_KEYPAD'
export const DEVICE_SUBTYPE_NEO_ICON_KEYPAD_WITH_PROX = 'NEO_ICON_KEYPAD_WITH_PROX'
export const DEVICE_SUBTYPE_NEO_ICON_RFK_KEYPAD = 'NEO_ICON_RFK_KEYPAD'
export const DEVICE_SUBTYPE_NEO_ICON_RFK_KEYPAD_WITH_PROX =
    'NEO_ICON_RFK_KEYPAD_WITH_PROX'
export const DEVICE_SUBTYPE_NEO_MX_EXPANSION_MODULE = 'NEO_MX_EXPANSION_MODULE'
export const DEVICE_SUBTYPE_NEO_PANEL = 'NEO_PANEL'
export const DEVICE_SUBTYPE_NEO_POWERG_WIRELESS_TRANCEIVER_MODULE =
    'NEO_POWERG_WIRELESS_TRANCEIVER_MODULE'
export const DEVICE_SUBTYPE_NEO_TOUCHSCREEN_KEYPAD_WITH_MIFARE =
    'NEO_TOUCHSCREEN_KEYPAD_WITH_MIFARE'
export const DEVICE_SUBTYPE_NEO_TOUCHSCREEN_KEYPAD_WITH_PROX =
    'NEO_TOUCHSCREEN_KEYPAD_WITH_PROX'
export const DEVICE_SUBTYPE_OUTDOOR = 'OUTDOOR'
export const DEVICE_SUBTYPE_OUTDOOR_CONTACT_NBIOT = 'OUTDOOR_CONTACT_NBIOT'
export const DEVICE_SUBTYPE_PGM_ON_4_HIGH_CURRENT_OUTPUT_MODULE =
    'PGM_ON_4_HIGH_CURRENT_OUTPUT_MODULE'
export const DEVICE_SUBTYPE_PGM_ON_8_OUTPUT_EXPANDER = 'PGM_ON_8_OUTPUT_EXPANDER'
export const DEVICE_SUBTYPE_PGM_ON_CORBUS_REPEATER = 'PGM_ON_CORBUS_REPEATER'
export const DEVICE_SUBTYPE_PGM_ON_EXPANDER33 = 'PGM_ON_EXPANDER33'
export const DEVICE_SUBTYPE_PGM_ON_HW_IOV = 'PGM_ON_HW_IOV'
export const DEVICE_SUBTYPE_PGM_ON_KEYPAD = 'PGM_ON_KEYPAD'
export const DEVICE_SUBTYPE_PGM_ON_PANEL = 'PGM_ON_PANEL'
export const DEVICE_SUBTYPE_PGM_ON_ZONE = 'PGM_ON_ZONE'
export const DEVICE_SUBTYPE_POWER_LINK = 'POWER_LINK'
export const DEVICE_SUBTYPE_PROXIMITY_KEYPAD = 'PROXIMITY_KEYPAD'
export const DEVICE_SUBTYPE_PSP_CONTACT = 'PSP_CONTACT'
export const DEVICE_SUBTYPE_RECESSED = 'RECESSED'
export const DEVICE_SUBTYPE_SHOCK = 'SHOCK'
export const DEVICE_SUBTYPE_SHOCK_AUX = 'SHOCK_AUX'
export const DEVICE_SUBTYPE_SHOCK_CONTACT_AUX_ANTIMASK = 'SHOCK_CONTACT_AUX_ANTIMASK'
export const DEVICE_SUBTYPE_SHOCK_CONTACT_G2 = 'SHOCK_CONTACT_G2'
export const DEVICE_SUBTYPE_SHOCK_CONTACT_G3 = 'SHOCK_CONTACT_G3'
export const DEVICE_SUBTYPE_SINGLE_BUTTON = 'SINGLE_BUTTON'
export const DEVICE_SUBTYPE_SMALL = 'SMALL'
export const DEVICE_SUBTYPE_SMOKE = 'SMOKE'
export const DEVICE_SUBTYPE_SMOKE_HEAT = 'SMOKE_HEAT'
export const DEVICE_SUBTYPE_SMOKE_OR_HEAT = 'SMOKE_OR_HEAT'
export const DEVICE_SUBTYPE_SOUNDER = 'SOUNDER'
export const DEVICE_SUBTYPE_S_CEILING_LR = 'S_CEILING_LR'
export const DEVICE_SUBTYPE_S_CEILING_SR = 'S_CEILING_SR'
export const DEVICE_SUBTYPE_S_CO_SENSOR = 'S_CO_SENSOR'
export const DEVICE_SUBTYPE_S_OUT_CURTAIN = 'S_OUT_CURTAIN'
export const DEVICE_SUBTYPE_S_OUT_MC_1IN = 'S_OUT_MC_1IN'
export const DEVICE_SUBTYPE_S_SMOKE_AND_HEAT = 'S_SMOKE_AND_HEAT'
export const DEVICE_SUBTYPE_TEMPERATURE = 'TEMPERATURE'
export const DEVICE_SUBTYPE_TWO_BUTTON = 'TWO_BUTTON'
export const DEVICE_SUBTYPE_UNIVERSAL_TRANSMITTER_2_INPUTS =
    'UNIVERSAL_TRANSMITTER_2_INPUTS'
export const DEVICE_SUBTYPE_VISONIC_PANEL = 'VISONIC_PANEL'
export const DEVICE_SUBTYPE_WIRELESS_FULL_MESSAGE_LCD_KEYPAD =
    'WIRELESS_FULL_MESSAGE_LCD_KEYPAD'
export const DEVICE_SUBTYPE_WIRELESS_VALVE = 'WIRELESS_VALVE'

export const DEVICE_SUBTYPE_ARM_DISARM_STATUS = 'ARM_DISARM_STATUS'
export const DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_1 = 'PARTIAL_ARM_DISARM_STATUS_1'
export const DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_2 = 'PARTIAL_ARM_DISARM_STATUS_2'
export const DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_3 = 'PARTIAL_ARM_DISARM_STATUS_3'
export const DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_4 = 'PARTIAL_ARM_DISARM_STATUS_4'
export const DEVICE_SUBTYPE_ALARM_STATUS = 'ALARM_STATUS'
export const DEVICE_SUBTYPE_TAMPER_STATUS = 'TAMPER_STATUS'
export const DEVICE_SUBTYPE_FAULT_STATUS = 'FAULT_STATUS'
export const DEVICE_SUBTYPE_AUTOMATION_INPUT = 'AUTOMATION_INPUT'

export const DEVICE_SUBTYPE_DUALPATH_ARM_DISARM = 'DUALPATH_ARM_DISARM'
export const DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_1 = 'DUALPATH_PARTIAL_ARM_1'
export const DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_2 = 'DUALPATH_PARTIAL_ARM_2'
export const DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_3 = 'DUALPATH_PARTIAL_ARM_3'
export const DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_4 = 'DUALPATH_PARTIAL_ARM_4'
export const DEVICE_SUBTYPE_DUALPATH_ARM_ONLY = 'DUALPATH_ARM_ONLY'
export const DEVICE_SUBTYPE_DUALPATH_DISARM_ONLY = 'DUALPATH_DISARM_ONLY'
export const DEVICE_SUBTYPE_DUALPATH_AUTOMATION_BUTTON = 'DUALPATH_AUTOMATION_BUTTON'

export const DEVICE_SUBTIPE_GATEWAY_PANEL = 'GATEWAY_PANEL'

export const DEVICE_SUBTYPE_ZWAVE_THERMOSTAT = 'ZWAVE_THERMOSTAT'
export const DEVICE_SUBTYPE_ZWAVE_DOOR_LOCK = 'ZWAVE_DOOR_LOCK'
export const DEVICE_SUBTYPE_ZWAVE_LIGHT = 'ZWAVE_LIGHT'
export const DEVICE_SUBTYPE_ZWAVE_WATER_VALVE = 'ZWAVE_WATER_VALVE'
export const DEVICE_SUBTYPE_ZWAVE_THERMOMETER = 'ZWAVE_THERMOMETER'
export const DEVICE_SUBTYPE_ZWAVE_ENERGY_CLAMP = 'ZWAVE_ENERGY_CLAMP'
export const DEVICE_SUBTYPE_ZWAVE_REPEATER = 'ZWAVE_REPEATER'
export const DEVICE_SUBTYPE_ZWAVE_GARAGE_DOOR = 'ZWAVE_GARAGE_DOOR'
export const DEVICE_SUBTYPE_ZWAVE_EXTERNAL_SIREN = 'ZWAVE_EXTERNAL_SIREN'
export const DEVICE_SUBTYPE_ZWAVE_SMART_SOCKET = 'ZWAVE_SMART_SOCKET'
export const DEVICE_SUBTYPE_ZWAVE_DIMMER = 'ZWAVE_DIMMER'

export const COMMON_KEYFOB_DEVICE_SUBTYPE = 'KEYFOB'

export function deviceSubtype(subtype, type = null) {
    switch (subtype) {
        case DEVICE_SUBTYPE_UNDEFINED:
            return __('Undefined')
        case DEVICE_SUBTYPE_AC_OUTDOOR:
            return __('AC outdoor')
        case DEVICE_SUBTYPE_BASIC_KEYFOB:
            return __('Basic keyfob')
        case DEVICE_SUBTYPE_BASIC_REPEATER:
            return __('Basic repeater')
        case DEVICE_SUBTYPE_BIG:
            return __('Big')
        case DEVICE_SUBTYPE_CO:
            return __('CO')
        case DEVICE_SUBTYPE_CONTACT:
            return __('Contact')
        case DEVICE_SUBTYPE_CONTACT_4_IN:
            return __('Contact 4 in')
        case DEVICE_SUBTYPE_CONTACT_AUX:
            return __('Contact aux')
        case DEVICE_SUBTYPE_CONTACT_IOS:
            return __('Contact ios')
        case DEVICE_SUBTYPE_CONTACT_V:
            return __('Vanish contact')
        case DEVICE_SUBTYPE_COMBO_NBIOT:
            return __('Combo')
        case DEVICE_SUBTYPE_CURTAIN:
            return __('Curtain')
        case DEVICE_SUBTYPE_DSC_KEYFOB_1:
            return __('DSC keyfob 1')
        case DEVICE_SUBTYPE_DSC_KEYFOB_2:
            return __('DSC keyfob 2')
        case DEVICE_SUBTYPE_FLAT_PIR_SMART:
            return __('Flat pir smart')
        case DEVICE_SUBTYPE_FLOOD:
            return __('Flood')
        case DEVICE_SUBTYPE_FLOOD_PROBE:
            return __('Flood probe')
        case DEVICE_SUBTYPE_GAS:
            return __('Gas')
        case DEVICE_SUBTYPE_GENERIC_COMMUNICATOR:
            return __('Generic Communicator')
        case DEVICE_SUBTYPE_GENERIC_EXPANDER33:
            return __('Expander 33')
        case DEVICE_SUBTYPE_GENERIC_FIREBELL:
            return __('Generic firebell')
        case DEVICE_SUBTYPE_GENERIC_HSMX56K:
            return __('Generic HSMX56K')
        case DEVICE_SUBTYPE_GENERIC_HW_IOV:
            return __('Generic HW IOV')
        case DEVICE_SUBTYPE_GENERIC_HW_ZONE:
            return __('Generic HW Zone')
        case DEVICE_SUBTYPE_GENERIC_PGM:
            return __('Generic PGM')
        case DEVICE_SUBTYPE_GENERIC_PRINTER:
            return __('Generic printer')
        case DEVICE_SUBTYPE_GENERIC_PROXY_TAG:
            return __('Proximity Tag')
        case DEVICE_SUBTYPE_GLASS_BREAK:
            return __('Glass break')
        case DEVICE_SUBTYPE_GLASS_BREAK_SMART:
            return __('Glass break smart')
        case DEVICE_SUBTYPE_HW_ZONE_CONNECTED_DIRECTLY_TO_THE_PANEL:
            return __('HW zone connected directly to the panel')
        case DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_EXPANDER33:
            return __('HW zone connected to expander33')
        case DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_EXPANDER_HW_IOV:
            return __('HW zone connected to expander HW IOV')
        case DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_WL_EXPANDER_IOV:
            return __('HW zone connected to WL expander IOV')
        case DEVICE_SUBTYPE_HW_ZONE_ON_8_IO_EXPANDER:
            return __('HW Zone on 8 I/O expander')
        case DEVICE_SUBTYPE_HW_ZONE_ON_8_ZONE_EXPANDER:
            return __('HW Zone on 8 zone expander')
        case DEVICE_SUBTYPE_HW_ZONE_ON_KEYPAD:
            return __('HW Zone on Keypad')
        case DEVICE_SUBTYPE_INDOOR:
            return __('Indoor')
        case DEVICE_SUBTYPE_KEYFOB_ARM_LED:
            return __('Keyfob arm LED')
        case DEVICE_SUBTYPE_KEYPAD:
            return __('Keypad')
        case DEVICE_SUBTYPE_LCD_KEYPAD:
            return __('LCD keypad')
        case DEVICE_SUBTYPE_LCD_PRG_KEYPAD:
            return __('LCD prg keypad')
        case DEVICE_SUBTYPE_LOCAL_PGM_ON_KEYPAD:
            return __('Local PGM on Keypad')
        case DEVICE_SUBTYPE_MC303_VANISH:
            return __('New vanish contact')
        case DEVICE_SUBTYPE_MOTION:
            return __('Motion')
        case DEVICE_SUBTYPE_MOTION_CAMERA:
            return __('Motion camera')
        case DEVICE_SUBTYPE_MOTION_DUAL:
            return __('Motion dual')
        case DEVICE_SUBTYPE_MOTION_ENERGY:
            return __('Motion energy')
        case DEVICE_SUBTYPE_MOTION_OUTDOOR:
            return __('Motion outdoor')
        case DEVICE_SUBTYPE_MOTION_OUTDOOR_CAMERA:
            return __('Motion outdoor camera')
        case DEVICE_SUBTYPE_MOTION_V_ANTIMASK:
            return __('Motion v antimask')
        case DEVICE_SUBTYPE_NEO_16_LED_KEYPAD:
            return __('16 LED keypad')
        case DEVICE_SUBTYPE_NEO_1A_POWER_SUPPLY_MODULE:
            return __('1A Power Supply module')
        case DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD:
            return __('2x16 LCD Keypad')
        case DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD_WITH_MIFARE:
            return __('Neo 2x16 LCD keypad with mifare')
        case DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD_WITH_PROX:
            return __('2x16 LCD keypad with prox')
        case DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD:
            return __('2x16 LCD RFK keypad')
        case DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD_WITH_MIFARE:
            return __('2x16 LCD RFK keypad with mifare')
        case DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD_WITH_PROX:
            return __('2x16 LCD RFK keypad with prox')
        case DEVICE_SUBTYPE_NEO_3A_POWER_SUPPLY_MODULE:
            return __('3A Power Supply module')
        case DEVICE_SUBTYPE_NEO_4_HIGH_CURRENT_OUTPUT_MODULE:
            return __('4 high current output module')
        case DEVICE_SUBTYPE_NEO_8_IO_EXPANDER_MODULE:
            return __('8 I/O Expansion')
        case DEVICE_SUBTYPE_NEO_8_OUTPUT_EXPANDER_MODULE:
            return __('8 Output Expander')
        case DEVICE_SUBTYPE_NEO_8_ZONE_EXPANDER_MODULE:
            return __('8 Zone Expander')
        case DEVICE_SUBTYPE_NEO_AUDIO_ALARM_VERIFICATION_MODULE:
            return __('Audio Alarm Verifcation Module')
        case DEVICE_SUBTYPE_NEO_AUDIO_MODULE_WITH_RECORDING:
            return __('Audio module with recording')
        case DEVICE_SUBTYPE_NEO_CORBUS_REPEATER_MODULE:
            return __('Corbus repeater module')
        case DEVICE_SUBTYPE_NEO_DOOR_CONTROL_MODULE:
            return __('Door control module')
        case DEVICE_SUBTYPE_NEO_ICON_KEYPAD:
            return __('Icon keypad')
        case DEVICE_SUBTYPE_NEO_ICON_KEYPAD_WITH_PROX:
            return __('Icon keypad with prox')
        case DEVICE_SUBTYPE_NEO_ICON_RFK_KEYPAD:
            return __('Icon RFK keypad')
        case DEVICE_SUBTYPE_NEO_ICON_RFK_KEYPAD_WITH_PROX:
            return __('Icon RFK keypad with prox')
        case DEVICE_SUBTYPE_NEO_MX_EXPANSION_MODULE:
            return __('MX expansion module')
        case DEVICE_SUBTYPE_NEO_PANEL:
            return __('Neo Panel')
        case DEVICE_SUBTYPE_NEO_POWERG_WIRELESS_TRANCEIVER_MODULE:
            return __('Power-G transceiver module')
        case DEVICE_SUBTYPE_NEO_TOUCHSCREEN_KEYPAD_WITH_MIFARE:
            return __('Touchscreen keypad with mifare')
        case DEVICE_SUBTYPE_NEO_TOUCHSCREEN_KEYPAD_WITH_PROX:
            return __('Touchscreen keypad with prox')
        case DEVICE_SUBTYPE_OUTDOOR:
            return __('Outdoor')
        case DEVICE_SUBTYPE_OUTDOOR_CONTACT_NBIOT:
            return __('Outdoor contact NbIoT')
        case DEVICE_SUBTYPE_PGM_ON_4_HIGH_CURRENT_OUTPUT_MODULE:
            return __('PGM on 4 high current output module')
        case DEVICE_SUBTYPE_PGM_ON_8_OUTPUT_EXPANDER:
            return __('PGM on 8 output expander')
        case DEVICE_SUBTYPE_PGM_ON_CORBUS_REPEATER:
            return __('PGM on Corbus Repeater')
        case DEVICE_SUBTYPE_PGM_ON_EXPANDER33:
            return __('PGM on expander33')
        case DEVICE_SUBTYPE_PGM_ON_HW_IOV:
            return __('PGM on HW IOV')
        case DEVICE_SUBTYPE_PGM_ON_KEYPAD:
            return __('PGM on Keypad')
        case DEVICE_SUBTYPE_PGM_ON_PANEL:
            return __('PGM on panel')
        case DEVICE_SUBTYPE_PGM_ON_ZONE:
            return __('PGM on zone')
        case DEVICE_SUBTYPE_POWER_LINK:
            return __('Power link')
        case DEVICE_SUBTYPE_PROXIMITY_KEYPAD:
            return __('Proximity keypad')
        case DEVICE_SUBTYPE_PSP_CONTACT:
            return __('PSP contact')
        case DEVICE_SUBTYPE_RECESSED:
            return __('Recessed contact')
        case DEVICE_SUBTYPE_SHOCK:
            return __('Shock')
        case DEVICE_SUBTYPE_SHOCK_AUX:
            return __('Shock aux')
        case DEVICE_SUBTYPE_SHOCK_CONTACT_AUX_ANTIMASK:
            return __('Shock contact aux antimask')
        case DEVICE_SUBTYPE_SHOCK_CONTACT_G2:
            return __('Shock contact g2')
        case DEVICE_SUBTYPE_SHOCK_CONTACT_G3:
            return __('Shock contact g3')
        case DEVICE_SUBTYPE_SINGLE_BUTTON:
            return __('Single button')
        case DEVICE_SUBTYPE_SMALL:
            return __('Small')
        case DEVICE_SUBTYPE_SMOKE:
            return __('Smoke')
        case DEVICE_SUBTYPE_SMOKE_HEAT:
            return __('Smoke heat')
        case DEVICE_SUBTYPE_SMOKE_OR_HEAT:
            return __('Smoke or heat')
        case DEVICE_SUBTYPE_SOUNDER:
            return __('Sounder')
        case DEVICE_SUBTYPE_S_CEILING_LR:
            return __('Smart Presence Detector (Long Range)')
        case DEVICE_SUBTYPE_S_CEILING_SR:
            return __('Smart Presence Detector (Short Range)')
        case DEVICE_SUBTYPE_S_CO_SENSOR:
            return __('Smart CO sensor')
        case DEVICE_SUBTYPE_S_OUT_CURTAIN:
            return __('Smart Outdoor PIR (Curtain) Detector')
        case DEVICE_SUBTYPE_S_OUT_MC_1IN:
            return __('Outdoor Contact Detector with 1 Aux input')
        case DEVICE_SUBTYPE_S_SMOKE_AND_HEAT:
            return __('Apollo Smoke and Heat Detector')
        case DEVICE_SUBTYPE_TEMPERATURE:
            return __('Temperature')
        case DEVICE_SUBTYPE_TWO_BUTTON:
            return __('Two button')
        case DEVICE_SUBTYPE_UNIVERSAL_TRANSMITTER_2_INPUTS:
            return __('Universal transmitter 2 inputs')
        case DEVICE_SUBTYPE_VISONIC_PANEL:
            return __('Power Master Panel')
        case DEVICE_SUBTYPE_WIRELESS_FULL_MESSAGE_LCD_KEYPAD:
            return __('Wireless full message LCD keypad')
        case DEVICE_SUBTYPE_WIRELESS_VALVE:
            return __('Wireless water valve')
        case DEVICE_SUBTYPE_ARM_DISARM_STATUS:
            return __('Arm disarm status')
        case DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_1:
            return __('Partial arm disarm status 1')
        case DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_2:
            return __('Partial arm disarm status 2')
        case DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_3:
            return __('Partial arm disarm status 3')
        case DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_4:
            return __('Partial arm disarm status 4')
        case DEVICE_SUBTYPE_ALARM_STATUS:
            return __('Alarm status')
        case DEVICE_SUBTYPE_TAMPER_STATUS:
            return __('Tamper status')
        case DEVICE_SUBTYPE_FAULT_STATUS:
            return __('Fault status')
        case DEVICE_SUBTYPE_AUTOMATION_INPUT:
            return __('Automation input')
        case DEVICE_SUBTYPE_DUALPATH_ARM_DISARM:
            return __('Arm disarm')
        case DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_1:
            return __('Partial arm 1')
        case DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_2:
            return __('Partial arm 2')
        case DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_3:
            return __('Partial arm 3')
        case DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_4:
            return __('Partial arm 4')
        case DEVICE_SUBTYPE_DUALPATH_ARM_ONLY:
            return __('Arm only')
        case DEVICE_SUBTYPE_DUALPATH_DISARM_ONLY:
            return __('Disarm only')
        case DEVICE_SUBTYPE_DUALPATH_AUTOMATION_BUTTON:
            return __('Automation button')
        case DEVICE_SUBTYPE_ZWAVE_THERMOSTAT:
            return __('Z-Wave Thermostat')
        case DEVICE_SUBTYPE_ZWAVE_DOOR_LOCK:
            return __('Z-Wave Door Lock')
        case DEVICE_SUBTYPE_ZWAVE_LIGHT:
            return __('Z-Wave Light')
        case DEVICE_SUBTYPE_ZWAVE_WATER_VALVE:
            return __('Z-Wave Water Valve')
        case DEVICE_SUBTYPE_ZWAVE_THERMOMETER:
            return __('Z-Wave Thermometer')
        case DEVICE_SUBTYPE_ZWAVE_ENERGY_CLAMP:
            return __('Z-Wave Energy Clamp')
        case DEVICE_SUBTYPE_ZWAVE_REPEATER:
            return __('Z-Wave Repeater')
        case DEVICE_SUBTYPE_ZWAVE_GARAGE_DOOR:
            return __('Z-Wave Garage Door')
        case DEVICE_SUBTYPE_ZWAVE_EXTERNAL_SIREN:
            return __('Z-Wave External Siren')
        case DEVICE_SUBTYPE_ZWAVE_SMART_SOCKET:
            return __('Z-Wave Smart Socket')
        case DEVICE_SUBTYPE_ZWAVE_DIMMER:
            return __('Z-Wave Dimmer')
    }

    if (subtype === null && type) {
        return deviceType(type)
    }

    warn(`Unknown device subtype ${subtype}, type ${type}`)

    return __('Unknown device')
}

const unknown = require('assets/devices/unknown.svg')

const images = {
    [DEVICE_SUBTYPE_UNDEFINED]: unknown,
    [DEVICE_SUBTYPE_AC_OUTDOOR]: require('assets/devices/SR-730-PG2.svg'),
    [DEVICE_SUBTYPE_BASIC_KEYFOB]: require('assets/devices/KF-234-PG2.svg'),
    [DEVICE_SUBTYPE_BASIC_REPEATER]: require('assets/devices/RP-600-PG2.svg'),
    [DEVICE_SUBTYPE_BIG]: null,
    [DEVICE_SUBTYPE_CO]: require('assets/devices/GSD-442-PG2.svg'),
    [DEVICE_SUBTYPE_CONTACT]: require('assets/devices/MC-302.svg'),
    [DEVICE_SUBTYPE_CONTACT_4_IN]: require('assets/devices/FLD-550-PG2.svg'),
    [DEVICE_SUBTYPE_CONTACT_AUX]: require('assets/devices/MC-302e.svg'),
    [DEVICE_SUBTYPE_CONTACT_IOS]: require('assets/devices/FLD-550-PG2.svg'),
    [DEVICE_SUBTYPE_CONTACT_V]: require('assets/devices/ContactVanish.svg'),
    [DEVICE_SUBTYPE_COMBO_NBIOT]: require('assets/devices/Smart-CO-Sensor.svg'),
    [DEVICE_SUBTYPE_PSP_CONTACT]: require('assets/devices/MC-302e.svg'),
    [DEVICE_SUBTYPE_CURTAIN]: require('assets/devices/Clip-PG2.svg'),
    [DEVICE_SUBTYPE_DSC_KEYFOB_1]: require('assets/devices/KF-234-PG2.svg'),
    [DEVICE_SUBTYPE_DSC_KEYFOB_2]: require('assets/devices/KF-235-PG2.svg'),
    [DEVICE_SUBTYPE_FLAT_PIR_SMART]: require('assets/devices/FlatPIR.svg'),
    [DEVICE_SUBTYPE_FLOOD]: require('assets/devices/FLD-550-PG2.svg'),
    [DEVICE_SUBTYPE_FLOOD_PROBE]: require('assets/devices/FLD-550-PG2.svg'),
    [DEVICE_SUBTYPE_GAS]: require('assets/devices/GSD-441-PG2.svg'),
    [DEVICE_SUBTYPE_GENERIC_COMMUNICATOR]: require('assets/devices/GEN_POWER_SUPPLY.svg'),
    [DEVICE_SUBTYPE_GENERIC_EXPANDER33]: require('assets/devices/Expander33.svg'),
    [DEVICE_SUBTYPE_GENERIC_FIREBELL]: null,
    [DEVICE_SUBTYPE_GENERIC_HSMX56K]: null,
    [DEVICE_SUBTYPE_GENERIC_HW_IOV]: require('assets/devices/Expander33.svg'),
    [DEVICE_SUBTYPE_GENERIC_PGM]: require('assets/devices/PGM.svg'),
    [DEVICE_SUBTYPE_GENERIC_PRINTER]: null,
    [DEVICE_SUBTYPE_GENERIC_PROXY_TAG]: require('assets/devices/Proxy-tag.svg'),
    [DEVICE_SUBTYPE_GLASS_BREAK]: require('assets/devices/GB-501.svg'),
    [DEVICE_SUBTYPE_GLASS_BREAK_SMART]: require('assets/devices/GB-502-PG2.svg'),
    [DEVICE_SUBTYPE_GLASS_BREAK_SMART]: require('assets/devices/GB-502-PG2.svg'),
    [DEVICE_SUBTYPE_HW_ZONE_CONNECTED_DIRECTLY_TO_THE_PANEL]: require('assets/devices/WiredZone.svg'),
    [DEVICE_SUBTYPE_HW_ZONE_ON_KEYPAD]: require('assets/devices/WiredZone.svg'),
    [DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_EXPANDER33]: require('assets/devices/WiredZone.svg'),
    [DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_EXPANDER_HW_IOV]: require('assets/devices/WiredZone.svg'),
    [DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_WL_EXPANDER_IOV]: require('assets/devices/WiredZone.svg'),
    [DEVICE_SUBTYPE_HW_ZONE_ON_8_IO_EXPANDER]: require('assets/devices/WiredZone.svg'),
    [DEVICE_SUBTYPE_HW_ZONE_ON_8_ZONE_EXPANDER]: require('assets/devices/WiredZone.svg'),
    [DEVICE_SUBTYPE_INDOOR]: require('assets/devices/RP-600-PG2-white.svg'),
    [DEVICE_SUBTYPE_KEYFOB_ARM_LED]: require('assets/devices/KF-235-PG2.svg'),
    [DEVICE_SUBTYPE_KEYPAD]: require('assets/devices/MCM-140.svg'),
    [DEVICE_SUBTYPE_LCD_KEYPAD]: require('assets/devices/MKP-160.svg'),
    [DEVICE_SUBTYPE_LCD_PRG_KEYPAD]: require('assets/devices/KP-250.svg'),
    [DEVICE_SUBTYPE_MC303_VANISH]: require('assets/devices/MC-303.svg'),
    [DEVICE_SUBTYPE_MOTION]: require('assets/devices/Next-PG2.svg'),
    [DEVICE_SUBTYPE_MOTION_CAMERA]: require('assets/devices/Next-CAM-PG2.svg'),
    [DEVICE_SUBTYPE_MOTION_DUAL]: require('assets/devices/TOWER-30-PG2.svg'),
    [DEVICE_SUBTYPE_MOTION_ENERGY]: null,
    [DEVICE_SUBTYPE_MOTION_OUTDOOR]: require('assets/devices/TOWER-20AM-PG2.svg'),
    [DEVICE_SUBTYPE_MOTION_OUTDOOR_CAMERA]: require('assets/devices/TOWER-CAM-PG2.svg'),
    [DEVICE_SUBTYPE_MOTION_V_ANTIMASK]: require('assets/devices/TOWER-30-PG2.svg'),
    [DEVICE_SUBTYPE_NEO_16_LED_KEYPAD]: require('assets/devices/LED_KEYPAD.svg'),
    [DEVICE_SUBTYPE_NEO_1A_POWER_SUPPLY_MODULE]: require('assets/devices/GEN_POWER_SUPPLY.svg'),
    [DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD]: require('assets/devices/WIRE_KEYPAD.svg'),
    [DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD_WITH_MIFARE]: require('assets/devices/WIRE_KEYPAD.svg'),
    [DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD_WITH_PROX]: require('assets/devices/WIRE_KEYPAD.svg'),
    [DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD]: require('assets/devices/WIRE_KEYPAD.svg'),
    [DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD_WITH_MIFARE]: require('assets/devices/WIRE_KEYPAD.svg'),
    [DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD_WITH_PROX]: require('assets/devices/WIRE_KEYPAD.svg'),
    [DEVICE_SUBTYPE_NEO_3A_POWER_SUPPLY_MODULE]: require('assets/devices/GEN_POWER_SUPPLY.svg'),
    [DEVICE_SUBTYPE_NEO_4_HIGH_CURRENT_OUTPUT_MODULE]: require('assets/devices/GEN_POWER_SUPPLY.svg'),
    [DEVICE_SUBTYPE_NEO_8_IO_EXPANDER_MODULE]: require('assets/devices/GEN_POWER_SUPPLY.svg'),
    [DEVICE_SUBTYPE_NEO_8_OUTPUT_EXPANDER_MODULE]: require('assets/devices/GEN_ZONE_EXPANDER.svg'),
    [DEVICE_SUBTYPE_NEO_8_ZONE_EXPANDER_MODULE]: require('assets/devices/GEN_ZONE_EXPANDER.svg'),
    [DEVICE_SUBTYPE_NEO_AUDIO_ALARM_VERIFICATION_MODULE]: require('assets/devices/GEN_ZONE_EXPANDER.svg'),
    [DEVICE_SUBTYPE_NEO_AUDIO_MODULE_WITH_RECORDING]: require('assets/devices/GEN_ZONE_EXPANDER.svg'),
    [DEVICE_SUBTYPE_NEO_DOOR_CONTROL_MODULE]: null,
    [DEVICE_SUBTYPE_NEO_ICON_KEYPAD]: require('assets/devices/WIRE_KEYPAD.svg'),
    [DEVICE_SUBTYPE_NEO_ICON_KEYPAD_WITH_PROX]: require('assets/devices/WIRE_KEYPAD.svg'),
    [DEVICE_SUBTYPE_NEO_ICON_RFK_KEYPAD]: require('assets/devices/WIRE_KEYPAD.svg'),
    [DEVICE_SUBTYPE_NEO_ICON_RFK_KEYPAD_WITH_PROX]: require('assets/devices/WIRE_KEYPAD.svg'),
    [DEVICE_SUBTYPE_NEO_MX_EXPANSION_MODULE]: null,
    [DEVICE_SUBTYPE_NEO_PANEL]: require('assets/panels/neo-1.svg'),
    [DEVICE_SUBTYPE_NEO_POWERG_WIRELESS_TRANCEIVER_MODULE]: require('assets/devices/RP-600-PG2-white.svg'),
    [DEVICE_SUBTYPE_NEO_TOUCHSCREEN_KEYPAD_WITH_MIFARE]: require('assets/devices/TOUCH_KP.svg'),
    [DEVICE_SUBTYPE_NEO_TOUCHSCREEN_KEYPAD_WITH_PROX]: require('assets/devices/TOUCH_KP.svg'),
    [DEVICE_SUBTYPE_OUTDOOR]: require('assets/devices/SR-730-PG2.svg'),
    [DEVICE_SUBTYPE_OUTDOOR_CONTACT_NBIOT]: require('assets/devices/Smart-Out-MC.svg'),
    [DEVICE_SUBTYPE_PGM_ON_EXPANDER33]: require('assets/devices/PGM.svg'),
    [DEVICE_SUBTYPE_PGM_ON_4_HIGH_CURRENT_OUTPUT_MODULE]: require('assets/devices/PGM.svg'),
    [DEVICE_SUBTYPE_PGM_ON_8_OUTPUT_EXPANDER]: require('assets/devices/PGM.svg'),
    [DEVICE_SUBTYPE_PGM_ON_CORBUS_REPEATER]: require('assets/devices/PGM.svg'),
    [DEVICE_SUBTYPE_PGM_ON_HW_IOV]: require('assets/devices/PGM.svg'),
    [DEVICE_SUBTYPE_PGM_ON_PANEL]: require('assets/devices/PGM.svg'),
    [DEVICE_SUBTYPE_PGM_ON_ZONE]: require('assets/devices/PGM.svg'),
    [DEVICE_SUBTYPE_PGM_ON_KEYPAD]: require('assets/devices/PGM.svg'),
    [DEVICE_SUBTYPE_POWER_LINK]: require('assets/devices/GEN_POWER_SUPPLY.svg'),
    [DEVICE_SUBTYPE_PROXIMITY_KEYPAD]: require('assets/devices/MCM-140.svg'),
    [DEVICE_SUBTYPE_RECESSED]: require('assets/devices/Recessed.svg'),
    [DEVICE_SUBTYPE_S_CEILING_LR]: require('assets/devices/Long_Range.svg'),
    [DEVICE_SUBTYPE_S_CEILING_SR]: require('assets/devices/Smart-Ceiling-SR.svg'),
    [DEVICE_SUBTYPE_S_CO_SENSOR]: require('assets/devices/Smart-CO-Sensor.svg'),
    [DEVICE_SUBTYPE_S_OUT_CURTAIN]: require('assets/devices/Smart-Out-Curtain.svg'),
    [DEVICE_SUBTYPE_S_OUT_MC_1IN]: require('assets/devices/Smart-Out-MC.svg'),
    [DEVICE_SUBTYPE_S_SMOKE_AND_HEAT]: require('assets/devices/Smart-Smoke-Heat.svg'),
    [DEVICE_SUBTYPE_SHOCK]: null,
    [DEVICE_SUBTYPE_SHOCK_AUX]: require('assets/devices/SD-304-PG2.svg'),
    [DEVICE_SUBTYPE_SHOCK_CONTACT_AUX_ANTIMASK]: require('assets/devices/SD-304-PG2.svg'),
    [DEVICE_SUBTYPE_SHOCK_CONTACT_G2]: require('assets/devices/SD-304-PG2.svg'),
    [DEVICE_SUBTYPE_SHOCK_CONTACT_G3]: require('assets/devices/SD-304-PG2.svg'),
    [DEVICE_SUBTYPE_SINGLE_BUTTON]: require('assets/devices/PB-101-PG2.svg'),
    [DEVICE_SUBTYPE_SMALL]: null,
    [DEVICE_SUBTYPE_SMOKE]: require('assets/devices/SMD-426-PG2.svg'),
    [DEVICE_SUBTYPE_SMOKE_HEAT]: require('assets/devices/SMD-427-PG2.svg'),
    [DEVICE_SUBTYPE_SMOKE_OR_HEAT]: require('assets/devices/SMD-427-PG2.svg'),
    [DEVICE_SUBTYPE_SOUNDER]: require('assets/devices/RP-600-PG2-white.svg'),
    [DEVICE_SUBTYPE_TEMPERATURE]: require('assets/devices/TMD-560-PG2.svg'),
    [DEVICE_SUBTYPE_TWO_BUTTON]: require('assets/devices/PM-102-PG2.svg'),
    [DEVICE_SUBTYPE_UNIVERSAL_TRANSMITTER_2_INPUTS]: null,
    [DEVICE_SUBTYPE_VISONIC_PANEL]: require('assets/panels/powermaster-360R.svg'),
    [DEVICE_SUBTYPE_WIRELESS_FULL_MESSAGE_LCD_KEYPAD]: require('assets/devices/WIRE_KEYPAD.svg'),
    [DEVICE_SUBTYPE_WIRELESS_VALVE]: require('assets/devices/Wireless-water-valve.svg'),
}

const imagesByDeviceType = {
    [deviceTypes.DEVICE_TYPE_PROXY_TAG]: require('assets/devices/Proxy-tag.svg'),
    [deviceTypes.DEVICE_TYPE_POWER_LINK]: require('assets/devices/GEN_POWER_SUPPLY.svg'),
    [deviceTypes.DEVICE_TYPE_POWER_SUPPLY]: require('assets/devices/GEN_POWER_SUPPLY.svg'),
    [deviceTypes.DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS]: require('assets/devices/GEN_POWER_SUPPLY.svg'),
    [deviceTypes.DEVICE_TYPE_AUDIO_VERIFICATION]: require('assets/devices/GEN_POWER_SUPPLY.svg'),
    [deviceTypes.DEVICE_TYPE_OUTPUT_EXPANDER]: require('assets/devices/GEN_POWER_SUPPLY.svg'),
    [deviceTypes.DEVICE_TYPE_ZONE_EXPANDER]: require('assets/devices/GEN_POWER_SUPPLY.svg'),
    [deviceTypes.DEVICE_TYPE_BUS_REPEATER_HSM3204CX]: require('assets/devices/GEN_POWER_SUPPLY.svg'),
    [deviceTypes.DEVICE_TYPE_DUALPATH_INPUT]: require('assets/devices/PGM.svg'),
    [deviceTypes.DEVICE_TYPE_DUALPATH_OUTPUT]: require('assets/devices/PGM.svg'),
    [deviceTypes.DEVICE_TYPE_GSM]: require('assets/devices/4g/GSM-Modem.svg'),
    [deviceTypes.DEVICE_TYPE_LTE_MODULE]: require('assets/devices/4g/GSM-Modem.svg'),
    [deviceTypes.DEVICE_TYPE_DT_PG_BOX_DEVICE]: require('assets/devices/MC-302.svg'),
}

export function deviceImage(subtype, type = null) {
    if (has(images, subtype)) {
        return images[subtype] || unknown
    }

    if (type && has(imagesByDeviceType, type)) {
        return imagesByDeviceType[type] || unknown
    }

    warn(`Image for device subtype ${subtype} not set`)

    return unknown
}

// see bug PMN-11729 for Virtual panels
export const devicesToFilterNames = [
    DEVICE_SUBTIPE_GATEWAY_PANEL,
    DEVICE_SUBTYPE_OUTDOOR_CONTACT_NBIOT,
    DEVICE_SUBTYPE_COMBO_NBIOT,
]

export function devicesToEnrollExists(devices: Array) {
    return devices.filter(({name}) => !devicesToFilterNames.includes(name)).length > 0
}
