import {__} from 'utils/i18n'
import {err} from 'utils/log'

export default function diagnosticItemType(name) {
    switch ((name || '').trim().toLowerCase()) {
        case 'batteryvoltage':
            return __('Battery Voltage')
        case 'averagebatteryvoltage':
            return __('Average Battery Voltage')
        case 'batterychargecurrent':
            return __('Battery Charge Current')
        case 'batterychargestatus':
            return __('Battery Charge Status')
        case 'batterychargestartvoltage':
            return __('Battery Charge Start Voltage')
        case 'batterychargestarttime':
            return __('Battery Charge Start Time')
        case 'batterychargeduration':
            return __('Battery Charge Duration')
        case 'batterydischargecurrent':
            return __('Battery Discharge Current')
        case 'batterychargevoltage':
            return __('Battery Charge Voltage')
        case 'powersupplyinputvoltage':
            return __('Power Supply Input Voltage')
        case 'resistance':
            return __('Resistance')
        case '':
            return __('Unknown')
        default:
            err(`Unknown diagnostic item ${name}`)
            return name
    }
}

export function diagnosticItemMeasurement(name) {
    switch ((name || '').trim().toLowerCase()) {
        case 'powersupplyinputvoltage':
        case 'batterychargevoltage':
        case 'averagebatteryvoltage':
        case 'batteryvoltage':
        case 'batterychargestartvoltage':
            return __('V')
        case 'batterychargecurrent':
        case 'batterydischargecurrent':
            return __('mA')
        case 'batterychargeduration':
            return __('seconds')
        case 'resistance':
            return __('Ohm')
        case 'batterychargestatus':
        case 'batterychargestarttime':
        case '':
            return ''
        default:
            err(`Unknown diagnostic measurement for item ${name}`)
            return ''
    }
}
