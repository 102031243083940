import {createSelector} from 'reselect'
import get from 'lodash-es/get'

export const selectPanelInstallersList = createSelector(
    (state) => state.panelInstallers.list,
    (state, {panelId}) => get(state, ['panelInstallers', 'store', panelId], {}),
    ({page, isLoading}, store) => ({
        rows: page.map((id) => store[id] || {}),
        isLoading,
    })
)
