import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'

import {__} from 'utils/i18n'

export const validation = {
    name: {
        presence: true,
        length: {maximum: 32},
    },
    idOnPanel: {
        presence: true,
    },
}

export default class NotificationTypeFieldset extends PureComponent {
    static propTypes = {
        data: PropTypes.object,
    }

    static defaultProps = {
        data: {
            name: '',
            idOnPanel: '',
        },
    }

    render() {
        const {data} = this.props
        return (
            <div>
                <Input
                    autoFocus
                    label={__('Notification Type Name')}
                    name="name"
                    defaultValue={data.name}
                />
                <Input
                    label={__('Id On Panel')}
                    name="idOnPanel"
                    defaultValue={data.idOnPanel}
                />
            </div>
        )
    }
}
