import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {refresh} from 'modules/panels/store/actions'
import {
    setStart,
    init,
    fetch,
    reset,
    startPoll,
    stopPoll,
} from 'modules/panels/list/actions'
import {remove} from 'modules/panels/one/actions'
import {
    showAddPanelModal,
    showChangePanelGroupModal,
    showMarkForServiceModal,
} from 'modules/modals/actions'
import {selectPanelsListRows} from 'modules/panels/list/selectors'

const mapStateToProps = () => (state) => {
    const {start, total, error, isLoading} = state.panels.list

    return {
        start,
        total,
        error,
        isLoading,
        rows: selectPanelsListRows(state),
    }
}

const mapDispatchToProps = () => (dispatch) =>
    bindActionCreators(
        {
            onPageChange: setStart,
            init,
            fetch,
            reset,
            remove,
            refresh,
            stopPoll,
            startPoll,
            showAddPanelModal,
            showMarkForServiceModal,
            showChangePanelGroupModal,
        },
        dispatch
    )

export default function withPanels() {
    return compose(withPerPage('panels'), connect(mapStateToProps, mapDispatchToProps))
}

export function withPanelsRefresh() {
    return connect(
        (state) => ({isRefreshing: state.panels.store.isRefreshing}),
        (dispatch) =>
            bindActionCreators(
                {
                    refresh,
                },
                dispatch
            )
    )
}
