import React, {Component} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import Button from 'ipmp-react-ui/Button'
import Card, {CardActions, CardClose, CardContent} from 'ipmp-react-ui/Card'

import {__} from 'utils/i18n'
import Modal from 'ipmp-react-ui/Modal'

import LicenseFeaturesTable from './ui/LicenseFeaturesTable'

import {selectLicense} from 'modules/system/settings/selectors'

class AboutLicenseFeatures extends Component {
    static propTypes = {
        hide: PropTypes.func,
        license: PropTypes.object,
    }

    render() {
        const {
            hide,
            license: {limits, valid, features},
        } = this.props
        return (
            <Modal onClose={hide}>
                <Card className="card--wide">
                    <CardClose onClick={hide} />
                    <CardContent className="card-about-license">
                        <div className="block">
                            <h3 className="title title--margin">
                                {__('License valid dates').toUpperCase()}
                            </h3>

                            <div className="about-info">
                                <p className="hint hint--gray">{__('From')}</p>
                                {moment.unix(valid.not_before).format('LL – LT')}
                            </div>

                            <div className="about-info">
                                <p className="hint hint--gray">{__('Until')}</p>
                                {moment.unix(valid.not_after).format('LL – LT')}
                            </div>
                        </div>
                        <div className="block">
                            <h3 className="title title--margin">
                                {__('License activated systems').toUpperCase()}
                            </h3>
                            <div className="about-info">
                                <p className="hint hint--gray">
                                    {__('PowerMaster/Max Systems')}
                                </p>
                                {limits.panels}
                            </div>
                            <div className="about-info">
                                <p className="hint hint--gray">
                                    {__('PowerSeries NEO/PRO Systems')}
                                </p>
                                {limits.panelsNeo}
                            </div>
                            <div className="about-info">
                                <p className="hint hint--gray">
                                    {__('DualPath Systems')}
                                </p>
                                {limits.panelsDualPath}
                            </div>
                            <div className="about-info">
                                <p className="hint hint--gray">
                                    {__('Automation Software connections')}
                                </p>
                                {limits.centralStationNum}
                            </div>
                        </div>
                        <div className="block">
                            <h3 className="title title--margin">
                                {__('License features').toUpperCase()}
                            </h3>
                        </div>
                        <LicenseFeaturesTable
                            className="modal-table"
                            rows={Object.values(features).filter((row) => row.feature)}
                        />
                    </CardContent>
                    <CardActions>
                        <Button primary onClick={hide}>
                            {__('Ok')}
                        </Button>
                    </CardActions>
                </Card>
            </Modal>
        )
    }
}

export default compose(connect(selectLicense))(AboutLicenseFeatures)
