import {compose} from 'redux'
import get from 'lodash-es/get'

import {suspend} from 'permissions/users/selection'

import {ifSelection, withSelectionHandler} from 'containers/withSelection'
import withConfirmation from 'containers/withConfirmation'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'
import withUsers from 'containers/withUsers'

import {ReactComponent as PauseIcon} from 'ipmp-react-ui/icons/pause.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: suspend}),
    withVisibility(),
    withUsers(),
    withProps(({suspend: onClick}) => ({
        onClick,
        Icon: PauseIcon,
        title: __('Suspend users'),
        message: __('Do you really want to suspend selected users?'),
        label: __('Suspend'),
    })),
    withSelectionHandler(),
    withConfirmation(),
    ifSelection((id, {users}) => get(users, ['store', 'byIds', id, 'isEnabled'], false))
)(Button)
