import {takeEvery, put, call, all} from 'redux-saga/effects'
import {fetch, setLoading} from './actions'
import {fetch as fetchApi} from 'api/installers'
import {update} from 'modules/installers/store/actions'

export default function* () {
    yield all([takeEvery(fetch, watchFetch)])
}

function* watchFetch({payload: {prefix, count}}) {
    try {
        const {rows} = yield call(fetchApi, {
            count,
            filters: {},
            query: prefix || '',
            sort: 'email',
        })

        yield put(update(rows))
        yield put(setLoading(false))
    } catch (error) {
        yield put(setLoading(false))
    }
}
