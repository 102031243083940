import withProps from 'containers/withProps'

import ActionLogTable from './ActionLogTable'
import ActionLogGridBar from './ActionLogGridBar'
import ActionLogSearch from './ActionLogSearch'

import Page from 'ipmp-react-ui/Page'

export default withProps({
    Top: ActionLogSearch,
    Bar: ActionLogGridBar,
    Content: ActionLogTable,
})(Page)
