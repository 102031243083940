import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Button from 'ipmp-react-ui/Button'
import Card, {CardActions, CardClose, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import Logo from 'ui/Logo'
import {__} from 'utils/i18n'
import Modal from 'ipmp-react-ui/Modal'

import ReleaseNotes from './ui/ReleaseNotes'
import LicenseInfo from './ui/LicenseInfo'
import TimeInfo from 'modals/General/ui/TimeInfo'

export default class About extends Component {
    static propTypes = {
        hide: PropTypes.func.isRequired,
    }

    render() {
        const {hide} = this.props

        return (
            <Modal onClose={hide}>
                <Card className="about">
                    <CardClose onClick={hide} />

                    <CardHeader>
                        <Logo multiline />
                    </CardHeader>

                    <CardContent>
                        <div className="about-info">
                            <p className="hint hint--gray">{__('Version')}</p>
                            {process.env.REACT_APP_IPMP_VERSION}&nbsp;
                            <ReleaseNotes />
                        </div>

                        <div className="about-info">
                            <p className="hint hint--gray">{__('Build number')}</p>
                            {process.env.REACT_APP_IPMP_REVISION}
                        </div>
                        <TimeInfo />
                        <LicenseInfo />
                    </CardContent>

                    <CardActions>
                        <Button primary onClick={hide}>
                            {__('Ok')}
                        </Button>
                    </CardActions>
                </Card>
            </Modal>
        )
    }
}
