import {POLL_PANELS} from 'configs/pollers'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import {selectPerPage} from 'modules/settings/selectors'
import {snackShow} from 'modules/snacks'
import {takeEvery, all, put, select, call} from 'redux-saga/effects'
import isEqual from 'lodash-es/isEqual'

import * as api from 'api/panels'
import toIds from 'utils/toIds'
import * as actions from './actions'
import {update, purge} from '../store/actions'
import createListSaga from 'modules/higherOrder/createListSaga'
import {addPanel} from 'modules/forms/handlers'
import forgetPanels from 'modules/panels/one/watchers/forgetPanels'

const selector = (state) => ({
    ...state.panels.list,
    scopes: state.settings.scopes.panels,
    perPage: selectPerPage(state, 'panels'),
    listRoute: 'panels',
})

export default function* () {
    yield all([
        createListSaga(api, actions, update, selector, purge),
        takeEvery(addPanel.SUCCESS, refreshPanel),
        takeEvery(addPanel.FAILURE, watchCreateFailure),
        takeEvery(actions.remove, watchRemove),
        createListPollerSaga(actions, POLL_PANELS, watchInvalidate),
    ])
}

function* watchRemove({payload: ids}) {
    yield call(forgetPanels, ids)
}

function* refreshPanel() {
    yield put(actions.fetch())
}

function* watchInvalidate() {
    const state = yield select(selector)

    try {
        const {rows, count} = yield call(api.fetch, state)
        const page = toIds(rows)

        const store = yield select((state) => state.panels.store.byIds)

        const updatedRows = rows.filter((row) => !isEqual(store[row.id], row))

        if (updatedRows.length > 0) {
            yield put(update(updatedRows))
        }

        if (!isEqual(page, state.page)) {
            yield put(actions.receive(page, count))
        }
    } catch (error) {
        yield put(actions.receive(error))
    }
}

function* watchCreateFailure({payload: {error}}) {
    yield put(snackShow(error))
}
