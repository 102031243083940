import {all} from 'redux-saga/effects'

import store from './store/saga'
import values from './values/saga'
import list from './list/saga'
import results from './results/saga'

export default function* () {
    yield all([store(), values(), list(), results()])
}
