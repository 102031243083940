import {get, post} from 'api/http'
import {mapRunners, keysMap} from 'api/runners.map'
import createRowsResponseParser from 'api/base/createRowsResponseParser'
import generateSuggestString from 'api/base/generateSuggestString'
import mapSuggests from 'api/base/mapSuggests'
import generateFilterString from 'api/base/generateFilterString'

export function fetch({batchId, start = 0, perPage: count = 15, filters, query}) {
    const filter = generateFilterString(filters, keysMap)

    return get('runners/list', {
        batch_id: batchId,
        start,
        count,
        filter,
    }).then(createRowsResponseParser(mapRunners))
}

export function suggest(fields, start = 0, count = 5, {batchId}) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('runners/suggest', {
        suggest,
        start,
        count,
        batch_id: batchId,
    }).then(mapSuggests(fields, keysMap))
}

export function retry({batchId, runnerIds}) {
    return post('runners/retry', {batch_id: batchId, runner_ids: runnerIds})
}

export function stop(runnerIds) {
    return post('runners/stop', {runner_ids: runnerIds})
}

export function status(runnerIds) {
    return get('runners/status', {
        runner_ids: runnerIds,
    }).then((runners) => runners.map(mapRunners))
}
