import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {setStart} from 'modules/upgradeStatus/upgradeStatusRunnersList/actions'

import withUpgradeStatusRunners from 'containers/withUpgradeStatusRunners'

import Pager from 'ipmp-react-ui/Pager'

export default compose(
    withUpgradeStatusRunners(),
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                setStart,
                onPageChange: setStart,
            },
            dispatch
        )
    )
)(Pager)
