import {compose} from 'redux'

import {makeBasic} from 'permissions/panel/configuration/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withCurrentConfiguration, {
    withConfigurationActions,
} from 'containers/withConfiguration'
import withProps from 'containers/withProps'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import withConfirmation from 'containers/withConfirmation'
import {withFeatureRejection} from 'containers/withFeature'
import Void from 'components/Void'
import {selectMakeBasicConfigurationFeature} from 'modules/features/store/selectors'

export const MakeBasicConfigurationButton = compose(
    withPermission({isVisible: makeBasic}),
    withVisibility(),
    withCurrentConfiguration(),
    withConfigurationActions(),
    withProps(({onClick, setMakeBasic, changes}) => ({
        onClick: () => {
            setMakeBasic(true)
            onClick && onClick()
        },
        label: __('Make Basic'),
        title: __('Not submitted changes'),
        message: __(
            'By switching to create basic configuration mode you will lose all configuration changes'
        ),
        isConfirmationEnabled: !!changes && Object.keys(changes).length > 0,
    })),
    withConfirmation(),
    withFeatureRejection(selectMakeBasicConfigurationFeature, Void)
)(Button)
