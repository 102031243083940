import React from 'react'
import {compose} from 'redux'

import list from 'permissions/installers/list'

import withProps from 'containers/withProps'
import withLoader from 'containers/withLoader'
import {withPermission, withRejection} from 'containers/withPermission'
import DateTime from 'ipmp-react-ui/DateTime'
import Definition from 'ipmp-react-ui/Definition'

import Table from 'ipmp-react-ui/Table'

import {__} from 'utils/i18n'
import {withPanelInstallers} from 'containers/withInstallers'
import withLifeCycle from 'containers/withLifeCycle'
import InstallerStatus from 'components/Installer/InstallerStatus'
import UnassignButton from './GridBar/UnassignInstallerButton'

const columns = [
    {
        name: () => __('Installer'),
        render: ({name, email}) => <Definition title={name} detail={email} />,
    },
    {
        name: () => __('Telephone'),
        render: ({telephone}) => telephone,
    },
    {
        name: () => __('Created'),
        render: ({createdAt}) => <DateTime datetime={createdAt} />,
    },
    {
        name: () => __('Status'),
        tooltip: false,
        component: InstallerStatus,
    },
    {
        tooltip: false,
        fixed: true,
        width: 56,
        render: (row) => <UnassignButton row={row} />,
    },
]

export default compose(
    withPermission({
        isAllowed: list,
    }),
    withRejection(),
    withPanelInstallers(),
    withLifeCycle({
        onMount: ({init}) => {
            init()
        },
        onUnmount: ({purgeList}) => {
            purgeList()
        },
    }),
    withLoader(),
    withProps(({rows}) => ({
        fullHeight: true,
        columns,
        rows,
        emptyMessage: () => __('No installers found'),
    }))
)(Table)
