import {compose} from 'redux'

import {refreshGsmRssi} from 'permissions/panel/devices/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withDevices from 'containers/withDevices'
import withProps from 'containers/withProps'

import RefreshButton from 'ipmp-react-ui/RefreshButton'

import {__} from 'utils/i18n'
import {withRouterPanelId} from 'containers/withPanel'
import {withFeatureRejection} from 'containers/withFeature'
import {selectRSSIGsm} from 'modules/features/store/selectors'
import Void from 'components/Void'

export default compose(
    withPermission({isVisible: refreshGsmRssi}),
    withVisibility(),
    withDevices(),
    withProps(({refreshGSM, panel: {GSMRefreshProcess}}) => ({
        label: __('Refresh GSM'),
        onClick: refreshGSM,
        isRefreshing: !!GSMRefreshProcess,
    })),
    withRouterPanelId(),
    withFeatureRejection(selectRSSIGsm, Void)
)(RefreshButton)
