import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Device from 'components/Devices/Device'

export default class DevicesGroup extends Component {
    static propTypes = {
        devices: PropTypes.array,
        groupTitle: PropTypes.string,
        isChanged: PropTypes.func,
        selected: PropTypes.number,
        status: PropTypes.objectOf(PropTypes.string),
        Device: PropTypes.elementType,
    }

    static defaultProps = {
        Device,
    }

    render() {
        const {devices, groupTitle, isChanged, selected, status, Device} = this.props

        return (
            <div className="devicesList-section">
                <h3 className="devicesList-section-title">{groupTitle}</h3>

                <div className="devicesList-section-content">
                    {devices.map((device) => (
                        <Device
                            key={device.id}
                            device={device}
                            isChanged={isChanged ? isChanged(device) : null}
                            active={device.id === selected}
                            status={status && status[device.id]}
                        />
                    ))}
                </div>
            </div>
        )
    }
}
