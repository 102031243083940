import {put} from 'redux-saga/effects'
import * as actions from '../actions'

export default function* watchReassignService({meta: {ids, userId, user}}) {
    const panels = ids.map((id) => ({
        id,
        user,
        userId,
    }))

    if (panels.length > 0) {
        yield put(actions.update(panels))
    }
}
