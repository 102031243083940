import axios from 'axios/index'
import has from 'lodash-es/has'

import reject from 'api/base/reject'

const cancelError = (response) => {
    return reject({
        isCancel: true,
    })
}

const serverError = ({data, status}) => {
    return reject({
        status,
        isServerError: true,
        data: data.data,
        details: data.details,
        type: data.message,
    })
}

const communicationError = (response, message) => {
    return reject({
        isCommunicationError: true,
        status: response.status,
        message,
    })
}

const wrongFormatError = (response) => {
    return reject({
        isWrongFormatError: true,
        status: response.status,
    })
}

const requestError = (request, message) => {
    return reject({
        isRequestError: true,
        message,
    })
}

const unknownError = (message) => {
    return reject({message})
}

export default function apiFormat(instance) {
    return instance.interceptors.response.use(
        (response) => {
            if (!response.data || typeof response.data !== 'object') {
                return wrongFormatError(response)
            }

            if (response.data.status !== 'success') {
                return serverError(response)
            }

            return response.data.data
        },
        (error) => {
            if (axios.isCancel(error)) {
                return cancelError(error.response)
            }

            if (error.response) {
                if (has(error.response.data, 'status')) {
                    return serverError(error.response)
                }

                return communicationError(error.response, error.message)
            }

            if (error.request) {
                return requestError(error.request, error.message)
            }

            return unknownError(error.message)
        }
    )
}
