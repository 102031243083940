import createRowsResponseParser from 'api/base/createRowsResponseParser'
import generateFilterString from 'api/base/generateFilterString'
import generateSuggestString from 'api/base/generateSuggestString'
import mapSuggests from 'api/base/mapSuggests'

import {get} from 'api/http'
import {keysMap, mapupgradeStatusBatch} from './upgradeStatus.map'
import {mapRunners} from './runners.map'
import {keysMap as runnersKeymap} from 'api/runners.map'

export function fetch({start = 0, perPage: count, filters, query}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/firmware/upgradestatus', {
        start,
        count,
        filter,
        query,
    }).then(createRowsResponseParser(mapupgradeStatusBatch))
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('/firmware/suggest', {suggest, start, count}).then(
        mapSuggests(fields, keysMap)
    )
}

export function fetchRunners({start = 0, perPage: count, filters, query, batchId}) {
    const filter = generateFilterString(filters, runnersKeymap)

    return get('/firmware/upgradestatusrunners', {
        start,
        count,
        filter,
        query,
        batch_id: batchId,
    }).then(createRowsResponseParser(mapRunners))
}

export function runnersSuggest(fields, start = 0, count = 5, {batchId}) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('firmware/upgradestatusrunnerssuggest', {
        suggest,
        start,
        count,
        batch_id: batchId,
    }).then(mapSuggests(fields, runnersKeymap))
}
