import {compose} from 'redux'

import EventsRateChart from 'components/Dashboard/EventsRateChart'
import {withEventsRate} from 'containers/withDashboard'
import {withPermission, withRejection} from 'containers/withPermission'
import {eventsRate} from 'permissions/dashboard/charts'

export default compose(
    withPermission({isAllowed: eventsRate}),
    withRejection(),
    withEventsRate()
)(EventsRateChart)
