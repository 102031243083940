import React from 'react'

import __ from 'utils/i18n'
import Error from 'ipmp-react-ui/Error'

export default class PageNotFound {
    path = '*'

    render = () => (
        <main className="content">
            <Error message={__('The requested page could not be found')} />
        </main>
    )
}
