import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {CardActions} from 'ipmp-react-ui/Card'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRun} from 'ipmp-react-ui/icons/run.svg'

import {EventRuleStep} from 'constants/eventRules'
import __ from 'utils/i18n'

class EventRuleStepPagination extends Component {
    static propTypes = {
        currentStep: PropTypes.instanceOf(EventRuleStep).isRequired,
        onPrev: PropTypes.func.isRequired,
        onNext: PropTypes.func.isRequired,
        editable: PropTypes.bool,
        isLoading: PropTypes.bool,
    }

    onPrev = () => this.props.onPrev()

    get isPrev() {
        return !this.props.currentStep.isFirstStep
    }

    get isNext() {
        return !this.props.currentStep.isLastStep
    }

    get isSave() {
        return !this.isNext
    }

    get prevButton() {
        if (!this.isPrev) {
            return null
        }

        return (
            <Button
                className="eventRules-master-steps-prevButton"
                borderless
                onClick={this.onPrev}
                Icon={IconRun}
            >
                {__('Prev')}
            </Button>
        )
    }

    get nextButton() {
        if (!this.isNext) {
            return null
        }

        return (
            <Button
                className="eventRules-master-steps-nextButton"
                type="submit"
                Icon={IconRun}
            >
                {__('Next')}
            </Button>
        )
    }

    get saveButton() {
        const {isLoading, editable} = this.props

        if (!this.isSave) {
            return null
        }

        return (
            editable && (
                <Button
                    className="eventRules-master-steps-saveButton"
                    primary
                    disabled={isLoading}
                    type="submit"
                >
                    {__('Save')}
                </Button>
            )
        )
    }

    render() {
        return (
            <CardActions className="eventRules-master-steps-cardActions">
                {this.prevButton}
                {this.nextButton}
                {this.saveButton}
            </CardActions>
        )
    }
}

export default EventRuleStepPagination
