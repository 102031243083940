import React from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'
import {ReactComponent as IconCheck} from 'ipmp-react-ui/icons/check.svg'
import Button from 'ipmp-react-ui/Button'
import {withInstallerAddPanelTable} from 'containers/withInstallerPanelsTable'
import withConfirmation from 'containers/withConfirmation'
import {assignPanel} from 'modules/installerPanels/list/actions'
import withProps from 'containers/withProps'
import __ from 'utils/i18n'

const AddPanelCell = ({isAssigned, row}) => {
    if (isAssigned) {
        return <IconCheck />
    }

    return <AssignButton panel={row} />
}

AddPanelCell.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    showAssignInstallerToPanelModal: PropTypes.func.isRequired,
    isAssigned: PropTypes.bool.isRequired,
}

export default compose(
    withInstallerAddPanelTable(),
    connect(({installerPanels: {list}}, {row: {id}}) => ({
        isAssigned: list.page.includes(id),
    }))
)(AddPanelCell)

const AssignButton = compose(
    withInstallerAddPanelTable(),
    connect(null, (dispatcher, {panel}) =>
        bindActionCreators(
            {
                onAssign: () => assignPanel(panel.id),
            },
            dispatcher
        )
    ),
    withProps(({onAssign, panel: {serial}}) => ({
        onClick: onAssign,
        small: true,
        flat: true,
        Icon: IconPlus,
        message: __('Do you really want to assign %s panel?', serial),
        title: __('Assign panel'),
    })),
    withConfirmation()
)(Button)
