import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import ModalCardForm from 'ui/ModalCardForm'
import ZoneTypeFieldset from './ZoneTypeFieldset'

export default class UserForm extends PureComponent {
    static propTypes = {
        handle: PropTypes.func,
        header: PropTypes.string,
        rules: PropTypes.object,
    }

    render() {
        const {handle, header, rules, ...props} = this.props
        return (
            <ModalCardForm
                confirmOnDismiss
                onSubmit={handle}
                header={header}
                rules={rules}
                {...props}
            >
                <ZoneTypeFieldset {...props} />
            </ModalCardForm>
        )
    }
}
