import {compose} from 'redux'

import {create} from 'permissions/centralStations/actions'

import withCentralStations from 'containers/withCentralStations'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: create}),
    withVisibility(),
    withCentralStations(),
    withProps(({showCentralStationCreateModal}) => ({
        onClick: showCentralStationCreateModal,
        Icon: IconPlus,
        label: __('Add Central Station'),
    }))
)(Button)
