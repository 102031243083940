import {humanDuration} from 'ipmp-react-ui/humanTime'
import __ from 'utils/i18n'

export const DEFAULT_DISCOVERY_TIMEOUT = 10 * 60

export const DISCOVERY_TIMEOUTS = [
    0,
    10 * 60,
    60 * 60,
    12 * 60 * 60,
    24 * 60 * 60,
    7 * 24 * 60 * 60,
]

export default function discoveryTimeoutTitle(timeoutValue) {
    if (timeoutValue === 0) {
        return __('Infinite')
    }

    return humanDuration(timeoutValue)
}
