import {createSelector} from 'reselect'
import get from 'lodash-es/get'

export const selectInstallerAddPanelList = createSelector(
    (state) => state.installerPanels.addPanelList,
    (state) => state.panels.store.byIds,
    ({page, start, total, error, isLoading}, store) => ({
        rows: page
            .map((id) => store[id] || {})
            .filter((item) => get(item, 'features.applications.installer', null)),
        isLoading,
        start,
        total,
        error,
    })
)
