import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import PanelCell from 'components/TableCells/PanelCell'
import {__} from 'utils/i18n'

class RecentHistoryPanelList extends Component {
    static propTypes = {
        panels: PropTypes.arrayOf(
            PropTypes.shape({
                serial: PropTypes.string,
                id: PropTypes.number,
                account: PropTypes.string,
            })
        ),
    }

    onClick = (event) => {
        this.props.onClose && this.props.onClose(event)
    }

    render() {
        const {panels} = this.props

        if (!panels) {
            return null
        }

        return (
            <Fragment>
                <h3 className="recentHistory-item-panelList-title">
                    {__('Panel')} ({panels.length})
                </h3>
                {panels.map(({serial, account, id}) => (
                    <div key={id + serial} className="recentHistory-item-panelList-item">
                        <PanelCell
                            id={id}
                            serial={serial}
                            account={account}
                            onClick={this.onClick}
                        />
                    </div>
                ))}
            </Fragment>
        )
    }
}

export default RecentHistoryPanelList
