import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Transmission from './Content/Transmission'
import Format from './Content/Format'

export default class extends Component {
    static propTypes = {
        contentOptions: PropTypes.object.isRequired,
        meteoMetrics: PropTypes.object.isRequired,
        pmaxIndexes: PropTypes.object.isRequired,
        transportEmails: PropTypes.array.isRequired,
    }

    render() {
        const {
            contentOptions,
            meteoMetrics,
            pmaxIndexes,
            transportEmails,
            panelIds,
        } = this.props

        return (
            <div>
                <Format {...{contentOptions, meteoMetrics, pmaxIndexes, panelIds}} />
                <Transmission {...{transportEmails}} />
            </div>
        )
    }
}
