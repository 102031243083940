import createPermission, {any} from 'permissions/createPermission'

import list from './list'

export const changeGroup = createPermission('units.edit')
export const refreshState = createPermission('unit.state.get')
export const remove = createPermission('units.remove')
export const downloadConfiguration = createPermission('unit.config.download')
export const pushBasic = createPermission('units.pushBasic')

export const createReport = createPermission('reports.create')
export const markForService = createPermission('units.markForService')
export const reassign = createPermission('units.faults.reassign')
export const resolveFaults = createPermission('units.faults.resolve')
export const suspendFaults = createPermission('units.faults.suspend')
export const resumeFaults = createPermission('units.faults.resume')
export const discovery = createPermission('units.discovery')
export const diagnostic = createPermission('units.diagnostic')

export const faultsMonitoring = createPermission(
    list,
    any(markForService, reassign, resolveFaults, suspendFaults, resumeFaults)
)

export const service = createPermission(
    list,
    any(downloadConfiguration, refreshState, pushBasic, remove, changeGroup, discovery)
)

export default createPermission(list, any(faultsMonitoring, service, createReport))
