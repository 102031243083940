import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import has from 'lodash-es/has'

import Tabs from 'ipmp-react-ui/Tabs'
import {ScrollView} from 'ipmp-react-ui/Layout'
import DiagnosticInfo from 'components/Diagnostic/DiagnosticInfo'
import FirmwareTab from 'pages/Panel/Devices/Sidebar/Tabs/FirmwareTab'
import configuration from 'permissions/panel/configuration/page'
import diagnostic from 'permissions/panel/devices/diagnostic'

import panelSectionName from 'utils/configuration/panelSectionName'

import ConfigurationTab from './Tabs/ConfigurationTab'
import ConnectionStatusTab from './Tabs/ConnectionStatusTab'

import {withPermission} from 'containers/withPermission'

import {__} from 'utils/i18n'

import {DEVICE_TYPE_CONTROL_PANEL} from 'constants/deviceType'
import {selectPanelFirmwareByDeviceType} from 'modules/panels/firmware/selectors'

const TabsLayout = ({nav, tab}) => {
    return (
        <Fragment>
            {nav}

            <ScrollView className="sidebar-content">{tab}</ScrollView>
        </Fragment>
    )
}

export class PanelSidebar extends Component {
    get hasConfiguration() {
        return this.props.isConfigurationAllowed
    }

    get hasDiagnostic() {
        return (
            this.props.isDiagnosticAllowed &&
            this.props.panel &&
            this.props.panel.traits &&
            this.props.panel.traits.diagnostic
        )
    }

    get hasFirmware() {
        return this.props.firmware && has(this.props.firmware, 'packages')
    }

    get hasConnectionStatus() {
        return has(this.props.panel, 'connectionStatus.status')
    }

    render() {
        const {panel, firmware} = this.props

        const configurationSectionName = panelSectionName(panel)

        return (
            <Tabs Layout={TabsLayout}>
                {this.hasConnectionStatus && (
                    <ConnectionStatusTab
                        name={__('Connection Status')}
                        connectionStatus={panel.connectionStatus}
                    />
                )}

                {this.hasDiagnostic && (
                    <DiagnosticInfo
                        name={__('Diagnostic')}
                        {...panel.traits.diagnostic}
                    />
                )}

                {this.hasConfiguration && configurationSectionName && (
                    <ConfigurationTab
                        name={__('Configuration')}
                        sectionName={configurationSectionName}
                        panelId={panel.id}
                    />
                )}

                {this.hasFirmware && (
                    <FirmwareTab name={__('Firmware')} panelId={panel.id} {...firmware} />
                )}
            </Tabs>
        )
    }
}

export default compose(
    withPermission({
        isConfigurationAllowed: configuration,
        isDiagnosticAllowed: diagnostic,
    }),
    connect((state, {panel}) =>
        selectPanelFirmwareByDeviceType(state, {
            panelId: panel.id,
            device: {deviceType: DEVICE_TYPE_CONTROL_PANEL},
        })
    )
)(PanelSidebar)
