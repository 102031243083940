import React from 'react'
import classnames from 'classnames'

import {ReactComponent as CheckIcon} from 'ipmp-react-ui/icons/check.svg'
import {ReactComponent as CrossIcon} from 'ipmp-react-ui/icons/cross.svg'
import {ReactComponent as QuestionIcon} from 'ipmp-react-ui/icons/question.svg'
import Circle from 'ipmp-react-ui/Circle'

const getIcon = (status) => {
    switch (status) {
        case 'ok':
            return CheckIcon
        case 'fail':
            return CrossIcon
        default:
            return QuestionIcon
    }
}

export default function WalktestMark({status}) {
    return (
        <Circle
            Icon={getIcon(status)}
            className={classnames('device-walktestMark', {
                success: status === 'ok',
                danger: status === 'fail',
            })}
        />
    )
}
